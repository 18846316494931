import {
  Grid,
  FormControlLabel,
  Radio,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { RadioGroup } from "formik-material-ui";
import React, { useCallback } from "react";

interface Props {
  options: {
    label: string;
    value: any;
  }[];
  name: string;
  label?: string;
  handleTransFormValue?: (value: any) => any;
  onChange?: (value: any) => any;
  disabled?: boolean;
}

const useRadioStyles = makeStyles(({ spacing: s }) => ({
  root: {
    padding: s(1, 0),
    width: "100%",
  },
  radio: {
    padding: s(0, 0.75),
    "& .MuiSvgIcon-root": {
      fontSize: 16,
    },
  },
}));

export const Radios: React.FC<Props> = ({
  options,
  name,
  label,
  handleTransFormValue,
  onChange,
  disabled,
}) => {
  const classes = useRadioStyles();

  const { setFieldValue } = useFormikContext();

  const handleChange = useCallback(
    (event: { target: { value: any } }) => {
      const value = event.target.value;
      if (onChange) {
        onChange(handleTransFormValue ? handleTransFormValue(value) : value);
      } else {
        setFieldValue(
          name,
          handleTransFormValue ? handleTransFormValue(value) : value
        );
      }
    },
    [setFieldValue, name, handleTransFormValue, onChange]
  );

  return (
    <Field
      component={RadioGroup}
      name={name}
      className={classes.root}
      onChange={handleChange}
      disabled={!!disabled}
    >
      <Grid container alignItems="center" spacing={2}>
        {label && (
          <Grid item>
            <Typography variant="body2">{label}</Typography>
          </Grid>
        )}
        <Grid item>
          <Grid container spacing={2}>
            {options.map((option) => (
              <Grid item key={option.value}>
                <FormControlLabel
                  value={option.value}
                  label={option.label}
                  disabled={!!disabled}
                  control={
                    <Radio
                      size="small"
                      color="primary"
                      className={classes.radio}
                      disabled={!!disabled}
                    />
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Field>
  );
};
