import React, { useCallback } from "react";
import { BAHAMAS_MAIN_ISLANDS } from "configs";
import { Field, FormikProps, getIn } from "formik";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
} from "formik-material-ui-lab";
import TextField from "@material-ui/core/TextField";

interface IslandFieldProps {
  formikProps: FormikProps<any>;
  // eslint-disable-next-line react/require-default-props
  name?: string;
  disabled?: boolean;
  use2Stars?: boolean;
}

export const IslandField = ({
  formikProps: { errors, touched, values, setFieldValue },
  name = "islandOrStateOrRegion",
  use2Stars,
}: IslandFieldProps) => {
  const stateError: string | undefined = getIn(errors, name);
  const showStateError: boolean = getIn(touched, name) && !!stateError;
  const value = getIn(values, name) || "";

  const onInputChange = useCallback(
    (_event: React.ChangeEvent<any>, newValue: string) => {
      setFieldValue(name, newValue);
    },
    [name, setFieldValue]
  );

  return (
    <Field
      freeSolo
      name={name}
      value={value}
      inputValue={value || ""}
      onInputChange={onInputChange}
      component={Autocomplete}
      options={BAHAMAS_MAIN_ISLANDS}
      getOptionLabel={(option: string) => option}
      style={{ width: "100%" }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          name={name}
          error={showStateError}
          helperText={showStateError && stateError}
          label={use2Stars ? "Island **" : "Island*"}
        />
      )}
    />
  );
};
