import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { FilterMenu } from "pages/UnansweredRequest/filter";
import { FulfilmentDashboardLayout } from "layout";
import { ServiceRequestTable } from "pages/UnansweredRequest/serviceRequestTable";
import { CreateUnansweredServiceRequest } from "component/Dialogs";
import { getAllUnansweredServiceCases } from "pages/UnansweredRequest/request";
import {
  GetServiceCases,
  ServiceCaseResponse,
  ServiceCaseStatusEnum,
  SocketEventsEnum,
} from "@deep-consulting-solutions/be2-constants";
import Loader from "component/Loader";
import { useSocket } from "hooks";
import { getServiceCase } from "redux/call/requests";

type FilterType = Pick<typeof GetServiceCases.QueryParams, "origin" | "type">;

interface SocketEvent {
  serviceCaseId: string;
  modifiedFields: Array<keyof ServiceCaseResponse>;
}

export const UnansweredRequest = () => {
  const [open, setOpen] = useState(false);
  const [serviceCases, setServiceCases] = useState<ServiceCaseResponse[]>([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pageTotal, setPageTotal] = useState(0);
  const [filters, setFilters] = useState<FilterType>({
    type: "Emergency,Scheduled Transport,Information Support,False Alarm",
    origin: "BE App Call,BE App SMS,External Phone",
  });
  const [hasFiltered, setHasFiltered] = useState<FilterType | boolean>(false);

  const handleUnansweredRequest = () => setOpen(!open);

  const makeRequest = useCallback(
    async ({
      page: pageNumber,
      perPage: perPageNumber,
      ...params
    }: Partial<typeof GetServiceCases.QueryParams>) => {
      setPageLoading(true);
      const nextPage = pageNumber === undefined ? 1 : pageNumber + 1;
      const nextPerPage = perPageNumber === undefined ? 10 : perPageNumber;
      const res = await getAllUnansweredServiceCases({
        ...params,
        page: nextPage,
        perPage: nextPerPage,
        type: params.type || undefined,
        origin: params.origin || undefined,
      });
      setServiceCases(res.serviceCases);
      setPage(nextPage - 1);
      setPageTotal(res.total);
      setPageLoading(false);
    },
    []
  );

  const handleFilterUpdate = useCallback(async () => {
    try {
      if (filters.type || filters.origin) {
        await makeRequest({
          page: 0,
          perPage,
          ...filters,
        });
        setHasFiltered(filters);
      }
    } catch {
      setHasFiltered(false);
      setPageLoading(false);
    }
  }, [filters, perPage, makeRequest]);

  const clearFilters = () => {
    setFilters({ type: "", origin: "" });
  };

  const clearNetworkFilter = useCallback(async () => {
    setFilters({
      type: "Emergency,Scheduled Transport,Information Support,False Alarm",
      origin: "BE App Call,BE App SMS,External Phone",
    });
    await makeRequest({
      page: 0,
      perPage,
    });
    setHasFiltered(false);
  }, [perPage, makeRequest]);

  const handleFilters = useCallback((res: FilterType) => {
    setFilters(res);
  }, []);

  const handleChangePage = useCallback(
    (value: number) => {
      (async () => {
        await makeRequest({ ...filters, page: value, perPage });
      })();
    },
    [filters, perPage, makeRequest]
  );

  const handlePerPage = useCallback(
    (value: number) => {
      (async () => {
        await makeRequest({ ...filters, perPage: value, page: 0 });
        setPerPage(value);
      })();
    },
    [filters, makeRequest]
  );

  const handleUpdateServiceCases = useCallback(
    (newData: ServiceCaseResponse[]) => {
      setServiceCases([...newData]);
    },
    []
  );

  useEffect(() => {
    (async () => {
      await makeRequest({
        page: 0,
        perPage: 10,
      });
      setPageLoading(false);
    })();
  }, [makeRequest]);

  const { addHandler, removeHandler } = useSocket("Unanswered request");

  const handleNewServiceCase = useCallback(
    ({ serviceCaseId }: SocketEvent) => {
      (async () => {
        const serviceCase = await getServiceCase(serviceCaseId);

        if (serviceCase.caseStatus === ServiceCaseStatusEnum.UNANSWERED) {
          makeRequest({
            page,
            perPage,
            ...filters,
          });
        }
      })();
    },
    [page, perPage, filters, makeRequest]
  );

  useEffect(() => {
    addHandler(
      SocketEventsEnum.NEW_SERVICE_CASE_NOTIFICATION,
      handleNewServiceCase
    );

    return () => {
      removeHandler(
        SocketEventsEnum.NEW_SERVICE_CASE_NOTIFICATION,
        handleNewServiceCase
      );
    };
  }, [addHandler, removeHandler, handleNewServiceCase]);

  return (
    <>
      <Loader open={pageLoading} />
      {open && (
        <CreateUnansweredServiceRequest
          open={open}
          onClose={handleUnansweredRequest}
        />
      )}
      <section>
        <FulfilmentDashboardLayout.Container>
          <Typography
            color="primary"
            variant="h3"
            component="h1"
            style={{ marginBottom: 30 }}
          >
            Unanswered Service Requests
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Box display="flex" alignItems="center">
              <FilterMenu
                onSave={handleFilterUpdate as any}
                onClearAll={clearFilters}
                onSetFilters={handleFilters}
                initFilters={hasFiltered}
                filters={filters}
              />
              {hasFiltered && (
                <Button
                  color="primary"
                  style={{ marginLeft: 10 }}
                  variant="outlined"
                  onClick={clearNetworkFilter as any}
                >
                  Clear All Filters
                </Button>
              )}
            </Box>
            <div>
              <Button color="primary" onClick={handleUnansweredRequest}>
                New Unanswered Service Request
              </Button>
            </div>
          </Box>
          <div>
            <ServiceRequestTable
              page={page}
              perPage={perPage}
              totalCount={pageTotal}
              handleChangePage={handleChangePage}
              handlePerPage={handlePerPage}
              serviceCases={serviceCases}
              updateServiceCases={handleUpdateServiceCases}
            />
          </div>
        </FulfilmentDashboardLayout.Container>
      </section>
    </>
  );
};
