import React, { ChangeEvent } from "react";
import {
  Box,
  Checkbox,
  TextField,
  Typography,
  FormControlLabel,
} from "@material-ui/core";
import {
  PatientCoverageEnum,
  ServiceCaseResponse,
  ServiceCaseStatusEnum,
} from "@deep-consulting-solutions/be2-constants";
import { formatNameToDisplay } from "helpers";
import MultiSelectWithTags from "component/atoms/Select";
import { Alert } from "@material-ui/lab";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";

interface Option {
  label: string;
  value: string;
}

type RespT = {
  cancelForAllPatients: boolean;
  reasonForCancelling: string;
  patientIds: Option[];
};

interface CancelServiceProps {
  state: RespT;
  errors?: Record<string, any>;
  status: ServiceCaseStatusEnum;
  onChange?: (res: RespT) => void;
  refundStatus: Record<string, boolean>;
  patients?: ServiceCaseResponse["patients"];
  coveredPatients: ServiceCaseResponse["patients"];
  uncoveredPatients: ServiceCaseResponse["patients"];
  cancellablePatients?: ServiceCaseResponse["patients"];
  handleCheckboxChange: (checked: boolean, patientID: string) => void;
}

export const CancelService = ({
  state,
  status,
  errors,
  patients,
  onChange,
  refundStatus,
  coveredPatients,
  uncoveredPatients,
  cancellablePatients,
  handleCheckboxChange,
}: CancelServiceProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const changes = {
      ...state,
      [e.target.name]: e.target.value,
    };
    if (onChange) onChange(changes);
  };

  return (
    <Box padding="16px">
      {status === ServiceCaseStatusEnum.SERVICE_STARTED && (
        <Box
          bgcolor="#FEECEB"
          display="grid"
          gridTemplateColumns="auto 1fr"
          gridGap={10}
          alignItems="center"
          px="16px"
          py="14px"
          mb={3}
          borderRadius="4px"
        >
          <ErrorOutlineOutlinedIcon
            style={{
              color: "#CA2027",
            }}
          />
          <Typography
            variant="subtitle2"
            style={{
              color: "#611c16",
            }}
          >
            Make sure that the patient signed the refusal of treatment document
            and upload it to the service case record attachments in the CRM.
          </Typography>
        </Box>
      )}

      {patients?.length && cancellablePatients?.length ? (
        <>
          {patients.length > 1 && (
            <Box mb={2}>
              <MultiSelectWithTags
                label="For Patients"
                name="patientIds"
                error={errors && errors?.patientIds}
                onChange={handleChange}
                value={state.patientIds}
                options={cancellablePatients.map((el) => ({
                  label: formatNameToDisplay(el?.firstName, el?.lastName),
                  value: el.patientID,
                }))}
              />
            </Box>
          )}

          <Typography
            color="primary"
            style={{
              fontWeight: 500,
              marginBottom: "11px",
            }}
          >
            Reason for Cancellation
          </Typography>

          <TextField
            onChange={handleChange}
            name="reasonForCancelling"
            helperText={errors && errors?.reasonForCancelling}
            error={!!errors?.reasonForCancelling}
            size="small"
            label="Reason"
            required
            value={state.reasonForCancelling}
            multiline
            minRows={3}
          />

          <Box mt={3}>
            {(() => {
              if (patients.length === 1) {
                const [patient] = patients;

                return patient.selectedSubsCoverage?.coverage ===
                  PatientCoverageEnum.COVERED ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        // defaulting to false to prevent initial undefined errors in react.
                        checked={refundStatus[patient.patientID] || false}
                        onChange={(e) => {
                          handleCheckboxChange(
                            e.target.checked,
                            patient.patientID
                          );
                        }}
                      />
                    }
                    label="Refund Patient Credit"
                  />
                ) : (
                  <Alert severity="info">
                    <Typography variant="body2">
                      Refunding the patient should be decided by the Business
                      Owner. He will be Notified.
                    </Typography>
                  </Alert>
                );
              }

              return (
                <>
                  {coveredPatients.map((coveredPatient) => (
                    <FormControlLabel
                      key={coveredPatient.contactID}
                      label={
                        <Typography>
                          Refund Patient{" "}
                          <b>
                            {formatNameToDisplay(
                              coveredPatient.firstName,
                              coveredPatient.lastName
                            )}
                          </b>{" "}
                          Credit
                        </Typography>
                      }
                      control={
                        <Checkbox
                          color="primary"
                          checked={
                            refundStatus[coveredPatient.patientID] || false
                          }
                          onChange={(e) => {
                            handleCheckboxChange(
                              e.target.checked,
                              coveredPatient.patientID
                            );
                          }}
                        />
                      }
                    />
                  ))}

                  {uncoveredPatients.length ? (
                    <Alert severity="info">
                      <Typography variant="body2">
                        Refunding uncovered patients (
                        <b>
                          {uncoveredPatients
                            .map((patient) =>
                              formatNameToDisplay(
                                patient.firstName,
                                patient.lastName
                              )
                            )
                            .join(", ")}
                        </b>
                        ) should be decided by the Business Owner. He will be
                        Notified.
                      </Typography>
                    </Alert>
                  ) : null}
                </>
              );
            })()}
          </Box>
        </>
      ) : (
        <Box padding={1} display="flex" justifyContent="center">
          <Typography color="primary" variant="body2">
            Patient(s) information not gathered from the requester yet.
          </Typography>
        </Box>
      )}
    </Box>
  );
};
