import React, { useMemo, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import {
  Box,
  Collapse,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { ContactRequester } from "component/Dialogs/ContactRequester";
import { format, formatDistance } from "date-fns";
import {
  ServiceCaseCallBackTypeEnum,
  ServiceCaseRequestOriginEnum,
  ServiceCaseRequestTypeEnum,
  ServiceCaseResponse,
} from "@deep-consulting-solutions/be2-constants";
import { formatNameToDisplay } from "helpers";
import { EnhancedTableHead } from "./EnhancedTableHead";

interface Column {
  id:
    | "caseNumber"
    | "requestType"
    | "createdAt"
    | "requestOrigin"
    | "callBackLogs"
    | "contact"
    | "contactNumber";
  label: string;
  minWidth?: number;
  align?: "right" | "left" | "center";
  format?: (value: any) => any;
}

type ServiceCaseResponseT = ServiceCaseResponse & {
  contact: string;
  code: string;
};

const columns: Column[] = [
  {
    id: "caseNumber",
    label: "Ticket number",
    minWidth: 170,
    format: (value: string) => (
      <Typography variant="body2" color="primary">
        {value}
      </Typography>
    ),
  },
  {
    id: "requestType",
    label: "Type",
    minWidth: 100,
    format: (value) =>
      value === ServiceCaseRequestTypeEnum.EMERGENCY ? (
        <span style={{ color: "#D32F2F", fontWeight: 700 }}>{value}</span>
      ) : (
        value
      ),
  },
  {
    id: "createdAt",
    label: "Request made on",
    minWidth: 170,
    align: "left",
    format: (value: string) => (
      <Tooltip
        arrow
        placement="top-end"
        title={format(new Date(value), "dd MMM yyyy pp")}
      >
        <span>
          {formatDistance(new Date(value), new Date(), {
            includeSeconds: true,
            addSuffix: false,
          })}{" "}
          ago
        </span>
      </Tooltip>
    ),
  },
  {
    id: "requestOrigin",
    label: "Request Origin",
    minWidth: 170,
    align: "left",
  },
  {
    id: "callBackLogs",
    label: "Callback Attempts",
    minWidth: 170,
    align: "center",
    format: (v: any) => v?.length || 0,
  },
  {
    id: "contact",
    label: "Contact",
    minWidth: 170,
    align: "left",
  },
  {
    id: "contactNumber",
    label: "Contact Number",
    minWidth: 170,
    align: "right",
  },
];

function createData(
  id: string,
  caseNumber: string,
  requestType: ServiceCaseResponse["requestType"],
  createdAt: any,
  requestOrigin: ServiceCaseResponse["requestOrigin"],
  callBackLogs: ServiceCaseResponse["callBackLogs"],
  contact: any,
  contactNumber: string
): Partial<ServiceCaseResponseT> {
  return {
    id,
    caseNumber,
    requestType,
    createdAt,
    requestOrigin,
    callBackLogs,
    contact,
    contactNumber,
    code: id,
  };
}

const useStyles = makeStyles({
  root: {
    width: "100%",
    "& .MuiTablePagination-input": {
      width: "unset",
      flexShrink: 1,
    },
    "& .MuiToolbar-gutters p:nth-child(2)": {
      color: "#828282",
    },
  },
  container: {
    maxHeight: 440,
    "& .MuiTableCell-root": {
      verticalAlign: "top",
      padding: "11.5px 16px",
    },
  },
  collapseContent: {
    "& > p": {
      paddingBottom: 6,
    },
  },
});

const ServiceRequestRow = ({
  row,
  onRowClick,
}: {
  row: ServiceCaseResponseT;
  onRowClick?: () => void;
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <TableRow hover role="checkbox" tabIndex={-1} onClick={onRowClick}>
      {columns.map((column) => {
        const value = row[column.id];
        if (row.requestOrigin === ServiceCaseRequestOriginEnum.BE_APP_CALL) {
          return (
            <TableCell key={column.id} align={column.align}>
              {column.id === "contact" ? (
                <Collapse
                  collapsedSize={24}
                  in={open}
                  timeout="auto"
                  style={{
                    backgroundColor: open ? "#F6F6F6" : undefined,
                    whiteSpace: "nowrap",
                  }}
                >
                  <Box
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    style={{ paddingTop: open ? 6 : 0 }}
                    className={classes.collapseContent}
                  >
                    <Typography variant="body2">{value}</Typography>
                  </Box>
                </Collapse>
              ) : null}
              {column.id === "contactNumber" ? (
                <Collapse
                  collapsedSize={24}
                  in={open}
                  timeout="auto"
                  style={{
                    backgroundColor: open ? "#F6F6F6" : undefined,
                    whiteSpace: "nowrap",
                  }}
                >
                  <Box
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    display="flex"
                    justifyContent="end"
                    textAlign="left"
                    style={{ paddingTop: open ? 6 : 0 }}
                  >
                    <Box className={classes.collapseContent}>
                      <Typography variant="body2">{value}</Typography>
                    </Box>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpen(!open);
                      }}
                      className={classes.collapseContent}
                      style={{
                        height: 20,
                        width: 20,
                        marginLeft: 10,
                        display: "none",
                      }}
                    >
                      {open ? (
                        <ChevronLeft
                          style={{
                            transform: open ? "rotate(-90deg)" : "rotate(0deg)",
                          }}
                        />
                      ) : (
                        <ChevronLeft />
                      )}
                    </IconButton>
                  </Box>
                </Collapse>
              ) : null}
              {!["contactNumber", "contact"].includes(column.id) && (
                <div style={{ minHeight: 40 }}>
                  {column.format ? column.format(value) : value}
                </div>
              )}
            </TableCell>
          );
        }
        return (
          <TableCell key={column.id} align={column.align}>
            <Box
              textAlign={column.id === "contactNumber" && "right"}
              style={{
                minHeight: 40,
              }}
            >
              {column.format ? column.format(value) : value}
            </Box>
          </TableCell>
        );
      })}
    </TableRow>
  );
};

function descendingComparator(a: any, b: any, orderBy: any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: string, orderBy: string) {
  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: T[],
  comparator: (order: string, orderBy: string) => number
) {
  const stabilizedThis = array.map((el: T, index) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface ServiceRequestTableProps {
  serviceCases: ServiceCaseResponse[];
  page: number;
  perPage: number;
  totalCount: number;
  handleChangePage: (page: number) => void;
  handlePerPage: (page: number) => void;
  updateServiceCases: (data: ServiceCaseResponse[]) => void;
}

export const ServiceRequestTable = ({
  serviceCases,
  page,
  perPage,
  totalCount,
  handleChangePage,
  handlePerPage,
  updateServiceCases,
}: ServiceRequestTableProps) => {
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState("caseNumber");

  const tableRef = useRef<HTMLTableElement>(null);
  const classes = useStyles();
  const [activeIdx, setActiveIdx] = useState(0);
  const [contactRequesterData, setContactRequesterData] = useState<
    Partial<ServiceCaseResponse> | false
  >(false);

  const setPage = (event: unknown, newPage: number) => {
    if (tableRef.current) tableRef.current.scrollIntoView();
    handleChangePage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (tableRef.current) tableRef.current.scrollIntoView();
    handlePerPage(+event.target.value);
  };

  const handleClose = () => {
    setContactRequesterData(false);
  };

  const rows = useMemo(
    () =>
      serviceCases.map((el) => {
        const callBackLogs = el.callBackLogs.filter(
          (r) =>
            r.callBackType === ServiceCaseCallBackTypeEnum.UNANSWERED_REQUEST
        );
        return createData(
          el.id,
          el.caseNumber,
          el.requestType,
          el.createdAt,
          el.requestOrigin,
          callBackLogs,
          el.requester ? (
            <>
              {formatNameToDisplay(
                el.requester?.firstName,
                el.requester?.lastName
              )}{" "}
              <span style={{ color: "#828282" }}>(Main Beneficiary)</span>
            </>
          ) : (
            <span style={{ color: "#828282" }}>(Unknown)</span>
          ),
          el.requester?.phone || "--"
        );
      }),
    [serviceCases]
  );

  const handleRowClick = (pos: number) => () => {
    setActiveIdx(pos);
    setContactRequesterData(rows[pos]);
  };

  const handleUpdateState = (key: string, value: any) => {
    if (key === "callBackLogs") {
      const copyCases = [...serviceCases];
      const record = { ...copyCases[activeIdx] };
      record.callBackLogs = [...record.callBackLogs, value];
      setContactRequesterData(record);
      copyCases[activeIdx] = record;
      updateServiceCases(copyCases);
    }
    if (key === "record") {
      const copyCases = [...serviceCases];
      copyCases[activeIdx] = value;
      updateServiceCases(copyCases);
    }
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Paper className={classes.root}>
      <ContactRequester
        data={contactRequesterData as ServiceCaseResponse}
        onClose={handleClose}
        onUpdate={handleUpdateState}
      />
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" innerRef={tableRef}>
          <EnhancedTableHead
            columns={columns}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {rows.length
              ? stableSort(rows, getComparator(order, orderBy)).map(
                  (row, i) => {
                    return (
                      <ServiceRequestRow
                        onRowClick={handleRowClick(i)}
                        row={row as ServiceCaseResponseT}
                        key={`${i.toString()}`}
                      />
                    );
                  }
                )
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={perPage}
        page={page}
        onPageChange={setPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
