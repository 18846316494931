import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// eslint-disable-next-line import/no-named-default
import { default as MPopover } from "@material-ui/core/Popover";
import { PopoverProps } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  button: {},
}));

interface Props extends Omit<PopoverProps, "open"> {
  content: ({ handleClose }: { handleClose: (e: any) => void }) => any;
  button: any;
  buttonClassName?: string;
  handleClick?: (e: React.MouseEvent) => void;
}

export const Popover = ({
  content,
  button,
  anchorOrigin,
  transformOrigin,
  buttonClassName,
  handleClick,
}: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>();

  const handleClickBtnClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
    if (handleClick) handleClick(event);
  };

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <button
        type="button"
        className={clsx(classes.button, buttonClassName)}
        onClick={handleClickBtnClick}
      >
        {button}
      </button>
      <MPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
          ...anchorOrigin,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
          ...transformOrigin,
        }}
      >
        {content({ handleClose })}
      </MPopover>
    </div>
  );
};
