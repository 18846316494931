import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { NotificationsNone } from "@material-ui/icons";
import { IconButton, makeStyles, styled, Typography } from "@material-ui/core";
import clsx from "clsx";
import NotificationSnippet from "component/AppNotifications/NotificationSnippet";
import { ContactRequester } from "component/Dialogs";
import {
  ServiceCaseRequestTypeEnum,
  SocketEventsEnum,
  ServiceCaseResponse,
} from "@deep-consulting-solutions/be2-constants";
import { SnackbarKey, useSnackbar } from "notistack";
import { UnansweredServiceRequestI } from "layout/types";
import { useAppDispatch, useAppSelector } from "redux/store";
import { notificationActions } from "redux/notifications";
import { useOnClickOutside } from "hooks/outsideClick";
import { useCall } from "call";
import { useElementWithin } from "hooks/useElementOnScreen";
import { openNotificationRequest } from "./request";
import Loader from "../Loader";
import SiteContext from "../../context";

const useStyles = makeStyles(() => ({
  icon: {
    position: "relative",
    height: 27,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  hasNotify: {
    position: "absolute",
    right: 1,
    top: 6,
    backgroundColor: "#D32F2F",
    width: 11,
    height: 11,
    borderRadius: 5.5,
    boxShadow: "0 0 0 1px white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const NotificationList = styled("div")({
  backgroundColor: "white",
  position: "absolute",
  width: "400px",
  right: "0",
  top: "72px",
});

export const AppNotifications = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    visible: showNotifications,
    setVisible: setShowNotifications,
    ref: notifRef,
  } = useOnClickOutside(false);
  const { viewedCall } = useCall();

  const { socket } = useContext(SiteContext);
  const { zohoID } = useAppSelector((state) => state.dispatcher);
  const { notifications } = useAppSelector((state) => state.notifications);

  const [isOpen, setIsOpening] = useState(false);
  const [requesterData, setRequesterData] =
    useState<ServiceCaseResponse | null>(null);

  const classes = useStyles();

  const { setNotificationToRead, setNotifications, updateNotifications } =
    notificationActions;

  const dispatch = useAppDispatch();

  const unread = useMemo(
    () => notifications.slice(0, 100).filter((el) => el?.isNew),
    [notifications]
  );

  const handleOpen = useCallback(
    (s_id: string, id: string, key?: SnackbarKey) => () => {
      (async () => {
        try {
          setIsOpening(true);
          const res = await openNotificationRequest(zohoID, s_id);
          dispatch(setNotificationToRead({ serviceCaseDetailsId: s_id }));
          setIsOpening(false);
          setShowNotifications(false);
          setRequesterData(res.data.data as unknown as ServiceCaseResponse);
          if (key) {
            closeSnackbar(key);
          }
        } catch (e) {
          setIsOpening(false);
        }
      })();
    },
    [
      closeSnackbar,
      dispatch,
      setNotificationToRead,
      setShowNotifications,
      zohoID,
    ]
  );

  const toggleNotifications = useCallback(() => {
    if (!isOpen) setShowNotifications(!showNotifications);
  }, [isOpen, showNotifications, setShowNotifications]);

  const updateModal = useCallback(
    (key: string, value: ServiceCaseResponse) => {
      const copyData: any = { ...(requesterData as ServiceCaseResponse) };
      if (key === "callBackLogs") {
        copyData[key] = [...copyData[key], value];
        setRequesterData(copyData as ServiceCaseResponse);
      } else if (key === "record") {
        setRequesterData(value);
        dispatch(
          updateNotifications(
            notifications.filter((el) => el.serviceCaseDetails.id !== value.id)
          )
        );
      } else if (key === "close-case") {
        dispatch(
          updateNotifications(
            notifications.filter((el) => el.serviceCaseDetails.id !== value.id)
          )
        );
      }
    },
    [dispatch, notifications, requesterData, updateNotifications]
  );

  const handleNotificationEvent = useCallback(
    (data: UnansweredServiceRequestI) => {
      dispatch(setNotifications({ ...data, isNew: true }));
    },
    [dispatch, setNotifications]
  );

  const [more, setLoadMore] = useState(17);

  const { target: snippetRef } = useElementWithin(() => {
    if (more < notifications.length) {
      setLoadMore(more + 10);
    }
  }, [notifications.length]);

  useEffect(() => {
    if (!showNotifications) setLoadMore(17);
  }, [showNotifications]);

  useEffect(() => {
    socket.on(
      SocketEventsEnum.CALL_UNANSWERED_REMINDER,
      (data: UnansweredServiceRequestI) => {
        handleNotificationEvent(data);

        const notify = (id: string) => {
          if (id) {
            enqueueSnackbar(id, {
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
              preventDuplicate: true,
              content: (key) => {
                return (
                  <div id={id}>
                    <NotificationSnippet
                      onClose={() => closeSnackbar(key)}
                      onOpen={handleOpen(data.serviceCaseDetails.id, id, key)}
                      standAlone
                      id={data.serviceCaseDetails.caseNumber}
                      timeInText={data.serviceCaseDetails.timeInText}
                    />
                  </div>
                );
              },
            });
          }
        };

        if (!viewedCall) {
          notify(zohoID);
        } else {
          if (
            viewedCall?.requestType === ServiceCaseRequestTypeEnum.EMERGENCY
          ) {
            return;
          }
          if (
            viewedCall?.requestType ===
              ServiceCaseRequestTypeEnum.SCHEDULED_TRANSPORT &&
            data.requestType === ServiceCaseRequestTypeEnum.EMERGENCY
          ) {
            notify(zohoID);
          }
        }
      }
    );
    // eslint-disable-next-line
  }, [zohoID, viewedCall]);

  return (
    <div>
      {requesterData && (
        <ContactRequester
          data={requesterData}
          onClose={() => setRequesterData(null)}
          onUpdate={updateModal}
        />
      )}
      <div ref={notifRef}>
        <IconButton onClick={toggleNotifications}>
          <div className={classes.icon}>
            {!!notifications?.length && (
              <div className={clsx(notifications.length && classes.hasNotify)}>
                <p style={{ fontSize: 6, color: "white", fontWeight: 600 }}>
                  {unread.length > 90 ? "90+" : unread.length || ""}
                </p>
              </div>
            )}
            <NotificationsNone style={{ fontSize: 24, color: "#0000008a" }} />
          </div>
        </IconButton>
        {showNotifications && !!notifications?.length && (
          <NotificationList>
            <Typography
              color="primary"
              style={{ padding: "12px 16px", fontWeight: 500 }}
            >
              Notifications
            </Typography>
            <div
              style={{
                borderBottom: "1px solid #E0E0E0",
                maxHeight: 645,
                overflow: "auto",
              }}
            >
              <Loader absolute open={isOpen} />
              {notifications?.slice(0, more).map((el, i) => (
                <div
                  ref={
                    notifications.length > 10 && more - 1 === i
                      ? snippetRef
                      : undefined
                  }
                >
                  <NotificationSnippet
                    id={el.serviceCaseDetails.caseNumber}
                    key={`${el.serviceCaseDetails.id}--${i.toString()}`}
                    timeInText={el.serviceCaseDetails.timeInText}
                    onOpen={handleOpen(el.serviceCaseDetails.id, zohoID)}
                    isNew={el?.isNew}
                  />
                </div>
              ))}
            </div>
          </NotificationList>
        )}
      </div>
    </div>
  );
};
