import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Typography, makeStyles, Button } from "@material-ui/core";
import {
  ServiceCaseAccessLinkResponse,
  ServiceCaseStatusEnum,
} from "@deep-consulting-solutions/be2-constants";

import { serviceCaseRequests } from "redux/serviceCase";
import Loader from "component/Loader";

import { CaseClassificationsContext } from "context/CaseClassificationProvider";
import { AccessLink } from "./AccessLink";
import { GenerateDialog } from "./GenerateDialog/GenerateDialog";
import { DeactivateDialog } from "./DeactivateDialog";
import {
  updateAccessLinks,
  FormValues,
  composeGenerateAccessLinkBody,
} from "./helpers";

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  wrapper: {
    border: `1px solid ${p.grey[300]}`,
    borderRadius: s(1),
    padding: s(1),
    flex: 1,
    overflowY: "auto",
  },
  title: {
    fontWeight: 600,
    color: p.primary.main,
    fontSize: 24,
    marginBottom: s(2),
  },
  cards: {},
  actions: {
    marginTop: s(2),
    textAlign: "right",
    marginBottom: s(2),
  },
  generateBtn: {
    color: p.primary.dark,
  },
}));

export const AccessLinksTab: React.FC = () => {
  const { state: serviceCase } = useContext(CaseClassificationsContext);
  const [accessLinks, setAccessLinks] = useState<
    ServiceCaseAccessLinkResponse[]
  >([]);
  const [loading, setLoading] = useState(false);

  const isEditable = useMemo(() => {
    if (!serviceCase?.caseStatus) return false;

    if (serviceCase.caseStatus === ServiceCaseStatusEnum.CLOSED) {
      return serviceCase.accessLinksEnabledWhenClosed;
    }

    return [
      ServiceCaseStatusEnum.PENDING_PLANNING,
      ServiceCaseStatusEnum.SERVICE_SCHEDULED,
      ServiceCaseStatusEnum.SERVICE_STARTED,
      ServiceCaseStatusEnum.PATIENT_RELEASED,
      ServiceCaseStatusEnum.COMPLETED,
      ServiceCaseStatusEnum.CANCELLED,
      ServiceCaseStatusEnum.RE_OPENED,
    ].includes(serviceCase.caseStatus);
  }, [serviceCase?.caseStatus, serviceCase?.accessLinksEnabledWhenClosed]);

  useEffect(() => {
    (async () => {
      if (!serviceCase?.id) return;
      if (!isEditable) return;

      try {
        setLoading(true);

        const res = await serviceCaseRequests.fetchAccessLinksForServiceCase(
          serviceCase.id
        );
        setAccessLinks(res);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    })();
  }, [isEditable, serviceCase?.id, serviceCase?.caseStatus]);

  const handleActiveToggle = useCallback(
    async (accessLinkID: string, isActive: boolean) => {
      if (!serviceCase?.id) return;
      try {
        setLoading(true);
        const res = await serviceCaseRequests.updateAccessLinkActiveStatus(
          serviceCase.id,
          accessLinkID,
          isActive
        );
        setAccessLinks((links) => updateAccessLinks(links, res));
        setLoading(false);
      } catch {
        setLoading(false);
      }
    },
    [serviceCase?.id]
  );

  // Generate Access Link
  const [showGenerateDialog, setShowGenerateDialog] = useState(false);
  const handleGenerateDialogToggle = useCallback(() => {
    setShowGenerateDialog((s) => !s);
  }, []);

  const handleGenerateClick = useCallback(
    async (formValues: FormValues) => {
      if (!serviceCase?.id) return false;
      try {
        const res = await serviceCaseRequests.addAccessLinkForServiceCase(
          composeGenerateAccessLinkBody(serviceCase.id, formValues)
        );
        setAccessLinks((current) => {
          return [...current, res];
        });
        return true;
      } catch {
        return false;
      }
    },
    [serviceCase?.id]
  );

  // Deactivate:
  const [accessLinkToBeDeactivated, setAccessLinkToBeDeactivated] =
    useState<ServiceCaseAccessLinkResponse | null>(null);

  const handleDeactiveDialogOpen = useCallback(
    (accessLink: ServiceCaseAccessLinkResponse) => {
      setAccessLinkToBeDeactivated(accessLink);
    },
    []
  );

  const handleDeactivateDialogClose = useCallback(() => {
    setAccessLinkToBeDeactivated(null);
  }, []);

  const handleAccessLinkDeactivate = useCallback(
    async (accessLinkID: string) => {
      if (!serviceCase?.id) return false;
      try {
        const res = await serviceCaseRequests.permanentlyDeactivateAccessLink(
          serviceCase.id,
          accessLinkID
        );
        setAccessLinks((links) => updateAccessLinks(links, res));
        return true;
      } catch {
        return false;
      }
    },
    [serviceCase?.id]
  );

  const handleAccessLinkResend = useCallback(async (accessLinkID: string) => {
    try {
      setLoading(true);

      const res = await serviceCaseRequests.resendAccessLink(accessLinkID);
      setAccessLinks((links) => updateAccessLinks(links, res));
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, []);

  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Loader open={loading} />
      <Typography className={classes.title}>
        Service Case Access Links
      </Typography>

      <div className={classes.cards}>
        {accessLinks.map((accessLink) => {
          return (
            <AccessLink
              key={accessLink.ID}
              accessLink={accessLink}
              onActiveToggle={handleActiveToggle}
              onDeactivateClick={handleDeactiveDialogOpen}
              onResendClick={handleAccessLinkResend}
              disabled={!isEditable}
            />
          );
        })}
      </div>

      <div className={classes.actions}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.generateBtn}
          onClick={handleGenerateDialogToggle}
          disabled={!isEditable}
        >
          Generate Access Link
        </Button>
      </div>

      <GenerateDialog
        open={showGenerateDialog}
        onClose={handleGenerateDialogToggle}
        onGenerateClick={handleGenerateClick}
      />

      <DeactivateDialog
        accessLink={accessLinkToBeDeactivated}
        onClose={handleDeactivateDialogClose}
        onDeactivateClick={handleAccessLinkDeactivate}
      />
    </div>
  );
};
