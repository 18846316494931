import * as Yup from "yup";
import { Alert } from "@material-ui/lab";
import { Close } from "@material-ui/icons";
import { getGeocode } from "use-places-autocomplete";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import React, { useCallback, useMemo, useState } from "react";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import { Field, Formik, FormikConfig, FormikProps } from "formik";
import {
  Hospital,
  HospitalTypeEnum,
} from "@deep-consulting-solutions/be2-constants";
import {
  Box,
  Button,
  Dialog,
  MenuItem,
  Typography,
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import Loader from "component/Loader";
import { hospitalRequests } from "redux/hospital";
import { Marker, ReactMap } from "component/ReactMap";
import { FormikStreetField } from "component/FormikStreetField";
import { environmentVariables } from "configs/environmentVariables";
import { AddressFormFields } from "component/Forms/AddressFormFields";
import {
  MapErrorComponent,
  MapLoadingComponent,
} from "call/components/CallMap";
import {
  VALIDATIONS,
  getCountryCode,
  getLongAddressObject,
  getShortAddressObject,
} from "helpers";

import { mapStyle } from "../helpers";

interface NewMedicalFacilityDialogProps {
  inputValue?: string;
  onClose: () => void;
  onCreate: (hospital: Hospital) => void;
}

interface NewMedicalFacilityFormValues {
  city: string;
  type: string;
  street: string;
  country: string;
  hasHelipad: boolean;
  facilityName: string;
  islandOrStateOrRegion: string;
  poBoxOrZipOrPostalCode: string;
  coords: google.maps.LatLngLiteral;
}

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  root: {
    minHeight: 775,
  },
  dialogActions: {
    padding: s(2),
    display: "flex",
    marginLeft: "auto",
    justifyContent: "space-between",
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F7F7F7",
    justifyContent: "space-between",
  },
  formFields: {
    width: 255,
    padding: "24px 24px 24px 0",
    boxShadow: "inset -2px 0px 0px rgba(0, 0, 0, 0.12)",

    "& fieldset": {
      borderColor: p.primary.main,
    },

    "& > *": {
      marginBottom: s(3),
    },
  },
  addressFields: {
    "& > :not(:last-child)": {
      marginBottom: s(3),
    },
  },
  dialogContent: {
    display: "flex",
  },
  mapBox: {
    flexGrow: 1,
    padding: s(2),
    paddingRight: 0,
  },
  streetWrapper: {
    width: "480px",

    "& .MuiFormHelperText-contained": {
      marginLeft: 0,
    },
  },
  alert: {
    marginTop: s(2),
    marginBottom: s(2),
    backgroundColor: p.primary.main,
  },
  map: {
    height: 514,
    width: "100%",
    marginTop: s(2),
    borderRadius: 4,
    background: "white",
  },
}));

const validationSchema = Yup.object<NewMedicalFacilityFormValues>({
  coords: Yup.object()
    .shape({
      lat: Yup.number().required("Please select a location"),
      lng: Yup.number().required("Please select a location"),
    })
    .required(),
  city: Yup.string().required("City is required"),
  type: Yup.mixed<HospitalTypeEnum>()
    .oneOf(Object.values(HospitalTypeEnum))
    .required("Type is required"),
  street: VALIDATIONS.street,
  country: VALIDATIONS.country,
  hasHelipad: Yup.boolean(),
  poBoxOrZipOrPostalCode: VALIDATIONS.poBox,
  facilityName: Yup.string().required("Facility name is required"),
  islandOrStateOrRegion: Yup.string()
    .required()
    .when("country", {
      is: "United States",
      then: VALIDATIONS.state,
      otherwise: (schema: Yup.StringSchema) =>
        schema.when("country", {
          is: "Bahamas",
          then: VALIDATIONS.island,
          otherwise: VALIDATIONS.region,
        }),
    }),
});

export const NewMedicalFacilityDialog = ({
  onClose,
  onCreate,
  inputValue = "",
}: NewMedicalFacilityDialogProps) => {
  const classes = useStyles();
  const [zoom, setZoom] = useState(8);
  const [loading, setLoading] = useState(false);
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 24.4229317,
    lng: -78.2103076, // Bahamas
  });

  const initialValues = useMemo(() => {
    return {
      type: "",
      city: "",
      street: "",
      hasHelipad: false,
      country: "Bahamas",
      facilityName: inputValue,
      islandOrStateOrRegion: "",
      poBoxOrZipOrPostalCode: "",
      coords: {
        lat: 0,
        lng: 0,
      },
    };
  }, [inputValue]);

  const handleSubmit: FormikConfig<NewMedicalFacilityFormValues>["onSubmit"] =
    useCallback(
      async (values) => {
        setLoading(true);

        try {
          const latLngString = `${values.coords.lat},${values.coords.lng}`;

          const res = await hospitalRequests.createHospitalReq({
            city: values.city,
            street: values.street,
            location: latLngString,
            country: values.country,
            name: values.facilityName,
            hasHelipad: values.hasHelipad,
            state: values.islandOrStateOrRegion,
            pobox: values.poBoxOrZipOrPostalCode,
            type: values.type as HospitalTypeEnum,
          });

          onCreate(res);
        } catch {
          //
        } finally {
          setLoading(false);
        }
      },
      [onCreate]
    );

  const handleMapClick = useCallback(
    (
      { latLng }: google.maps.MapMouseEvent,
      { setFieldValue }: FormikProps<NewMedicalFacilityFormValues>
    ) => {
      (async () => {
        const [object] = await getGeocode({ location: latLng });

        const longAddress = getLongAddressObject(object.address_components);
        const shortAddress = getShortAddressObject(object.address_components);

        const street = `${longAddress.street_number || ""}${
          longAddress.route
            ? `${longAddress.street_number ? " " : ""}${longAddress.route}`
            : ""
        }`;
        setFieldValue("street", street || "Unnamed road");

        setFieldValue(
          "city",
          longAddress.locality ||
            longAddress.administrative_area_level_3 ||
            longAddress.postal_town ||
            ""
        );

        const islandValue =
          shortAddress.country === "GB"
            ? longAddress.administrative_area_level_2 ||
              longAddress.administrative_area_level_1
            : longAddress.administrative_area_level_1 ||
              longAddress.administrative_area_level_2;

        setFieldValue("islandOrStateOrRegion", islandValue || "");

        if (shortAddress.country !== "BS") {
          setFieldValue(
            "poBoxOrZipOrPostalCode",
            longAddress.postal_code || ""
          );
        }

        setFieldValue("country", shortAddress.country || "");
        setFieldValue("coords", {
          lat: latLng!.lat(),
          lng: latLng!.lng(),
        });
      })();
    },
    []
  );

  const onMapIdle = useCallback((m: google.maps.Map) => {
    setZoom(m.getZoom()!);
    setCenter(m.getCenter()!.toJSON());
  }, []);

  const handleSetValue = useCallback((result: google.maps.GeocoderResult) => {
    const lat = result.geometry.location.lat();
    const lng = result.geometry.location.lng();

    setCenter({ lat, lng });
    setZoom((currentZoom) => Math.max(currentZoom, 14));
  }, []);

  return (
    <Formik<NewMedicalFacilityFormValues>
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {(props) => {
        return (
          <Dialog
            open
            fullWidth
            maxWidth="lg"
            onClose={onClose}
            className={classes.root}
          >
            <Loader open={loading || props.isSubmitting} absolute />

            <DialogTitle disableTypography className={classes.dialogTitle}>
              <Typography
                component="h2"
                color="primary"
                variant="subtitle1"
                style={{ fontWeight: 500 }}
              >
                Create Medical Facility
              </Typography>

              <Close
                onClick={onClose}
                style={{ color: "#BDBDBD", cursor: "pointer" }}
              />
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
              <Box className={classes.formFields}>
                <Field
                  required
                  name="facilityName"
                  component={TextField}
                  label="Facility Name"
                />

                <AddressFormFields
                  formikProps={props}
                  rootClassName={classes.addressFields}
                />

                <Field
                  select
                  required
                  name="type"
                  label="Type"
                  component={TextField}
                  SelectProps={{ displayEmpty: true }}
                >
                  {Object.values(HospitalTypeEnum).map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Field>

                <Field
                  type="checkbox"
                  color="primary"
                  name="hasHelipad"
                  component={CheckboxWithLabel}
                  Label={{ label: "Has A Helipad" }}
                />
              </Box>

              <Box className={classes.mapBox}>
                <Box className={classes.streetWrapper}>
                  <FormikStreetField
                    formikProps={props}
                    onSet={handleSetValue}
                    islandName="islandOrStateOrRegion"
                    label="Search for medical facility"
                    helperText="Search is restricted to the Country and the boundaries of Island or City provided"
                    country={getCountryCode(props.values.country) || undefined}
                  />
                </Box>

                {props.touched.street && props.errors.street && (
                  <Alert
                    severity="info"
                    variant="filled"
                    className={classes.alert}
                  >
                    You can mark the location on the map if you can&apos;t find
                    it using search.
                  </Alert>
                )}

                <Wrapper
                  libraries={["places"]}
                  apiKey={environmentVariables.REACT_APP_GOOGLE_MAP_API_KEY}
                  render={(status: Status) =>
                    status === Status.FAILURE ? (
                      <MapErrorComponent />
                    ) : (
                      <MapLoadingComponent />
                    )
                  }
                >
                  <ReactMap
                    zoom={zoom}
                    center={center}
                    styles={mapStyle}
                    onIdle={onMapIdle}
                    mapTypeControl={false}
                    id="medical-facility-map"
                    streetViewControl={false}
                    mapClassName={classes.map}
                    onClick={(e) => handleMapClick(e, props)}
                  >
                    <Marker
                      draggable
                      position={props.values.coords}
                      onDragEnd={(event: google.maps.MapMouseEvent) =>
                        handleMapClick(event, props)
                      }
                    />
                  </ReactMap>
                </Wrapper>
              </Box>
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
              <Box>
                <Button
                  color="primary"
                  onClick={onClose}
                  variant="outlined"
                  style={{ marginRight: "16px" }}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  disabled={!props.isValid || props.isSubmitting}
                  onClick={() => {
                    props.submitForm();
                  }}
                >
                  Create
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};
