import {
  DispatcherActivityLogTypeEnum,
  GetDispatcherActivityLogRecords,
  SortOrderEnum,
  GetDispatcherActivityLogsSortByEnum,
  DispatcherActivityLog,
  PostDispatcherLateCheckinManagementAlert,
  PostDispatcherActivityLog,
} from "@deep-consulting-solutions/be2-constants";
import { stringify } from "query-string";

import { crmClient } from "apis";

export const getLastSystemActionReq = async (
  dispatcherId: string
): Promise<DispatcherActivityLog | null> => {
  const types = Object.values(DispatcherActivityLogTypeEnum).filter(
    (v) => v !== DispatcherActivityLogTypeEnum.FAILED_CHECK_IN
  );
  const params: typeof GetDispatcherActivityLogRecords["Params"] = {
    page: 1,
    perPage: 1,
    types: types.join(","),
    sortBy: GetDispatcherActivityLogsSortByEnum.ENTRY_TIME,
    order: SortOrderEnum.DESC,
  };
  const res = await crmClient.get<
    typeof GetDispatcherActivityLogRecords["Res"]
  >(
    `${GetDispatcherActivityLogRecords.ROUTE.replace(
      ":id",
      dispatcherId
    )}?${stringify(params)}`
  );

  return res.data.data.logEntries[0] || null;
};

export const reportLateCheckinReq = async (
  dispatcherId: string,
  triggerTime: string
) => {
  const body: typeof PostDispatcherLateCheckinManagementAlert["Body"] = {
    triggerTime,
  };
  const res = await crmClient.post<
    typeof PostDispatcherLateCheckinManagementAlert["Res"]
  >(
    PostDispatcherLateCheckinManagementAlert.ROUTE.replace(":id", dispatcherId),
    body
  );
  return res.data.data;
};

export const logCheckinReq = async (
  dispatcherId: string,
  body: typeof PostDispatcherActivityLog["Body"]
) => {
  const res = await crmClient.post<typeof PostDispatcherActivityLog["Res"]>(
    PostDispatcherActivityLog.ROUTE.replace(":id", dispatcherId),
    body
  );
  return res.data.data;
};
