import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";

const useStyles = makeStyles(({ spacing: s }) => ({
  container: {
    position: "absolute",
    top: 0,
    right: 0,
    padding: s(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    paddingRight: s(0),
  },
}));

export const CallNotificationWrapper: FC = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.container}>{children}</div>;
};
