import { SubscriptionStatusEnum } from "@deep-consulting-solutions/be2-constants";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";

interface StyleProps {
  status?: SubscriptionStatusEnum;
}

const getBackgroundColor = (status?: SubscriptionStatusEnum) => {
  if (!status) {
    return "";
  }
  const colors: Record<SubscriptionStatusEnum, string> = {
    [SubscriptionStatusEnum.ACTIVE]: "#08A40F",
    [SubscriptionStatusEnum.SUSPENDED]: "#CA2027",
    [SubscriptionStatusEnum.CANCELLED]: "#CA2027",
    [SubscriptionStatusEnum.PENDING_ACTIVATION]: "#ED6C02",
    [SubscriptionStatusEnum.PENDING_VALIDATION]: "#808080",
    [SubscriptionStatusEnum.VALIDATING]: "#808080",
    [SubscriptionStatusEnum.VALID]: "#808080",
    [SubscriptionStatusEnum.DRAFT]: "#808080",
    [SubscriptionStatusEnum.PENDING_COMPLETION]: "#808080",
    [SubscriptionStatusEnum.ABANDONED]: "#CA2027",
    [SubscriptionStatusEnum.INVALID]: "#CA2027",
    [SubscriptionStatusEnum.REJECTED]: "#CA2027",
  };

  return colors[status];
};

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  text: {
    fontWeight: 500,
    color: "white",
    fontSize: 10,
    textAlign: "center",
    display: "block",
  },
  wrapper: ({ status }) => ({
    background: getBackgroundColor(status),
    borderRadius: 8,
    padding: "0 14px",
    margin: "0 auto",
    display: "block",
  }),
}));

interface StatusProps {
  value?: SubscriptionStatusEnum;
}

export const Status = ({ value }: StatusProps) => {
  const classes = useStyles({ status: value });
  return (
    <div className={classes.wrapper}>
      <Typography align="center" className={classes.text} variant="caption">
        {value}
      </Typography>
    </div>
  );
};
