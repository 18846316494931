import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  UserRoleEnum,
  ServiceTypesEnum,
  ServiceCaseResponse,
  ServiceCaseStatusEnum,
} from "@deep-consulting-solutions/be2-constants";

import { useCall } from "call";
import { CaseClassificationsContext } from "context/CaseClassificationProvider";
import { serviceCaseRequests } from "redux/serviceCase";

import { MovePatientsToNewCaseDialog } from "call/components/CallForms/ServiceForm/MovePatientsToNewCase/MovePatientsToNewCaseDialog";
import { PaymentDialog } from "call/components/CallForms/ServiceForm/components";
import { patchPatientSubCoverageService } from "redux/call/requests";
import Loader from "component/Loader";
import { TabsEnum } from "call/components/CallForms/types";
import { useZohoCurrentUser } from "hooks";
import { ReviewPanel } from "./ReviewPanel";
import { PatientPhysician } from "./PatientPhysician";
import { Patient } from "./Patient";
import { RefuseDialog } from "./RefuseDialog";
import { AcceptDialog } from "./AcceptDialog";

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  wrapper: {
    paddingBottom: s(3),
  },
  divider: {
    marginTop: s(3),
    marginBottom: s(3),
  },
  movePatientWrapper: {
    textAlign: "right",
  },
  movePatientBtn: {
    color: p.primary.dark,
  },
  service: {
    display: "flex",
    alignItems: "center",
  },
  serviceKey: {
    fontSize: 16,
    color: p.primary.main,
    marginRight: s(1),
  },
  serviceValue: {
    fontSize: 18,
    fontWeight: 600,
    color: p.primary.main,
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: "#263E7F",
    marginBottom: s(3),
  },
  medTitle: {
    color: p.primary.main,
    fontSize: 14,
    fontWeight: 600,
    marginBottom: s(1),
  },
  medText: {
    fontSize: 14,
    color: p.grey[600],
  },
  actions: {
    display: "flex",
    alignItems: "center",
    marginBottom: s(2),
  },
  backBtn: {
    marginRight: "auto",
  },
  refuseBtn: {
    marginRight: s(1),
    backgroundColor: "#ba3330",
    color: p.common.white,
    "&:hover": {
      backgroundColor: "#ba3330",
    },
  },
  acceptBtn: {
    backgroundColor: "#4aa132",
    color: p.common.white,
    "&:hover": {
      backgroundColor: "#4aa132",
    },
  },
}));

interface AcceptanceByBEManagerProps {
  handleEnableTab: (tab: TabsEnum) => void;
  setCases: React.Dispatch<React.SetStateAction<ServiceCaseResponse[]>>;
}

export const AcceptanceByBEManager = (props: AcceptanceByBEManagerProps) => {
  const { setCases, handleEnableTab } = props;
  const { state: serviceCase, updateState } = useContext(
    CaseClassificationsContext
  );

  const [
    { subscriptionForPayment, subscriptionContact },
    setSubscriptionForPayment,
  ] = useState({ subscriptionForPayment: "", subscriptionContact: "" });
  const [loading, setLoading] = useState(false);

  const latestApprovalRequest = useMemo(() => {
    let latest: ServiceCaseResponse["approvalRequests"][0] | null = null;
    serviceCase?.approvalRequests?.forEach((r) => {
      if (!latest || r.createdAt > latest.createdAt) {
        latest = r;
      }
    });
    return latest as ServiceCaseResponse["approvalRequests"][0] | null;
  }, [serviceCase]);

  // Refuse:
  const [refuseOpen, setRefuseOpen] = useState(false);

  const handleRefuseToggle = useCallback(() => {
    setRefuseOpen((o) => !o);
  }, []);

  const handleRefuseSubmit = useCallback(
    async (reason: string) => {
      if (!latestApprovalRequest) return false;
      try {
        const res = await serviceCaseRequests.refuseApprovalRequestForMD(
          latestApprovalRequest.id,
          reason
        );
        updateState(res);
        return true;
      } catch {
        return false;
      }
    },
    [latestApprovalRequest, updateState]
  );

  // Accept:
  const [acceptOpen, setAcceptOpen] = useState(false);

  const handleAcceptToggle = useCallback(() => {
    setAcceptOpen((o) => !o);
  }, []);

  const patients = useMemo(
    () => serviceCase?.patients || [],
    [serviceCase?.patients]
  );

  const [patientsValues, setPatientsValues] = useState(
    patients.map((patient) => ({
      patientID: patient.patientID,
      ...(patient.selectedSubCoverageID
        ? { coverageID: patient.selectedSubCoverageID }
        : {}),
    }))
  );

  const { currentUser } = useZohoCurrentUser();

  const canAcceptOrRefuseCase = useMemo(() => {
    return [
      UserRoleEnum.Manager,
      UserRoleEnum.Administrator,
      UserRoleEnum.BusinessOwner,
    ].includes(currentUser?.profile.name as UserRoleEnum);
  }, [currentUser]);

  const handleAcceptSubmit = useCallback(
    async (remarks: string) => {
      if (!latestApprovalRequest) return false;
      try {
        const res = await serviceCaseRequests.acceptApprovalRequestForMD(
          latestApprovalRequest.id,
          {
            remarks,
            patients: patientsValues,
          }
        );
        updateState(res);
        handleEnableTab(TabsEnum.PLANNING);
        return true;
      } catch {
        return false;
      }
    },
    [latestApprovalRequest, updateState, patientsValues, handleEnableTab]
  );

  const serviceRef = React.useRef<ServiceTypesEnum | null>(null);

  const fetchCoverageDetails = useCallback(async () => {
    if (!serviceRef.current) {
      serviceRef.current = serviceCase?.service || null;
      return;
    }

    if (serviceRef.current === serviceCase?.service) return;

    try {
      setLoading(true);

      if (serviceCase?.service && serviceCase?.id) {
        const res = await patchPatientSubCoverageService(
          serviceCase.id,
          serviceCase.service
        );

        updateState(res);
        serviceRef.current = serviceCase?.service;
      }
    } catch {
      //
    } finally {
      setLoading(false);
    }
  }, [serviceCase?.service, serviceCase?.id, updateState]);

  const handlePaymentSuccess = useCallback(async () => {
    await fetchCoverageDetails();
  }, [fetchCoverageDetails]);

  // Move Patient
  const [showMovePatient, setShowMovePatient] = useState(false);

  const handleMovePatientToggle = useCallback(() => {
    setShowMovePatient((s) => !s);
  }, []);

  const hasMoreThanOnePatients = useMemo(() => {
    return patients.length > 1;
  }, [patients]);

  const handleClosePaymentDialog = useCallback(() => {
    setSubscriptionForPayment({
      subscriptionForPayment: "",
      subscriptionContact: "",
    });
  }, []);

  const handleOpenPaymentDialog = useCallback(
    (values: {
      subscriptionForPayment: string;
      subscriptionContact: string;
    }) => {
      setSubscriptionForPayment(values);
    },
    []
  );

  const handlePatientCoverageChange = useCallback(
    (patientID: string, coverageID = "") => {
      setPatientsValues((current) =>
        current.map((c) => {
          if (c.patientID === patientID) {
            return {
              patientID,
              coverageID,
            };
          }

          return c;
        })
      );
    },
    []
  );

  useEffect(() => {
    fetchCoverageDetails();
  }, [fetchCoverageDetails]);

  const classes = useStyles();

  const { viewedCall } = useCall();

  const disableForm = useMemo(() => {
    return (
      !!viewedCall?.disableForms ||
      serviceCase?.caseStatus !== ServiceCaseStatusEnum.PENDING_BE_ACCEPTANCE
    );
  }, [serviceCase?.caseStatus, viewedCall?.disableForms]);

  return (
    <div className={classes.wrapper}>
      <Loader open={loading} />

      <ReviewPanel
        approvalRequest={latestApprovalRequest}
        canAcceptOrRefuseCase={canAcceptOrRefuseCase}
      />

      {hasMoreThanOnePatients && (
        <>
          <div className={classes.divider}>
            <Divider />
          </div>
          <div className={classes.movePatientWrapper}>
            <Button
              color="primary"
              variant="outlined"
              className={classes.movePatientBtn}
              onClick={handleMovePatientToggle}
            >
              MOVE PATIENTS TO NEW CASE
            </Button>
          </div>
        </>
      )}
      <div className={classes.divider}>
        <Divider />
      </div>
      <div className={classes.service}>
        <Typography className={classes.serviceKey}>
          Services to be Rendered:
        </Typography>
        <Typography className={classes.serviceValue}>
          {serviceCase?.service ?? "-"}
        </Typography>
      </div>
      <div className={classes.divider}>
        <Divider />
      </div>
      <Typography className={classes.sectionTitle}>
        Patient&apos;s Physician
      </Typography>
      <PatientPhysician />
      <div className={classes.divider}>
        <Divider />
      </div>
      <div className={classes.service}>
        <Typography className={classes.serviceKey}>
          Medical Director:
        </Typography>
        <Typography className={classes.serviceValue}>
          {serviceCase?.medicalDirector?.zohoUser.name ?? "_"}
        </Typography>
      </div>
      <div className={classes.divider}>
        <Divider />
      </div>
      <Typography className={classes.sectionTitle}>
        Patient&apos;s Medical Information
      </Typography>
      {(serviceCase?.patients || []).map((p) => {
        return (
          <Patient
            key={p.contactID}
            patient={p}
            serviceCase={serviceCase}
            disableForm={disableForm}
            handleOpenPaymentDialog={handleOpenPaymentDialog}
            handlePatientCoverageChange={handlePatientCoverageChange}
            patientValue={patientsValues.find(
              ({ patientID }) => patientID === p.patientID
            )}
            service={
              serviceCase?.service || ("" as unknown as ServiceTypesEnum)
            }
          />
        );
      })}
      <div className={classes.divider}>
        <Divider />
      </div>
      <Typography className={classes.sectionTitle}>
        Medical Professionals
      </Typography>
      <Grid container>
        <Grid item xs={6}>
          <Typography className={classes.medTitle}>
            Number and Specialty of Medical Professionals Required
          </Typography>
          <Typography className={classes.medText}>
            {serviceCase?.noAndSpecialityOfMedicalProfessionals || "-"}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.medTitle}>
            Recommended Medical Professionals
          </Typography>
          <Typography className={classes.medText}>
            {serviceCase?.recommendedMedicalProfessionals || "-"}
          </Typography>
        </Grid>
      </Grid>

      <div className={classes.divider}>
        <Divider />
      </div>

      <div className={classes.actions}>
        <Button color="primary" variant="outlined" className={classes.backBtn}>
          BACK
        </Button>

        {canAcceptOrRefuseCase && (
          <>
            <Button className={classes.refuseBtn} onClick={handleRefuseToggle}>
              Refuse Case
            </Button>

            <Button className={classes.acceptBtn} onClick={handleAcceptToggle}>
              Accept Case
            </Button>
          </>
        )}
      </div>

      <RefuseDialog
        open={refuseOpen}
        onClose={handleRefuseToggle}
        onSubmit={handleRefuseSubmit}
      />

      <AcceptDialog
        open={acceptOpen}
        onClose={handleAcceptToggle}
        onSubmit={handleAcceptSubmit}
      />

      <MovePatientsToNewCaseDialog
        setCases={setCases}
        open={showMovePatient}
        handleClose={handleMovePatientToggle}
      />

      <PaymentDialog
        goBack={handleClosePaymentDialog}
        subscriptionId={subscriptionForPayment}
        contactID={subscriptionContact}
        handleSuccess={handlePaymentSuccess}
      />
    </div>
  );
};
