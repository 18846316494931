import React, {
  useCallback,
  useState,
  MouseEventHandler,
  useMemo,
} from "react";
import { Box, Button, Divider, Grid, makeStyles } from "@material-ui/core";
import { FormikProvider, useFormik, Field } from "formik";
import { TextField } from "formik-material-ui";
import { SelectField } from "component/atoms";
import DCSPhoneInput from "component/atoms/DCSPhoneInput";
import {
  ServiceCaseRequestTypeEnum,
  ServiceCaseStatusEnum,
} from "@deep-consulting-solutions/be2-constants";
import { validatePhoneNumberWithCountryCode } from "@deep-consulting-solutions/dcs-web-ui";
import * as yup from "yup";
import { notifications } from "services";
import { patchInitialAssessment } from "redux/call/requests";
import { getENText } from "helpers";
import { useCall } from "call/hooks";
import Loader from "component/Loader";
import { FalseAlarmDialog } from "./FalseAlarmDialog";
import { TabsEnum } from "../types";

const requestTypeOptions = Object.values(ServiceCaseRequestTypeEnum);

const useStyles = makeStyles(({ spacing: s }) => ({
  divider: {
    margin: s(2, 0),
  },
  falseAlarm: {
    background: "rgba(202, 32, 39, 1)",
    color: "white",
    "&:hover": {
      background: "rgba(202, 32, 39, 0.8)",
    },
  },
  wrapper: {
    paddingTop: s(2),
  },
}));

interface Props {
  switchTab: (tab: TabsEnum) => void;
}

export const initialAssessmentValidationSchema = yup.object({
  situationDescription: yup
    .string()
    .required(getENText("initialAssessmentForm.situationDescription.required")),
  requestType: yup
    .string()
    .required(getENText("initialAssessmentForm.requestType.required")),
  contactNumber: yup
    .string()
    .required(getENText("initialAssessmentForm.contactNumber.required"))
    .test(
      "check validity",
      getENText("initialAssessmentForm.contactNumber.invalid"),
      validatePhoneNumberWithCountryCode
    ),
  callBackNumber: yup
    .string()
    .test(
      "check validity",
      getENText("initialAssessmentForm.callBackNumber.invalid"),
      validatePhoneNumberWithCountryCode
    ),
});

export const InitialAssessmentForm = ({ switchTab }: Props) => {
  const { viewedCall, updateCallRequesterInitialAssessment } = useCall();
  const [showFalseAlarmDialog, setShowFalseAlarmDialog] = useState(false);

  const handleOpenFalseAlarmDialog: MouseEventHandler<HTMLButtonElement> =
    useCallback((e) => {
      e.preventDefault();
      setShowFalseAlarmDialog(true);
    }, []);

  const handleCloseFalseAlarmDialog = useCallback(() => {
    setShowFalseAlarmDialog(false);
  }, []);

  const formik = useFormik({
    initialValues: {
      contactNumber:
        viewedCall?.updatedServiceCase?.requester?.phone ||
        viewedCall?.updatedServiceCase?.contactNumber ||
        "",
      situationDescription:
        viewedCall?.updatedServiceCase?.situationDescription || "",
      callBackNumber: viewedCall?.updatedServiceCase?.callbackPhone,
      requestType: (viewedCall?.updatedServiceCase?.requestType ||
        "") as ServiceCaseRequestTypeEnum,
    },
    enableReinitialize: true,
    validationSchema: initialAssessmentValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const res = await patchInitialAssessment(
          viewedCall!.updatedServiceCase!.id,
          values
        );

        updateCallRequesterInitialAssessment(viewedCall!.sessionId, res);

        switchTab(TabsEnum.REQUESTER_AND_PATIENT);
        notifications.notifySuccess(
          getENText("initialAssessmentForm.successful")
        );
      } catch (error) {
        //
      } finally {
        setSubmitting(false);
      }
    },
  });

  const classes = useStyles();

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      formik.handleSubmit();
    },
    [formik]
  );

  const disableForm = useMemo(
    () =>
      !!viewedCall?.disableForms ||
      viewedCall?.updatedServiceCase?.caseStatus !==
        ServiceCaseStatusEnum.GATHERING_REQUIREMENTS,
    [viewedCall?.disableForms, viewedCall?.updatedServiceCase?.caseStatus]
  );

  return (
    <div className={classes.wrapper}>
      <Loader open={formik.isSubmitting} />
      <FormikProvider value={formik}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field
                size="small"
                component={DCSPhoneInput}
                name="contactNumber"
                label="Contact Number"
                required
                disabled={disableForm}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                size="small"
                component={DCSPhoneInput}
                name="callBackNumber"
                label="Call Back Number"
                required
                disabled={disableForm}
              />
            </Grid>
          </Grid>

          <Divider className={classes.divider} />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field
                size="small"
                component={TextField}
                name="situationDescription"
                label="Situation Description"
                multiline
                required
                disabled={disableForm}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectField
                size="small"
                name="requestType"
                label="Request Type"
                required
                options={requestTypeOptions}
                disabled={disableForm}
              />
            </Grid>
          </Grid>

          <Box
            mt={6}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            width="100%"
          >
            {formik.values.requestType ===
            ServiceCaseRequestTypeEnum.FALSE_ALARM ? (
              <Button
                color="primary"
                type="submit"
                onClick={handleOpenFalseAlarmDialog}
                className={classes.falseAlarm}
                disabled={disableForm}
              >
                CLOSE CASE
              </Button>
            ) : (
              <Button
                color="primary"
                type="submit"
                onClick={handleSubmit}
                disabled={disableForm || !formik.isValid}
              >
                NEXT
              </Button>
            )}
          </Box>
        </form>
      </FormikProvider>
      <FalseAlarmDialog
        open={showFalseAlarmDialog}
        handleClose={handleCloseFalseAlarmDialog}
      />
    </div>
  );
};
