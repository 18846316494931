import React, { useMemo } from "react";
import { ServiceCaseResponse } from "@deep-consulting-solutions/be2-constants";
import { useCall } from "call/hooks";
import { ServiceCaseAccepted } from "./ServiceCaseAccepted";
import { ServiceCaseRefused } from "./ServiceCaseRefused";
import {
  getIsServiceCaseApproved,
  getIsServiceCaseRefused,
  getIsServiceCaseWaiting,
} from "../helpers";
import { ServiceCaseAwaiting } from "./ServiceCaseAwaiting";
import { TabsEnum } from "../../types";

interface Props {
  serviceCase?: ServiceCaseResponse;
  handleDisableTab: (tab: TabsEnum) => void;
}

export const ServiceStatus = ({ serviceCase, handleDisableTab }: Props) => {
  const { viewedCall } = useCall();

  const isAwaiting = useMemo(() => {
    return getIsServiceCaseWaiting(
      viewedCall?.updatedServiceCase?.approvalRequests
    );
  }, [viewedCall?.updatedServiceCase?.approvalRequests]);

  const isServiceCaseAccepted = useMemo(() => {
    return getIsServiceCaseApproved(
      viewedCall?.updatedServiceCase?.approvalRequests
    );
  }, [viewedCall?.updatedServiceCase?.approvalRequests]);

  const isServiceCaseRefused = useMemo(() => {
    return getIsServiceCaseRefused(
      viewedCall?.updatedServiceCase?.approvalRequests
    );
  }, [viewedCall?.updatedServiceCase?.approvalRequests]);

  if (isAwaiting) {
    return (
      <ServiceCaseAwaiting
        serviceCase={serviceCase}
        handleDisableTab={handleDisableTab}
      />
    );
  }

  if (isServiceCaseAccepted) {
    return <ServiceCaseAccepted />;
  }

  if (isServiceCaseRefused) {
    return <ServiceCaseRefused />;
  }

  return null;
};
