import { useState, useEffect } from "react";

interface MarkerProps extends google.maps.MarkerOptions {
  onDragEnd?: (event: google.maps.MapMouseEvent) => void;
  infoWindowContent?: string;
}

export const Marker = ({
  onDragEnd,
  infoWindowContent,
  ...options
}: MarkerProps) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      const newMarker = new google.maps.Marker();

      if (onDragEnd) {
        newMarker.addListener("dragend", onDragEnd);
      }

      setMarker(newMarker);
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
        google.maps.event.clearListeners(marker, "dragend");
      }
    };
  }, [marker, onDragEnd]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  useEffect(() => {
    if (marker && infoWindowContent) {
      const infoWindow = new google.maps.InfoWindow({
        content: infoWindowContent,
      });

      marker.addListener("click", () => {
        infoWindow.open(marker.getMap(), marker);
      });
    }
  }, [marker, infoWindowContent]);

  return null;
};
