import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { LocationSummary, LocationSummaryProps } from "./LocationSummary";

interface LocationInfoWindowProps extends LocationSummaryProps {
  title: string;
}

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 10,
    color: "#00000099",
  },
}));

export const LocationInfoWindow = ({
  title,
  ...props
}: LocationInfoWindowProps) => {
  const classes = useStyles();

  return (
    <Box maxWidth={232}>
      <Typography variant="subtitle2" className={classes.title}>
        {title}
      </Typography>
      <LocationSummary {...props} />
    </Box>
  );
};
