import { Box, Button, makeStyles } from "@material-ui/core";

interface BarProps {
  active: boolean;
}

const useBarStyle = makeStyles(({ palette: p }) => ({
  bar: ({ active }: BarProps) => ({
    height: "12px",
    backgroundColor: active ? p.primary.main : "#C4C4C4",
    width: "3px",
    borderRadius: "1px",
  }),
}));

export const AudioBar: React.FC<BarProps> = ({ active }) => {
  const classes = useBarStyle({ active });

  return <div className={classes.bar} />;
};

interface VisualizerProps {
  buttonText: string;
  buttonIcon: React.ReactNode;
  isActive: boolean;
  disableButton?: boolean;
  onButtonClick: () => void;
  data: number[];
}

export const AudioVisualizer: React.FC<VisualizerProps> = ({
  data,
  buttonIcon,
  buttonText,
  isActive,
  disableButton,
  onButtonClick,
}) => {
  const array = Array.from(Array(32).keys());

  return (
    <Box mt={2} ml={8} display="flex" alignItems="center">
      <Button
        variant={isActive ? "text" : "outlined"}
        color="primary"
        size="small"
        startIcon={buttonIcon}
        onClick={onButtonClick}
        style={{ textTransform: "capitalize" }}
        disabled={disableButton}
      >
        {buttonText}
      </Button>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        ml={2}
        flex={1}
      >
        {array.map((_, key) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <AudioBar key={key.toString()} active={isActive && data[key] > 0} />
          );
        })}
      </Box>
    </Box>
  );
};
