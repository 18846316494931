import React, { useEffect } from "react";
import {
  UserRoleEnum,
  ServiceCaseResponse,
  ServiceCaseStatusEnum,
} from "@deep-consulting-solutions/be2-constants";

import { useCall } from "call/hooks";
import { useZohoCurrentUser } from "hooks";
import { useAppSelector } from "redux/store";
import { dispatcherSelectors } from "redux/dispatcher";
import { ProgressRendering } from "pages/FulfilmentDashboard/Views/CaseClassifications/Tabs/Details/Tabs";

import { CallFormTabProps, TabsEnum } from "./types";
import { InitialAssessmentForm } from "./InitialAssessmentForm";
import { RequesterAndPatientForm } from "./RequesterAndPatientForm";
import { LocationForm } from "./LocationForm";
import { ServiceForm } from "./ServiceForm";
import { PlanningForm } from "./PlanningForm";
import { AssessmentForm } from "./AssessmentForm";
import { numberToTabsEnum } from "./helpers";

interface Props extends CallFormTabProps {
  index: number;
  value: number;
  label: TabsEnum;
  skippedTabs: TabsEnum[];
  switchTab: (tab: TabsEnum) => void;
  handleSkipTab: (tab: TabsEnum) => void;
  handleEnableTab: (tab: TabsEnum) => void;
  handleIncludeTab: (tab: TabsEnum) => void;
  handleDisableTab: (tab: TabsEnum) => void;
  setCases: React.Dispatch<React.SetStateAction<ServiceCaseResponse[]>>;
}
export const CallFormsTabPanel = ({
  value,
  index,
  id,
  label,
  switchTab,
  skippedTabs,
  handleSkipTab,
  handleEnableTab,
  handleIncludeTab,
  handleDisableTab,
  setCases,
  ...props
}: Props) => {
  const { viewedCall } = useCall();
  const dispatcherId = useAppSelector(dispatcherSelectors.id);
  const { currentUser } = useZohoCurrentUser();
  const medicalDirectorID = useAppSelector(
    dispatcherSelectors.medicalDirectorID
  );

  useEffect(() => {
    const isReadyForAssessment =
      !!viewedCall?.updatedServiceCase?.medicalDirector?.id;

    if (isReadyForAssessment) {
      const hasWrongRole = !(
        currentUser?.profile.name === UserRoleEnum.Administrator ||
        !!medicalDirectorID
      );

      const hasWrongStatus =
        viewedCall?.updatedServiceCase?.caseStatus !==
        ServiceCaseStatusEnum.PENDING_MEDICAL_ASSESSMENT;

      const hasWrongMedicalDirector =
        viewedCall?.updatedServiceCase?.medicalDirector?.id !==
        medicalDirectorID;

      if (hasWrongRole || hasWrongStatus || hasWrongMedicalDirector) {
        if (numberToTabsEnum(value) === TabsEnum.ASSESSMENT) {
          switchTab(TabsEnum.SERVICE);
        }

        handleSkipTab(TabsEnum.ASSESSMENT);
      } else {
        handleIncludeTab(TabsEnum.ASSESSMENT);
      }
    }
  }, [
    value,
    switchTab,
    dispatcherId,
    handleSkipTab,
    handleIncludeTab,
    medicalDirectorID,
    currentUser?.profile.name,
    viewedCall?.updatedServiceCase?.caseStatus,
    viewedCall?.updatedServiceCase?.medicalDirector?.id,
  ]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={props["aria-controls"]}
      aria-labelledby={id}
    >
      <>
        {label === TabsEnum.INITIAL_ASSESSMENT && (
          <InitialAssessmentForm switchTab={switchTab} />
        )}
        {label === TabsEnum.REQUESTER_AND_PATIENT && (
          <RequesterAndPatientForm
            activeTab={numberToTabsEnum(value)}
            switchTab={switchTab}
          />
        )}
        {label === TabsEnum.LOCATION && (
          <LocationForm
            switchTab={switchTab}
            activeTab={numberToTabsEnum(value)}
          />
        )}
        {label === TabsEnum.SERVICE && (
          <ServiceForm
            setCases={setCases}
            switchTab={switchTab}
            skippedTabs={skippedTabs}
            activeTab={numberToTabsEnum(value)}
            handleSkipTab={handleSkipTab}
            handleEnableTab={handleEnableTab}
            handleIncludeTab={handleIncludeTab}
            handleDisableTab={handleDisableTab}
          />
        )}
        {label === TabsEnum.ASSESSMENT && (
          <AssessmentForm
            switchTab={switchTab}
            activeTab={numberToTabsEnum(value)}
          />
        )}
        {label === TabsEnum.PLANNING && (
          <PlanningForm
            switchTab={switchTab}
            activeTab={numberToTabsEnum(value)}
          />
        )}
        {label === TabsEnum.RENDERING && value === index && (
          <ProgressRendering />
        )}
      </>
    </div>
  );
};
