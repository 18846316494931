import { useCall } from "call/hooks";
import { useFormikContext } from "formik";
import React, { useMemo, useState, useCallback } from "react";
import Loader from "component/Loader";
import { patchPatientSubCoverageService } from "redux/call/requests";
import { ServiceCaseStatusEnum } from "@deep-consulting-solutions/be2-constants";
import { PatientCard } from "./PatientCard";
import { ServiceTitle } from "../components";
import { FormValues } from "../types";
import { PaymentDialog } from "../components/PaymentDialog";
import {
  getIsServiceCaseApproved,
  getIsServiceCaseRefused,
  getIsServiceToBeRenderedEmergencyAirliftEvacuationOrHospitalTransferOrRepatriationOrCustom,
  getIsServiceToBeRenderedEmergencyGroundTransportOrEmergencyMaritimeTransport,
} from "../helpers";

export const Patients = () => {
  const { viewedCall, updateCallServiceCase } = useCall();
  const [loading, setLoading] = useState(false);
  const {
    values: { serviceToBeRendered, patients: formPatients },
  } = useFormikContext<FormValues>();
  const [
    { subscriptionForPayment, subscriptionContact },
    setSubscriptionForPayment,
  ] = useState({ subscriptionForPayment: "", subscriptionContact: "" });

  const { patients } = useMemo(() => {
    return {
      patients: viewedCall?.updatedServiceCase?.patients || [],
    };
  }, [viewedCall?.updatedServiceCase?.patients]);

  const visible = useMemo(
    () =>
      !!serviceToBeRendered &&
      patients?.length &&
      (getIsServiceToBeRenderedEmergencyGroundTransportOrEmergencyMaritimeTransport(
        serviceToBeRendered
      ) ||
        getIsServiceToBeRenderedEmergencyAirliftEvacuationOrHospitalTransferOrRepatriationOrCustom(
          serviceToBeRendered
        )),
    [serviceToBeRendered, patients?.length]
  );

  const isServiceCaseAccepted = useMemo(() => {
    return getIsServiceCaseApproved(
      viewedCall?.updatedServiceCase?.approvalRequests
    );
  }, [viewedCall?.updatedServiceCase?.approvalRequests]);

  const isServiceCaseRefused = useMemo(() => {
    return getIsServiceCaseRefused(
      viewedCall?.updatedServiceCase?.approvalRequests
    );
  }, [viewedCall?.updatedServiceCase?.approvalRequests]);

  const handleClosePaymentDialog = useCallback(() => {
    setSubscriptionForPayment({
      subscriptionForPayment: "",
      subscriptionContact: "",
    });
  }, []);

  const handlePaymentSuccess = useCallback(async () => {
    try {
      setLoading(true);
      if (serviceToBeRendered && viewedCall?.updatedServiceCase?.id) {
        const res = await patchPatientSubCoverageService(
          viewedCall.updatedServiceCase.id,
          serviceToBeRendered
        );
        updateCallServiceCase(viewedCall.sessionId, res);
      }
    } catch {
      //
    } finally {
      setLoading(false);
    }
  }, [
    serviceToBeRendered,
    viewedCall?.sessionId,
    updateCallServiceCase,
    viewedCall?.updatedServiceCase?.id,
  ]);

  const handleOpenPaymentDialog = useCallback(
    (values: {
      subscriptionForPayment: string;
      subscriptionContact: string;
    }) => {
      setSubscriptionForPayment(values);
    },
    []
  );

  const disableForm = useMemo(
    () =>
      !!viewedCall?.disableForms ||
      viewedCall?.updatedServiceCase?.caseStatus !==
        ServiceCaseStatusEnum.GATHERING_REQUIREMENTS,
    [viewedCall?.disableForms, viewedCall?.updatedServiceCase?.caseStatus]
  );

  if (!visible || isServiceCaseAccepted || isServiceCaseRefused) {
    return null;
  }

  return (
    <>
      <ServiceTitle title="Patients" />
      <Loader open={loading} />
      {patients.map((patient, index) =>
        patient && formPatients[index] ? (
          <PatientCard
            key={patient.email}
            handleOpenPaymentDialog={handleOpenPaymentDialog}
            patient={patient}
            formKey={`patients.${index}`}
            patientValue={formPatients[index]}
            disableForm={disableForm}
          />
        ) : null
      )}
      <PaymentDialog
        goBack={handleClosePaymentDialog}
        subscriptionId={subscriptionForPayment}
        contactID={subscriptionContact}
        handleSuccess={handlePaymentSuccess}
      />
    </>
  );
};
