import {
  ServiceTypesEnum,
  PatientCoverageEnum,
  ServiceCaseResponse,
  InformationSupportReasonEnum,
  ServiceCaseApprovalRequestStatusEnum,
  ServiceTypesWithoutMortalRemainsEnum,
  ServiceCaseRequesterPatientRelationEnum,
} from "@deep-consulting-solutions/be2-constants";
import { notifications } from "services";
import { validatePhoneNumberWithCountryCode } from "@deep-consulting-solutions/dcs-web-ui";
import { getENText } from "helpers";
import * as yup from "yup";
import { FormikErrors } from "formik";
import { FormValues, PhysicianFormValues } from "./types";

export const getIsServiceToBeRenderedEmergencyGroundTransportOrEmergencyMaritimeTransport =
  (serviceToBeRendered: ServiceTypesEnum) => {
    return [
      ServiceTypesEnum.EMERGENCY_GROUND_TRANSPORT,
      ServiceTypesEnum.EMERGENCY_MARITIME_TRANSPORT,
    ].includes(serviceToBeRendered);
  };

export const geIsAllPatientsCovered = (patients: FormValues["patients"]) => {
  if (!patients?.length) {
    return true;
  }

  return patients.every(
    (patient) => patient.coverageStatus === PatientCoverageEnum.COVERED
  );
};

export const getShouldGoToPayment = (
  serviceToBeRendered: ServiceTypesEnum,
  patients: FormValues["patients"]
) => {
  return (
    !!serviceToBeRendered &&
    !geIsAllPatientsCovered(patients) &&
    getIsServiceToBeRenderedEmergencyGroundTransportOrEmergencyMaritimeTransport(
      serviceToBeRendered
    )
  );
};
export const getIsServiceToBeRenderedEmergencyAirliftEvacuationOrHospitalTransferOrRepatriationOrCustom =
  (serviceToBeRendered: ServiceTypesEnum) => {
    return [
      ServiceTypesEnum.EMERGENCY_AIRLIFT_EVACUATION,
      ServiceTypesEnum.HOSPITAL_TRANSFER,
      ServiceTypesEnum.REPATRIATION,
      ServiceTypesEnum.CUSTOM,
    ].includes(serviceToBeRendered);
  };

export const getIsServiceToBeRenderedCustom = (
  serviceToBeRendered: ServiceTypesEnum
) => {
  return [ServiceTypesEnum.CUSTOM].includes(serviceToBeRendered);
};

export const getIsServiceToBeRenderedInformationalSupport = (
  serviceToBeRendered: ServiceTypesEnum
) => {
  return [ServiceTypesEnum.INFORMATIONAL_SUPPORT].includes(serviceToBeRendered);
};

export const getIsServiceToBeRenderedEmergencyAirliftEvacuation = (
  serviceToBeRendered: ServiceTypesEnum
) => {
  return [ServiceTypesEnum.EMERGENCY_AIRLIFT_EVACUATION].includes(
    serviceToBeRendered
  );
};

type ButtonText =
  | "START PLANNING"
  | "SEND FOR MEDICAL ASSESSMENT"
  | "SEND FOR APPROVAL"
  | "NEXT";

export const getButtonText = (
  serviceToBeRendered: ServiceTypesEnum,
  isServiceCaseApproved: boolean,
  isServiceCaseAccepted: boolean,
  isAllPatientsCovered: boolean
): ButtonText => {
  if (
    (isServiceCaseApproved || isServiceCaseAccepted) &&
    isAllPatientsCovered
  ) {
    return "START PLANNING";
  }

  if (
    (isServiceCaseApproved || isServiceCaseAccepted) &&
    !isAllPatientsCovered
  ) {
    return "NEXT";
  }

  if (
    getIsServiceToBeRenderedEmergencyAirliftEvacuationOrHospitalTransferOrRepatriationOrCustom(
      serviceToBeRendered
    )
  ) {
    return "SEND FOR MEDICAL ASSESSMENT";
  }

  if (
    getIsServiceToBeRenderedEmergencyGroundTransportOrEmergencyMaritimeTransport(
      serviceToBeRendered
    )
  ) {
    return "START PLANNING";
  }

  return "NEXT";
};

export const getIsServiceCaseApproved = (
  approvalRequests?: ServiceCaseResponse["approvalRequests"]
) => {
  return !!approvalRequests?.some(
    (approvalRequest) =>
      approvalRequest.status === ServiceCaseApprovalRequestStatusEnum.APPROVED
  );
};

export const getIsServiceCaseRefused = (
  approvalRequests?: ServiceCaseResponse["approvalRequests"]
) => {
  return !!approvalRequests?.some(
    (approvalRequest) =>
      approvalRequest.status === ServiceCaseApprovalRequestStatusEnum.REJECTED
  );
};

export const getIsServiceCaseWaiting = (
  approvalRequests?: ServiceCaseResponse["approvalRequests"]
) => {
  return !!approvalRequests?.some(
    (approvalRequest) =>
      approvalRequest.status ===
        ServiceCaseApprovalRequestStatusEnum.PENDING_MANAGER_APPROVAL ||
      approvalRequest.status ===
        ServiceCaseApprovalRequestStatusEnum.PENDING_MEDICAL_DIRECTOR_APPROVAL
  );
};

export const getAwaitServiceCaseApprovalRequest = (
  approvalRequests?: ServiceCaseResponse["approvalRequests"]
) => {
  return approvalRequests?.find(
    (approvalRequest) =>
      approvalRequest.status ===
        ServiceCaseApprovalRequestStatusEnum.PENDING_MANAGER_APPROVAL ||
      approvalRequest.status ===
        ServiceCaseApprovalRequestStatusEnum.PENDING_MEDICAL_DIRECTOR_APPROVAL
  );
};

export const getIsRequesterPhysician = (
  patients: ServiceCaseResponse["patients"] | undefined
) => {
  if (!patients?.length) {
    return false;
  }
  return patients.some(
    (patient) =>
      patient.requesterRelationToPatient ===
      ServiceCaseRequesterPatientRelationEnum.PHYSICIAN
  );
};

export const getInitialValues = (
  serviceCase?: ServiceCaseResponse
): FormValues => {
  return {
    serviceToBeRendered: (serviceCase?.service || "") as ServiceTypesEnum,
    customService: serviceCase?.customService || "",
    informationSupportReason: (serviceCase?.informationSupportReason ||
      "") as InformationSupportReasonEnum,
    informationSupportExplanation: "",
    physician: {
      firstName: serviceCase?.requestingPhysician?.firstName || "",
      lastName: serviceCase?.requestingPhysician?.lastName || "",
      email: serviceCase?.requestingPhysician?.email || "",
      phone: serviceCase?.requestingPhysician?.phone || "",
      isContacted: true,
      isRequester: getIsRequesterPhysician(serviceCase?.patients),
    },
    medicalDirectorId: serviceCase?.medicalDirector?.id || "",
    patients:
      serviceCase?.patients.map((patient) => ({
        id: patient.patientID || "",
        medicalStatus: patient.medicalStatus || "",
        subCoverageID:
          patient.coverageDetails?.coverageID ||
          patient.selectedSubsCoverage?.id ||
          patient.selectedSubCoverageID ||
          "",
        coverageStatus: (patient.coverageDetails?.coverageStatus ||
          "") as PatientCoverageEnum,
        isBEMember: !!patient.isMemberOrCustomer,
      })) || [],
  };
};
export const checkIsRequesterAndIsContacted = (
  isContacted: boolean,
  isRequester: boolean
) => {
  if (isRequester) {
    return true;
  }

  if (isContacted) {
    return true;
  }

  return false;
};

const physicianValidation = yup.object().shape({
  firstName: yup.string().when(["isContacted", "isRequester"], {
    is: checkIsRequesterAndIsContacted,
    then: yup.string().required(getENText("validation.firstName.required")),
  }),
  lastName: yup.string().when(["isContacted", "isRequester"], {
    is: checkIsRequesterAndIsContacted,
    then: yup.string().required(getENText("validation.lastName.required")),
  }),
  phone: yup.string().when(["isContacted", "isRequester"], {
    is: checkIsRequesterAndIsContacted,
    then: yup
      .string()
      .test(
        "check validity",
        getENText("validation.phone.invalid"),
        validatePhoneNumberWithCountryCode
      )
      .required(getENText("validation.phone.required")),
  }),
  email: yup.string().nullable().email(getENText("validation.email.invalid")),
  isContacted: yup.boolean(),
});

export const validationSchema = yup.object({
  serviceToBeRendered: yup
    .string()
    .oneOf(
      Object.values(ServiceTypesWithoutMortalRemainsEnum),
      getENText("serviceForm.serviceToBeRendered.invalid")
    )
    .required(getENText("serviceForm.serviceToBeRendered.required")),
  customService: yup.string().when(["serviceToBeRendered", "physician"], {
    is: (
      serviceToBeRendered: ServiceTypesEnum,
      physician: PhysicianFormValues
    ) =>
      serviceToBeRendered === ServiceTypesEnum.CUSTOM &&
      !!physician.isContacted,
    then: yup
      .string()
      .required(getENText("serviceForm.customService.required")),
  }),
  informationSupportReason: yup.string().when("serviceToBeRendered", {
    is: ServiceTypesEnum.INFORMATIONAL_SUPPORT,
    then: yup
      .string()
      .oneOf(
        Object.values(InformationSupportReasonEnum),
        getENText("serviceForm.informationSupportReason.invalid")
      )
      .required(getENText("serviceForm.informationSupportReason.required")),
    otherwise: yup.string().nullable(),
  }),
  informationSupportExplanation: yup.string().when("serviceToBeRendered", {
    is: ServiceTypesEnum.INFORMATIONAL_SUPPORT,
    then: yup.string().when("informationSupportReason", {
      is: InformationSupportReasonEnum.OTHER,
      then: yup
        .string()
        .required(
          getENText("serviceForm.informationSupportExplanation.required")
        ),
      otherwise: yup.string().nullable(),
    }),
    otherwise: yup.string().nullable(),
  }),
  physician: yup.object().when("serviceToBeRendered", {
    is: (val: ServiceTypesEnum) =>
      getIsServiceToBeRenderedEmergencyAirliftEvacuationOrHospitalTransferOrRepatriationOrCustom(
        val
      ),
    then: physicianValidation,
  }),
  medicalDirectorId: yup.string().when("serviceToBeRendered", {
    is: getIsServiceToBeRenderedEmergencyAirliftEvacuationOrHospitalTransferOrRepatriationOrCustom,
    then: yup
      .string()
      .required(getENText("serviceForm.medicalDirector.required")),
  }),
  patients: yup
    .array()
    .when("serviceToBeRendered", {
      is: (serviceToBeRendered: ServiceTypesEnum) => {
        return getIsServiceToBeRenderedEmergencyGroundTransportOrEmergencyMaritimeTransport(
          serviceToBeRendered
        );
      },
      then: yup.array().of(
        yup.object().shape({
          id: yup.string().required(),
          subCoverageID: yup.string().test({
            name: "check validity",
            message: getENText("serviceForm.patient.subCoverageID.required"),
            test(value) {
              const isBEMember = !!this.parent?.isBEMember;
              if (isBEMember) {
                return !!value;
              }
              return true;
            },
          }),
          medicalStatus: yup.string().nullable(),
        })
      ),
    })
    .when("serviceToBeRendered", {
      is: (serviceToBeRendered: ServiceTypesEnum) => {
        return getIsServiceToBeRenderedEmergencyAirliftEvacuationOrHospitalTransferOrRepatriationOrCustom(
          serviceToBeRendered
        );
      },
      then: yup.array().of(
        yup.object().shape({
          id: yup.string().required(),
          subCoverageID: yup.string().test({
            name: "check validity",
            message: getENText("serviceForm.patient.subCoverageID.required"),
            test(value) {
              const isBEMember = !!this.parent?.isBEMember;
              if (isBEMember) {
                return !!value;
              }
              return true;
            },
          }),
          medicalStatus: yup.string().test({
            name: "check validity",
            message: getENText("serviceForm.patient.medicalStatus.required"),
            test(value) {
              const isBEMember = !!this.parent?.isBEMember;
              if (isBEMember) {
                return !!value;
              }
              return true;
            },
          }),
        })
      ),
    }),
});

export const showValidationErrorAsNotification = (
  errors: FormikErrors<Partial<FormValues>>
) => {
  let errorMessage = "";
  const errorsArray = Object.entries(errors);
  const [field, errorObject] = errorsArray[0]!;
  if (
    [
      "serviceToBeRendered",
      "informationSupportReason",
      "customService",
      "informationSupportExplanation",
      "medicalDirectorId",
    ].includes(field)
  ) {
    notifications.notifyError(errorObject as unknown as string);
    return;
  }
  if (field === "physician") {
    errorMessage = "Physician's ";
    const physicianErrorsArray = Object.entries(
      errorObject as FormikErrors<Partial<FormValues["physician"]>>
    );
    const [, requesterFieldError] = physicianErrorsArray[0]!;
    errorMessage += requesterFieldError.toLowerCase();

    notifications.notifyError(errorMessage);
    return;
  }

  if (field === "patients") {
    errorMessage = "Patient's ";
    if (errorObject) {
      if (Array.isArray(errorObject)) {
        (errorObject as unknown as Record<string, string>[]).forEach(
          (patientError: Record<string, string>) => {
            if (patientError) {
              const patientErrorsArray = Object.entries(patientError);
              const [, patientFieldError] = patientErrorsArray[0]!;
              errorMessage += patientFieldError.toLowerCase();
              notifications.notifyError(errorMessage);
            }
          }
        );
      }
    }
  }
};
