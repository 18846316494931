export enum TabsEnum {
  INITIAL_ASSESSMENT = "INITIAL ASSESSMENT",
  REQUESTER_AND_PATIENT = "REQUESTER & PATIENT",
  LOCATION = "LOCATION",
  SERVICE = "SERVICE",
  ASSESSMENT = "ASSESSMENT",
  PLANNING = "PLANNING",
  RENDERING = "RENDERING",
}

export type CallFormTabProps = {
  id: string;
  "aria-controls": string;
};

export type CallFormTab = {
  label: TabsEnum;
  props: CallFormTabProps;
  index: number;
};

export enum MainTabsEnum {
  DETAILS = "Details",
  ACCESS_LINKS = "Access Links",
  NOTES = "Notes",
}

export type CallFormMainTabProps = {
  id: string;
  "aria-controls": string;
};

export type CallFormMainTab = {
  label: MainTabsEnum;
  props: CallFormMainTabProps;
  index: number;
};
