import { SocketEventsEnum } from "@deep-consulting-solutions/be2-constants";
import SiteContext from "context";
import { useCallback, useContext, useEffect, useState } from "react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useSocket = (sourceID: string) => {
  /**
   * TODO: multiple handlers can be appended to same event listener messages. Use sourceID to create unique handlers for same event messages
   */
  const { socket, ...rest } = useContext(SiteContext);
  const [listeners, setListeners] = useState<string[]>([]);

  const addHandler = useCallback(
    <T>(
      message: SocketEventsEnum | "private message",
      handler: (data: T) => void
    ) => {
      if (socket.hasListeners(message)) {
        return false;
      }
      setListeners((c) => {
        return c.concat(message);
      });
      socket.on(message, (data: T) => handler(data));

      return true;
    },
    [socket]
  );

  const removeHandler = useCallback(
    <T>(message: string, callBack: (data: T) => void) => {
      if (!socket.hasListeners(message)) {
        return false;
      }
      socket.removeListener(message, callBack);
      setListeners((c) => {
        return c.filter((listener) => listener !== message);
      });

      return true;
    },
    [socket]
  );

  useEffect(() => {
    return () => {
      listeners.forEach((listener) => socket.removeListener(listener));
    };
  }, [socket, listeners]);

  return { socket, addHandler, removeHandler, ...rest };
};
