import React from "react";
import Loader from "component/Loader/Loader";

export const GettingMoreLoader = ({ open }: { open?: boolean }) => {
  if (!open) {
    return null;
  }
  return (
    <div
      style={{
        height: 60,
        position: "relative",
        padding: 10,
      }}
    >
      <Loader open={open} absolute />
    </div>
  );
};
