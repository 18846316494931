import { useCallback, useState, useMemo, useEffect } from "react";
import { Box, Button, Tooltip, Typography, Grid } from "@material-ui/core";
import { FormikProvider, useFormik } from "formik";
import { Radios } from "component/atoms";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useStyles } from "call/components/CallForms/RequesterAndPatientForm/styles";
import {
  FormValues,
  Member,
  MembershipTypeEnum,
  PatientData,
} from "call/components/CallForms/RequesterAndPatientForm/types";
import { PatientInformation } from "call/components/CallForms/RequesterAndPatientForm/PatientInformation";
import { SearchForMember } from "call/components/CallForms/RequesterAndPatientForm/SearchForMember";
import { SearchedMembers } from "call/components/CallForms/RequesterAndPatientForm/SearchedMembers";
import { RequesterInformation } from "call/components/CallForms/RequesterAndPatientForm/RequesterInfomation";
import { ContactRequester } from "call/components/CallForms/RequesterAndPatientForm/ContactRequester";
import { useCall } from "call/hooks";
import { notifications } from "services";
import { getENText } from "helpers";
import {
  putRequesterPatientInfo,
  getContactSubMembers,
  searchExistingMembers,
} from "redux/call/requests";
import Loader from "component/Loader";
import {
  MembersResponseSubObject,
  PatientTypeEnum,
  ServiceCaseStatusEnum,
} from "@deep-consulting-solutions/be2-constants";
import { usePrevious } from "hooks";
import { useAppDispatch } from "redux/store";
import { hospitalActions, hospitalSelectors } from "redux/hospital";
import { useSelector } from "react-redux";
import {
  getRequesterAndPatientFormValidationSchema,
  getRequesterAndPatientFormInitialValues,
  formatPayloadToSubmit,
  getDefaultPatient,
  getGeneralAddressString,
} from "./helpers";
import { ProvideInformationSupportDialog } from "../../ProvideInformationSupportDialog";
import { DeletePatientDialog } from "./DeletePatientDialog";
import {
  BackButton,
  HandleUpdateFormValuesWithServiceCaseDetailsAfterTabChange,
} from "../Components";
import { TabsEnum } from "../types";

interface Props {
  switchTab: (value: TabsEnum) => void;
  activeTab: TabsEnum;
}

const parseDate = (date: string) => {
  try {
    return new Date(date);
  } catch (error) {
    return "";
  }
};

export const RequesterAndPatientForm: React.FC<Props> = ({
  switchTab,
  activeTab,
}) => {
  const dispatch = useAppDispatch();
  const hospitals = useSelector(hospitalSelectors.getHospitals);

  const [contactPatientDialogOpen, setContactPatientDialogOpen] =
    useState(false);
  const [subscriptionMembers, setSubscriptionMembers] = useState<
    MembersResponseSubObject[]
  >([]);
  const [patientToRemove, setPatientToRemove] = useState<
    Pick<PatientData, "firstName" | "lastName" | "id"> | undefined
  >(undefined);
  const [
    isInformationalSupportDialogOpen,
    setIsInformationalSupportDialogOpen,
  ] = useState(false);
  const { viewedCall, updateCallServiceCase } = useCall();
  const [oldValues, setOldValues] = useState<FormValues>(
    getRequesterAndPatientFormInitialValues(viewedCall?.updatedServiceCase)
  );

  const handleSubmit = useCallback(
    async (data: FormValues) => {
      const formattedData = formatPayloadToSubmit(
        viewedCall!.updatedServiceCase!,
        data
      );
      const res = await putRequesterPatientInfo(
        viewedCall!.updatedServiceCase!.id,
        formattedData
      );
      updateCallServiceCase(viewedCall!.sessionId, res);
    },
    [viewedCall, updateCallServiceCase]
  );

  const handleOpenInformationalSupportDialog = useCallback(() => {
    setIsInformationalSupportDialogOpen(true);
  }, []);

  const handleCloseInformationalSupportDialog = useCallback(() => {
    setIsInformationalSupportDialogOpen(false);
  }, []);

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: getRequesterAndPatientFormInitialValues(
      viewedCall?.updatedServiceCase
    ),
    validationSchema: getRequesterAndPatientFormValidationSchema(
      viewedCall?.updatedServiceCase?.requestType
    ),
    onSubmit: async (data, { setSubmitting }) => {
      try {
        setSubmitting(true);
        await handleSubmit(data);
        notifications.notifySuccess(
          getENText("requesterAndPatientForm.successful")
        );
        setOldValues(data);
        switchTab(TabsEnum.LOCATION);
      } catch (error) {
        //
      } finally {
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    values,
    submitCount,
    setFieldValue,
    handleSubmit: handleFormSubmit,
    setValues,
  } = formik;

  const prevSubmitCount = usePrevious<number>(submitCount);

  useEffect(() => {
    if (
      prevSubmitCount !== submitCount &&
      (errors.requester || errors.patients)
    ) {
      notifications.notifyError(
        "Requester or patient details are invalid, please verify that the values provided are correct."
      );
    }
  }, [errors, submitCount, prevSubmitCount]);

  const restoreValues = useCallback(
    (v: FormValues) => {
      setValues(v);
      setOldValues(v);
    },
    [setValues]
  );

  const classes = useStyles({ active: !!values.patients.length });

  const [loading, setLoading] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState<Member[]>([]);
  const closeContactPatientDialog = () => {
    setContactPatientDialogOpen(false);
  };

  const addToPatients = useCallback(
    (patient: PatientData) => {
      const patients = [...values.patients, patient];
      setFieldValue("patients", patients);
    },
    [setFieldValue, values.patients]
  );

  const handleReplacePatient = useCallback(
    (id: string, patient: PatientData) => {
      const patients = [...values.patients.filter((p) => p.id !== id), patient];
      setFieldValue("patients", patients);
    },
    [setFieldValue, values.patients]
  );

  const addPatient = useCallback(() => {
    addToPatients(getDefaultPatient({}));
  }, [addToPatients]);

  const handleTransFormRadiosValue = useCallback(
    (value: any) => value === "true" || value === true,
    []
  );

  const handleCloseRemovePatientDialog = useCallback(() => {
    setPatientToRemove(undefined);
  }, []);

  const handleDeletePatient = useCallback(
    (id?: string) => {
      setFieldValue(
        "patients",
        values.patients.filter(
          (patient) => patient.id !== (id || patientToRemove?.id)
        )
      );
      handleCloseRemovePatientDialog();
    },
    [
      values.patients,
      patientToRemove?.id,
      setFieldValue,
      handleCloseRemovePatientDialog,
    ]
  );

  const handleOpenRemovePatientDialog = useCallback(
    (
      patient: Pick<PatientData, "firstName" | "lastName" | "id">,
      skipDialog = false
    ) => {
      if (skipDialog) {
        handleDeletePatient(patient.id);
      } else if (patient.firstName?.trim() || patient.lastName?.trim()) {
        setPatientToRemove(patient);
      } else {
        handleDeletePatient(patient.id);
      }
    },
    [handleDeletePatient]
  );

  const showRemovePatientButton = useMemo(() => true, []);

  const existingRequesterPatient = useMemo(
    () => values.patients.find((p) => p.isRequester),
    [values.patients]
  );

  // Sync when requester is a patient
  const handlePatientRequesterSync = useCallback(
    (isPatient: boolean, isMemberOrCustomer: boolean, contactID?: string) => {
      if (!contactID) {
        return;
      }
      if (isMemberOrCustomer && isPatient) {
        const patientValue = {
          isMemberOrCustomer: true,
          isRequester: true,
          patientType: PatientTypeEnum.EXISTING,
          contactID,
        };
        if (!existingRequesterPatient) {
          addToPatients(getDefaultPatient(patientValue));
        } else if (
          existingRequesterPatient.contactID !== contactID &&
          existingRequesterPatient.id
        ) {
          handleReplacePatient(
            existingRequesterPatient.id,
            getDefaultPatient(patientValue)
          );
        }
      } else if (existingRequesterPatient) {
        handleDeletePatient(existingRequesterPatient.id);
      }
    },
    [
      existingRequesterPatient,
      addToPatients,
      handleDeletePatient,
      handleReplacePatient,
    ]
  );

  const handleRequesterIsPatient = useCallback(
    (value: any) => {
      const isPatient = value === "true" || value === true;
      handlePatientRequesterSync(
        isPatient,
        !!values.requester.isMemberOrCustomer,
        values.requester.contactID
      );
      return handleTransFormRadiosValue(value);
    },
    [
      handleTransFormRadiosValue,
      values.requester.isMemberOrCustomer,
      handlePatientRequesterSync,
      values.requester.contactID,
    ]
  );

  const handleRequesterIsMember = useCallback(
    (value: any) => {
      const isMemberOrCustomer = value === "true" || value === true;
      handlePatientRequesterSync(
        !!values.requester.isPatient,
        isMemberOrCustomer,
        values.requester.contactID
      );
      return handleTransFormRadiosValue(value);
    },
    [
      handleTransFormRadiosValue,
      values.requester.isPatient,
      handlePatientRequesterSync,
      values.requester.contactID,
    ]
  );

  const handleMemberSelect = useCallback(
    async (member: Member) => {
      setSelectedMembers([member]);
      handlePatientRequesterSync(!!values.requester.isPatient, true, member.id);
      try {
        setLoading(true);
        const res = await getContactSubMembers(member.id);
        const formKey = "requester";

        setFieldValue(`${formKey}.firstName`, member.firstName);
        setFieldValue(`${formKey}.lastName`, member.lastName);
        setFieldValue(`${formKey}.gender`, member.gender);
        setFieldValue(`${formKey}.dob`, member.dob);
        setFieldValue(`${formKey}.contactID`, member.id);
        setFieldValue(`${formKey}.email`, member.email);
        setFieldValue(`${formKey}.phone`, member.phone);
        setFieldValue(`${formKey}.nextOfKin`, {
          relationshipToPatient: res.nextOfKin?.relationToContact || "",
          otherRelationToPatient: res.nextOfKin?.otherRelationToContact || "",
          firstName: res.nextOfKin?.firstName || "",
          lastName: res.nextOfKin?.lastName || "",
          phone: res.nextOfKin?.phone || "",
          email: res.nextOfKin?.email || "",
          isMemberOrCustomer: !!res.nextOfKin?.firstName,
        });
        setSubscriptionMembers(res.subscriptionMembers);
      } catch (error) {
        //
      } finally {
        setLoading(false);
      }
    },
    [setFieldValue, handlePatientRequesterSync, values.requester.isPatient]
  );

  const handleRequesterInitialValues = useCallback(async () => {
    const requester = viewedCall?.updatedServiceCase?.requester;
    if (!requester) {
      return;
    }
    const contactID =
      requester.contactID || (requester as unknown as { id: string }).id;
    if (
      contactID &&
      requester.firstName &&
      requester.lastName &&
      requester.email &&
      selectedMembers.length === 0
    ) {
      try {
        setLoading(true);
        const data: Record<string, any> = {};
        const fields = ["firstName", "lastName", "email", "phone"];
        fields.forEach((field: string) => {
          if ((requester as any)[field]) {
            data[field] = (requester as any)[field];
          }
        });
        if (Object.values(data).length > 0) {
          const contacts = await searchExistingMembers(data);

          const contact = contacts.find((s) => s.id === contactID);
          if (contact) {
            const memberships = [
              {
                type: contact.isMainbeneficiaryOfPaidSub
                  ? MembershipTypeEnum.MainBeneficiary
                  : MembershipTypeEnum.Dependent,
                number: contact.membershipNumber,
              },
            ].concat(
              contact.dependentSubsMembershipNumbers.map((d) => ({
                type: MembershipTypeEnum.Dependent,
                number: d,
              }))
            );
            setSelectedMembers([
              {
                firstName: contact.firstName,
                lastName: contact.lastName,
                dob: parseDate(contact.dob),
                id: contact.id,
                memberships,
                citizenships: contact.citizenships,
                address: getGeneralAddressString(contact.address),
                email: contact.email,
                phone: contact.phone,
                gender: contact.gender,
              },
            ]);
          }
        }
        const res = await getContactSubMembers(contactID, true, true);

        setSubscriptionMembers(res.subscriptionMembers);
      } catch (error) {
        //
      } finally {
        setLoading(false);
      }
    }
  }, [viewedCall?.updatedServiceCase?.requester, selectedMembers.length]);

  useEffect(() => {
    handleRequesterInitialValues();
  }, [handleRequesterInitialValues]);

  useEffect(() => {
    if (!hospitals.length) {
      dispatch(hospitalActions.fetchHospitals());
    }
  }, [dispatch, hospitals.length]);

  const disableForm = useMemo(
    () =>
      !!viewedCall?.disableForms ||
      viewedCall?.updatedServiceCase?.caseStatus !==
        ServiceCaseStatusEnum.GATHERING_REQUIREMENTS,
    [viewedCall?.disableForms, viewedCall?.updatedServiceCase?.caseStatus]
  );

  return (
    <>
      <div>
        <FormikProvider value={formik}>
          <HandleUpdateFormValuesWithServiceCaseDetailsAfterTabChange
            valuesCreator={getRequesterAndPatientFormInitialValues}
            activeTab={activeTab}
            presentTab={TabsEnum.REQUESTER_AND_PATIENT}
          />
          <form onSubmit={formik.handleSubmit}>
            <Loader open={formik.isSubmitting || loading} />
            <Typography color="primary" className={classes.title}>
              Requester Information
            </Typography>
            <Radios
              label="Requester is a patient?"
              name="requester.isPatient"
              options={[
                { label: "No", value: false },
                { label: "Yes", value: true },
              ]}
              handleTransFormValue={handleRequesterIsPatient}
              disabled={disableForm}
            />
            <Radios
              label="Requester is a Member or an Existing Customer?"
              name="requester.isMemberOrCustomer"
              options={[
                { label: "No", value: false },
                { label: "Yes", value: true },
              ]}
              handleTransFormValue={handleRequesterIsMember}
              disabled={disableForm}
            />
            {values.requester.isMemberOrCustomer === false &&
              typeof values.requester.isPatient === "boolean" && (
                <RequesterInformation
                  isPatient={values.requester.isPatient}
                  disableForm={disableForm}
                />
              )}

            {values.requester.isMemberOrCustomer === true && (
              <>
                <SearchForMember
                  onMemberSelect={(member: Member) => {
                    handleMemberSelect(member);
                  }}
                  disableForm={disableForm}
                />
                {selectedMembers.length ? (
                  <SearchedMembers
                    members={selectedMembers}
                    disableForm={disableForm}
                  />
                ) : null}
              </>
            )}
            {typeof values.requester.isPatient === "boolean" && (
              <div>
                <Typography color="primary" className={classes.title}>
                  Patient(s) Information
                  <Tooltip title="Please create a New Service Case for Patient in need of a different service.">
                    <InfoOutlinedIcon className={classes.infoIcon} />
                  </Tooltip>
                </Typography>

                {/* eslint-disable-next-line no-nested-ternary */}
                {values.patients.length ? (
                  <>
                    {values.patients.map((patient, index) => (
                      <PatientInformation
                        patient={patient}
                        formKey={`patients.${index}`}
                        isMemberOrCustomer={values.requester.isMemberOrCustomer}
                        key={patient.id}
                        subscriptionMembers={subscriptionMembers}
                        handleOpenRemovePatientDialog={
                          handleOpenRemovePatientDialog
                        }
                        showRemovePatientButton={
                          patient.isRequester ? false : showRemovePatientButton
                        }
                        requesterSelectedMembers={selectedMembers}
                        disableForm={disableForm}
                      />
                    ))}
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={addPatient}
                      disabled={disableForm}
                    >
                      Add Another Patient
                    </Button>
                  </>
                ) : values.requester.isPatient &&
                  values.requester.isMemberOrCustomer === false ? (
                  <div>
                    <Box
                      mb={3}
                      padding={2}
                      display="flex"
                      borderRadius={8}
                      alignItems="center"
                      border="2px solid rgba(30, 90, 182, 0.44)"
                    >
                      <Typography variant="body1" style={{ color: "#0D151F" }}>
                        Requester is a patient
                      </Typography>
                    </Box>

                    <Button
                      type="button"
                      color="primary"
                      variant="outlined"
                      onClick={addPatient}
                      disabled={disableForm}
                    >
                      Add Another Patient
                    </Button>
                  </div>
                ) : (
                  <div className={classes.patientInfoBox}>
                    <div className={classes.addPatientContainer}>
                      <Typography
                        variant="body2"
                        className={classes.addPatientText}
                      >
                        No Patient Added
                      </Typography>
                      <Button
                        color="primary"
                        variant="outlined"
                        disabled={disableForm}
                        onClick={addPatient}
                      >
                        Add Patient
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </form>

          <Box
            mt={3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <BackButton<FormValues>
              activeTab={activeTab}
              switchTab={switchTab}
              oldValues={oldValues}
              newValues={values}
              handleSaveValues={restoreValues}
              disabled={disableForm}
            />
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Grid item>
                  <Button
                    color="primary"
                    type="submit"
                    onClick={handleOpenInformationalSupportDialog}
                    disabled={disableForm}
                  >
                    Provide Informational Support
                  </Button>
                </Grid>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  color="primary"
                  disabled={disableForm || formik.isSubmitting}
                  onClick={() => {
                    handleFormSubmit();
                  }}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Box>
        </FormikProvider>
        <ContactRequester
          patients={values.patients.filter((pat) => !pat.isMemberOrCustomer)}
          open={contactPatientDialogOpen}
          onClose={closeContactPatientDialog}
        />
      </div>
      <ProvideInformationSupportDialog
        open={isInformationalSupportDialogOpen}
        handleClose={handleCloseInformationalSupportDialog}
      />
      <DeletePatientDialog
        handleClose={handleCloseRemovePatientDialog}
        open={!!patientToRemove}
        handleDelete={handleDeletePatient}
        patient={patientToRemove}
      />
    </>
  );
};
