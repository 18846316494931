import React, { useCallback, useEffect, useState } from "react";
import { Grid, makeStyles, Theme, Box, Typography } from "@material-ui/core";
import { useResponsive } from "hooks";
import { StyledTypography } from "component/StyledTypography";
import { Close, ErrorOutline } from "@material-ui/icons";

interface StyleProps {
  isGrand: boolean;
  isDesktop: boolean;
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(
  ({ spacing: s, palette: p }) => ({
    amount: ({ isGrand, isDesktop }) => ({
      textAlign: "right",
      fontWeight: "bold",
      fontFamily: "'Raleway', sans-serif",
      ...(isGrand
        ? {
            fontSize: isDesktop ? 48 : 24,
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-end",
          }
        : {
            ...(isDesktop
              ? {}
              : {
                  fontSize: 12,
                }),
          }),
    }),
    grand: {
      marginTop: s(4),
    },
    grandTitle: {
      fontFamily: "Raleway",
      fontSize: 24,
      fontWeight: 600,
    },
    container: ({ isGrand }) =>
      isGrand
        ? {
            marginTop: s(3),
          }
        : {},
    currency: ({ isDesktop }) => ({
      fontSize: isDesktop ? 20 : 18,
    }),
    title: ({ isDesktop, isGrand }) =>
      isDesktop
        ? {
            ...(isGrand
              ? {
                  fontSize: 24,
                }
              : {}),
          }
        : {
            fontSize: isGrand ? 18 : 12,
          },
    infoTitle: {
      color: "white",
    },
    btn: {
      cursor: "pointer",
      fontSize: 14,
    },
    info: {
      background: p.primary.main,
      color: "white",
      padding: s(2),
      borderRadius: 4,
      margin: s(1, 0, 2, 0),
    },
  })
);

interface Props {
  title: string;
  amount?: string | number;
  titleClass?: string;
  amountClassName?: string;
  isGrand?: boolean;
  infoComponent?: () => JSX.Element;
  infoTitle?: string;
}

export const StyledTitleAndAmount = ({
  title,
  amount = 0,
  titleClass = "",
  amountClassName = "",
  isGrand = false,
  infoComponent,
  infoTitle,
}: Props) => {
  const { isDesktop, isMobile } = useResponsive();
  const [showInfo, setShowInfo] = useState(false);

  const openShowInfo = useCallback(() => setShowInfo(true), []);
  const closeShowInfo = useCallback(() => setShowInfo(false), []);

  useEffect(() => {
    setShowInfo(!!infoComponent || !!infoTitle);
  }, [infoComponent, infoTitle]);

  const classes = useStyles({ isGrand, isDesktop, isMobile });
  return (
    <Box className={classes.container}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={isGrand ? 6 : 8}>
          <Grid container alignItems="center" spacing={1}>
            {(!!infoComponent || !!infoTitle) && (
              <Grid item justify="center">
                <ErrorOutline
                  className={classes.btn}
                  onClick={openShowInfo}
                  color="error"
                />
              </Grid>
            )}
            <Grid item justify="center">
              <StyledTypography
                variant="subtitle2"
                color="primary.dark"
                className={`${classes.title} ${titleClass}`}
              >
                {title}
              </StyledTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={isGrand ? 6 : 4}>
          <StyledTypography
            variant="subtitle2"
            className={`${classes.amount} ${amountClassName}`}
            color="primary.dark"
          >
            {isGrand && <span className={classes.currency}>BSD</span>} {amount}
          </StyledTypography>
        </Grid>
      </Grid>
      {showInfo && (
        <Box className={classes.info}>
          <Grid container wrap="nowrap" justify="space-between" spacing={2}>
            <Grid item>
              <Typography variant="subtitle2" className={classes.infoTitle}>
                {infoTitle}
              </Typography>
            </Grid>
            <Grid item>
              <Close className={classes.btn} onClick={closeShowInfo} />
            </Grid>
          </Grid>
          {!!infoComponent && !!infoTitle && (
            <Box mt={2}>{infoComponent()}</Box>
          )}
        </Box>
      )}
    </Box>
  );
};
