import { YesNoEnum } from "@deep-consulting-solutions/be2-constants";
import { format } from "date-fns";

export const displayTime = (date: Date | string) => {
  const d = new Date(date);
  return format(d, "dd MMM yyyy hh:mm aa");
};

export const displayPatientCoverageAddedTime = (date: Date | string) => {
  return format(
    typeof date === "string" ? new Date(date) : date,
    "dd MMM yyyy"
  );
};

export const convertYesNoBoolean = (status: boolean | YesNoEnum | null) => {
  if (status === true) return YesNoEnum.YES;
  if (status === false) return YesNoEnum.NO;
  return status;
};
