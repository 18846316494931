/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  attentionCheckSelectors,
  attentionCheckActions,
} from "redux/attentionCheck";
import { dispatcherSelectors } from "redux/dispatcher";
import { AppDispatch } from "redux/store";
import { ATTENTION_CHECK_CHECKIN_MAX_IN_SECONDS } from "configs";
import Loader from "component/Loader";
import notificationBell from "media/notification_bell.mp3";

import { useCall } from "call";
import {
  getDisplayedSeconds,
  formatDisplayedSeconds,
  isEntryTimeWithinPermittedTime,
  getTimeoutForNextCheck,
  hideAttentionCheck,
} from "./helpers";
import { Banner } from "./Banner";

const useStyles = makeStyles<Theme, { isLate: boolean }>(
  ({ palette: p, spacing: s }) => {
    return {
      content: { position: "relative" },
      title: ({ isLate }) => ({
        fontSize: 24,
        fontWeight: 600,
        color: isLate ? p.error.main : p.primary.main,
      }),
      message: {
        marginTop: s(1),
        fontSize: 16,
        color: p.grey[700],
      },
      warningMessage: {
        marginTop: s(1),
      },
      actionRow: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        justifyContent: "space-between",
      },
      time: ({ isLate }) => ({
        fontSize: 60,
        fontWeight: 600,
        color: isLate ? p.error.main : p.primary.main,
      }),
      checkinBtn: {
        marginBottom: s(2),
      },
    };
  }
);

export const AttentionCheck = () => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { isOnCall } = useCall();
  const open = useSelector(attentionCheckSelectors.getOpen);
  const isDispatcherOnDuty = useSelector(dispatcherSelectors.onDuty);
  const checkinCount = useSelector(attentionCheckSelectors.getCheckinCount);
  const loading = useSelector(attentionCheckSelectors.getLoading);
  const failureCount = useSelector(attentionCheckSelectors.getFailureCount);

  const [countdown, setCountdown] = useState(
    ATTENTION_CHECK_CHECKIN_MAX_IN_SECONDS
  );

  const displayedSeconds = getDisplayedSeconds(countdown);

  const audioSound1 = useRef<HTMLAudioElement | null>(null);
  const audioSound2 = useRef<HTMLAudioElement | null>(null);
  const audioSound3 = useRef<HTMLAudioElement | null>(null);

  const playSound = useCallback(async (times: number) => {
    if (times > 0 && audioSound1.current) {
      audioSound1.current.play();
    }
    if (times > 1 && audioSound2.current) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      audioSound2.current.play();
    }

    if (times > 2 && audioSound3.current) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      audioSound3.current.play();
    }
  }, []);

  const warningMessage = useMemo(() => {
    if (countdown < ATTENTION_CHECK_CHECKIN_MAX_IN_SECONDS - 60) {
      return (
        <>
          You are more than 30 seconds late, your supervisor has been notified.
          Check in as soon as possible to shorten your delay on record.
        </>
      );
    }

    if (countdown < ATTENTION_CHECK_CHECKIN_MAX_IN_SECONDS - 30) {
      return (
        <>
          If you are late by <b>more than 30 seconds</b>, your supervisor will
          be notified.
        </>
      );
    }

    return null;
  }, [countdown]);

  const handleCheckinClick = useCallback(() => {
    dispatch(attentionCheckActions.checkin());
  }, [dispatch]);

  // Countdown
  const countdownTimeout = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (countdownTimeout.current) clearInterval(countdownTimeout.current);
    if (open) {
      countdownTimeout.current = setInterval(() => {
        const timePassed = new Date().getTime() - open;
        const secondsPassed = Math.floor(timePassed / 1000);
        const secondsLeft =
          ATTENTION_CHECK_CHECKIN_MAX_IN_SECONDS - secondsPassed;
        setCountdown(Math.max(secondsLeft, 0));
      }, 1000);
    }

    return () => {
      if (countdownTimeout.current) clearInterval(countdownTimeout.current);
    };
  }, [open]);

  const logCheckin = useRef<Promise<any> | null>(null);

  // fetch last system action
  const checkingSystemActionTimeout = useRef<NodeJS.Timeout | null>(null);
  const [checkLastActionLoop, setCheckLastActionLoop] = useState(0);
  useEffect(() => {
    (async () => {
      if (checkingSystemActionTimeout.current)
        clearTimeout(checkingSystemActionTimeout.current);
      if (isDispatcherOnDuty && !isOnCall && !open) {
        if (logCheckin.current) {
          checkingSystemActionTimeout.current = setTimeout(() => {
            setCheckLastActionLoop((c) => c + 1);
          }, getTimeoutForNextCheck(new Date()));
          return;
        }

        const res = await dispatch(attentionCheckActions.getLastSystemAction());

        let entryTime = new Date();
        if (
          attentionCheckActions.getLastSystemAction.fulfilled.match(res) &&
          res.payload
        ) {
          entryTime = new Date(res.payload.entryTime);
        }

        if (isEntryTimeWithinPermittedTime(entryTime)) {
          checkingSystemActionTimeout.current = setTimeout(() => {
            setCheckLastActionLoop((c) => c + 1);
          }, getTimeoutForNextCheck(entryTime));
        } else {
          dispatch(attentionCheckActions.openDialog());
          setCountdown(ATTENTION_CHECK_CHECKIN_MAX_IN_SECONDS);
        }
      }
    })();

    return () => {
      if (checkingSystemActionTimeout.current) {
        clearTimeout(checkingSystemActionTimeout.current);
      }
    };
  }, [dispatch, isDispatcherOnDuty, open, checkLastActionLoop, isOnCall]);

  // Log late checkin
  const lateCheckinLog = useRef<Promise<any> | null>(null);
  useEffect(() => {
    (async () => {
      if (countdown === ATTENTION_CHECK_CHECKIN_MAX_IN_SECONDS - 60) {
        if (logCheckin.current) return;
        lateCheckinLog.current = dispatch(
          attentionCheckActions.reportLateCheckin()
        );
        await lateCheckinLog.current;
        lateCheckinLog.current = null;
      }
    })();
  }, [dispatch, countdown]);

  // Checkin
  useEffect(() => {
    (async () => {
      if (checkinCount) {
        const entryTime = new Date();
        if (lateCheckinLog.current) {
          await lateCheckinLog.current;
        }
        logCheckin.current = dispatch(
          attentionCheckActions.logCheckin({ entryTime })
        );
        await logCheckin.current;
        logCheckin.current = null;
      }
    })();
  }, [checkinCount, dispatch]);

  // Log failed checkin
  useEffect(() => {
    (async () => {
      if (countdown === 0) {
        if (logCheckin.current) return;
        await dispatch(
          attentionCheckActions.logFailedCheckin({
            entryTime: new Date(),
          })
        );
        setCountdown(ATTENTION_CHECK_CHECKIN_MAX_IN_SECONDS);
      }
    })();
  }, [countdown, dispatch]);

  useEffect(() => {
    if (open) playSound(1);
  }, [playSound, open]);

  useEffect(() => {
    if (
      countdown === ATTENTION_CHECK_CHECKIN_MAX_IN_SECONDS - 30 &&
      !logCheckin.current
    ) {
      playSound(2);
    }
  }, [countdown, playSound]);

  useEffect(() => {
    if (
      countdown === ATTENTION_CHECK_CHECKIN_MAX_IN_SECONDS - 60 &&
      !logCheckin.current
    ) {
      playSound(3);
    }
  }, [countdown, playSound]);

  const classes = useStyles({ isLate: displayedSeconds < 0 });

  if (hideAttentionCheck(location.pathname)) return null;

  return (
    <>
      <Dialog open={!!open}>
        <DialogContent className={classes.content}>
          <Loader absolute open={loading} />
          <Typography className={classes.title}>ATTENTION CHECK</Typography>
          <Typography className={classes.message}>
            {(() => {
              if (displayedSeconds >= 0) {
                return "Check in to confirm that you are paying attention before timer runs out";
              }

              return "Are you still here? You are late on your regular check-in.";
            })()}
          </Typography>
          {warningMessage && (
            <div className={classes.warningMessage}>
              <Banner>{warningMessage}</Banner>
            </div>
          )}
          <div className={classes.actionRow}>
            <Typography className={classes.time}>
              {formatDisplayedSeconds(displayedSeconds)}
            </Typography>
            <Button
              color="primary"
              className={classes.checkinBtn}
              onClick={handleCheckinClick}
            >
              Check In
            </Button>
          </div>
          {!!failureCount && (
            <Banner>
              You already failed previous attention check{" "}
              <b>
                {failureCount} time
                {failureCount > 1 ? "s" : ""}
              </b>
              .
            </Banner>
          )}
        </DialogContent>
      </Dialog>
      <audio ref={audioSound1} preload="auto">
        <source type="audio/mp3" src={notificationBell} />
      </audio>
      <audio ref={audioSound2} preload="auto">
        <source type="audio/mp3" src={notificationBell} />
      </audio>
      <audio ref={audioSound3} preload="auto">
        <source type="audio/mp3" src={notificationBell} />
      </audio>
    </>
  );
};
