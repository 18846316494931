import React, { useRef, useEffect, useCallback } from "react";
import { Button, Dialog, Typography, makeStyles } from "@material-ui/core";
import Bowser from "bowser";

import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions,
} from "mic-check";

const browser = Bowser.getParser(window.navigator.userAgent);

enum DialogType {
  explanation = "explanation",

  systemDenied = "systemDenied",
  userDenied = "userDenied",
  trackError = "trackError",
}

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  root: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 658,
    },
    "& .MuiBackdrop-root": {
      backgroundColor: p.common.white,
    },
  },
  paper: {
    backgroundColor: p.common.white,
    borderRadius: s(1),
    padding: s(4),
    boxShadow:
      "0px 10px 14px rgba(84, 134, 199, 0.12), 0px 22px 35px rgba(84, 134, 199, 0.15), 0px 8px 42px rgba(84, 134, 199, 0.12)",
  },
  header: {
    fontSize: s(3),
    lineHeight: "32px",
    fontWeight: 500,
    fontFamily: "'Roboto', sans-serif",
    marginBottom: s(1),
    color: p.primary.main,
    "& span": {
      fontWeight: 700,
    },
  },
  withIcon: {
    display: "inline-flex",
    alignItems: "center",
    "& img": {
      margin: s(0, 1),
      height: 16,
    },
  },
}));

const MediaOnboardingDialog: React.FC = () => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = React.useState<DialogType | null>(null);

  const [audioAllowed, setAudioAllowed] = React.useState<boolean>(false);
  const [videoAllowed, setVideoAllowed] = React.useState<boolean>(false);

  // Create wrapper refs to access values even during setTimeout
  // https://github.com/facebook/react/issues/14010
  const showDialogRef = useRef(showDialog);
  showDialogRef.current = showDialog;
  const audioAllowedRef = useRef(audioAllowed);
  audioAllowedRef.current = audioAllowed;
  const videoAllowedRef = useRef(videoAllowed);
  videoAllowedRef.current = videoAllowed;

  const checkForExplanationDialog = () => {
    if (
      (!audioAllowedRef.current || !videoAllowedRef.current) &&
      showDialogRef.current === null
    )
      setShowDialog(DialogType.explanation);
  };

  const checkMediaPermissions = useCallback(() => {
    requestMediaPermissions()
      .then(() => {
        setAudioAllowed(true);
        setVideoAllowed(true);
        setShowDialog(null);
      })
      .catch((error: MediaPermissionsError) => {
        if (error.type === MediaPermissionsErrorType.SystemPermissionDenied) {
          // user denied permission
          setShowDialog(DialogType.systemDenied);
        } else if (
          error.type === MediaPermissionsErrorType.UserPermissionDenied
        ) {
          // browser doesn't have access to devices
          setShowDialog(DialogType.userDenied);
        } else if (
          error.type === MediaPermissionsErrorType.CouldNotStartVideoSource
        ) {
          // most likely when other apps or tabs are using the cam/mic (mostly windows)
          setShowDialog(DialogType.trackError);
        }
      });

    setTimeout(() => {
      checkForExplanationDialog();
    }, 1000);
  }, []);

  const renderTryAgain = (text?: string) => {
    return (
      <div style={{ width: "100%", marginTop: 20 }}>
        <Button
          onClick={() => {
            window.location.reload();
          }}
          color="primary"
          style={{ float: "right" }}
        >
          {text || "Retry"}
        </Button>
      </div>
    );
  };

  const renderExplanationDialog = () => {
    return (
      <div>
        <Typography variant="h5" color="primary" className={classes.header}>
          Allow the App to access your <span>camera and microphone</span>
        </Typography>
        <Typography variant="body1">
          The app needs to access your microphone and camera so that you can
          receive voice and video calls from the BahamasEvac Mobile App Users.
        </Typography>
      </div>
    );
  };

  const renderUserDeniedDialog = () => {
    return (
      <div>
        <Typography variant="h5" color="primary" className={classes.header}>
          <span>Microphone and camera</span> are blocked
        </Typography>
        <Typography variant="body1">
          App requires access to your microphone and camera.{" "}
          {browser.getBrowserName() !== "Safari" && (
            <span className={classes.withIcon}>
              Click the camera blocked icon{" "}
              <img
                alt="icon"
                src="https://www.gstatic.com/meet/ic_blocked_camera_dark_f401bc8ec538ede48315b75286c1511b.svg"
              />{" "}
              in your browser&apos;s address bar to enable them.
            </span>
          )}
        </Typography>
        {renderTryAgain()}
      </div>
    );
  };

  const renderSystemDeniedDialog = () => (
    <div>
      <Typography variant="h5" color="primary" className={classes.header}>
        Can&apos;t use your <span>microphone and camera</span>
      </Typography>
      <Typography>
        Your browser might not have access to your microphone or camera. To fix
        this problem allow the browser to access them in{" "}
        {
          // @ts-ignore
          browser.getOSName() === "macOS" ? (
            <a
              target="_blank"
              rel="noreferrer"
              href="x-apple.systempreferences:com.apple.preference.security?Privacy_Camera"
            >
              System Preferences.
            </a>
          ) : (
            "System Settings"
          )
        }
      </Typography>
      {renderTryAgain()}
    </div>
  );

  const renderTrackErrorDialog = () => {
    return (
      <div>
        <Typography variant="h5" color="primary" className={classes.header}>
          Can&apos;t start your <span>microphone and/or camera</span>
        </Typography>
        <Typography>
          Another application (Zoom, Webex) or browser tab (Google Meet,
          Messenger Video) might already be using your webcam. Please turn off
          other cameras before proceeding.
        </Typography>
        {renderTryAgain()}
      </div>
    );
  };

  const renderDialogContent = () => {
    switch (showDialog) {
      case DialogType.explanation:
        return renderExplanationDialog();
      case DialogType.systemDenied:
        return renderSystemDeniedDialog();
      case DialogType.userDenied:
        return renderUserDeniedDialog();
      case DialogType.trackError:
        return renderTrackErrorDialog();
      default:
        return null;
    }
  };

  useEffect(() => {
    checkMediaPermissions();
  }, [checkMediaPermissions]);

  return (
    <Dialog
      classes={{ paper: classes.paper, root: classes.root }}
      open={!!showDialog}
    >
      {showDialog && renderDialogContent()}
    </Dialog>
  );
};

export default MediaOnboardingDialog;
