import { createContext } from "react";
import { Socket } from "socket.io-client";

const obj = {
  socket: {} as Socket,
  isSocketConnected: false,
};

const SiteContext = createContext(obj);
export const SiteContextProvider = SiteContext.Provider;

export default SiteContext;
