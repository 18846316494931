import { loadPaymentScript } from "./mountScript";

interface PnpResponseType {
  FinalStatus: "problem" | "success";
  MErrMsg: string;
  MStatus: "problem" | "success";
  receiptcc: string;
  orderID: string;
}

declare global {
  interface Window {
    payment_api: {
      send: () => void;
      setCallback: (callback?: (...arg: any[]) => void) => void;
    };
  }
}

const decodePnpData = (data: string) => {
  const newData = decodeURI(data).split("&");
  return newData.reduce((res: Record<string, any>, cur: string) => {
    const [name, value] = cur.split("=");
    res[name] = value;
    return res;
  }, {}) as PnpResponseType;
};

const script = `https://pay1.plugnpay.com/api/iframe/${process.env.REACT_APP_PNP_SITE_KEY}/client/`;

const jQuery =
  "https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js";

const MERCHANT_NAME = process.env.REACT_APP_MERCHANT_NAME;

const initializeComponentForPayment = (
  callback?: (...v: any[]) => void,
  setLoading?: (...v: any[]) => void
) => {
  try {
    if (!window.payment_api) {
      if (setLoading) setLoading(true);

      const allScripts = [
        loadPaymentScript.append(script),
        loadPaymentScript.append(jQuery),
      ];

      Promise.all(allScripts).then(() => {
        // makes sure window object has payment_api key object
        setTimeout(() => {
          if (setLoading) setLoading(false);
          window.payment_api?.setCallback(callback);
        }, 1000);
      });
    } else {
      if (setLoading) setLoading(false);
      window.payment_api?.setCallback(callback);
    }

    return () => {
      window.payment_api?.setCallback(() => {});
      loadPaymentScript.remove(script);
      loadPaymentScript.remove(jQuery);
      // loadPaymentScript.removeIframe("payment_api");
    };
  } catch {
    //
  } finally {
    if (setLoading) setLoading(false);
  }
};

export const pnpUtils = {
  jQuery,
  script,
  MERCHANT_NAME,
  initializeComponentForPayment,
  decodePnpData,
};
