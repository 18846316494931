import { useCallback, useMemo } from "react";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import { composeUserName, formatDateToDisplay } from "helpers";
import { Member } from "call/components/CallForms/RequesterAndPatientForm/types";
import { FormValues } from "./types";

interface StyleProps {
  clickable?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(
  ({ spacing: s, palette: p }) => ({
    heading: {
      fontSize: 20,
    },
    header: {
      padding: s(2),
      backgroundColor: "#FFF",
    },
    tableContainer: {
      margin: s(1, 0),
      border: "1px solid #E0E0E0",
      borderRadius: "4px",
      backgroundColor: "#ECECEC",
      "& .MuiTableCell-root": {
        color: p.primary.dark,
        fontSize: s(1.5),
        padding: s(1),
        minWidth: 100,
        letterSpacing: "0.15px",
      },
    },
    tableBody: {
      backgroundColor: "#FFF",
      "& .MuiTableRow-root:hover": {
        background: ({ clickable }) => (clickable ? "#BDBDBD" : "#FFF"),
        cursor: "pointer",
      },
    },
    memberships: {
      display: "flex",
      flexDirection: "column",
    },
    membershipItem: {
      "& span": {
        color: "#828282",
      },
    },
  })
);

export interface SearchedMemberProps {
  members: Member[];
  onClickRow?: (member: Member) => void;
  filterMembersByPatients?: boolean;
  disableForm: boolean;
}

export const SearchedMembers = ({
  onClickRow,
  members: m,
  filterMembersByPatients = false,
  disableForm,
}: SearchedMemberProps) => {
  const {
    values: { patients },
  } = useFormikContext<FormValues>();
  const classes = useStyles({ clickable: !!onClickRow });

  const handleRowClick = useCallback(
    (member: Member) => {
      if (disableForm) {
        return;
      }
      if (onClickRow) {
        onClickRow(member);
      }
    },
    [onClickRow, disableForm]
  );

  const patientContactIds = useMemo(
    () => patients.map((p) => p.contactID),
    [patients]
  );

  const members = useMemo(() => {
    if (!filterMembersByPatients) {
      return m;
    }

    return m.filter((i) => !patientContactIds.includes(i.id));
  }, [m, filterMembersByPatients, patientContactIds]);

  return (
    <TableContainer className={classes.tableContainer}>
      <Table aria-label="searched members">
        <TableHead>
          <TableRow>
            <TableCell>Full Name</TableCell>
            <TableCell width={160}>Membership(s)</TableCell>
            <TableCell>Date of Birth</TableCell>
            <TableCell>Citizenship</TableCell>
            <TableCell>Address</TableCell>
          </TableRow>
        </TableHead>

        <TableBody className={classes.tableBody}>
          {members.map((member) => (
            <TableRow key={member.id} onClick={() => handleRowClick(member)}>
              <TableCell>
                {composeUserName({
                  firstName: member.firstName,
                  lastName: member.lastName,
                })}
              </TableCell>
              <TableCell>
                <div className={classes.memberships}>
                  {member.memberships.map((membership, index) => (
                    <div
                      className={classes.membershipItem}
                      key={`${membership.number}-${
                        membership.type
                      }${index.toString()}`}
                    >
                      {membership.number} <span>({membership.type})</span>
                    </div>
                  ))}
                </div>
              </TableCell>
              <TableCell>
                {formatDateToDisplay(member.dob, "dd MMM yyyy")}
              </TableCell>
              <TableCell>{member.citizenships.join(", ")}</TableCell>
              <TableCell>{member.address}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
