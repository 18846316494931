import React, { useEffect, useMemo } from "react";
import { Field, FormikProps } from "formik";
import ReactDOMServer from "react-dom/server";
import { TextField } from "formik-material-ui";
import { LocationOn } from "@material-ui/icons";
import {
  LocationTypeEnum,
  ServiceCaseStatusEnum,
} from "@deep-consulting-solutions/be2-constants";
import {
  Box,
  Divider,
  MenuItem,
  makeStyles,
  Typography,
} from "@material-ui/core";

import { useCall } from "call/hooks";
import { useMap } from "call/context";

import { TabsEnum } from "../types";
import { tabsEnumToNumber } from "../helpers";
import { LocationSearch } from "./LocationSearch";
import { LocationSummary } from "./LocationSummary";
import type { LocationFormValues } from "./LocationForm";
import { LocationInfoWindow } from "./LocationInfoWindow";
import { MedicalFacilityField } from "./MedicalFacilityField";

type DestinationLocationProps = FormikProps<LocationFormValues> & {
  activeTab: TabsEnum;
};

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  title: {
    color: p.primary.main,
    fontFamily: "'Roboto', sans-serif",
  },
  textField: {
    width: "217px",
    height: "54px",
    marginRight: s(2),

    "& .MuiFormLabel-root, & .MuiInputBase-root": {
      fontSize: 14,
    },
  },
  destination: {
    "& .MuiFormHelperText-root": {
      marginLeft: 0,
    },
  },
  moreAddressDetails: {
    fontSize: 12,

    "& .MuiInputBase-input::placeholder": {
      fontSize: 12,
    },
  },
}));

export const DestinationLocation = (props: DestinationLocationProps) => {
  const { viewedCall } = useCall();
  const { activeTab, values } = props;
  const classes = useStyles();
  const {
    map,
    getMarker,
    setMapZoom,
    createInfoWindow,
    updateMarkerPosition,
    createDestinationMarker,
  } = useMap();

  const label = useMemo(() => {
    switch (values.destinationType) {
      case LocationTypeEnum.AIRPORT:
        return "Search for Airport by Name, City, or Country";

      case LocationTypeEnum.CUSTOM:
        return "Search by Name, City, or Country";

      default:
        return "Search for Medical Facility by Name, City, or Country";
    }
  }, [values.destinationType]);

  const infoWindow = useMemo(() => {
    if (
      map &&
      tabsEnumToNumber(activeTab) < tabsEnumToNumber(TabsEnum.PLANNING)
    ) {
      return createInfoWindow({
        content: ReactDOMServer.renderToString(
          <LocationInfoWindow
            showAddress
            title="Destination"
            location={values.destination}
          />
        ),
      });
    }
  }, [map, activeTab, createInfoWindow, values.destination]);

  const disableForm = useMemo(() => {
    return (
      !!viewedCall?.disableForms ||
      viewedCall?.updatedServiceCase?.caseStatus !==
        ServiceCaseStatusEnum.GATHERING_REQUIREMENTS
    );
  }, [viewedCall?.disableForms, viewedCall?.updatedServiceCase?.caseStatus]);

  useEffect(() => {
    setMapZoom(8);
    createDestinationMarker({});
  }, [setMapZoom, createDestinationMarker]);

  useEffect(() => {
    const { lat, lng } = values.destination;

    if (lat && lng) {
      updateMarkerPosition("destination", { lat, lng });
    }
  }, [values.destination, updateMarkerPosition]);

  useEffect(() => {
    const marker = getMarker("destination");

    if (marker && infoWindow) {
      const event = marker.addListener("click", () => {
        if (infoWindow.getPosition()) {
          infoWindow.close();
          infoWindow.setPosition(undefined);
        } else {
          infoWindow.open(map, marker);
        }
      });

      return () => {
        event.remove();
        infoWindow.close();
        google.maps.event.clearListeners(marker, "click");
      };
    }
  }, [map, getMarker, infoWindow]);

  return (
    <div>
      <Typography variant="h5" className={classes.title}>
        Destination Location
      </Typography>

      <Box mt={1} mb={2}>
        <Divider style={{ backgroundColor: "1px solid #565C631A" }} />
      </Box>

      <Box display="flex">
        <Box
          width={32}
          height={32}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <LocationOn width={32} height={32} style={{ color: "#08A40F" }} />
        </Box>

        <Box width="450px">
          <Box display="flex" alignItems="center" mb={2}>
            <Field
              select
              required
              size="small"
              component={TextField}
              name="destinationType"
              disabled={disableForm}
              label="Destination Type"
              className={classes.textField}
              SelectProps={{
                displayEmpty: true,
              }}
            >
              {Object.entries(LocationTypeEnum).map(([key, value]) => (
                <MenuItem key={key} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Field>

            {values.destinationType === LocationTypeEnum.MEDICAL_FACILITY && (
              <MedicalFacilityField<LocationFormValues>
                formikProps={props}
                disabled={disableForm}
                fieldName="destination"
                name="destinationMedicalFacility"
              />
            )}

            {values.destinationType === LocationTypeEnum.CUSTOM && (
              <Field
                required
                size="small"
                label="Custom Type"
                component={TextField}
                disabled={disableForm}
                name="customDestination"
                placeholder="Custom Type"
                className={classes.textField}
                style={{ marginRight: 0 }}
              />
            )}
          </Box>

          <LocationSearch
            label={label}
            name="destination"
            formikProps={props}
            disabled={disableForm}
          />
        </Box>
      </Box>

      {values.destination?.dms && (
        <Box display="flex" width={482} justifyContent="space-between" mt={1}>
          <Box sx={{ ml: 4 }}>
            <LocationSummary location={values.destination} />
          </Box>

          <Box>
            <Field
              size="small"
              variant="outlined"
              component={TextField}
              disabled={disableForm}
              name="moreDestinationDetails"
              placeholder="More Address Details"
              className={classes.moreAddressDetails}
            />
          </Box>
        </Box>
      )}
    </div>
  );
};
