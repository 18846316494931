import React, { ChangeEvent, useEffect, useMemo } from "react";
import { Box, makeStyles, Typography, TextField } from "@material-ui/core";
import { CardContent } from "pages/FulfilmentDashboard/components";
import { ServiceCaseResponse } from "@deep-consulting-solutions/be2-constants";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { formatNameToDisplay } from "helpers";
import Loader from "component/Loader/Loader";

const useStyles = makeStyles({
  filePreview: {
    display: "flex",
    alignItems: "center",
    margin: "8px 0px",
  },
  patientName: {
    fontWeight: 500,
    fontSize: 20,
    textTransform: "capitalize",
  },
});

type RespT = {
  firstName: string;
  lastName: string;
  title: string;
  id: string;
}[];

interface PatientReleasedCardProps {
  data: RespT;
  loading?: boolean;
  zohoCRMUrl: string;
  errors?: Record<number, any>;
  onChange?: (res: RespT) => void;
  patients?: ServiceCaseResponse["patients"];
}

const PatientReleasedCard = ({
  patients: unfiltered,
  data,
  onChange,
  errors,
  loading,
  zohoCRMUrl,
}: PatientReleasedCardProps) => {
  const classes = useStyles();

  const patients = useMemo(
    () => unfiltered?.filter((patient) => !patient.serviceCancelled),
    [unfiltered]
  );

  const handleChange = (pos: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const changes = data.map((el, i) => {
      if (pos === i) {
        return {
          ...el,
          id: patients ? patients[i].patientID : "",
          [e.target.name]: e.target.value,
        };
      }
      return el;
    });
    if (onChange) onChange(changes);
  };

  useEffect(() => {
    if (patients?.length && onChange)
      onChange(
        Array(patients?.length).fill({
          id: "",
          firstName: "",
          lastName: "",
          title: "",
        }) as RespT
      );
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      <Loader absolute open={Boolean(loading)} />
      <Typography color="primary" variant="h4">
        Patient Released
      </Typography>
      <Box
        bgcolor="#fdf1e7"
        display="grid"
        gridTemplateColumns="auto 1fr"
        gridGap={10}
        alignItems="center"
        px="16px"
        py="14px"
        mb={2}
        mt={2}
        borderRadius="4px"
      >
        <ReportProblemOutlinedIcon
          style={{
            color: "#ED6C02",
          }}
        />

        <Typography
          variant="body2"
          style={{
            color: "#5f2b01",
          }}
        >
          {zohoCRMUrl ? (
            <>
              Make sure that the Patient Care Report has been signed and then{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${zohoCRMUrl}?redirect=false#${process.env.REACT_APP_SERVICE_CASE_ATTACHMENTS_TAG}`}
              >
                click here
              </a>{" "}
              to upload it to the service case record attachments in the CRM.
            </>
          ) : (
            <>
              Make sure that the Patient Care Report has been signed and then
              upload it to the service case record attachments in the CRM.
            </>
          )}
        </Typography>
      </Box>

      {patients && patients.length ? (
        patients.map(({ firstName, lastName }, i) => (
          <CardContent
            mb={2}
            noBorder
            boxShadow="0px 3px 5px rgba(0, 0, 0, 0.12)"
          >
            <Typography className={classes.patientName} color="primary">
              {formatNameToDisplay(firstName, lastName)}
            </Typography>
            <Box>
              <Typography
                variant="subtitle1"
                style={{ color: "#4F4F4F", margin: "20px 0px" }}
              >
                Receiving Physician
              </Typography>
              <Box
                display="grid"
                gridTemplateColumns="1fr 1fr 1fr"
                px="5px"
                gridGap={16}
              >
                <TextField
                  onChange={handleChange(i)}
                  name="firstName"
                  size="small"
                  label="First Name"
                  error={errors && errors[i]?.firstName}
                  required
                />
                <TextField
                  onChange={handleChange(i)}
                  name="lastName"
                  error={errors && errors[i]?.lastName}
                  size="small"
                  label="Last Name"
                  required
                />
                <TextField
                  onChange={handleChange(i)}
                  name="title"
                  error={errors && errors[i]?.title}
                  size="small"
                  label="Title"
                  required
                />
              </Box>
            </Box>
          </CardContent>
        ))
      ) : (
        <Box padding={1} display="flex" justifyContent="center">
          <Typography color="primary" variant="body2">
            Patient(s) information not gathered from the requester yet.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default PatientReleasedCard;
