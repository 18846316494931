import {
  ATTENTION_CHECK_CHECKIN_MAX_IN_SECONDS,
  ATTENTION_CHECK_NO_ACTION_TIME_SPAN_IN_MS,
} from "configs";
import { ROUTES } from "component/configs";

export const hideAttentionCheck = (pathname: string) => {
  return (
    pathname !== ROUTES.fulfilment.path &&
    pathname !== ROUTES.fulfilmentUnAnsweredRequest.path
  );
};

export const getDisplayedSeconds = (secondsLeft: number) => {
  return secondsLeft - (ATTENTION_CHECK_CHECKIN_MAX_IN_SECONDS - 30);
};

export const display2Digits = (num: number) => {
  return num < 10 ? `0${num}` : `${num}`;
};

export const formatDisplayedSeconds = (displayedSeconds: number) => {
  const absSeconds = Math.abs(displayedSeconds);
  const s = absSeconds % 60;
  const m = (absSeconds - s) / 60;
  return `${displayedSeconds < 0 ? "- " : ""}${display2Digits(
    m
  )}:${display2Digits(s)}`;
};

export const isEntryTimeWithinPermittedTime = (d: Date) => {
  const now = new Date();
  const min = now.getTime() - ATTENTION_CHECK_NO_ACTION_TIME_SPAN_IN_MS;
  return d.getTime() >= min;
};

export const getTimeoutForNextCheck = (d: Date) => {
  const now = new Date();
  const ms = d.getTime() + ATTENTION_CHECK_NO_ACTION_TIME_SPAN_IN_MS;
  return ms - now.getTime();
};
