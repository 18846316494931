import React from "react";
import { useStyles } from "./style";
import { Icon } from "./types";
import screenshot from "./screenshot.png";

export const Screenshot = ({ onClick }: Icon) => {
  const classes = useStyles();
  return (
    <img
      onClick={onClick}
      src={screenshot}
      alt="screenshot"
      className={classes.icon}
      aria-hidden="true"
    />
  );
};
