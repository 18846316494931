import React from "react";
import { Grid, Typography, makeStyles, Theme } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

const useStyles = makeStyles<Theme, Pick<Props, "variant">>(
  ({ spacing: s, palette: p }) => ({
    wrapper: {
      background: ({ variant }) =>
        variant === "error" ? "#CA2027" : p.primary.main,
      color: "white",
      borderRadius: 4,
      margin: s(2, 0),
      width: "100%",
    },
    ul: {
      padding: 0,
      margin: 0,
      width: "100%",
    },
  })
);

interface Props {
  visible: boolean;
  texts: string[];
  variant?: "error" | "info";
}

export const InfoCard = ({ visible, texts, variant = "info" }: Props) => {
  const classes = useStyles({ variant });

  if (!visible) {
    return null;
  }

  return (
    <Grid
      container
      spacing={4}
      alignItems="flex-start"
      justifyContent="flex-start"
      className={classes.wrapper}
      wrap="nowrap"
    >
      <Grid item>
        <InfoOutlined />
      </Grid>
      <Grid item>
        <ul className={classes.ul}>
          {texts.map((text) => (
            <li key={text}>
              <Typography align="left" variant="subtitle2">
                {text}
              </Typography>
            </li>
          ))}
        </ul>
      </Grid>
    </Grid>
  );
};
