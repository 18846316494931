import React, { useMemo } from "react";
import { Box, Typography, Grid, makeStyles, Theme } from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";

interface Props {
  breakdown: {
    activationDate: string;
    newRecurringFee: number;
    oldRecurringFee: number;
    proRatedFee: number;
    recurringPeriodFee: number;
  };
}

interface TimelineProps {
  background: string;
  title: string;
  numberOfDays: string;
  dateTitle: string;
  date: string;
  id: number;
}

interface Style {
  background?: string;
}

const useStyles = makeStyles<Theme, Style>(({ spacing: s }) => ({
  chart: {
    background: "#F8F8F8",
    boxShadow: "1px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 4,
    maxWidth: 400,
    minHeight: 160,
    margin: "auto",
    marginTop: s(2),
    marginBottom: s(2),
    padding: s(2),
  },
  bar: {
    height: 50,
    padding: 3,
    position: "relative",
  },
  barTitle: {
    fontSize: 8,
    color: "black",
    padding: 0,
    margin: 0,
    lineHeight: 0.5,
  },
  barDays: {
    fontSize: 8,
    color: "black",
    padding: 0,
    margin: 0,
    lineHeight: 0.5,
  },
  barDate: {
    position: "absolute",
    top: "100%",
    left: "50%",
    width: "100%",
    textAlign: "center",
    color: "black",
    fontSize: 8,
    display: "block",
    zIndex: 9,
    marginTop: -10,
  },
  line: {
    width: 1,
    background: "black",
    height: 20,
    marginTop: -10,
  },
  longer: {
    height: 50,
  },
  background: ({ background }) =>
    background
      ? {
          background,
        }
      : {},
}));

const Timeline = ({
  background,
  title,
  numberOfDays,
  dateTitle,
  date,
  id,
}: TimelineProps) => {
  const classes = useStyles({
    background,
  });
  return (
    <div className={`${classes.bar} ${classes.background}`}>
      <div>
        <Typography variant="caption" className={classes.barTitle}>
          {title}
        </Typography>
      </div>
      {!!numberOfDays && (
        <div>
          <Typography variant="caption" className={classes.barDays}>
            ({numberOfDays} DAYS)
          </Typography>
        </div>
      )}
      {!!dateTitle && (
        <div className={classes.barDate}>
          <Grid
            container
            alignItems="center"
            justify="flex-start"
            spacing={0}
            wrap="nowrap"
            direction="column"
          >
            <Grid item>
              <FiberManualRecord fontSize="small" />
            </Grid>
            <Grid item>
              <div
                className={`${classes.line} ${
                  Math.floor(id / 2) === id / 2 ? classes.longer : ""
                } `}
              >
                {" "}
              </div>
            </Grid>
            <Grid item>
              <div>
                <Typography variant="caption" className={classes.barTitle}>
                  {dateTitle}
                </Typography>
              </div>
              <div>
                <Typography variant="caption" className={classes.barTitle}>
                  {date}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export const ProRatedFee = ({
  breakdown: {
    activationDate,
    oldRecurringFee,
    newRecurringFee,
    proRatedFee,
    recurringPeriodFee,
  },
}: Props) => {
  const classes = useStyles({});

  const daysFromToday = useMemo(() => {
    const todayInMilliseconds = new Date().getTime();
    const activationDateInMilliseconds = new Date(activationDate).getTime();
    const differenceInMilliseconds =
      todayInMilliseconds - activationDateInMilliseconds;
    const daysToMilliseconds = 86400000;
    return differenceInMilliseconds / daysToMilliseconds;
  }, [activationDate]);
  return (
    <Box>
      <Typography>
        We charge you a pro-rated fee for the number of days between the
        activation date ({daysFromToday} days from today) and the date of your
        next payment (when you start paying the new recurring rate).
      </Typography>

      <Box>
        <Grid container spacing={0} className={classes.chart} wrap="nowrap">
          <Grid item xs={3}>
            <Timeline
              background="linear-gradient(270deg, #7CD0FF 0%, rgba(124, 208, 255, 0) 100%)"
              title=""
              numberOfDays=""
              dateTitle="Today"
              date="21-4-2021"
              id={1}
            />
          </Grid>
          <Grid item xs={3}>
            <Timeline
              background="#FCFF6B"
              title="ACTIVATION PERIOD"
              numberOfDays="15"
              dateTitle="ACTIVATION DATE"
              date="21-4-2021"
              id={2}
            />
          </Grid>
          <Grid item xs={3}>
            <Timeline
              background="#7CFFEF"
              title="PRO-RATED PERIOD"
              numberOfDays="36"
              dateTitle="NEXT PAYMENT DATE"
              date="21-4-2021"
              id={3}
            />
          </Grid>
          <Grid item xs={3}>
            <Timeline
              background="linear-gradient(270deg, rgba(124, 208, 255, 0) 0%, #7CD0FF 20.31%)"
              title="NEXT BILLING CYCLE"
              numberOfDays=""
              dateTitle=""
              date=""
              id={4}
            />
          </Grid>
        </Grid>
      </Box>

      <Typography>
        The daily fee is calculated as the difference between your new recurring
        fee and your old recurring fee ({newRecurringFee}- {oldRecurringFee} ={" "}
        {newRecurringFee - oldRecurringFee}) divided by the number of days in
        your billing cycle ({newRecurringFee - oldRecurringFee} / 90 ={" "}
        {Math.floor((newRecurringFee - oldRecurringFee) / 90)}). Then we
        calculate the additional pro-rated fee by multiplying that value by the
        number of days between the activation date (15 days from now) and the
        date of your next payment:
      </Typography>
      <Box mt={2}>
        <Typography>
          Old recurring fee minus new recurring fee: {oldRecurringFee} -
          {recurringPeriodFee} = {oldRecurringFee - newRecurringFee}
          <br />
          Daily pro-rated fee: BSD{proRatedFee} / 90 ={" "}
          {Math.floor(proRatedFee / 90)}
          <br />
          Number of pro-rated days: 36 (from 06-05-2021 to 12-06-2021)
          <br />
          Total pro-rated fee: {proRatedFee} x 36 = {`BSD${proRatedFee * 36}`}
        </Typography>
      </Box>
    </Box>
  );
};
