import browser from "browser-detect";

import { ROUTES } from "component/configs";

export const isFulfilmentWebtabPath = (pathname: string) => {
  return [
    ROUTES.fulfilment.path,
    ROUTES.fulfilmentUnAnsweredRequest.path,
    ROUTES.caseClassifications.path,
    ROUTES.caseClassificationsWidget.path,
    pathname === ROUTES.locationCollector.path,
  ].includes(pathname);
};

export const detectBrowser = () => {
  const currentBrowser = browser();
  const browserName = currentBrowser.name;

  return {
    browserName: browserName
      ? browserName[0].toUpperCase() + browserName.slice(1)
      : "unsupported",
    isChrome: currentBrowser.name === "chrome",
  };
};
