import React, { FC } from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  Box,
  Button,
  Dialog,
  makeStyles,
  Typography,
  IconButton,
  DialogProps,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core";

import Loader from "../Loader";

export interface ConfirmationDialogProps extends DialogProps {
  title: string;
  loading?: boolean;
  onClose: () => void;
  hideCloseButton?: boolean;
  hideCancelButton?: boolean;
  disableCancelButton?: boolean;
  disableConfirmButton?: boolean;
  confirmButtonClassName?: string;
  onConfirm?: (...args: any[]) => any;
  cancelButtonContent?: React.ReactNode;
  confirmButtonContent?: React.ReactNode;
}

const useStyles = makeStyles(({ spacing: s }) => ({
  dialog: {
    position: "relative",
  },
  dialogActions: {
    padding: s(1, 3, 3),
  },
}));

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  open,
  title,
  onClose,
  children,
  confirmButtonClassName,
  loading = false,
  onConfirm = () => {},
  hideCloseButton = false,
  hideCancelButton = false,
  disableCancelButton = false,
  disableConfirmButton = false,
  cancelButtonContent = "Cancel",
  confirmButtonContent = "Confirm",
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Dialog {...rest} open={open} onClose={onClose} className={classes.dialog}>
      <Loader open={loading} absolute />
      <DialogTitle disableTypography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" color="primary">
            {title}
          </Typography>

          {!hideCloseButton && (
            <IconButton aria-label="close" size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions className={classes.dialogActions}>
        {!hideCancelButton && (
          <Button
            type="button"
            variant="text"
            color="primary"
            onClick={onClose}
            disabled={disableCancelButton}
          >
            {cancelButtonContent}
          </Button>
        )}

        <Button
          type="button"
          color="primary"
          variant="contained"
          onClick={onConfirm}
          disabled={disableConfirmButton}
          className={confirmButtonClassName}
        >
          {confirmButtonContent}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
