import {
  Box,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  makeStyles,
  Typography,
  TableFooter,
  TableContainer,
  TablePagination,
} from "@material-ui/core";
import clsx from "clsx";
import { StyledTypography } from "component/StyledTypography";
import React, { useState, useEffect, useCallback } from "react";
import {
  SortOrderEnum,
  DispatcherActivityLog as Log,
  DispatcherActivityLogTypeEnum,
  GetDispatcherActivityLogsSortByEnum,
} from "@deep-consulting-solutions/be2-constants";
import {
  ArrowUpward,
  ArrowDownward,
  Close as CloseIcon,
  FilterList as FilterListIcon,
} from "@material-ui/icons";

import Loader from "component/Loader";
import TableActions from "component/TableActions";
import { useAppDispatch, useAppSelector } from "redux/store";
import {
  dispatcherActions,
  dispatcherRequests,
  dispatcherSelectors,
} from "redux/dispatcher";

import { LogEntry } from "./LogEntry";
import FiltersModal from "./FiltersModal";
import { DynamicHeading } from "./DynamicHeading";

const useStyles = makeStyles(({ spacing: s }) => ({
  root: {
    padding: "20px",
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "20px",
  },
  filterButton: {
    background: "transparent",
    "& .MuiTypography-root": {
      fontSize: 15,
      fontWeight: 700,
    },
  },
  clearAllButton: {
    background: "transparent",
    "& .MuiTypography-root": {
      fontSize: 12,
      fontWeight: 700,
    },
  },
  tableContainer: {
    borderRadius: "8px",
  },
  tableHead: {
    "& .MuiTableCell-head": {
      padding: s(1),
      backgroundColor: "#ececec",
    },
  },
  tableBody: {
    "& .MuiTableRow-root": {
      backgroundColor: "#fff",
    },
  },
  select: {
    width: "auto",

    "& .MuiSelect-root": {
      fontSize: 12,
      paddingTop: "9px",
    },
  },
  dynamicHeader: {
    fontWeight: 700,
  },
  icon: {
    fontSize: 16,
    marginRight: s(1),
    color: "rgba(0,0,0,0.54)",
  },
  activeIcon: {
    color: "black",
  },
  pagination: {
    "& .MuiTablePagination-caption": {
      fontSize: 12,
    },
  },
}));

const toggleSortOrder = (order: SortOrderEnum) =>
  order === SortOrderEnum.ASC ? SortOrderEnum.DESC : SortOrderEnum.ASC;

const defaultFilters = Object.values(DispatcherActivityLogTypeEnum);

export const DispatcherActivityLog = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [order, setOrder] = useState(SortOrderEnum.DESC);
  const [logEntries, setLogEntries] = useState<Log[]>([]);
  const { zohoID } = useAppSelector((state) => state.dispatcher);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [sortBy, setSortBy] = useState(
    GetDispatcherActivityLogsSortByEnum.ENTRY_TIME
  );
  const dispatcherRoles = useAppSelector(dispatcherSelectors.roles);
  const [activeFilters, setActiveFilters] =
    useState<DispatcherActivityLogTypeEnum[]>(defaultFilters);

  const onFiltersSelect = useCallback(
    (values: Record<DispatcherActivityLogTypeEnum, boolean>) => {
      setActiveFilters(
        (
          Object.entries(values) as [DispatcherActivityLogTypeEnum, boolean][]
        ).reduce((acc, [filter, value]) => {
          if (value) {
            acc.push(filter);
          }

          return acc;
        }, [] as DispatcherActivityLogTypeEnum[])
      );

      setShowFiltersModal(false);
    },
    []
  );

  const getRecords = useCallback(async () => {
    if (zohoID) {
      setLoading(true);

      try {
        const res = await dispatcherRequests.getRecordsRequest(zohoID, {
          page,
          order,
          sortBy,
          perPage: rowsPerPage,
          types: activeFilters.join(","),
        });

        setTotal(res.total);
        setLogEntries(res.logEntries);
      } catch (err) {
        //
      } finally {
        setLoading(false);
      }
    }
  }, [page, sortBy, order, zohoID, rowsPerPage, activeFilters]);

  const handlePageChange = useCallback((_, newPage: number) => {
    setPage(newPage + 1);
  }, []);

  const handleRowsPerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(1);
    },
    []
  );

  const updateSort = useCallback(
    (newSort: GetDispatcherActivityLogsSortByEnum) => () => {
      if (sortBy === newSort) {
        setOrder(toggleSortOrder(order));
      } else {
        setSortBy(newSort);
        setOrder(SortOrderEnum.DESC);
      }
    },
    [sortBy, order]
  );

  const composeIcon = useCallback(
    (sortLabel: GetDispatcherActivityLogsSortByEnum) => {
      if (sortLabel === sortBy) {
        if (order === SortOrderEnum.ASC) {
          return (
            <ArrowUpward className={clsx(classes.icon, classes.activeIcon)} />
          );
        }
        return (
          <ArrowDownward className={clsx(classes.icon, classes.activeIcon)} />
        );
      }

      return <ArrowDownward className={classes.icon} />;
    },
    [order, sortBy, classes.icon, classes.activeIcon]
  );

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  useEffect(() => {
    if (dispatcherRoles.isDispatcher) {
      dispatch(dispatcherActions.fetchDispatcherActivityRecord());
    }
  }, [dispatch, dispatcherRoles.isDispatcher]);

  useEffect(() => {
    dispatch(dispatcherActions.getRoles());
  }, [dispatch]);

  return (
    <Box className={classes.root}>
      <Loader open={loading} absolute />

      <Box display="flex" justifyContent="space-between" mb={5}>
        <StyledTypography
          variant="subtitle2"
          color="primary.main"
          className={classes.title}
        >
          Dispatcher Activity Log
        </StyledTypography>

        <CloseIcon />
      </Box>

      <Box display="flex" justifyContent="space-between" mb={2.5}>
        <Button
          startIcon={<FilterListIcon />}
          className={classes.filterButton}
          onClick={() => setShowFiltersModal(true)}
        >
          <StyledTypography variant="subtitle2" color="primary.dark">
            Filter
          </StyledTypography>
        </Button>

        <Button
          className={classes.clearAllButton}
          onClick={() => setActiveFilters(defaultFilters)}
        >
          <StyledTypography variant="subtitle2" color="primary.dark">
            Clear All Filters
          </StyledTypography>
        </Button>
      </Box>

      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <DynamicHeading
                title="Log Event Type"
                updateSort={updateSort}
                composeIcon={composeIcon}
                sortLabel={GetDispatcherActivityLogsSortByEnum.TYPE}
              />

              <DynamicHeading
                updateSort={updateSort}
                composeIcon={composeIcon}
                title="Entry Time (Date-Time)"
                sortLabel={GetDispatcherActivityLogsSortByEnum.ENTRY_TIME}
              />

              <TableCell width="30%">
                <Typography
                  style={{ paddingLeft: "8px" }}
                  className={classes.dynamicHeader}
                >
                  Description
                </Typography>
              </TableCell>

              <DynamicHeading
                updateSort={updateSort}
                composeIcon={composeIcon}
                title="Regular Check-In Time"
                sortLabel={GetDispatcherActivityLogsSortByEnum.CHECKIN_TIME}
              />
            </TableRow>
          </TableHead>

          <TableBody className={classes.tableBody}>
            {logEntries.map((logEntry) => (
              <LogEntry {...logEntry} key={logEntry.id} />
            ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                count={total}
                page={page - 1}
                rowsPerPage={rowsPerPage}
                className={classes.pagination}
                onPageChange={handlePageChange}
                ActionsComponent={TableActions}
                rowsPerPageOptions={[10, 20, 50]}
                onRowsPerPageChange={handleRowsPerPageChange}
                SelectProps={{
                  className: classes.select,
                  inputProps: { "aria-label": "rows per page" },
                }}
                labelRowsPerPage={
                  <Typography
                    variant="body2"
                    style={{ color: "rgba(0,0,0,0.54)", fontSize: 12 }}
                  >
                    Rows per page:
                  </Typography>
                }
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {showFiltersModal && (
        <FiltersModal
          activeFilters={activeFilters}
          onFiltersSelect={onFiltersSelect}
          onClose={() => setShowFiltersModal(false)}
        />
      )}
    </Box>
  );
};
