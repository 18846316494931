export enum TabsEnum {
  MESSAGES = "Messages",
  MEDIA = "Media",
  RECORDINGS = "Recordings",
}

export type CallChatTabProps = {
  id: string;
  "aria-controls": string;
};

export type CallChatTab = {
  label: TabsEnum;
  props: CallChatTabProps;
  index: number;
};
