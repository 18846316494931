import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useCallback } from "react";
import { useCall } from "../../hooks";

const useStyles = makeStyles(({ spacing: s }) => ({
  title: {},
  content: {},
  actions: {},
  wrapper: {
    padding: s(4),
  },
}));

export const PickCallDialog = () => {
  const {
    showPickCallRequestDialog,
    cancelPickCallRequest,
    completePickCallRequest,
  } = useCall();

  const handleProceed = useCallback(() => {
    completePickCallRequest();
  }, [completePickCallRequest]);

  const classes = useStyles();

  return (
    <Dialog open={showPickCallRequestDialog} className={classes.wrapper}>
      <DialogTitle className={classes.title}>
        <Typography align="center" color="primary" variant="h4">
          Answering Incoming Call
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant="body1">
          This action will put the current call on hold. Please notify the
          caller before proceeding.
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
        >
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              onClick={cancelPickCallRequest}
            >
              CANCEL
            </Button>
          </Grid>
          <Grid item>
            <Button color="primary" onClick={handleProceed}>
              SWITCH CALLS
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
