import React from "react";
import { Button, Grid } from "@material-ui/core";
import { CheckboxWithLabel } from "formik-material-ui";
import { Field } from "formik";
import { PatientForm } from "call/components/CallForms/RequesterAndPatientForm/PatientForm";
import { useStyles } from "call/components/CallForms/RequesterAndPatientForm/styles";
import { PatientData } from "./types";
import { PatientMedicalInformationSection } from "./PatientMedicalInformationSection";

interface Props {
  formKey: string;
  patient: PatientData;
  handleOpenRemovePatientDialog: () => void;
  showRemovePatientButton: boolean;
  disableForm: boolean;
}

export const NewPatient = ({
  patient,
  formKey,
  handleOpenRemovePatientDialog,
  showRemovePatientButton,
  disableForm,
}: Props) => {
  const classes = useStyles({ active: true });

  return (
    <div className={classes.patientInfo}>
      {showRemovePatientButton && (
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              onClick={handleOpenRemovePatientDialog}
              className={classes.deleteButton}
              disabled={disableForm}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Field
            name={`${formKey}.isDeceased`}
            size="small"
            type="checkbox"
            color="primary"
            Label={{ label: "Patient is deceased?" }}
            component={CheckboxWithLabel}
            disabled={disableForm}
          />
        </Grid>
      </Grid>
      <PatientForm
        formKey={formKey}
        patient={patient}
        disableForm={disableForm}
      />
      <PatientMedicalInformationSection
        formKey={formKey}
        patient={patient}
        disableForm={disableForm}
      />
    </div>
  );
};
