import React, { MouseEventHandler, useCallback } from "react";
import { Tooltip, Typography } from "@material-ui/core";
import { NavLink, useLocation } from "react-router-dom";
import { ArrowBackIos } from "@material-ui/icons";
import { useCall } from "call";
import clsx from "clsx";
import { useSidebarStyles } from "layout/styles";
import { SidebarNavType } from "./types";

export const SidebarLayout = ({
  nav,
  fullSidebar: { sidebarState, setSidebarState },
}: {
  nav: SidebarNavType;
  fullSidebar: {
    sidebarState: boolean;
    setSidebarState: (res: boolean) => void;
  };
}) => {
  const classes = useSidebarStyles({ sidebar: sidebarState });
  const { pathname } = useLocation();

  const { ongoingCalls } = useCall();

  const handleClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      if (ongoingCalls.length) {
        e.preventDefault();
      }
    },
    [ongoingCalls]
  );

  return (
    <div className={classes.root}>
      <div className={classes.toggleSection}>
        <button
          type="button"
          className={classes.toggle}
          onClick={() => setSidebarState(!sidebarState)}
        >
          <ArrowBackIos />
        </button>
      </div>
      <div style={{ overflow: "auto" }}>
        <ol className={classes.nav}>
          {nav.map(({ link, label, icon, drop }, i) => (
            <li key={`label-${i.toString()}`}>
              <NavLink
                activeClassName={classes.navOptionActive}
                to={link}
                strict={!!drop}
                exact={!drop}
                className={classes.navOption}
                onClick={handleClick}
              >
                <span>{icon}</span>
                <Typography component="p">{label}</Typography>
              </NavLink>
              {drop && (
                <ol
                  className={clsx(
                    classes.nav,
                    classes.dropNav,
                    pathname.includes(link) && classes.dropNavOpen
                  )}
                >
                  {drop.map(
                    (
                      { link: subLink, icon: subIcon, label: subLabel },
                      idx
                    ) => (
                      <NavLink
                        key={`sub-link-${idx.toString()}`}
                        activeClassName={classes.navOptionActive}
                        to={`${link}${subLink ?? ""}`}
                        style={{ backgroundColor: "#EFEFEF" }}
                        className={classes.navOption}
                        exact
                        onClick={handleClick}
                      >
                        <span>
                          {!sidebarState ? (
                            <Tooltip title={subLabel} arrow placement="right">
                              <Typography style={{ fontSize: 14 }}>
                                {subLabel}
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Tooltip title={subLabel} arrow placement="right">
                              {subIcon}
                            </Tooltip>
                          )}
                        </span>
                        <Typography component="p" style={{ fontSize: 14 }}>
                          {subLabel}
                        </Typography>
                      </NavLink>
                    )
                  )}
                </ol>
              )}
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};
