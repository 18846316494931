import React from "react";
import { ChevronLeft } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";
import { Button, IconButton, IconButtonProps } from "@material-ui/core";
import { useMedicalDetailStyles } from "../constants";

export const SuccessButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText("#08A40F"),
    backgroundColor: "#08A40F",
    "&:hover": {
      backgroundColor: green[800],
    },
  },
}))(Button);

export const CancelButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText("#CA2027"),
    backgroundColor: "#CA2027",
    "&:hover": {
      backgroundColor: red[900],
    },
  },
}))(Button);

export const ToggleBtn = ({
  open,
  size,
  reverse,
  noBg,
  ...props
}: IconButtonProps & { open?: boolean; reverse?: boolean; noBg?: boolean }) => {
  const styles = useMedicalDetailStyles({
    btnIsOpen: open,
    btnSize: size,
    noBg,
    reverseToggleAnimation: reverse,
  });
  return (
    <IconButton size="small" {...props} className={styles.buttonWrapper}>
      <ChevronLeft color="primary" className={styles.buttonToggle} />
    </IconButton>
  );
};
