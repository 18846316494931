import { Theme, makeStyles } from "@material-ui/core";
import { SIDE_BAR_MAX_HEIGHT, SIDE_BAR_TOP } from "constants/style";

interface DashboardLayoutStyleProps {
  sidebar: boolean;
  isWidget?: boolean;
}

export const useDashboardLayoutStyles = makeStyles<
  Theme,
  DashboardLayoutStyleProps
>(({ spacing: s, palette: p, breakpoints: b }) => ({
  root: {
    position: "relative",
  },
  container: {
    background: ({ isWidget }) => (isWidget ? "white" : "none"),
    marginLeft: ({ isWidget }) => (isWidget ? 0 : 60),

    [b.up("md")]: {
      marginLeft: ({ sidebar, isWidget }) => {
        if (isWidget) return 0;
        return sidebar ? 277 : 60;
      },
    },
  },
  header: {
    height: "72px",
    backgroundColor: p.background.paper,
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
    padding: `0 ${s(4)}px`,
    borderBottom: "1px solid #E0E0E0",
    zIndex: 5,
    position: "sticky",
    top: 0,

    "& div:last-child > button > h6": {
      color: p.primary.dark,
      textTransform: "capitalize",
    },

    "& div:last-child > button > svg": {
      color: p.primary.dark,
    },
  },
  rowStart: {},
  rowEnd: {
    display: "flex",
    alignItems: "center",

    "& > div:first-child": {
      marginRight: 17,
    },
  },
  content: {
    position: "relative",

    "& .dashboard-container": {
      padding: 20,
      maxWidth: 1366,
    },
  },
  contentFulfilment: {
    position: "relative",

    "& .dashboard-container": {
      width: "100%",
      padding: 20,
    },
  },
}));

export const useSidebarStyles = makeStyles(
  ({ breakpoints: b, palette: p }) => ({
    root: {
      position: "fixed",
      minWidth: 60,
      maxWidth: ({ sidebar }: { sidebar: boolean }) => (sidebar ? 277 : 60),
      top: SIDE_BAR_TOP,
      bottom: 0,
      width: "100%",
      background: "#F9F9F9",
      zIndex: 4,
      boxShadow: "1px 2px 4px 0 #0000003b",
      maxHeight: SIDE_BAR_MAX_HEIGHT,
      display: "grid",
      gridTemplateRows: "56px auto minmax(0, 200px)",

      [b.up("xs")]: {
        minWidth: 20,
      },
    },
    toggleSection: {
      display: "flex",
      justifyContent: "flex-end",
      position: "relative",
    },
    toggle: {
      width: 24,
      height: 24,
      backgroundColor: "#EFEFEF",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 8,
      border: "none",
      overflow: "hidden",
      boxShadow: "0px 4px 10px rgb(0 0 0 / 25%)",
      position: "relative",
      left: 12,
      top: 15,

      "& > svg": {
        fontSize: 12,
        color: "#828282",
        position: "relative",
        transition: "transform 0.3s",
        right: ({ sidebar }: { sidebar: boolean }) => (sidebar ? -2 : 2),
        transform: ({ sidebar }: { sidebar: boolean }) =>
          `rotate(${sidebar ? 0 : 180}deg)`,
      },
    },
    nav: {
      listStyleType: "none",
      paddingInlineStart: 0,
      margin: 0,

      "& > li": {},
    },
    navOption: {
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
      padding: "14px 0",
      textTransform: "capitalize",
      color: "#828282",
      minHeight: 54,

      "&:hover": {
        backgroundColor: "#EFEFEF",
      },

      "& > span": {
        padding: "0 18px",
        maxWidth: 60,
        width: "100%",
        display: "flex",

        "& > svg": {
          fontSize: 24,
        },
      },
      "& > p": {
        display: ({ sidebar }: { sidebar: boolean }) =>
          sidebar ? "block" : "none",
      },
    },
    dropNav: {
      height: 0,
      overflow: "hidden",
    },
    dropNavOpen: {
      height: "auto",
    },
    navOptionActive: {
      color: p.primary.dark,
      backgroundColor: "#EFEFEF",
    },
    adsContainer: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "center",
    },
    ads: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
      maxWidth: 131,
      margin: "auto 15px auto 15px",
    },
    adsButton: {
      border: "none",
      padding: 0,
      background: "none",
      marginTop: 10,

      "& > img": {
        width: "100%",
      },
    },
  })
);
