import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import { Alert, AlertProps } from "@material-ui/lab";
import { Form, Field, Formik, FormikConfig, FormikProps } from "formik";
import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  NextOfKinData,
  ServiceTypesEnum,
  LocationTypeEnum,
  ServiceCaseResponse,
  ServiceCaseStatusEnum,
  ServiceCaseRequestTypeEnum,
  PatientLevelOfEmergencyEnum,
  NextOfKinRelationToPatientEnum,
  ServiceCaseApprovalRequestTypeEnum,
  ServiceTypesWithoutMortalRemainsEnum,
} from "@deep-consulting-solutions/be2-constants";
import {
  Box,
  Grid,
  Button,
  Divider,
  MenuItem,
  makeStyles,
  Typography,
} from "@material-ui/core";

import theme from "theme";
import { useCall } from "call/hooks";
import Loader from "component/Loader";
import { notifications } from "services";
import { getENText, convertBooleanToYesNo } from "helpers";
import { StyledTypography } from "component/StyledTypography";
import { ConfirmationDialog } from "component/ConfirmationDialog";
import { patchPatientSubCoverageService } from "redux/call/requests";
import {
  rejectServiceCase,
  recommendServiceCase,
} from "redux/serviceCase/requests";

import Physician from "./Physician";
import { TabsEnum } from "../types";
import { BackButton } from "../Components";
import { SubCoverageDialog } from "./SubCoverageDialog";
import { MedicalInformation } from "./MedicalInformation";
import { RejectServiceDialog } from "./RejectServiceDialog";
import { NextOfKinTypeEnum } from "../RequesterAndPatientForm/types";
import MinimalMedicalProfessionals from "./MinimalMedicalProfessionals";
import {
  getFullSchema,
  getInitialValues,
  getValidationSchema,
} from "./helpers";

interface AssessmentFormProps {
  activeTab: TabsEnum;
  switchTab: (tab: TabsEnum) => void;
}

interface FormValues {
  reason: string;
}

interface AssessmentAlert {
  message: string;
  severity: AlertProps["severity"];
}

export type ServiceCasePatient =
  Required<ServiceCaseResponse>["patients"][number];

export interface AssessmentFormNextOfKin {
  id: string;
  email: string;
  phone: string;
  lastName: string;
  firstName: string;
  isMemberOrCustomer: boolean;
  otherRelationToPatient?: string;
  relationshipToPatient: NextOfKinRelationToPatientEnum;
  relationToPatient: NextOfKinRelationToPatientEnum | null;
}

export interface AssessmentPatient {
  patientID: string;
  contactID: string;
  medicalNeeds: string;
  isDeceased?: boolean;
  isRequester?: boolean;
  skipNextOfKin: boolean;
  nextOfKin?: NextOfKinData;
  canFlyCommercially: boolean;
  nextOfKinMembership?: string;
  requiresMedicalEscort: boolean;
  preferredDestinationArrivalTime: string;
  levelOfEmergency: PatientLevelOfEmergencyEnum;
  physician?: {
    email: string;
    phone: string;
    lastName: string;
    firstName: string;
    patientID: string;
  };
}

export interface AssessmentFormFields {
  reason: string;
  customService?: string;
  service: ServiceTypesEnum;
  patients: AssessmentPatient[];
  recommendedMedicalProfessionals: string;
  noAndSpecialityOfMedicalProfessionals: string;
}

const serviceToBeRenderedOptions = Object.values(
  ServiceTypesWithoutMortalRemainsEnum
);

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  divider: {
    margin: s(2, 0),
  },
  serviceTitle: {
    fontSize: 24,
    marginBottom: s(2),
    color: p.primary.main,
  },
  select: {
    width: "100%",
  },
  rejectButton: {
    color: "white",
    marginRight: 16,
    backgroundColor: "#CA2027",
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",

    "& .MuiButton-root": {
      padding: "13px 22px",
    },

    "& .MuiButton-label": {
      fontSize: 12,
      lineHeight: "14px",
    },
  },
  rejectDialogButton: {
    backgroundColor: "#CA2027",
  },
  alert: {
    marginTop: s(3),
  },
  alertDivider: {
    marginTop: s(3),
  },
}));

export const AssessmentForm: React.FC<AssessmentFormProps> = ({
  activeTab,
  switchTab,
}) => {
  const classes = useStyles();

  const { viewedCall, updateCallServiceCase } = useCall();

  const [formLoading, setFormLoading] = useState(false);
  const [serviceActionLoading, setServiceActionLoading] = useState(false);
  const [showSubCoverageDialog, setShowSubCoverageDialog] = useState(false);
  const [showRejectServiceDialog, setShowRejectServiceDialog] = useState(false);
  const [showRecommendServiceDialog, setShowRecommendServiceDialog] =
    useState(false);
  const [oldValues, setOldValues] = useState<AssessmentFormFields>(
    getInitialValues(viewedCall?.updatedServiceCase)
  );

  const serviceCaseApprovalRequest = useMemo(
    () =>
      viewedCall?.updatedServiceCase?.approvalRequests.find(
        (request) =>
          request.type === ServiceCaseApprovalRequestTypeEnum.SERVICE_CASE &&
          request.serviceCaseId === viewedCall?.updatedServiceCase?.id
      ),
    [
      viewedCall?.updatedServiceCase?.id,
      viewedCall?.updatedServiceCase?.approvalRequests,
    ]
  );

  const serviceRef = useRef<ServiceTypesEnum | null>(null);

  const updateService = useCallback(
    async (service: ServiceTypesEnum) => {
      if (!serviceRef.current) {
        serviceRef.current = service;
        return;
      }

      if (serviceRef.current === service) {
        return;
      }

      try {
        setFormLoading(true);
        if (
          service &&
          viewedCall?.updatedServiceCase?.id &&
          service !== ServiceTypesEnum.INFORMATIONAL_SUPPORT
        ) {
          const res = await patchPatientSubCoverageService(
            viewedCall.updatedServiceCase.id,
            service
          );

          updateCallServiceCase(viewedCall.sessionId, res);
          serviceRef.current = service;
        }
      } catch {
        //
      } finally {
        setFormLoading(false);
      }
    },
    [
      updateCallServiceCase,
      viewedCall?.sessionId,
      viewedCall?.updatedServiceCase?.id,
    ]
  );

  const handleRejectService: FormikConfig<FormValues>["onSubmit"] = async (
    values
  ) => {
    if (serviceCaseApprovalRequest) {
      try {
        setServiceActionLoading(true);
        if (!viewedCall?.updatedServiceCase?.service) {
          return;
        }

        const coverageService =
          viewedCall.updatedServiceCase?.patients[0].subCoverage;

        if (
          coverageService.length &&
          coverageService[0].selectedService !==
            viewedCall.updatedServiceCase.service
        ) {
          await patchPatientSubCoverageService(
            viewedCall.updatedServiceCase.id,
            viewedCall.updatedServiceCase.service
          );
        }

        const res = await rejectServiceCase(
          serviceCaseApprovalRequest.id,
          values.reason
        );

        updateCallServiceCase(viewedCall.sessionId, res);

        setShowRejectServiceDialog(false);
      } catch {
        //
      } finally {
        setServiceActionLoading(false);
      }
    }
  };

  const handleSubmit: FormikConfig<AssessmentFormFields>["onSubmit"] =
    useCallback(
      async (values) => {
        if (serviceCaseApprovalRequest) {
          try {
            setServiceActionLoading(true);
            if (!viewedCall?.updatedServiceCase?.service) {
              return;
            }

            const res = await recommendServiceCase(
              viewedCall?.updatedServiceCase?.id || "",
              {
                ...values,
                patients: values.patients.map((patient: AssessmentPatient) => {
                  const skipNextOfKin =
                    patient.nextOfKinMembership === NextOfKinTypeEnum.NONE ||
                    viewedCall.updatedServiceCase?.requestType ===
                      ServiceCaseRequestTypeEnum.EMERGENCY ||
                    (patient as any).isDeceased ||
                    false;

                  return {
                    ...patient,
                    skipNextOfKin,
                    ...(skipNextOfKin
                      ? {}
                      : {
                          nextOfKin: {
                            relationshipToPatient:
                              patient.nextOfKin!.relationshipToPatient,
                            otherRelationToPatient:
                              patient.nextOfKin?.otherRelationToPatient,
                            firstName: patient.nextOfKin?.firstName,
                            lastName: patient.nextOfKin?.lastName,
                            phone: patient.nextOfKin?.phone,
                            email: patient.nextOfKin?.email || undefined,
                            ...((patient as any).nextOfKinMembership ===
                            NextOfKinTypeEnum.NEW
                              ? {}
                              : { contactID: patient.nextOfKin?.contactID }),
                            isMemberOrCustomer:
                              patient.nextOfKin?.isMemberOrCustomer || false,
                          },
                        }),
                    canFlyCommercially: convertBooleanToYesNo(
                      patient.canFlyCommercially
                    ),
                    requiresMedicalEscort: convertBooleanToYesNo(
                      patient.requiresMedicalEscort
                    ),
                    physician:
                      viewedCall.updatedServiceCase?.destinationLocationType ===
                      LocationTypeEnum.MEDICAL_FACILITY
                        ? patient.physician
                        : undefined,
                  };
                }),
              }
            );

            notifications.notifySuccess(getENText("assessmentForm.successful"));

            updateCallServiceCase(viewedCall.sessionId, res);

            setShowRecommendServiceDialog(false);
          } catch {
            //
          } finally {
            setServiceActionLoading(false);
          }
        } else {
          notifications.notifyError(
            getENText("assessmentForm.approvalRequest.missing")
          );
        }
      },
      [viewedCall, updateCallServiceCase, serviceCaseApprovalRequest]
    );

  const initialValues: AssessmentFormFields = useMemo(
    () => getInitialValues(viewedCall?.updatedServiceCase),
    [viewedCall?.updatedServiceCase]
  );

  useEffect(() => {
    setOldValues(initialValues);
  }, [initialValues]);

  const validationSchema = useMemo(
    () => getFullSchema(viewedCall?.updatedServiceCase),
    [viewedCall?.updatedServiceCase]
  );

  const alert: AssessmentAlert | null = useMemo(() => {
    if (serviceCaseApprovalRequest?.mdReasonForRecommendation) {
      return {
        message: "Service Recommended by Medical Director",
        severity: "info",
      };
    }

    if (serviceCaseApprovalRequest?.mdReasonForRejection) {
      return {
        message: "Service Rejected by Medical Director",
        severity: "error",
      };
    }

    return null;
  }, [
    serviceCaseApprovalRequest?.mdReasonForRejection,
    serviceCaseApprovalRequest?.mdReasonForRecommendation,
  ]);

  const validateForm = (props: FormikProps<AssessmentFormFields>) => {
    try {
      getValidationSchema(viewedCall?.updatedServiceCase).validateSync(
        props.values,
        { abortEarly: false }
      );

      setShowRecommendServiceDialog(true);
    } catch (err: any) {
      props.setErrors(
        (err as Yup.ValidationError).inner.reduce((acc, cur) => {
          acc[cur.path] = cur.message;
          props.setFieldTouched(cur.path, true);
          return acc;
        }, {} as Record<string, string>)
      );
    }
  };

  const disableForm = useMemo(() => {
    return (
      !!viewedCall?.disableForms ||
      viewedCall?.updatedServiceCase?.caseStatus !==
        ServiceCaseStatusEnum.PENDING_MEDICAL_ASSESSMENT
    );
  }, [viewedCall?.disableForms, viewedCall?.updatedServiceCase?.caseStatus]);

  return (
    <Formik<AssessmentFormFields>
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {function Callback(props) {
        const { values, setValues, submitForm, isSubmitting } = props;
        const { service } = values;

        useEffect(() => {
          updateService(service);
        }, [service]);

        return (
          <Form>
            <Loader open={formLoading} absolute />

            {alert && (
              <>
                <Alert
                  variant="filled"
                  className={classes.alert}
                  severity={alert.severity}
                  style={{
                    backgroundColor:
                      alert.severity === "info"
                        ? theme.palette.primary.main
                        : theme.palette.error.main,
                  }}
                >
                  {alert.message}
                </Alert>

                <Divider className={classes.alertDivider} />
              </>
            )}

            <>
              <Box mt={3}>
                <Typography
                  color="primary"
                  variant="subtitle2"
                  className={classes.serviceTitle}
                >
                  Service to be Rendered
                </Typography>
              </Box>

              <Grid
                container
                spacing={4}
                alignItems="stretch"
                justifyContent="flex-start"
              >
                <Grid item xs={6}>
                  <Field
                    select
                    required
                    size="small"
                    name="service"
                    component={TextField}
                    className={classes.select}
                    label="Service to be Rendered"
                    disabled={!!alert || disableForm}
                    placeholder="Service to be Rendered"
                    options={serviceToBeRenderedOptions}
                  >
                    {serviceToBeRenderedOptions
                      .sort()
                      .map((serviceToBeRenderedOption) => {
                        return (
                          <MenuItem
                            key={serviceToBeRenderedOption}
                            value={serviceToBeRenderedOption}
                          >
                            {serviceToBeRenderedOption}
                          </MenuItem>
                        );
                      })}
                  </Field>
                </Grid>

                {values.service === ServiceTypesEnum.CUSTOM && (
                  <Grid item xs={6}>
                    <Field
                      required
                      size="small"
                      name="customService"
                      component={TextField}
                      label="Custom Service"
                      disabled={!!alert || disableForm}
                    />
                  </Grid>
                )}
              </Grid>
            </>

            <Divider className={classes.divider} />

            <Box>
              <StyledTypography
                variant="body1"
                component="span"
                color="primary.main"
              >
                Medical Director:{" "}
              </StyledTypography>

              <StyledTypography
                variant="body1"
                component="span"
                color="primary.main"
                style={{ fontSize: 18, fontWeight: 500 }}
              >
                {viewedCall?.updatedServiceCase?.medicalDirector?.zohoUser
                  .name || "---"}
              </StyledTypography>
            </Box>

            <Divider className={classes.divider} />

            <Box>
              <Typography
                color="primary"
                variant="subtitle2"
                className={classes.serviceTitle}
              >
                Patient&apos;s Physician
              </Typography>

              <Physician
                disableForm={disableForm}
                serviceCase={viewedCall?.updatedServiceCase}
              />
            </Box>

            <Divider className={classes.divider} />

            <Box>
              <Typography
                color="primary"
                variant="subtitle2"
                className={classes.serviceTitle}
              >
                Patient&apos;s Medical Information
              </Typography>

              <Box mb={4}>
                {values.patients.map((patient, index) =>
                  viewedCall?.updatedServiceCase?.patients[index] ? (
                    <MedicalInformation
                      index={index}
                      formikProps={props}
                      key={patient.patientID}
                      serviceCase={viewedCall.updatedServiceCase}
                      disabled={!!alert || disableForm}
                    />
                  ) : null
                )}
              </Box>
            </Box>

            <Divider className={classes.divider} />

            <Box mb={4}>
              <Typography
                color="primary"
                variant="subtitle2"
                className={classes.serviceTitle}
              >
                Medical Professionals
              </Typography>

              <MinimalMedicalProfessionals
                disableForms={!!alert || disableForm}
              />
            </Box>

            <Box className={classes.actions}>
              <BackButton<AssessmentFormFields>
                newValues={values}
                activeTab={activeTab}
                switchTab={switchTab}
                oldValues={oldValues}
                disabled={disableForm}
                handleSaveValues={(v: AssessmentFormFields) => {
                  setValues(v);
                  setOldValues(v);
                }}
              />

              {alert ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => switchTab(TabsEnum.PLANNING)}
                >
                  Next
                </Button>
              ) : (
                <Box>
                  <Button
                    variant="contained"
                    disabled={disableForm}
                    className={classes.rejectButton}
                    onClick={() => {
                      setShowRejectServiceDialog(true);
                    }}
                  >
                    Reject Service
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    disabled={disableForm}
                    onClick={() => validateForm(props)}
                  >
                    Recommend Service
                  </Button>
                </Box>
              )}
            </Box>

            <RejectServiceDialog
              title="Reject Service"
              open={showRejectServiceDialog}
              loading={serviceActionLoading}
              onSubmission={handleRejectService}
              placeholder="Reason for Rejection*"
              confirmButtonContent="Reject Service"
              onClose={() => setShowRejectServiceDialog(false)}
              confirmButtonClassName={classes.rejectDialogButton}
            />

            <ConfirmationDialog
              fullWidth
              maxWidth="sm"
              loading={isSubmitting}
              onConfirm={submitForm}
              title="Recommend Service"
              open={showRecommendServiceDialog}
              confirmButtonContent="Recommend Service"
              onClose={() => setShowRecommendServiceDialog(false)}
            >
              <Field
                required
                rows={4}
                multiline
                name="reason"
                component={TextField}
                placeholder="Reason for Recommendation*"
              />
            </ConfirmationDialog>

            <SubCoverageDialog
              showSubCoverageDialog={showSubCoverageDialog}
              onClose={() => {
                setShowSubCoverageDialog(false);
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
