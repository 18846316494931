import { FormikProps, getIn } from "formik";
import { Search } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { Box, InputAdornment, TextField } from "@material-ui/core";
import { AutocompleteRenderInputParams } from "formik-material-ui-lab";
import Autocomplete, {
  AutocompleteChangeReason,
} from "@material-ui/lab/Autocomplete";
import usePlacesAutocomplete, {
  getGeocode,
  RequestOptions,
} from "use-places-autocomplete";

import { getLongAddressObject, getShortAddressObject } from "helpers";

function statusMessage(status: string) {
  switch (status) {
    case "ZERO_RESULTS":
      return "No Results Found";
    case "UNKNOWN_ERROR":
      return "Netwrok or Server Error";
    case "ERROR":
      return "Network Error";
    default:
      return "Server Error";
  }
}

export interface FormikStreetFieldProps<
  FormValues extends Record<string, any>
> {
  name?: string;
  label?: string;
  notify?: boolean;
  cityName?: string;
  poBoxName?: string;
  disabled?: boolean;
  coordsName?: string;
  islandName?: string;
  use2Stars?: boolean;
  helperText?: string;
  countryName?: string;
  country?: string | string[];
  formikProps: FormikProps<FormValues>;
  coordinations?: { lat: number; lng: number };
  requestOptions?: Omit<RequestOptions, "location">;
  onSet?: (result: google.maps.GeocoderResult) => void;
  onSelect?: (result: google.maps.GeocoderResult | undefined) => void;
}

(window as any).dummyPlacesLoaded = () => {};

export const FormikStreetField = <FormValues extends Record<string, any>>({
  name = "street",
  cityName = "city",
  coordsName = "coords",
  islandName = "island",
  countryName = "country",
  poBoxName = "poBoxOrZipOrPostalCode",
  label = "Street",
  disabled = false,
  formikProps: {
    setFieldTouched,
    setFieldValue,
    values,
    isSubmitting,
    errors,
    touched,
  },
  onSelect,
  requestOptions,
  country,
  helperText,
  coordinations,
  use2Stars,
  onSet,
}: FormikStreetFieldProps<FormValues>) => {
  const [location, setLocation] = useState<google.maps.LatLng | undefined>(
    undefined
  );

  const componentRestrictions = country
    ? {
        country,
      }
    : undefined;

  const {
    ready,
    init,
    suggestions: { data, loading: placesLoading },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ["hospital"],
      location,
      componentRestrictions,
      ...requestOptions,
    },
    callbackName: "dummyPlacesLoaded2", // different on purpose, so that it wont be called automatically
    cache: false,
  });

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (ready && coordinations) {
      setLocation(new google.maps.LatLng(coordinations.lat, coordinations.lng));
    }
  }, [coordinations, ready]);

  const [loadingAddress, setLoadingAddress] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, setError] = useState("");

  const fieldError: string | undefined = getIn(errors, name);
  const showFieldError: boolean = getIn(touched, name) && !!fieldError;
  const fieldValue = getIn(values, name);

  const handleSelect = useCallback(
    async (address: string) => {
      setLoadingAddress(true);
      setValue(address, false);
      try {
        const results = await getGeocode({ address });
        setLoadingAddress(false);
        return results[0];
      } catch (err) {
        setLoadingAddress(false);
        setError(statusMessage(err as string));
      }
      return undefined;
    },
    [setValue]
  );

  const onInputChange = useCallback(
    (_event: React.ChangeEvent<any>, value: string) => {
      setError("");
      setValue(value);
      setFieldValue(name, value);
    },
    [name, setFieldValue, setValue]
  );

  const onInputBlur = useCallback(() => {
    setFieldTouched(name, true);
  }, [name, setFieldTouched]);

  const onAutocompleteChange = useCallback(
    (
      event: React.ChangeEvent<any>,
      value: string | google.maps.places.AutocompletePrediction | null,
      reason: AutocompleteChangeReason
    ) => {
      (async () => {
        if (reason === "select-option") {
          const result = await handleSelect(
            (value as google.maps.places.AutocompletePrediction).description
          );

          if (onSelect) {
            onSelect(result);
            return;
          }

          if (result) {
            const longAddress = getLongAddressObject(result.address_components);
            const shortAddress = getShortAddressObject(
              result.address_components
            );

            const street = `${longAddress.street_number || ""}${
              longAddress.route
                ? `${longAddress.street_number ? " " : ""}${longAddress.route}`
                : ""
            }`;
            setFieldValue(name, street);
            setValue(street, false);

            setFieldValue(
              cityName,
              longAddress.locality ||
                longAddress.administrative_area_level_3 ||
                longAddress.postal_town ||
                ""
            );

            const islandValue =
              shortAddress.country === "GB"
                ? longAddress.administrative_area_level_2 ||
                  longAddress.administrative_area_level_1
                : longAddress.administrative_area_level_1 ||
                  longAddress.administrative_area_level_2;

            setFieldValue(islandName, islandValue || "");

            if (shortAddress.country !== "BS") {
              setFieldValue(poBoxName, longAddress.postal_code || "");
            }

            setFieldValue(countryName, shortAddress.country || "");
            setFieldValue(coordsName, {
              lat: result.geometry.location.lat(),
              lng: result.geometry.location.lng(),
            });
            onSet?.(result);
          }
        } else if (reason === "clear") {
          setFieldValue(name, "");
        }
      })();
    },
    [
      name,
      onSet,
      onSelect,
      setValue,
      cityName,
      poBoxName,
      islandName,
      coordsName,
      countryName,
      handleSelect,
      setFieldValue,
    ]
  );

  const getOptionSelected = useCallback(
    (
      option: google.maps.places.AutocompletePrediction,
      value: google.maps.places.AutocompletePrediction
    ) => {
      const [stNumber, stName] = (value as unknown as string).split(" ");
      const hasNumber = option.description.includes(stNumber);
      const hasName = option.description.includes(stName);

      return hasNumber && hasName;
    },
    []
  );

  return (
    <Autocomplete<google.maps.places.AutocompletePrediction, false, true, true>
      freeSolo
      options={data}
      disableClearable
      value={fieldValue}
      inputValue={fieldValue}
      loading={placesLoading}
      onInputChange={onInputChange}
      onChange={onAutocompleteChange}
      noOptionsText="No Results Found"
      getOptionSelected={getOptionSelected}
      disabled={disabled || loadingAddress || isSubmitting}
      getOptionLabel={(
        option: google.maps.places.AutocompletePrediction | string
      ) => (typeof option === "string" ? option : option.description)}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          name={name}
          autoComplete="off"
          onBlur={onInputBlur}
          error={showFieldError}
          label={`${label}${use2Stars ? "**" : "*"}`}
          helperText={showFieldError ? fieldError : helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <Box
                  style={{
                    width: "46px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <div
                    style={{
                      width: "1px",
                      height: "40px",
                      backgroundColor: "rgba(86, 92, 99, 0.12)",
                    }}
                  />

                  <Search style={{ fontSize: 24 }} />
                </Box>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};
