import { makeStyles } from "@material-ui/core";

export const useDialogStyles = makeStyles(() => ({
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      width: "100%",
      borderRadius: "4px 4px 16px 16px",
    },
  },
  dialogRoot: {},
  dialogContainer: {},
  dialogTitle: {
    fontSize: 20,
    fontWeight: 500,
    backgroundColor: "#F7F7F7",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "14px 16px",
  },
  dialogDiscrepancy: {
    "& .MuiDialog-paperWidthSm": {
      width: "100%",
      maxWidth: 452,
      padding: "32px 24px",
      borderRadius: "15px",
      boxShadow:
        "0px 10px 14px rgba(84, 134, 199, 0.12), 0px 22px 35px rgba(84, 134, 199, 0.15), 0px 8px 42px rgba(84, 134, 199, 0.12)",
    },
  },
}));
