import * as Yup from "yup";
import { Field, Formik } from "formik";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { TextField, CheckboxWithLabel } from "formik-material-ui";

import { ConfirmationDialog } from "component/ConfirmationDialog";

const useStyles = makeStyles(({ spacing: s }) => ({
  ncpWrapper: {
    padding: s(2),
    borderRadius: s(0.5),
    marginBottom: s(2.5),
    border: "1px solid #BDBDBD",
  },
  ncpTitle: {
    fontSize: 20,
    fontWeight: 500,
    color: "#263E7F",
    lineHeight: "20px",
    marginBottom: s(2),
    fontFamily: "'Roboto', sans-serif",
  },
  ncp: {
    color: "#263E7F",
    marginBottom: s(1),
  },
  checkboxGroup: {
    marginBottom: s(2.5),
  },
  checkboxLabel: {
    fontWeight: 500,
    color: "#263E7F",
  },
  confirmButton: {
    backgroundColor: "#263E7F",
  },
}));

interface FormValues {
  paymentDetails: string;
  paymentHasBeenReceived: boolean;
}

interface SubCoverageDialogProps {
  onClose: () => void;
  showSubCoverageDialog: boolean;
}

const validationSchema = Yup.object().shape({
  paymentDetails: Yup.string().trim().required("Payment Details are required."),
  paymentHasBeenReceived: Yup.boolean().required("This field is required."),
});

export const SubCoverageDialog = ({
  onClose,
  showSubCoverageDialog,
}: SubCoverageDialogProps) => {
  const classes = useStyles();

  return (
    <Formik<FormValues>
      validateOnMount
      validationSchema={validationSchema}
      initialValues={{
        paymentDetails: "",
        paymentHasBeenReceived: false,
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
      }}
    >
      {({ isValid, submitForm }) => {
        return (
          <ConfirmationDialog
            fullWidth
            maxWidth="sm"
            hideCancelButton
            onClose={onClose}
            onConfirm={submitForm}
            open={showSubCoverageDialog}
            disableConfirmButton={!isValid}
            title="Confirm Receiving Service Payment"
            confirmButtonClassName={classes.confirmButton}
          >
            <Box className={classes.ncpWrapper}>
              <Typography className={classes.ncpTitle}>
                Non-Covered Patients
              </Typography>

              <Box>
                <Typography className={classes.ncp}>Claudia Doe</Typography>
                <Typography className={classes.ncp}>Jane Doe</Typography>
              </Box>
            </Box>

            <Box className={classes.checkboxGroup}>
              <Field
                required
                type="checkbox"
                component={CheckboxWithLabel}
                name="paymentHasBeenReceived"
                Label={{
                  label: (
                    <Typography className={classes.checkboxLabel}>
                      Payment for the service has been received.
                    </Typography>
                  ),
                }}
              />
            </Box>

            <Field
              required
              multiline
              fullWidth
              minRows={2}
              maxRows={2}
              component={TextField}
              name="paymentDetails"
              label="Payment Details"
            />
          </ConfirmationDialog>
        );
      }}
    </Formik>
  );
};
