import { useFormikContext } from "formik";
import { Radios } from "component/atoms";
import { useStyles } from "call/components/CallForms/RequesterAndPatientForm/styles";
import {
  PatientData,
  NextOfKinTypeEnum,
  Member,
} from "call/components/CallForms/RequesterAndPatientForm/types";
import { ExistingPatientInformation } from "call/components/CallForms/RequesterAndPatientForm/ExistingPatientInformation";
import {
  MembersResponseSubObject,
  PatientTypeEnum,
} from "@deep-consulting-solutions/be2-constants";
import { useCallback } from "react";
import { Button, Grid } from "@material-ui/core";
import { MemberPatientInformation } from "./MemberPatientInformation";
import { NewPatient } from "./NewPatient";
import { FormValues } from "./types";

interface Props {
  formKey: string;
  patient: PatientData;
  isMemberOrCustomer?: boolean;
  subscriptionMembers?: MembersResponseSubObject[];
  handleOpenRemovePatientDialog: (
    patient: Pick<PatientData, "firstName" | "lastName" | "id">,
    skipDialog?: boolean
  ) => void;
  showRemovePatientButton: boolean;
  requesterSelectedMembers: Member[];
  disableForm: boolean;
}

export const PatientInformation = ({
  patient,
  formKey,
  isMemberOrCustomer,
  subscriptionMembers,
  handleOpenRemovePatientDialog,
  showRemovePatientButton,
  requesterSelectedMembers,
  disableForm,
}: Props) => {
  const classes = useStyles({ active: true });
  const { setFieldValue } = useFormikContext<FormValues>();

  const patientTypeOptions = Object.values(PatientTypeEnum)
    .map((type) => ({
      label: type,
      value: type,
    }))
    .filter(
      (option) => isMemberOrCustomer || option.value !== PatientTypeEnum.MEMBER
    );

  const handlePatientTypeChange = useCallback(
    (value: string) => {
      setFieldValue(`${formKey}.nextOfKinMembership`, NextOfKinTypeEnum.NEW);
      setFieldValue(`${formKey}.patientType`, value);
      setFieldValue(
        `${formKey}.isMemberOrCustomer`,
        value !== PatientTypeEnum.NEW
      );
      setFieldValue(`${formKey}.firstName`, "");
      setFieldValue(`${formKey}.lastName`, "");
      setFieldValue(`${formKey}.gender`, "");
      setFieldValue(`${formKey}.dob`, "");
      setFieldValue(`${formKey}.age`, "");
      setFieldValue(`${formKey}.contactID`, "");
      setFieldValue(`${formKey}.email`, "");
      setFieldValue(`${formKey}.phone`, "");
      setFieldValue(`${formKey}.requesterRelationToPatient`, "");
      setFieldValue(`${formKey}.requesterOtherRelationToPatient`, "");
      setFieldValue(`${formKey}.membership`, "");
      setFieldValue(`${formKey}.nextOfKin`, {
        relationshipToPatient: "",
        otherRelationToPatient: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        isMemberOrCustomer: false,
        contactID: "",
      });
    },
    [formKey, setFieldValue]
  );

  const handleRemovePatient = useCallback(() => {
    handleOpenRemovePatientDialog(patient);
  }, [handleOpenRemovePatientDialog, patient]);

  const handleRemovePatientWithoutDialog = useCallback(() => {
    handleOpenRemovePatientDialog(patient, true);
  }, [handleOpenRemovePatientDialog, patient]);

  return (
    <div className={classes.patientInfoBox}>
      <Radios
        name={`${formKey}.patientType`}
        options={patientTypeOptions}
        onChange={handlePatientTypeChange}
        disabled={disableForm || patient.isRequester}
      />

      {!patient.patientType && !patient.isRequester && (
        <div className={classes.patientInfo}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="contained"
                onClick={handleRemovePatient}
                className={classes.deleteButton}
                disabled={disableForm}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
      {patient.patientType === PatientTypeEnum.NEW && (
        <>
          <NewPatient
            patient={patient}
            formKey={formKey}
            handleOpenRemovePatientDialog={handleRemovePatient}
            showRemovePatientButton={showRemovePatientButton}
            disableForm={disableForm}
          />
        </>
      )}
      {patient.patientType === PatientTypeEnum.EXISTING && (
        <>
          <ExistingPatientInformation
            patient={patient}
            formKey={formKey}
            handleOpenRemovePatientDialog={handleRemovePatient}
            showRemovePatientButton={showRemovePatientButton}
            requesterSelectedMembers={requesterSelectedMembers}
            disableForm={disableForm}
          />
        </>
      )}

      {patient.patientType === PatientTypeEnum.MEMBER && (
        <>
          <MemberPatientInformation
            patient={patient}
            formKey={formKey}
            requesterMembers={subscriptionMembers}
            handleOpenRemovePatientDialog={handleRemovePatient}
            showRemovePatientButton={showRemovePatientButton}
            handleRemovePatientWithoutDialog={handleRemovePatientWithoutDialog}
            disableForm={disableForm}
          />
        </>
      )}
    </div>
  );
};
