import { makeStyles, PopoverProps, Theme, Menu } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { Call } from "call/types";
import React, { MouseEventHandler, useCallback, useState } from "react";
import { CallOnHoldComponent } from "../CallOnHoldComponent";

interface Props {
  callsToCollapse: Call[];
}

interface StyleProps {
  collapsed: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(({ spacing: s }) => ({
  wrapper: {
    marginRight: s(1),
  },
  iconWrapper: ({ collapsed }) => ({
    height: "100%",
    border: "1px solid #BDBDBD",
    borderRadius: 8,
    background: !collapsed ? "rgba(130, 130, 130, 1)" : "white",
    cursor: "pointer",
    display: "block",
    padding: 0,
  }),
  icon: ({ collapsed }) => ({
    color: collapsed ? "rgba(130, 130, 130, 1)" : "white",
  }),
  menu: {
    "& .MuiPaper-root": {
      padding: s(1),
      paddingBottom: s(0),
      background: "#828282",
      boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.12)",
      marginTop: s(10),
      borderRadius: 8,
      "& .MuiMenu-list": {
        padding: s(0),
        margin: s(0),
      },
    },
  },
  menuItem: {
    marginBottom: s(1),
  },
}));
export const CollapsedCalls = ({ callsToCollapse }: Props) => {
  const [showLayoutOptions, setShowLayoutOptions] = useState(false);
  const [anchorEl, setAnchorEl] = useState<
    PopoverProps["anchorEl"] | undefined
  >();

  const handleShowLayoutOptions: MouseEventHandler<Element> = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
      setShowLayoutOptions(true);
    },
    []
  );

  const handleCloseLayoutOptions = useCallback(() => {
    setShowLayoutOptions(false);
  }, []);

  const classes = useStyles({ collapsed: !showLayoutOptions });

  if (!callsToCollapse.length) {
    return null;
  }
  return (
    <div className={classes.wrapper}>
      <button
        onClick={handleShowLayoutOptions}
        className={classes.iconWrapper}
        aria-controls={showLayoutOptions ? "collapsed-call-options" : undefined}
        aria-haspopup="true"
        aria-expanded={showLayoutOptions ? "true" : undefined}
        type="button"
      >
        <MoreVert className={classes.icon} fontSize="large" />
      </button>
      <Menu
        anchorEl={anchorEl}
        id="collapsed-call-options"
        open={showLayoutOptions}
        onClose={handleCloseLayoutOptions}
        className={classes.menu}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        {callsToCollapse.map((call) => (
          <div key={call.id} className={classes.menuItem}>
            <CallOnHoldComponent call={call} />
          </div>
        ))}
      </Menu>
    </div>
  );
};
