import { ServiceCaseResponse } from "@deep-consulting-solutions/be2-constants";
import { useCall } from "call/hooks";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { TabsEnum } from "../../types";

interface Props {
  activeTab: TabsEnum;
  presentTab: TabsEnum; // tab of host component
  valuesCreator: (serviceCase: ServiceCaseResponse) => void;
}
export const HandleUpdateFormValuesWithServiceCaseDetailsAfterTabChange = ({
  activeTab,
  presentTab,
  valuesCreator,
}: Props) => {
  const { setValues } = useFormikContext();
  const { viewedCall } = useCall();
  const [shouldUpdateValues, setShouldUpdateValues] = useState(true);

  useEffect(() => {
    if (activeTab === presentTab) {
      if (viewedCall?.updatedServiceCase && shouldUpdateValues) {
        setValues(valuesCreator(viewedCall?.updatedServiceCase));
        setShouldUpdateValues(false);
      }
    } else {
      setShouldUpdateValues(true);
    }
  }, [
    setValues,
    valuesCreator,
    viewedCall?.updatedServiceCase,
    activeTab,
    presentTab,
    shouldUpdateValues,
  ]);

  return null;
};
