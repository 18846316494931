import React, { FC, useState } from "react";
import { Box, Collapse } from "@material-ui/core";
import { ToggleBtn } from "pages/FulfilmentDashboard/components/Buttons";
import { BoxProps } from "@material-ui/core/Box/Box";

export const CardContent: FC<
  {
    header?: any;
    collapsible?: boolean;
    noBorder?: boolean;
    noPadding?: boolean;
    onToggle?: (state: boolean) => void;
    toggleBtnSize?: "small" | "medium";
  } & BoxProps
> = ({
  header,
  onToggle,
  noPadding,
  toggleBtnSize,
  collapsible,
  children,
  noBorder,
  ...rest
}) => {
  const [show, setShow] = useState(true);
  const onCollapse = () => {
    setShow(!show);
    if (onToggle) onToggle(!show);
  };

  return (
    <Box
      borderRadius="8px"
      border={noBorder ? undefined : "1px solid #E0E0E0"}
      p={noPadding ? undefined : "16px"}
      {...rest}
    >
      {header && (
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Box width="100%">{header}</Box>
          {collapsible && (
            <ToggleBtn
              size={toggleBtnSize || "small"}
              open={show}
              onClick={onCollapse}
            />
          )}
        </Box>
      )}
      <Collapse in={show}>
        <Box>{children}</Box>
      </Collapse>
    </Box>
  );
};
