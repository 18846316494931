import {
  ServiceCaseResponse,
  ServiceCaseRequestTypeEnum,
  ServiceCaseResponseExtended,
} from "@deep-consulting-solutions/be2-constants";
import { serviceCaseNeedsUpdate } from "pages/FulfilmentDashboard/helpers";
import type { RequestTypeState } from "./index";

export const updateStateForServiceCase = (
  state: RequestTypeState,
  serviceCase: ServiceCaseResponse | ServiceCaseResponseExtended,
  type: ServiceCaseRequestTypeEnum,
  modifiedFields: Array<keyof ServiceCaseResponse>
) => {
  const serviceCaseInState = state.data.find(({ id }) => id === serviceCase.id);

  if (serviceCaseInState && serviceCase.requestType !== type) {
    return {
      ...state,
      total: state.total - 1,
      data: state.data.filter(({ id }) => id !== serviceCase.id),
    };
  }

  if (!serviceCaseInState && serviceCase.requestType === type) {
    return {
      ...state,
      total: state.total + 1,
      data: [
        {
          ...serviceCase,
          quotes: [],
          isNew: true,
        } as unknown as ServiceCaseResponseExtended,
      ].concat(state.data),
    };
  }

  if (
    serviceCaseInState &&
    serviceCase.requestType === type &&
    serviceCaseNeedsUpdate(serviceCaseInState, serviceCase, modifiedFields)
  ) {
    return {
      ...state,
      data: state.data.map((item) =>
        item.id === serviceCase.id ? { ...serviceCase, quotes: [] } : item
      ),
    };
  }

  return state;
};
