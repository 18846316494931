export default {
  en: {
    "api.noti.info.tokenExpired":
      "Your session has expired. Please login again.",
    "subscription.noti.success.startedValidation":
      "Subscription validation started!",
    "subscription.noti.success.updatedDocumentExpiryDate":
      "Expiry date of {{documentType}} updated.",
    "subscription.noti.success.deletedDocument":
      "{{documentType}} was deleted successfully.",
    "subscription.noti.success.addedNewDocument":
      "{{documentType}} was added successfully.",
    "subscription.noti.success.requestDocumentInformationFromCustomer":
      "Request sent to customer successfully.",
    "subscription.noti.success.employerInformationUpdated":
      "Employer information updated successfully.",
    "subscription.noti.success.newEmployerCreated":
      "New employer created with provided data.",
    "subscription.noti.success.requestEmploymentInformationFromCustomer":
      "Request sent to customer successfully.",
    "subscription.noti.success.requestEmploymentInformationFromEmployer":
      "Request sent to employer successfully.",
    "subscription.noti.success.markSubscriptionAsInvalid":
      "Subscription marked as invalid successfully.",
    "subscription.noti.success.markSubscriptionAsValid":
      "Subscription marked as valid successfully.",
    "subscription.noti.success.resendEmailToDependent":
      "Email sent to dependent successfully.",

    "validation.email.required": "Email is required",
    "validation.email.invalid": "Email is invalid",
    "validation.firstName.required": "First name is required",
    "validation.lastName.required": "Last name is required",
    "validation.phone.required": "Phone Number is required",
    "validation.phone.invalid": "Phone Number is invalid",
    "validation.role.required": "Role is required",
    "validation.title.required": "Title is required",
    "validation.password.min":
      "Passwords must be at least 8 characters in length",
    "validation.password.required": "Password is required",
    "validation.repassword.required": "Please confirm your password",
    "validation.repassword.match": "Passwords must match",
    "validation.otp.required": "One Time Password is Required",
    "validation.otp.invalid": "One Time Password must be 6 numbers",
    "validation.street.required": "Street is required",
    "validation.city.required": "City is required",
    "validation.island.required": "Island is required",
    "validation.island.typeError": "Island should be a string",
    "validation.state.required": "State is required",
    "validation.state.typeError": "State should be a string",
    "validation.region.required": "Region is required",
    "validation.region.typeError": "Region should be a string",
    "validation.zip.required": "ZIP is required",
    "validation.country.required": "Country is required",
    "validation.dob.required": "Date of birth is required",
    "validation.dob.inPast": "Date of birth can only be in the past",
    "validation.dob.min.to.greater.than.parent":
      "Child's date of birth must be after parent's",
    "validation.dob.max.to.less.than.child":
      "Parent's date of birth must be after child's",
    "validation.gender.required": "Gender is required",
    "validation.reason.required": "Reason is required",
    "validation.requestType.required": "Request Type is required",
    "validation.situationDescription.required":
      "Situation Description is required",
    "validation.citizenship.required": "Citizenship is required",
    "validation.relation.required": "Relation is Required",
    "validation.unansweredRequestTime.required":
      "Unanswered Request Time required",
    "validation.passportDoc.oneRequired": "At least one passport is required",
    "validation.expiryDate.required": "Expiry Date is required",
    "validation.expiryDate.expired": "Document has expired",
    "validation.renewalConfirmationDoc.required":
      "Renewal Confirmation Document is required",
    "validation.bahamianResidencePermitDoc.required":
      "Bahamian Residence Permit is required",
    "validation.usEntryDoc.required": "U.S. Entrance Document is required",
    "validation.docType.required": "Document Type is required",
    "validation.any.required": "Please, select an answer",
    "validation.option.required": "Please, select an option",
    "validation.otherOriginOfInterest.required":
      "Origin of interest is required",
    "validation.preferredHospital.required": "Preferred hospital is required",
    "validation.employer.required": "Employer name is required",
    "validation.company.required": "Company is required",
    "validation.employmentStatus.required": "Employee Status is required",
    "validation.file.required": "An uploaded document is required",
    "validation.membershipNumber.required": "Membership Number is required",

    "AppSales.NewSaleDialog.saleType.required": "Please select Sale Type",
    "AppSales.NewSaleDialog.event.required": "Please select Event",
    "validation.documentType.required": "Document type is required",
    "validation.document.required": "Document is required",
    "validation.companyName.required": "Company Name is required",
    "validation.headquartersStreet.required": "Street is required",
    "validation.headquartersCity.required": "City is required",
    "validation.headquartersState.required": "State is required",
    "validation.headquartersCountry.required": "Country is required",
    "validation.employer.name.required": "Employer name is required",
    "validation.employee.departmentInCompany.required":
      "Department within the Company is required",
    "validation.employee.position.required": "Position is required",
    "validation.employee.salaryRollNumber.required": "This is required",
    "validation.employee.commencementDate.required":
      "Commencement Date is required",
    "validation.employee.nib.required": "NIB Number is required",

    "AppSales.MainBeneficiary.originOfInterest.required":
      "Please select Origin of Interest",
    "AppSales.MainBeneficiary.otherOriginOfInterest.required":
      "Please enter Origin of Interest",

    "comp.EmailWithSearch.contact.existing": "This is an existing contact",
    "comp.EmailWithSearch.contact.new": "This is a new contact",

    "subRequest.noti.info.emailExists": "Email already exists. Please, Login.",
    "subRequest.noti.success.infoSaved":
      "Your information is saved successfully",
    "subRequest.noti.success.dependentSaved": "Dependent is saved successfully",
    "subRequest.noti.success.docsSaved": "Documents are saved successfully",
    "subRequest.noti.success.docDeleted": "Document was deleted successfully",
    "subRequest.noti.success.originSaved":
      "Origin of Interest is saved successfully",
    "subRequest.noti.success.salaryDeductionSaved":
      "Salary Deduction Form is saved successfully.",
    "signUp.noti.success": "Signed up successfully",
    "activation.noti.success":
      "Your Account is Activated Successfully. You now have access to the Portal and the BahamasEvac Mobile App.",
    "otp.noti.success.otpSent":
      "One Time Password is Sent to {email} Successfully", //
    "otp.noti.success.otpVerified":
      "One Time Password is Verified Successfully",

    "AppWidget.documents.otherType.required":
      "Other type is required if type is other",
    "AppWidget.documents.sentToEmployerDate.required":
      "sentToEmployerDate is required",
    "AppWidget.documents.sentToCustomerDate.required":
      "sentToCustomerDate is required",
    "AppWidget.documents.expiryDate.required": "Expiry date is required",
    "AppWidget.documents.owner.required": "Ownership is required",
    "AppWidget.documents.upload.success": "Document uploaded successfully",
    "AppWidget.subscription.updateContactPerson.success":
      "Contact person updated successfully.",
    "AppWidget.subscription.noti.success.updateEmployer":
      "Employer updated successfully.",
    "AppWidget.subscription.noti.success.discardEmployerChanges":
      "Employer changes discarded successfully",
    "AppwWidget.subscription.noti.success.rejectSubscription":
      "Subscription was rejected successfully.",
    "AppWidget.subscription.noti.success.revalidateSubscription":
      "Subscription was revalidated successfully.",
    "AppWidget.administration.success.sendPaymentLink":
      "Payment link was sent to customer successfully",
    "AppWidget.administration.validation.expectedPaymentDate":
      "Expected payment date is required",
    "AppWidget.updateReconcile.validation.paymentDate":
      "Please specify payment date",
    "AppWidget.updateReconcile.validation.paymentAmount":
      "Please input a valid Amount",
    "AppWidget.updateReconcile.validation.Success":
      "Payment updated successfully",

    "AppWidget.administration.success.payLater":
      "Payment date updated successfully",

    "AppWidget.PaymentIncidentResolution.resolution.required":
      "Incident Resolution is required",
    "AppWidget.PaymentIncidentResolution.expectedPaymentDate.required":
      "Expected Payment Date is required",
    "AppWidget.customerRefund.otherReason.required":
      "Other reason is required if reason contains other",
    "AppWidget.customerRefund.bankAccountDetails.required":
      "Bank account details is required",
    "AppWidget.customerRefund.success":
      "Customer refund request record updated successfully",
    "AppWidget.employerEscalationEmail.success":
      "Email sent to employer successfully",
    "AppWidget.employerEscalationForm.success":
      "Employer escalation request record updated successfully",
    "AppWidget.employerEscalation.correctAmount.required":
      "Correct amount field is required",
    "AppWidget.employerEscalation.endOfGracePeriodDate.required":
      "End of grace period date field is required",
    "AppWidget.employerEscalation.refundRequestType.required":
      "Refund request type field is required",

    "validation.correctAmount.required": "Correct Amount is required",
    "validation.outstandingPaymentDate.required":
      "Expected Outstanding Payment Date is required",
    "validation.revisedListDate.required":
      "Expected Revised Payment Date is required",

    "location.error": "There is error in determining your location",

    "validation.holderName.required": "Card's holder name is required",
    "validation.cvv.not.valid": "Provide a valid CVV",
    "validation.expiryDate.not.valid": "Provide a valid card expiry date",
    "validation.cardNumber.not.valid": "Provide a valid card number",
    "validation.card.success": "You Credit Card is added successfully.",
    "validation.card.badCard":
      "Failed to add your Credit Card. Please, check your Credit Card information or try a new Card.",
    "validation.card.error":
      "Failed to add your Credit card. Please, try again.",
    "payment.card.brand.invalid": "Card brand is not supported",

    "initialAssessmentForm.contactNumber.required":
      "Contact number is required",
    "initialAssessmentForm.situationDescription.required":
      "Situation description is required",
    "initialAssessmentForm.requestType.required": "Request type is required",
    "initialAssessmentForm.successful":
      "Initial assessment submitted successfully",
    "initialAssessmentForm.falseAlarmReason.required": "Reason is required",
    "initialAssessmentForm.callBackNumber.invalid":
      "Call back number is invalid",
    "initialAssessmentForm.contactNumber.invalid": "Contact number is invalid",
    "initialAssessmentForm.falseAlarm.successful": "Case closed successfully",

    "serviceForm.serviceToBeRendered.required":
      "Service to be rendered is required",

    "movePatientsDialog.patients.required": "Select at least one patient",
    "serviceForm.movePatientsDialog.successful":
      "Patients {patient_names_list} has been moved to new service case {new_service_case_number}",

    "cancellationReason.patients.required": "Cancellation reason is required",

    "serviceForm.cancelApprovalRequest.successful":
      "Approval request cancelled",

    "requesterAndPatientForm.contactNumber.required":
      "Contact number is required",
    "requesterAndPatientForm.situationDescription.required":
      "Situation description is required",
    "requesterAndPatientForm.requestType.required": "Request type is required",
    "requesterAndPatientForm.successful":
      "Requester and patient details submitted successfully",

    "requesterAndPatientForm.requester.isPatient.required": "Select an option",
    "requesterAndPatientForm.requester.isMemberOrCustomer.required":
      "Select an option",
    "requesterAndPatientForm.requester.firstName.required":
      "First name is required",
    "requesterAndPatientForm.requester.lastName.required":
      "Last name is required",
    "requesterAndPatientForm.requester.phone.required":
      "Phone number is required",
    "requesterAndPatientForm.requester.phone.invalid":
      "Provide a valid phone number",
    "requesterAndPatientForm.requester.gender.required": "Gender is required",
    "requesterAndPatientForm.requester.dob.required":
      "Date of Birth is required if age is not provided",
    "requesterAndPatientForm.requester.age.required":
      "Age is required if date of birth is not provided",
    "requesterAndPatientForm.requester.nextOfKin.firstName.required":
      "Next of kin first name is required",
    "requesterAndPatientForm.requester.nextOfKin.lastName.required":
      "Next of kin last name is required",
    "requesterAndPatientForm.requester.nextOfKin.email.required":
      "Next of kin email is required",
    "requesterAndPatientForm.requester.nextOfKin.phone.required":
      "Next of kin phone is required",
    "requesterAndPatientForm.requester.nextOfKin.phone.invalid":
      "Provide a valid phone number",
    "requesterAndPatientForm.requester.nextOfKin.relationshipToPatient.required":
      "Next of kin relationship to patient is required",
    "requesterAndPatientForm.requester.nextOfKin.otherRelationToPatient.required":
      "Next of kin relationship to patient is required",
    "requesterAndPatientForm.patient.relationshipToRequester.required":
      "Relationship to requester is required",
    "informationalSupport.explaination.required":
      "Explanation is required when reason is Other",
    "informationalSupport.reason.required": "Reason is required",
    "informationalSupport.successful": "Information submitted successfully",
    "requesterAndPatientForm.requester.dob.invalid":
      "Date of birth is invalid.",
    "requesterAndPatientForm.patient.nextOfKin.required":
      "Next of kin is required",

    "serviceForm.serviceToBeRendered.invalid":
      "Service to be rendered is invalid",
    "serviceForm.customService.required": "Custom service is required",
    "serviceForm.informationSupportReason.invalid":
      "Informational support reason is invalid",
    "serviceForm.informationSupportReason.required":
      "Informational support reason is required",
    "serviceForm.informationSupportExplanation.required":
      "Explanation is required",
    "serviceForm.logFailedCall.successful": "Successful",
    "serviceForm.medicalDirector.required": "Medical director is required",
    "serviceForm.patient.medicalStatus.required": "Medical Status is required",
    "serviceForm.patient.subCoverageID.required": "Coverage is required",

    "assessmentForm.successful": "Assessment Form was submitted successfully.",
    "assessmentForm.approvalRequest.missing":
      "Cannot recommend a service case that is not pending medical assessment.",

    "holdAndResumeCallDelay.info": "You are too fast, please try again.",

    "failedCallbackLog.successful":
      "Failed Callback Attempt Logged Successfully.",

    "accessLinks.personName.required": "Person Name is required.",
    "accessLinks.organizationName.required": "Organization Name is required.",
  },
};
