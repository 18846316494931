import React, { useEffect, useRef } from "react";
import { Box, MenuItem, TextField } from "@material-ui/core";
import { notifications } from "services";
import { ReactComponent as VideoIcon } from "images/video.svg";
import { useMediaDeviceContext } from "context/MediaDeviceContext";

export interface ModuleProps {
  open: boolean;
}

export const VideoModule: React.FC<ModuleProps> = ({ open }) => {
  const {
    devices: { video },
    selectedDevices: { videoinput },
    handleSelectedDevice,
  } = useMediaDeviceContext();
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    handleSelectedDevice("videoinput", event.target.value);
  };

  const removeStream = () => {
    if (videoRef.current) {
      if (videoRef.current.srcObject) {
        (videoRef.current.srcObject as MediaStream)
          .getTracks()
          .forEach((track) => {
            track.stop();
          });
      }
      videoRef.current.srcObject = null;
    }
  };

  useEffect(() => {
    if (open) {
      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((stream) => {
            if (videoRef.current) {
              videoRef.current.srcObject = stream;
            }
          })
          .catch(() => {
            notifications.notifyError("error");
          });
      }
    } else {
      removeStream();
    }
    return () => {
      removeStream();
    };
  }, [open]);

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginRight={3}
        >
          <VideoIcon />
        </Box>
        <TextField
          value={videoinput || video[0].deviceId}
          select
          label="camera"
          onChange={handleChange}
        >
          {video.map((el) => (
            <MenuItem key={el.deviceId} value={el.deviceId}>
              {el.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box mt={2} ml={7} display="flex" alignItems="center">
        <video
          autoPlay
          ref={videoRef}
          style={{
            marginTop: 16,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 225,
            maxWidth: 300,
            border: "1px solid #828282",
          }}
        >
          <track kind="captions" />
          Camera Preview
        </video>
      </Box>
    </Box>
  );
};
