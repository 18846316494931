import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CaseClassificationsContext } from "context/CaseClassificationProvider";
import {
  GetServiceCase,
  ServiceCaseResponse,
} from "@deep-consulting-solutions/be2-constants";

import { getClient } from "apis";
import { notifications } from "services";
import Loader from "component/Loader/Loader";
import { CallLayout, useCall } from "call";

export const CaseClassifications = () => {
  const location = useLocation<{ serviceCase: ServiceCaseResponse }>();
  const { setDashboardCall, viewedCall } = useCall();
  const { updateState } = useContext(CaseClassificationsContext);
  const [updateServiceLoading, setUpdateServiceLoading] = useState(true);

  useEffect(() => {
    // on mount
    // navigation from on edit click
    if (location.state) {
      (async () => {
        try {
          const res = await getClient(true).get<typeof GetServiceCase.Res>(
            GetServiceCase.ROUTE.replace(":id", location.state?.serviceCase?.id)
          );
          updateState(res.data.data);
          if (setDashboardCall) {
            setDashboardCall(res.data.data);
          }
          setUpdateServiceLoading(false);
        } catch (e) {
          setUpdateServiceLoading(false);
          notifications.notifyError("Service case info is partial.");
        }
      })();
    }
  }, [location.state, setDashboardCall, updateState]);

  return (
    <>
      <Loader open={updateServiceLoading} />
      {!updateServiceLoading && !!viewedCall?.updatedServiceCase && (
        <CallLayout />
      )}
    </>
  );
};
