import React, { useEffect, useMemo } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Grid,
  DialogTitle,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { StyledTypography } from "component/StyledTypography";
import checked from "images/green-checked-circle.svg";
import invalid from "images/invalid.svg";
import unsuccessful from "images/unsuccessful.svg";
import { PaymentStatusReasonEnum } from "./types";

export const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  container: {
    padding: s(4, 2),
  },
  image: {
    maxWidth: 200,
    width: "50%",
    marginTop: s(4),
  },
  info: {
    marginTop: s(2),
  },
  close: {
    cursor: "pointer",
  },
  dialog: {
    background: "#F7F7F7",
    boxShadow:
      "0px 10px 14px rgba(84, 134, 199, 0.12), 0px 22px 35px rgba(84, 134, 199, 0.15), 0px 8px 42px rgba(84, 134, 199, 0.12)",
    borderRadius: 15,
  },
  action: {
    color: p.primary.main,
    background: "none",
    fontWeight: "bolder",
    textDecoration: "underline",
    border: 0,
    outline: 0,
    padding: 0,
    cursor: "pointer",
  },
}));

export const useDialogStyles = makeStyles(() => ({
  paper: {
    background: "#F7F7F7",
    boxShadow:
      "0px 10px 14px rgba(84, 134, 199, 0.12), 0px 22px 35px rgba(84, 134, 199, 0.15), 0px 8px 42px rgba(84, 134, 199, 0.12)",
    borderRadius: 15,
  },
}));

interface Props {
  isSuccessful: boolean;
  onClose: () => void;
  open: boolean;
  reason: PaymentStatusReasonEnum;
  newCardClick: () => void;
}

export const PaymentStatus = ({
  isSuccessful = true,
  onClose,
  open,
  reason,
  newCardClick,
}: Props) => {
  const classes = useStyles();
  const dialogStyles = useDialogStyles();

  useEffect(() => {
    if (isSuccessful) {
      // Close automatically if successful after 5 sec
      const timeoutId = setTimeout(onClose, 5000);
      return () => clearTimeout(timeoutId);
    }
  }, [onClose, isSuccessful]);

  const errorStatus = useMemo(
    () =>
      reason === PaymentStatusReasonEnum.BAD_CARD ? "Error" : "Unsuccessful",
    [reason]
  );
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      classes={dialogStyles}
    >
      <DialogTitle>
        <Grid container justify="flex-end">
          <Grid item>
            <Close
              onClick={onClose}
              color="disabled"
              className={classes.close}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          className={classes.container}
        >
          <StyledTypography color="primary.dark" align="center" variant="h3">
            Payment {isSuccessful ? "Successful" : errorStatus}
          </StyledTypography>
          {isSuccessful && (
            <>
              <StyledTypography
                color="primary.main"
                variant="body1"
                align="center"
                className={classes.info}
              >
                Your payment was successful!
              </StyledTypography>
              <img src={checked} alt="success" className={classes.image} />
            </>
          )}
          {!isSuccessful && reason === PaymentStatusReasonEnum.BAD_CARD && (
            <>
              <StyledTypography
                color="primary.main"
                variant="body1"
                align="center"
                className={classes.info}
              >
                We encountered an error while verifying your Credit Card
                details.
                <br />
                Please check your Credit Card information or try a{" "}
                {/* // eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                <button
                  type="button"
                  className={classes.action}
                  onClick={newCardClick}
                >
                  New Card
                </button>
              </StyledTypography>
              <img src={invalid} alt="success" className={classes.image} />
            </>
          )}

          {!isSuccessful && reason === PaymentStatusReasonEnum.UNSUCCESSFUL && (
            <>
              <StyledTypography
                color="primary.main"
                variant="body1"
                align="center"
                className={classes.info}
              >
                We encountered an error while processing your payment. Please{" "}
                <button
                  type="button"
                  className={classes.action}
                  onClick={onClose}
                >
                  try again
                </button>
                .
              </StyledTypography>
              <img src={unsuccessful} alt="success" className={classes.image} />
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
