import React, { useRef, useEffect, useCallback, useState } from "react";
import { Button, Dialog, Typography, makeStyles } from "@material-ui/core";
// import Bowser from "bowser";

import { getLocation, PositionErrorType } from "helpers";
import { ErrorOutlineOutlined } from "@material-ui/icons";

// const browser = Bowser.getParser(window.navigator.userAgent);

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  root: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 361,
      height: "100%",
    },
    "& .MuiBackdrop-root": {
      backgroundColor: p.common.white,
    },
  },
  paper: {
    backgroundColor: p.common.white,
    borderRadius: s(1),
    padding: s(3, 2),
    color: "#4F4F4F",
    boxShadow:
      "0px 10px 14px rgba(84, 134, 199, 0.12), 0px 22px 35px rgba(84, 134, 199, 0.15), 0px 8px 42px rgba(84, 134, 199, 0.12)",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    fontSize: s(2.5),
    lineHeight: "24px",
    fontFamily: "'Roboto', sans-serif",
    marginBottom: s(1),
    fontWeight: 700,
    color: "#CA2027",
  },
  withIcon: {
    display: "inline-flex",
    alignItems: "center",
    "& img": {
      margin: s(0, 1),
      height: 16,
    },
  },
  warningText: {
    color: "#CA2027",
    width: 128,
    height: 128,
    marginTop: s(3),
  },
  text: {
    textAlign: "center",
  },
  list: {
    color: p.primary.main,
  },
  caption: {
    color: "#828282",
    fontSize: s(1.25),
    textAlign: "center",
  },
}));

const LocationPermissionDialog: React.FC = () => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState<PositionErrorType | null>(null);
  const [, setLocation] = useState<GeolocationPosition | undefined>();

  // Create wrapper refs to access values even during setTimeout
  // https://github.com/facebook/react/issues/14010
  const showDialogRef = useRef(showDialog);
  showDialogRef.current = showDialog;

  const checkLocationPermission = useCallback(async () => {
    const { error, position } = await getLocation();

    if (error) {
      return setShowDialog(error.code);
    }
    setLocation(position);
  }, []);

  const renderTryAgain = (text?: string) => {
    return (
      <div style={{ width: "100%", marginTop: 20 }}>
        <Button
          onClick={() => {
            window.location.reload();
          }}
          color="primary"
          style={{ float: "right" }}
        >
          {text || "Retry"}
        </Button>
      </div>
    );
  };

  const renderUnsupportedDialog = () => {
    return (
      <div className={classes.container}>
        <Typography variant="h5" color="error" className={classes.header}>
          Failed to get Location.
        </Typography>
        <Typography variant="body2" className={classes.text}>
          Getting Location is not supported by this Browser. Please, use a
          different browser.
        </Typography>
        <ErrorOutlineOutlined className={classes.warningText} />
      </div>
    );
  };

  const renderPositionUnavailableDialog = () => {
    return (
      <div className={classes.container}>
        <Typography variant="h5" color="error" className={classes.header}>
          Failed to get Location.
        </Typography>
        <Typography variant="body2" className={classes.text}>
          Please, refresh the page and if you see the same error please use a
          different browser or device.
        </Typography>
        <ErrorOutlineOutlined className={classes.warningText} />
      </div>
    );
  };

  const renderPermissionDeniedDialog = () => (
    <div className={classes.container}>
      <Typography variant="h5" color="error" className={classes.header}>
        Failed to get Location.
      </Typography>
      <Typography variant="body2" className={classes.text}>
        Please Enable Location Permission.
      </Typography>
      <Typography variant="caption" className={classes.caption}>
        Enabling Location Permissions might be different for other devices or
        browsers.
      </Typography>
      <ol className={classes.list}>
        <li>Click on the lock icon beside the website name</li>
        <li>Click on Permissions</li>
        <li>Enable Location Permission Click this button</li>
        <li>Reload the page to apply the changes.</li>
      </ol>
    </div>
  );

  const renderTimeoutDialog = () => {
    return (
      <div>
        <Typography variant="h5" color="primary" className={classes.header}>
          Can&apos;t start your <span>microphone and/or camera</span>
        </Typography>
        <Typography>
          Another application (Zoom, Webex) or browser tab (Google Meet,
          Messenger Video) might already be using your webcam. Please turn off
          other cameras before proceeding.
        </Typography>
        {renderTryAgain()}
      </div>
    );
  };

  const renderDialogContent = () => {
    switch (showDialog) {
      case "UNSUPPORTED":
        return renderUnsupportedDialog();
      case "PERMISSION_DENIED":
        return renderPermissionDeniedDialog();
      case "POSITION_UNAVAILABLE":
        return renderPositionUnavailableDialog();
      case "TIMEOUT":
        return renderTimeoutDialog();
      default:
        return null;
    }
  };

  useEffect(() => {
    checkLocationPermission();
  }, [checkLocationPermission]);

  return (
    <Dialog
      classes={{ paper: classes.paper, root: classes.root }}
      open={!!showDialog}
    >
      {showDialog && renderDialogContent()}
    </Dialog>
  );
};

export default LocationPermissionDialog;
