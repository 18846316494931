import { Grid, makeStyles } from "@material-ui/core";
import { getIn, Field, useFormikContext } from "formik";
import { TextField } from "formik-material-ui";
import { SelectField } from "component/atoms";
import DCSPhoneInput from "component/atoms/DCSPhoneInput";
import {
  GenderEnum,
  NextOfKinData,
  ServiceCaseRequesterPatientRelationEnum,
  ServiceCaseRequestTypeEnum,
  PatientTypeEnum,
  ServiceCaseStatusEnum,
} from "@deep-consulting-solutions/be2-constants";
import { Alert } from "@material-ui/lab";
import { FormikDatePicker } from "@deep-consulting-solutions/dcs-web-ui";
import { endOfYesterday } from "date-fns";
import { DATE_DOT_FORMAT, DATE_TYPING_FORMAT } from "configs";
import { useCall } from "call/hooks";
import { FormikNumberField } from "component/atoms/FormikNumberField";
import { NextOfKin } from "call/components/CallForms/RequesterAndPatientForm/NextOfKin";
import { useStyles } from "call/components/CallForms/RequesterAndPatientForm/styles";
import { useCallback, useEffect, useMemo } from "react";
import { getAge } from "./helpers";
import { ExistingNextOfKin } from "./ExistingNextOfKin";
import { FormValues, NextOfKinTypeEnum } from "./types";

const useInfoStyles = makeStyles(({ palette: p, spacing: s }) => ({
  filledInfo: {
    backgroundColor: p.primary.main,
    fontSize: s(1),
    marginBottom: s(-0.25),
    padding: s(0, 0.5),
    width: "100%",
    borderRadius: "2px",
    alignItems: "center",
    paddingTop: s(0.25),
    paddingBottom: s(0.25),
    minHeight: s(2),
  },
  message: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  icon: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: s(1.25),
    marginRight: s(0.5),
  },
}));
const Info: React.FC<{ info: string }> = ({ info }) => {
  const classes = useInfoStyles();

  return (
    <Alert severity="info" variant="filled" classes={classes}>
      {info}
    </Alert>
  );
};

const genderOptions = Object.entries(GenderEnum).map(([key, value]) => {
  return {
    label: key[0] + key.slice(1).toLowerCase(),
    value,
  };
});

interface Props {
  formKey: string;
  disableForm: boolean;
  patient?: Partial<{
    phone: string;
    email: string;
    firstName: string;
    lastName: string;
    dob: string;
    age: number;
    nextOfKin: NextOfKinData;
    requesterRelationToPatient: ServiceCaseRequesterPatientRelationEnum;
    requesterOtherRelationToPatient: string;
    isMemberOrCustomer?: boolean;
    patientType: PatientTypeEnum;
    isDeceased: boolean;
    nextOfKinMembership?: string;
  }>;
}

export const PatientForm: React.FC<Props> = ({
  patient,
  formKey,
  disableForm,
}) => {
  const { viewedCall } = useCall();
  const classes = useStyles({});
  const { errors, touched, setFieldValue } = useFormikContext<FormValues>();

  const phoneError = getIn(errors, `${formKey}.phone`);
  const hasPhoneError = getIn(touched, `${formKey}.phone`) && !!phoneError;

  const setAge = useCallback(() => {
    if (!patient?.dob) {
      return undefined;
    }

    const ageInYears = getAge(patient.dob);
    if (ageInYears !== patient?.age) {
      setFieldValue(`${formKey}.age`, ageInYears > 0 ? ageInYears : "");
    }
  }, [formKey, patient?.dob, patient?.age, setFieldValue]);

  const shouldRequestMinimalInfo = useMemo(() => {
    if (formKey === "requester") {
      return false;
    }
    if (
      patient &&
      patient.patientType === PatientTypeEnum.NEW &&
      patient.isDeceased
    ) {
      return true;
    }

    if (
      viewedCall?.updatedServiceCase?.requestType ===
      ServiceCaseRequestTypeEnum.EMERGENCY
    ) {
      return true;
    }

    return false;
  }, [patient, formKey, viewedCall?.updatedServiceCase?.requestType]);

  const showExistingNextOfKinComponent = useMemo(() => {
    if (formKey === "requester") {
      return (
        viewedCall?.updatedServiceCase?.requestType !==
        ServiceCaseRequestTypeEnum.EMERGENCY
      );
    }

    if (shouldRequestMinimalInfo) {
      return false;
    }

    if (!!patient?.age && patient.age >= 18) {
      return true;
    }

    return false;
  }, [
    patient,
    formKey,
    shouldRequestMinimalInfo,
    viewedCall?.updatedServiceCase?.requestType,
  ]);

  const showNextOfKinComponent = useMemo(() => {
    if (formKey === "requester") {
      return false;
    }

    if (shouldRequestMinimalInfo) {
      return false;
    }

    if (!patient?.age || patient.age < 18) {
      return true;
    }

    return false;
  }, [patient, formKey, shouldRequestMinimalInfo]);

  useEffect(() => {
    setAge();
  }, [setAge]);

  const disableFields = useMemo(() => {
    if (
      viewedCall?.updatedServiceCase?.caseStatus !==
      ServiceCaseStatusEnum.GATHERING_REQUIREMENTS
    ) {
      return {
        firstName: true,
        lastName: true,
        phone: true,
        email: true,
        relationshipToPatient: true,
        otherRelationToPatient: true,
      };
    }

    return undefined;
  }, [viewedCall?.updatedServiceCase?.caseStatus]);

  useEffect(() => {
    if (
      formKey === "requester" &&
      (patient?.age || 0) < 18 &&
      viewedCall?.updatedServiceCase?.requestType !==
        ServiceCaseRequestTypeEnum.EMERGENCY &&
      patient?.nextOfKinMembership === NextOfKinTypeEnum.NONE
    ) {
      setFieldValue(`${formKey}.nextOfKinMembership`, NextOfKinTypeEnum.NEW);
    }
  }, [
    formKey,
    patient?.age,
    setFieldValue,
    patient?.nextOfKinMembership,
    viewedCall?.updatedServiceCase?.requestType,
  ]);

  return (
    <div className={classes.patientForm}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Field
            size="small"
            component={TextField}
            name={`${formKey}.firstName`}
            label="First Name"
            required
            disabled={disableForm}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            size="small"
            component={TextField}
            name={`${formKey}.lastName`}
            label="Last Name"
            required
            disabled={disableForm}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectField
            size="small"
            name={`${formKey}.gender`}
            label="Gender"
            required={!shouldRequestMinimalInfo}
            options={genderOptions}
            disabled={disableForm}
          />
        </Grid>
        {!patient?.dob && !patient?.age && (
          <Grid item xs={12}>
            <Info info="Enter the patient Date of Birth or Age if the Date of Birth is unknown (at least one is required)." />
          </Grid>
        )}
        <Grid item xs={4}>
          <Field
            size="small"
            component={FormikDatePicker}
            disablePast={false}
            disableToolbar={false}
            disableFuture
            maxDate={endOfYesterday()}
            label="Date of Birth"
            name={`${formKey}.dob`}
            placeholder={DATE_TYPING_FORMAT}
            typingFormat={DATE_TYPING_FORMAT}
            displayFormat={DATE_DOT_FORMAT}
            invalidDateMessage=""
            disabled={disableForm}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            size="small"
            name={`${formKey}.age`}
            component={FormikNumberField}
            label="Age"
            disabled={!!patient?.dob || disableForm}
          />
        </Grid>
        {formKey !== "requester" && (
          <>
            <Grid item xs={4}>
              <SelectField
                size="small"
                name={`${formKey}.requesterRelationToPatient`}
                label="Requester relation to patient"
                required={!shouldRequestMinimalInfo}
                options={Object.values(
                  ServiceCaseRequesterPatientRelationEnum
                ).map((relation) => ({
                  label: relation,
                  value: relation,
                }))}
                disabled={disableForm}
              />
            </Grid>
            {patient?.requesterRelationToPatient ===
              ServiceCaseRequesterPatientRelationEnum.Other && (
              <Grid item xs={4}>
                <Field
                  size="small"
                  component={TextField}
                  name={`${formKey}.requesterOtherRelationToPatient`}
                  label="Requester relation to patient"
                  required={!shouldRequestMinimalInfo}
                  disabled={disableForm}
                />
              </Grid>
            )}
          </>
        )}
        <Grid item xs={4}>
          <Field
            size="small"
            component={TextField}
            name={`${formKey}.email`}
            label="Email"
            disabled={disableForm}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            size="small"
            label="Phone"
            error={hasPhoneError}
            component={DCSPhoneInput}
            name={`${formKey}.phone`}
            required={!shouldRequestMinimalInfo}
            disabled={disableForm}
            helperText={hasPhoneError ? phoneError : undefined}
          />
        </Grid>
      </Grid>
      <div className={classes.fullSpaced}>
        {showNextOfKinComponent && (
          <NextOfKin
            formKey={formKey}
            nextOfKin={patient?.nextOfKin}
            disabledFields={
              disableForm
                ? {
                    firstName: true,
                    lastName: true,
                    phone: true,
                    email: true,
                    relationshipToPatient: true,
                    otherRelationToPatient: true,
                  }
                : undefined
            }
          />
        )}

        {showExistingNextOfKinComponent && !!patient && (
          <ExistingNextOfKin
            formKey={formKey}
            nextOfKin={patient.nextOfKin}
            memberships={[]}
            patient={patient}
            disabledFields={disableFields}
            forceDisableFields={disableForm}
          />
        )}
      </div>
    </div>
  );
};
