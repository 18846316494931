import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { FC, useMemo, useState } from "react";
import clsx from "clsx";
import Loader from "component/Loader";
import { Field, FormikProvider, useFormik } from "formik";
import { CloseOutlined } from "@material-ui/icons";
import { useDialogStyles } from "component/Dialogs/styles";
import { TextField } from "formik-material-ui";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  QuoteStatusEnum,
  ServiceCaseResponse,
} from "@deep-consulting-solutions/be2-constants";
import { closeServiceCase } from "component/Dialogs/request";
import { notifications } from "services";
import * as yup from "yup";
import { getENText } from "helpers";
// import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { ServiceCaseResponseExtended } from "@deep-consulting-solutions/be2-constants/build/types/types";

const useStyle = makeStyles<Theme, { maxWidth?: number }>((theme) => ({
  fields: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 20,
  },
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      width: "100%",
      maxWidth: ({ maxWidth }) => maxWidth || 700,
      borderRadius: "4px 4px 16px 16px",
    },
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

export const CloseServiceCase: FC<{
  id: string;
  open: boolean;
  onClose?: (action?: string) => void;
  confirmDialog?: boolean;
  maxWidth?: number;
  quotes?: ServiceCaseResponseExtended["quotes"];
  onUpdate?: (data: ServiceCaseResponse) => void;
}> = ({ open, confirmDialog, onUpdate, id, quotes, onClose, maxWidth }) => {
  const classes = useStyle({ maxWidth });
  const baseClasses = useDialogStyles();

  const pendingQuotes = useMemo(
    () =>
      quotes?.filter((el) =>
        [
          QuoteStatusEnum.PENDING_APPROVAL,
          QuoteStatusEnum.PENDING_PAYMENT,
        ].includes(el.status)
      ),
    [quotes]
  );

  const btnTheme = React.useMemo(() => {
    return createTheme({
      palette: {
        secondary: {
          main: "#CA2027",
          contrastText: "#fff",
        },
      },
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      reasonForClosing: "",
    },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: yup.object(
      confirmDialog
        ? { reasonForClosing: yup.string() }
        : {
            reasonForClosing: yup
              .string()
              .required(getENText("validation.reason.required")),
          }
    ),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const res = await closeServiceCase(id, values);
      if (confirmDialog && onUpdate) {
        onUpdate(res);
      }
      notifications.notifySuccess(`Service case #${res?.caseNumber} Closed`);
      resetForm();
      setSubmitting(false);
      if (onClose) onClose("close-all");
    },
  });

  const handleClose = () => {
    formik.resetForm();
    if (onClose) onClose();
  };

  const [checked, setChecked] = useState(false);

  const checkToContinue = (_: any, v: boolean) => {
    setChecked(v);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      onBackdropClick={handleClose}
      className={clsx(classes.dialog, baseClasses.dialog)}
    >
      {confirmDialog ? (
        <>
          <Loader open={formik.isSubmitting} absolute />
          <div className={baseClasses.dialogTitle}>
            <Typography
              color="primary"
              style={{ fontSize: 20, fontWeight: 500 }}
            >
              Close Case
            </Typography>
            <IconButton size="small" onClick={handleClose}>
              <CloseOutlined style={{ fontSize: 20, color: "#BDBDBD" }} />
            </IconButton>
          </div>
          <DialogContent>
            <FormControlLabel
              style={{ marginBottom: 10 }}
              control={<Checkbox size="small" color="primary" />}
              onChange={checkToContinue}
              checked={checked}
              labelPlacement="end"
              label={
                <Typography color="primary" style={{ marginTop: 20 }}>
                  I confirm that all administrative tasks related to this
                  Service Case have been completed.
                </Typography>
              }
            />
            {/* this part of the code was removed in minimal scope */}
            {/*  <Box */}
            {/*    display="flex" */}
            {/*    alignItems="center" */}
            {/*    bgcolor={pendingQuotes?.length ? "#feeceb" : undefined} */}
            {/*    borderRadius="4px" */}
            {/*  > */}
            {/*    <Box */}
            {/*      display="flex" */}
            {/*      justifyContent="center" */}
            {/*      alignItems="center" */}
            {/*      style={{ width: 30 }} */}
            {/*      mt={1} */}
            {/*    > */}
            {/*      {!!pendingQuotes?.length && ( */}
            {/*        <ErrorOutlineIcon */}
            {/*          style={{ color: "#F44336", fontSize: 16 }} */}
            {/*        /> */}
            {/*      )} */}
            {/*    </Box> */}
            {/*    <Typography */}
            {/*      component="div" */}
            {/*      variant="body2" */}
            {/*      style={{ marginTop: 10, color: "#4F4F4F" }} */}
            {/*    > */}
            {/*      {pendingQuotes?.length ? ( */}
            {/*        <Box color="#621b16" padding="9px 0px 9px"> */}
            {/*          Service Case can’t be closed because there are pending */}
            {/*          quotes: */}
            {/*          <ul style={{ margin: 0 }}> */}
            {/*            {pendingQuotes?.map(({ quoteNumber, status }) => ( */}
            {/*              <li style={{ marginLeft: -15 }}> */}
            {/*                Quote {quoteNumber} is {status} */}
            {/*              </li> */}
            {/*            ))} */}
            {/*          </ul> */}
            {/*        </Box> */}
            {/*      ) : ( */}
            {/*        <> */}
            {/*          <b>Administrative tasks like:</b> */}
            {/*          <ul> */}
            {/*            <li>Payables Recorded and Relevant Payables Paid. </li> */}
            {/*            <li>The Post-Service Routine is done.</li> */}
            {/*          </ul> */}
            {/*        </> */}
            {/*      )} */}
            {/*    </Typography> */}
            {/*  </Box> */}
          </DialogContent>
          <DialogActions style={{ padding: 24 }}>
            <Button
              color="primary"
              type="submit"
              onClick={() => formik.handleSubmit()}
              disabled={!pendingQuotes?.length && !checked}
            >
              Close Case
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <Loader open={formik.isSubmitting} absolute />
          <FormikProvider value={formik}>
            <div className={baseClasses.dialogTitle}>
              <Typography
                color="primary"
                style={{ fontSize: 20, fontWeight: 500 }}
              >
                Close Service Case
              </Typography>
              <IconButton size="small" onClick={handleClose}>
                <CloseOutlined style={{ fontSize: 20, color: "#BDBDBD" }} />
              </IconButton>
            </div>
            <DialogContent>
              <form>
                <Box mb={2}>
                  <Typography color="primary" style={{ fontWeight: 500 }}>
                    Reason for Closing
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Enter your reason for closing the Service Case
                  </Typography>
                </Box>
                <Field
                  size="small"
                  component={TextField}
                  name="reasonForClosing"
                  label="Enter Reason"
                  multiline
                  required
                  minRows={3}
                />
              </form>
            </DialogContent>
            <DialogActions style={{ padding: 24 }}>
              <Button
                color="primary"
                variant="outlined"
                type="submit"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <ThemeProvider theme={btnTheme}>
                <Button
                  color="secondary"
                  variant="contained"
                  type="submit"
                  onClick={formik.handleSubmit as any}
                  disabled={!formik.isValid}
                >
                  Close Service Case
                </Button>
              </ThemeProvider>
            </DialogActions>
          </FormikProvider>
        </>
      )}
    </Dialog>
  );
};
