import {
  SortOrderEnum,
  GetFetchServiceCases,
  ServiceCaseStatusEnum,
} from "@deep-consulting-solutions/be2-constants";
import { getClient } from "apis";

export const getUnanswered = (
  params: Partial<typeof GetFetchServiceCases.Params> = {}
) => {
  return getClient(true).get<typeof GetFetchServiceCases["Res"]>(
    GetFetchServiceCases.ROUTE,
    {
      params: {
        perPage: 20,
        searchText: "",
        sortBy: "createdAt",
        order: SortOrderEnum.DESC,
        ...params,
        status: ServiceCaseStatusEnum.UNANSWERED,
      } as typeof GetFetchServiceCases.Params,
    }
  );
};
