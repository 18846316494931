import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useCallback, MouseEventHandler } from "react";
import * as yup from "yup";
import { FormikProvider, useFormik, Field } from "formik";
import { getENText } from "helpers";
import { notifications } from "services";
import { TextField } from "formik-material-ui";
import { useCall } from "call/hooks";
import { putCloseCase } from "redux/call/requests";
import Loader from "component/Loader";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles(({ spacing: s }) => ({
  title: {
    background: "#F6F6F6",
    padding: s(2),
  },
  content: {
    padding: s(2),
  },
  actions: {
    padding: s(2),
  },
  bodyText: {
    color: "#4F4F4F",
    marginBottom: s(2),
  },
  wrapper: {
    borderRadius: 16,
  },
  true: {
    background: "rgba(202, 32, 39, 1)",
    color: "white",
    "&:hover": {
      background: "rgba(202, 32, 39, 0.8)",
    },
  },
  titleText: {
    fontWeight: 500,
  },
  closeIcon: {
    color: "#4F4F4F",
  },
}));

interface Props {
  handleClose: () => void;
}

export const FalseAlarmDialog = ({
  handleClose,
  ...props
}: DialogProps & Props) => {
  const { viewedCall, updateCallServiceCase } = useCall();

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema: yup.object({
      reason: yup
        .string()
        .required(getENText("initialAssessmentForm.falseAlarmReason.required")),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const res = await putCloseCase(
          viewedCall!.updatedServiceCase!.id,
          values.reason
        );
        updateCallServiceCase(viewedCall!.sessionId, res);
        notifications.notifySuccess(
          getENText("initialAssessmentForm.falseAlarm.successful")
        );
        handleClose();
      } catch (error) {
        //
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      formik.handleSubmit();
    },
    [formik]
  );

  const handleCancel: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      handleClose();
    },
    [handleClose]
  );

  return (
    <>
      <Loader open={formik.isSubmitting} />
      <Dialog {...props} className={classes.wrapper} fullWidth maxWidth="sm">
        <DialogTitle className={classes.title}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography
                align="left"
                color="primary"
                variant="subtitle1"
                className={classes.titleText}
              >
                Close Service Case {viewedCall?.updatedServiceCase?.caseNumber}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <Close className={classes.closeIcon} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Typography variant="body2" align="left" className={classes.bodyText}>
            To close the ticket you need to provide a reason
          </Typography>
          <FormikProvider value={formik}>
            <form>
              <Field
                component={TextField}
                name="reason"
                label="Reason"
                required
              />
            </form>
          </FormikProvider>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
          >
            <Grid item>
              <Button color="primary" variant="outlined" onClick={handleCancel}>
                CANCEL
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.true}
                color="primary"
                onClick={handleSubmit}
                disabled={!formik.isValid}
              >
                CLOSE CASE
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
