import React, { useCallback, useState } from "react";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { ServiceCaseResponse } from "@deep-consulting-solutions/be2-constants";

import { useAppSelector } from "redux/store";
import { dispatcherSelectors } from "redux/dispatcher";

import { TabsEnum } from "../../types";
import { CancelApprovalRequestDialog } from "./CancelApprovalRequestDialog";

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  wrapper: {
    display: "flex",
    alignItems: "stretch",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    marginBottom: s(4),
  },
  textIcon: {
    background: "rgba(237, 241, 247, 1)",
    borderRadius: 4,
    marginRight: s(2),
    flex: 1,
    display: "flex",
    alignItems: "center",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    padding: s(0, 2),
  },
  icon: {
    color: p.primary.main,
    marginRight: s(2),
  },
  text: { fontWeight: 700 },
  cancel: {
    background: "rgba(202, 32, 39, 1)",
    color: "white",
    "&:hover": {
      background: "rgba(202, 32, 39, 0.8)",
    },
  },
}));

interface Props {
  serviceCase?: ServiceCaseResponse;
  handleDisableTab: (tab: TabsEnum) => void;
}

export const ServiceCaseAwaiting = ({
  serviceCase,
  handleDisableTab,
}: Props) => {
  const [showCancelApprovalDialog, setShowCancelApprovalDialog] =
    useState(false);

  const handleCancelApprovalRequest = useCallback(() => {
    setShowCancelApprovalDialog(true);
  }, []);

  const handleCloseApprovalRequestDialog = useCallback(() => {
    setShowCancelApprovalDialog(false);
  }, []);

  const id = useAppSelector(dispatcherSelectors.id);

  const classes = useStyles();

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.textIcon}>
          <div>
            <InfoOutlined className={classes.icon} fontSize="small" />
          </div>
          <div>
            <Typography
              align="left"
              className={classes.text}
              variant="subtitle2"
            >
              Waiting for Approval
            </Typography>
          </div>
        </div>
        <Button
          className={classes.cancel}
          onClick={handleCancelApprovalRequest}
          disabled={id !== serviceCase?.dispatcher.id}
        >
          CANCEL APPROVAL REQUEST
        </Button>
      </div>

      <CancelApprovalRequestDialog
        open={showCancelApprovalDialog}
        handleClose={handleCloseApprovalRequestDialog}
        handleDisableTab={handleDisableTab}
      />
    </>
  );
};
