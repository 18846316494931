import { useEffect, useState } from "react";
import { Box, MenuItem, TextField, makeStyles } from "@material-ui/core";
import { ReactComponent as Mic } from "images/mic.svg";
import { ReactComponent as SmallMic } from "images/smallMic.svg";
import { AudioVisualizer } from "component/Dialogs/audioVideoSettings/AudioVisualizer";
import { useAudioContext } from "context/AudioContext";
import { useMediaDeviceContext } from "context/MediaDeviceContext";

interface BarProps {
  active: boolean;
}

const useBarStyle = makeStyles(({ palette: p }) => ({
  bar: ({ active }: BarProps) => ({
    height: "12px",
    backgroundColor: active ? p.primary.main : "#C4C4C4",
    width: "3px",
    borderRadius: "1px",
  }),
}));

export const AudioBar: React.FC<BarProps> = ({ active }) => {
  const classes = useBarStyle({ active });

  return <div className={classes.bar} />;
};

export const AudioModule: React.FC = () => {
  const {
    devices: {
      audio: { input },
    },
    selectedDevices: { audioinput },
    handleSelectedDevice,
  } = useMediaDeviceContext();

  const {
    state,
    device,
    startRecord,
    audioUrl,
    stopRecord,
    play,
    stopPlay,
    visualData,
    initAudioRecorder,
  } = useAudioContext();

  const [buttonText, setButtonText] = useState("Test");

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    handleSelectedDevice("audioinput", event.target.value);
  };

  const recording = state === "recording";
  const playing = state === "playing" && device === "audioinput";
  const canPlay = state === "inactive" && audioUrl;

  const playAudio = () => {
    if (recording) {
      stopRecord();
    } else if (playing) {
      stopPlay();
    } else if (canPlay) {
      play("audioinput");
    } else {
      startRecord();
    }
  };

  useEffect(() => {
    initAudioRecorder(audioinput);
  }, [audioinput, initAudioRecorder]);

  useEffect(() => {
    if (recording) {
      setButtonText("Recording");
    } else if (playing) {
      setButtonText("Playing");
    } else if (canPlay) {
      setButtonText("Play");
    } else {
      setButtonText("Test");
    }
  }, [canPlay, playing, recording]);

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginRight={3}
        >
          <Mic />
        </Box>
        <TextField
          color="primary"
          select
          value={audioinput || input[0].deviceId}
          label="Microphone"
          onChange={handleChange}
          placeholder="label"
        >
          {input.map((el) => (
            <MenuItem key={el.deviceId} value={el.deviceId}>
              {el.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <AudioVisualizer
        data={visualData}
        onButtonClick={playAudio}
        buttonIcon={<SmallMic />}
        buttonText={buttonText}
        isActive={recording || playing}
      />
    </Box>
  );
};
