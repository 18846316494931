import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit";
import { DispatcherActivityLog } from "@deep-consulting-solutions/be2-constants";

import type { RootState } from "../reducers";
import * as attentionCheckRequests from "./requests";
import { composeAttentionCheckLog, composeLateCheckinLog } from "./helpers";

const ENTITY = "attentionCheck";

const closeDialog = createAction(`${ENTITY}/closeDialog`);

const openDialog = createAction(`${ENTITY}/openDialog`);

const getLastSystemAction = createAsyncThunk<
  DispatcherActivityLog | null,
  void,
  { state: RootState }
>(`${ENTITY}/getLastSystemAction`, async (_, { getState }) => {
  const { zohoID } = getState().dispatcher;
  const action = await attentionCheckRequests.getLastSystemActionReq(zohoID);
  return action;
});

const reportLateCheckin = createAsyncThunk<
  { ticketKey: string } | null,
  void,
  { state: RootState }
>(`${ENTITY}/reportLateCheckin`, async (_, { getState }) => {
  const state = getState();
  const { zohoID } = state.dispatcher;
  const { open } = state.attentionCheck;
  if (!open) return null;
  const res = await attentionCheckRequests.reportLateCheckinReq(
    zohoID,
    new Date(open).toISOString()
  );
  return res;
});

const checkin = createAction(`${ENTITY}/checkin`);

const logCheckin = createAsyncThunk<
  void,
  { entryTime: Date },
  { state: RootState }
>(`${ENTITY}/logCheckin`, async ({ entryTime }, { getState }) => {
  const {
    dispatcher: { zohoID },
    attentionCheck: { ticketKey, open },
  } = getState();

  if (!open) return;
  await attentionCheckRequests.logCheckinReq(
    zohoID,
    composeAttentionCheckLog(entryTime, new Date(open), ticketKey)
  );
});

const logFailedCheckin = createAsyncThunk<
  void,
  { entryTime: Date },
  { state: RootState }
>(`${ENTITY}/logFailedCheckin`, async ({ entryTime }, { getState }) => {
  const {
    dispatcher: { zohoID },
    attentionCheck: { ticketKey, open },
  } = getState();
  if (!open) return;
  await attentionCheckRequests.logCheckinReq(
    zohoID,
    composeLateCheckinLog(entryTime, new Date(open), ticketKey)
  );
});

export interface AttentionCheckState {
  open: number | null;
  ticketKey: string | null;
  checkinCount: number;
  loading: boolean;
  failureCount: number;
}

const initialState: AttentionCheckState = {
  open: null,
  ticketKey: null,
  checkinCount: 0,
  loading: false,
  failureCount: 0,
};

const slice = createSlice({
  name: ENTITY,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(closeDialog, (state) => {
        state.open = null;
        state.ticketKey = null;
        return state;
      })
      .addCase(openDialog, (state) => {
        state.open = new Date().getTime();
        state.ticketKey = null;
        return state;
      })
      .addCase(reportLateCheckin.fulfilled, (state, action) => {
        if (action.payload?.ticketKey) {
          state.ticketKey = action.payload.ticketKey;
        }
        return state;
      })
      .addCase(checkin, (state) => {
        state.checkinCount += 1;
        if (state.open) state.loading = true;
        return state;
      })
      .addCase(logCheckin.pending, (state) => {
        state.loading = true;
        return state;
      })
      .addCase(logCheckin.fulfilled, (state) => {
        state.open = null;
        state.ticketKey = null;
        state.loading = false;
        return state;
      })
      .addCase(logCheckin.rejected, (state) => {
        state.open = null;
        state.ticketKey = null;
        state.loading = false;
        return state;
      })
      .addCase(logFailedCheckin.pending, (state) => {
        state.loading = true;
        return state;
      })
      .addCase(logFailedCheckin.fulfilled, (state) => {
        state.open = new Date().getTime();
        state.ticketKey = null;
        state.loading = false;
        state.failureCount += 1;
        return state;
      })
      .addCase(logFailedCheckin.rejected, (state) => {
        state.open = new Date().getTime();
        state.ticketKey = null;
        state.loading = false;
        state.failureCount += 1;
        return state;
      }),
});

export const attentionCheckActions = {
  closeDialog,
  openDialog,
  getLastSystemAction,
  reportLateCheckin,
  checkin,
  logCheckin,
  logFailedCheckin,
};

export const attentionCheckSelectors = {
  getOpen: (state: RootState) => state.attentionCheck.open,
  getCheckinCount: (state: RootState) => state.attentionCheck.checkinCount,
  getLoading: (state: RootState) => state.attentionCheck.loading,
  getFailureCount: (state: RootState) => state.attentionCheck.failureCount,
};

export * as attentionCheckRequests from "./requests";

export default slice.reducer;
