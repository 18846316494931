import { Grid } from "@material-ui/core";
import { Field, getIn, useFormikContext } from "formik";
import { TextField } from "formik-material-ui";
import DCSPhoneInput from "component/atoms/DCSPhoneInput";
import { PatientForm } from "call/components/CallForms/RequesterAndPatientForm/PatientForm";
import { GenderEnum } from "@deep-consulting-solutions/be2-constants";
import { FormValues } from "./types";
import { PatientMedicalInformationSection } from "./PatientMedicalInformationSection";

interface Props {
  isPatient?: boolean;
  disableForm: boolean;
}

const formKey = "requester";

export const RequesterInformation = ({ isPatient, disableForm }: Props) => {
  const { errors, values, touched } = useFormikContext<FormValues>();

  const phoneError = getIn(errors, `${formKey}.phone`);
  const hasPhoneError = getIn(touched, `${formKey}.phone`) && !!phoneError;

  return (
    <div>
      {isPatient ? (
        <>
          <PatientForm
            formKey={formKey}
            patient={values.requester}
            disableForm={disableForm}
          />
          <PatientMedicalInformationSection
            formKey={formKey}
            patient={{
              ...values.requester,
              gender: values.requester?.gender || ("" as GenderEnum),
            }}
            disableForm={disableForm}
          />
        </>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field
              size="small"
              component={TextField}
              name={`${formKey}.firstName`}
              label="First Name"
              required
              disabled={disableForm}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              size="small"
              component={TextField}
              name={`${formKey}.lastName`}
              label="Last Name"
              required
              disabled={disableForm}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              required
              size="small"
              label="Phone"
              error={hasPhoneError}
              component={DCSPhoneInput}
              name={`${formKey}.phone`}
              disabled={disableForm}
              helperText={hasPhoneError ? phoneError : undefined}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              size="small"
              component={TextField}
              name={`${formKey}.email`}
              label="Email"
              disabled={disableForm}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};
