import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  ZohoEntity,
  ZohoServices,
  ZohoRecord,
  ZohoCurrentUser,
} from "services";
import { setCRMAuthHeader } from "apis";
import { RootState } from "redux/reducers";

const ENTITY = "zoho";

export interface ZohoState {
  ids: string[];
  entity: string;
  records: { [recordId: string]: ZohoRecord };
  token: string | null;
  currentUser: ZohoCurrentUser | null;
}

const initialState: ZohoState = {
  ids: [],
  entity: "",
  records: {},
  token: null,
  currentUser: null,
};

const initZoho = createAsyncThunk<ZohoEntity | null>(
  `${ENTITY}/initZoho`,
  async () => {
    await ZohoServices.injectScript();
    return ZohoServices.init();
  }
);

export const transformInitZohoPayload = (payload: ZohoEntity) => {
  let ids: string[] | null = null;
  if (payload.EntityId) {
    ids = Array.isArray(payload.EntityId)
      ? payload.EntityId
      : [payload.EntityId];
  }
  return {
    ids,
    entity: payload.Entity || null,
  };
};

const getZohoToken = createAsyncThunk(
  `${ENTITY}/generateZohoToken`,
  async () => {
    const data = await ZohoServices.generateAuthToken();
    if (data) setCRMAuthHeader(data.token);
    return data;
  }
);

const fetchRecords = createAsyncThunk(
  `${ENTITY}/get-zoho-records`,
  async (_, store) => {
    const state = store.getState() as any;
    const { ids, entity } = state.zoho as typeof initialState;
    return ZohoServices.getZohoRecords(entity, ids);
  }
);

const fetchCurrentUser = createAsyncThunk<ZohoCurrentUser, void>(
  `${ENTITY}/fetchCurrentUser`,
  async () => {
    const data = await ZohoServices.getCurrentUser();
    return data.users[0];
  }
);

const slice = createSlice({
  name: ENTITY,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(initZoho.fulfilled, (state, action) => {
        if (action.payload) {
          const { ids, entity } = transformInitZohoPayload(action.payload);
          if (ids) state.ids = ids;
          if (entity) state.entity = entity;
        }
      })
      .addCase(getZohoToken.fulfilled, (state, action) => {
        state.token = action.payload?.token ?? null;
        state.currentUser = action.payload?.currentUser ?? null;
      })
      .addCase(fetchRecords.fulfilled, (state, action) => {
        state.records = action.payload.reduce(
          (map, record) => ({
            ...map,
            [record.id]: record,
          }),
          {}
        );
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.currentUser = action.payload;
      }),
});

const getIds = (state: RootState) => state.zoho.ids;
const getEntity = (state: RootState) => state.zoho.entity;
const getToken = (state: RootState) => state.zoho.token;
const getRecords = (state: RootState) => state.zoho.records;
const getCurrentUser = (state: RootState) => state.zoho.currentUser;

export const zohoActions = {
  initZoho,
  fetchRecords,
  getZohoToken,
  fetchCurrentUser,
};

export const zohoSelectors = {
  getIds,
  getEntity,
  getRecords,
  getToken,
  getCurrentUser,
};

export default slice.reducer;
