import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  Box,
} from "@material-ui/core";
import React, { useCallback, MouseEventHandler, useState } from "react";
import * as yup from "yup";
import { FormikProvider, useFormik, Field } from "formik";
import { getENText, openPageUrl } from "helpers";
import { notifications } from "services";
import { SelectField } from "component/atoms";
import { TextField } from "formik-material-ui";

import { useCall } from "call/hooks";
import { provideInformationalSupport } from "redux/call/requests";
import Loader from "component/Loader";
import { Close } from "@material-ui/icons";
import { InformationSupportReasonEnum } from "@deep-consulting-solutions/be2-constants";
import { getInformationalSupportDocumentLink } from "redux/serviceCase/requests";

const reasons = Object.values(InformationSupportReasonEnum);

const useStyles = makeStyles(({ spacing: s }) => ({
  title: {
    background: "#F6F6F6",
    padding: s(2),
  },
  content: {
    padding: s(2),
  },
  actions: {
    padding: s(2),
  },
  bodyText: {
    color: "#4F4F4F",
    marginBottom: s(2),
  },
  wrapper: {
    borderRadius: 16,
  },
  true: {
    background: "rgba(202, 32, 39, 1)",
    color: "white",
    "&:hover": {
      background: "rgba(202, 32, 39, 0.8)",
    },
  },
  titleText: {
    fontWeight: 500,
  },
  closeIcon: {
    color: "#4F4F4F",
  },
  instructions: {
    textDecoration: "underline",
  },
}));

interface Props {
  handleClose: () => void;
}

export const ProvideInformationSupportDialog = ({
  handleClose,
  ...props
}: DialogProps & Props) => {
  const { viewedCall, updateCallServiceCase } = useCall();
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      reason: "",
      explaination: "",
    },
    validationSchema: yup.object({
      reason: yup
        .string()
        .oneOf(Object.values(InformationSupportReasonEnum))
        .required(getENText("informationalSupport.reason.required")),
      explaination: yup.string().when("reason", {
        is: InformationSupportReasonEnum.OTHER,
        then: yup
          .string()
          .required(getENText("informationalSupport.explaination.required")),
      }),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const res = await provideInformationalSupport(
          viewedCall!.updatedServiceCase!.id,
          values
        );
        updateCallServiceCase(viewedCall!.sessionId, res);
        notifications.notifySuccess(
          getENText("informationalSupport.successful")
        );
        handleClose();
      } catch (error) {
        //
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      formik.handleSubmit();
    },
    [formik]
  );

  const handleCancel: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      handleClose();
    },
    [handleClose]
  );

  const openInstructions = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getInformationalSupportDocumentLink();
      openPageUrl(res.url);
    } catch {
      //
    } finally {
      setLoading(false);
    }
  }, []);

  const handleOpenInstructions: MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (e) => {
        e.preventDefault();
        openInstructions();
      },
      [openInstructions]
    );

  return (
    <>
      <Loader open={formik.isSubmitting || loading} />
      <Dialog {...props} className={classes.wrapper} fullWidth maxWidth="sm">
        <DialogTitle className={classes.title}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography
                align="left"
                color="primary"
                variant="subtitle1"
                className={classes.titleText}
              >
                Provide Informational Support
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <Close className={classes.closeIcon} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Typography variant="body2" align="left" className={classes.bodyText}>
            Provide only Informational Support
          </Typography>
          <FormikProvider value={formik}>
            <form>
              <SelectField
                size="small"
                name="reason"
                label="Reason for selecting Informational Support"
                required
                options={reasons}
              />
              {formik.values.reason === InformationSupportReasonEnum.OTHER && (
                <Box mt={2}>
                  <Field
                    size="small"
                    component={TextField}
                    name="explaination"
                    label="Explanation"
                    required
                    minRows={3}
                    multiline
                  />
                </Box>
              )}
            </form>
          </FormikProvider>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item>
              <Button
                color="primary"
                variant="text"
                onClick={handleOpenInstructions}
                className={classes.instructions}
              >
                Instructions
              </Button>
            </Grid>
            <Grid item>
              <Grid
                container
                alignItems="center"
                justifyContent="flex-end"
                spacing={2}
              >
                <Grid item>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={handleCancel}
                  >
                    CANCEL
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!formik.isValid}
                  >
                    SAVE AND COMPLETE
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
