import { getIn, useFormikContext } from "formik";
import { useEffect, useMemo, useState } from "react";
import { ServiceCaseStatusEnum } from "@deep-consulting-solutions/be2-constants";

import { useCall } from "call/hooks";
import { StyledAccordion } from "component/atoms/StyledAccordion";
import { NextOfKinFormProps } from "call/components/CallForms/RequesterAndPatientForm/types";
import { NextOfKinForm } from "call/components/CallForms/RequesterAndPatientForm/NextOfKinForm";

export const NextOfKin: React.FC<NextOfKinFormProps> = ({
  formKey,
  nextOfKin,
}) => {
  const { viewedCall } = useCall();
  const { errors, submitCount } = useFormikContext();
  const [nextOfKinExpanded, setNextOfKinExpanded] = useState(false);

  const disableFields = useMemo(() => {
    if (
      viewedCall?.updatedServiceCase?.caseStatus !==
      ServiceCaseStatusEnum.GATHERING_REQUIREMENTS
    ) {
      return {
        firstName: true,
        lastName: true,
        phone: true,
        email: true,
        relationshipToPatient: true,
        otherRelationToPatient: true,
      };
    }

    return undefined;
  }, [viewedCall?.updatedServiceCase?.caseStatus]);

  const hasErrors = getIn(errors, `${formKey}.nextOfKin`);

  useEffect(() => {
    if (hasErrors) {
      setNextOfKinExpanded(true);
    }
  }, [hasErrors, submitCount]);

  return (
    <StyledAccordion
      summary="Next of kin"
      expanded={nextOfKinExpanded}
      onChange={() => setNextOfKinExpanded(!nextOfKinExpanded)}
    >
      <NextOfKinForm
        formKey={formKey}
        nextOfKin={nextOfKin}
        disabledFields={disableFields}
      />
    </StyledAccordion>
  );
};
