import axios from "axios";
import { LocationInfo } from "@deep-consulting-solutions/be2-constants";

import { getENText } from "helpers";
import { notifications } from "services";
import { CustomGeoCodeResult } from "call/types";

export const getLocationInfo = async () => {
  try {
    const res = await axios.get<{
      IPv4: string;
      country_name: string;
      latitude: number;
      longitude: number;
    }>("https://geolocation-db.com/json/");

    const { IPv4, country_name: country, latitude, longitude } = res.data;
    const info: LocationInfo = {
      ip: IPv4,
      country,
      latitude: `${latitude}`,
      longitude: `${longitude}`,
    };
    return info;
  } catch {
    const message = getENText("location.error");
    notifications.notifyError(message);
    throw new Error(message);
  }
};

export const ddToDMS = (lat: number, lng: number) => {
  const latDeg = Math.floor(lat);
  const latMin = Math.floor((lat - latDeg) * 60);
  const latSec = Math.floor(((lat - latDeg) * 60 - latMin) * 60);
  const lngDeg = Math.floor(lng);
  const lngMin = Math.floor((lng - lngDeg) * 60);
  const lngSec = Math.floor(((lng - lngDeg) * 60 - lngMin) * 60);
  return `${latDeg}° ${latMin}' ${latSec}" N, ${lngDeg}° ${lngMin}' ${lngSec}" W`;
};

const getValue = (pos: string) => {
  try {
    const [deg, min, sec] = pos.trim().split(" ");
    const position =
      parseFloat(deg) + parseFloat(min) / 60 + parseFloat(sec) / 3600;

    return parseFloat(position.toFixed(7));
  } catch {
    return NaN;
  }
};

export const DMStoDD = (dms: string) => {
  const [lat, lng] = dms.split(",");
  return [getValue(lat), getValue(lng)];
};

export function getShortAddressObject(
  addressComponents: google.maps.GeocoderAddressComponent[]
) {
  const address: { [key: string]: string } = {};
  addressComponents.forEach((element) => {
    address[element.types[0]] = element.short_name;
  });
  return address;
}

export function getLongAddressObject(
  addressComponents: google.maps.GeocoderAddressComponent[]
) {
  const address: { [key: string]: string } = {};
  addressComponents.forEach((element) => {
    address[element.types[0]] = element.long_name;
    if (element.types.includes("sublocality")) {
      address.sublocality = element.long_name;
    }
  });
  return address;
}

export const geoLocateMe = () => {
  return new Promise<GeolocationPosition>((resolve, reject) => {
    if ("geolocation" in navigator) {
      return navigator.geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: true,
        timeout: 10000,
      });
    }
    return reject(new Error("UNSUPPORTED"));
  });
};

export type PositionErrorType =
  | "UNSUPPORTED"
  | "PERMISSION_DENIED"
  | "POSITION_UNAVAILABLE"
  | "TIMEOUT";

type ReturnValue = Promise<
  | {
      position: GeolocationPosition;
      error?: undefined;
    }
  | {
      error: {
        code: PositionErrorType;
      };
      position?: undefined;
    }
>;

export const getLocation = async (tries = 0): Promise<ReturnValue> => {
  try {
    const position = await geoLocateMe();
    return { position };
  } catch (error: any) {
    if (tries < 3) {
      return getLocation(tries + 1);
    }
    let code: PositionErrorType = "UNSUPPORTED";
    if (error.code === 1) {
      code = "PERMISSION_DENIED";
    } else if (error.code === 2) {
      code = "POSITION_UNAVAILABLE";
    } else if (error.code === 3) {
      code = "TIMEOUT";
    }
    return {
      error: { code },
    };
  }
};

export const getLocationFromGeoCodeResult = (
  result?: CustomGeoCodeResult | google.maps.GeocoderResult
) => {
  try {
    if (!result) throw new Error();

    let latLng: google.maps.LatLngLiteral | undefined;
    if ("toJSON" in result.geometry.location) {
      latLng = result.geometry.location.toJSON();
    } else {
      latLng = result.geometry.location;
    }
    const longAddress = getLongAddressObject(result.address_components);
    const lat = parseFloat(latLng.lat.toFixed(7));
    const lng = parseFloat(latLng.lng.toFixed(7));
    const dms = ddToDMS(lat, lng);

    const street = `${longAddress.street_number || ""}${
      longAddress.route
        ? `${longAddress.street_number ? " " : ""}${longAddress.route}`
        : ""
    }`;

    const address =
      longAddress.sublocality || longAddress.locality || "Unnamed road";

    return {
      lat,
      lng,
      dms,
      plusCode: result.plus_code?.compound_code,
      address: `${street}${street && address ? `, ${address}` : ""}` || address,
    };
  } catch {
    return {
      lat: 0,
      lng: 0,
      dms: "",
      address: "",
      plusCode: "",
    };
  }
};

export const getLocationFromPlacesResult = (
  place: google.maps.places.PlaceResult
) => {
  const latLng = place.geometry!.location!.toJSON();

  const longAddress = getLongAddressObject(place.address_components!);
  const lat = parseFloat(latLng.lat.toFixed(7));
  const lng = parseFloat(latLng.lng.toFixed(7));
  const dms = ddToDMS(lat, lng);

  const street = `${longAddress.street_number || ""}${
    longAddress.route
      ? `${longAddress.street_number ? " " : ""}${longAddress.route}`
      : ""
  }`;

  const address =
    street || longAddress.sublocality || longAddress.locality || "";

  return {
    lat,
    lng,
    dms,
    plusCode: place.plus_code?.compound_code,
    address: `${place.name || ""}${
      place.name && address ? `, ${address}` : ""
    }`,
  };
};

export const getLocationItems = (
  locationString?: string
): {
  lat: number;
  lng: number;
  dms: string;
} => {
  if (locationString?.includes("N")) {
    // locationString is a dms string;

    const dd = DMStoDD(locationString);
    return getLocationItems(`${dd[0]}, ${dd[1]}`);
  }

  let dms = "";

  const [latString = "0", lngString = "0"] = (locationString || "").split(",");
  const lat = Number(latString);
  const lng = Number(lngString);

  if (lat && lng) {
    dms = ddToDMS(lat, lng);
  }

  return { lat, lng, dms };
};
