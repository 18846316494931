import React from "react";
import { useStyles } from "./style";
import { Icon } from "./types";

export const FourSide = ({ onClick }: Icon) => {
  const classes = useStyles();
  return (
    <svg
      width="35"
      height="29"
      viewBox="0 0 35 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={classes.icon}
    >
      <path
        d="M1 5C1 2.79086 2.79086 1 5 1H30C32.2091 1 34 2.79086 34 5V24C34 26.2091 32.2091 28 30 28H5C2.79086 28 1 26.2091 1 24V5Z"
        fill="#F7F7F7"
      />
      <rect x="7" y="7" width="8" height="15" rx="1" stroke="#828282" />
      <rect x="18" y="7" width="3.5" height="6.5" rx="1" stroke="#828282" />
      <rect x="18" y="15.5" width="3.5" height="6.5" rx="1" stroke="#828282" />
      <rect x="24.5" y="7" width="3.5" height="15" rx="1" stroke="#828282" />
      <path
        d="M5 1.5H30V0.5H5V1.5ZM33.5 5V24H34.5V5H33.5ZM30 27.5H5V28.5H30V27.5ZM1.5 24V5H0.5V24H1.5ZM5 27.5C3.067 27.5 1.5 25.933 1.5 24H0.5C0.5 26.4853 2.51472 28.5 5 28.5V27.5ZM33.5 24C33.5 25.933 31.933 27.5 30 27.5V28.5C32.4853 28.5 34.5 26.4853 34.5 24H33.5ZM30 1.5C31.933 1.5 33.5 3.067 33.5 5H34.5C34.5 2.51472 32.4853 0.5 30 0.5V1.5ZM5 0.5C2.51472 0.5 0.5 2.51472 0.5 5H1.5C1.5 3.067 3.067 1.5 5 1.5V0.5Z"
        fill="#E0E0E0"
      />
    </svg>
  );
};
