import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useCallback } from "react";
import { useCall } from "../../hooks";

const useStyles = makeStyles(({ spacing: s }) => ({
  title: {},
  content: {},
  actions: {},
  wrapper: {
    padding: s(4),
  },
  true: {
    background: "rgba(202, 32, 39, 1)",
    color: "white",
    "&:hover": {
      background: "rgba(202, 32, 39, 0.8)",
    },
  },
}));

export const EndCallDialog = () => {
  const {
    showEndCallRequestDialog,
    cancelEndCallRequest,
    completeEndCallRequest,
  } = useCall();

  const classes = useStyles();

  const handleCompleteEndCallRequest = useCallback(() => {
    completeEndCallRequest();
  }, [completeEndCallRequest]);

  return (
    <Dialog open={showEndCallRequestDialog} className={classes.wrapper}>
      <DialogTitle className={classes.title} disableTypography>
        <Typography align="center" color="primary" variant="h4">
          Are you sure you want to end the call?
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant="body1">
          This action will end the current call. Please notify the caller before
          proceeding.
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
        >
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              onClick={cancelEndCallRequest}
            >
              No
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.true}
              color="primary"
              onClick={handleCompleteEndCallRequest}
            >
              True
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
