import { makeStyles, Tooltip, Typography, IconButton } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useCall } from "call/hooks";
import { takeScreenshot } from "helpers/screenshot";
import OpenTok from "@opentok/client";
import { Call } from "call/types";
import clsx from "clsx";
import { Screenshot } from "./icons/Screenshot";
// import { Video } from "./icons/Video";
// import { VideoOff } from "./icons/VideoOff";
import { Volume } from "./icons/Volume";
import { VolumeOff } from "./icons/VolumeOff";

interface StyleProps {
  isViewedCall: boolean;
}

const useStyles = makeStyles(({ spacing: s }) => ({
  wrapper: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    visibility: ({ isViewedCall }: StyleProps) =>
      isViewedCall ? "visible" : "hidden",
  },
  main: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  mainVideo: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    background: "black",
  },
  user: {
    height: 112,
    objectFit: "cover",
    border: "1px solid rgba(255, 255, 255, 0.54)",
    borderRadius: 8,
    marginBottom: s(2),
    overflow: "hidden",
    width: "100%",
  },
  actions: {
    background: "rgba(0, 0, 0, 0.64)",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 8,
    padding: s(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexFlow: "row nowrap",
  },
  actionArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
    flexFlow: "column nowrap",
    position: "absolute",
    left: 0,
    bottom: 0,
    margin: s(2),
    // width: 100,
    zIndex: 99,
  },
  iconButton: {
    padding: 0,
    cursor: "pointer",
  },
  screenshotTooltip: {
    marginRight: s(2),
  },
}));

interface Props {
  call: Call;
}

export const SingleCallVideo = ({ call }: Props) => {
  const [isVideoOff, setIsVideoOff] = useState(false);
  const { viewedCall, openTokSessions, mutedMicCalls, handleMuteCall } =
    useCall();
  const [incomingStream, setIncomingStream] = useState<OpenTok.Stream | null>(
    null
  );

  const isMicMuted = useMemo(
    () => !!mutedMicCalls[call?.sessionId],
    [mutedMicCalls, call?.sessionId]
  );

  const handleVideo = useCallback(() => {
    setIsVideoOff((m) => {
      return !m;
    });
  }, []);

  const handleMuteMic = useCallback(() => {
    handleMuteCall(call.sessionId);
  }, [handleMuteCall, call.sessionId]);

  const { openTokPublisher, openTokSession } = useMemo(
    () => ({
      openTokPublisher: call?.sessionId
        ? openTokSessions[call?.sessionId]?.publisher
        : undefined,
      openTokSession: call?.sessionId
        ? openTokSessions[call?.sessionId]?.session
        : undefined,
    }),
    [openTokSessions, call?.sessionId]
  );

  const streamPublisherElementId = useMemo(
    () => `stream-publisher-${call?.sessionId}`,
    [call?.sessionId]
  );

  const handleScreenshot = useCallback(() => {
    const base64Data = openTokPublisher?.getImgData();
    if (base64Data) {
      takeScreenshot(
        base64Data,
        `${call?.updatedServiceCase?.caseNumber}-${new Date().getTime()}.jpg`
      );
    }
  }, [openTokPublisher, call?.updatedServiceCase?.caseNumber]);

  useEffect(() => {
    openTokPublisher?.publishAudio(!isMicMuted);
  }, [openTokPublisher, isMicMuted]);

  useEffect(() => {
    openTokPublisher?.publishVideo(!isVideoOff);
  }, [openTokPublisher, isVideoOff]);

  useEffect(() => {
    if (
      openTokSession &&
      openTokPublisher &&
      openTokSession.capabilities.publish === 1
    ) {
      setIncomingStream((current) => {
        if (current) {
          return current;
        }
        const streams: OpenTok.Stream[] = openTokSession.streams
          ? openTokSession.streams.map((s) => s)
          : [];

        return streams[0] || null;
      });

      openTokSession.on({
        streamCreated: ({ stream }: { stream: OpenTok.Stream }) => {
          setIncomingStream(stream);
        },
        streamDestroyed: () => {
          setIncomingStream(null);
        },
      });
      openTokSession.publish(openTokPublisher, (err) => {
        if (!err) {
          handleVideo();
        }
      });
    }

    return () => {
      if (openTokSession && openTokPublisher) {
        openTokPublisher.publishVideo(false);
        openTokSession.unpublish(openTokPublisher);
        openTokPublisher.destroy();
        openTokSession.disconnect();
      }
    };
  }, [openTokSession, openTokPublisher, handleMuteMic, handleVideo]);

  useEffect(() => {
    let streamSubscription: OpenTok.Subscriber;
    if (incomingStream && openTokSession) {
      streamSubscription = openTokSession.subscribe(
        incomingStream,
        streamPublisherElementId,
        {
          insertMode: "append",
          width: "100%",
          height: "100%",
        },
        () => {
          //
        }
      );
    }

    return () => {
      if (streamSubscription && openTokSession) {
        openTokSession.unsubscribe(streamSubscription);
      }
    };
  }, [streamPublisherElementId, openTokSession, incomingStream]);

  const isViewedCall = useMemo(
    () =>
      !!viewedCall?.sessionId &&
      !!call.sessionId &&
      viewedCall.sessionId === call.sessionId,
    [viewedCall?.sessionId, call.sessionId]
  );

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      if (!isViewedCall) {
        setIsVideoOff(true);
        handleMuteCall(call.sessionId, true);
      }
    }, 1000);

    return () => {
      clearTimeout(timeoutID);
    };
  }, [isViewedCall, handleMuteCall, call.sessionId]);

  const classes = useStyles({ isViewedCall });

  return (
    <div className={clsx(classes.wrapper)}>
      <div className={classes.main}>
        <div className={classes.mainVideo} id={streamPublisherElementId}>
          {" "}
        </div>
      </div>
      <div className={classes.actionArea}>
        <div
          className={classes.user}
          style={{
            display: "none",
          }}
        >
          <div
            className={classes.mainVideo}
            id={`publisher-${call?.sessionId}`}
          >
            {" "}
          </div>
        </div>
        <div className={classes.actions}>
          <Tooltip
            title={<Typography variant="caption">Take Screenshot</Typography>}
            className={classes.screenshotTooltip}
          >
            <div>
              <IconButton
                className={classes.iconButton}
                onClick={handleScreenshot}
              >
                <Screenshot />
              </IconButton>
            </div>
          </Tooltip>
          {/* <Tooltip
            title={
              <Typography variant="caption">
                {!isVideoOff ? "Stop" : "Start"} Video
              </Typography>
            }
          >
            <div>
              <IconButton className={classes.iconButton} onClick={handleVideo}>
                {!isVideoOff ? <Video /> : <VideoOff />}
              </IconButton>
            </div>
          </Tooltip> */}
          <Tooltip
            title={
              <Typography variant="caption">
                {!isMicMuted ? "Mute" : "Unmute"} Speaker
              </Typography>
            }
          >
            <div>
              <IconButton
                className={classes.iconButton}
                onClick={handleMuteMic}
              >
                {!isMicMuted ? <Volume /> : <VolumeOff />}
              </IconButton>
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
