import React from "react";
import { ServiceCaseStatusEnum } from "@deep-consulting-solutions/be2-constants";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import clsx from "clsx";
import { getSnippetColor } from "../helpers";

const useStyles = makeStyles<Theme, { status: ServiceCaseStatusEnum }>(() => ({
  tag: {
    padding: "4px 14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: ({ status }) => getSnippetColor(status).tag,
    color: ({ status }) => getSnippetColor(status).text,
    borderRadius: 20,
    width: "fit-content",
    fontSize: 10,
  },
}));
export const StatusTag = ({ status }: { status: ServiceCaseStatusEnum }) => {
  const styles = useStyles({ status });

  return status === ServiceCaseStatusEnum.PENDING_PLANNING ? (
    <>
      <Typography variant="caption" style={{ fontWeight: 700 }}>
        {status}
      </Typography>{" "}
      <div className={styles.tag}>
        <Typography style={{ fontSize: "inherit", color: "inherit" }}>
          Quote Pending Approval
        </Typography>
      </div>
    </>
  ) : (
    <div
      className={clsx(
        styles.tag,
        !status.toLowerCase().includes("pending") && { paddingLeft: 0 }
      )}
    >
      <Typography
        style={{
          fontSize: "inherit",
          color: "inherit",
          fontWeight: !status.toLowerCase().includes("pending") ? 400 : 700,
        }}
      >
        {status}
      </Typography>
    </div>
  );
};
