import { getClient } from "apis";
import {
  SortOrderEnum,
  GetFetchServiceCases,
  ServiceCaseStatusEnum,
  GetAuthenticatedMedicalDirector,
} from "@deep-consulting-solutions/be2-constants";

export const getPendingMedicalAssessment = (
  params: Partial<typeof GetFetchServiceCases.Params> = {}
) => {
  return getClient(true).get<typeof GetFetchServiceCases["Res"]>(
    GetFetchServiceCases.ROUTE,
    {
      params: {
        perPage: 20,
        searchText: "",
        sortBy: "createdAt",
        order: SortOrderEnum.DESC,
        status: ServiceCaseStatusEnum.PENDING_MEDICAL_ASSESSMENT,
        ...params,
      } as typeof GetFetchServiceCases.Params,
    }
  );
};

export const getSignedInMedicalDirector = async () => {
  const res = await getClient(true).get<
    typeof GetAuthenticatedMedicalDirector.Res
  >(GetAuthenticatedMedicalDirector.ROUTE);

  return res.data.data;
};
