import React from "react";
import { makeStyles, Link } from "@material-ui/core";
import { StyledTypography } from "component/StyledTypography";

const useStyles = makeStyles(({ spacing: s }) => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: s(2, 0),
  },
  rule: {
    margin: s(0, 1),
  },
  link: {
    textDecoration: "underline",
    fontWeight: 500,
  },
}));

export const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <StyledTypography variant="body2" color="grey.400">
        Powered by PNP
      </StyledTypography>

      <StyledTypography
        variant="body2"
        color="primary.dark"
        className={classes.rule}
      >
        |
      </StyledTypography>

      <Link
        href="/#"
        color="primary"
        variant="caption"
        className={classes.link}
      >
        Terms and Conditions
      </Link>
    </div>
  );
};
