import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  Box,
  Button,
  Collapse,
  Divider,
  makeStyles,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  UserRoleEnum,
  ServiceCaseResponse,
  ServiceCaseStatusEnum,
} from "@deep-consulting-solutions/be2-constants";
import { ReactComponent as LocationIcon } from "pages/FulfilmentDashboard/Views/MedicalDirector/locationIcon.svg";
import { formatNameToDisplay, getLocationItems } from "helpers";
import { format } from "date-fns";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Action } from "pages/FulfilmentDashboard/types";
import clsx from "clsx";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { renderServiceCaseActions } from "pages/FulfilmentDashboard/helpers";
import { useZohoCurrentUser } from "hooks";
import { Popover } from "pages/FulfilmentDashboard/components/Popover";
import { CSSTransition } from "react-transition-group";
import { useAppSelector } from "redux/store";
import { dispatcherSelectors } from "redux/dispatcher";
import "./PatientTableCellRecord.css";
import { omit } from "lodash";
import { useMap } from "call";
import { StatusSnippetTag } from "./StatusSnippetTag";
import { useInfoWindow } from "./useInfoWindow";
import { getColors } from "./utils";

const useStyles = makeStyles(({ palette }) => ({
  alignHorizontal: {
    minWidth: 400,
    maxWidth: 450,
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 10,
  },
  details: {
    display: "flex",
    color: "#4F4F4F",
  },
  collapseBtn: {
    textTransform: "capitalize",
    fontSize: 10,
    color: "#4f4f4f",
    "& :hover": {
      color: palette.primary.main,
    },
  },
  collapsed: {
    position: "relative",
    transition: "all 0.5s",
    fontSize: 10,
    marginLeft: 10,
  },
  unCollapsed: {
    top: -4,
    transform: "rotate(-90deg)",
  },
  moreBtn: {
    background: "none",
    border: "none",
    padding: 0,
    position: "relative",
    height: "min-content",
    cursor: "pointer",
    paddingLeft: 5,
  },
  more: {
    color: "#828282",
    fontSize: 19,
  },
  dropdown: {
    backgroundColor: "white",
    borderRadius: 4,
  },
  dropdownBtn: {
    all: "unset",
    cursor: "pointer",
    padding: 8,
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    minWidth: 120,
  },
  spacer: {
    height: 19,
  },
  snippet: {
    padding: "5px 8px",
    borderRadius: 4,
    border: "1px solid #d9e4ea",
    marginBottom: 4,
    textTransform: "capitalize",
  },
  nextOfKin: {
    padding: "0px 5px",
    color: "#4F4F4F",
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridGap: 8,
  },
}));

const MoreOptions = ({
  onMore,
  caseStatus,
}: {
  caseStatus: ServiceCaseResponse["caseStatus"];
  onMore: (action: Action) => void;
}) => {
  const { currentUser } = useZohoCurrentUser();
  const { isDispatcher, isMedicalDirector } = useAppSelector(
    dispatcherSelectors.roles
  );

  const classes = useStyles({});

  const handleClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
  };

  const onActionSelect = (action: Action) => {
    onMore(action);
  };

  const renderServiceCaseActionsMem = useMemo(
    () =>
      renderServiceCaseActions(
        currentUser?.profile?.name as UserRoleEnum,
        caseStatus,
        isMedicalDirector,
        isDispatcher
      ),
    [caseStatus, isDispatcher, isMedicalDirector, currentUser?.profile?.name]
  );

  return (
    <div style={{ position: "relative" }}>
      {!!renderServiceCaseActionsMem.length && (
        <Popover
          buttonClassName={classes.moreBtn}
          handleClick={handleClick}
          content={({ handleClose }) => (
            <div className={classes.dropdown}>
              {renderServiceCaseActionsMem.map((el, index, arr) => (
                <React.Fragment key={`${index.toString()}`}>
                  <Tooltip
                    title={
                      Action.Crm === el
                        ? "To view case details and attach documents"
                        : ""
                    }
                  >
                    <button
                      type="button"
                      className={classes.dropdownBtn}
                      onClick={(e) => {
                        e.stopPropagation();
                        onActionSelect(el);
                        handleClose(e);
                      }}
                    >
                      <Typography variant="caption" color="primary">
                        {el}
                      </Typography>
                    </button>
                  </Tooltip>
                  {arr.length - 1 !== index && <Divider />}
                </React.Fragment>
              ))}
            </div>
          )}
          button={<MoreVertIcon className={classes.more} />}
        />
      )}
    </div>
  );
};

interface Props extends ServiceCaseResponse {
  isNew?: boolean;
  onMore: (a: Action, d: ServiceCaseResponse) => void;
  setUnansweredCase: (sc: ServiceCaseResponse) => void;
}

export const PatientTableCellRecord = (props: Props) => {
  const {
    isNew,
    onMore,
    service,
    overdue,
    patients,
    requester,
    updatedAt,
    createdAt,
    caseStatus,
    caseNumber,
    dispatcher,
    requestType,
    initialLocation,
    setUnansweredCase,
  } = props;

  const [showPatient, setShowPatient] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const { currentUser } = useZohoCurrentUser();
  const { isMedicalDirector } = useAppSelector(dispatcherSelectors.roles);

  const classes = useStyles();
  const ref = useRef<string | null>(null);

  const initChange = () => {
    setHasChange(true);

    setTimeout(() => {
      setHasChange(false);
    }, 1000);
  };

  useEffect(() => {
    if (isNew) {
      initChange();
    }
  }, [isNew]);

  useEffect(() => {
    if (ref.current && ref.current !== updatedAt) {
      initChange();
    }

    ref.current = updatedAt;
  }, [updatedAt]);

  const colors = useMemo(
    () =>
      getColors({
        hasChange,
        caseStatus,
        isMedicalDirector,
        role: currentUser?.profile.name,
      }),
    [hasChange, caseStatus, isMedicalDirector, currentUser?.profile.name]
  );

  useInfoWindow({ serviceCase: omit(props, "onMore") });

  const { map } = useMap();

  const handleLocationsClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();

      if (map && initialLocation) {
        const { lat, lng } = getLocationItems(initialLocation);

        if (lat && lng) {
          map.setZoom(12);
          map.setCenter({ lat, lng });
        }
      }
    },
    [map, initialLocation]
  );

  const serviceCase = useMemo(
    () => omit(props, "onMore", "setUnansweredCase"),
    [props]
  );

  const handleCardClick = () => {
    if (caseStatus === ServiceCaseStatusEnum.UNANSWERED) {
      setUnansweredCase(serviceCase);
    } else {
      onMore(Action.Edit, serviceCase);
    }
  };

  return (
    <>
      <CSSTransition
        timeout={1000}
        in={hasChange}
        classNames="row"
        addEndListener={(node, done) => {
          node.addEventListener("transitionend", done, false);
        }}
      >
        <TableRow
          className="patientTableCellRecord"
          style={{
            cursor: "pointer",
            background: !hasChange ? colors.bg : "",
          }}
          onClick={handleCardClick}
        >
          <TableCell align="left" component="th" scope="row">
            <Box>
              <Box display="flex" alignItems="center" whiteSpace="nowrap">
                <Typography color="primary" variant="caption">
                  {caseNumber} -{" "}
                  <span style={{ textTransform: "capitalize" }}>
                    {requestType}
                  </span>
                </Typography>
                <Typography
                  color="primary"
                  style={{ fontSize: 8, marginLeft: 16 }}
                >
                  Dispatcher:{" "}
                  <b style={{ textTransform: "capitalize", color: "black" }}>
                    {formatNameToDisplay(
                      dispatcher?.firstName,
                      dispatcher?.lastName
                    ) || "--"}
                  </b>
                </Typography>
              </Box>

              {colors.bg ? (
                <StatusSnippetTag status={caseStatus} colors={colors} />
              ) : (
                <Typography component="span" variant="caption">
                  <b>{caseStatus}</b>{" "}
                </Typography>
              )}

              {overdue && (
                <Typography component="span" variant="caption">
                  <span style={{ fontSize: "8px" }}>
                    <span style={{ color: "#CA2027" }}>Overdue:</span>
                    <span style={{ color: "#263E7F", marginLeft: 3 }}>
                      {overdue}
                    </span>
                  </span>
                </Typography>
              )}
            </Box>
          </TableCell>
          <TableCell align="left">
            <div className={classes.spacer} />
            <Typography variant="caption">{service ?? "--"}</Typography>
          </TableCell>

          <TableCell align="right" onClick={handleLocationsClick}>
            <div className={classes.spacer} />
            <Typography
              style={{
                fontSize: 10,
                display: "flex",
                alignItems: "center",
                gap: 8,
              }}
            >
              Locations
              <Tooltip placement="top-start" arrow title="View Destinations">
                <LocationIcon />
              </Tooltip>
            </Typography>
          </TableCell>

          <TableCell align="left">
            <div className={classes.spacer} />
            <Typography
              variant="caption"
              style={{ textTransform: "capitalize" }}
            >
              <span style={{ fontSize: 10 }}>Requester: </span>
              <b>
                {formatNameToDisplay(requester?.firstName, requester?.lastName)}
              </b>
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Box
              display="flex"
              alignItems="center"
              whiteSpace="nowrap"
              maxHeight="19px"
              justifyContent="flex-end"
            >
              <Typography style={{ fontSize: 8, color: "#4F4F4F" }}>
                {updatedAt
                  ? format(new Date(updatedAt ?? createdAt), "d LLL yyyy p")
                  : "--"}
              </Typography>
              <MoreOptions
                caseStatus={caseStatus}
                onMore={(a) => onMore(a, props)}
              />
            </Box>
            <Box>
              <Button
                size="small"
                variant="text"
                className={classes.collapseBtn}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowPatient(!showPatient);
                }}
              >
                {`Patient${patients && patients.length > 1 ? "s" : ""}`}
                <ArrowBackIosIcon
                  className={clsx(
                    classes.collapsed,
                    showPatient && classes.unCollapsed
                  )}
                  style={{ fontSize: 10, color: "#263E7F" }}
                />
              </Button>
            </Box>
          </TableCell>
        </TableRow>
      </CSSTransition>

      <CSSTransition
        timeout={1000}
        in={hasChange}
        classNames="row"
        addEndListener={(node, done) => {
          node.addEventListener("transitionend", done, false);
        }}
      >
        {patients && (
          <TableRow
            className="patientTableCellRecord"
            style={{
              background: !hasChange ? colors.bg : "",
            }}
          >
            <TableCell
              style={{
                paddingBottom: showPatient ? 14 : 0,
                paddingTop: showPatient ? 14 : 0,
                borderTop: showPatient ? "1px solid #e0e0e0" : "none",
              }}
              colSpan={6}
            >
              <Collapse
                in={showPatient}
                style={{
                  overflow: "auto",
                  maxHeight: 240,
                }}
              >
                {patients?.length ? (
                  <div>
                    {patients.map(
                      ({
                        firstName,
                        lastName,
                        nextOfKin,
                        selectedSubsCoverage,
                      }) => (
                        <Box className={classes.snippet}>
                          <Typography style={{ fontSize: 10 }} color="primary">
                            {formatNameToDisplay(firstName, lastName)}
                          </Typography>

                          <Box className={classes.nextOfKin}>
                            <Typography style={{ fontSize: 8 }}>
                              Next of Kin:{" "}
                              <b>
                                {formatNameToDisplay(
                                  nextOfKin?.firstName,
                                  nextOfKin?.lastName
                                ) || "--"}
                              </b>
                            </Typography>

                            <Typography style={{ fontSize: 8 }} component="p">
                              Coverage:{" "}
                              <Typography
                                component="span"
                                variant="subtitle2"
                                style={{ fontSize: 10 }}
                              >
                                {selectedSubsCoverage?.coverage || <b>--</b>}
                              </Typography>
                            </Typography>
                          </Box>
                        </Box>
                      )
                    )}
                  </div>
                ) : (
                  <Typography
                    variant="caption"
                    style={{
                      fontSize: 8,
                      color: "#828282",
                      display: "block",
                      textAlign: "center",
                    }}
                  >
                    Patient(s) information not gathered from the requester yet.
                  </Typography>
                )}
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </CSSTransition>
    </>
  );
};
