/* eslint-disable @typescript-eslint/no-unsafe-call */
import { ZohoEntity, ZohoRecord, ZohoCurrentUser } from "./types";

export const ZOHO_SCRIPT_SRC = "/zoho.min.js";

declare const ZOHO: any;

export class ZohoServices {
  public static injectScript = () => {
    const defaultError = new Error("Cannot load ZOHO CRM script!");

    return new Promise<void>((resolve, reject) => {
      const script = document.createElement("script");
      script.async = true;
      script.src = ZOHO_SCRIPT_SRC;
      document.body.appendChild(script);

      script.addEventListener("load", () => {
        if (Object.prototype.hasOwnProperty.call(window, "ZOHO")) {
          resolve();
        } else {
          reject(defaultError);
        }
      });
      script.addEventListener("error", () => {
        reject(defaultError);
      });
    });
  };

  public static init = () => {
    return new Promise<ZohoEntity>((resolve, reject) => {
      let isOK = false;

      ZOHO.embeddedApp.on("PageLoad", (data: ZohoEntity) => {
        isOK = true;
        resolve(data);
      });
      ZOHO.embeddedApp.init();

      setTimeout(() => {
        if (!isOK) reject();
      }, 3000);
    });
  };

  public static generateAuthToken = async () => {
    try {
      const { users } = await ZohoServices.getCurrentUser();

      const result = await ZOHO.CRM.FUNCTIONS.execute("generateCrmToken", {
        userID: users[0].id,
      });

      if (result.code !== "success") {
        return null;
      }

      return {
        token: result.details.output as string,
        currentUser: users[0],
      };
    } catch (error) {
      return null;
    }
  };

  // min-width is always 700px and can't be changed.
  public static resizeWidget = async ({
    height,
    width = 700,
  }: {
    height: number;
    width?: number;
  }) => {
    await ZOHO.CRM.UI.Resize({ height, width });
  };

  public static getZohoRecords = async (
    Entity: string,
    RecordID: string | string[]
  ) => {
    const res = await ZOHO.CRM.API.getRecord({
      Entity,
      RecordID,
    });
    return res.data as ZohoRecord[];
  };

  public static getCurrentUser = async () => {
    const data = (await ZOHO.CRM.CONFIG.getCurrentUser()) as {
      users: ZohoCurrentUser[];
    };
    return data;
  };

  public static closePopup = async (reload?: boolean) => {
    if (reload) {
      await ZOHO.CRM.UI.Popup.closeReload();
    } else {
      await ZOHO.CRM.UI.Popup.close();
    }
  };
}
