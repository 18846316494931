import { ErrorOutline } from "@material-ui/icons";
import { Switch, useLocation } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import { Loader as APILoader } from "@googlemaps/js-api-loader";
import { CssBaseline, Typography, makeStyles } from "@material-ui/core";

import {
  AttentionCheck,
  UnansweredRequest,
  LocationCollector,
  FulfilmentDashboard,
} from "pages";
import Loader from "component/Loader";
import { ROUTES } from "component/configs";
import { useAppDispatch, useAppSelector } from "redux/store";
import { ZohoRoute } from "component/ZohoRoute";
import { FulfilmentDashboardLayout } from "layout";
import { dispatcherActions, dispatcherSelectors } from "redux/dispatcher";
import { environmentVariables } from "configs/environmentVariables";
import { CaseClassifications } from "pages/FulfilmentDashboard/Views/CaseClassifications";
import CaseClassificationsWidget from "pages/FulfilmentDashboard/Views/CaseClassificationsWidget";

import MediaOnboardingDialog from "component/Dialogs/audioVideoSettings/MediaDeviceOnboarding";
import { isFulfilmentWebtabPath, detectBrowser } from "./helpers";

const useStyles = makeStyles(({ palette: p }) => {
  return {
    errorWrapper: {
      marginTop: "100px",
      display: "flex",
      justifyContent: "center",
    },
    errorInner: {
      width: "600px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    errorMessage: {
      fontSize: 32,
      color: p.error.main,
      textAlign: "center",
    },
    errorIcon: {
      height: 300,
      width: 300,
      color: p.error.main,
    },
  };
});

export const FulfilmentWebtab = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [isMapsLoaded, setIsMapsLoaded] = useState(false);
  const { browserName, isChrome } = useMemo(() => detectBrowser(), []);
  const { isDispatcher } = useAppSelector(dispatcherSelectors.roles);
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      try {
        const apiLoader = new APILoader({
          apiKey: environmentVariables.REACT_APP_GOOGLE_MAP_API_KEY,
          libraries: ["places"],
        });
        await apiLoader.load();
        setIsMapsLoaded(true);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(dispatcherActions.getRoles());
  }, [dispatch]);

  if (!isFulfilmentWebtabPath(location.pathname)) return null;

  if (!isChrome) {
    return (
      <div className={classes.errorWrapper}>
        <div className={classes.errorInner}>
          <Typography className={classes.errorMessage}>
            You are using {browserName} browser. Some application functionality
            may not work. Please, use Chrome to have a fully functional app.
          </Typography>
          <ErrorOutline className={classes.errorIcon} />
        </div>
      </div>
    );
  }

  if (!isMapsLoaded) return <Loader open absolute />;

  return (
    <>
      <CssBaseline />
      <MediaOnboardingDialog />
      <FulfilmentDashboardLayout>
        {isDispatcher && <AttentionCheck />}
        <Switch>
          <ZohoRoute
            path={ROUTES.fulfilment.path}
            component={FulfilmentDashboard}
            exact
          />
          <ZohoRoute
            path={ROUTES.fulfilmentUnAnsweredRequest.path}
            component={UnansweredRequest}
            exact
          />
          <ZohoRoute
            path={ROUTES.caseClassificationsWidget.path}
            component={CaseClassificationsWidget}
            exact
          />
          <ZohoRoute
            path={ROUTES.caseClassifications.path}
            component={CaseClassifications}
            exact
          />
          <ZohoRoute
            path={ROUTES.locationCollector.path}
            component={LocationCollector}
            exact
          />
        </Switch>
      </FulfilmentDashboardLayout>
    </>
  );
};
