import React, { ChangeEvent, useEffect, useMemo } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Box, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import {
  ServiceCaseRequestTypeEnum,
  ServiceCaseRequestOriginEnum,
  GetServiceCases,
} from "@deep-consulting-solutions/be2-constants";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    padding: 20,
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export const FilterMenu = ({
  onSave,
  onClearAll,
  filters,
  initFilters,
  onSetFilters,
}: {
  onSave: () => void;
  onClearAll: () => void;
  onSetFilters: (
    filters: Pick<typeof GetServiceCases.QueryParams, "origin" | "type">
  ) => void;
  filters: Pick<typeof GetServiceCases.QueryParams, "origin" | "type">;
  initFilters:
    | Pick<typeof GetServiceCases.QueryParams, "origin" | "type">
    | boolean;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleSave = () => {
    onSave();
    setAnchorEl(null);
  };

  const { type, origin } = useMemo(
    () => ({
      type: filters.type.split(","),
      origin: filters.origin.split(","),
    }),
    [filters.origin, filters.type]
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const pickFilter = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name, value } = e.target;
    if (!checked) {
      const newFilter = filters[name as "origin" | "type"]
        .split(",")
        .filter((el) => el && el !== value)
        .join();
      onSetFilters({ ...filters, [name]: newFilter });
    } else {
      onSetFilters({
        ...filters,
        [name]: filters[name as "origin" | "type"]
          .split(",")
          .filter((el) => el && el !== value)
          .concat(value)
          .join(),
      });
    }
  };

  useEffect(() => {
    if (anchorEl) {
      if (initFilters)
        onSetFilters(
          initFilters as Pick<
            typeof GetServiceCases.QueryParams,
            "origin" | "type"
          >
        );
    }
  }, [anchorEl, initFilters, onSetFilters]);

  return (
    <div>
      <Button
        variant="text"
        color="primary"
        onClick={handleClick}
        startIcon={<FilterListIcon />}
      >
        FILTER
      </Button>
      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Box display="flex" style={{ outline: "none" }}>
          <Box display="flex" flexDirection="column">
            <Typography style={{ fontSize: 20, marginBottom: 28 }}>
              Type
            </Typography>
            {Object.values(ServiceCaseRequestTypeEnum).map((el) => (
              <FormControlLabel
                key={el}
                label={el}
                control={
                  <Checkbox
                    checked={type.includes(el)}
                    color="primary"
                    name="type"
                    value={el}
                    onChange={pickFilter}
                  />
                }
              />
            ))}
          </Box>
          <Box display="flex" flexDirection="column" ml={5}>
            <Typography style={{ fontSize: 20, marginBottom: 28 }}>
              Request Origin
            </Typography>
            {Object.values(ServiceCaseRequestOriginEnum).map((el) => (
              <FormControlLabel
                key={el}
                label={el}
                control={
                  <Checkbox
                    checked={origin.includes(el)}
                    color="primary"
                    name="origin"
                    value={el}
                    onChange={pickFilter}
                  />
                }
              />
            ))}
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            variant="text"
            style={{ marginLeft: 10 }}
            onClick={onClearAll}
          >
            clear all
          </Button>
          <Button
            color="primary"
            style={{ marginLeft: 10 }}
            onClick={handleSave}
          >
            save
          </Button>
        </Box>
      </StyledMenu>
    </div>
  );
};
