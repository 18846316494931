import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { ArrowBack as BackIcon } from "@material-ui/icons";

const useStyles = makeStyles(({ spacing: s }) => ({
  icon: {
    marginRight: s(1),
  },
}));

interface Props {
  onClick: () => any;
  hideText?: true;
}

export const BackButton: React.FC<Props> = ({ onClick, hideText }) => {
  const classes = useStyles();

  return (
    <Button onClick={onClick} variant="text" color="primary">
      <BackIcon className={classes.icon} /> {!hideText && "Back"}
    </Button>
  );
};
