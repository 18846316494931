import { Divider, makeStyles } from "@material-ui/core";
import { useCall } from "call/hooks";
import React, { useMemo } from "react";
import { ServiceCaseResponse } from "@deep-consulting-solutions/be2-constants";
import { ServiceTitle } from "../components";
import { getIsServiceCaseApproved, getIsServiceCaseRefused } from "../helpers";
import { PatientMedicalInformation } from "./PatientMedicalInformation";

const useStyles = makeStyles(({ spacing: s }) => ({
  divider: {
    margin: s(2, 0),
  },
}));

export const PatientsMedicalInformation = () => {
  const { viewedCall } = useCall();

  const { patients } = useMemo(() => {
    return {
      patients: viewedCall?.updatedServiceCase
        ?.patients as ServiceCaseResponse["patients"],
    };
  }, [viewedCall?.updatedServiceCase?.patients]);

  const isServiceCaseAccepted = useMemo(() => {
    return getIsServiceCaseApproved(
      viewedCall?.updatedServiceCase?.approvalRequests
    );
  }, [viewedCall?.updatedServiceCase?.approvalRequests]);

  const isServiceCaseRefused = useMemo(
    () =>
      getIsServiceCaseRefused(viewedCall?.updatedServiceCase?.approvalRequests),
    [viewedCall?.updatedServiceCase?.approvalRequests]
  );

  const visible = useMemo(
    () => patients?.length && (isServiceCaseAccepted || isServiceCaseRefused),
    [patients?.length, isServiceCaseRefused, isServiceCaseAccepted]
  );
  const classes = useStyles();

  if (!visible) {
    return null;
  }
  return (
    <>
      <ServiceTitle title="Patients Medical Information" />

      {!!patients &&
        patients?.map((patient) => (
          <PatientMedicalInformation
            key={patient.contactID}
            patient={patient}
          />
        ))}
      <Divider className={classes.divider} />
    </>
  );
};
