export const addHostToZohoSignUrl = (url: string) => {
  if (!url) return "";
  return `${url}&frameorigin=${process.env.REACT_APP_DOMAIN}`;
};

export const openPageUrl = (url: string, id?: string) => {
  try {
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.target = "_blank";
    anchor.id = `open-page-url-${id || String(Math.random())}`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    return true;
  } catch {
    return false;
  }
};
