import React from "react";
import {
  Box,
  Table,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  makeStyles,
  Typography,
  TableContainer,
} from "@material-ui/core";

import { useCall } from "call/hooks";
import { differenceInYears } from "date-fns";
import { displayGender, formatNameToDisplay } from "helpers";
import { StyledTypography } from "component/StyledTypography";

import PlanItinerary from "./PlanItinerary";
import { TabsEnum } from "../types";

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  title: {
    fontWeight: 500,
    marginBottom: s(3),
    color: p.primary.main,
    fontFamily: "'Roboto', sans-serif",
  },
  wrapper: {
    padding: s(4.25, 0),
  },
  tableContainer: {
    marginBottom: s(2),
  },
  table: {
    border: "1px solid #ececec",
  },
  tableHead: {
    backgroundColor: "#ececec",

    "& .MuiTableCell-root": {
      color: p.primary.dark,
    },
  },
  inputGroup: {
    display: "flex",
    marginBottom: s(2),
    justifyContent: "space-between",

    "& > *": {
      width: "140px",
    },

    "& label": {
      color: p.primary.main,
    },

    "& .MuiOutlinedInput-root": {
      "& input": {
        color: p.primary.dark,
      },

      "& fieldset": {
        borderColor: p.primary.main,
      },
    },
  },
  updateWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

interface Props {
  activeTab: TabsEnum;
  switchTab: (value: TabsEnum) => void;
}

export const PlanningForm = ({ activeTab, switchTab }: Props) => {
  const classes = useStyles();

  const { viewedCall } = useCall();

  return (
    <div>
      <Box className={classes.wrapper}>
        <StyledTypography variant="body1" color="primary.main">
          Service to be Rendered:{" "}
          <b>{viewedCall?.updatedServiceCase?.service}</b>
        </StyledTypography>
      </Box>

      <Divider />

      <Box className={classes.wrapper}>
        <Typography variant="h5" className={classes.title}>
          Patient Details
        </Typography>

        <Box width="50%">
          <TableContainer>
            <Table aria-label="patients table" className={classes.table}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Age</TableCell>
                  <TableCell>Gender</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {viewedCall?.updatedServiceCase?.patients?.map((patient) => (
                  <TableRow key={patient.contactID}>
                    <TableCell>
                      {formatNameToDisplay(patient.firstName, patient.lastName)}
                    </TableCell>
                    <TableCell>
                      {patient.dob
                        ? differenceInYears(Date.now(), new Date(patient.dob))
                        : patient.age || "--"}
                    </TableCell>
                    <TableCell>{displayGender(patient.gender)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <Divider />

      <PlanItinerary activeTab={activeTab} switchTab={switchTab} />
    </div>
  );
};
