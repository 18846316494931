import React, { FC, useEffect, useMemo, useState } from "react";
import { Dashboard, MoreVert, PhoneMissed } from "@material-ui/icons";
import { useDashboardLayoutStyles } from "layout/styles";
import {
  Box,
  FormControlLabel,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Switch,
  SwitchProps,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AppNotifications } from "component";
import { ROUTES } from "component/configs";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { AudioVideoSettings } from "component/Dialogs/audioVideoSettings";
import { toggleDispatchDuty } from "layout/request";
import { useAppDispatch, useAppSelector } from "redux/store";
import logo from "images/be_logo_text_dark_transparent.png";
import {
  dispatcherActions,
  dispatcherSelectors,
  DisplayLayout,
  DisplayMode,
} from "redux/dispatcher";
import { formatNameToDisplay } from "helpers";
import { withStyles } from "@material-ui/core/styles";
import { CallProvider, CallWrapper, MapProvider, useCall } from "call";
import LocationPermissionDialog from "component/Dialogs/LocationPermissions";
import { useZohoCurrentUser } from "hooks";
import { ReactComponent as PauseIcon } from "images/pause.svg";
import { StartEndShift } from "component/Dialogs/StartEndShift";
import { UserRoleEnum } from "@deep-consulting-solutions/be2-constants";
import { SimpleSelect } from "component/atoms/Select";
import { CaseClassificationProvider } from "context/CaseClassificationProvider";
import {
  CreateServiceCase,
  CreateUnansweredServiceRequest,
} from "component/Dialogs";
import Grid from "@material-ui/core/Grid";
import { SplitButton } from "component/atoms";
import { DashboardMockCallProvider } from "dashboard-mock-call";
import { useHistory, useLocation } from "react-router-dom";
import { SidebarLayout } from "./sidebar.layout";
import { SidebarNavType } from "./types";

const sidebarNavigations = (): SidebarNavType => {
  return [
    { label: "Dashboard", link: ROUTES.fulfilment.path, icon: <Dashboard /> },
    {
      label: "Unanswered Requests",
      link: ROUTES.fulfilmentUnAnsweredRequest.path,
      icon: <PhoneMissed />,
    },
  ];
};

const DutySwitchWithStyles = withStyles({
  switchBase: {
    "&$checked": {
      color: "#08A40F",
    },
    "&$checked + $track": {
      backgroundColor: "#08A40F",
    },
  },
  checked: {},
  track: {},
})(Switch);

const DutySwitch = ({ disabled, ...props }: SwitchProps) => {
  const { ongoingCalls } = useCall();
  return (
    <DutySwitchWithStyles
      {...props}
      disabled={disabled || !!ongoingCalls.length}
    />
  );
};

type DashboardType = FC & { Container: FC };

const options = ["new service case", "new unanswered request"];

export const FulfilmentDashboardLayout: DashboardType = ({ children }) => {
  const {
    zohoID,
    onDuty,
    lastName,
    firstName,
    displayMode,
    dispatcherID,
    displayLayout,
  } = useAppSelector((state) => state.dispatcher);
  const dispatcherRoles = useAppSelector(dispatcherSelectors.roles);

  const { currentUser } = useZohoCurrentUser();
  const [sidebarState, setSidebarState] = useState(false);
  const [onDutyLoading, setOnDutyLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [audioDialog, setAudioDialog] = React.useState(false);
  const [shiftDialog, setShiftDialog] = React.useState({
    show: false,
    reAssign: false,
  });

  const [newService, setNewService] = useState(false);
  const [newUnanswered, setNewUnanswered] = useState(false);

  const location = useLocation();

  const showModeChanger = useMemo(() => {
    return ![ROUTES.fulfilmentUnAnsweredRequest.path].includes(
      location?.pathname
    );
  }, [location]);

  const handleClick = (value: string) => {
    switch (value) {
      case options[0]:
        setNewService(!newService);
        break;
      case options[1]:
        setNewUnanswered(!newUnanswered);
        break;
      default:
        break;
    }
  };

  const handleCloseCreateServiceCase = () => {
    setNewService(!newService);
  };
  const handleCloseUnanswered = () => {
    setNewUnanswered(!newUnanswered);
  };

  const history = useHistory();

  const isWidget = useMemo(() => {
    return history.location.pathname === ROUTES.caseClassificationsWidget.path;
  }, [history.location.pathname]);

  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const classes = useDashboardLayoutStyles({ sidebar: sidebarState, isWidget });

  const dispatch = useAppDispatch();

  const {
    fetchDispatcherActivityRecord,
    toggleOnDuty,
    setDisplayMode,
    setDisplayLayout,
  } = dispatcherActions;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event?: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event?.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleAudioSettings = () => {
    setAudioDialog(!audioDialog);
  };

  const toggleDispatcher = () => {
    (async () => {
      try {
        setOnDutyLoading(true);
        await toggleDispatchDuty(!onDuty, zohoID);
        setShiftDialog({ show: false, reAssign: false });
        dispatch(toggleOnDuty());
        setOnDutyLoading(false);
      } catch (e: any) {
        setOnDutyLoading(false);
        if (
          e?.response?.status === 400 &&
          e?.response?.data?.status === "error"
        ) {
          setShiftDialog({ ...shiftDialog, reAssign: true });
        }
      }
    })();
  };
  const handleToggleDispatcher = () => {
    setShiftDialog({ show: true, reAssign: false });
  };

  const handleSwitchView = () => {
    dispatch(setDisplayLayout());
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);

  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (!zohoID && dispatcherRoles.isDispatcher) {
      dispatch(fetchDispatcherActivityRecord());
    }
  }, [
    dispatch,
    zohoID,
    dispatcherRoles.isDispatcher,
    fetchDispatcherActivityRecord,
  ]);

  useEffect(() => {
    if (currentUser?.role.name === UserRoleEnum.Manager) {
      dispatch(setDisplayMode(DisplayMode.StatusGroup));
    } else dispatch(setDisplayMode(DisplayMode.StatusGroup));
  }, [currentUser, dispatch, setDisplayMode]);

  return (
    <CallProvider>
      <DashboardMockCallProvider>
        <CaseClassificationProvider>
          <MapProvider>
            <div className={classes.root}>
              <CreateUnansweredServiceRequest
                open={newUnanswered}
                onClose={handleCloseUnanswered}
              />
              <CreateServiceCase
                open={newService}
                onClose={handleCloseCreateServiceCase}
              />
              <AudioVideoSettings
                open={audioDialog}
                onClose={handleAudioSettings}
              />
              <StartEndShift
                loading={onDutyLoading}
                type={onDuty ? "end" : "start"}
                onActionClick={toggleDispatcher}
                open={shiftDialog.show}
                reAssign={shiftDialog.reAssign}
                onClose={() => setShiftDialog({ show: false, reAssign: false })}
              />

              {!isWidget ? (
                <>
                  <header className={classes.header}>
                    <div className={classes.rowStart}>
                      <div className={classes.logo}>
                        <img src={logo} alt="" style={{ width: 80 }} />
                      </div>
                    </div>
                    <div className={classes.rowEnd}>
                      <Grid container direction="column" alignItems="flex-end">
                        <SplitButton options={options} onClick={handleClick} />
                      </Grid>
                      {showModeChanger && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <SimpleSelect
                            style={{ minWidth: 150 }}
                            label="Display Mode"
                            value={displayMode as string}
                            InputLabelProps={{ shrink: !!displayMode }}
                            onChange={(e) => {
                              dispatch(
                                setDisplayMode(e.target.value as DisplayMode)
                              );
                            }}
                            options={Object.values(DisplayMode)}
                          />
                          <IconButton onClick={handleSwitchView}>
                            <Tooltip
                              title={
                                displayLayout === DisplayLayout.horizontal
                                  ? "Switch to Vertical view"
                                  : "Switch to Horizontal view"
                              }
                            >
                              <PauseIcon
                                style={{
                                  height: 37,
                                  width: 37,
                                  border: "1px solid #4F4F4F",
                                  borderRadius: 4,
                                  transform: `rotate(${
                                    displayLayout === DisplayLayout.horizontal
                                      ? 90
                                      : 0
                                  }deg)`,
                                }}
                              />
                            </Tooltip>
                          </IconButton>
                        </div>
                      )}

                      {!!dispatcherID && (
                        <FormControlLabel
                          control={
                            <DutySwitch
                              name="onDuty"
                              checked={onDuty}
                              onChange={handleToggleDispatcher}
                              disabled={onDutyLoading || !dispatcherID}
                            />
                          }
                          label={
                            <span style={{ whiteSpace: "nowrap" }}>
                              {onDuty ? "On" : "Off"} duty
                            </span>
                          }
                        />
                      )}

                      <Box
                        style={{
                          marginLeft: 5,
                          borderLeft: "1px solid rgba(86, 92, 99, 0.1)",
                          paddingLeft: 15,
                          marginRight: 20,
                        }}
                      >
                        <Typography
                          color="primary"
                          style={{
                            fontSize: 16,
                            fontWeight: 500,
                            whiteSpace: "nowrap",
                          }}
                        >
                          {formatNameToDisplay(firstName, lastName)}
                        </Typography>
                      </Box>
                      <AppNotifications />
                      <IconButton ref={anchorRef} onClick={handleToggle}>
                        <MoreVert />
                      </IconButton>
                      <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === "bottom"
                                  ? "center top"
                                  : "center bottom",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                  autoFocusItem={open}
                                  id="menu-list-grow"
                                >
                                  <MenuItem onClick={handleAudioSettings}>
                                    Audio and Video Settings
                                  </MenuItem>
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </div>
                  </header>

                  <SidebarLayout
                    fullSidebar={{ sidebarState, setSidebarState }}
                    nav={sidebarNavigations()}
                  />
                </>
              ) : null}
              <div className={classes.container}>
                <div className={classes.contentFulfilment}>
                  <CallWrapper>{children}</CallWrapper>
                </div>
              </div>
              <LocationPermissionDialog />
            </div>
          </MapProvider>
        </CaseClassificationProvider>
      </DashboardMockCallProvider>
    </CallProvider>
  );
};

const DashboardContainer: FC = ({ children }) => {
  return (
    <div className="dashboard-container" style={{ overflow: "auto" }}>
      {children}
    </div>
  );
};

FulfilmentDashboardLayout.Container = DashboardContainer;
