import {
  GetContacts,
  GetHospitals,
  PostHoldCall,
  PostResumeCall,
  ServiceCaseResponse,
  PatchInitialLocation,
  PostAnswerCallSession,
  CopyWithOptionalProps,
  ServiceCaseStatusEnum,
  GetMedicalSpecialties,
  PatchInitialAssessment,
  PostEndCallByDispacther,
  GetMedicalProfessionals,
  PutRequesterPatientInfo,
  PatchDestinationLocation,
  GetDispatcherActiveCalls,
  PutUpdateServiceCaseStatus,
  PatchSendMedicalAssessment,
  PostGetLocationCollectorLink,
  MovePatientsToNewServiceCase,
  PostSendLocationCollectorLink,
  PostSendDispactherSessionChat,
  GetContactSubscriptionMembers,
  GetServiceCaseItinerarySections,
  PatchServiceCaseItineraryPlanning,
  PostServiceCasePhysicianFailedCallback,
  AddOrRemoveServiceCaseItinerarySection,
  PatchPatientSubCoverageService,
  PatchEmergencyService,
  GetServiceCase,
  S3BucketTypeEnum,
  GetS3UploadSignedUrl,
  GetDispatcherAppChatAttachmentURL,
} from "@deep-consulting-solutions/be2-constants";
import { stringify } from "query-string";
import { crmClient, crmSilentClient } from "apis";
import { MedicalDirectorType } from "./types";

export const patchInitialAssessment = async (
  id: string,
  values: typeof PatchInitialAssessment.Body
) => {
  const res = await crmClient.patch<typeof PatchInitialAssessment.Res>(
    `/service-cases/:id/initial-assesment`.replace(":id", id),
    values
  );

  return res.data.data;
};

export const putUpdateServiceCaseStatus = async (
  id: string,
  caseStatus: ServiceCaseStatusEnum
) => {
  const res = await crmClient.put<typeof PutUpdateServiceCaseStatus.Res>(
    PutUpdateServiceCaseStatus.ROUTE.replace(":id", id),
    { caseStatus }
  );

  return res.data.data;
};

export const getActiveCalls = async () => {
  const res = await crmClient.get<typeof GetDispatcherActiveCalls.Res>(
    GetDispatcherActiveCalls.ROUTE
  );

  return res.data.data;
};

export const putCloseCase = async (id: string, reasonForClosing: string) => {
  const res = await crmClient.put<{ data: ServiceCaseResponse }>(
    PutUpdateServiceCaseStatus.ROUTE.replace(":id", id),
    {
      reasonForClosing,
      caseStatus: ServiceCaseStatusEnum.CLOSED,
    }
  );

  return res.data.data;
};

export const postAnswerCallSession = async (sessionId: string) => {
  const res = await crmClient.post<typeof PostAnswerCallSession.Res>(
    PostAnswerCallSession.ROUTE,
    { sessionId }
  );

  return res.data.data;
};

export const postResumeCall = async (id: string, sessionId: string) => {
  const res = await crmClient.post<typeof PostAnswerCallSession.Res>(
    PostResumeCall.ROUTE.replace(":sessionId", sessionId).replace(":id", id)
  );

  return res.data;
};

export const postHoldCall = async (id: string, sessionId: string) => {
  const res = await crmClient.post<typeof PostAnswerCallSession.Res>(
    PostHoldCall.ROUTE.replace(":sessionId", sessionId).replace(":id", id)
  );

  return res.data;
};

export const postEndCallByDispatcher = async (
  id: string,
  sessionId: string
) => {
  const res = await crmClient.post<typeof PostEndCallByDispacther.Res>(
    PostEndCallByDispacther.ROUTE.replace(":sessionId", sessionId).replace(
      ":id",
      id
    )
  );

  return res.data;
};

export const getHospitals = async () => {
  const res = await crmClient.get<typeof GetHospitals.Res>(GetHospitals.ROUTE);
  return res.data.data;
};

export const getLocationCollectorLink = async (
  id: string,
  isLiveLocationSync: boolean
) => {
  const body: typeof PostGetLocationCollectorLink.Body = {
    isLiveLocationSync,
  };

  const res = await crmClient.post<typeof PostGetLocationCollectorLink.Res>(
    PostGetLocationCollectorLink.ROUTE.replace(":id", id),
    body
  );

  return res.data.data;
};

export const sendLocationCollectorLink = async (
  id: string,
  link: string,
  phone: string
) => {
  const query = new URL(link).search;
  const body: typeof PostSendLocationCollectorLink.Body = {
    locationIdentificationPhone: phone,
  };

  const res = await crmClient.post<typeof PostSendLocationCollectorLink.Res>(
    `${PostSendLocationCollectorLink.ROUTE.replace(":id", id)}${query}`,
    body
  );

  return res.data.data;
};

export const postSendDispactherSessionChat = async (
  id: string,
  sessionId: string,
  body: typeof PostSendDispactherSessionChat.Body
) => {
  const res = await crmClient.post<typeof PostSendDispactherSessionChat.Res>(
    PostSendDispactherSessionChat.ROUTE.replace(
      ":sessionId",
      sessionId
    ).replace(":id", id),
    body
  );

  return res.data.data;
};

export const fetchS3SignedUrlForUpload = async (
  contentType: string,
  fileName?: string
) => {
  const queryParams: Record<string, string> = {
    contentType,
    bucketType: S3BucketTypeEnum.CALL_SESSION_CHAT_ATTACHMENT,
  };
  if (fileName) {
    queryParams.fileName = fileName;
  }

  const query =
    queryParams && Object.keys(queryParams).length > 0
      ? stringify(queryParams, {
          skipEmptyString: true,
          skipNull: true,
        })
      : "";

  const res = await crmClient.get<typeof GetS3UploadSignedUrl.Res>(
    `${GetS3UploadSignedUrl.path}?${query}`
  );
  return res.data.data;
};

export const uploadFileToS3 = async (file: File, url: string) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", url, true);
    xhr.onload = (e) => {
      const currentTarget = e.currentTarget as any;

      try {
        if ([4, 5, "4", "5"].includes(String(currentTarget.status).charAt(0))) {
          reject((e.currentTarget as any)?.statusText);
        } else {
          resolve(e);
        }
      } catch {
        reject(currentTarget?.statusText);
      }
    };
    xhr.onerror = reject;
    xhr.send(file);
  });
};

export const patchInitialLocation = async (
  serviceCaseId: string,
  body: CopyWithOptionalProps<
    typeof PatchInitialLocation.Body,
    "medicalFacilityID"
  >
) => {
  const res = await crmClient.patch<typeof PatchInitialLocation.Res>(
    PatchInitialLocation.ROUTE.replace(":id", serviceCaseId),
    body
  );

  return res.data.data;
};

export const patchDestination = async (
  serviceCaseId: string,
  body: typeof PatchDestinationLocation.Body
) => {
  const res = await crmClient.patch<typeof PatchDestinationLocation.Res>(
    PatchDestinationLocation.ROUTE.replace(":id", serviceCaseId),
    body
  );

  return res.data.data;
};

export const patchPatientSubCoverageService = async (
  serviceCaseId: string,
  service: string
) => {
  const res = await crmClient.patch<typeof PatchPatientSubCoverageService.Res>(
    PatchPatientSubCoverageService.ROUTE.replace(":id", serviceCaseId),
    { service }
  );

  return res.data.data;
};

export const fetchMedicalProfessionals = async (
  params: typeof GetMedicalProfessionals.Params
) => {
  const res = await crmClient.get<typeof GetMedicalProfessionals.Res>(
    `${GetMedicalProfessionals.ROUTE}?${stringify(params)}`
  );

  return res.data.data;
};

export const movePatients = async (
  serviceCaseId: string,
  patientIds: string[]
) => {
  const res = await crmClient.put<typeof MovePatientsToNewServiceCase.Res>(
    MovePatientsToNewServiceCase.ROUTE.replace(":id", serviceCaseId),
    { patientIds }
  );

  return res.data.data;
};

export const patchEmergencyService = async (
  serviceCaseId: string,
  body: typeof PatchEmergencyService["Body"]
) => {
  const res = await crmClient.patch<typeof PatchEmergencyService.Res>(
    PatchEmergencyService.ROUTE.replace(":id", serviceCaseId),
    body
  );

  return res.data.data;
};

export const patchSendMedicalAssessment = async (
  serviceCaseId: string,
  body: typeof PatchSendMedicalAssessment["Body"]
) => {
  const res = await crmClient.patch<typeof PatchSendMedicalAssessment.Res>(
    PatchSendMedicalAssessment.ROUTE.replace(":id", serviceCaseId),
    body
  );

  return res.data.data;
};

export const patchSendForApproval = async () => {
  const res = await new Promise((r) =>
    setTimeout(() => {
      r(true);
    }, 3000)
  );
  return res;
};

export const getMedicalProfessionals = async () => {
  const res = await crmClient.get<typeof GetMedicalProfessionals.Res>(
    GetMedicalProfessionals.ROUTE
  );

  return res.data.data.medicalProfessionals;
};

export const getMedicalDirectors = async () => {
  const res = await crmSilentClient.get<{ data: MedicalDirectorType[] }>(
    "/medical-directors"
  );
  return res.data.data;
};

export const getMedicalSpecialities = async () => {
  const res = await crmClient.get<typeof GetMedicalSpecialties.Res>(
    GetMedicalSpecialties.ROUTE
  );

  return res.data.data;
};

export const getItinerarySections = async (id: string) => {
  const res = await crmClient.get<typeof GetServiceCaseItinerarySections.Res>(
    GetServiceCaseItinerarySections.ROUTE.replace(":id", id)
  );

  return res.data.data;
};

export const addOrRemoveServiceCaseItinerarySection = async (
  id: string,
  body: typeof AddOrRemoveServiceCaseItinerarySection.Body
) => {
  const res = await crmClient.put<
    typeof AddOrRemoveServiceCaseItinerarySection.Res
  >(AddOrRemoveServiceCaseItinerarySection.ROUTE.replace(":id", id), body);

  return res.data.data;
};

export const patchCancelApprovalRequest = async (
  serviceCaseId: string,
  reasonForCancellation: string
) => {
  const res = await crmClient.patch<{ data: ServiceCaseResponse }>(
    "/service-cases/approval-request/:id/cancel".replace(":id", serviceCaseId),
    { reason: reasonForCancellation }
  );
  return res.data.data;
};
export const postServiceCasePhysicianFailedCallback = async (id: string) => {
  const res = await crmClient.post<
    typeof PostServiceCasePhysicianFailedCallback.Res
  >(PostServiceCasePhysicianFailedCallback.ROUTE.replace(":id", id));

  return res.data.data;
};

export const putRequesterPatientInfo = async (
  id: string,
  values: Record<string, unknown>
) => {
  const res = await crmClient.put<typeof PutRequesterPatientInfo.Res>(
    PutRequesterPatientInfo.ROUTE.replace(":id", id),
    values
  );

  return res.data.data;
};

export const searchExistingMembers = async (values: Record<string, any>) => {
  const res = await crmClient.get<typeof GetContacts.Res>(
    `${GetContacts.ROUTE}?${stringify(values, {
      skipEmptyString: true,
      skipNull: true,
    })}`
  );

  return res.data.data;
};

export const getContactSubMembers = async (
  contactID: string,
  above18 = false,
  forNextOfKin = false
) => {
  const res = await crmClient.get<typeof GetContactSubscriptionMembers.Res>(
    `${GetContactSubscriptionMembers.ROUTE.replace(":id", contactID)}${
      above18 ? "?above18=true" : ""
    }${forNextOfKin ? `${above18 ? "&" : "?"}forNextOfKin=true` : ""}`
  );
  return res.data.data;
};

export const startOrScheduleServiceCase = async (
  id: string,
  body: typeof PatchServiceCaseItineraryPlanning.Body
) => {
  const res = await crmClient.patch<
    typeof PatchServiceCaseItineraryPlanning.Res
  >(PatchServiceCaseItineraryPlanning.ROUTE.replace(":id", id), body);

  return res.data.data;
};

export const provideInformationalSupport = async (
  id: string,
  values: {
    reason: string;
    explaination?: string;
  }
) => {
  const res = await crmClient.patch<{ data: ServiceCaseResponse }>(
    `/service-cases/:id/complete-informational-case`.replace(":id", id),
    values
  );
  return res.data.data;
};

export const updatePatientSubCoverageService = async (
  id: string,
  body: typeof PatchPatientSubCoverageService.Body
) => {
  const res = await crmClient.patch<typeof PatchPatientSubCoverageService.Res>(
    PatchPatientSubCoverageService.ROUTE.replace(":id", id),
    body
  );

  return res.data.data;
};

export const getServiceCase = async (id: string) => {
  const res = await crmClient.get<typeof GetServiceCase.Res>(
    GetServiceCase.ROUTE.replace(":id", id)
  );
  return res.data.data;
};

export const fetchS3SignedUrlForDownload = async (
  serviceCaseId: string,
  sessionId: string,
  chatId: string,
  attachmentId: string
) => {
  const res = await crmSilentClient.get<
    typeof GetDispatcherAppChatAttachmentURL.Res
  >(
    GetDispatcherAppChatAttachmentURL.ROUTE.replace(":id", serviceCaseId)
      .replace(":sessionId", sessionId)
      .replace(":chatId", chatId)
      .replace(":attachmentId", attachmentId)
  );
  return res.data.data;
};
