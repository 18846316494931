import { makeStyles } from "@material-ui/core";
import React from "react";
import { useCall } from "call/hooks";
import { SingleCallVideo } from "./SingleCallVideo";

const useStyles = makeStyles(() => ({
  wrapper: {
    height: "100%",
    width: "100%",
    position: "relative",
    borderRadius: 8,
    overflow: "hidden",
  },
}));

export const CallVideo = () => {
  const classes = useStyles();
  const { calls } = useCall();

  return (
    <div className={classes.wrapper}>
      {calls.map((callWithOpenTokSession) => (
        <SingleCallVideo
          call={callWithOpenTokSession}
          key={callWithOpenTokSession.sessionId}
        />
      ))}
    </div>
  );
};
