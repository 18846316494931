import React, { useCallback, useMemo } from "react";
import { Box, Checkbox, Grid, makeStyles } from "@material-ui/core";
import { CreditCard } from "@deep-consulting-solutions/be2-constants";
import { differenceInMonths } from "date-fns";
import { Theme } from "@material-ui/core/styles";
import { CreditCardLogo } from "component/CreditCardLogo";
import { ReactComponent as Checked } from "images/checked.svg";
import { ReactComponent as Unchecked } from "images/unchecked.svg";
import { StyledTypography } from "component/StyledTypography";

const getStatusColor = (value: string | null) => {
  switch (value) {
    case "soon":
      return "#ED6C02";
    case "expired":
      return "#D32F2F";
    default:
      return "inherit";
  }
};

type StyleType = {
  status?: "soon" | "expired" | null;
  handlesRecurringPayments?: boolean;
  isSelected: boolean;
};

const useStyle = makeStyles<Theme, StyleType>(({ spacing: s }) => ({
  card: {
    margin: s(2, 0),
    background: "white",
    boxShadow: ({ isSelected }) =>
      isSelected ? "0px 6px 20px rgba(0, 0, 0, 0.15)" : "",
    padding: s(2),
    cursor: "pointer",
    borderRadius: 8,
    border: ({ status }: StyleType) =>
      status ? `2px solid ${getStatusColor(status)}` : "unset",
    display: "block",
    width: "100%",
  },
  atmNumbers: {
    textTransform: "capitalize",
  },
  recurring: {
    fontSize: 10,
  },
}));

interface Props extends CreditCard {
  isSelected: boolean;
  brand: string;
  handleClick: (id: string) => void;
  id: string;
  disabled: boolean;
}

export const CreditCardSnippet = ({
  holderName,
  brand,
  lastFourDigits,
  expiryMonth,
  expiryYear,
  handlesRecurringPayments,
  isSelected,
  handleClick,
  disabled,
  id,
}: Props) => {
  const expirationStatus = (
    month: number,
    year: number
  ): "soon" | "expired" | null => {
    const result = differenceInMonths(new Date(year, month - 1, 1), new Date());
    if (result <= 2 && result > 0) return "soon";
    if (result < 0) return "expired";
    return null;
  };

  const memoizedRenderLogo = useMemo(
    () => <CreditCardLogo brand={brand} />,
    [brand]
  );

  const handleChange = useCallback(() => {
    handleClick(id);
  }, [handleClick, id]);

  const classes = useStyle({
    status: handlesRecurringPayments
      ? expirationStatus(expiryMonth, expiryYear)
      : null,
    handlesRecurringPayments,
    isSelected,
  });

  return (
    <button
      type="button"
      className={classes.card}
      onClick={handleChange}
      disabled={disabled}
    >
      <Grid container spacing={1} wrap="nowrap" alignItems="flex-start">
        <Grid item xs={2}>
          <Checkbox
            icon={<Unchecked />}
            checkedIcon={<Checked />}
            checked={isSelected}
            name={holderName}
            color="primary"
          />
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={1} wrap="wrap" alignItems="flex-start">
            <Grid item>
              <StyledTypography
                variant="body1"
                color="primary"
                className={classes.atmNumbers}
                style={{
                  color:
                    getStatusColor(
                      expirationStatus(expiryMonth, expiryYear)
                    ) === "#D32F2F"
                      ? "#D32F2F"
                      : "#263E7F",
                }}
              >
                {holderName}
              </StyledTypography>
            </Grid>
            <Grid item>
              <Grid container spacing={1} wrap="nowrap" alignItems="flex-start">
                <Grid item>
                  <StyledTypography
                    variant="body1"
                    color="primary"
                    className={classes.atmNumbers}
                    style={{
                      color:
                        getStatusColor(
                          expirationStatus(expiryMonth, expiryYear)
                        ) === "#D32F2F"
                          ? "#D32F2F"
                          : "#263E7F",
                    }}
                  >
                    **** **** **** {lastFourDigits}
                  </StyledTypography>
                </Grid>
                <Grid item>
                  <StyledTypography
                    variant="body1"
                    align="left"
                    color="primary"
                    className={classes.atmNumbers}
                    style={{
                      color:
                        getStatusColor(
                          expirationStatus(expiryMonth, expiryYear)
                        ) === "#D32F2F"
                          ? "#D32F2F"
                          : "#263E7F",
                    }}
                  >
                    {`${
                      expiryMonth <= 9 ? `0${expiryMonth}` : expiryMonth
                    }/${expiryYear?.toString()?.substring(2, 4)}`}
                  </StyledTypography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {handlesRecurringPayments ? (
            <Box mt={1}>
              <StyledTypography
                variant="body2"
                align="left"
                color="grey.900"
                className={classes.recurring}
              >
                This card is used for recurring payment
              </StyledTypography>
            </Box>
          ) : null}
        </Grid>
        <Grid item xs={2}>
          {memoizedRenderLogo}
        </Grid>
      </Grid>
    </button>
  );
};
