import React, { useMemo, useState, useEffect } from "react";
import { format } from "date-fns";
import { Alert } from "@material-ui/lab";
import {
  Box,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  makeStyles,
  Typography,
  TableContainer,
} from "@material-ui/core";
import {
  CallBackLog,
  ServiceCaseResponse,
  ServiceCaseCallBackTypeEnum,
  PhysicianFailedCallbackUserTypeEnum,
} from "@deep-consulting-solutions/be2-constants";

import Loader from "component/Loader";
import { formatNameToDisplay } from "helpers";
import { serviceCaseRequests } from "redux/serviceCase";

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  alert: {
    marginBottom: s(3),
    backgroundColor: p.primary.main,
  },
  physicianContainer: {
    display: "grid",
    gap: s(2),
    width: "100%",
    padding: s(2),
    borderRadius: s(1),
    gridTemplateColumns: "57fr 36fr",
    boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.12)",
  },
  physicianDetails: {
    padding: "8px",
    height: "fit-content",
    border: "1px solid #e0e0e0",
  },
  detailItem: {
    display: "flex",

    "& :not(a)": {
      color: "#4f4f4f",
    },

    "&:not(:last-child)": {
      marginBottom: "12px",
    },

    "& :first-child": {
      marginRight: "8px",
    },

    "& :nth-child(2n)": {
      fontWeight: 700,
    },
  },
  table: {
    marginBottom: "10px",
    boxShadow: "0px 3px 5px rgb(0 0 0 / 12%)",

    "& th.MuiTableCell-root": {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
    },

    "& .MuiTableRow-root td:last-child": {
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  },
  tableHead: {
    backgroundColor: "#F6F6F6",
  },
  callAttempts: {
    color: "#4f4f4f",
    marginBottom: s(2),
  },
  logButton: {
    height: s(5),
    fontSize: 12,
  },
}));

interface PhysicianProps {
  disableForm: boolean;
  serviceCase?: ServiceCaseResponse;
}

const Physician = ({ serviceCase, disableForm }: PhysicianProps) => {
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [logs, setLogs] = useState<CallBackLog[]>([]);
  const classes = useStyles();

  const physicianIsNotAvailable = useMemo(() => {
    const { requestingPhysician = null } = serviceCase || {};

    if (requestingPhysician?.firstName && requestingPhysician.lastName) {
      return false;
    }

    return true;
  }, [serviceCase]);

  const logFailedCall = () => {
    (async () => {
      if (serviceCase?.id) {
        try {
          setButtonLoading(true);

          const log = await serviceCaseRequests.logPhysicianCallbackAttempt(
            serviceCase.id,
            PhysicianFailedCallbackUserTypeEnum.MEDICAL_DIRECTOR
          );

          setTotal((t) => t + 1);
          setLogs([log, ...logs]);
        } catch {
          //
        } finally {
          setButtonLoading(false);
        }
      }
    })();
  };

  useEffect(() => {
    (async () => {
      if (serviceCase?.id) {
        try {
          setLoading(true);

          const { total: t, callBackLogs } =
            await serviceCaseRequests.getPhysicianCallbackLogs(serviceCase.id, {
              page: 1,
              perPage: 3,
              type: ServiceCaseCallBackTypeEnum.PHYSICIAN,
            });

          setTotal(t);
          setLogs(callBackLogs);
        } catch {
          //
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [serviceCase?.id]);

  if (physicianIsNotAvailable) {
    return (
      <Box
        height={84}
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        border="1px solid #e0e0e0"
      >
        <Typography variant="body1" style={{ color: "#4f4f4f" }}>
          Patient&apos;s Physician is not available.
        </Typography>
      </Box>
    );
  }

  return (
    <Box position="relative">
      <Loader open={loading} absolute />
      <Alert severity="info" variant="filled" className={classes.alert}>
        Call the Physician and fill in the information about the medical status
        of the Patient.
      </Alert>

      <Box className={classes.physicianContainer}>
        <Box className={classes.physicianDetails}>
          <Box className={classes.detailItem}>
            <Typography>Name:</Typography>
            <Typography>
              {formatNameToDisplay(
                serviceCase?.requestingPhysician.firstName,
                serviceCase?.requestingPhysician.lastName
              )}
            </Typography>
          </Box>

          <Box className={classes.detailItem}>
            <Typography>Phone:</Typography>
            <Typography>
              {serviceCase?.requestingPhysician.phone || "---"}
            </Typography>
          </Box>

          <Box className={classes.detailItem}>
            <Typography>Email:</Typography>
            <Typography>
              {serviceCase?.requestingPhysician.email || "---"}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography variant="subtitle2" className={classes.callAttempts}>
            Call Attempts ({total})
          </Typography>

          {logs.length ? (
            <TableContainer>
              <Table aria-label="call attempts" className={classes.table}>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption">
                        Time of the Attempt
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {logs.map((log) => (
                    <TableRow key={log.id}>
                      <TableCell>
                        <Typography variant="caption">
                          {format(new Date(log.callBackTime), "d MMM y")}{" "}
                        </Typography>
                        <Typography
                          style={{ color: "#828282" }}
                          variant="caption"
                        >
                          {format(new Date(log.callBackTime), "h:m:s aaa")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}

          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={logFailedCall}
            className={classes.logButton}
            disabled={loading || buttonLoading || disableForm}
          >
            <Loader open={buttonLoading} absolute />
            Log Failed Call to Physician
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Physician;
