import {
  GetFetchServiceCases,
  PatchCloseServiceCase,
  PostLogCallBackAttempt,
  PostServiceCase,
  PutUpdateServiceCaseStatus,
  ServiceCaseStatusGroupEnum,
} from "@deep-consulting-solutions/be2-constants";
import { getClient } from "apis";

export const createUnansweredServiceRequest = async (
  values: typeof PostServiceCase.Body
) => {
  const res = await getClient(true).post<typeof PostServiceCase.Res>(
    PostServiceCase.ROUTE,
    values
  );
  return res.data;
};

export const updateServiceCase = async (
  id: string,
  values: typeof PutUpdateServiceCaseStatus.Body
) => {
  const res = await getClient(true).put<typeof PutUpdateServiceCaseStatus.Res>(
    PutUpdateServiceCaseStatus.ROUTE.replace(":id", id),
    values
  );
  return res.data.data;
};

export const closeServiceCase = async (
  serviceCaseId: string,
  values: typeof PatchCloseServiceCase.Body
) => {
  const res = await getClient(true).patch<typeof PatchCloseServiceCase.Res>(
    PatchCloseServiceCase.ROUTE.replace(":id", serviceCaseId),
    values
  );
  return res.data.data.serviceCase;
};

export const logFailedCallBackAttempts = async (
  id: string,
  values: typeof PostLogCallBackAttempt.Body
) => {
  const res = await getClient(true).post<typeof PostLogCallBackAttempt.Res>(
    PostLogCallBackAttempt.ROUTE.replace(":id", id),
    values
  );
  return res.data.data;
};

export const getPendingCases = (
  params: Partial<typeof GetFetchServiceCases.Params> = {}
) => {
  return getClient(true).get<typeof GetFetchServiceCases["Res"]>(
    GetFetchServiceCases.ROUTE,
    {
      params: {
        perPage: 6,
        ...params,
        searchText: "",
        statusGroup: [
          ServiceCaseStatusGroupEnum.NEW,
          ServiceCaseStatusGroupEnum.SCHEDULED,
          ServiceCaseStatusGroupEnum.IN_PROGRESS,
        ].join(),
      },
    }
  );
};
