import React, { useCallback } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { environmentVariables } from "configs/environmentVariables";
import { MapComponent } from "./MapComponent";
import { MapErrorComponent } from "./MapErrorComponent";
import { MapLoadingComponent } from "./MapLoadingComponent";

export const CallMap = () => {
  const render = useCallback((status: Status) => {
    if (status === Status.FAILURE) {
      return <MapErrorComponent />;
    }
    return <MapLoadingComponent />;
  }, []);

  return (
    <Wrapper
      render={render}
      libraries={["places"]}
      apiKey={environmentVariables.REACT_APP_GOOGLE_MAP_API_KEY}
    >
      <MapComponent />
    </Wrapper>
  );
};
