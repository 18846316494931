import * as Yup from "yup";
import { validatePhoneNumberWithCountryCode } from "@deep-consulting-solutions/dcs-web-ui";
import {
  YesNoEnum,
  LocationTypeEnum,
  ServiceTypesEnum,
  ServiceCaseResponse,
  PatientLevelOfEmergencyEnum,
  NextOfKinRelationToPatientEnum,
} from "@deep-consulting-solutions/be2-constants";

import { VALIDATIONS, convertYesOrNoToBoolean } from "helpers";

import type { AssessmentFormFields } from "./AssessmentForm";
import { NextOfKinTypeEnum } from "../RequesterAndPatientForm/types";
import {
  getIsMemberOrCustomer,
  patientNextOfKinValidation,
  getDefaultNextOfKinMembership,
} from "../RequesterAndPatientForm/helpers";

export const getValidationSchema = (serviceCase?: ServiceCaseResponse) => {
  const destinationIsHospital =
    serviceCase?.destinationLocationType === LocationTypeEnum.MEDICAL_FACILITY;

  const count = destinationIsHospital ? serviceCase?.patients?.length || 0 : 0;

  return Yup.object().shape({
    service: Yup.string<ServiceTypesEnum>().required("Service is Required"),
    customService: Yup.string().when("service", {
      is: ServiceTypesEnum.CUSTOM,
      then: Yup.string().required("Custom service is required"),
    }),
    patientReceivingPhysicians: Yup.array().min(count).max(count),
    noAndSpecialityOfMedicalProfessionals: Yup.string().required(
      "Number and Specialty of Medical Professionals is Required"
    ),
    recommendedMedicalProfessionals: Yup.string().required(
      "Recommended Medical Professionals are required"
    ),
    patients: Yup.array().of(
      Yup.object().shape({
        patientID: Yup.string().required(),
        medicalNeeds: Yup.string().required("Medical Needs are Required"),
        canFlyCommercially: Yup.string<YesNoEnum>(),
        requiresMedicalEscort: Yup.string<YesNoEnum>(),
        preferredDestinationArrivalTime: Yup.date()
          .min(new Date(), "Date and time must be in the future")
          .required("Preferred date and time is required")
          .typeError("Invalid Date"),
        nextOfKin: Yup.object().when("nextOfKinMembership", {
          is: (val: NextOfKinTypeEnum) => val !== NextOfKinTypeEnum.NONE,
          then: Yup.object().shape(patientNextOfKinValidation),
        }),
        physician: Yup.object().when([], {
          is: () => destinationIsHospital,
          then: () =>
            Yup.object().shape({
              patientID: Yup.string().required("patientID is required"),
              phone: Yup.string()
                .test(
                  "check validity",
                  "Physician Phone is invalid",
                  validatePhoneNumberWithCountryCode
                )
                .required("Physician phone is required"),
              email: Yup.string().required("Physician email is required"),
              firstName: Yup.string().required(
                "Physician first name is required"
              ),
              lastName: Yup.string().required(
                "Physician last name is required"
              ),
            }),
          otherwise: Yup.object().nullable(),
        }),
        levelOfEmergency: Yup.string<PatientLevelOfEmergencyEnum>().required(
          "Level of Emergency is Required"
        ),
      })
    ),
  });
};

export const getFullSchema = (serviceCase?: ServiceCaseResponse) =>
  getValidationSchema(serviceCase).shape({
    reason: VALIDATIONS.reason,
  });

export const getInitialValues = (
  serviceCase?: ServiceCaseResponse
): AssessmentFormFields => {
  return {
    reason: "",
    noAndSpecialityOfMedicalProfessionals:
      serviceCase?.noAndSpecialityOfMedicalProfessionals || "",
    recommendedMedicalProfessionals:
      serviceCase?.recommendedMedicalProfessionals || "",
    service: (serviceCase?.service || "") as ServiceTypesEnum,
    customService: serviceCase?.customService,
    patients:
      serviceCase?.patients?.map((patient) => {
        return {
          age: patient.age,
          dob: patient.dob,
          contactID: patient.contactID,
          patientID: patient.patientID,
          isDeceased: patient.isDeceased,
          isRequester: patient.isRequester,
          skipNextOfKin: false,
          physician: {
            patientID: patient.patientID,
            email: patient.receivingPhysicianEmail || "",
            phone: patient.receivingPhysicianPhone || "",
            lastName: patient.receivingPhysicianLastName || "",
            firstName: patient.receivingPhysicianFirstName || "",
          },
          medicalNeeds: patient.medicalNeeds || "",
          nextOfKinMembership: getDefaultNextOfKinMembership(
            patient,
            getIsMemberOrCustomer(serviceCase?.requester?.isMemberOrCustomer)
          ),
          preferredDestinationArrivalTime:
            patient.preferredDestinationArrivalTime || "",
          requiresMedicalEscort: convertYesOrNoToBoolean(
            patient.comercialFlightMedicalEscort
          ),
          canFlyCommercially: convertYesOrNoToBoolean(
            patient.canFlyCommercially
          ),
          levelOfEmergency:
            patient.levelOfEmergency || ("" as PatientLevelOfEmergencyEnum),
          nextOfKin: {
            phone: patient.nextOfKin?.phone || "",
            email: patient.nextOfKin?.email || "",
            lastName: patient.nextOfKin?.lastName || "",
            firstName: patient.nextOfKin?.firstName || "",
            otherRelationToPatient:
              patient.nextOfKin?.otherRelationToPatient || "",
            isMemberOrCustomer:
              patient.nextOfKin?.isMemberOrCustomer ||
              ("" as unknown as boolean),
            relationshipToPatient:
              patient.nextOfKin?.relationToPatient ||
              ("" as unknown as NextOfKinRelationToPatientEnum),
          },
        };
      }) || [],
  };
};
