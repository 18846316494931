import { useHistory, useLocation } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { Tab, Tabs, Button, makeStyles, Typography } from "@material-ui/core";
import {
  ServiceCaseResponse,
  ServiceCaseStatusEnum,
} from "@deep-consulting-solutions/be2-constants";

import { useCall } from "call/hooks";
import Loader from "component/Loader";
import { getServiceCase } from "redux/call/requests";
import { appendQueryParam, getQueryParam } from "helpers/queryParams";

import { MainTabsEnum } from "./types";
import { CallFormsMainTabPanel } from "./CallFormsMainTabPanel";
import {
  getCallFormMainTabs,
  numberToMainTabsEnum,
  mainTabsEnumToNumber,
} from "./helpers";

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  wrapper: {
    width: "100%",
    background: "#FFFFFF",
    borderRadius: 8,
    padding: s(2),
    height: "100%",
    overflow: "hidden",
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
  },
  title: {
    fontWeight: 800,
  },
  tabs: {
    background: "#F7F7F7",
    borderRadius: 4,
    margin: s(2, 0),
  },
  container: {
    flex: 1,
    overflow: "hidden",
  },
  tab: {
    margin: s(1, 1, 0, 1),
    "&.Mui-selected": {
      background: "white",
      borderRadius: "4px 4px 0px 0px",
    },
  },
  caseNumber: {
    borderRadius: 0,
    borderBottom: `2px solid ${p.primary.main}`,
  },
}));

const queryName = "mainFormTab";

export const CallForms = () => {
  const { viewedCall, updateCall } = useCall();
  const [activeTab, setActiveTab] = useState(
    mainTabsEnumToNumber(MainTabsEnum.DETAILS)
  );
  const location = useLocation();
  const history = useHistory();

  const switchTab = useCallback(
    (value: number) => {
      setActiveTab(value);
      history.push(
        appendQueryParam(location.pathname, [
          { key: queryName, value: numberToMainTabsEnum(value) },
        ])
      );
    },
    [history, location]
  );

  const handleSwitchTab = useCallback(
    (event, value: number) => {
      switchTab(value);
    },
    [switchTab]
  );

  const classes = useStyles();

  const [cases, setCases] = useState<ServiceCaseResponse[]>([]);

  useEffect(() => {
    const value = getQueryParam(queryName);
    if (value) {
      const indexOfQueryFromTab = mainTabsEnumToNumber(value as MainTabsEnum);
      if (indexOfQueryFromTab !== activeTab) {
        setActiveTab(indexOfQueryFromTab);
      }
    }
  }, [activeTab]);

  const [loading, setLoading] = useState(false);

  const handleCaseClick = useCallback(
    async (selectedCase: ServiceCaseResponse) => {
      if (
        viewedCall?.sessionId &&
        selectedCase.caseNumber !== viewedCall.updatedServiceCase?.caseNumber
      ) {
        try {
          setLoading(true);
          const updatedCase = await getServiceCase(selectedCase.id);

          updateCall(viewedCall.sessionId, {
            ...viewedCall,
            updatedServiceCase: updatedCase,
            assignedServiceCaseId: cases[0].id,
            disableForms: [
              ServiceCaseStatusEnum.CLOSED,
              ServiceCaseStatusEnum.REFUSED,
              ServiceCaseStatusEnum.RE_OPENED,
              ServiceCaseStatusEnum.COMPLETED,
              ServiceCaseStatusEnum.CANCELLED,
            ].includes(updatedCase.caseStatus),
          });
        } catch {
          //
        } finally {
          setLoading(false);
        }
      }
    },
    [cases, viewedCall, updateCall]
  );

  useEffect(() => {
    setCases((prevCases) => {
      if (viewedCall?.updatedServiceCase) {
        const index = prevCases.findIndex(
          (prevCase) =>
            prevCase.caseNumber === viewedCall?.updatedServiceCase?.caseNumber
        );

        if (index !== -1) {
          const copy = [...prevCases];
          copy[index] = {
            ...copy[index],
            ...viewedCall?.updatedServiceCase,
          };
          return copy;
        }

        return [...prevCases, viewedCall?.updatedServiceCase];
      }

      return prevCases;
    });
  }, [viewedCall?.updatedServiceCase]);

  return (
    <div className={classes.wrapper}>
      <Loader open={loading} absolute />

      <div>
        {cases.map((selectedCase) => {
          const isActive =
            selectedCase.caseNumber ===
            viewedCall?.updatedServiceCase?.caseNumber;

          return (
            <Button
              variant="text"
              disabled={isActive}
              key={selectedCase.caseNumber}
              onClick={() => {
                handleCaseClick(selectedCase);
              }}
              className={
                cases.length > 1 && isActive ? classes.caseNumber : undefined
              }
            >
              <Typography
                variant="subtitle1"
                color="primary"
                className={classes.title}
              >
                {selectedCase.caseNumber}
              </Typography>
            </Button>
          );
        })}
      </div>

      {loading ? null : (
        <>
          <div className={classes.tabs}>
            <Tabs
              value={activeTab}
              onChange={handleSwitchTab}
              aria-label="call main form tabs"
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="off"
              TabIndicatorProps={{
                style: { display: "none" },
              }}
            >
              {getCallFormMainTabs().map((tab) => (
                <Tab
                  key={tab.label}
                  label={
                    <Typography variant="subtitle2">{tab.label}</Typography>
                  }
                  value={mainTabsEnumToNumber(tab.label)}
                  textColor="primary"
                  className={classes.tab}
                  {...tab.props}
                />
              ))}
            </Tabs>
          </div>

          <div className={classes.container}>
            {getCallFormMainTabs().map((tab) => (
              <CallFormsMainTabPanel
                key={tab.label}
                index={tab.index}
                value={activeTab}
                label={tab.label}
                switchTab={switchTab}
                setCases={setCases}
                {...tab.props}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
