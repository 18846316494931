import React from "react";
import { Box, SvgIcon, makeStyles, Typography } from "@material-ui/core";

import { ReactComponent as Location1Icon } from "images/location1.svg";
import { ReactComponent as Location2Icon } from "images/location2.svg";
import { ReactComponent as Location3Icon } from "images/location3.svg";
import { ReactComponent as LocationMarkerIcon } from "images/locationMarker.svg";

import type { LocationField } from "./LocationForm";

export interface LocationSummaryProps {
  showAddress?: boolean;
  location: LocationField;
}

interface SummaryItemProps {
  label: string;
  color?: string;
  icon: React.ReactElement;
}

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  locationDetail: {
    display: "flex",
    minHeight: "20px",
    maxWidth: "356px",
    alignItems: "center",

    "& > :first-child": {
      width: 16,
      height: 16,
      marginRight: s(0.5),
    },

    "& .MuiTypography-root": {
      fontSize: 10,
      color: p.primary.main,
    },

    "&:not(:last-of-type)": {
      marginBottom: "6px",
    },
  },
}));

const SummaryItem = ({ icon, label, color }: SummaryItemProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.locationDetail}>
      <SvgIcon width={16} height={16} viewBox="0 0 16 16" style={{ color }}>
        {icon}
      </SvgIcon>

      <Typography style={{ color }}>{label}</Typography>
    </Box>
  );
};

export const LocationSummary = ({
  location,
  showAddress = false,
}: LocationSummaryProps) => {
  return (
    <Box>
      {showAddress && (
        <SummaryItem
          color="#263E7F"
          label={location.address}
          icon={<LocationMarkerIcon />}
        />
      )}

      <SummaryItem
        color="#263E7F"
        icon={<Location1Icon />}
        label={`${location.lat}, ${location.lng}`}
      />

      <SummaryItem
        color="#263E7F"
        label={location.dms}
        icon={<Location3Icon />}
      />

      {location.plusCode && (
        <SummaryItem
          color="#263E7F"
          icon={<Location2Icon />}
          label={location.plusCode}
        />
      )}
    </Box>
  );
};

export const LocationSummaryToolTip = ({
  location,
  showAddress = false,
}: LocationSummaryProps) => {
  return (
    <Box>
      {showAddress && (
        <SummaryItem
          color="white"
          label={location.address}
          icon={<LocationMarkerIcon />}
        />
      )}

      <SummaryItem
        color="white"
        icon={<Location1Icon />}
        label={`${location.lat}, ${location.lng}`}
      />

      <SummaryItem
        color="white"
        label={location.dms}
        icon={<Location3Icon />}
      />

      {location.plusCode && (
        <SummaryItem
          color="white"
          icon={<Location2Icon />}
          label={location.plusCode}
        />
      )}
    </Box>
  );
};
