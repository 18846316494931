import {
  BloodTypeEnum,
  PatientLevelOfEmergencyEnum,
} from "@deep-consulting-solutions/be2-constants";
import { Grid, MenuItem, Typography, makeStyles } from "@material-ui/core";
import { FormikDateTimePicker } from "call/components/FormikDateTimePicker";
import { Field } from "formik";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import React from "react";
import { useSelector } from "react-redux";
import { hospitalSelectors } from "redux/hospital";
import { PatientData } from "./types";

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  checkboxes: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",

    "& > *": {
      maxHeight: s(2.25),
    },

    "& > :not(:last-child)": {
      marginBottom: s(1.25),
    },
  },
  checkboxLabel: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "14px",
    color: p.primary.main,
  },
}));

interface Props {
  formKey: string;
  patient: PatientData;
  disableForm: boolean;
}

export const PatientMedicalInformationSection: React.FC<Props> = ({
  formKey,
  patient,
  disableForm,
}) => {
  const hospitals = useSelector(hospitalSelectors.getHospitals);

  const classes = useStyles();
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Field
            name={`${formKey}.preferredHospitalId`}
            label="Preferred Hospital"
            component={TextField}
            select
            size="small"
            disabled={disableForm}
          >
            {hospitals.map((h) => {
              return (
                <MenuItem key={h.id} value={h.id}>
                  {h.name}
                </MenuItem>
              );
            })}
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name={`${formKey}.bloodType`}
            label="Blood Type"
            component={TextField}
            select
            size="small"
            disabled={disableForm}
          >
            {Object.values(BloodTypeEnum).map((b) => {
              return (
                <MenuItem key={b} value={b}>
                  {b}
                </MenuItem>
              );
            })}
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Field
            name={`${formKey}.patientWeight`}
            label="Patient Weight (Kg)"
            component={TextField}
            size="small"
            type="number"
            min={0}
            inputProps={{
              type: "number",
              step: "0.01",
              min: "0",
            }}
            disabled={disableForm}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name={`${formKey}.primaryPhysicianName`}
            label="Name of Your Primary Physician"
            size="small"
            disabled={disableForm}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name={`${formKey}.primaryPhysicianPhone`}
            label="Phone of Your Primary Physician"
            size="small"
            disabled={disableForm}
          />
        </Grid>

        <Grid item xs={6}>
          <Field
            select
            size="small"
            component={TextField}
            label="Level of Emergency"
            name={`${formKey}.levelOfEmergency`}
            disabled={disableForm}
          >
            <MenuItem value={PatientLevelOfEmergencyEnum.LEVEL_1}>
              Level 1 - Most urgent
            </MenuItem>
            <MenuItem value={PatientLevelOfEmergencyEnum.LEVEL_2}>
              Level 2 - Less urgent (but still an emergency)
            </MenuItem>
            <MenuItem value={PatientLevelOfEmergencyEnum.LEVEL_3}>
              Level 3 - Least urgent (but still an emergency)
            </MenuItem>
            <MenuItem value={PatientLevelOfEmergencyEnum.BE_PRIORITY}>
              BahamasEvac Priority - not an emergency as such but Patient needs
              to get proper treatment within a reasonable time
            </MenuItem>
          </Field>
        </Grid>

        <Grid item xs={6}>
          <Field
            size="small"
            ampm={false}
            label="DD/MM/YYYY 00:00"
            component={FormikDateTimePicker}
            typingFormat="dd/MM/yyyy HH:mm"
            displayFormat="dd/MM/yyyy HH:mm"
            name={`${formKey}.preferredDestinationArrivalTime`}
            placeholder="Preferred Date and Time of Arrival at Destination *"
            invalidDateMessage=""
            disabled={disableForm}
          />
        </Grid>

        <Grid item xs={6}>
          <Field
            required
            multiline
            maxRows={4}
            component={TextField}
            label="Medical Needs of Patient"
            name={`${formKey}.medicalNeeds`}
            size="small"
            disabled={disableForm}
          />
        </Grid>

        <Grid item xs={6} className={classes.checkboxes}>
          <Field
            size="small"
            color="primary"
            type="checkbox"
            disabled={disableForm}
            component={CheckboxWithLabel}
            name={`${formKey}.canFlyCommercial`}
            Label={{
              label: (
                <Typography className={classes.checkboxLabel}>
                  Patient can fly commercially
                </Typography>
              ),
            }}
          />

          {patient.canFlyCommercial && (
            <Field
              size="small"
              color="primary"
              type="checkbox"
              disabled={disableForm}
              name={`${formKey}.medicalEscort`}
              component={CheckboxWithLabel}
              Label={{
                label: (
                  <Typography className={classes.checkboxLabel}>
                    Requires Medical Escort on the Commercial Flight
                  </Typography>
                ),
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
