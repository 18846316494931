import React from "react";
import { format } from "date-fns";
import { Theme, TableRow, TableCell, makeStyles } from "@material-ui/core";
import {
  DispatcherActivityLog,
  DispatcherActivityLogTypeEnum,
} from "@deep-consulting-solutions/be2-constants";

interface StyleProps {
  type: DispatcherActivityLogTypeEnum;
}

const formatDate = (dateString?: string) => {
  if (!dateString) return "--";

  return format(new Date(dateString), "dd MMM yyyy hh:mm aa");
};

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  logType: ({ type }) => ({
    color:
      // eslint-disable-next-line no-nested-ternary
      type === DispatcherActivityLogTypeEnum.LATE_PASSED_CHECK_IN
        ? " #ED6C02"
        : type === DispatcherActivityLogTypeEnum.FAILED_CHECK_IN
        ? "#CA2027"
        : "black",
  }),
}));

export const LogEntry = ({
  type,
  entryTime,
  description,
  checkInTime,
}: DispatcherActivityLog) => {
  const classes = useStyles({ type });

  return (
    <TableRow>
      <TableCell className={classes.logType}>{type}</TableCell>
      <TableCell>{formatDate(entryTime)}</TableCell>
      <TableCell
        style={{ whiteSpace: "pre-wrap" }}
        dangerouslySetInnerHTML={{ __html: description.replace("/n", "\n") }}
      />
      <TableCell align="right">{formatDate(checkInTime)}</TableCell>
    </TableRow>
  );
};
