import {
  ServiceCaseResponse,
  ServiceCaseStatusGroupEnum,
  ServiceCaseResponseExtended,
} from "@deep-consulting-solutions/be2-constants";
import {
  getStatusGroup,
  serviceCaseNeedsUpdate,
} from "pages/FulfilmentDashboard/helpers";
import { FilterStateType } from "./request";

export const updateStatusGroup = (
  group: FilterStateType,
  serviceCase: ServiceCaseResponseExtended
) => {
  return {
    ...group,
    count: group.count + 1,
    data: {
      ...group.data,
      total: group.data.total + 1,
      serviceCases: [serviceCase].concat(group.data.serviceCases),
    },
  };
};

export const updateAllGroup = (
  group: FilterStateType,
  serviceCase: ServiceCaseResponseExtended,
  modifiedFields: Array<keyof ServiceCaseResponse>
) => {
  const serviceCaseExists = group.data.serviceCases.find(
    ({ id }) => id === serviceCase.id
  );

  const newServiceCaseStatus = getStatusGroup(serviceCase.caseStatus);

  if (
    serviceCaseExists &&
    newServiceCaseStatus === ServiceCaseStatusGroupEnum.IN_PROGRESS
  ) {
    return {
      ...group,
      count: Math.max(group.count - 1, 0),
      data: {
        ...group.data,
        serviceCases: group.data.serviceCases.filter(
          ({ id }) => id !== serviceCase.id
        ),
      },
    };
  }

  if (
    !serviceCaseExists &&
    (newServiceCaseStatus === ServiceCaseStatusGroupEnum.NEW ||
      newServiceCaseStatus === ServiceCaseStatusGroupEnum.SCHEDULED)
  ) {
    return {
      ...group,
      count: group.count + 1,
      data: {
        ...group.data,
        serviceCases: [
          {
            ...serviceCase,
            isNew: true,
          } as unknown as ServiceCaseResponseExtended,
        ].concat(group.data.serviceCases),
      },
    };
  }

  if (
    serviceCaseExists &&
    (newServiceCaseStatus === ServiceCaseStatusGroupEnum.NEW ||
      newServiceCaseStatus === ServiceCaseStatusGroupEnum.SCHEDULED) &&
    serviceCaseNeedsUpdate(serviceCaseExists, serviceCase, modifiedFields)
  ) {
    return {
      ...group,
      data: {
        ...group.data,
        serviceCases: group.data.serviceCases.map((existingServiceCase) =>
          existingServiceCase.id === serviceCase.id
            ? {
                ...existingServiceCase,
                ...serviceCase,
              }
            : existingServiceCase
        ),
      },
    };
  }

  return group;
};

export const updateSpecialGroup = (
  group: FilterStateType,
  serviceCase: ServiceCaseResponseExtended,
  groupType: ServiceCaseStatusGroupEnum,
  modifiedFields: Array<keyof ServiceCaseResponse>
): FilterStateType => {
  const statusGroup = getStatusGroup(serviceCase.caseStatus);
  const existingServiceCase = group.data.serviceCases.find(
    (element) => element.id === serviceCase.id
  );

  if (existingServiceCase && statusGroup !== groupType) {
    return {
      ...group,
      count: group.count - 1,
      data: {
        ...group.data,
        serviceCases: group.data.serviceCases.filter(
          ({ id }) => id !== serviceCase.id
        ),
      },
    };
  }

  if (!existingServiceCase && statusGroup === groupType) {
    return {
      ...group,
      count: group.count + 1,
      data: {
        ...group.data,
        serviceCases: [serviceCase].concat(group.data.serviceCases),
      },
    };
  }

  if (
    existingServiceCase &&
    statusGroup === groupType &&
    serviceCaseNeedsUpdate(existingServiceCase, serviceCase, modifiedFields)
  ) {
    return {
      ...group,
      data: {
        ...group.data,
        serviceCases: group.data.serviceCases.map((element) =>
          element.id === serviceCase.id ? serviceCase : element
        ),
      },
    };
  }

  return group;
};
