import React, { useCallback, useMemo } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { Call } from "call/types";
import { useCall } from "../../hooks";
import { ActiveCallComponent } from "../ActiveCallComponent";
import { CallOnHoldComponent } from "../CallOnHoldComponent";
import { CollapsedCalls } from "./CollapsedCalls";

const useStyles = makeStyles(({ spacing: s }) => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "space-between",
    padding: s(0, 2),
  },
  displayWrapper: {
    flex: 1,
  },
}));

export const OngoingCallsComponent = () => {
  const { ongoingCalls, activeCall } = useCall();

  const { numberOfCallsToDisplay, callsToDisplay, callsToCollapse } =
    useMemo(() => {
      const res = {
        numberOfCallsToDisplay: ongoingCalls.length,
        callsToDisplay: ongoingCalls,
        callsToCollapse: [] as Call[],
      };
      if (ongoingCalls.length >= 4 && activeCall?.sessionId) {
        res.numberOfCallsToDisplay = 3;
        res.callsToDisplay = ongoingCalls.slice(ongoingCalls.length - 3);
        res.callsToCollapse = ongoingCalls.slice(0, ongoingCalls.length - 3);
      }
      if (ongoingCalls.length >= 4 && !activeCall?.sessionId) {
        res.numberOfCallsToDisplay = 4;
        res.callsToDisplay = ongoingCalls.slice(ongoingCalls.length - 4);
        res.callsToCollapse = ongoingCalls.slice(0, ongoingCalls.length - 4);
      }

      return res;
    }, [ongoingCalls, activeCall?.sessionId]);

  const getVisibleItemsXs = useCallback(
    (sessionId: string) => {
      if (numberOfCallsToDisplay === 1) {
        return 12;
      }
      if (numberOfCallsToDisplay === 2 && !activeCall?.sessionId) {
        return 6;
      }
      if (numberOfCallsToDisplay === 2 && sessionId === activeCall?.sessionId) {
        return 7;
      }
      if (numberOfCallsToDisplay === 2 && sessionId !== activeCall?.sessionId) {
        return 5;
      }
      if (numberOfCallsToDisplay === 3 && !activeCall?.sessionId) {
        return 4;
      }
      if (numberOfCallsToDisplay === 3 && sessionId === activeCall?.sessionId) {
        return 6;
      }
      if (numberOfCallsToDisplay === 3 && sessionId !== activeCall?.sessionId) {
        return 3;
      }

      if (numberOfCallsToDisplay > 3 && !activeCall?.sessionId) {
        return 3;
      }
      if (numberOfCallsToDisplay > 3 && sessionId !== activeCall?.sessionId) {
        return 3;
      }
      if (numberOfCallsToDisplay > 3 && sessionId === activeCall?.sessionId) {
        return 6;
      }

      return 3;
    },
    [activeCall?.sessionId, numberOfCallsToDisplay]
  );

  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <CollapsedCalls callsToCollapse={callsToCollapse} />
      <div className={classes.displayWrapper}>
        <Grid container spacing={1} direction="row" alignItems="stretch">
          {callsToDisplay.map((ongoingCall) => (
            <Grid
              item
              key={ongoingCall.sessionId}
              xs={getVisibleItemsXs(ongoingCall.sessionId)}
            >
              {ongoingCall.sessionId === activeCall?.sessionId ? (
                <ActiveCallComponent />
              ) : (
                <CallOnHoldComponent call={ongoingCall} />
              )}
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};
