import {
  PatientCoverageEnum,
  SubscriptionStatusEnum,
  PatientSubCoverageDB,
  PatientReasonForNonCoverageEnum,
  ServiceTypesEnum,
} from "@deep-consulting-solutions/be2-constants";
import { format } from "date-fns";
import {
  Button,
  Grid,
  makeStyles,
  Radio,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import { InfoOutlined } from "@material-ui/icons";
import { Status } from "../Status";
import {
  getIsServiceToBeRenderedCustom,
  getIsServiceToBeRenderedInformationalSupport,
} from "../../helpers";

const useStyles = makeStyles(({ palette: p }) => ({
  info: {
    color: "#828282",
    display: "block",
    fontSize: 10,
    fontWeight: 400,
  },
  tableCell: {
    fontWeight: 400,
    color: p.primary.dark,
    textAlign: "center",
    display: "block",
    fontSize: 12,
  },
}));

interface Props {
  coverage: PatientSubCoverageDB;
  handlePayment: (subscriptionID: string) => void;
  selectedCoverage?: string;
  handleCoverageChange: (coverageId: string, coverageStatus: string) => void;
  service: ServiceTypesEnum;
  disableForm: boolean;
}

export const PatientCardRow = ({
  coverage,
  handlePayment,
  selectedCoverage,
  handleCoverageChange,
  service,
  disableForm,
}: Props) => {
  const {
    isServiceToBeRenderedCustom,
    isServiceToBeRenderedInformationalSupport,
  } = useMemo(() => {
    return {
      isServiceToBeRenderedCustom: getIsServiceToBeRenderedCustom(service),
      isServiceToBeRenderedInformationalSupport:
        getIsServiceToBeRenderedInformationalSupport(service),
    };
  }, [service]);

  const {
    type,
    createdOn,
    plans,
    status,
    coverageStatus,
    coverageInfo,
    membership,
    subscriptionID,
    isPaymentRequired,
  } = useMemo(() => {
    return {
      type: coverage.subscription?.isDependent
        ? "Dependent"
        : "Main Beneficiary",
      createdOn: coverage.subscription?.dependentAddedDate
        ? format(
            new Date(coverage.subscription.dependentAddedDate),
            "dd MMM yyyy"
          )
        : "",
      status: coverage.subscription?.status,
      plans: coverage.subscription?.plans || [],
      coverageStatus: isServiceToBeRenderedCustom
        ? PatientCoverageEnum.NOT_COVERED
        : coverage.coverage || "",
      coverageInfo: coverage.reasonForNonCoverage,
      membership: coverage.subscription?.membershipNumber || "",
      isPaymentRequired:
        coverage.subscription?.status === SubscriptionStatusEnum.SUSPENDED &&
        coverage.reasonForNonCoverage ===
          PatientReasonForNonCoverageEnum.SUSPENDED_SUBSCRIPTION,
      subscriptionID: coverage.subscription?.id || "",
    };
  }, [coverage, isServiceToBeRenderedCustom]);

  const handlePayNow = useCallback(() => {
    handlePayment(subscriptionID);
  }, [handlePayment, subscriptionID]);

  const handleRadioChange = useCallback(
    (_, checked: boolean) => {
      handleCoverageChange(
        checked ? coverage.id : "",
        checked ? coverageStatus : ""
      );
    },
    [handleCoverageChange, coverage.id, coverageStatus]
  );

  const classes = useStyles();

  return (
    <TableRow>
      <TableCell size="small">
        <Radio
          color="primary"
          disabled={isServiceToBeRenderedInformationalSupport || disableForm}
          checked={selectedCoverage === coverage.id}
          value={coverage.id}
          onChange={handleRadioChange}
        />
      </TableCell>
      <TableCell>
        <Typography
          className={classes.tableCell}
          align="center"
          color="primary"
          variant="caption"
        >
          {membership}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          className={classes.tableCell}
          align="center"
          color="primary"
          variant="caption"
        >
          {type}
        </Typography>
        {type === "Dependent" && (
          <Typography align="center" variant="caption" className={classes.info}>
            Added on {createdOn}
          </Typography>
        )}
      </TableCell>
      <TableCell>
        <Status value={status} />
      </TableCell>
      <TableCell>
        <Typography
          className={classes.tableCell}
          align="center"
          color="primary"
          variant="caption"
        >
          {plans.join(", ")}
        </Typography>
      </TableCell>
      <TableCell>
        <Grid
          container
          direction="column"
          spacing={1}
          wrap="nowrap"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <Typography
              className={classes.tableCell}
              align="center"
              color="primary"
              variant="caption"
            >
              {coverageStatus}
            </Typography>
          </Grid>
          {coverageInfo ? (
            <Grid item>
              <Typography
                align="center"
                variant="caption"
                className={classes.info}
              >
                ({coverageInfo})
              </Typography>
            </Grid>
          ) : null}
          {isPaymentRequired && (
            <Grid item>
              <Button
                onClick={handlePayNow}
                color="primary"
                disabled={disableForm}
              >
                PAY NOW
              </Button>
            </Grid>
          )}
        </Grid>
      </TableCell>
      {isPaymentRequired && (
        <TableCell size="small">
          <Tooltip title="This subscription can be reactivated by paying the overdue fees. You can ask the requester if he can make the payment now.">
            <InfoOutlined fontSize="small" color="primary" />
          </Tooltip>
        </TableCell>
      )}
    </TableRow>
  );
};
