import { Field, FormikProps } from "formik";
import ReactDOMServer from "react-dom/server";
import { TextField } from "formik-material-ui";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { LocationTypeEnum } from "@deep-consulting-solutions/be2-constants";
import {
  LocationOn,
  KeyboardArrowUp,
  KeyboardArrowLeft,
} from "@material-ui/icons";
import {
  Box,
  SvgIcon,
  Collapse,
  MenuItem,
  IconButton,
  makeStyles,
} from "@material-ui/core";

import progressDot from "images/progressDot.svg";
import { ReactComponent as MyLocation } from "images/myLocation.svg";
import {
  useMap,
  destinationMarkerTitle,
  initialLocationMarkerTitle,
} from "call/context";

import { TabsEnum } from "../types";
import { tabsEnumToNumber } from "../helpers";
import { LocationSearch } from "../LocationForm/LocationSearch";
import { LocationSummary } from "../LocationForm/LocationSummary";
import { LocationInfoWindow } from "../LocationForm/LocationInfoWindow";
import type { FormFields, ItinerarySectionField } from "./PlanItinerary";
import { MedicalFacilityField } from "../LocationForm/MedicalFacilityField";

interface ItineraryLocationProps<T extends Record<string, any>> {
  index: number;
  disabled?: boolean;
  activeTab: TabsEnum;
  formikProps: FormikProps<T>;
  section: ItinerarySectionField;
}

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  gpsFixed: {
    width: "20px",
    height: "20px",
    fill: p.primary.dark,
  },
  locationTypeGroup: {
    display: "flex",
    marginBottom: s(2),
    alignItems: "center",

    "& > :not(:last-child)": {
      marginRight: s(2),
    },
  },
  textField: {
    width: "217px",
    height: "54px",

    "& .MuiFormLabel-root, & .MuiInputBase-root": {
      fontSize: 14,
    },
  },
  removeButton: {
    height: s(5),
    color: "white",
    backgroundColor: "#CA2027",
  },
  moreAddressDetails: {
    fontSize: 12,

    "& .MuiInputBase-input::placeholder": {
      fontSize: 12,
    },
  },
  toggleButton: {
    width: s(5),
    height: s(5),
    padding: s(1),
    marginLeft: s(1),
    borderRadius: s(0.5),
    backgroundColor: "#F6F6F6",
  },
}));

const ItineraryLocation = ({
  index,
  section,
  disabled,
  activeTab,
  formikProps,
}: ItineraryLocationProps<FormFields>) => {
  const classes = useStyles();
  const { isSubmitting } = formikProps;
  const [showDetails, setShowDetails] = useState(true);
  const { map, getMarker, createInfoWindow, updateMarkerPosition } = useMap();

  const { label, fieldName, isDestination, isInitialLocation } = useMemo(() => {
    let innerFieldName = "";
    let innerLabel = "";
    let innerIsFirstElement = true;

    if (index === 0) {
      innerLabel = "Initial Location";
      innerFieldName = `itinerarySections.initialLocation`;
    }

    if (index === 1) {
      innerLabel = "Destination";
      innerIsFirstElement = false;
      innerFieldName = `itinerarySections.destinationLocation`;
    }

    return {
      label: innerLabel,
      fieldName: innerFieldName,
      isDestination: !innerIsFirstElement,
      isInitialLocation: innerIsFirstElement,
    };
  }, [index]);

  const handleSelectChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const setFieldValue = formikProps.setFieldValue;

      setFieldValue(`${fieldName}.locationType`, e.target.value);

      setFieldValue(`${fieldName}.locationMedicalFacility`, null);
      setFieldValue(`${fieldName}.customLocation`, "");
      setFieldValue(`${fieldName}.location`, {
        lat: 0,
        lng: 0,
        dms: "",
        address: "",
        plusCode: "",
      });
    },
    [fieldName, formikProps.setFieldValue]
  );

  const title = useMemo(() => {
    if (isInitialLocation) {
      return initialLocationMarkerTitle;
    }

    if (isDestination) {
      return destinationMarkerTitle;
    }

    return `Location ${index + 1}`;
  }, [index, isDestination, isInitialLocation]);

  const infoWindow = useMemo(() => {
    let infoWindowTitle = "Initial Location";
    if (isDestination) infoWindowTitle = "Destination";

    if (tabsEnumToNumber(activeTab) >= tabsEnumToNumber(TabsEnum.PLANNING)) {
      return createInfoWindow({
        content: ReactDOMServer.renderToString(
          <LocationInfoWindow
            showAddress
            title={infoWindowTitle}
            location={section.location}
          />
        ),
      });
    }
  }, [activeTab, isDestination, createInfoWindow, section.location]);

  useEffect(() => {
    const marker = getMarker(title);

    if (marker && infoWindow) {
      const event = marker.addListener("click", () => {
        infoWindow.open(map, marker);
      });

      return () => {
        event.remove();
        infoWindow.close();
        google?.maps.event.clearListeners(marker, "click");
      };
    }
  }, [map, title, getMarker, infoWindow]);

  useEffect(() => {
    if (tabsEnumToNumber(activeTab) >= tabsEnumToNumber(TabsEnum.PLANNING)) {
      const { lat, lng } = section.location;
      let position;

      if (
        lat &&
        lng &&
        !Number.isNaN(Number(lat)) &&
        !Number.isNaN(Number(lng))
      ) {
        position = { lat, lng };
      }

      if (position) {
        if (isInitialLocation) {
          updateMarkerPosition("initialLocation", position);
        }

        if (isDestination) {
          updateMarkerPosition("destination", position);
        }
      }
    }
  }, [
    title,
    activeTab,
    isDestination,
    section.location,
    isInitialLocation,
    updateMarkerPosition,
  ]);

  useEffect(() => {
    const { lat, lng } = section.location;

    if (
      lat &&
      lng &&
      !Number.isNaN(Number(lat)) &&
      !Number.isNaN(Number(lng))
    ) {
      updateMarkerPosition(title, { lat, lng });
    }
  }, [title, section.location, updateMarkerPosition]);

  return (
    <Box mb={3}>
      <Box display="flex" mb={1}>
        <Box height="inherit">
          <Box
            width={32}
            height={32}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {isInitialLocation || isDestination ? (
              <LocationOn
                width={32}
                height={32}
                style={{
                  color: isInitialLocation ? "#CA2027" : "#08A40F",
                }}
              />
            ) : (
              <SvgIcon
                width={20}
                height={20}
                component={MyLocation}
                className={classes.gpsFixed}
              />
            )}
          </Box>

          {!isDestination && (
            <Box
              style={{
                overflow: "hidden",
                height: "calc(100% - 32px)",
                background: `url(${progressDot}) repeat-y center 10px`,
              }}
            >
              &nbsp;
            </Box>
          )}
        </Box>

        <Box>
          <Box className={classes.locationTypeGroup}>
            <Field
              select
              required
              size="small"
              component={TextField}
              label={`${label} Type`}
              value={section.locationType}
              className={classes.textField}
              name={`${fieldName}.locationType`}
              disabled={isInitialLocation || isSubmitting || disabled}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleSelectChange(e)
              }
              SelectProps={{
                displayEmpty: true,
              }}
            >
              {Object.entries(LocationTypeEnum).map(([key, value]) => (
                <MenuItem key={key} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Field>

            {section.locationType === LocationTypeEnum.CUSTOM && (
              <Field
                required
                size="small"
                label="Custom Type"
                component={TextField}
                placeholder="Custom Type"
                disabled={isInitialLocation || isSubmitting || disabled}
                name={`${fieldName}.customLocation`}
                className={classes.textField}
                style={{ marginRight: 0 }}
              />
            )}

            {section.locationType === LocationTypeEnum.MEDICAL_FACILITY && (
              <MedicalFacilityField<FormFields>
                formikProps={formikProps}
                fieldName={`${fieldName}.location`}
                disabled={isInitialLocation || disabled}
                name={`${fieldName}.locationMedicalFacility`}
              />
            )}
          </Box>

          <Box display="flex" alignItems="center">
            <Box width={450}>
              <LocationSearch
                label={label}
                markerTitle={title}
                formikProps={formikProps}
                name={`${fieldName}.location`}
                disabled={isInitialLocation || disabled}
              />
            </Box>

            {section.location.dms && (
              <IconButton
                className={classes.toggleButton}
                onClick={() => setShowDetails(!showDetails)}
              >
                {showDetails ? <KeyboardArrowUp /> : <KeyboardArrowLeft />}
              </IconButton>
            )}
          </Box>

          <Collapse in={showDetails && !!section.location.dms}>
            <Box
              mt={1}
              width={450}
              display="flex"
              justifyContent="space-between"
            >
              <Box>
                <LocationSummary location={section.location} />
              </Box>

              <Box>
                <Field
                  size="small"
                  variant="outlined"
                  component={TextField}
                  placeholder="More Address Details"
                  className={classes.moreAddressDetails}
                  name={`${fieldName}.moreLocationDetails`}
                  disabled={isInitialLocation || isSubmitting || disabled}
                />
              </Box>
            </Box>
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
};

export default ItineraryLocation;
