import React from "react";
import { US_STATES } from "configs";
import { Field, FormikProps, getIn } from "formik";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
} from "formik-material-ui-lab";
import TextField from "@material-ui/core/TextField";

interface StateFieldProps {
  formikProps: FormikProps<any>;
  // eslint-disable-next-line react/require-default-props
  name?: string;
  disabled?: boolean;
  use2Stars?: boolean;
}

export const StateField = ({
  formikProps: { errors, touched },
  name = "islandOrStateOrRegion",
  use2Stars,
}: StateFieldProps) => {
  const stateError: string | undefined = getIn(errors, name);
  const showStateError: boolean = getIn(touched, name) && !!stateError;
  return (
    <Field
      name={name}
      component={Autocomplete}
      options={US_STATES}
      getOptionLabel={(option: string) => option}
      style={{ width: "100%" }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          name={name}
          required={!use2Stars}
          error={showStateError}
          label={use2Stars ? "State **" : "State"}
          helperText={showStateError && stateError}
        />
      )}
    />
  );
};
