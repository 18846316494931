import { Grid, makeStyles, Typography } from "@material-ui/core";
import { ReactNode } from "react";

const useStyles = makeStyles(() => ({
  rowText: {
    color: "#4F4F4F",
  },
  rowTextBold: {
    color: "#4F4F4F",
    fontWeight: 700,
  },
}));

export const Item = ({
  title,
  value,
}: {
  title?: string;
  value?: string | ReactNode;
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={6}>
      <Grid container spacing={2} wrap="nowrap" direction="row">
        {!!title && (
          <Grid item>
            <Typography variant="caption" className={classes.rowText}>
              {title}:
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Typography variant="caption" className={classes.rowTextBold}>
            {value}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
