import { ServiceCaseStatusEnum } from "@deep-consulting-solutions/be2-constants";
import { DashboardMockCallContext } from "dashboard-mock-call";
import { useContext, useMemo } from "react";
import { CallContext } from "../context";

export type CallSource = "call" | "dashboard";

export const useCall = () => {
  const call = useContext(CallContext);
  const dashboardMockCall = useContext(DashboardMockCallContext);

  const { activeCall, ringingCalls, ongoingCalls, callsOnHold } =
    useMemo(() => {
      const aC = call.calls.find((c) => c.sessionId === call.activeCallSession);
      return {
        activeCall: aC,
        ringingCalls: call.calls.filter((c) => c.isRinging),
        ongoingCalls: call.calls
          .filter((c) => !c.isRinging && c.sessionId !== call.activeCallSession)
          .concat(aC ? [aC] : []),
        callsOnHold: call.calls.filter((c) => c.isOnHold),
      };
    }, [call.activeCallSession, call.calls]);

  const activeCallServiceCase = useMemo(() => {
    return activeCall?.updatedServiceCase || null;
  }, [activeCall]);

  const isOnCall = useMemo(
    () => !!call.activeCallSession,
    [call.activeCallSession]
  );

  const viewedCall = useMemo(() => {
    const vCall = call.calls.find((c) => c.sessionId === call.viewedCall);
    if (vCall && vCall.updatedServiceCase) {
      return {
        ...vCall,
        disableForms: [
          ServiceCaseStatusEnum.CLOSED,
          ServiceCaseStatusEnum.REFUSED,
          ServiceCaseStatusEnum.RE_OPENED,
          ServiceCaseStatusEnum.COMPLETED,
          ServiceCaseStatusEnum.CANCELLED,
        ].includes(vCall.updatedServiceCase.caseStatus),
      };
    }
    return vCall;
  }, [call.viewedCall, call.calls]);

  const showEndCallRequestDialog = useMemo(
    () => !!call.endCallSessionRequest,
    [call.endCallSessionRequest]
  );

  const showPickCallRequestDialog = useMemo(
    () => !!call.pickCallRequestId,
    [call.pickCallRequestId]
  );

  const chatRecordings = useMemo(
    () =>
      viewedCall?.sessionId && call.chatMessages
        ? call.chatMessages[viewedCall?.sessionId]?.filter(
            (chat) => chat.type === "recording"
          ) || []
        : [],
    [call.chatMessages, viewedCall?.sessionId]
  );

  const chatMessages = useMemo(
    () =>
      viewedCall?.sessionId && call.chatMessages
        ? call.chatMessages[viewedCall?.sessionId] || []
        : [],
    [call.chatMessages, viewedCall?.sessionId]
  );

  const chatMedia = useMemo(
    () =>
      viewedCall?.sessionId && call.chatMessages
        ? call.chatMessages[viewedCall?.sessionId]?.filter(
            (chat) => chat.type === "media" || (chat.type as string) === "image"
          ) || []
        : [],
    [call.chatMessages, viewedCall?.sessionId]
  );

  const viewedDashboardMockCall = useMemo(() => {
    const vCall = dashboardMockCall.call;
    if (vCall && vCall.updatedServiceCase) {
      return {
        ...vCall,
        disableForms: [
          ServiceCaseStatusEnum.CLOSED,
          ServiceCaseStatusEnum.REFUSED,
          ServiceCaseStatusEnum.RE_OPENED,
          ServiceCaseStatusEnum.COMPLETED,
          ServiceCaseStatusEnum.CANCELLED,
        ].includes(vCall.updatedServiceCase.caseStatus),
      };
    }
    return vCall;
  }, [dashboardMockCall.call]);

  return {
    ...call,
    ...(!isOnCall ? dashboardMockCall : {}),
    viewedCallSessionId: isOnCall
      ? call.viewedCall
      : dashboardMockCall.viewedCall,
    isOnCall,
    activeCall,
    activeCallServiceCase,
    ringingCalls,
    showEndCallRequestDialog,
    showPickCallRequestDialog,
    ongoingCalls,
    callsOnHold,
    viewedCall: isOnCall ? viewedCall : viewedDashboardMockCall,
    chatMedia,
    chatRecordings,
    callSource: (isOnCall ? "call" : "dashboard") as CallSource,
    chatMessages,
  };
};
