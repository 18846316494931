import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getActiveCalls } from "redux/call/requests";
import {
  CallAndServiceCaseData,
  Dispatcher,
} from "@deep-consulting-solutions/be2-constants";
import * as dispatcherRequests from "./requests";

import type { RootState } from "../reducers";

const ENTITY = "dispatcher";

export enum DisplayMode {
  // Default = "Default",
  StatusGroup = "Status Group",
  RequestType = "Request Type",
  Priority = "Priority",
  MedicalDirector = "Medical Director",
  List = "List",
}

export enum DisplayLayout {
  horizontal = "horizontal",
  vertical = "vertical",
}

export interface DispatcherState {
  id: string;
  zohoID: string;
  type: string;
  firstName: string;
  lastName: string;
  name: string;
  onDuty: boolean;
  activeCalls: any[];
  displayMode: null | DisplayMode;
  displayLayout: null | DisplayLayout;
  serviceCaseUpdated: string | null;
  medicalDirectorID: string;
  dispatcherID: string;
}

const initialState: DispatcherState = {
  id: "",
  zohoID: "",
  type: "",
  firstName: "",
  lastName: "",
  name: "",
  onDuty: false,
  activeCalls: [],
  displayMode: null,
  displayLayout: DisplayLayout.vertical,
  serviceCaseUpdated: null,
  medicalDirectorID: "",
  dispatcherID: "",
};

const fetchDispatcherActivityRecord = createAsyncThunk<
  | (Dispatcher & {
      activeCalls: CallAndServiceCaseData[];
    })
  | undefined,
  undefined,
  { state: { dispatcher: DispatcherState } }
>(`${ENTITY}/fetchLogs`, async (__, { getState }) => {
  const isDispatcher = !!getState().dispatcher.dispatcherID;

  if (!isDispatcher) {
    return undefined;
  }
  const res = await getActiveCalls();
  return res;
});

const getRoles = createAsyncThunk(`${ENTITY}/getRoles`, async () => {
  const res = await dispatcherRequests.fetchRoles();
  return res;
});

const slice = createSlice({
  name: ENTITY,
  initialState,
  reducers: {
    toggleOnDuty: (state) => {
      state.onDuty = !state.onDuty;
    },
    setDisplayMode: (state, action: PayloadAction<DisplayMode | null>) => {
      state.displayMode = action.payload;
    },
    setDisplayLayout: (state) => {
      state.displayLayout =
        state.displayLayout === DisplayLayout.horizontal
          ? DisplayLayout.vertical
          : DisplayLayout.horizontal;
    },
    setServiceCaseUpdated: (state) => {
      state.serviceCaseUpdated = new Date().getTime().toString();
    },
  },
  extraReducers: (builders) =>
    builders
      .addCase(
        fetchDispatcherActivityRecord.fulfilled,
        (state, action: PayloadAction<any>) => {
          return { ...state, ...action.payload };
        }
      )
      .addCase(getRoles.fulfilled, (state, action) => {
        const [medicalDirectorID, dispatcherID] = action.payload;

        state.dispatcherID = dispatcherID;
        state.medicalDirectorID = medicalDirectorID;
      }),
});

export const dispatcherActions = {
  ...slice.actions,
  getRoles,
  fetchDispatcherActivityRecord,
};

export { dispatcherRequests };

export const dispatcherSelectors = {
  id: (state: RootState) => state.dispatcher.id,
  zohoID: (state: RootState) => state.dispatcher.zohoID,
  onDuty: (state: RootState) => state.dispatcher.onDuty,
  dispatcherID: (state: RootState) => state.dispatcher.dispatcherID,
  medicalDirectorID: (state: RootState) => state.dispatcher.medicalDirectorID,
  serviceCaseUpdated: (state: RootState) => state.dispatcher.serviceCaseUpdated,
  roles: (state: RootState) => ({
    isDispatcher: !!state.dispatcher.dispatcherID,
    isMedicalDirector: !!state.dispatcher.medicalDirectorID,
  }),
};

export default slice.reducer;
