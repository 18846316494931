import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";

export enum LocationAlertStatusEnum {
  IDLE = "idle",
  ERROR = "error",
  SUCCESS = "success",
  LOADING = "loading",
  TIMEOUT = "timeout",
}

interface BaseProps {
  status: LocationAlertStatusEnum;
}

interface ErrorAlertProps extends BaseProps {
  location?: string;
  errorMessage: string;
  locationSyncTime: number;
}

interface TimeoutAlertProps extends BaseProps {
  location?: string;
  errorMessage: string;
  locationSyncTime: number;
}

interface LoadingAlertProps extends BaseProps {
  location?: string;
  errorMessage: string;
  syncWithLiveLocation: boolean;
}

interface SuccessAlertProps extends BaseProps {
  location?: string;
  errorMessage: string;
  locationSyncTime: number;
}

const useStyles = makeStyles(({ spacing: s }) => ({
  alert: {
    marginBottom: s(2),
    justifyContent: "space-between",

    "& .MuiAlert-action": {
      marginLeft: "unset",
    },
  },
}));

export function LocationAlert(props: ErrorAlertProps): React.ReactElement;
export function LocationAlert(props: SuccessAlertProps): React.ReactElement;
export function LocationAlert(props: LoadingAlertProps): React.ReactElement;
export function LocationAlert(props: TimeoutAlertProps): React.ReactElement;
export function LocationAlert(
  props:
    | ErrorAlertProps
    | SuccessAlertProps
    | LoadingAlertProps
    | TimeoutAlertProps
) {
  const { status } = props;
  const classes = useStyles();
  const [showAlert, setShowAlert] = useState(true);
  const [showPreviousLocation, setShowPreviousLocation] = useState(true);

  useEffect(() => {
    setShowAlert(true);
    setShowPreviousLocation(true);
  }, [status]);

  if (status === LocationAlertStatusEnum.LOADING) {
    const { syncWithLiveLocation } = props as LoadingAlertProps;

    if (!showAlert) return null;

    if (syncWithLiveLocation) {
      return (
        <Alert
          severity="info"
          className={classes.alert}
          onClose={() => {
            setShowAlert(false);
          }}
        >
          <strong>Syncing Requester&apos; Live Location</strong>
        </Alert>
      );
    }

    return (
      <Alert
        severity="info"
        className={classes.alert}
        onClose={() => {
          setShowAlert(false);
        }}
      >
        <strong>Retrieving Requester&apos;s GPS Location</strong>
      </Alert>
    );
  }

  if (status === LocationAlertStatusEnum.ERROR) {
    const { location, errorMessage, locationSyncTime } =
      props as ErrorAlertProps;

    return (
      <>
        {showAlert && (
          <Alert
            severity="error"
            className={classes.alert}
            onClose={() => {
              setShowAlert(false);
            }}
          >
            Error Encountered: <strong>{errorMessage}</strong>
          </Alert>
        )}

        {locationSyncTime && location && showPreviousLocation ? (
          <Alert
            severity="info"
            className={classes.alert}
            onClose={() => setShowPreviousLocation(false)}
          >
            <strong>
              Last reported Location: {location} at{" "}
              {format(new Date(locationSyncTime), "dd/MM/yyyy hh:mm a")}
            </strong>
          </Alert>
        ) : null}
      </>
    );
  }

  if (status === LocationAlertStatusEnum.SUCCESS) {
    const { locationSyncTime } = props as SuccessAlertProps;

    return (
      showAlert && (
        <Alert
          severity="success"
          className={classes.alert}
          onClose={() => {
            setShowAlert(false);
          }}
        >
          <strong>
            Location synced successfully:{" "}
            {format(new Date(locationSyncTime), "dd/MM/yyyy hh:mm a")}
          </strong>
        </Alert>
      )
    );
  }

  if (status === LocationAlertStatusEnum.TIMEOUT) {
    const { location, locationSyncTime } = props as TimeoutAlertProps;

    return (
      <>
        {showAlert && (
          <Alert
            severity="error"
            className={classes.alert}
            onClose={() => {
              setShowAlert(false);
            }}
          >
            Response timed out:{" "}
            <strong>No location information received after 2 min.</strong>
          </Alert>
        )}

        {location && locationSyncTime && showPreviousLocation && (
          <Alert
            severity="info"
            className={classes.alert}
            onClose={() => setShowPreviousLocation(false)}
          >
            <strong>
              Last reported Location: {location} at{" "}
              {format(new Date(locationSyncTime), "dd/MM/yyyy hh:mm a")}
            </strong>
          </Alert>
        )}
      </>
    );
  }

  return null;
}
