import {
  Dialog,
  DialogContent,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import React, { useCallback, useRef, useState } from "react";
import { Form, Formik, Field, FormikConfig, FormikProps } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";

import Loader from "component/Loader";

import { DialogTitleWithCloseButton } from "component/DialogTitleWithCloseButton";

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  label: {
    marginTop: s(2),
    marginBottom: s(2),
    color: p.primary.main,
    fontSize: 16,
    fontWeight: 500,
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    padding: s(2),
  },
  refuseBtn: {
    backgroundColor: "#ba3330",
    color: p.common.white,
    marginLeft: s(1),
    "&:hover": {
      backgroundColor: "#ba3330",
    },
  },
}));

interface FormValues {
  reason: string;
}

const validationSchema = Yup.object<FormValues>({
  reason: Yup.string().required("This is required"),
});

interface Props {
  open: boolean;
  onSubmit: (reason: string) => Promise<boolean>;
  onClose: () => any;
}

export const RefuseDialog: React.FC<Props> = ({ onSubmit, open, onClose }) => {
  const formRef = useRef<FormikProps<FormValues>>(null);
  const [loading, setLoading] = useState(false);

  const handleClose = useCallback(() => {
    formRef.current?.resetForm();
    onClose();
  }, [onClose]);

  const handleSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    async (values, helpers) => {
      helpers.setSubmitting(false);
      setLoading(true);
      const isDone = await onSubmit(values.reason);
      setLoading(false);
      if (isDone) handleClose();
    },
    [onSubmit, handleClose]
  );

  const classes = useStyles();

  return (
    <Formik<FormValues>
      onSubmit={handleSubmit}
      initialValues={{ reason: "" }}
      validationSchema={validationSchema}
      innerRef={formRef}
      validateOnMount
    >
      {({ isValid }) => {
        return (
          <Dialog open={open} maxWidth="sm" fullWidth>
            <Loader open={loading} />
            <Form>
              <DialogTitleWithCloseButton
                title="Refuse Service"
                onClose={handleClose}
              />
              <DialogContent>
                <Typography className={classes.label}>
                  Reason for Refusal
                </Typography>
                <Field
                  name="reason"
                  component={TextField}
                  required
                  label="Reason"
                  multiline
                  minRows={3}
                />
              </DialogContent>

              <div className={classes.actions}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  className={classes.refuseBtn}
                  type="submit"
                  disabled={!isValid}
                >
                  Refuse Service
                </Button>
              </div>
            </Form>
          </Dialog>
        );
      }}
    </Formik>
  );
};
