import React, { useMemo } from "react";
import { ReactComponent as VisaIcon } from "images/visa.svg";
import { ReactComponent as MasterIcon } from "images/master.svg";
import { ReactComponent as DiscoverIcon } from "images/discover.svg";
import { getCardIssuer } from "helpers";

export type RenderCardType =
  | "visa"
  | "mastercard"
  | "master"
  | "discover"
  | "maestro";

interface CardNumberProps {
  cardNumber: string;
  brand?: string;
}

interface BrandProps {
  brand: string;
  cardNumber?: string;
}

export const CreditCardLogo = ({
  cardNumber,
  brand,
  ...props
}: CardNumberProps | BrandProps) => {
  const style = { width: 37, height: 20 };
  const type = useMemo(() => {
    if (cardNumber) {
      return getCardIssuer(cardNumber);
    }
    return brand;
  }, [cardNumber, brand]);
  switch (type?.toLowerCase()) {
    case "visa":
    case "maestro":
      return <VisaIcon style={style} {...props} />;
    case "master":
    case "mastercard":
      return <MasterIcon style={style} {...props} />;
    case "discover":
      return <DiscoverIcon style={style} {...props} />;
    default:
      return null;
  }
};
