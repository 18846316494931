import { createTheme } from "@material-ui/core/styles";

export const TRANSITION = "0.2s";
export const COLOR_PAGE_BG = "#e5e9f2";

export default createTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        p: {
          margin: 0,
        },
      },
    },
  },
  typography: {
    h1: {
      fontWeight: 700,
      fontSize: 40,
      fontFamily: "'Raleway', sans-serif",
    },
    h2: {
      fontWeight: 500,
      fontSize: 36,
      fontFamily: "'Raleway', sans-serif",
    },
    h3: {
      fontWeight: 700,
      fontSize: 32,
      fontFamily: "'Raleway', sans-serif",
    },
    h4: {
      fontWeight: 600,
      fontSize: 24,
      fontFamily: "'Raleway', sans-serif",
    },
    h5: {
      fontWeight: 500,
      fontSize: 20,
      fontFamily: "'Raleway', sans-serif",
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: 18,
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: 14,
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
    },
    body2: {
      fontWeight: 400,
      fontSize: 14,
    },
    button: {
      fontWeight: 500,
      fontSize: 14,
    },
    caption: {
      fontWeight: 400,
      fontSize: 12,
    },
  },
  palette: {
    background: {
      default: "#F4F4F4",
      paper: "#FCFCFC",
    },
    primary: {
      main: "#263E7F",
      dark: "#192954",
      light: "#E3EAF3",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#DAA61C",
      dark: "#C99B1A",
      light: "#FCC221",
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#0277bd",
      dark: "#01579b",
      light: "#03a9f4",
    },
    success: {
      main: "#2e7d32",
      dark: "#1b5e20",
      light: "#4caf50",
      contrastText: "#fff",
    },
    error: {
      main: "#d32f2f",
      dark: "#c62828",
      light: "#ef5350",
    },
    warning: {
      main: "#FF9800",
      dark: "#e65100",
      light: "#ff9800",
    },
  },
  props: {
    MuiGrid: {
      spacing: 2,
    },
    MuiAppBar: {
      elevation: 0,
    },
    MuiSelect: {
      variant: "outlined",
      fullWidth: true,
    },
    MuiTextField: {
      fullWidth: true,
      variant: "outlined",
    },
    MuiButton: {
      variant: "contained",
      disableElevation: true,
    },
  },
  spacing: 8,
});
