import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useContext } from "react";
import { format } from "date-fns";

import { CaseClassificationsContext } from "context/CaseClassificationProvider";

import { getName } from "../helpers";

const formatDate = (date: Date) => {
  return format(date, "dd MMM yyyy");
};
const formatTime = (date: Date) => {
  return format(date, "hh:mm:ss aa");
};

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  wrapper: {
    borderRadius: s(1),
    padding: s(1),
  },
  infos: {
    border: `1px solid ${p.grey[500]}`,
    borderRadius: s(1),
    padding: s(1),
  },
  infoText: {
    color: p.grey[600],
    fontSize: 12,
    marginRight: s(1),
  },
  cbTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: p.primary.main,
    marginTop: s(2),
    marginBottom: s(2),
  },
  table: {
    borderRadius: s(1),
  },
  tableHead: {
    background: "#F6F6F6",
  },
  time: {
    color: p.grey[500],
  },
}));

export const PatientPhysician = () => {
  const { state: serviceCase } = useContext(CaseClassificationsContext);

  const classes = useStyles();

  const renderItem = (key: string, value: string) => {
    return (
      <Grid item xs={6}>
        <Typography className={classes.infoText}>
          {key}: <b>{value}</b>
        </Typography>
      </Grid>
    );
  };

  return (
    <Paper className={classes.wrapper}>
      <div className={classes.infos}>
        <Grid container>
          {renderItem(
            "Name",
            serviceCase?.requestingPhysician
              ? `${serviceCase.requestingPhysician.firstName} ${serviceCase.requestingPhysician.lastName}`
              : "-"
          )}
          {renderItem("Phone", serviceCase?.requestingPhysician?.phone || "-")}
          {renderItem("Email", serviceCase?.requestingPhysician?.email || "-")}
        </Grid>
      </div>

      <Typography className={classes.cbTitle}>
        Call Back Attempts ({serviceCase?.callBackLogs.length || 0})
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead}>Caller</TableCell>
              <TableCell className={classes.tableHead}>
                Time of the Attempt
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(serviceCase?.callBackLogs || []).map((log) => {
              return (
                <TableRow key={log.id}>
                  <TableCell>
                    <Typography variant="body2">
                      {log.medicalDirector
                        ? `${getName(log.medicalDirector)} (Medical Director)`
                        : `${getName(log.dispatcher)} (Dispatcher)`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {formatDate(new Date(log.callBackTime))}{" "}
                      <span className={classes.time}>
                        {formatTime(new Date(log.callBackTime))}
                      </span>
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
