import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import { Field, Form, Formik, FormikConfig, FormikProps } from "formik";
import { KeyboardArrowLeft, KeyboardArrowUp } from "@material-ui/icons";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Hospital,
  Itinerary,
  LocationTypeEnum,
  PatientCoverageEnum,
  ServiceCaseStatusEnum,
} from "@deep-consulting-solutions/be2-constants";
import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";

import { useCall } from "call/hooks";
import Loader from "component/Loader";
import {
  getItinerarySections,
  startOrScheduleServiceCase,
} from "redux/call/requests";
import {
  getLocationItems,
  getLocationFromGeoCodeResult,
} from "helpers/locations";

import { useMap } from "call/context";
import { LocationField } from "../LocationForm";
import ItineraryLocation from "./ItineraryLocation";
import { SubCoveragePaymentDialog } from "./SubCoveragePaymentDialog";
import {
  earlyValidationSchema,
  getSubmitAction,
  mapItinerarySectionsToLocations,
} from "./helpers";
import { TabsEnum } from "../types";

export interface ItinerarySectionField {
  customLocation: string;
  location: LocationField;
  moreLocationDetails: string;
  locationType: LocationTypeEnum;
  locationMedicalFacility: Hospital | null;
}

export interface FormFields {
  assignedStaff?: string;
  paymentDetails?: string;
  itineraryDetails?: string;
  confirmPaymentReceived?: boolean;
  itinerarySections: {
    initialLocation: ItinerarySectionField;
    destinationLocation: ItinerarySectionField;
  };
}

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  title: {
    fontWeight: 500,
    marginBottom: s(3),
    color: p.primary.main,
    fontFamily: "'Roboto', sans-serif",
  },
  wrapper: {
    padding: s(4.25, 0),
  },
  alert: {
    marginBottom: s(2),
    backgroundColor: p.primary.main,
  },
  fieldset: {
    border: "none",
    marginBottom: s(3),
  },
  headerIconButton: {
    width: s(3),
    height: s(3),
    borderRadius: s(0.5),
    backgroundColor: "#E7E7E7",
  },
  rowFields: {
    display: "flex",
    marginBottom: s(4),

    "& > :not(:last-child)": {
      marginRight: s(2.5),
    },
  },
}));

interface Props {
  activeTab: TabsEnum;
  switchTab: (value: TabsEnum) => void;
}

const PlanItinerary = ({ activeTab, switchTab }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const { viewedCall } = useCall();

  const [itinerarySections, setItinerarySections] = useState<Itinerary[]>([]);

  const initialValues: FormFields = useMemo(() => {
    const serviceCase = viewedCall?.updatedServiceCase;

    return {
      assignedStaff: serviceCase?.assignedStaff || "",
      paymentDetails: serviceCase?.paymentDetails || "",
      confirmPaymentReceived: serviceCase?.confirmPaymentReceived || false,
      itineraryDetails: serviceCase?.itineraryDetails || "",
      itinerarySections: itinerarySections.length
        ? mapItinerarySectionsToLocations(itinerarySections)
        : {
            initialLocation: {
              customLocation: serviceCase?.initialLocationTypeName || "",
              moreLocationDetails: serviceCase?.initialLocationAddress2 || "",
              locationType: (serviceCase?.initialLocationType ||
                "") as LocationTypeEnum,
              locationMedicalFacility:
                serviceCase?.initalLocationHospital || null,
              location: {
                plusCode: "",
                ...getLocationItems(serviceCase?.initialLocation),
                address: serviceCase?.initialLocationAddress || "",
              },
            },
            destinationLocation: {
              customLocation: serviceCase?.destinationLocationTypeName || "",
              moreLocationDetails:
                serviceCase?.destinationLocationAddress2 || "",
              locationType: (serviceCase?.destinationLocationType ||
                "") as LocationTypeEnum,
              locationMedicalFacility:
                serviceCase?.destinationLocationHospital || null,
              location: {
                plusCode: "",
                ...getLocationItems(serviceCase?.destinationLocation),
                address: serviceCase?.destinationLocationAddress || "",
              },
            },
          },
    };
  }, [viewedCall?.updatedServiceCase, itinerarySections]);

  const uncoveredPatients = useMemo(
    () =>
      (viewedCall?.updatedServiceCase?.patients || []).filter(
        (patient) =>
          patient.selectedSubsCoverage === null ||
          patient.selectedSubsCoverage.coverage === null ||
          patient.selectedSubsCoverage.coverage ===
            PatientCoverageEnum.NOT_COVERED
      ),
    [viewedCall?.updatedServiceCase?.patients]
  );

  const validationSchema = useMemo(
    () =>
      earlyValidationSchema.shape({
        paymentDetails: uncoveredPatients.length
          ? Yup.string().required("Payment details are required")
          : Yup.string(),
        confirmPaymentReceived: uncoveredPatients.length
          ? Yup.boolean()
              .required("Payment details must have been collected to continue")
              .oneOf(
                [true],
                "Payment details must have been collected to continue"
              )
          : Yup.boolean(),
      }),
    [uncoveredPatients.length]
  );

  const saveItinerarySection: FormikConfig<FormFields>["onSubmit"] =
    useCallback(
      async (values, { setSubmitting }) => {
        if (viewedCall?.updatedServiceCase?.id) {
          try {
            setLoading(true);

            const section = values.itinerarySections.destinationLocation;

            const trigger = getSubmitAction(
              viewedCall.updatedServiceCase.service
            );

            await startOrScheduleServiceCase(viewedCall.updatedServiceCase.id, {
              status:
                trigger === "Start Service"
                  ? ServiceCaseStatusEnum.SERVICE_STARTED
                  : ServiceCaseStatusEnum.SERVICE_SCHEDULED,
              assignedStaff: values.assignedStaff,
              paymentDetails: values.paymentDetails,
              itineraryDetails: values.itineraryDetails,
              destinationLocationType: section.locationType,
              destinationLocationAddress: section.location.address,
              confirmPaymentReceived: values.confirmPaymentReceived,
              destinationLocationAddress2: section.moreLocationDetails,
              destinationLocationLatLong: `${section.location.lat},${section.location.lng}`,
              destinationMedicalFacility:
                (section.locationType === LocationTypeEnum.MEDICAL_FACILITY &&
                  section.locationMedicalFacility) ||
                undefined,
              destinationLocationTypeName:
                section.locationType || section.customLocation,
            });

            setShowConfirmationDialog(false);
            switchTab(TabsEnum.RENDERING);
          } catch {
            //
          } finally {
            setLoading(false);
            setSubmitting(false);
          }
        }
      },
      [
        switchTab,
        viewedCall?.updatedServiceCase?.id,
        viewedCall?.updatedServiceCase?.service,
      ]
    );

  const handleSubmitClick = useCallback(
    ({
        values,
        setErrors,
        submitForm,
        setFieldTouched,
      }: FormikProps<FormFields>) =>
      () => {
        try {
          setLoading(true);
          earlyValidationSchema.validateSync(values, {
            abortEarly: false,
          });

          if (!uncoveredPatients.length) submitForm();
          else setShowConfirmationDialog(true);
        } catch (err: any) {
          setErrors(
            (err as Yup.ValidationError).inner.reduce((acc, cur) => {
              acc[cur.path] = cur.message;
              setFieldTouched(cur.path, true);
              return acc;
            }, {} as Record<string, string>)
          );
        } finally {
          setLoading(false);
        }
      },
    [uncoveredPatients.length]
  );

  const submitButtonText = useMemo(() => {
    if (viewedCall?.updatedServiceCase?.service) {
      return getSubmitAction(viewedCall.updatedServiceCase.service);
    }
  }, [viewedCall?.updatedServiceCase?.service]);

  useEffect(() => {
    (async () => {
      if (viewedCall?.updatedServiceCase?.id) {
        try {
          setLoading(true);
          const res = await getItinerarySections(
            viewedCall?.updatedServiceCase.id
          );

          setItinerarySections(res.sort((a, b) => a.order - b.order));
        } catch {
          // err
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [viewedCall?.updatedServiceCase?.id]);

  const formRef = React.useRef<FormikProps<FormFields>>(null);

  useEffect(() => {
    if (viewedCall?.updatedServiceCase?.requesterSameAsInitialLocation) {
      const { lat, lng } = getLocationItems(
        viewedCall.updatedServiceCase.initialLocation
      );

      const geoCoder = new google.maps.Geocoder();

      geoCoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK" && results?.[0]) {
          const initialLocation = getLocationFromGeoCodeResult(results[0]);

          formRef.current?.setFieldValue("itinerarySections.initialLocation", {
            ...formRef.current.values.itinerarySections.initialLocation,
            location: initialLocation,
            locationType: LocationTypeEnum.CUSTOM,
            customLocation: "Requester's Location",
          });
        }
      });
    }
  }, [
    viewedCall?.updatedServiceCase?.initialLocation,
    viewedCall?.updatedServiceCase?.requesterSameAsInitialLocation,
  ]);

  const disableForm = useMemo(() => {
    return (
      !!viewedCall?.disableForms ||
      viewedCall?.updatedServiceCase?.caseStatus !==
        ServiceCaseStatusEnum.PENDING_PLANNING
    );
  }, [viewedCall?.disableForms, viewedCall?.updatedServiceCase?.caseStatus]);

  return (
    <Box className={classes.wrapper}>
      <Loader open={loading} absolute />

      <Box display="flex" justifyContent="space-between">
        <Typography className={classes.title} variant="h5">
          Itinerary
        </Typography>

        <IconButton
          onClick={() => setOpen(!open)}
          className={classes.headerIconButton}
          aria-label="toggle plan itinerary details"
        >
          {open ? <KeyboardArrowUp /> : <KeyboardArrowLeft />}
        </IconButton>
      </Box>

      {open && (
        <Formik<FormFields>
          enableReinitialize
          innerRef={formRef}
          initialValues={initialValues}
          onSubmit={saveItinerarySection}
          validationSchema={validationSchema}
        >
          {function MyForm(props) {
            const { values } = props;

            const { map, createPolyline, removePolyline } = useMap();

            useEffect(() => {
              const path = Object.values(values.itinerarySections).reduce(
                (acc, { location: { lat, lng } }) => {
                  if (lat && lng) acc.push({ lat, lng });
                  return acc;
                },
                [] as google.maps.LatLngLiteral[]
              );

              if (map && path.length > 1 && activeTab === TabsEnum.PLANNING) {
                try {
                  createPolyline("Itinerary Sections", path);
                } catch (err) {
                  // eslint-disable-next-line no-console
                  console.error(err);
                }
              } else {
                removePolyline("Itinerary Sections");
              }
            }, [map, createPolyline, removePolyline, values.itinerarySections]);

            return (
              <Form>
                <fieldset className={classes.fieldset}>
                  <ItineraryLocation
                    index={0}
                    formikProps={props}
                    activeTab={activeTab}
                    disabled={disableForm}
                    section={values.itinerarySections.initialLocation}
                  />

                  <ItineraryLocation
                    index={1}
                    formikProps={props}
                    activeTab={activeTab}
                    disabled={disableForm}
                    section={values.itinerarySections.destinationLocation}
                  />
                </fieldset>

                <Box className={classes.rowFields}>
                  <Field
                    multiline
                    minRows={3}
                    maxRows={3}
                    component={TextField}
                    disabled={disableForm}
                    name="itineraryDetails"
                    label="Itinerary Details"
                    placeholder="Itinerary Details"
                  />

                  <Field
                    rows={3}
                    multiline
                    name="assignedStaff"
                    component={TextField}
                    label="Assigned Staff"
                    disabled={disableForm}
                    placeholder="Assigned Staff"
                  />
                </Box>

                <Box display="flex" justifyContent="flex-end">
                  {submitButtonText && (
                    <Button
                      type="button"
                      color="primary"
                      variant="contained"
                      disabled={disableForm}
                      onClick={handleSubmitClick(props)}
                    >
                      {submitButtonText}
                    </Button>
                  )}
                </Box>

                {showConfirmationDialog && (
                  <SubCoveragePaymentDialog<FormFields>
                    formikProps={props}
                    uncoveredPatients={uncoveredPatients}
                    onClose={() => {
                      setShowConfirmationDialog(false);
                    }}
                  />
                )}
              </Form>
            );
          }}
        </Formik>
      )}
    </Box>
  );
};

export default PlanItinerary;
