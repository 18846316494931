import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { FC } from "react";
import { DialogProps } from "@material-ui/core/Dialog/Dialog";
import clsx from "clsx";
import Loader from "component/Loader";
import { Field, FormikProvider, useFormik } from "formik";
import { CloseOutlined } from "@material-ui/icons";
import { useDialogStyles } from "component/Dialogs/styles";
import DCSPhoneInput from "component/atoms/DCSPhoneInput";
import { TextField } from "formik-material-ui";
import { SelectField } from "component/atoms";
import {
  ServiceCaseRequestTypeEnum,
  ServiceCaseStatusEnum,
} from "@deep-consulting-solutions/be2-constants";
import { useHistory } from "react-router-dom";
import { createUnansweredServiceRequest } from "component/Dialogs/request";
import * as yup from "yup";
import { getENText, VALIDATIONS } from "helpers";
import { notifications } from "services";
import { useDispatch } from "react-redux";
import { dispatcherActions } from "redux/dispatcher";
import { ROUTES } from "component/configs";

const useStyle = makeStyles((theme) => ({
  fields: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 20,
  },
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      width: "100%",
      maxWidth: 700,
      borderRadius: "4px 4px 16px 16px",
    },
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

const requestTypeOptions = Object.values(ServiceCaseRequestTypeEnum);

export const CreateServiceCase: FC<DialogProps> = ({ open, onClose }) => {
  const classes = useStyle();
  const baseClasses = useDialogStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  const { setServiceCaseUpdated } = dispatcherActions;

  const formik = useFormik({
    initialValues: {
      caseStatus: ServiceCaseStatusEnum.GATHERING_REQUIREMENTS,
      requesterPhone: "",
      situationDescription: "",
      requestType: "" as ServiceCaseRequestTypeEnum,
    },
    validationSchema: yup.object({
      requesterPhone: VALIDATIONS.phone,
      situationDescription: yup
        .string()
        .required(getENText("validation.situationDescription.required")),
      requestType: yup
        .string()
        .required(getENText("validation.requestType.required")),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const res = await createUnansweredServiceRequest(values);
      dispatch(setServiceCaseUpdated());
      notifications.notifySuccess(
        `Service Case #${res.data.caseNumber} is created`
      );
      setSubmitting(false);
      resetForm();
      if (onClose) onClose({}, "escapeKeyDown");
      history.push(ROUTES.caseClassifications.path, {
        serviceCase: res.data,
      });
    },
  });

  const handleClose = () => {
    formik.resetForm();
    if (onClose) onClose({}, "escapeKeyDown");
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={clsx(classes.dialog, baseClasses.dialog)}
    >
      <Loader open={formik.isSubmitting} absolute />
      <FormikProvider value={formik}>
        <div className={baseClasses.dialogTitle}>
          <Typography color="primary" style={{ fontSize: 20 }}>
            Create Service Case
          </Typography>
          <IconButton size="small" onClick={handleClose as any}>
            <CloseOutlined style={{ fontSize: 20, color: "#BDBDBD" }} />
          </IconButton>
        </div>
        <DialogContent>
          <FormikProvider value={formik}>
            <form>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography color="primary">Contact Number</Typography>
              </Box>
              <Box mt={2} className={classes.fields}>
                <Field
                  component={DCSPhoneInput}
                  name="requesterPhone"
                  label="Phone Number"
                  size="small"
                  required
                />
              </Box>
              <Divider className={classes.divider} />
              <Box mb={2}>
                <Typography color="primary">Situation Description</Typography>
                <Typography variant="body2">
                  Obtain information from the Requester
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field
                    size="small"
                    component={TextField}
                    name="situationDescription"
                    label="Situation Description"
                    multiline
                    required
                    minRows={3}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectField
                    size="small"
                    name="requestType"
                    label="Request Type"
                    required
                    options={requestTypeOptions}
                    helperText="Choose the type of service that is being requested after assessing the Requester's situation"
                  />
                </Grid>
              </Grid>
            </form>
          </FormikProvider>
        </DialogContent>
        <DialogActions style={{ padding: 24 }}>
          <Button
            color="primary"
            type="submit"
            onClick={formik.handleSubmit as any}
          >
            Create
          </Button>
        </DialogActions>
      </FormikProvider>
    </Dialog>
  );
};
