import { Alert } from "@material-ui/lab";
import ReactDOMServer from "react-dom/server";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import React, { useCallback, useEffect, useState } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

import { getQueryParam } from "helpers/queryParams";
import { Marker, ReactMap } from "component/ReactMap";
import { getLocationFromGeoCodeResult } from "helpers";
import { MapErrorComponent, MapLoadingComponent } from "call";
import { environmentVariables } from "configs/environmentVariables";
import { LocationField } from "call/components/CallForms/LocationForm";
import { LocationSummary } from "call/components/CallForms/LocationForm/LocationSummary";

import { verifyLocationCollectorLinkReq } from "./requests";

const useStyles = makeStyles(({ spacing: s }) => ({
  root: {
    marginTop: s(3),
  },
  heading: {
    fontSize: 20,
    marginBottom: 4,
    fontWeight: 700,
    color: "#08A40F",
    fontFamily: `"Roboto", sans-serif`,
  },
  mapWrapper: {
    height: "100vh",
    maxWidth: "100vw",
    position: "relative",
  },
  map: {
    width: "100%",
    height: "100%",
    marginTop: s(2),
    borderRadius: 4,
    background: "white",
  },
  alert: {
    position: "absolute",
    left: "10%",
    top: "10px",
  },
}));

export const LocationCollector = () => {
  const classes = useStyles();
  const [showAlert, setShowAlert] = useState(false);
  const [otpIsValid, setOtpIsValid] = useState(true);
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 0,
    lng: 0,
  });
  const [location, setLocation] = useState<LocationField>({
    lat: 0,
    lng: 0,
    dms: "",
    address: "",
    plusCode: "",
  });

  const successCallback = useCallback(({ coords }: GeolocationPosition) => {
    const position = {
      lat: coords.latitude,
      lng: coords.longitude,
    };

    setCenter(position);

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: position }, (results, status) => {
      if (status === "OK" && results?.[0]) {
        const result = results[0];
        setLocation(getLocationFromGeoCodeResult(result));
        setShowAlert(true);
      }
    });
  }, []);

  const errorCallback = useCallback(() => {}, []);

  useEffect(() => {
    if (otpIsValid) {
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback, {
        maximumAge: 0,
        enableHighAccuracy: true,
      });
    }
  }, [errorCallback, successCallback, otpIsValid]);

  useEffect(() => {
    (async () => {
      const otp = getQueryParam("otp");
      // const otp = "123456";

      if (otp) {
        try {
          await verifyLocationCollectorLinkReq("1", otp);
          setOtpIsValid(true);
        } catch {
          //
        }
      }
    })();
  }, []);

  return (
    <Box className={classes.root}>
      <Box textAlign="center" mb={3}>
        <Typography variant="h3" className={classes.heading}>
          Location was sent successfully to BahamasEvac Dispatcher.
        </Typography>

        <Typography variant="body2" style={{ color: "#4F4F4F" }}>
          Please, let the Dispatcher know If the location is not accurate.
        </Typography>
      </Box>

      <Box className={classes.mapWrapper}>
        <Wrapper
          libraries={["places"]}
          apiKey={environmentVariables.REACT_APP_GOOGLE_MAP_API_KEY}
          render={(status: Status) =>
            status === Status.FAILURE ? (
              <MapErrorComponent />
            ) : (
              <MapLoadingComponent />
            )
          }
        >
          <ReactMap
            zoom={12}
            center={center}
            mapTypeControl={false}
            streetViewControl={false}
            id="location-collector-map"
            mapClassName={classes.map}
          >
            {showAlert && (
              <Alert
                severity="success"
                className={classes.alert}
                onClose={() => setShowAlert(false)}
              >
                Your Location was sent successfully.
              </Alert>
            )}

            <Marker
              position={center}
              infoWindowContent={
                location.address
                  ? ReactDOMServer.renderToString(
                      <LocationSummary showAddress location={location} />
                    )
                  : ""
              }
            />
          </ReactMap>
        </Wrapper>
      </Box>
    </Box>
  );
};

export default LocationCollector;
