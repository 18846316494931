import { Chat } from "@deep-consulting-solutions/be2-constants";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Call } from "call";
import { WritableDraft } from "immer/dist/internal";

import type { RootState } from "../reducers";

const ENTITY = "call";

export interface CallState {
  calls: Call[];
  chats: Record<string, Chat[]>;
}

const initialState: CallState = {
  calls: [],
  chats: {},
};

const slice = createSlice({
  name: ENTITY,
  initialState,
  reducers: {
    setCalls: (state, { payload }: PayloadAction<CallState["calls"]>) => {
      state.calls = payload as unknown as WritableDraft<Call[]>;
      state.chats = state.calls.reduce<CallState["chats"]>(
        (previous, current) => {
          return {
            ...previous,
            [current.sessionId]: state.chats[current.sessionId] || [],
          };
        },
        {}
      );
    },
    setChats: (state, { payload }: PayloadAction<CallState["chats"]>) => {
      state.chats = payload as unknown as WritableDraft<CallState["chats"]>;
    },
  },
});

export const callActions = {
  ...slice.actions,
};

export const callSelectors = {
  callState: (state: RootState) => state.call,
  chats: (state: RootState) => state.call.chats || {},
};

export default slice.reducer;
