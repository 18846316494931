import { Field, FormikProps, getIn } from "formik";
import React, { useState } from "react";
import { Alert } from "@material-ui/lab";
import { TextField } from "formik-material-ui";
import { KeyboardArrowDown, KeyboardArrowLeft } from "@material-ui/icons";
import {
  Box,
  Collapse,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";

import DCSPhoneInput from "component/atoms/DCSPhoneInput";

interface ReceivingPhysicianProps {
  index: number;
  disabled?: boolean;
  formikProps: FormikProps<any>;
}

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  root: {
    padding: s(1),
    position: "relative",
    borderRadius: s(0.5),
    backgroundColor: "white",
    border: "1px solid #E0E0E0",

    "& .MuiCollapse-wrapperInner": {
      marginTop: s(1),
    },
  },
  infoAlert: {
    marginBottom: s(1.5),
    backgroundColor: p.primary.main,
  },
  fields: {
    display: "grid",
    gap: s(1.5),
    gridTemplateRows: "repeat(2, 1fr)",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  heading: {},
  iconButton: {
    padding: s(0.5),
    borderRadius: s(0.5),
  },
}));

const ReceivingPhysician = ({
  index,
  disabled,
  formikProps,
}: ReceivingPhysicianProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const namePrefix = `patients[${index}].physician`;
  const { errors, touched } = formikProps;

  const phoneError = getIn(errors, `${namePrefix}.phone`);
  const hasPhoneError = getIn(touched, `${namePrefix}.phone`) && !!phoneError;

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Typography variant="subtitle2" className={classes.heading}>
          Receiving Physician
        </Typography>

        <IconButton
          className={classes.iconButton}
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowDown /> : <KeyboardArrowLeft />}
        </IconButton>
      </Box>

      <Collapse in={open} unmountOnExit>
        <Alert severity="info" variant="filled" className={classes.infoAlert}>
          Destination Location is a medical facility. Please enter Receiving
          Physician Information.
        </Alert>

        <Box className={classes.fields}>
          <Field
            required
            size="small"
            label="First Name"
            disabled={disabled}
            component={TextField}
            name={`${namePrefix}.firstName`}
          />

          <Field
            required
            size="small"
            label="Last Name"
            disabled={disabled}
            component={TextField}
            name={`${namePrefix}.lastName`}
          />

          <Field
            required
            size="small"
            label="Phone"
            error={hasPhoneError}
            disabled={disabled}
            component={DCSPhoneInput}
            name={`${namePrefix}.phone`}
            helperText={hasPhoneError ? phoneError : undefined}
          />

          <Field
            required
            size="small"
            label="Email"
            disabled={disabled}
            component={TextField}
            name={`${namePrefix}.email`}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

export default ReceivingPhysician;
