import {
  Button,
  makeStyles,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { format } from "date-fns";
import React, {
  MouseEventHandler,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useFormikContext } from "formik";
import { notifications } from "services";
import { postServiceCasePhysicianFailedCallback } from "redux/call/requests";
import { useCall } from "call/hooks";
import { getENText } from "helpers";
import Loader from "component/Loader";
import { FormValues } from "../../types";
import { getIsRequesterPhysician } from "../../helpers";

const tableHeaders = ["Dispatcher", "Time of the Attempt"];

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  tableContainer: {
    filter: " drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.12))",
    borderRadius: 8,
    marginBottom: s(2),
  },
  tableRow: {
    padding: s(1),
    background: "#FFFFFF",
    boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",
  },
  tableHead: {
    background: "#F6F6F6",
    boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",
  },
  wrapper: {
    background: "#FFFFFF",
    boxShadow: " 0px 3px 5px rgba(0, 0, 0, 0.12)",
    borderRadius: 8,
    padding: s(2),
    marginBottom: s(4),
  },
  title: {
    fontSize: 20,
    marginBottom: s(2),
  },
  subtitle: {
    fontSize: 18,
    color: "#4F4F4F",
    marginBottom: s(2),
  },
  tableCellTitle: {
    fontWeight: 500,
    color: p.primary.dark,
    textAlign: "center",
    display: "block",
  },
  medicalStatusTitle: {
    color: p.grey[400],
    fontWeight: 500,
  },
  medicalStatus: {
    marginTop: s(4),
  },
  tableCell: {
    display: "block",
  },
  time: {
    fontWeight: "lighter",
  },
}));

interface CallAttempt {
  id: string;
  name: string;
  createdAt: string;
}

export const CallAttempts = () => {
  const { viewedCall } = useCall();
  const [loading, setLoading] = useState(false);
  const {
    values: {
      physician: { isContacted: physicianContacted },
    },
  } = useFormikContext<FormValues>();

  const isRequesterPhysician = useMemo(() => {
    return getIsRequesterPhysician(viewedCall?.updatedServiceCase?.patients);
  }, [viewedCall?.updatedServiceCase?.patients]);

  const { callAttempts } = useMemo(() => {
    return {
      callAttempts: [] as CallAttempt[],
    };
  }, []);

  const handleLogFailedCall: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      (async () => {
        try {
          setLoading(true);
          if (!viewedCall?.id) {
            return;
          }
          await postServiceCasePhysicianFailedCallback(viewedCall.id);
          notifications.notifySuccess(
            getENText("serviceForm.logFailedCall.successful")
          );
        } catch (error) {
          //
        } finally {
          setLoading(false);
        }
      })();
    },
    [viewedCall?.id]
  );

  const classes = useStyles();

  if (isRequesterPhysician || !physicianContacted || !callAttempts.length) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <Loader open={loading} />
      <Typography color="primary" variant="subtitle2" className={classes.title}>
        Call Attempts ({callAttempts.length})
      </Typography>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <TableHead>
          <TableRow className={classes.tableHead}>
            {tableHeaders.map((header) => (
              <TableCell key={header}>
                <Typography
                  variant="caption"
                  className={classes.tableCellTitle}
                  align="center"
                >
                  {header}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {callAttempts.map((callAttempt) => (
            <TableRow key={callAttempt.id}>
              <TableCell>
                <Typography
                  className={classes.tableCell}
                  align="center"
                  color="primary"
                  variant="caption"
                >
                  {callAttempt.name}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={classes.tableCell}
                  align="center"
                  color="primary"
                  variant="caption"
                >
                  {format(new Date(callAttempt.createdAt), "dd MMM yyyy")}
                  <span className={classes.time}>
                    {format(new Date(callAttempt.createdAt), "h:m:s a")}
                  </span>
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>

      <Button color="primary" onClick={handleLogFailedCall}>
        Log Failed Call to Physician
      </Button>
    </div>
  );
};
