import { useEffect, useContext, createContext, useState } from "react";
import { useStorage } from "hooks";
import { SELECTED_DEVICE_STORAGE_NAME } from "constants/storage";

interface AudioSource {
  input: MediaDeviceInfo[];
  output: MediaDeviceInfo[];
}

interface MediaDeviceTypes {
  audio: AudioSource;
  video: MediaDeviceInfo[];
}

export type DeviceKind = MediaDeviceKind | "audiooutputext";

export type MediaDeviceSelected = {
  [key in DeviceKind]: string;
};

interface MediaDeviceContextProps {
  devices: MediaDeviceTypes;
  handleSelectedDevice: (
    deviceName: keyof MediaDeviceSelected,
    deviceId: string
  ) => void;
  selectedDevices: MediaDeviceSelected;
  saveDevices: () => void;
}

export const MediaDeviceContext = createContext<MediaDeviceContextProps | null>(
  null
);
export const useMediaDeviceContext = () =>
  useContext(MediaDeviceContext) as MediaDeviceContextProps;

const getDevices = async () => {
  const initial: MediaDeviceTypes = {
    audio: { input: [], output: [] },
    video: [],
  };
  const res = await navigator.mediaDevices.enumerateDevices();
  return res.reduce((sources, cur) => {
    if (cur.kind === "audioinput") {
      sources.audio.input.push(cur);
    }
    if (cur.kind === "audiooutput") {
      sources.audio.output.push(cur);
    }
    if (cur.kind === "videoinput") {
      sources.video.push(cur);
    }
    return sources;
  }, initial);
};

const MediaDeviceProvider: React.FC = ({ children }) => {
  const { setItem, getItem } = useStorage();
  const [devices, setDevices] = useState<MediaDeviceTypes>({
    audio: { input: [], output: [] },
    video: [],
  });
  const [selectedDevices, setSelectedDevice] = useState<MediaDeviceSelected>({
    audioinput: "",
    audiooutput: "",
    videoinput: "",
    audiooutputext: "",
  });

  const getMediaDevices = async () => {
    try {
      const d = await getDevices();
      setDevices(d);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("error has occurred", e);
    }
  };

  const saveDevices = () => {
    setItem(SELECTED_DEVICE_STORAGE_NAME, selectedDevices);
  };

  const handleSelectedDevice = (
    deviceName: keyof MediaDeviceSelected,
    deviceId: string
  ) => {
    setSelectedDevice((curr) => {
      return { ...curr, [deviceName]: deviceId };
    });
  };

  useEffect(() => {
    getMediaDevices();
    const saved = getItem<MediaDeviceSelected>(SELECTED_DEVICE_STORAGE_NAME);
    if (saved) {
      setSelectedDevice(saved);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  navigator.mediaDevices.ondevicechange = () => {
    getMediaDevices();
  };
  return (
    <MediaDeviceContext.Provider
      value={{
        devices,
        saveDevices,
        selectedDevices,
        handleSelectedDevice,
      }}
    >
      {children}
    </MediaDeviceContext.Provider>
  );
};

export default MediaDeviceProvider;
