import {
  ServiceTypesEnum,
  ServiceCaseResponse,
  ServiceCaseStatusEnum,
} from "@deep-consulting-solutions/be2-constants";
import { initialAssessmentValidationSchema } from "./InitialAssessmentForm";
import {
  CallFormTab,
  CallFormTabProps,
  TabsEnum,
  MainTabsEnum,
  CallFormMainTabProps,
  CallFormMainTab,
} from "./types";

export const tabsEnumToNumber = (value: TabsEnum) => {
  return Object.values(TabsEnum).indexOf(value);
};

export const numberToTabsEnum = (value: number) => {
  return Object.values(TabsEnum)[value];
};

export const getCallFormTabId = (index: number) => `call-form-tab-${index}`;

export const getCallFormAreaLabel = (index: number) =>
  `call-form-tab-panel-${index}`;

const getProps = (index: number): CallFormTabProps => {
  return {
    id: getCallFormTabId(index),
    "aria-controls": getCallFormAreaLabel(index),
  };
};

export const getCallFormTabs = (skippedTabs: TabsEnum[]): CallFormTab[] => {
  return Object.values(TabsEnum)
    .filter((tab) => !skippedTabs.includes(tab))
    .map((tab) => ({
      label: tab,
      props: getProps(tabsEnumToNumber(tab)),
      index: tabsEnumToNumber(tab),
    }));
};

export const getIsNautical = (serviceCase?: ServiceCaseResponse | null) => {
  return (
    serviceCase?.service === ServiceTypesEnum.EMERGENCY_AIRLIFT_EVACUATION ||
    serviceCase?.service === ServiceTypesEnum.HOSPITAL_TRANSFER
  );
};

export const mainTabsEnumToNumber = (value: MainTabsEnum) => {
  return Object.values(MainTabsEnum).indexOf(value);
};

export const numberToMainTabsEnum = (value: number) => {
  return Object.values(MainTabsEnum)[value];
};

export const getCallFormMainTabId = (index: number) =>
  `call-form-main-tab-${index}`;

export const getCallFormMainAreaLabel = (index: number) =>
  `call-form-main-tab-panel-${index}`;

const getMainTabProps = (index: number): CallFormMainTabProps => {
  return {
    id: getCallFormMainTabId(index),
    "aria-controls": getCallFormMainAreaLabel(index),
  };
};

export const getCallFormMainTabs = (): CallFormMainTab[] => {
  return Object.values(MainTabsEnum).map((tab) => ({
    label: tab,
    props: getMainTabProps(mainTabsEnumToNumber(tab)),
    index: mainTabsEnumToNumber(tab),
  }));
};

export const getInitialActiveTab = (
  serviceCase: ServiceCaseResponse | undefined
) => {
  if (!serviceCase) {
    return TabsEnum.INITIAL_ASSESSMENT;
  }

  if (serviceCase.caseStatus === ServiceCaseStatusEnum.PENDING_BE_ACCEPTANCE) {
    return TabsEnum.SERVICE;
  }

  if (
    serviceCase.caseStatus === ServiceCaseStatusEnum.PENDING_MEDICAL_ASSESSMENT
  ) {
    return TabsEnum.ASSESSMENT;
  }

  if (
    [
      ServiceCaseStatusEnum.PENDING_PLANNING,
      ServiceCaseStatusEnum.QUOTE_REQUESTED,
      ServiceCaseStatusEnum.PENDING_PAYMENT,
    ].includes(serviceCase.caseStatus)
  ) {
    return TabsEnum.PLANNING;
  }

  if (
    [
      ServiceCaseStatusEnum.SERVICE_SCHEDULED,
      ServiceCaseStatusEnum.SERVICE_STARTED,
      ServiceCaseStatusEnum.PATIENT_RELEASED,
    ].includes(serviceCase.caseStatus)
  ) {
    return TabsEnum.RENDERING;
  }

  if (serviceCase.caseStatus === ServiceCaseStatusEnum.GATHERING_REQUIREMENTS) {
    if (serviceCase.destinationLocation) return TabsEnum.SERVICE;

    if (serviceCase?.patients.length || serviceCase?.requester?.isPatient) {
      return TabsEnum.LOCATION;
    }

    try {
      initialAssessmentValidationSchema.validateSync({
        ...serviceCase,
        contactNumber: serviceCase.requester?.phone || "",
        callBackNumber: serviceCase.callbackPhone,
      });
      return TabsEnum.REQUESTER_AND_PATIENT;
    } catch (err) {
      return TabsEnum.INITIAL_ASSESSMENT;
    }
  }

  return TabsEnum.INITIAL_ASSESSMENT;
};

export const getDefaultDisabledTabs = (
  serviceCase: ServiceCaseResponse | undefined
) => {
  const initialActiveTab = getInitialActiveTab(serviceCase);

  const defaultValues = Object.values(TabsEnum).filter(
    (tab) => tab !== TabsEnum.INITIAL_ASSESSMENT
  );

  if (!serviceCase) return defaultValues;

  if (initialActiveTab === TabsEnum.INITIAL_ASSESSMENT) {
    if (
      [
        ServiceCaseStatusEnum.CLOSED,
        ServiceCaseStatusEnum.REFUSED,
        ServiceCaseStatusEnum.RE_OPENED,
        ServiceCaseStatusEnum.CANCELLED,
        ServiceCaseStatusEnum.COMPLETED,
      ].includes(serviceCase.caseStatus)
    ) {
      return [];
    }
    return defaultValues;
  }

  if (initialActiveTab === TabsEnum.REQUESTER_AND_PATIENT) {
    return [
      TabsEnum.LOCATION,
      TabsEnum.SERVICE,
      TabsEnum.ASSESSMENT,
      TabsEnum.PLANNING,
      TabsEnum.RENDERING,
    ];
  }

  if (initialActiveTab === TabsEnum.LOCATION) {
    return [
      TabsEnum.SERVICE,
      TabsEnum.ASSESSMENT,
      TabsEnum.PLANNING,
      TabsEnum.RENDERING,
    ];
  }

  if (initialActiveTab === TabsEnum.SERVICE) {
    return [TabsEnum.ASSESSMENT, TabsEnum.PLANNING, TabsEnum.RENDERING];
  }

  if (initialActiveTab === TabsEnum.ASSESSMENT) {
    return [TabsEnum.PLANNING, TabsEnum.RENDERING];
  }

  if (initialActiveTab === TabsEnum.PLANNING) {
    return [TabsEnum.RENDERING];
  }

  if (initialActiveTab === TabsEnum.RENDERING) {
    return [];
  }
  return defaultValues;
};
