import {
  Dialog,
  DialogContent,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { ServiceCaseAccessLinkResponse } from "@deep-consulting-solutions/be2-constants";

import { DialogTitleWithCloseButton } from "component/DialogTitleWithCloseButton";
import Loader from "component/Loader";

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  message: {
    fontSize: 14,
    color: p.grey[700],
  },
  actions: {
    marginTop: s(2),
    textAlign: "right",
  },
  cancelBtn: {
    marginRight: s(1),
  },
}));

interface Props {
  accessLink: ServiceCaseAccessLinkResponse | null;
  onDeactivateClick: (accessLinkID: string) => Promise<boolean>;
  onClose: () => any;
}

export const DeactivateDialog: React.FC<Props> = ({
  accessLink,
  onClose,
  onDeactivateClick,
}) => {
  const [loading, setLoading] = useState(false);

  const handleDeactivateClick = useCallback(() => {
    (async () => {
      if (!accessLink?.ID) return;
      try {
        setLoading(true);
        const isDone = await onDeactivateClick(accessLink.ID);
        setLoading(false);
        if (isDone) onClose();
      } catch {
        setLoading(false);
      }
    })();
  }, [accessLink?.ID, onDeactivateClick, onClose]);

  const classes = useStyles();
  return (
    <Dialog open={!!accessLink}>
      <Loader open={loading} />
      <DialogTitleWithCloseButton
        title="Deactivate Access Permanently"
        onClose={onClose}
      />
      <DialogContent>
        <Typography className={classes.message}>
          This will permanently cut access to anyone who is accessing the
          service case details using this access link.
        </Typography>
        <div className={classes.actions}>
          <Button
            color="primary"
            variant="outlined"
            className={classes.cancelBtn}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button color="primary" onClick={handleDeactivateClick}>
            Deactivate Access Permanently
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
