import {
  MinimalContact,
  RelationToApplicantEnum,
  NextOfKinRelationToPatientEnum,
} from "@deep-consulting-solutions/be2-constants";

export function getRole(contact: MinimalContact) {
  if (contact.relationToContact) {
    if (contact.relationToContact === NextOfKinRelationToPatientEnum.OTHER) {
      return contact.otherRelationToContact;
    }

    return contact.relationToContact;
  }

  if (contact.relationToMainbeneficiary) {
    if (contact.relationToMainbeneficiary === RelationToApplicantEnum.other) {
      return contact.otherRelationToMainbeneficiary;
    }

    return contact.relationToMainbeneficiary;
  }
}

export function getOppositeRole(contact: MinimalContact) {
  if (!contact.relationToMainbeneficiary) return;

  if (contact.relationToMainbeneficiary === RelationToApplicantEnum.child) {
    return RelationToApplicantEnum.parent;
  }

  if (contact.relationToMainbeneficiary === RelationToApplicantEnum.parent) {
    return RelationToApplicantEnum.child;
  }

  if (contact.relationToMainbeneficiary === RelationToApplicantEnum.other) {
    return contact.otherRelationToMainbeneficiary;
  }

  // contact is spouse.
  return contact.relationToMainbeneficiary;
}
