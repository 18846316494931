import React from "react";
import { GetDispatcherActivityLogsSortByEnum } from "@deep-consulting-solutions/be2-constants";
import {
  Box,
  Theme,
  makeStyles,
  TableCell,
  Typography,
} from "@material-ui/core";

interface DynamicHeadingProps {
  title: string;
  sortLabel: GetDispatcherActivityLogsSortByEnum;
  updateSort: (sortLabel: GetDispatcherActivityLogsSortByEnum) => () => void;
  composeIcon: (
    title: GetDispatcherActivityLogsSortByEnum
  ) => JSX.Element | null;
}

interface StyleProps {
  sortLabel: GetDispatcherActivityLogsSortByEnum;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  dynamicHead: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: ({ sortLabel }) =>
      sortLabel === GetDispatcherActivityLogsSortByEnum.CHECKIN_TIME
        ? "flex-end"
        : "flex-start",
  },
  dynamicHeader: {
    fontWeight: 700,
  },
}));

export const DynamicHeading = ({
  title,
  sortLabel,
  updateSort,
  composeIcon,
}: DynamicHeadingProps) => {
  const classes = useStyles({ sortLabel });

  return (
    <TableCell width="23.3%" onClick={updateSort(sortLabel)}>
      <Box className={classes.dynamicHead}>
        {composeIcon(sortLabel)}
        <Typography className={classes.dynamicHeader}>{title}</Typography>
      </Box>
    </TableCell>
  );
};
