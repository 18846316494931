import { Hospital } from "@deep-consulting-solutions/be2-constants";
import {
  Dispatch,
  createSlice,
  createAction,
  createAsyncThunk,
} from "@reduxjs/toolkit";

import { RootState } from "redux/reducers";
import { getHospitals } from "redux/call/requests";

import * as hospitalRequests from "./requests";

const ENTITY = "hospital";

export interface HospitalState {
  loading: boolean;
  hospitals: Hospital[];
}

const initialState: HospitalState = {
  loading: false,
  hospitals: [],
};

const addHospital = createAction<Hospital>(`${ENTITY}/addHospital`);

const setLoading = createAction<boolean>(`${ENTITY}/setLoading`);

const fetchHospitals = createAsyncThunk<
  Hospital[],
  void,
  { state: RootState; dispatch: Dispatch }
>(`${ENTITY}/fetchHospitals`, async (_, { getState, dispatch }) => {
  const { loading, hospitals } = getState().hospital;
  if (loading) return hospitals;

  dispatch(setLoading(true));

  const res = await getHospitals();
  return res;
});

const slice = createSlice({
  name: ENTITY,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(setLoading, (state, action) => {
        state.loading = action.payload;
      })
      .addCase(fetchHospitals.fulfilled, (state, action) => {
        state.loading = false;
        state.hospitals = action.payload;
      })
      .addCase(fetchHospitals.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addHospital, (state, action) => {
        state.hospitals.push(action.payload);
      }),
});

export const hospitalActions = {
  addHospital,
  fetchHospitals,
};

export const hospitalSelectors = {
  getHospitals: (state: RootState) => state.hospital.hospitals,
};

export default slice.reducer;

export { hospitalRequests };
