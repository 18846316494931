import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Card, makeStyles, Theme, Typography } from "@material-ui/core";
import {
  SocketEventsEnum,
  ServiceCaseResponse,
  ServiceCaseStatusGroupEnum,
} from "@deep-consulting-solutions/be2-constants";
import clsx from "clsx";
import Loader from "component/Loader/Loader";
import { useElementWithin } from "hooks/useElementOnScreen";
import { DisplayModeLayout } from "layout/displayMode.layout";
import { DisplayLayout } from "redux/dispatcher";
import { useAppSelector } from "redux/store";
import { PatientStatusSnippet } from "pages/FulfilmentDashboard/components/PatientStatusSnippet";
import { ServiceCaseResponseExtended } from "@deep-consulting-solutions/be2-constants/build/types/types";
import { useSocket } from "hooks";
import { getServiceCase } from "redux/serviceCase/requests";
import { GettingMoreLoader, RenderCaseStatus } from "../../components";
import { RenderMap } from "../MedicalDirector/RenderMap";
import {
  FilterStateKeys,
  getInProgressStatus,
  getNewAndScheduledStatus,
  GetNewAndScheduledStatusResponseType,
  getNewStatus,
  getScheduledStatus,
} from "./request";
import {
  scrollToRef,
  getStatusGroup,
  shouldUpdateServiceCase,
  serviceCaseNeedsUpdate,
} from "../../helpers";
import {
  updateAllGroup,
  updateSpecialGroup,
  updateStatusGroup,
} from "./helpers";

const FILTER_PER_PAGE = 20;

const useStyles = makeStyles<Theme, { layout?: DisplayLayout | null }>(
  ({ palette: p }) => ({
    container: {
      display: "grid",
      gridTemplateColumns: "auto auto 1fr",
      gap: 16,
      gridTemplateRows: ({ layout }) =>
        layout === DisplayLayout?.horizontal
          ? "auto auto minmax(auto, 500px)"
          : undefined,
    },
    card: {
      position: "relative",
      padding: 11,
      height: "min-content",
      maxWidth: ({ layout }) =>
        layout === DisplayLayout?.vertical ? 500 : "unset",
      minWidth: 432,
      width: "100%",
    },
    scrollable: {
      overflow: "auto",
      paddingLeft: 5,
      paddingRight: 5,
      maxHeight: "75vh",
    },
    isHorizontal: {
      display: "flex",
      gap: 16,
    },
    inputContainer: {
      position: "relative",
      display: "grid",
      width: "100%",
      gap: 10,
    },
    inputSplit: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: 10,
    },
    spaceBetween: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    tabBtn: {
      border: "none",
      background: "none",
      padding: "16px 8px",
      borderBottom: "1px solid #e0e0e0",
      textTransform: "uppercase",
      color: "#666666",
      cursor: "pointer",
    },
    tabActive: {
      borderBottom: `2px solid ${p.primary.main}`,
      color: p.primary.main,
    },
  })
);

const getTabKey = (label: string) => {
  switch (label) {
    case "all":
      return "allStatuses";
    case "new":
      return "newStatuses";
    case "scheduled":
      return "scheduledStatuses";
    default:
      return "allStatuses";
  }
};

const tabFilter = (
  filterState: GetNewAndScheduledStatusResponseType,
  tab: FilterStateKeys
) => {
  switch (tab) {
    case "newStatuses":
      return filterState.newStatuses.data;
    case "scheduledStatuses":
      return filterState.scheduledStatuses.data;
    case "allStatuses":
    default:
      return filterState.allStatuses.data;
  }
};

interface SocketEvent {
  serviceCaseId: string;
  modifiedFields: Array<keyof ServiceCaseResponse>;
}

const DefaultView = () => {
  const { displayLayout, serviceCaseUpdated } = useAppSelector(
    (state) => state.dispatcher
  );

  const [initProgressLoading, setProgressLoading] = useState(false);
  const [initFilterLoading, setFilterLoading] = useState(false);

  const [activeTab, setActiveTab] = useState<FilterStateKeys>("allStatuses");

  const filterTopRef = useRef(null);

  const [inProgressState, setInProgressState] = useState({
    total: 0,
    perPage: FILTER_PER_PAGE,
    data: [] as ServiceCaseResponseExtended[],
    page: 1,
  });

  const [filterState, setFilterState] =
    useState<GetNewAndScheduledStatusResponseType>({
      allStatuses: {
        count: 0,
        data: {
          page: 1,
          perPage: FILTER_PER_PAGE,
          serviceCases: [],
          total: 0,
        },
      },
      newStatuses: {
        count: 0,
        data: { page: 1, perPage: FILTER_PER_PAGE, serviceCases: [], total: 0 },
      },
      scheduledStatuses: {
        count: 0,
        data: { page: 1, perPage: FILTER_PER_PAGE, serviceCases: [], total: 0 },
      },
    });

  const handleOnMoreSelection = (selected: string) => {
    if (selected === "edit") {
      // do edit action
    }
  };

  const handleUpdate = (id: string) => (data: ServiceCaseResponse) => {
    setProgressLoading(true);
    const cpyProgress = inProgressState.data.map((el) => {
      if (el.id === id) return { ...el, ...data };
      return el;
    });
    const cpyFilterAll = filterState.allStatuses.data.serviceCases.map((el) => {
      if (el.id === id) return { ...el, ...data };
      return el;
    });
    const cpyFilterNew = filterState.newStatuses.data.serviceCases.map((el) => {
      if (el.id === id) return { ...el, ...data };
      return el;
    });
    const cpyFilterSch = filterState.scheduledStatuses.data.serviceCases.map(
      (el) => {
        if (el.id === id) return { ...el, ...data };
        return el;
      }
    );
    setInProgressState({
      ...inProgressState,
      data: cpyProgress,
    });
    setFilterState({
      ...filterState,
      allStatuses: {
        ...filterState.allStatuses,
        data: {
          ...filterState.allStatuses.data,
          serviceCases: cpyFilterAll,
        },
      },
      newStatuses: {
        ...filterState.newStatuses,
        data: {
          ...filterState.newStatuses.data,
          serviceCases: cpyFilterNew,
        },
      },
      scheduledStatuses: {
        ...filterState.scheduledStatuses,
        data: {
          ...filterState.scheduledStatuses.data,
          serviceCases: cpyFilterSch,
        },
      },
    });
    setProgressLoading(false);
  };

  const [gettingMore, setGettingMore] = useState(false);
  const [gettingMoreTabbed, setGettingMoreTabbed] = useState(false);

  const { target } = useElementWithin(() => {
    if (inProgressState.data.length < inProgressState.total) {
      const newPage = `${(filterState.allStatuses.data.page || 1) + 1}`;
      (async () => {
        try {
          const res = await getInProgressStatus({
            page: newPage,
          });
          setInProgressState({
            data: inProgressState.data.concat(res.data.data.serviceCases),
            perPage: res.data.data?.perPage ?? 10,
            total: res.data.data.total,
            page: +newPage,
          });
          setGettingMore(false);
        } catch (e) {
          setGettingMore(false);
        }
      })();
    }
  }, []);

  const fetchMoreTabbedRequest = useCallback(() => {
    if (activeTab === "allStatuses") {
      if (
        filterState.allStatuses.data.serviceCases.length <
        filterState.allStatuses.count
      ) {
        const newPage = `${(filterState.allStatuses.data.page || 1) + 1}`;
        setGettingMoreTabbed(true);
        (async () => {
          try {
            const tabRes = await getNewAndScheduledStatus({
              page: newPage,
            });
            setGettingMoreTabbed(false);
            setFilterState({
              ...filterState,
              allStatuses: {
                ...tabRes.allStatuses,
                data: {
                  ...filterState.allStatuses.data,
                  page: +newPage,
                  serviceCases:
                    filterState.allStatuses.data.serviceCases.concat(
                      tabRes.allStatuses.data.serviceCases
                    ),
                },
              },
            });
          } catch (e) {
            setGettingMoreTabbed(false);
          }
        })();
      }
    } else if (activeTab === "newStatuses") {
      if (
        filterState.newStatuses.data.serviceCases.length <
        filterState.newStatuses.count
      ) {
        const newPage = `${(filterState.newStatuses.data.page || 1) + 1}`;
        setGettingMoreTabbed(true);
        (async () => {
          try {
            const newRes = await getNewStatus({
              page: newPage,
            });
            setGettingMoreTabbed(false);
            setFilterState({
              ...filterState,
              newStatuses: {
                ...filterState.newStatuses,
                data: {
                  ...filterState.newStatuses.data,
                  ...newRes.data.data,
                  page: +newPage,
                  serviceCases:
                    filterState.newStatuses.data.serviceCases.concat(
                      newRes.data.data.serviceCases
                    ),
                },
              },
            });
          } catch (e) {
            setGettingMoreTabbed(false);
          }
        })();
      }
    } else if (activeTab === "scheduledStatuses") {
      if (
        filterState.scheduledStatuses.data.serviceCases.length <
        filterState.scheduledStatuses.count
      ) {
        const newPage = `${(filterState.scheduledStatuses.data.page || 1) + 1}`;
        setGettingMoreTabbed(true);
        (async () => {
          try {
            const newRes = await getScheduledStatus({
              page: newPage,
            });
            setGettingMoreTabbed(false);
            setFilterState({
              ...filterState,
              scheduledStatuses: {
                ...filterState.scheduledStatuses,
                data: {
                  ...filterState.scheduledStatuses.data,
                  ...newRes.data.data,
                  page: +newPage,
                  serviceCases:
                    filterState.scheduledStatuses.data.serviceCases.concat(
                      newRes.data.data.serviceCases
                    ),
                },
              },
            });
          } catch (e) {
            setGettingMoreTabbed(false);
          }
        })();
      }
    }
  }, [activeTab, filterState]);

  const { target: filterRef } = useElementWithin(fetchMoreTabbedRequest, [
    filterState,
  ]);

  const onTabClick = (label: string) => () => {
    setActiveTab(getTabKey(label));
    scrollToRef(filterTopRef);
  };

  const classes = useStyles({ layout: displayLayout });

  useEffect(() => {
    (async () => {
      setFilterLoading(true);
      setProgressLoading(true);
      try {
        const res = await getInProgressStatus();
        setProgressLoading(false);
        setInProgressState({
          data: res.data.data.serviceCases,
          perPage: res.data.data?.perPage ?? 10,
          total: res.data.data.total,
          page: 1,
        });

        const tabRes = await getNewAndScheduledStatus();
        setFilterLoading(false);
        setFilterState(tabRes);
      } catch (e) {
        setFilterLoading(false);
        setProgressLoading(false);
      }
    })();
  }, [serviceCaseUpdated]);

  const { addHandler } = useSocket("Default View");

  const handleNewServiceCase = useCallback(({ serviceCaseId }: SocketEvent) => {
    (async () => {
      const serviceCase = {
        ...(await getServiceCase(serviceCaseId)),
        quotes: [],
        isNew: true,
      };

      const statusGroup = getStatusGroup(serviceCase.caseStatus);

      if (statusGroup === ServiceCaseStatusGroupEnum.IN_PROGRESS) {
        setInProgressState((prev) => ({
          ...prev,
          total: prev.total + 1,
          perPage: prev.perPage + 1,
          data: [...prev.data, serviceCase as ServiceCaseResponseExtended],
        }));
      } else if (statusGroup === ServiceCaseStatusGroupEnum.NEW) {
        setFilterState((state) => ({
          ...state,
          newStatuses: updateStatusGroup(state.newStatuses, serviceCase),
        }));
      } else if (statusGroup === ServiceCaseStatusGroupEnum.SCHEDULED) {
        setFilterState((state) => ({
          ...state,
          scheduledStatuses: updateStatusGroup(
            state.scheduledStatuses,
            serviceCase
          ),
        }));
      }

      setFilterState((state) => ({
        ...state,
        allStatuses: updateStatusGroup(state.allStatuses, serviceCase),
      }));
    })();
  }, []);

  const handleUpdatedServiceCase = useCallback(
    ({ serviceCaseId, modifiedFields }: SocketEvent) => {
      (async () => {
        const shouldUpdate = shouldUpdateServiceCase(modifiedFields);

        if (!shouldUpdate) return;

        const serviceCase = {
          ...(await getServiceCase(serviceCaseId)),
          quotes: [],
        };

        const statusGroup = getStatusGroup(serviceCase.caseStatus);

        setInProgressState((state) => {
          const existingServiceCase = state.data.find(
            (sc) => sc.id === serviceCaseId
          );
          const caseExists = !!existingServiceCase;

          if (
            statusGroup !== ServiceCaseStatusGroupEnum.IN_PROGRESS &&
            caseExists
          ) {
            // if service case is in progress and
            // updated service case is not in progress,
            // delete the service case from the progress group.
            return {
              ...state,
              data: state.data.filter(({ id }) => id !== serviceCaseId),
            };
          }

          if (
            statusGroup === ServiceCaseStatusGroupEnum.IN_PROGRESS &&
            !caseExists
          ) {
            return {
              ...state,
              data: [
                {
                  ...serviceCase,
                  quotes: [],
                  isNew: true,
                } as ServiceCaseResponseExtended,
              ].concat(state.data),
            };
          }

          if (
            statusGroup === ServiceCaseStatusGroupEnum.IN_PROGRESS &&
            caseExists &&
            serviceCaseNeedsUpdate(
              existingServiceCase,
              serviceCase,
              modifiedFields
            )
          ) {
            return {
              ...state,
              data: state.data.map((item) =>
                item.id === serviceCaseId
                  ? { ...serviceCase, quotes: [] }
                  : item
              ),
            };
          }

          return state;
        });

        setFilterState((state) => ({
          ...state,
          allStatuses: updateAllGroup(
            state.allStatuses,
            serviceCase,
            modifiedFields
          ),
          newStatuses: updateSpecialGroup(
            state.newStatuses,
            serviceCase as ServiceCaseResponseExtended,
            ServiceCaseStatusGroupEnum.NEW,
            modifiedFields
          ),
          scheduledStatuses: updateSpecialGroup(
            state.scheduledStatuses,
            serviceCase as ServiceCaseResponseExtended,
            ServiceCaseStatusGroupEnum.SCHEDULED,
            modifiedFields
          ),
        }));
      })();
    },
    []
  );

  useEffect(() => {
    addHandler(
      SocketEventsEnum.NEW_SERVICE_CASE_NOTIFICATION,
      handleNewServiceCase
    );

    addHandler(
      SocketEventsEnum.UPDATED_SERVICE_CASE_NOTIFICATION,
      handleUpdatedServiceCase
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DisplayModeLayout className={classes.container}>
      <Box minWidth={0}>
        <Card className={clsx(classes.card)}>
          <Loader absolute open={initProgressLoading} />
          <Typography
            color="primary"
            variant="body2"
            style={{ textTransform: "uppercase" }}
          >
            <b>in-progress ({inProgressState.total})</b>
          </Typography>
          {!!inProgressState.total && (
            <Box
              className={clsx(
                classes.scrollable,
                displayLayout === DisplayLayout.horizontal &&
                  classes.isHorizontal
              )}
              mt={4}
            >
              {inProgressState.data.map((el, idx) => (
                <React.Fragment key={el.id}>
                  <PatientStatusSnippet
                    key={el.id}
                    onMore={handleOnMoreSelection}
                    onUpdate={handleUpdate(el.id)}
                    {...el}
                  >
                    <RenderCaseStatus
                      caseStatus={el.caseStatus}
                      overdue={el.overdue}
                    />
                  </PatientStatusSnippet>
                  <div
                    ref={
                      inProgressState.data.length - 2 === idx
                        ? target
                        : undefined
                    }
                  />
                </React.Fragment>
              ))}
              <GettingMoreLoader open={gettingMore} />
            </Box>
          )}
        </Card>
      </Box>
      <Box minWidth={0}>
        <Card className={classes.card}>
          <Loader open={initFilterLoading} absolute />
          <Box>
            {[
              { label: "all", obj: filterState.allStatuses },
              { label: "new", obj: filterState.newStatuses },
              { label: "scheduled", obj: filterState.scheduledStatuses },
            ].map((btn, i) => (
              <button
                key={`${i.toString()}`}
                type="button"
                className={clsx(
                  classes.tabBtn,
                  activeTab === getTabKey(btn.label) && classes.tabActive
                )}
                onClick={onTabClick(btn.label)}
              >
                <Typography variant="button">
                  {`${btn.label} (${btn.obj.count})`}
                </Typography>
              </button>
            ))}
          </Box>
          <Box
            className={clsx(
              classes.scrollable,
              displayLayout === DisplayLayout.horizontal && classes.isHorizontal
            )}
            mt={2}
          >
            <div ref={filterTopRef} />
            {tabFilter(filterState, activeTab).serviceCases.map((el, idx) => (
              <Box key={el.id}>
                <PatientStatusSnippet
                  {...el}
                  onMore={handleOnMoreSelection}
                  onUpdate={handleUpdate(el.id)}
                >
                  <RenderCaseStatus
                    caseStatus={el.caseStatus}
                    overdue={el.overdue}
                  />
                </PatientStatusSnippet>
                <div
                  ref={
                    filterState[activeTab].data.serviceCases.length - 2 === idx
                      ? filterRef
                      : undefined
                  }
                />
              </Box>
            ))}
            <GettingMoreLoader open={gettingMoreTabbed} />
          </Box>
        </Card>
      </Box>
      <Box
        height={displayLayout === DisplayLayout.vertical ? "80vh" : "100%"}
        minWidth={0}
      >
        <RenderMap />
      </Box>
    </DisplayModeLayout>
  );
};

export default DefaultView;
