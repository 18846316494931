import { makeStyles, Typography } from "@material-ui/core";
import { useCall } from "call/hooks";
import React, { useMemo } from "react";
import { CallAttempts } from "./CallAttempts";

const useStyles = makeStyles(({ spacing: s }) => ({
  wrapper: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: s(4),
    padding: s(2),
    background: "#FFFFFF",
    boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.12)",
    borderRadius: 8,
  },
  info: {
    marginRight: s(2),
    border: "1px solid #E0E0E0",
    borderRadius: 2,
    padding: s(2),
    flex: 1,
  },
  infoText: {
    color: "#4F4F4F",
    display: "block",
  },
}));

export const ServiceCaseStatusForPatientPhysician = () => {
  const { viewedCall } = useCall();

  const { physicianName, physicianPhone, physicianEmail } = useMemo(() => {
    if (!viewedCall?.updatedServiceCase?.requestingPhysician) {
      return {
        physicianName: "",
        physicianPhone: "",
        physicianEmail: "",
      };
    }

    const { email, firstName, lastName, phone } =
      viewedCall.updatedServiceCase.requestingPhysician;

    return {
      physicianName: `${firstName || ""}${firstName ? " " : ""}${
        lastName || ""
      }`,
      physicianPhone: phone,
      physicianEmail: email,
    };
  }, [viewedCall?.updatedServiceCase?.requestingPhysician]);

  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.info}>
        <Typography variant="caption" className={classes.infoText}>
          Name: <strong>{physicianName}</strong>
        </Typography>
        <Typography variant="caption" className={classes.infoText}>
          Phone: <strong>{physicianPhone}</strong>
        </Typography>
        <Typography variant="caption" className={classes.infoText}>
          Email: <strong>{physicianEmail}</strong>
        </Typography>
      </div>

      <div>
        <CallAttempts />
      </div>
    </div>
  );
};
