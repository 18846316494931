import { PostGenerateSalesSubscriptionPaymentOrderId } from "@deep-consulting-solutions/be2-constants";

export type GeneratePaymentOrderIdBody =
  typeof PostGenerateSalesSubscriptionPaymentOrderId["Body"];

export type GeneratePaymentOrderIdResponse =
  typeof PostGenerateSalesSubscriptionPaymentOrderId["Res"]["data"];

export enum PaymentStatusReasonEnum {
  BAD_CARD = "bad-card",
  UNSUCCESSFUL = "Unsuccessful",
}

export enum PaymentCardsStepEnum {
  LIST_CARDS = "list-cards",
  SELECTED_CARD = "selected-card",
}

export enum PaymentCardsFlowEnum {
  CARDS = "Cards",
  NEW_CARD = "new-card",
}
