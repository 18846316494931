import { useState, useEffect } from "react";
import { TextField } from "formik-material-ui";
import { formatDistanceToNow } from "date-fns";
import { Form, Field, Formik, FormikConfig } from "formik";
import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import { ArrowUpward, AccessTime, ArrowDownward } from "@material-ui/icons";
import {
  SortOrderEnum,
  ServiceCaseNote,
  PostServiceCaseNote,
} from "@deep-consulting-solutions/be2-constants";

import { useCall } from "call";
import Loader from "component/Loader";
import {
  getServiceCaseNotes,
  saveServiceCaseNote,
} from "redux/serviceCase/requests";

type NoteForm = Pick<ServiceCaseNote, "title" | "content">;

const useStyles = makeStyles(({ spacing: s }) => ({
  root: {
    padding: s(2),
    background: "#FFF",
    borderRadius: s(1),
    position: "relative",
    height: "100%",
    overflow: "auto",
  },
  header: {
    display: "flex",
    marginBottom: s(3),
    alignItems: "center",
    justifyContent: "space-between",
  },
  heading: {
    fontSize: 24,
    fontWeight: 500,
    color: "#263E7F",
    lineHeight: "20px",
    fontFamily: "'Roboto', sans-serif",
  },
  form: {
    padding: s(1.5),
    marginBottom: s(3),
    borderRadius: s(1),
    background: "#F6F6F68A",
  },
  formTitle: {
    fontSize: s(2),
    fontWeight: 600,
    color: "#263E7F",
    lineHeight: "16px",
    marginBottom: s(2),
  },
  formComponent: {},
  formFields: {
    "& > :not(:last-child)": {
      marginBottom: s(2),
    },
  },
  formButtons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  titleBox: {
    maxWidth: "50%",
  },
  noteList: {
    "& > :not(:last-child)": {
      marginBottom: s(3),
    },
  },
  noteTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: "#263E7F",
    lineHeight: "16px",
    marginBottom: s(1),
  },
  noteContent: {
    color: "#4F4F4F",
  },
  noteDetails: {
    display: "flex",
    color: "#828282",
    alignItems: "center",

    "& > :first-child": {
      marginRight: s(0.5),
    },
  },
  sort: {
    display: "flex",
    alignItems: "center",
  },
  sortIcon: {
    width: 16,
    height: 16,
    color: "#0000008A",
  },
  sortLabel: {
    fontSize: 12,
    fontWeight: 400,
    color: "#263E7F",
    lineHeight: "18px",
    textTransform: "none",
    fontFamily: "'Roboto', sans-serif",
  },
  accessTime: {
    width: s(1.5),
    height: s(1.5),
  },
}));

export const CallNotes = () => {
  const classes = useStyles();
  const { viewedCall } = useCall();
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState<ServiceCaseNote[]>([]);
  const [sortOrder, setSortOrder] = useState<SortOrderEnum>(SortOrderEnum.ASC);

  const saveNote: FormikConfig<NoteForm>["onSubmit"] = async (
    values,
    { resetForm, setSubmitting }
  ) => {
    try {
      setLoading(true);

      const body: typeof PostServiceCaseNote.Body = {
        content: values.content,
      };

      if (values.title) body.title = values.title;

      const newNote = await saveServiceCaseNote(
        viewedCall?.updatedServiceCase?.id || "",
        body
      );

      setNotes((prevValue) => {
        if (sortOrder === SortOrderEnum.ASC) {
          return [newNote, ...prevValue];
        }

        return [...prevValue, newNote];
      });

      resetForm();
    } catch {
      //
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  const toggleSort = () => {
    setSortOrder((prevOrder) => {
      if (prevOrder === SortOrderEnum.ASC) return SortOrderEnum.DESC;
      return SortOrderEnum.ASC;
    });
  };

  useEffect(() => {
    const id = viewedCall?.updatedServiceCase?.id || "";

    if (id) {
      (async () => {
        try {
          setLoading(true);
          const { notes: res } = await getServiceCaseNotes(id, {
            page: "1",
            sortOrder,
          });
          setNotes(res);
        } catch {
          //
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [sortOrder, viewedCall?.updatedServiceCase?.id]);

  return (
    <Box className={classes.root}>
      <Loader open={loading} absolute />

      <Box className={classes.header}>
        <Typography component="h2" className={classes.heading}>
          Notes
        </Typography>

        {notes.length ? (
          <Box className={classes.sort}>
            <Button
              variant="text"
              onClick={toggleSort}
              startIcon={
                sortOrder === SortOrderEnum.ASC ? (
                  <ArrowUpward className={classes.sortIcon} />
                ) : (
                  <ArrowDownward className={classes.sortIcon} />
                )
              }
            >
              <Typography className={classes.sortLabel}>
                {sortOrder === SortOrderEnum.ASC
                  ? "Latest first"
                  : "Oldest first"}
              </Typography>
            </Button>
          </Box>
        ) : null}
      </Box>

      <Box className={classes.form}>
        <Typography className={classes.formTitle}>New Note</Typography>

        <Formik<NoteForm>
          onSubmit={saveNote}
          initialValues={{ title: "", content: "" }}
        >
          {({ values }) => {
            return (
              <Form className={classes.formComponent}>
                <Box className={classes.formFields}>
                  <Box className={classes.titleBox}>
                    <Field
                      fullWidth
                      size="small"
                      name="title"
                      label="Title"
                      component={TextField}
                    />
                  </Box>

                  <Box>
                    <Field
                      rows={3}
                      required
                      multiline
                      fullWidth
                      size="small"
                      name="content"
                      label="Content"
                      component={TextField}
                    />
                  </Box>

                  <Box className={classes.formButtons}>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={!values.content}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>

      <Box className={classes.noteList}>
        {notes.map(({ title, content, createdAt, createdBy }) => (
          <Box>
            {title && (
              <Typography component="h3" className={classes.noteTitle}>
                {title}
              </Typography>
            )}

            <Typography className={classes.noteContent}>{content}</Typography>

            <Box className={classes.noteDetails}>
              <AccessTime className={classes.accessTime} />

              <Typography>
                {formatDistanceToNow(new Date(createdAt || Date.now()))} ago by
                - <Typography component="span">{createdBy.name}</Typography>
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
