import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { PatientResponseWithContact } from "@deep-consulting-solutions/be2-constants";

import { formatNameToDisplay } from "helpers";
import { CollapsedCard } from "component/CollapsedCard";
import ViewIdentificationDocuments from "component/ViewIdentificationDocuments";

import { Item } from "./Item";
import { convertYesNoBoolean, displayTime } from "./helpers";
import { getAge } from "../../RequesterAndPatientForm/helpers";

const useStyles = makeStyles(({ spacing: s }) => ({
  wrapper: {
    margin: s(1, 0),
    background: "#FFFFFF",
    boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.12)",
    borderRadius: 8,
    padding: s(2),
  },

  contact: {
    background: "#FFFFFF",
    border: "1px solid #E0E0E0",
    borderRadius: 2,
    padding: s(2),
    margin: s(2, 0),
  },
  medical: {
    background: "#FFFFFF",
    border: "1px solid #E0E0E0",
    borderRadius: 2,
    padding: s(2),
    margin: s(2, 0),
  },
  view: {
    outline: 0,
    border: 0,
    background: "none",
    textDecoration: "underline",
    color: "#2F80ED",
    fontSize: 12,
    fontWeight: 700,
    padding: 0,
    margin: 0,
    cursor: "pointer",
  },
  nextOfKin: {
    background: "#FFFFFF",
    border: "1px solid #E0E0E0",
    borderRadius: 2,
    padding: s(2),
    margin: s(2, 0),
  },
  nextOfKinTitle: {
    color: "#263E7F",
    fontSize: 14,
  },
}));

interface Props {
  patient: PatientResponseWithContact;
}
export const PatientMedicalInformation = ({ patient }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Typography color="primary" variant="h5" align="left">
        {patient.firstName} {patient.lastName}
      </Typography>

      <Typography color="primary" variant="caption" align="left">
        Coverage: <strong>{patient.coverageDetails?.coverageStatus}</strong>
      </Typography>

      <div className={classes.contact}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Item
            title="Age"
            value={patient.age || (patient.dob ? getAge(patient.dob) : "")}
          />
          <Item title="Blood Type" value={patient.bloodType} />
          <Item title="Primary Physician" value={patient.primaryPhysician} />
          <Item title="Phone" value={patient.phone} />
          <Item title="Preferred Hospital" value={patient.preferredHospital} />
          <Item value={<ViewIdentificationDocuments patient={patient} />} />
          <Item title="Medical Status" value={patient.medicalStatus} />
        </Grid>
      </div>

      <div className={classes.medical}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Item title="Level of Emergency" value={patient.levelOfEmergency} />
          <Item
            title="Preferred Date and Time of Arrival:"
            value={
              patient.preferredDestinationArrivalTime
                ? displayTime(patient.preferredDestinationArrivalTime)
                : "-"
            }
          />
          <Item title="Medical Needs" value={patient.medicalNeeds} />
          <Item
            title="Patient can fly commercially"
            value={patient.canFlyCommercially}
          />
          <Item
            title="Requires Medical Escort on the Commercial Flight"
            value={convertYesNoBoolean(patient.comercialFlightMedicalEscort)}
          />
        </Grid>
      </div>

      <div className={classes.nextOfKin}>
        <CollapsedCard
          title={
            <Typography className={classes.nextOfKinTitle}>
              Next of Kin:{" "}
              <b>
                {formatNameToDisplay(
                  patient.nextOfKin?.firstName,
                  patient.nextOfKin?.lastName
                )}
              </b>
            </Typography>
          }
        >
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Item
              title="Relation to Patient"
              value={patient.nextOfKin?.relationToPatient}
            />
            <Item title="Email" value={patient.nextOfKin?.email} />
            <Item title="Phone" value={patient.nextOfKin?.phone} />
          </Grid>
        </CollapsedCard>
      </div>
    </div>
  );
};
