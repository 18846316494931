import {
  NextOfKinData,
  ServiceCasePatientData,
  RequesterResponse,
  GenderEnum,
  BloodTypeEnum,
  PatientLevelOfEmergencyEnum,
  YesNoEnum,
} from "@deep-consulting-solutions/be2-constants";

export type PatientData = Partial<ServiceCasePatientData> & {
  id?: string;
  nextOfKinMembership?: string;
  canFlyCommercial?: boolean;
  medicalEscort?: boolean;
};

export interface FormValues {
  requester: Partial<RequesterResponse> & {
    nextOfKinMembership?: string;
    preferredHospitalId?: string;
    bloodType?: BloodTypeEnum;
    patientWeight?: number;
    primaryPhysicianName?: string;
    primaryPhysicianPhone?: string;
    levelOfEmergency?: PatientLevelOfEmergencyEnum;
    medicalNeeds?: string;
    canFlyCommercially?: YesNoEnum;
    requiresMedicalEscort?: YesNoEnum;
    preferredDestinationArrivalTime?: string;
    canFlyCommercial?: boolean;
    medicalEscort?: boolean;
  };
  patients: PatientData[];
}

export interface NextOfKinDisabledFields {
  firstName: boolean;
  lastName: boolean;
  phone: boolean;
  email: boolean;
  relationshipToPatient: boolean;
  otherRelationToPatient?: boolean;
}

export interface NextOfKinFormProps {
  formKey: string;
  nextOfKin?: Partial<NextOfKinData>;
  disabledFields?: Partial<NextOfKinDisabledFields>;
}

export enum MembershipTypeEnum {
  MainBeneficiary = "Main Beneficiary",
  Dependent = "Dependent",
  EmergencyContact = "Emergency Contact",
}

export interface Membership {
  type: MembershipTypeEnum;
  number: number | string;
}
export interface Member {
  id: string;
  firstName: string;
  lastName: string;
  memberships: Membership[];
  dob: string | Date;
  citizenships: string[];
  address?: string;
  email?: string;
  phone?: string;
  gender?: GenderEnum;
}

export enum NextOfKinTypeEnum {
  NEW = "New",
  NONE = "None",
  NEXT_OF_KIN = "Next of Kin",
}
