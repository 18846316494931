import { createContext } from "react";
import { CallContextInterface, CallLayoutEnum } from "../types";

export const CallContext = createContext<CallContextInterface>({
  calls: [],
  cutCalls: [],
  createEndCallRequest: () => {},
  createPickCallRequest: () => {},
  viewCall: () => {},
  chatOpen: false,
  stopVideo: false,
  layout: CallLayoutEnum.FORM_MAP,
  handleSwitchLayout: () => {},
  handleOpenChat: () => {},
  handleOpenVideo: () => {},
  completeEndCallRequest: async () => {},
  cancelEndCallRequest: () => {},
  completePickCallRequest: async () => {},
  cancelPickCallRequest: () => {},
  putCallOnHold: async () => {},
  resumeCallFromHold: async () => {},
  chatMessages: {},
  postMessageRequest: async () => {},
  removeCutCall: () => {},
  updateCallRequesterGeoLocation: () => {},
  updateCallRequesterInitialAssessment: () => {},
  updateCallServiceCase: () => {},
  openTokSessions: {},
  updateCall: () => {},
  handleMuteCall: () => {},
  mutedMicCalls: {},
});
