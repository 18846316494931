import { Theme, makeStyles, ClickAwayListener } from "@material-ui/core";
import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";

import { DMStoDD } from "helpers";
import { useCall } from "call/hooks";
import { useMap } from "call/context/Map";
import {
  BahamasDefaultLocation,
  BahamasDefaultGeocoderResult,
} from "pages/FulfilmentDashboard/constants";

import { MapErrorComponent } from "./MapErrorComponent";
import { MapLoadingComponent } from "./MapLoadingComponent";

interface StyleProps {
  listener: string;
}

const useStyles = makeStyles<Theme, StyleProps>(({ palette: p }) => ({
  wrapper: {
    height: "100%",
    width: "100%",
    background: "white",
    borderRadius: 8,
    position: "relative",
    border: ({ listener }) =>
      listener ? `3px solid ${p.primary.main}` : "none",
  },
}));

export const MapComponent = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [hasInitialLocation, setHasInitialLocation] = useState(false);
  const { viewedCall, updateCallRequesterGeoLocation, viewedCallSessionId } =
    useCall();

  const {
    createMap,
    listener,
    removeListenerFromMap,
    createInitialLocationMarker,
  } = useMap();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>();

  const getLongitudeAndLatitudeFromAddress = useCallback(async () => {
    try {
      if (viewedCall?.requesterGeoLocation) {
        return;
      }

      if (!viewedCall?.updatedServiceCase) {
        setLoading(false);
        return;
      }

      const geocoder = new google.maps.Geocoder();
      let location = viewedCall.updatedServiceCase?.requesterLocation;
      const address = viewedCall?.updatedServiceCase?.requesterAddress;

      if (!(location || address)) {
        // set default location to Bahamas.
        location = BahamasDefaultLocation;
      } else {
        setHasInitialLocation(true);
      }

      setLoading(true);
      setError(undefined);

      const request: {
        address?: string;
        location?: google.maps.LatLngLiteral;
      } = {};

      if (location) {
        const [lat, lng] = DMStoDD(location);
        request.location = { lat, lng };
      } else if (address) {
        request.address = address;
      }

      await new Promise((resolve) => {
        geocoder.geocode(request, (results, status) => {
          updateCallRequesterGeoLocation(
            viewedCallSessionId || "",
            status === "OK" && results && results[0]
              ? results[0]
              : (BahamasDefaultGeocoderResult as unknown as google.maps.GeocoderResult),
            location === BahamasDefaultLocation
          );

          resolve(true);
        });
      });
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setLoading(false);
    }
  }, [
    viewedCallSessionId,
    viewedCall?.updatedServiceCase,
    updateCallRequesterGeoLocation,
    viewedCall?.requesterGeoLocation,
  ]);

  const location = useMemo(() => {
    const serviceCaseLocation = viewedCall?.updatedServiceCase?.initialLocation;

    if (serviceCaseLocation) {
      const [lat, lng] = serviceCaseLocation.split(",").map(Number);
      if (lat && lng) return { lat, lng };
    }

    return viewedCall?.requesterGeoLocation?.geometry?.location;
  }, [
    viewedCall?.updatedServiceCase?.initialLocation,
    viewedCall?.requesterGeoLocation?.geometry.location,
  ]);

  useEffect(() => {
    if (ref.current && location) {
      createMap(ref.current, {
        center: location,
        mapTypeControl: false,
        streetViewControl: false,
        zoom: hasInitialLocation ? 12 : 9,
      });

      createInitialLocationMarker({
        position: location,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, location]);

  const handleClickAway = useCallback(() => {
    if (listener) {
      removeListenerFromMap("click");
    }
  }, [listener, removeListenerFromMap]);

  useEffect(() => {
    getLongitudeAndLatitudeFromAddress();
  }, [getLongitudeAndLatitudeFromAddress]);

  const classes = useStyles({ listener });

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.wrapper} ref={ref} id="call-form-map">
        <div className={classes.wrapper}>
          {error ? <MapErrorComponent message={error} /> : null}
          {loading ? <MapLoadingComponent /> : null}
        </div>
      </div>
    </ClickAwayListener>
  );
};
