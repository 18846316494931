import React from "react";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import { Field, Form, Formik, FormikConfig } from "formik";

import { VALIDATIONS } from "helpers";
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from "component/ConfirmationDialog";

interface RejectServiceDialogProps extends ConfirmationDialogProps {
  placeholder: string;
  onSubmission: FormikConfig<{ reason: string }>["onSubmit"];
}

export const RejectServiceDialog = ({
  placeholder,
  onSubmission,
  ...rest
}: RejectServiceDialogProps) => {
  return (
    <Formik
      onSubmit={onSubmission}
      initialValues={{ reason: "" }}
      validationSchema={Yup.object().shape({ reason: VALIDATIONS.reason })}
    >
      {({ submitForm }) => {
        return (
          <ConfirmationDialog
            {...rest}
            fullWidth
            maxWidth="sm"
            onConfirm={submitForm}
          >
            <Form>
              <Field
                required
                rows={4}
                multiline
                name="reason"
                component={TextField}
                placeholder={placeholder}
              />
            </Form>
          </ConfirmationDialog>
        );
      }}
    </Formik>
  );
};
