import React from "react";
import { Switch } from "react-router-dom";
import "services/pkPortals";
import { DispatcherActivityLog, FulfilmentWebtab } from "pages";
import { ROUTES } from "component/configs";
import { ZohoRoute } from "component/ZohoRoute";
import Root from "Root";
import store from "redux/store";

export default () => {
  return (
    <Root store={store}>
      <Switch>
        <ZohoRoute
          path={ROUTES.dispatcherActivity.path}
          component={DispatcherActivityLog}
          init
          exact
        />
        <ZohoRoute
          path={ROUTES.fulfilment.path}
          init
          component={FulfilmentWebtab}
        />
      </Switch>
    </Root>
  );
};
