import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Grid,
  makeStyles,
  Typography,
  MenuItem,
  Divider,
} from "@material-ui/core";
import { getMedicalDirectors } from "redux/call/requests";
import { TextField } from "formik-material-ui";
import { useFormikContext, Field } from "formik";
import Loader from "component/Loader";
import { useCall } from "call/hooks";
import { MedicalDirectorType } from "redux/call/types";
import { ServiceCaseStatusEnum } from "@deep-consulting-solutions/be2-constants";
import { ServiceTitle } from "../components";
import {
  getIsServiceCaseApproved,
  getIsServiceCaseRefused,
  getIsServiceToBeRenderedEmergencyAirliftEvacuationOrHospitalTransferOrRepatriationOrCustom,
} from "../helpers";
import { FormValues } from "../types";
import { MedicalDirectorRow } from "./MedicalDirectorRow";

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  divider: {
    margin: s(2, 0),
  },
  select: {
    width: "100%",
  },
  info: {
    color: p.grey[400],
    margin: s(1, 0),
    padding: s(0),
  },
  wrapper: {
    marginBottom: s(6),
  },
  serviceTitleWithStatus: {},
}));

export const MedicalDirector = () => {
  const [loading, setLoading] = useState(false);
  const { viewedCall } = useCall();
  const [medicalProfessionals, setMedicalProfessionals] = useState<
    MedicalDirectorType[]
  >([]);
  const [timeOutID, setTimeOutID] = useState<NodeJS.Timeout | undefined>();
  const {
    values: { serviceToBeRendered },
  } = useFormikContext<FormValues>();

  const { visible } = useMemo(() => {
    return {
      visible:
        !!serviceToBeRendered &&
        getIsServiceToBeRenderedEmergencyAirliftEvacuationOrHospitalTransferOrRepatriationOrCustom(
          serviceToBeRendered
        ),
    };
  }, [serviceToBeRendered]);

  const fetchMedicalProfessionals = useCallback(
    async (useLoading = true, count = 0) => {
      try {
        if (useLoading) {
          setLoading(true);
        }
        const res = await getMedicalDirectors();
        setMedicalProfessionals(res);
      } catch {
        if (count < 5) {
          const timeOutId = setTimeout(() => {
            fetchMedicalProfessionals(false, Number(count) + 1);
          }, 1000);
          setTimeOutID(timeOutId);
        }
      } finally {
        if (useLoading) {
          setLoading(false);
        }
      }
    },
    []
  );
  const classes = useStyles();

  useEffect(() => {
    fetchMedicalProfessionals();
  }, [fetchMedicalProfessionals]);

  useEffect(() => {
    return () => {
      if (timeOutID) {
        clearTimeout(timeOutID);
      }
    };
  }, [timeOutID]);

  const isServiceCaseAccepted = useMemo(() => {
    return getIsServiceCaseApproved(
      viewedCall?.updatedServiceCase?.approvalRequests
    );
  }, [viewedCall?.updatedServiceCase?.approvalRequests]);

  const isServiceCaseRefused = useMemo(
    () =>
      getIsServiceCaseRefused(viewedCall?.updatedServiceCase?.approvalRequests),
    [viewedCall?.updatedServiceCase?.approvalRequests]
  );

  const medicalDirectorName = useMemo(
    () => viewedCall?.updatedServiceCase?.medicalDirector?.zohoUser?.name || "",
    [viewedCall?.updatedServiceCase?.medicalDirector]
  );

  const disableForm = useMemo(
    () =>
      !!viewedCall?.disableForms ||
      viewedCall?.updatedServiceCase?.caseStatus !==
        ServiceCaseStatusEnum.GATHERING_REQUIREMENTS,
    [viewedCall?.disableForms, viewedCall?.updatedServiceCase?.caseStatus]
  );

  if (!visible) {
    return null;
  }

  if (isServiceCaseAccepted || isServiceCaseRefused) {
    return (
      <div>
        <Typography
          color="primary"
          variant="subtitle2"
          className={classes.serviceTitleWithStatus}
        >
          Medical Director: <strong>{medicalDirectorName}</strong>
        </Typography>

        <Divider className={classes.divider} />
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <Loader open={loading} />
      <ServiceTitle title="Medical Director" />

      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Field
            component={TextField}
            name="medicalDirectorId"
            placeholder="Medical director"
            label="Medical director"
            select
            SelectProps={{
              displayEmpty: false,
            }}
            required
            disabled={!medicalProfessionals.length || disableForm}
          >
            {medicalProfessionals.map((medicalProfessional) => {
              return (
                <MenuItem
                  key={medicalProfessional.id}
                  value={medicalProfessional.id}
                >
                  <MedicalDirectorRow
                    medicalProfessional={medicalProfessional}
                  />
                </MenuItem>
              );
            })}
          </Field>
          <Typography variant="caption" className={classes.info}>
            Select a medical director for this case
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
    </div>
  );
};
