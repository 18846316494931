import { makeStyles } from "@material-ui/core";
import React from "react";
import { CallMedia } from "./CallMedia";
import { CallMessages } from "./CallMessages";
import { CallRecordings } from "./CallRecordings";
import { CallChatTabProps, TabsEnum } from "./types";

interface Props extends CallChatTabProps {
  index: number;
  value: number;
  label: TabsEnum;
}

const useStyles = makeStyles(() => ({
  wrapper: {
    height: "100%",
    overflow: "hidden",
  },
}));

export const CallChatTabPanel = ({
  value,
  index,
  id,
  label,
  ...props
}: Props) => {
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={props["aria-controls"]}
      aria-labelledby={id}
      className={classes.wrapper}
    >
      {label === TabsEnum.MESSAGES && <CallMessages />}
      {label === TabsEnum.MEDIA && <CallMedia />}
      {label === TabsEnum.RECORDINGS && <CallRecordings />}
    </div>
  );
};
