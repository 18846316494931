/*
 * Environment details
 *
 * Contains information about the current environment.
 *
 * @property name The name of the Environment (Chrome, FF, Node, etc)
 * @property version Usually a Float, except in Node which uses a String
 * @property userAgent The raw user agent
 * @property isiOS Whether the client is on iOS
 * @property isElectron Whether the client is Electron
 * @property isOpera Whether the client is Opera
 * @property isFirefox Whether the client is Firefox
 * @property isEdge Whether the client is isEdge (legacy or chromiumedge)
 * @property isChromiumEdge Whether the client is isChromiumEdge (version >= 79)
 * @property isLegacyEdge Whether the client is isLegacyEdge (version < 79)
 * @property isLegacyElectron Whether the client is isLegacyElectron (version < 2)
 * @property isLegacyChrome Whether the client is isLegacyChrome (version < 50)
 * @property isAndroid Whether the client is an Android device
 * @property protocol The protocol property of the URL
 * @property hostName The domain of the URL
 */

const { isiPhone, isiPad, isiOS } = require('./isiOS');
const iOSVersion = require('./iOSVersion');
const getElectronVersion = require('./get-electron-version');

const isElectron = (navigator) => {
  // eslint-disable-next-line no-param-reassign
  navigator = navigator || global?.navigator;

  return navigator?.userAgent.toLowerCase().indexOf('electron') >= 0;
};

const isNode = () => typeof global?.process?.versions?.node === 'string' && !isElectron();

let env;

const resetEnv = () => {
  env = {
    // @todo why is the default -1?;
    version: -1,
    name: 'unknown',
  };
};

const parseNode = () => {
  env.name = 'Node';
  env.isNode = true;
  env.version = parseInt(process.versions.node, 10);
  env.userAgent = `${env.name} ${env.version}`;
};

const parseBrowser = (navigator) => {
  // eslint-disable-next-line global-require, no-param-reassign
  navigator = navigator || global.navigator;

  env.userAgent = navigator.userAgent.toLowerCase(); // @todo why lowercase?
  env.name = 'unknown';

  if (env.userAgent.indexOf('opera') > -1 || env.userAgent.indexOf('opr/') > -1) {
    env.name = 'Opera';
    env.isOpera = true;

    if (/opr\/([0-9]{1,}[.0-9]{0,})/.exec(env.userAgent) !== null) {
      env.version = parseFloat(RegExp.$1);
    }
  } else if (env.userAgent.indexOf('firefox') > -1) {
    env.name = 'Firefox';
    env.isFirefox = true;

    if (/firefox\/([0-9]{1,}[.0-9]{0,})/.exec(env.userAgent) !== null) {
      env.version = parseFloat(RegExp.$1);
    }
  } else if (env.userAgent.match(/edge?\/(\d+)(?:\.\d+)+$/)) {
    env.name = 'Edge';
    env.isEdge = true;

    const version = RegExp.$1;

    env.version = parseInt(version, 10);
  } else if (env.userAgent.indexOf('chrome') > -1) {
    env.name = 'Chrome';
    env.isElectron = isElectron(navigator);

    if (!env.isElectron) {
      env.isChrome = true;
    } else {
      env.electronVersion = getElectronVersion(navigator.userAgent);
    }

    if (/chrome\/([0-9]{1,}[.0-9]{0,})/.exec(env.userAgent) !== null) {
      env.version = parseFloat(RegExp.$1);
    }
  } else if (navigator.vendor &&
    navigator.vendor.toLowerCase().indexOf('apple') > -1) {
    env.name = 'Safari';
    env.isSafari = true;
    env.isiPhone = isiPhone(navigator.userAgent);
    env.isiPad = isiPad(navigator.userAgent);
    env.isiOS = isiOS(navigator.userAgent);
    if (env.isiOS) {
      env.iOSVersion = iOSVersion(navigator.userAgent);
    }
    if (/version\/([0-9]{1,}[.0-9]{0,})/.exec(env.userAgent) !== null) {
      env.version = parseFloat(RegExp.$1);
    } else if (/fxios\/([0-9]{1,}[.0-9]{0,})/.exec(env.userAgent) !== null) {
      // This gets the version from Firefox in iOS
      env.version = parseFloat(RegExp.$1);
    } else if (/crios\/([0-9]{1,}[.0-9]{0,})/.exec(env.userAgent) !== null) {
      // This gets the version from Chrome in iOS
      env.version = parseFloat(RegExp.$1);
    }
  } else if (env.userAgent.indexOf('applewebkit') > -1) {
    // From https://stackoverflow.com/questions/4460205/detect-ipad-iphone-webview-via-javascript
    if (env.userAgent.indexOf('version') === -1) {
      env.isIosWebView = true;
    }
  }

  // The Chromium-based Edge was released on this version
  env.isChromiumEdge = env.name === 'Edge' && env.version >= 79;

  // Prior versions ofo Edge versions were based on a proprietary, non-Chromium
  // code base
  env.isLegacyEdge = env.name === 'Edge' && env.version < 79;

  env.isLegacyElectron = env.isElectron && env.electronVersion < 2;

  env.isLegacyChrome = env.isChrome && env.version < 50;

  env.isChromium = !!(env.isChrome || env.isChromiumEdge || env.isElectron || env.isOpera);

  env.isAndroid = env.userAgent.indexOf('android') > -1;

  env.userAgent = navigator.userAgent;

  // eslint-disable-next-line no-undef
  env.protocol = location.protocol;
  // eslint-disable-next-line no-undef
  env.hostName = location.hostName;

  if (env.version === -1 || env.name === 'unknown' || (env.isiOS && !env.iOSVersion)) {
    env.error = true;
  }
};

const parse = (navigator) => {
  // We clear env since we have tests which pass in mocked user agent strings.
  resetEnv();

  if (isNode()) {
    parseNode();
  } else {
    parseBrowser(navigator);
  }

  return env;
};

module.exports = {
  parse,
  isNode,
};
