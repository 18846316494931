import React, { useMemo } from "react";
import {
  makeStyles,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { format } from "date-fns";
import { useCall } from "call/hooks";

const tableHeaders = ["Time of the Attempt"];

const useStyles = makeStyles(({ spacing: s }) => ({
  tableContainer: {
    filter: "drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.12))",
    borderRadius: 8,
  },
  tableRow: {
    padding: s(1),
    background: "#FFFFFF",
    boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",
  },
  tableHead: {
    background: "#F6F6F6",
    boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    color: "#4F4F4F",
    marginBottom: s(2),
  },
  tableCellTitle: {
    fontWeight: 400,
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: "center",
    display: "block",
  },
  tableCell: {
    display: "block",
  },
  time: {
    fontWeight: "lighter",
  },
}));

export const CallAttempts = () => {
  const { viewedCall } = useCall();
  const classes = useStyles();

  const { callAttempts } = useMemo(() => {
    return {
      callAttempts: viewedCall?.updatedServiceCase?.callBackLogs || [],
    };
  }, [viewedCall?.updatedServiceCase?.callBackLogs]);

  return (
    <>
      <Typography variant="subtitle2" className={classes.title}>
        Call Attempts ({callAttempts.length})
      </Typography>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <TableHead className={classes.tableHead}>
          <TableRow className={classes.tableRow}>
            {tableHeaders.map((header) => (
              <TableCell key={header}>
                <Typography
                  variant="caption"
                  className={classes.tableCellTitle}
                  align="center"
                >
                  {header}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {callAttempts.map((callAttempt) => (
            <TableRow key={callAttempt.id}>
              <TableCell>
                <Typography
                  className={classes.tableCell}
                  align="center"
                  color="primary"
                  variant="caption"
                >
                  {format(new Date(callAttempt.callBackTime), "dd MMM yyyy")}
                  <span className={classes.time}>
                    {format(new Date(callAttempt.callBackTime), "h:m:s a")}
                  </span>
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
    </>
  );
};
