import React from "react";
import { Field, FormikProps } from "formik";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import { Box, FormHelperText, makeStyles, Typography } from "@material-ui/core";
import { PatientResponseWithContact } from "@deep-consulting-solutions/be2-constants";

import { formatNameToDisplay } from "helpers";
import { ConfirmationDialog } from "component/ConfirmationDialog";

const useStyles = makeStyles(({ spacing: s }) => ({
  ncpWrapper: {
    padding: s(2),
    borderRadius: s(0.5),
    marginBottom: s(2.5),
    border: "1px solid #BDBDBD",
  },
  ncpTitle: {
    fontSize: 20,
    fontWeight: 500,
    color: "#263E7F",
    lineHeight: "20px",
    marginBottom: s(2),
    fontFamily: "'Roboto', sans-serif",
  },
  ncp: {
    color: "#263E7F",
    marginBottom: s(1),
  },
  checkboxGroup: {
    marginBottom: s(2.5),
  },
  checkboxLabel: {
    fontWeight: 500,
    color: "#263E7F",
  },
  confirmButton: {
    backgroundColor: "#263E7F",
  },
}));

interface SubCoveragePaymentDialogProps<T> {
  onClose: () => void;
  formikProps: FormikProps<T>;
  uncoveredPatients: PatientResponseWithContact[];
}

export function SubCoveragePaymentDialog<T extends Record<string, any>>({
  onClose,
  uncoveredPatients,
  formikProps: { errors, isValid, submitForm },
}: SubCoveragePaymentDialogProps<T>) {
  const classes = useStyles();

  return (
    <ConfirmationDialog
      open
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      onConfirm={submitForm}
      disableConfirmButton={!isValid}
      title="Confirm Receiving Service Payment"
      confirmButtonClassName={classes.confirmButton}
    >
      <Box className={classes.ncpWrapper}>
        <Typography className={classes.ncpTitle}>
          Non-Covered Patients
        </Typography>

        <Box>
          {uncoveredPatients.map((patient) => (
            <Typography className={classes.ncp} key={patient.patientID}>
              {formatNameToDisplay(patient.firstName, patient.lastName)}
            </Typography>
          ))}
        </Box>
      </Box>

      <Box className={classes.checkboxGroup}>
        <Field
          required
          color="primary"
          type="checkbox"
          component={CheckboxWithLabel}
          name="confirmPaymentReceived"
          Label={{
            label: (
              <Typography className={classes.checkboxLabel}>
                Payment for the service has been received.
              </Typography>
            ),
          }}
        />

        {errors.confirmPaymentReceived && (
          <FormHelperText error>{errors.confirmPaymentReceived}</FormHelperText>
        )}
      </Box>

      <Field
        required
        multiline
        fullWidth
        minRows={2}
        maxRows={2}
        component={TextField}
        name="paymentDetails"
        label="Payment Details"
      />
    </ConfirmationDialog>
  );
}
