import React, { memo, useState, useEffect, useCallback } from "react";
import { RouteProps, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

import { AppDispatch } from "redux/store";
import { zohoActions, transformInitZohoPayload } from "redux/zoho";
import Loader from "component/Loader";
import ErrorMessage from "component/ZohoErrorMessage";

import { ZohoRouteServiceEnum } from "./ZohoRoute.types";

interface ZohoRouteProps extends RouteProps {
  init?: boolean;
  entity?: boolean;
  services?: ZohoRouteServiceEnum | ZohoRouteServiceEnum[];
}

export const ZohoRoute: React.FC<ZohoRouteProps> = ({
  init,
  entity,
  services,
  component,
  render,
  ...props
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>();

  const returnWithError = useCallback(
    (errorMessage = "Something went wrong") => {
      setError((current: any) => current || errorMessage);
      return false;
    },
    []
  );

  const initZoho = useCallback(async () => {
    const res = await dispatch(zohoActions.initZoho());

    if (!zohoActions.initZoho.fulfilled.match(res)) {
      return returnWithError();
    }
    if (entity) {
      const entityError = "Cannot find zoho entity";
      if (!res.payload) {
        return returnWithError(entityError);
      }
      const { ids } = transformInitZohoPayload(res.payload);
      if (!ids || !ids.length) {
        return returnWithError(entityError);
      }
      return ids;
    }
    return true;
  }, [entity, dispatch, returnWithError]);

  const getToken = useCallback(async () => {
    const res = await dispatch(zohoActions.getZohoToken());
    if (!zohoActions.getZohoToken.fulfilled.match(res)) {
      return returnWithError("Cannot get Zoho token");
    }
    return true;
  }, [returnWithError, dispatch]);

  const fetchRecords = useCallback(async () => {
    const res = await dispatch(zohoActions.fetchRecords());
    if (!zohoActions.fetchRecords.fulfilled.match(res)) {
      return returnWithError("Cannot get Zoho Records");
    }
    return true;
  }, [returnWithError, dispatch]);

  const fetchCurrentUser = useCallback(async () => {
    const res = await dispatch(zohoActions.fetchCurrentUser());
    if (!zohoActions.fetchCurrentUser.fulfilled.match(res)) {
      return returnWithError("Cannot get Zoho Current User");
    }
    return true;
  }, [returnWithError, dispatch]);

  const runServices = useCallback(async () => {
    setLoading(true);
    try {
      const tasks: Promise<boolean>[] = [];

      if (init) {
        const inited = await initZoho();

        if (!inited) {
          setLoading(false);
          return;
        }
        tasks.push(getToken());
      }

      let serviceArray: ZohoRouteServiceEnum[] = [];
      if (services) {
        serviceArray = Array.isArray(services) ? services : [services];
      }

      serviceArray.forEach((service) => {
        if (service === ZohoRouteServiceEnum.records) {
          tasks.push(fetchRecords());
        } else if (service === ZohoRouteServiceEnum.currentUser) {
          tasks.push(fetchCurrentUser());
        }
      });

      await Promise.all(tasks);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [init, services, initZoho, getToken, fetchRecords, fetchCurrentUser]);

  useEffect(() => {
    runServices();
  }, [runServices]);

  const getComponent = (): {
    component?: typeof component | (() => JSX.Element);
    render?: typeof render;
  } => {
    if (loading) {
      return {
        component: () => <Loader open />,
      };
    }
    if (error) {
      return {
        component: () => <ErrorMessage message={error} />,
      };
    }

    return {
      component,
      render,
    };
  };

  return <Route {...getComponent()} {...props} />;
};

export const MemoZohoRoute = memo(ZohoRoute);
