import React, { FC, useCallback, useState } from "react";
import {
  ServiceCaseResponse,
  PatchInitialAssessment,
  ServiceCaseRequestTypeEnum,
} from "@deep-consulting-solutions/be2-constants";
import { ddToDMS } from "helpers";
import { DashboardMockCallContext } from "./context";
import { Call, CustomGeoCodeResult } from "../types";

export type SoundVariant = "emergency" | "incoming" | "check-in" | "unanswered";

export const DashboardMockCallProvider: FC = ({ children }) => {
  const [call, setCall] = useState<Call | undefined>(undefined);

  const viewCall = useCallback(() => {
    //
  }, []);

  const setDashboardCall = useCallback((serviceCase: ServiceCaseResponse) => {
    setCall({
      isEmergency:
        serviceCase.requestType === ServiceCaseRequestTypeEnum.EMERGENCY,
      fullName: `${serviceCase.requester?.firstName || ""} ${
        serviceCase.requester?.lastName || ""
      }`.trim(),
      id: "id",
      isOnHold: false,
      timeOfPickUp: "2022",
      isRinging: false,
      updatedServiceCase: serviceCase,
      isCut: false,
      isUnanswered: false,
      isEnded: false,
      disableForms: false,
      sessionId: "sessionID",
      dispatcherIds: [],
      requestType: serviceCase.requestType,
      requestOrigin: serviceCase.requestOrigin,
      firstName: serviceCase.requester?.firstName || "",
      lastName: serviceCase.requester?.lastName || "",
    });
  }, []);

  const updateCallRequesterGeoLocation = useCallback(
    (
      sessionId: string,
      requesterGeoLocation: google.maps.GeocoderResult,
      isDefaultLocation = false
    ) => {
      const locationObject = requesterGeoLocation.geometry.location.toJSON();
      const { lat, lng } = locationObject;
      const dms = ddToDMS(lat, lng);

      setCall((c) =>
        c
          ? {
              ...c,
              updatedServiceCase: {
                ...((c.updatedServiceCase || {}) as ServiceCaseResponse),
                requesterLocation: dms,
              },
              requesterGeoLocation: {
                ...((c.requesterGeoLocation || {}) as CustomGeoCodeResult),
                address_components: requesterGeoLocation.address_components,
                plus_code: requesterGeoLocation.plus_code,
                geometry: {
                  location: locationObject,
                },
                isDefaultLocation,
              },
            }
          : c
      );
    },
    []
  );

  const updateCallServiceCase = useCallback(
    (sessionId: string, serviceCase: ServiceCaseResponse) => {
      setCall((c) =>
        c
          ? {
              ...c,
              ...(c.updatedServiceCase
                ? {
                    updatedServiceCase: {
                      ...c.updatedServiceCase,
                      ...serviceCase,
                    },
                  }
                : {}),
            }
          : c
      );
    },
    []
  );

  const updateCallRequesterInitialAssessment = useCallback(
    (
      sessionId: string,
      initialAssessmentResponse: typeof PatchInitialAssessment.Res.data
    ) => {
      setCall((c) =>
        c
          ? {
              ...c,
              ...(c.updatedServiceCase
                ? {
                    updatedServiceCase: {
                      ...c.updatedServiceCase,
                      ...initialAssessmentResponse,
                    },
                  }
                : {}),
            }
          : c
      );
    },
    []
  );

  const updateCall = useCallback((sessionId: string, updatedCall: Call) => {
    setCall((existingCall) => ({
      ...existingCall,
      ...updatedCall,
    }));
  }, []);

  return (
    <DashboardMockCallContext.Provider
      value={{
        call,
        updateCallRequesterGeoLocation,
        updateCallRequesterInitialAssessment,
        updateCallServiceCase,
        viewCall,
        setDashboardCall,
        updateCall,
      }}
    >
      {children}
    </DashboardMockCallContext.Provider>
  );
};
