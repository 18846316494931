import {
  GetSalesDocuments,
  GetS3DocumentDownloadSignedUrl,
} from "@deep-consulting-solutions/be2-constants";
import { getClient } from "apis";

type DocumentsFilter = Pick<
  typeof GetSalesDocuments.Params,
  "page" | "order" | "owner" | "sortBy" | "perPage" | "type"
>;

export const fetchDocuments = async (body: DocumentsFilter) => {
  const s = new URLSearchParams();

  Object.entries(body).forEach(([key, value]) => s.set(key, String(value)));

  const url = `/documents${GetSalesDocuments.PATH}?${s.toString()}`;
  const res = await getClient(true).get<typeof GetSalesDocuments.Res>(url);
  return res.data.data;
};

export const fetchS3DownloadURL = async (id: string) => {
  const res = await getClient(true).get<
    typeof GetS3DocumentDownloadSignedUrl.Res
  >(GetS3DocumentDownloadSignedUrl.ROUTE.replace(":id", id));

  return res.data.data;
};
