import React, { useState, useCallback, useEffect, useMemo } from "react";
import { makeStyles, Theme, Grid } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import {
  PaymentMethodEnum,
  SubscriptionPaymentBreakdown,
  SubscriptionPaymentCaseEnum,
} from "@deep-consulting-solutions/be2-constants";
import { useResponsive } from "hooks";
import { displayPrice } from "helpers";
import { StyledTypography } from "component/StyledTypography";
import { StyledTitleAndAmount } from "./StyledTitleAndAmount";
import { FirstPayment } from "./variants/FirstPayment";
import { Overdue } from "./variants/Overdue";
import { ProRatedFeeVariant } from "./variants/ProRatedFee";
import { LateFee } from "./variants/LateFee";

interface StyleProps {
  isDesktop: boolean;
  isMobile: boolean;
  hideDetails: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(
  ({ spacing: s, palette: p }) => ({
    content: {
      display: "flex",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    input: {
      marginTop: s(2),
    },
    mainTitle: ({ isDesktop }) => ({
      fontFamily: "Roboto",
      fontWeight: "normal",
      marginBottom: s(2),
      ...(isDesktop
        ? {}
        : {
            fontSize: 14,
          }),
    }),
    title: {
      fontWeight: "normal",
    },
    mainList: ({ hideDetails }) => ({
      padding: s(0),
      listStyleType: "none",
      display: hideDetails ? "none" : "block",
    }),
    subList: {
      listStyleType: "disc",
      color: p.primary.dark,
    },
    largeAmount: ({ isDesktop }) => ({
      fontSize: isDesktop ? 20 : 14,
    }),
    rule: ({ isDesktop }) => ({
      borderBottom: `1px solid ${p.primary.main}`,
      paddingBottom: s(0),
      margin: s(2, 0, 1, 0),
      ...(isDesktop
        ? {}
        : {
            fontSize: 14,
          }),
    }),
    alert: {
      fontWeight: "normal",
    },
    plans: ({ isDesktop }) =>
      isDesktop
        ? {}
        : {
            fontSize: 16,
          },
    toggleHideButton: {
      color: p.primary.dark,
      background: "none",
      outline: "none",
      border: "none",
      fontSize: 10,
      margin: 0,
      padding: 0,
      display: "flex",
      cursor: "pointer",
      fontWeight: 500,
      alignItems: "center",
    },
    waive: {
      color: p.grey[600],
      textDecoration: "line-through",
    },
  })
);

interface Props {
  subscriptionPaymentBreakdown: SubscriptionPaymentBreakdown | null;
}

enum VariantEnum {
  FIRST_PAYMENT = "first-payment",
  PRO_RATED_FEE = "pro-rated fee",
  OVERDUE = "overdue",
  LATE_FEE = "late - fee",
}

export const PaymentInfo = ({ subscriptionPaymentBreakdown }: Props) => {
  const { isDesktop, isMobile } = useResponsive();
  const [hideDetails, setHideDetails] = useState(true);
  const classes = useStyles({ isDesktop, isMobile, hideDetails });

  const toggleDetailsView = useCallback(
    () => setHideDetails(!hideDetails),
    [hideDetails]
  );

  useEffect(() => {
    if (isDesktop) {
      setHideDetails(false);
    }
  }, [isDesktop]);

  const {
    isFirstPayment,
    isOverdue,
    monthlyFee,
    subtotal,
    subtotalVat,
    activationFee,
    recurringPeriod,
    isDirectPayment,
    recurringPeriodFee,
    recurringPeriodFeeVat,
    totalRecurringPeriodFee,
    firstPeriodPayment,
    alreadyCollectedAmount,
    isLateFeeWaived,
    newRecurringFee,
    oldRecurringFee,
    activationDate,
    lateFee,
    isProratedFee,
  } = useMemo(() => {
    return {
      isFirstPayment:
        subscriptionPaymentBreakdown?.paymentCase ===
        SubscriptionPaymentCaseEnum.FIRST_PAYMENT,
      isProratedFee:
        subscriptionPaymentBreakdown?.paymentCase ===
        SubscriptionPaymentCaseEnum.PRO_RATED_FEE,
      isOverdue:
        subscriptionPaymentBreakdown?.paymentCase ===
        SubscriptionPaymentCaseEnum.OVERDUE,
      monthlyFee: subscriptionPaymentBreakdown?.monthlyFee || 0,
      subtotal: subscriptionPaymentBreakdown?.subtotal || 0,
      subtotalVat: subscriptionPaymentBreakdown?.subtotalVat || 0,
      activationFee: subscriptionPaymentBreakdown?.activationFee || 0,

      recurringPeriod:
        subscriptionPaymentBreakdown?.recurringPeriod || "Monthly",
      isDirectPayment:
        subscriptionPaymentBreakdown?.paymentMethod ===
        PaymentMethodEnum.DIRECT_PAYMENT,
      recurringPeriodFee: subscriptionPaymentBreakdown?.recurringPeriodFee || 0,
      recurringPeriodFeeVat:
        subscriptionPaymentBreakdown?.recurringPeriodFeeVat || 0,
      totalRecurringPeriodFee:
        subscriptionPaymentBreakdown?.totalRecurringPeriodFee || 0,
      firstPeriodPayment: subscriptionPaymentBreakdown?.firstPeriodPayment || 0,
      alreadyCollectedAmount:
        subscriptionPaymentBreakdown?.alreadyCollectedPayment || 0,
      isLateFeeWaived: !!subscriptionPaymentBreakdown?.lateFeeWaived,
      lateFee: subscriptionPaymentBreakdown?.lateFee || 0,
      activationDate: "",
      oldRecurringFee: 0,
      newRecurringFee: 0,
    };
  }, [subscriptionPaymentBreakdown]);

  const proRatedFee = useMemo(() => 0, []);

  const proRateFeeAmount = useMemo(() => {
    const earliestPossibleActivationDate = 0;
    const nextPaymentDate = 1;
    const customerLastPaymentAmount = 0;
    const newRecurringPaymentAmount =
      subscriptionPaymentBreakdown?.totalRecurringPeriodFee || 0;

    return (
      (newRecurringPaymentAmount - customerLastPaymentAmount) *
      (nextPaymentDate - earliestPossibleActivationDate)
    );
  }, [subscriptionPaymentBreakdown]);

  const variant = useMemo(() => {
    if (isFirstPayment) {
      return VariantEnum.FIRST_PAYMENT;
    }

    if (isOverdue) {
      return VariantEnum.OVERDUE;
    }

    if (isProratedFee) {
      return VariantEnum.PRO_RATED_FEE;
    }

    return VariantEnum.LATE_FEE;
  }, [isFirstPayment, isOverdue, isProratedFee]);

  return (
    <div>
      <StyledTypography
        variant="h5"
        color="primary.dark"
        className={classes.mainTitle}
      >
        {variant !== VariantEnum.PRO_RATED_FEE
          ? "Your Selected Subscription Plan:"
          : "Your Selected Subscription Upgrade:"}
      </StyledTypography>

      <StyledTypography
        variant="h3"
        color="primary.main"
        className={classes.plans}
      >
        {subscriptionPaymentBreakdown?.rates?.map((rate, index) => (
          <>
            <span className={classes.alert}>BeAlert</span>{" "}
            {rate.name.replace("BE Alert", "").trim()}{" "}
            {index !==
              (subscriptionPaymentBreakdown?.rates || [])?.length - 1 && " + "}
          </>
        ))}
      </StyledTypography>
      {!isDesktop && (
        <Grid container justify="flex-end" direction="row">
          <Grid item>
            <button
              type="button"
              onClick={() => toggleDetailsView()}
              className={classes.toggleHideButton}
            >
              {hideDetails ? "View" : "Hide"} Details{" "}
              {hideDetails ? <ExpandMore /> : <ExpandLess />}
            </button>
          </Grid>
        </Grid>
      )}
      <ul className={classes.mainList}>
        {subscriptionPaymentBreakdown?.rates?.map((rate) => (
          <li key={rate.slug}>
            <StyledTitleAndAmount
              title={rate.name}
              amount={displayPrice(rate.finalFee || 0)}
            />
            <ul className={classes.subList}>
              {rate.beneficiaries?.map((beneficiary) => (
                <li key={`${beneficiary.id} additional fee`}>
                  <StyledTitleAndAmount
                    title={`Additional fee for ${beneficiary.firstName} ${beneficiary.lastName} (${beneficiary.relation})`}
                    amount={displayPrice(beneficiary.fee || 0)}
                    titleClass={classes.title}
                  />
                </li>
              ))}

              {rate.beneficiaries?.map((beneficiary) => (
                <li key={`${beneficiary.id} rebate`}>
                  <StyledTitleAndAmount
                    title={`Rebate for ${beneficiary.firstName} ${beneficiary.lastName} (${beneficiary.relation})`}
                    amount={`- ${displayPrice(beneficiary.rebate || 0)}`}
                    titleClass={classes.title}
                  />
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>

      {variant === VariantEnum.FIRST_PAYMENT && (
        <FirstPayment
          breakdown={{
            monthlyFee,
            recurringPeriod,
            isDirectPayment,
            recurringPeriodFee,
            recurringPeriodFeeVat,
            totalRecurringPeriodFee,
            firstPeriodPayment,
            subtotal,
            subtotalVat,
            activationFee,
          }}
        />
      )}

      {variant === VariantEnum.PRO_RATED_FEE && (
        <ProRatedFeeVariant
          breakdown={{
            monthlyFee,
            recurringPeriod,
            isDirectPayment,
            recurringPeriodFee,
            recurringPeriodFeeVat,
            totalRecurringPeriodFee,
            subtotalVat,
            proRateFeeAmount,
            activationDate,
            oldRecurringFee,
            newRecurringFee,
            proRatedFee,
          }}
        />
      )}

      {variant === VariantEnum.OVERDUE && (
        <Overdue
          breakdown={{
            monthlyFee,
            isDirectPayment,
            recurringPeriod,
            recurringPeriodFee,
            recurringPeriodFeeVat,
            totalRecurringPeriodFee,
            subtotal,
            subtotalVat,
            alreadyCollectedAmount,
          }}
        />
      )}

      {variant === VariantEnum.LATE_FEE && (
        <LateFee
          breakdown={{
            isLateFeeWaived,
            lateFee,
            alreadyCollectedPayment: alreadyCollectedAmount,
            monthlyFee,
            isDirectPayment,
            recurringPeriod,
            recurringPeriodFee,
            recurringPeriodFeeVat,
            subtotal,
            subtotalVat,
          }}
        />
      )}

      <StyledTitleAndAmount
        title="Grand Total"
        amount={displayPrice(subscriptionPaymentBreakdown?.grandTotal || 0)}
        isGrand
      />
    </div>
  );
};
