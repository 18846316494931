import { getClient } from "apis";
// import { PatchSetDispatcherOnDutyStatus } from "@deep-consulting-solutions/be2-constants";

export const toggleDispatchDuty = async (onDuty: boolean, zohoID: string) => {
  // const res = await getClient(true).patch<
  //   typeof PatchSetDispatcherOnDutyStatus.Res
  // >(PatchSetDispatcherOnDutyStatus.ROUTE.replace(":zohoID", zohoID), { onDuty });
  const res = await getClient(true).patch(`/dispatcher/${zohoID}/crm`, {
    onDuty,
  });
  return res.data.data;
};
