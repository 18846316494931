import React, {
  MouseEventHandler,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  Button,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  PopoverProps,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  CallEnd,
  Mic,
  MicOff,
  Pause,
  Videocam,
  VideocamOff,
} from "@material-ui/icons";
import { useCall } from "../../hooks";
import { ChatIcon, CloseChatIcon } from "./Icons/ChatIcon";
import { TwoSideLayout } from "./Icons/TwoSideLayout";
import { CallLayoutEnum } from "../../types";
import { ThreeSide } from "./Icons/ThreeSide";
import { ThreeSideB } from "./Icons/ThreeSideB";
import { FourSide } from "./Icons/FourSide";
import { FourSideB } from "./Icons/FourSideB";
import { CallTime } from "../CallTime";

interface StyleProps {
  mute: boolean;
  stopVideo: boolean;
  chatOpen: boolean;
}
const useStyles = makeStyles<Theme, StyleProps>(({ spacing: s }) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    background: "white",
    border: "1px solid #08A40F",
    borderRadius: 8,
    padding: s(2),
    height: "100%",
  },
  callManage: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  details: {
    flex: 1,
  },
  endCallContainer: {
    background: "#FF5555",
    borderRadius: "50%",
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginRight: s(2),
  },
  endCallIcon: {
    color: "white",
  },
  pauseCallContainer: {
    background: "#828282",
    borderRadius: "50%",
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  pauseCallIcon: {
    color: "white",
  },
  callActions: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  micContainer: ({ mute }) => ({
    background: mute ? undefined : "rgba(79, 79, 79, 0.1)",
    borderRadius: "50%",
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginRight: s(2),
  }),
  micIcon: {},
  videoContainer: ({ stopVideo }) => ({
    background: stopVideo ? "rgba(79, 79, 79, 0.1)" : undefined,
    borderRadius: "50%",
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginRight: s(2),
  }),
  videoIcon: {},
  chatContainer: ({ chatOpen }) => ({
    background: chatOpen ? "rgba(79, 79, 79, 0.1)" : undefined,
    borderRadius: "50%",
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginRight: s(2),
  }),
  chatIcon: {},
  layoutContainer: {
    borderRadius: "50%",
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginRight: s(2),
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  layoutMenu: {
    "& .MuiPaper-root": {
      marginTop: s(7),
      "& .MuiMenu-list": {
        display: "flex",
        padding: s(0),
        "& .MuiMenuItem-root": {
          padding: s(1),
        },
      },
    },
  },
}));

export const ActiveCallComponent = () => {
  const {
    activeCall,
    chatOpen,
    handleOpenChat,
    handleSwitchLayout,
    layout,
    createEndCallRequest,
    putCallOnHold,
    resumeCallFromHold,
    stopVideo,
    handleOpenVideo,
    mutedMicCalls,
    handleMuteCall,
  } = useCall();
  const [showLayoutOptions, setShowLayoutOptions] = useState(false);
  const [anchorEl, setAnchorEl] = useState<
    PopoverProps["anchorEl"] | undefined
  >();

  const mute = useMemo(
    () => !!activeCall?.sessionId && !!mutedMicCalls[activeCall?.sessionId],
    [mutedMicCalls, activeCall?.sessionId]
  );

  const classes = useStyles({ mute, stopVideo, chatOpen });

  const handleMute = useCallback(() => {
    if (activeCall?.sessionId) {
      handleMuteCall(activeCall?.sessionId);
    }
  }, [handleMuteCall, activeCall?.sessionId]);

  const handleHoldCall = useCallback(() => {
    putCallOnHold();
  }, [putCallOnHold]);

  const onOpenChat = useCallback(() => {
    handleOpenChat();
  }, [handleOpenChat]);

  const onStopChat = useCallback(() => {
    handleOpenVideo();
  }, [handleOpenVideo]);

  const handleResumeCall = useCallback(() => {
    if (!activeCall?.sessionId) {
      return;
    }
    resumeCallFromHold(activeCall.sessionId);
  }, [resumeCallFromHold, activeCall?.sessionId]);

  const handleShowLayoutOptions: MouseEventHandler<Element> = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
      setShowLayoutOptions(true);
    },
    []
  );

  const handleCloseLayoutOptions = useCallback(() => {
    setShowLayoutOptions(false);
  }, []);

  const handleSelectLayoutOption = useCallback(
    (l?: CallLayoutEnum) => {
      handleSwitchLayout(l);
      handleCloseLayoutOptions();
    },
    [handleSwitchLayout, handleCloseLayoutOptions]
  );

  const selectLayoutOption = useCallback(
    (l: CallLayoutEnum) => () => handleSelectLayoutOption(l),
    [handleSelectLayoutOption]
  );

  const layoutOptions = useMemo(
    () => [
      {
        icon: <TwoSideLayout />,
        value: CallLayoutEnum.FORM_MAP,
        disabled: chatOpen || stopVideo,
      },
      {
        icon: <ThreeSide />,
        value: chatOpen
          ? CallLayoutEnum.FORM_MAP_CHAT
          : CallLayoutEnum.FORM_MAP_VIDEO,
        disabled: (chatOpen && stopVideo) || (!chatOpen && !stopVideo),
      },
      {
        icon: <ThreeSideB />,
        value: chatOpen
          ? CallLayoutEnum.MAP_FORM_CHAT
          : CallLayoutEnum.MAP_FORM_VIDEO,
        disabled: (chatOpen && stopVideo) || (!chatOpen && !stopVideo),
      },
      {
        icon: <FourSide />,
        value: CallLayoutEnum.FORM_VIDEO_MAP_CHAT,
        disabled: !chatOpen || !stopVideo,
      },
      {
        icon: <FourSideB />,
        value: CallLayoutEnum.MAP_FORM_VIDEO_CHAT,
        disabled: !chatOpen || !stopVideo,
      },
    ],
    [chatOpen, stopVideo]
  );

  return (
    <div className={classes.container}>
      <div className={classes.details}>
        <Typography color="primary" variant="subtitle2">
          <b>{activeCall?.fullName}</b> - {activeCall?.requestType}
        </Typography>
      </div>
      <div className={classes.callManage}>
        <div className={classes.endCallContainer}>
          <CallEnd
            onClick={createEndCallRequest}
            fontSize="small"
            className={classes.endCallIcon}
          />
        </div>
        <div>
          <CallTime time={activeCall?.timeOfPickUp} />
        </div>
        {activeCall?.isOnHold ? (
          <Tooltip
            title={
              <Typography variant="caption">
                Resuming call will place any ongoing call{" "}
                <strong>on Hold</strong>
              </Typography>
            }
          >
            <Button
              color="primary"
              variant="outlined"
              onClick={handleResumeCall}
            >
              RESUME
            </Button>
          </Tooltip>
        ) : (
          <div className={classes.pauseCallContainer}>
            <Tooltip
              title={<Typography variant="caption">Hold Call</Typography>}
            >
              <Pause
                fontSize="small"
                onClick={handleHoldCall}
                className={classes.pauseCallIcon}
              />
            </Tooltip>
          </div>
        )}
      </div>
      <div className={classes.callActions}>
        <div className={classes.micContainer}>
          <Tooltip
            title={
              <Typography variant="caption">
                {mute ? "Unmute" : "Mute"} Audio
              </Typography>
            }
          >
            {mute ? (
              <MicOff
                fontSize="small"
                className={classes.micIcon}
                onClick={handleMute}
              />
            ) : (
              <Mic
                fontSize="small"
                className={classes.micIcon}
                onClick={handleMute}
              />
            )}
          </Tooltip>
        </div>
        <div className={classes.videoContainer}>
          <Tooltip
            title={
              <Typography variant="caption">
                {stopVideo ? "Stop" : "Start"} Video
              </Typography>
            }
          >
            {stopVideo ? (
              <Videocam className={classes.videoIcon} onClick={onStopChat} />
            ) : (
              <VideocamOff className={classes.videoIcon} onClick={onStopChat} />
            )}
          </Tooltip>
        </div>
        <div className={classes.chatContainer}>
          <Tooltip
            title={
              <Typography variant="caption">
                {chatOpen ? "Close" : "Open"} Chat
              </Typography>
            }
          >
            <div className={classes.iconWrapper}>
              {chatOpen ? (
                <CloseChatIcon
                  className={classes.chatIcon}
                  onClick={onOpenChat}
                />
              ) : (
                <ChatIcon className={classes.chatIcon} onClick={onOpenChat} />
              )}
            </div>
          </Tooltip>
        </div>
        <div className={classes.layoutContainer}>
          <Tooltip
            title={<Typography variant="caption">Select Layout</Typography>}
          >
            <div className={classes.iconWrapper}>
              <IconButton
                onClick={handleShowLayoutOptions}
                aria-controls={showLayoutOptions ? "layout-options" : undefined}
                aria-haspopup="true"
                aria-expanded={showLayoutOptions ? "true" : undefined}
              >
                {layout === CallLayoutEnum.FORM_MAP && <TwoSideLayout />}
                {[
                  CallLayoutEnum.FORM_MAP_CHAT,
                  CallLayoutEnum.FORM_MAP_VIDEO,
                ].includes(layout) && <ThreeSide />}
                {[
                  CallLayoutEnum.MAP_FORM_CHAT,
                  CallLayoutEnum.MAP_FORM_VIDEO,
                ].includes(layout) && <ThreeSideB />}

                {[CallLayoutEnum.FORM_VIDEO_MAP_CHAT].includes(layout) && (
                  <FourSide />
                )}

                {[CallLayoutEnum.MAP_FORM_VIDEO_CHAT].includes(layout) && (
                  <FourSideB />
                )}
              </IconButton>
            </div>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="layout-options"
            open={showLayoutOptions}
            onClose={handleCloseLayoutOptions}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            className={classes.layoutMenu}
          >
            {layoutOptions.map((layoutOption) => (
              <MenuItem
                key={layoutOption.value}
                onClick={selectLayoutOption(layoutOption.value)}
                disabled={layoutOption.disabled}
              >
                {layoutOption.icon}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
    </div>
  );
};
