import { useCallback, useState } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import { Field, FormikProvider, useFormik } from "formik";
import { useStyles } from "call/components/CallForms/RequesterAndPatientForm/styles";
import { TextField } from "formik-material-ui";
import { FormikDatePicker } from "@deep-consulting-solutions/dcs-web-ui";
import { endOfYesterday } from "date-fns";
import { DATE_DOT_FORMAT, DATE_TYPING_FORMAT } from "configs";
import { FormikNumberField } from "component/atoms/FormikNumberField";
import DCSPhoneInput from "component/atoms/DCSPhoneInput";
import { SearchedMembers } from "call/components/CallForms/RequesterAndPatientForm/SearchedMembers";
import { searchExistingMembers } from "redux/call/requests";
import {
  Member,
  MembershipTypeEnum,
} from "call/components/CallForms/RequesterAndPatientForm/types";
import { getGeneralAddressString } from "./helpers";

interface Props {
  onMemberSelect: (member: Member) => void;
  reSearch?: boolean;
  handleOpenRemovePatientDialog?: () => void;
  showRemovePatientButton?: boolean;
  filterMembersByPatients?: boolean;
  disabled?: boolean;
  disableForm: boolean;
}

const parseDate = (date: string) => {
  try {
    return new Date(date);
  } catch (error) {
    return "";
  }
};

export const SearchForMember: React.FC<Props> = ({
  onMemberSelect,
  reSearch,
  handleOpenRemovePatientDialog = () => {},
  showRemovePatientButton = false,
  filterMembersByPatients = false,
  disabled = false,
  disableForm,
}) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchedMembers, setSearchedMembers] = useState<Member[]>([]);
  const classes = useStyles({});

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      dob: null,
      membershipNumber: "",
    },
    validateOnBlur: false,
    validate: (values) => {
      if (
        !(
          values.firstName ||
          values.lastName ||
          values.phone ||
          values.dob ||
          values.membershipNumber ||
          values.email
        )
      )
        return {
          firstName: "At least one field is required",
        };
    },
    onSubmit: async (data) => {
      try {
        const res = await searchExistingMembers(data);
        setSearchedMembers(
          res.map((r) => {
            const memberships = [
              {
                type: r.isMainbeneficiaryOfPaidSub
                  ? MembershipTypeEnum.MainBeneficiary
                  : MembershipTypeEnum.Dependent,
                number: r.membershipNumber,
              },
            ].concat(
              r.dependentSubsMembershipNumbers.map((d) => ({
                type: MembershipTypeEnum.Dependent,
                number: d,
              }))
            );
            return {
              firstName: r.firstName,
              lastName: r.lastName,
              dob: parseDate(r.dob),
              id: r.id,
              memberships,
              citizenships: r.citizenships,
              address: getGeneralAddressString(r.address),
              email: r.email,
              phone: r.phone,
              gender: r.gender,
            };
          })
        );
      } catch (error) {
        //
      } finally {
        formik.setSubmitting(false);
      }
    },
  });
  const { handleSubmit: handleFormSubmit } = formik;

  const handleSubmit = useCallback(
    () => handleFormSubmit(),
    [handleFormSubmit]
  );
  const toggleSearch = useCallback(() => {
    setSearchOpen((current) => !current);
  }, []);

  const handleMemberSelect = useCallback(
    (member: Member) => {
      onMemberSelect(member);
      setSearchOpen(false);
      formik.resetForm();
      setSearchedMembers([]);
    },
    [formik, onMemberSelect]
  );

  const handleCancelSelection = useCallback(() => {
    setSearchOpen(false);
  }, []);

  return (
    <div className={classes.searchMemberContainer}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.fullWidth}
      >
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={toggleSearch}
            disabled={searchOpen || disableForm || disabled}
            className={classes.searchForMembersButton}
          >
            {reSearch ? "Search again" : "Search for member"}
          </Button>
        </Grid>
        {showRemovePatientButton && (
          <Grid item>
            <Button
              variant="contained"
              onClick={handleOpenRemovePatientDialog}
              className={classes.deleteButton}
              disabled={disableForm}
            >
              Delete
            </Button>
          </Grid>
        )}
      </Grid>
      {searchOpen && (
        <div className={classes.searchFieldsContainer}>
          <FormikProvider value={formik}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Field
                  size="small"
                  component={TextField}
                  name="firstName"
                  label="First Name"
                  disabled={disableForm}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  size="small"
                  component={TextField}
                  name="lastName"
                  label="Last Name"
                  disabled={disableForm}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  size="small"
                  name="membershipNumber"
                  component={FormikNumberField}
                  label="Membership Number"
                  disabled={disableForm}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  size="small"
                  component={FormikDatePicker}
                  disablePast={false}
                  disableToolbar={false}
                  disableFuture
                  maxDate={endOfYesterday()}
                  label="Date of Birth"
                  name="dob"
                  placeholder={DATE_TYPING_FORMAT}
                  typingFormat={DATE_TYPING_FORMAT}
                  displayFormat={DATE_DOT_FORMAT}
                  disabled={disableForm}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  size="small"
                  component={TextField}
                  name="email"
                  label="Email"
                  disabled={disableForm}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  size="small"
                  component={DCSPhoneInput}
                  name="phone"
                  label="Phone"
                  disabled={disableForm}
                />
              </Grid>
            </Grid>
          </FormikProvider>
          <Box
            mt={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Button variant="outlined" onClick={handleCancelSelection}>
              Cancel
            </Button>

            <Button
              color="primary"
              onClick={handleSubmit}
              disabled={
                formik.isSubmitting ||
                !formik.isValid ||
                !formik.dirty ||
                disableForm
              }
            >
              Search
            </Button>
          </Box>
          {searchedMembers.length ? (
            <SearchedMembers
              members={searchedMembers}
              onClickRow={handleMemberSelect}
              filterMembersByPatients={filterMembersByPatients}
              disableForm={disableForm}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};
