/* eslint-disable import/prefer-default-export */
import { useTheme, useMediaQuery } from "@material-ui/core";

export const useResponsive = () => {
  const theme = useTheme();
  const { breakpoints: b } = theme;
  const isXSDown = useMediaQuery(b.down("xs"));
  const isSMDown = useMediaQuery(b.down("sm"));

  return {
    theme,
    useMediaQuery,

    isXSDown,
    isXSUp: useMediaQuery(b.up("xs")),

    isSMDown,
    isSMUp: useMediaQuery(b.up("sm")),

    isMDDown: useMediaQuery(b.down("md")),
    isMDUp: useMediaQuery(b.up("md")),

    isLGDown: useMediaQuery(b.down("lg")),
    isLGUp: useMediaQuery(b.up("lg")),

    isXLDown: useMediaQuery(b.down("xl")),
    isXLUp: useMediaQuery(b.up("xl")),
    isDesktop: !isSMDown,
    isTablet: isSMDown && !isXSDown,
    isMobile: isXSDown,
  };
};
