import {
  CallSocketNotificationResponses,
  PostMessageRequestBody,
  Chat,
  ServiceCaseResponse,
  PatchInitialAssessment,
} from "@deep-consulting-solutions/be2-constants";
import OpenTok from "@opentok/client";

export interface CallContextInterface {
  calls: Call[];
  cutCalls: Call[];
  viewedCall?: string;
  activeCallSession?: string;
  chatOpen: boolean;
  stopVideo: boolean;
  layout: CallLayoutEnum;
  endCallSessionRequest?: string;
  pickCallRequestId?: string;
  createEndCallRequest: () => void;
  createPickCallRequest: (sessionId: string) => void;
  removeCutCall: (sessionId: string) => void;
  viewCall: (sessionId: string) => void;
  handleSwitchLayout: (v?: CallLayoutEnum) => void;
  handleOpenChat: (v?: boolean) => void;
  completeEndCallRequest: () => Promise<void>;
  cancelEndCallRequest: () => void;
  completePickCallRequest: (sessionId?: string) => Promise<void>;
  cancelPickCallRequest: () => void;
  putCallOnHold: () => Promise<void>;
  handleOpenVideo: () => void;
  resumeCallFromHold: (sessionId: string) => Promise<void>;
  chatMessages: Record<string, Chat[]>;
  postMessageRequest: (body: PostMessageRequestBody) => Promise<void>;
  updateCallRequesterGeoLocation: (
    sessionId: string,
    requesterGeoLocation: google.maps.GeocoderResult,
    isDefaultLocation?: boolean
  ) => void;
  updateCallRequesterInitialAssessment: (
    sessionId: string,
    initialAssessmentResponse: typeof PatchInitialAssessment.Res.data
  ) => void;
  updateCallServiceCase: (
    sessionId: string,
    serviceCase: ServiceCaseResponse
  ) => void;
  openTokSessions: Record<
    string,
    {
      session: { streams?: OpenTok.Stream[] } & OpenTok.Session;
      publisher: OpenTok.Publisher;
    }
  >;
  updateCall: (sessionId: string, call: Call) => void;
  mutedMicCalls: Record<string, boolean>;
  handleMuteCall: (sessionId: string, value?: boolean) => void;
}

export interface MapPosition {
  lat: number;
  lng: number;
}

export type CustomGeoCodeResult = Pick<
  google.maps.GeocoderResult,
  "plus_code" | "address_components"
> & {
  geometry: {
    location: google.maps.LatLngLiteral;
  };
  isDefaultLocation?: boolean;
};

type NewServiceCall = typeof CallSocketNotificationResponses.NewServiceCall;
export interface Call extends NewServiceCall {
  isEmergency?: boolean;
  fullName?: string;
  id?: string;
  isOnHold?: boolean;
  timeOfPickUp?: string;
  isRinging: boolean;
  updatedServiceCase?: ServiceCaseResponse;
  isCut?: boolean;
  requesterGeoLocation?: CustomGeoCodeResult;
  isUnanswered?: boolean;
  isEnded?: boolean;
  token?: string;
  apiKey?: string;
  disableForms?: boolean;
  assignedServiceCaseId?: string;
}

export enum CallLayoutEnum {
  FORM_MAP,
  FORM_MAP_CHAT,
  FORM_MAP_VIDEO,
  FORM_VIDEO_MAP_CHAT,
  MAP_FORM_VIDEO,
  MAP_FORM_CHAT,
  MAP_FORM_VIDEO_CHAT,
}
