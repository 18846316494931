import React from "react";
import {
  CreditCard,
  PostVerifySubscriptionPayment,
  SubscriptionPaymentBreakdown,
} from "@deep-consulting-solutions/be2-constants";
import { makeStyles, Theme } from "@material-ui/core";
import { useResponsive } from "hooks";
import { BackButton } from "component/BackButton";
import { Footer } from "./Footer";
import { PaymentInfo } from "./PaymentInfo";
import {
  GeneratePaymentOrderIdBody,
  GeneratePaymentOrderIdResponse,
} from "./types";
import { PaymentCard } from "./PaymentCard";

interface StyleProps {
  isDesktop: boolean;
  isMobile: boolean;
}
const useStyles = makeStyles<Theme, StyleProps>(({ spacing: s }) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    background: "white",
    position: "relative",
  },
  container: ({ isDesktop, isMobile }) =>
    isDesktop
      ? {
          display: "flex",
          alignItems: "stretch",
          justifyContent: "space-between",
        }
      : {
          display: "flex",
          flexDirection: "column",
          alignItems: isMobile ? "stretch" : "center",
        },
  content: ({ isDesktop }) =>
    isDesktop
      ? {
          flex: 1,
        }
      : {},
  title: {
    textAlign: "center",
  },
  input: {
    marginTop: s(2),
  },
  backButton: ({ isMobile }) =>
    isMobile
      ? {
          margin: s(4, 0),
          display: "flex",
          justifyContent: "center",
        }
      : {
          margin: s(4, 2),
          position: "absolute",
          top: 0,
          left: 0,
        },
  infoSide: ({ isDesktop, isMobile }) => ({
    background: "#F9F9F9",
    boxShadow: isMobile ? "none" : "2px 0px 4px rgba(0, 0, 0, 0.12)",
    ...(isDesktop
      ? {
          padding: s(8, 8, 4, 8),
          marginBottom: s(0),
          minHeight: "100vh",
          height: "100%",
        }
      : {
          width: isMobile ? "100%" : 400,
          margin: isMobile ? s(0) : s(8, 8, 0, 8),
          padding: isMobile ? s(4) : s(2),
        }),
  }),
  formSide: ({ isDesktop, isMobile }) =>
    isDesktop
      ? {
          padding: s(8, 4, 4, 4),
          minHeight: "100vh",
        }
      : {
          width: isMobile ? "100%" : 400,
          margin: isMobile ? s(0) : s(2, 8),
          padding: isMobile ? s(4) : s(2),
        },
  info: {},
  form: ({ isDesktop }) =>
    isDesktop
      ? {
          minHeight: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }
      : {},
  footer: {
    marginTop: s(6),
  },
}));

interface Props {
  subscriptionPaymentBreakdown: SubscriptionPaymentBreakdown | null;
  verifyPayment: (
    values: typeof PostVerifySubscriptionPayment["Body"]
  ) => Promise<boolean>;
  submitCardDetailsRequest?: (
    values: GeneratePaymentOrderIdBody
  ) => Promise<GeneratePaymentOrderIdResponse>;
  paymentFormButtonLabel?: string;
  handlePaymentResponse?: () => any;
  cards?: CreditCard[];
  payWithExistingCard?: (
    creditCardId: string,
    useCardForRecurringPayments: boolean
  ) => Promise<Record<string, unknown>>;
  onSuccess?: () => void;
  onBackClick?: () => void;
}
export const PNPLayout = ({
  subscriptionPaymentBreakdown,
  verifyPayment,
  paymentFormButtonLabel,
  submitCardDetailsRequest,
  handlePaymentResponse,
  cards,
  payWithExistingCard,
  onSuccess,
  onBackClick,
}: Props) => {
  const { isDesktop, isMobile } = useResponsive();
  const classes = useStyles({ isDesktop, isMobile });

  return (
    <div className={classes.wrapper}>
      {!isMobile && onBackClick && (
        <div className={classes.backButton}>
          <BackButton hideText onClick={onBackClick} />
        </div>
      )}
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.infoSide}>
            <div className={classes.info}>
              <PaymentInfo
                subscriptionPaymentBreakdown={subscriptionPaymentBreakdown}
              />
            </div>

            {isDesktop && (
              <div>
                <Footer />
              </div>
            )}
          </div>
        </div>
        <div className={classes.content}>
          <div className={classes.formSide}>
            <div className={classes.form}>
              <PaymentCard
                submitCardDetailsRequest={submitCardDetailsRequest}
                paymentFormButtonLabel={paymentFormButtonLabel}
                verifyPayment={verifyPayment}
                subscriptionPaymentBreakdown={subscriptionPaymentBreakdown}
                handlePaymentResponse={handlePaymentResponse}
                cards={cards}
                payWithExistingCard={payWithExistingCard}
                onSuccess={onSuccess}
              />
            </div>

            {isMobile && onBackClick && (
              <div className={classes.backButton}>
                <BackButton onClick={onBackClick} />
              </div>
            )}
            {!isDesktop && (
              <div className={classes.footer}>
                <Footer />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
