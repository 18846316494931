import { Box, MenuItem, TextField } from "@material-ui/core";
import { ReactComponent as HeadSet } from "images/headset.svg";
import { ReactComponent as Speaker } from "images/speaker.svg";
import { ReactComponent as SpeakerLow } from "images/speaker-low.svg";
import { ReactComponent as SpeakerLoud } from "images/speaker-loud.svg";
import { AudioVisualizer } from "component/Dialogs/audioVideoSettings/AudioVisualizer";
import { useAudioContext } from "context/AudioContext";
import { useMediaDeviceContext } from "context/MediaDeviceContext";

export const SpeakersModule = () => {
  const {
    devices: {
      audio: { output },
    },
    selectedDevices: { audiooutput },
    handleSelectedDevice,
  } = useMediaDeviceContext();

  const { state, device, play, stopPlay, visualData, audioUrl } =
    useAudioContext();

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    handleSelectedDevice("audiooutput", event.target.value);
  };

  const playing = state === "playing" && device === "audiooutput";

  const playAudio = () => {
    if (playing) {
      stopPlay();
    } else {
      play("audiooutput");
    }
  };

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginRight={3}
          marginBottom={2}
        >
          <HeadSet />
        </Box>
        <TextField
          color="primary"
          select
          label="In-Call Speakers"
          value={audiooutput || output[0].deviceId}
          onChange={handleChange}
          helperText="Select the headset speakers to be used in calls"
        >
          {output.map((el) => (
            <MenuItem key={el.deviceId} value={el.deviceId}>
              {el.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <AudioVisualizer
        data={visualData}
        onButtonClick={playAudio}
        buttonIcon={playing ? <SpeakerLoud /> : <SpeakerLow />}
        buttonText={playing ? "Playing" : "Test"}
        isActive={playing}
        disableButton={!audioUrl}
      />
    </Box>
  );
};

export const ExtSpeakersModule: React.FC = () => {
  const {
    devices: {
      audio: { output },
    },
    selectedDevices: { audiooutputext },
    handleSelectedDevice,
  } = useMediaDeviceContext();
  const { state, device, play, stopPlay, visualData, audioUrl } =
    useAudioContext();
  const playing = state === "playing" && device === "audiooutputext";
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    handleSelectedDevice("audiooutputext", event.target.value);
  };

  const playAudio = () => {
    if (playing) {
      stopPlay();
    } else {
      play("audiooutputext");
    }
  };

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginRight={3}
          marginBottom={2}
        >
          <Speaker />
        </Box>
        <TextField
          color="primary"
          label="External Speakers"
          select
          value={audiooutputext || output[0].deviceId}
          onChange={handleChange}
          helperText="Select the external speakers to be used for notifications and incoming calls ringing"
        >
          {output.map((el) => (
            <MenuItem key={el.deviceId} value={el.deviceId}>
              {el.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <AudioVisualizer
        data={visualData}
        onButtonClick={playAudio}
        buttonIcon={playing ? <SpeakerLoud /> : <SpeakerLow />}
        buttonText={playing ? "Playing" : "Test"}
        isActive={playing}
        disableButton={!audioUrl}
      />
    </Box>
  );
};
