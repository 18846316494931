import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import MultiSelectWithTags, { SimpleSelect } from "component/atoms/Select";
import FilterListIcon from "@material-ui/icons/FilterList";
import {
  GetDispatchers,
  ServiceCaseRequestOriginEnum,
  ServiceCaseRequestTypeEnum,
  ServiceCaseStatusGroupEnum,
} from "@deep-consulting-solutions/be2-constants";
import {
  getServiceOptions,
  getStatusOptions,
} from "pages/FulfilmentDashboard/helpers";
import { getClient } from "apis";
import { formatNameToDisplay } from "helpers";
import Loader from "component/Loader/Loader";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import SearchIcon from "@material-ui/icons/Search";
import { GetFetchServiceCases } from "@deep-consulting-solutions/be2-constants/build/backend/AppWidgets/serviceCase";

export const FilterBar = ({
  sortAsc,
  setSortAsc,
  updateState,
  onOverdue,
  onPendingApproval,
  isPendingApproval,
  isOverdue,
  onSearchChange,
  selectedRequestType,
  selectedStatusGroup,
  selectedStatuses,
  selectedServices,
  resetFiler,
  searchText,
  dispatcherIDs,
  requestOrigin,
  onApplyFilter,
  isTouched,
  tableData,
  setTableData,
}: {
  isOverdue: boolean;
  isPendingApproval: boolean;
  sortAsc: boolean;
  isTouched: boolean;
  updateState: (res: { name: string; value: any }) => void;
  onPendingApproval: (e: ChangeEvent<HTMLInputElement>) => void;
  onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onOverdue: (e: ChangeEvent<HTMLInputElement>) => void;
  setSortAsc: (v: boolean) => void;
  resetFiler: () => void;
  onApplyFilter: () => void;
  selectedStatusGroup: ServiceCaseStatusGroupEnum[];
  selectedServices: string[];
  selectedStatuses: string[];
  selectedRequestType: ServiceCaseRequestTypeEnum;
  tableData: any;
  setTableData: (r: any) => void;
} & Partial<typeof GetFetchServiceCases.Params>) => {
  const [dispatchers, setDispatchers] = useState<
    typeof GetDispatchers.Res["data"]
  >({ dispatchers: [], total: 0 });
  const [hasApply, setHasApply] = useState(false);
  const [gettingDispatchers, setGettingDispatchers] = useState(false);

  const [backupTableData, setBackupTableData] = useState<any>(null);

  const [showFilter, setShowFilter] = useState(false);
  const [delayOnBlur, setDelayOnBlur] = useState(false);

  const handleChanges = (e: any) => {
    updateState({ name: e.target.name, value: e.target.value });
  };

  const handleToggleSort = () => {
    setSortAsc(!sortAsc);
    onApplyFilter();
  };

  const getDispatchersId = (ids: string[], reverse = false) => {
    const d = ids.reduce((res: string[], cur: string) => {
      dispatchers.dispatchers.forEach((el) => {
        if (reverse) {
          if (el.id === cur) {
            res.push(formatNameToDisplay(el?.firstName, el?.lastName));
          }
        } else if (formatNameToDisplay(el?.firstName, el?.lastName) === cur) {
          res.push(el.id);
        }
      });
      return res;
    }, []);
    if (reverse) return d;
    updateState({ name: "dispatcherIDs", value: d });
  };

  const toggleFilter = () => {
    if (showFilter) {
      setShowFilter(false);
    } else {
      setShowFilter(true);
    }
  };

  const onClear = () => {
    updateState({ name: "itenerarySectionState", value: [] });
    resetFiler();
    if (hasApply) {
      onApplyFilter();
      setHasApply(false);
    }
  };

  const handleOnSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (delayOnBlur) setDelayOnBlur(false);
    onSearchChange(e);
  };

  const handleTextSearch = () => {
    if (searchText?.trim()) {
      if (!backupTableData) setBackupTableData(tableData);
      onApplyFilter();
    } else if (backupTableData) {
      setTableData(backupTableData);
      setBackupTableData(null);
    }
  };

  const onEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setDelayOnBlur(true);
      handleTextSearch();
    }
  };

  const statusGroupFilters = useMemo(
    () =>
      Object.values(ServiceCaseStatusGroupEnum).filter(
        (group) => group !== ServiceCaseStatusGroupEnum.RE_OPENED
      ),
    []
  );

  useEffect(() => {
    (async () => {
      try {
        setGettingDispatchers(true);
        const res = await getClient(true).get<typeof GetDispatchers.Res>(
          GetDispatchers.ROUTE
        );
        setGettingDispatchers(false);
        setDispatchers(res.data.data);
      } catch (e) {
        setGettingDispatchers(false);
      }
    })();
  }, []);

  return (
    <Box mb={2}>
      <Box
        display="grid"
        alignItems="center"
        gridTemplateColumns="minmax(auto, 500px) auto auto auto auto"
        gridGap={16}
      >
        <TextField
          name="searchText"
          placeholder="Ticket number, requester, patient(s)"
          variant="outlined"
          size="small"
          value={searchText}
          onBlur={!delayOnBlur ? handleTextSearch : undefined}
          onChange={handleOnSearch}
          onKeyDown={onEnterKeyPress}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  style={{ fontSize: 16, color: "rgba(0, 0, 0, 0.54)" }}
                />
              </InputAdornment>
            ),
          }}
        />
        <Button color="primary" variant="text" onClick={toggleFilter}>
          <FilterListIcon
            color="primary"
            style={{ fontSize: 16, marginRight: 8 }}
          />
          Filter
        </Button>
        <FormControlLabel
          label="Pending Quote Approval"
          style={{ color: "#263E7F" }}
          control={
            <Checkbox
              size="small"
              color="primary"
              name="pendingQuote"
              onChange={onPendingApproval}
              checked={isPendingApproval}
            />
          }
        />
        <FormControlLabel
          style={{ color: "#263E7F" }}
          label="Overdue"
          control={
            <Checkbox
              size="small"
              color="primary"
              name="overdue"
              onChange={onOverdue}
              checked={isOverdue}
            />
          }
        />
        <Button
          variant="text"
          color="primary"
          style={{ textTransform: "capitalize" }}
          onClick={handleToggleSort}
        >
          <ArrowUpwardIcon
            style={{
              color: "#0000008a",
              fontSize: 16,
              marginRight: 6,
              transform: `rotate(${sortAsc ? 180 : 0}deg)`,
            }}
          />
          Show {sortAsc ? "Oldest" : "Latest"} First
        </Button>
      </Box>
      <Collapse in={showFilter}>
        <Box mb={2}>
          <Divider style={{ marginTop: 16, marginBottom: 16 }} />
          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr 1fr"
            gridColumnGap={8}
            gridRowGap={16}
            position="relative"
          >
            <Loader open={gettingDispatchers} absolute />
            <MultiSelectWithTags
              label="Dispatcher"
              name="dispatcher"
              value={
                getDispatchersId(
                  dispatcherIDs as unknown as string[],
                  true
                ) as unknown as string[]
              }
              onChange={(e) => getDispatchersId(e.target.value as string[])}
              options={dispatchers.dispatchers.map((el) =>
                formatNameToDisplay(el?.firstName, el?.lastName)
              )}
            />
            <MultiSelectWithTags
              label="Status Group"
              name="statusGroup"
              value={selectedStatusGroup}
              onChange={(e) => {
                updateState({ name: "status", value: [] });
                handleChanges(e);
              }}
              options={statusGroupFilters}
            />
            <MultiSelectWithTags
              label="Status"
              name="status"
              value={selectedStatuses}
              onChange={handleChanges}
              disabled={!getStatusOptions(selectedStatusGroup).length}
              options={getStatusOptions(selectedStatusGroup)}
            />
            <SimpleSelect
              name="requestType"
              label="Request Type"
              value={selectedRequestType}
              options={Object.values(ServiceCaseRequestTypeEnum)}
              onChange={(e) => {
                updateState({ name: "service", value: [] });
                handleChanges(e);
              }}
            />
            <MultiSelectWithTags
              label="Service"
              name="service"
              onChange={handleChanges}
              value={selectedServices}
              disabled={!getServiceOptions(selectedRequestType).length}
              options={getServiceOptions(selectedRequestType)}
            />
            <SimpleSelect
              name="requestOrigin"
              label="Request Origin"
              value={requestOrigin}
              options={Object.values(ServiceCaseRequestOriginEnum)}
              onChange={handleChanges}
            />
          </Box>
        </Box>

        <Box display="flex" justifyContent="flex-end" style={{ gap: 16 }}>
          <Button
            color="primary"
            variant="outlined"
            onClick={onClear}
            disabled={!isTouched}
          >
            clear
          </Button>
          <Button
            color="primary"
            disabled={!isTouched}
            onClick={() => {
              setHasApply(true);
              onApplyFilter();
            }}
          >
            apply filters
          </Button>
        </Box>
      </Collapse>
    </Box>
  );
};
