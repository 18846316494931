import React, { useCallback } from "react";
import { Button, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { useCall } from "../../hooks";
import { Call } from "../../types";
import { CallTime } from "../CallTime";

const useStyles = makeStyles(({ spacing: s }) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    background: "white",
    border: "1px solid rgba(189, 189, 189, 1)",
    borderRadius: 8,
    padding: s(2),
    height: "100%",
  },
  callManage: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: 1,
  },
  details: {
    flex: 1,
  },
  callTime: {
    marginRight: s(2),
  },
}));

interface Props {
  call: Call;
}

export const CallOnHoldComponent = ({ call }: Props) => {
  const { resumeCallFromHold } = useCall();

  const classes = useStyles();

  const handleResumeCall = useCallback(() => {
    resumeCallFromHold(call.sessionId);
  }, [resumeCallFromHold, call.sessionId]);

  return (
    <div className={classes.container}>
      <div className={classes.details}>
        <Typography color="primary" variant="subtitle2">
          <b>{call?.fullName}</b> - {call?.requestType}
        </Typography>
      </div>
      <div className={classes.callManage}>
        <div className={classes.callTime}>
          <CallTime time={call.timeOfPickUp} />
        </div>
        <Tooltip
          title={
            <Typography>
              Resuming call will place any ongoing call <strong>on Hold</strong>
            </Typography>
          }
        >
          <Button color="primary" variant="outlined" onClick={handleResumeCall}>
            RESUME
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};
