import {
  Collapse,
  Divider,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { InfoOutlined, ArrowBackIosOutlined } from "@material-ui/icons";
import { ServiceCaseResponse } from "@deep-consulting-solutions/be2-constants";

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  wrapper: {
    background: "#efefef",
    padding: s(1),
    borderRadius: s(1),
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    color: p.primary.main,
  },
  recommendation: {
    padding: s(1),
    borderRadius: s(1),
    background: p.common.white,
    border: `1px solid ${p.grey[500]}`,
    marginTop: s(1),
  },
  head: {
    display: "flex",
    alignItems: "center",
  },
  headTitle: {
    fontSize: 20,
    fontWeight: 500,
    color: "#263E7F",
  },
  infoIcon: {
    fontSize: 20,
    marginRight: s(1),
  },
  toggleBtn: {
    marginLeft: "auto",
  },
  toggleIcon: {
    fontSize: 15,
  },
  content: {},
  divider: {
    marginTop: s(2),
    marginBottom: s(2),
  },
  contentText: {
    fontSize: 16,
    color: p.grey[700],
  },
  didNot: {
    fontWeight: 600,
    color: "#c23f38",
  },
  did: {
    fontWeight: 600,
    color: "green",
  },
  reason: {
    display: "flex",
    alignItems: "center",
  },
  reasonKey: {
    color: p.grey[700],
    fontSize: 16,
    fontWeight: 600,
    marginRight: s(1),
  },
  reasonValue: {
    color: p.grey[600],
    fontSize: 14,
  },
}));

interface Props {
  canAcceptOrRefuseCase: boolean;
  approvalRequest: ServiceCaseResponse["approvalRequests"][0] | null;
}

export const ReviewPanel: React.FC<Props> = ({
  approvalRequest,
  canAcceptOrRefuseCase,
}) => {
  const [expand, setExpand] = useState(true);

  const toggleExpand = useCallback(() => {
    setExpand((e) => !e);
  }, []);

  const classes = useStyles();

  if (
    !approvalRequest?.mdReasonForRejection &&
    !approvalRequest?.mdReasonForRecommendation
  ) {
    return null;
  }
  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title}>
        {canAcceptOrRefuseCase
          ? "Review the Service Case"
          : "Service Case is Pending Decision from Management"}
      </Typography>

      <div className={classes.recommendation}>
        <div className={classes.head}>
          <InfoOutlined color="primary" className={classes.infoIcon} />
          <Typography className={classes.headTitle}>
            View Medical Director’s Recommendation
          </Typography>
          <IconButton
            size="small"
            onClick={toggleExpand}
            className={classes.toggleBtn}
          >
            <ArrowBackIosOutlined
              color="primary"
              className={classes.toggleIcon}
              style={{
                transform: `rotate(${expand ? "-90" : "0"}deg)`,
                transition: "0.2s",
              }}
            />
          </IconButton>
        </div>

        <Collapse in={expand}>
          <div className={classes.content}>
            <div className={classes.divider}>
              <Divider />
            </div>
            <Typography className={classes.contentText}>
              The Medical Director{" "}
              {!!approvalRequest?.mdReasonForRejection && (
                <span className={classes.didNot}>DID NOT RECOMMEND</span>
              )}
              {!!approvalRequest?.mdReasonForRecommendation && (
                <span className={classes.did}>RECOMMENDS</span>
              )}{" "}
              for BahamasEvac to take the case.
            </Typography>
            <div className={classes.reason}>
              <Typography className={classes.reasonKey}>Reason:</Typography>
              <Typography className={classes.reasonValue}>
                {approvalRequest?.mdReasonForRecommendation ||
                  approvalRequest?.mdReasonForRejection ||
                  ""}
              </Typography>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};
