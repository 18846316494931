import {
  PostDispatcherActivityLog,
  DispatcherActivityLogTypeEnum,
} from "@deep-consulting-solutions/be2-constants";

export const composeAttentionCheckLog = (
  entryTime: Date,
  triggerTime: Date,
  ticketKey: string | null
) => {
  const timePassed = entryTime.getTime() - triggerTime.getTime();

  const body: typeof PostDispatcherActivityLog["Body"] = {
    type:
      timePassed <= 60 * 1000
        ? DispatcherActivityLogTypeEnum.ON_TIME_PASSED_CHECK_IN
        : DispatcherActivityLogTypeEnum.LATE_PASSED_CHECK_IN,
    entryTime: entryTime.toISOString(),
    triggerTime: triggerTime.toISOString(),
    ticketKey: ticketKey || undefined,
  };
  return body;
};

export const composeLateCheckinLog = (
  entryTime: Date,
  triggerTime: Date,
  ticketKey: string | null
) => {
  const body: typeof PostDispatcherActivityLog["Body"] = {
    type: DispatcherActivityLogTypeEnum.FAILED_CHECK_IN,
    entryTime: entryTime.toISOString(),
    triggerTime: triggerTime.toISOString(),
    ticketKey: ticketKey || undefined,
  };
  return body;
};
