import { Field, FormikProps } from "formik";
import { TextField } from "formik-material-ui";
import React, { useCallback, useEffect } from "react";

import { codeToCountry, getCountryCode } from "helpers";
import {
  StateField,
  IslandField,
  FormikCountrySelectInput,
} from "component/CommonFields";

export interface AddressFormValues {
  country: string;
  street: string;
  city: string;
  islandOrStateOrRegion: string | null;
  poBoxOrZipOrPostalCode: string;
}

const isBahamas = (country: string) => country === "BS";
const isUnitedStates = (country: string) => country === "US";

interface AddressFormFieldsProps<FormValues extends Record<string, any>> {
  formikProps: FormikProps<FormValues>;
  cityName?: string;
  islandName?: string;
  countryName?: string;
  poBoxName?: string;
  use2Stars?: boolean;
  rootClassName?: string;
}

export const AddressFormFields = <FormValues extends Record<string, any>>({
  formikProps,
  cityName = "city",
  islandName = "islandOrStateOrRegion",
  countryName = "country",
  poBoxName = "poBoxOrZipOrPostalCode",
  use2Stars,
  rootClassName,
}: AddressFormFieldsProps<FormValues>) => {
  const { values, setFieldValue, validateForm } = formikProps;

  const country = getCountryCode(values[countryName] as string);
  const island = values[islandName];

  const isBS = isBahamas(country || "");
  const isUs = isUnitedStates(country || "");

  let zipLabel = "Postal Code";
  if (isBS) zipLabel = "P.O.Box";
  if (isUs) zipLabel = "ZIP";

  const onCountryChange = useCallback(
    (selectedCountry: string) => {
      setFieldValue(
        countryName,
        codeToCountry(selectedCountry) || selectedCountry
      );
    },
    [setFieldValue, countryName]
  );

  useEffect(() => {
    if (country) {
      validateForm();
    }
  }, [validateForm, country]);

  return (
    <div className={rootClassName}>
      <Field
        component={FormikCountrySelectInput}
        name={countryName}
        label={use2Stars ? "Country**" : "Country*"}
        onCountryChange={onCountryChange}
        useCountryCode={false}
      />
      <Field component={TextField} name={cityName} label="City*" />
      {isBS && (
        <IslandField
          name={islandName}
          formikProps={formikProps}
          use2Stars={use2Stars}
        />
      )}
      {isUs && (
        <StateField
          name={islandName}
          formikProps={formikProps}
          use2Stars={use2Stars}
        />
      )}
      {!isBS && !isUs && (
        <Field
          component={TextField}
          name={islandName}
          label={use2Stars ? "Region **" : "Region*"}
          value={island || ""}
        />
      )}

      <Field component={TextField} name={poBoxName} label={zipLabel} />
    </div>
  );
};
