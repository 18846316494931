import React, {
  MouseEventHandler,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { useCall } from "call/hooks";
import {
  ServiceCaseResponse,
  ServiceCaseStatusEnum,
} from "@deep-consulting-solutions/be2-constants";
import { MovePatientsToNewCaseDialog } from "./MovePatientsToNewCaseDialog";

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  divider: {
    margin: s(2, 0),
  },
  moveInfo: {
    background: p.primary.main,
    color: "white",
    borderRadius: 4,
    marginBottom: s(2),
  },
}));

interface MovePatientsToNewCaseProps {
  setCases: React.Dispatch<React.SetStateAction<ServiceCaseResponse[]>>;
}

export const MovePatientsToNewCase = ({
  setCases,
}: MovePatientsToNewCaseProps) => {
  const { viewedCall } = useCall();
  const [openMovePatientsToNewCaseDialog, setOpenMovePatientsToNewCaseDialog] =
    useState(false);

  const patients = useMemo(() => {
    return viewedCall?.updatedServiceCase?.patients || [];
  }, [viewedCall?.updatedServiceCase?.patients]);

  const hasMoreThanOnePatients = useMemo(() => {
    return patients.length > 1;
  }, [patients]);

  const handleOpenMovePatientsToNewCaseDialog: MouseEventHandler<HTMLButtonElement> =
    useCallback((e) => {
      e.preventDefault();
      setOpenMovePatientsToNewCaseDialog(true);
    }, []);

  const handleCloseMovePatientsToNewCaseDialog = useCallback(() => {
    setOpenMovePatientsToNewCaseDialog(false);
  }, []);

  const classes = useStyles();

  const disableForm = useMemo(
    () =>
      !!viewedCall?.disableForms ||
      viewedCall?.updatedServiceCase?.caseStatus !==
        ServiceCaseStatusEnum.GATHERING_REQUIREMENTS,
    [viewedCall?.disableForms, viewedCall?.updatedServiceCase?.caseStatus]
  );

  if (!hasMoreThanOnePatients) {
    return null;
  }

  return (
    <>
      <MovePatientsToNewCaseDialog
        setCases={setCases}
        open={openMovePatientsToNewCaseDialog}
        handleClose={handleCloseMovePatientsToNewCaseDialog}
      />
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="flex-start"
        className={classes.moveInfo}
        wrap="nowrap"
      >
        <Grid item>
          <InfoOutlined />
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" align="left">
            If the Patients can&apos;t be transported by the same vehicle and
            medical crew or if the Patients have different Physicians you should
            move them to a different Service Case.
          </Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleOpenMovePatientsToNewCaseDialog}
            disabled={disableForm}
          >
            MOVE PATIENTS TO NEW CASE
          </Button>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
    </>
  );
};
