import React from "react";
import { useStyles } from "./style";
import { Icon } from "./types";

export const Volume = ({ onClick }: Icon) => {
  const classes = useStyles();
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={classes.icon}
    >
      <path
        d="M9.30748 0.795027C9.30727 0.649078 9.26589 0.50615 9.18808 0.382667C9.11028 0.259184 8.99922 0.160158 8.86766 0.0969701C8.7361 0.0337819 8.58938 0.0089951 8.44436 0.025459C8.29934 0.0419228 8.16191 0.0989688 8.04786 0.190041L4.38227 3.1219H0.775623C0.569915 3.1219 0.372632 3.20361 0.227175 3.34907C0.0817173 3.49453 0 3.69181 0 3.89752V10.1025C0 10.3082 0.0817173 10.5055 0.227175 10.651C0.372632 10.7964 0.569915 10.8781 0.775623 10.8781H4.38227L8.04786 13.81C8.16191 13.9011 8.29934 13.9581 8.44436 13.9746C8.58938 13.991 8.7361 13.9662 8.86766 13.9031C8.99922 13.8399 9.11028 13.7408 9.18808 13.6174C9.26589 13.4939 9.30727 13.3509 9.30748 13.205V0.795027ZM14 7.00001C14.0012 7.9169 13.8212 8.82498 13.4703 9.67207C13.1194 10.5192 12.6046 11.2886 11.9555 11.9361L10.8587 10.8393C11.3636 10.3357 11.764 9.73724 12.0369 9.07836C12.3098 8.41948 12.4497 7.71316 12.4487 7.00001C12.4496 6.28687 12.3096 5.58058 12.0368 4.92171C11.7639 4.26284 11.3636 3.66437 10.8587 3.16068L11.9555 2.06395C12.6046 2.71145 13.1194 3.48085 13.4703 4.32795C13.8212 5.17504 14.0012 6.08313 14 7.00001Z"
        fill="white"
      />
    </svg>
  );
};
