import React, { useCallback, useMemo } from "react";
import { Field, Formik, FormikConfig } from "formik";
import {
  Dialog,
  Button,
  makeStyles,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { DispatcherActivityLogTypeEnum } from "@deep-consulting-solutions/be2-constants";
import { CheckboxWithLabel } from "formik-material-ui";

type FiltersFormValues = {
  [key in DispatcherActivityLogTypeEnum]: boolean;
};

interface FiltersModalProps {
  onClose: () => any;
  activeFilters: DispatcherActivityLogTypeEnum[];
  onFiltersSelect: (
    values: Record<DispatcherActivityLogTypeEnum, boolean>
  ) => any;
}

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  dialog: {
    "& .MuiPaper-root": {
      padding: s(2),
      maxHeight: "504px",
    },
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: "32px",
  },
  filters: {
    display: "flex",
    flexDirection: "column",
  },
  filterField: {
    color: p.primary.main,
  },
}));

const FiltersModal = ({
  onClose,
  activeFilters,
  onFiltersSelect,
}: FiltersModalProps) => {
  const classes = useStyles();

  const initialValues = useMemo(
    () =>
      Object.values(DispatcherActivityLogTypeEnum).reduce((acc, cur) => {
        acc[cur] = activeFilters.includes(cur);
        return acc;
      }, {} as FiltersFormValues),
    [activeFilters]
  );

  const handleSubmit: FormikConfig<FiltersFormValues>["onSubmit"] = useCallback(
    (values) => {
      onFiltersSelect(values);
    },
    [onFiltersSelect]
  );

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ submitForm }) => (
        <Dialog
          open
          fullWidth
          maxWidth="sm"
          onClose={onClose}
          className={classes.dialog}
        >
          <DialogTitle>
            <Typography variant="subtitle2" className={classes.modalTitle}>
              Log Event Type
            </Typography>
          </DialogTitle>

          <DialogContent className={classes.filters}>
            {Object.values(DispatcherActivityLogTypeEnum).map((logType) => (
              <Field
                name={logType}
                type="checkbox"
                color="primary"
                Label={{ label: logType }}
                component={CheckboxWithLabel}
                className={classes.filterField}
              />
            ))}
          </DialogContent>

          <DialogActions>
            <Button onClick={onClose} variant="text" type="button">
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => {
                submitForm();
              }}
            >
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

export default FiltersModal;
