import React, { memo } from "react";
import clsx from "clsx";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { CircularProgress, Backdrop, BackdropProps } from "@material-ui/core";

interface StyleProps {
  absolute?: boolean;
  transparent?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: ({ absolute, transparent }: StyleProps) => ({
      zIndex: theme.zIndex.modal + 1,
      background: transparent ? "transparent" : "rgba(255, 255, 255, 0.7)",
      position: absolute ? "absolute" : undefined,
    }),
  })
);

interface Props extends BackdropProps {
  open: boolean;
  absolute?: boolean;
  transparent?: boolean;
  size?: number;
}

const Loader: React.FC<Props> = ({
  open,
  absolute,
  transparent,
  className,
  size,
  ...props
}) => {
  const classes = useStyles({ absolute, transparent });

  return (
    <Backdrop
      className={clsx(classes.backdrop, className)}
      open={open}
      data-testid="loader"
      {...props}
    >
      <CircularProgress size={size} color="primary" />
    </Backdrop>
  );
};

export default memo(Loader);
