import {
  Dialog,
  Typography,
  makeStyles,
  DialogContent,
  FormControlLabel,
  Radio,
  Tooltip,
  Divider,
  Grid,
  MenuItem,
  Button,
} from "@material-ui/core";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { InfoOutlined } from "@material-ui/icons";
import { Formik, Form, FormikProps, Field, FormikConfig } from "formik";
import { RadioGroup, TextField } from "formik-material-ui";
import clsx from "clsx";

import { DialogTitleWithCloseButton } from "component/DialogTitleWithCloseButton";
import { FormikPhoneInput } from "component/FormikPhoneInput";
import Loader from "component/Loader";

import { FormValues, getValidationSchema } from "../helpers";

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  contentWrapper: {
    padding: s(3),
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: p.primary.main,
    marginBottom: s(1),
  },
  controlLabel: {
    marginRight: 50,
  },
  typeRadioLabel: {
    display: "flex",
    alignItems: "center",
  },
  radioLabelText: {
    color: "#192954",
    fontSize: 16,
  },
  radioLabelTextUnselect: {
    color: p.grey[500],
  },
  infoIcon: {
    color: "#dd742d",
    fontSize: 20,
    marginLeft: s(1),
  },
  infoTooltip: {
    textAlign: "center",
    fontSize: 8,
  },
  divider: {
    marginTop: s(1),
    marginBottom: s(1),
  },
  shareTypes: {
    marginBottom: s(1),
  },
  input: {
    "&::placeholder": {
      fontSize: 14,
    },
    fontSize: 14,
  },
  actions: {
    textAlign: "right",
    marginTop: s(2),
  },
}));

interface Props {
  open: boolean;
  onClose: () => any;
  onGenerateClick: (values: FormValues) => Promise<boolean>;
}

export const GenerateDialog: React.FC<Props> = ({
  open,
  onClose,
  onGenerateClick,
}) => {
  const formRef = useRef<FormikProps<FormValues>>(null);
  const [loading, setLoading] = useState(false);

  const validationSchema = useMemo(() => getValidationSchema(), []);

  const handleSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    async (values, helpers) => {
      try {
        setLoading(true);
        const isDone = await onGenerateClick(values);
        setLoading(false);
        if (isDone) {
          helpers.resetForm();
          onClose();
        }
      } catch {
        setLoading(false);
      }
    },
    [onClose, onGenerateClick]
  );

  const classes = useStyles();

  return (
    <Formik<FormValues>
      initialValues={{
        accessType: "URL",
        shareType: "organization",
        organizationName: "",
        personName: "",
        designationPerson: "",
        shareVia: "email",
        email: "",
        phone: "",
      }}
      innerRef={formRef}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, isValid }) => {
        return (
          <Dialog open={!!open} maxWidth="md" fullWidth>
            <Loader open={loading} />
            <DialogTitleWithCloseButton
              title="Service Case Access Link"
              onClose={onClose}
            />

            <DialogContent className={classes.contentWrapper}>
              <Form>
                <div>
                  <Typography className={classes.sectionTitle}>Type</Typography>
                  <Field name="accessType" component={RadioGroup}>
                    <div>
                      <FormControlLabel
                        value="URL"
                        control={<Radio color="primary" />}
                        label={
                          <div className={classes.typeRadioLabel}>
                            <Typography
                              className={clsx(
                                classes.radioLabelText,
                                values.accessType !== "URL" &&
                                  classes.radioLabelTextUnselect
                              )}
                            >
                              URL Based
                            </Typography>
                            <Tooltip
                              title={
                                <Typography className={classes.infoTooltip}>
                                  The access link will be very hard to
                                  communicate verbally but no PIN is required.
                                </Typography>
                              }
                              arrow
                              placement="top"
                            >
                              <InfoOutlined className={classes.infoIcon} />
                            </Tooltip>
                          </div>
                        }
                        className={classes.controlLabel}
                      />
                      <FormControlLabel
                        value="PIN"
                        control={<Radio color="primary" />}
                        label={
                          <div className={classes.typeRadioLabel}>
                            <Typography
                              className={clsx(
                                classes.radioLabelText,
                                values.accessType !== "PIN" &&
                                  classes.radioLabelTextUnselect
                              )}
                            >
                              PIN Based
                            </Typography>
                            <Tooltip
                              title={
                                <Typography className={classes.infoTooltip}>
                                  The access link is simple to communicate
                                  verbally but a PIN is required.
                                </Typography>
                              }
                              arrow
                              placement="top"
                            >
                              <InfoOutlined className={classes.infoIcon} />
                            </Tooltip>
                          </div>
                        }
                      />
                    </div>
                  </Field>
                </div>

                <div className={classes.divider}>
                  <Divider />
                </div>

                <div>
                  <Typography className={classes.sectionTitle}>
                    Share To
                  </Typography>
                  <Field name="shareType" component={RadioGroup}>
                    <div className={classes.shareTypes}>
                      <FormControlLabel
                        value="organization"
                        control={<Radio color="primary" />}
                        label={
                          <Typography
                            className={clsx(
                              classes.radioLabelText,
                              values.shareType !== "organization" &&
                                classes.radioLabelTextUnselect
                            )}
                          >
                            Organization
                          </Typography>
                        }
                        className={classes.controlLabel}
                      />
                      <FormControlLabel
                        value="individual"
                        control={<Radio color="primary" />}
                        label={
                          <Typography
                            className={clsx(
                              classes.radioLabelText,
                              values.shareType !== "individual" &&
                                classes.radioLabelTextUnselect
                            )}
                          >
                            Individual
                          </Typography>
                        }
                        className={classes.controlLabel}
                      />
                      <FormControlLabel
                        value="anyone"
                        control={<Radio color="primary" />}
                        label={
                          <Typography
                            className={clsx(
                              classes.radioLabelText,
                              values.shareType !== "anyone" &&
                                classes.radioLabelTextUnselect
                            )}
                          >
                            Anyone with URL / PIN
                          </Typography>
                        }
                      />
                    </div>
                  </Field>
                </div>

                <Grid container>
                  {values.shareType === "organization" && (
                    <Grid item xs={4}>
                      <Field
                        required={values.shareType === "organization"}
                        name="organizationName"
                        component={TextField}
                        label="Organization Name"
                        size="small"
                        InputProps={{
                          className: classes.input,
                        }}
                        InputLabelProps={{
                          className: classes.input,
                        }}
                      />
                    </Grid>
                  )}

                  {values.shareType !== "anyone" && (
                    <Grid item xs={values.shareType === "organization" ? 4 : 6}>
                      <Field
                        required
                        name="personName"
                        component={TextField}
                        label="Person Name"
                        size="small"
                        InputProps={{
                          className: classes.input,
                        }}
                        InputLabelProps={{
                          className: classes.input,
                        }}
                      />
                    </Grid>
                  )}
                  {values.shareType !== "anyone" && (
                    <Grid item xs={values.shareType === "organization" ? 4 : 6}>
                      <Field
                        name="designationPerson"
                        component={TextField}
                        label="Designation of Person"
                        size="small"
                        InputProps={{
                          className: classes.input,
                        }}
                        InputLabelProps={{
                          className: classes.input,
                        }}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Divider orientation="horizontal" />
                  </Grid>

                  <Grid item xs={6}>
                    <Field
                      component={TextField}
                      select
                      name="shareVia"
                      label="Share Via"
                      required
                      size="small"
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        },
                      }}
                      InputProps={{
                        className: classes.input,
                      }}
                      InputLabelProps={{
                        className: classes.input,
                      }}
                    >
                      <MenuItem value="email">Email</MenuItem>
                      <MenuItem value="phone">Phone</MenuItem>
                    </Field>
                  </Grid>

                  {values.shareVia === "email" && (
                    <Grid item xs={6}>
                      <Field
                        name="email"
                        label="Email"
                        component={TextField}
                        required
                        size="small"
                        InputProps={{
                          className: classes.input,
                        }}
                        InputLabelProps={{
                          className: classes.input,
                        }}
                      />
                    </Grid>
                  )}

                  {values.shareVia === "phone" && (
                    <Grid item xs={6}>
                      <Field
                        name="phone"
                        component={FormikPhoneInput}
                        label="Phone"
                        required
                        size="small"
                        InputLabelProps={{
                          className: classes.input,
                        }}
                      />
                    </Grid>
                  )}
                </Grid>

                <div className={classes.actions}>
                  <Button color="primary" type="submit" disabled={!isValid}>
                    Generate and Share
                  </Button>
                </div>
              </Form>
            </DialogContent>
          </Dialog>
        );
      }}
    </Formik>
  );
};
