import { useCallback, useState } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import { useStyles } from "call/components/CallForms/RequesterAndPatientForm/styles";
import { MembersResponseSubObject } from "@deep-consulting-solutions/be2-constants";
import { SearchedPatients } from "./SearchedPatients";

interface Props {
  onPatientSelect: (member: string) => void;
  reSearch?: boolean;
  requesterMembers?: MembersResponseSubObject[];
  formKey: string;
  handleOpenRemovePatientDialog?: () => void;
  showRemovePatientButton?: boolean;
  getMembershipOptions: (membership: MembersResponseSubObject) => {
    label: string;
    value: string | undefined;
  }[];
  disableForm: boolean;
}

export const SearchForPatient: React.FC<Props> = ({
  onPatientSelect,
  reSearch,
  requesterMembers,
  formKey,
  handleOpenRemovePatientDialog = () => {},
  showRemovePatientButton = false,
  getMembershipOptions,
  disableForm,
}) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const classes = useStyles({});

  const toggleSearch = useCallback(() => {
    setSearchOpen((current) => !current);
  }, []);

  const handlePatientSelect = useCallback(
    (option: string) => {
      onPatientSelect(option);
      setSearchOpen(false);
    },
    [onPatientSelect]
  );

  const handleCancelSelection = useCallback(() => {
    setSearchOpen(false);
  }, []);

  return (
    <div className={classes.searchMemberContainer}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.fullWidth}
      >
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={toggleSearch}
            disabled={searchOpen || !requesterMembers?.length || disableForm}
            className={classes.searchForMembersButton}
          >
            {reSearch ? "Select a different Patient" : "Select a Patient"}
          </Button>
        </Grid>
        {showRemovePatientButton && (
          <Grid item>
            <Button
              variant="contained"
              onClick={handleOpenRemovePatientDialog}
              className={classes.deleteButton}
              disabled={disableForm}
            >
              Delete
            </Button>
          </Grid>
        )}
      </Grid>
      {searchOpen && (
        <div className={classes.searchFieldsContainer}>
          <SearchedPatients
            onClickPatient={handlePatientSelect}
            requesterMembers={requesterMembers || []}
            formKey={formKey}
            getMembershipOptions={getMembershipOptions}
            disableForm={disableForm}
          />
          <Box
            mt={2}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            width="100%"
          >
            <Button variant="outlined" onClick={handleCancelSelection}>
              Cancel
            </Button>
          </Box>
        </div>
      )}
    </div>
  );
};
