export const ROUTES = {
  fulfilment: {
    path: "/",
  },
  fulfilmentUnAnsweredRequest: {
    path: "/unanswered-requests",
  },
  dispatcherActivity: {
    path: "/fulfilment/dispatcherActivity",
  },
  caseClassifications: {
    path: "/case-classifications",
  },
  caseClassificationsWidget: {
    path: "/case-classifications-widget",
  },
  locationCollector: {
    path: "/locationCollector",
  },
};
