import React, { ReactNode, useMemo } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  makeStyles,
  Theme,
} from "@material-ui/core";
import {
  KeyboardArrowLeft,
  CheckCircleOutline as SuccessIcon,
  ErrorOutline as ErrorIcon,
} from "@material-ui/icons";

interface StylesProps {
  morePadding?: boolean;
  hasHorizontalPadding?: boolean;
  backgroundColor?: string;
  boxShadow?: string;
  bordered?: boolean;
  expanded: boolean;
}

const useStyles = makeStyles<Theme, StylesProps>(
  ({ spacing: s, palette: p, breakpoints: b }) => ({
    accordion: {
      boxShadow: "none",
      borderRadius: s(1),
      padding: (props) => (props.morePadding ? s(1.5) : undefined),
      border: (props) => (props.bordered ? "1px solid #E0E0E0" : undefined),
      "&::before": {
        opacity: 0,
        height: 0,
      },

      backgroundColor: (props) =>
        // eslint-disable-next-line no-nested-ternary
        props.expanded
          ? p.common.white
          : props.backgroundColor
          ? props.backgroundColor
          : "#F6F6F6",
      "&.Mui-disabled": {
        backgroundColor: (props) =>
          props.backgroundColor ? props.backgroundColor : "#F6F6F6",
      },
      "&.Mui-expanded": {
        marginBottom: s(1),
        marginTop: 0,
        [b.down("sm")]: {
          marginBottom: 0,
          marginTop: 0,
        },
      },
    },
    summaryDisabled: {
      background: (props) =>
        props.backgroundColor ? props.backgroundColor : "#F6F6F6",
      color: `${p.text.disabled} !important`,
      opacity: `1 !important`,
    },
    summary: {
      position: "relative",
      color: p.primary.main,
      minHeight: `${s(5.5)}px !important`,
      height: s(5.5),
      padding: 0,
      margin: ({ hasHorizontalPadding, expanded }) =>
        hasHorizontalPadding || !expanded ? s(0, 2) : 0,
      "& .MuiAccordionSummary-content.Mui-expanded": {
        margin: s(1.5, 0),
      },
    },
    expandIcon: {
      "&.Mui-expanded": {
        transform: "rotate(90deg)",
      },
    },
    divider: {
      background: p.primary.dark,
      margin: s(0, 2, 2),
    },
    details: {
      display: "block",
      padding: ({ hasHorizontalPadding }) =>
        hasHorizontalPadding ? s(0, 0.75) : 0,
      [b.up("sm")]: {
        padding: ({ hasHorizontalPadding }) =>
          hasHorizontalPadding ? s(1, 2) : s(1, 0),
      },
    },
    statusIcon: ({ expanded }) => ({
      marginLeft: s(1),
      display: "flex",
      alignItems: "center",
      opacity: expanded ? 0 : 1,
      transition: "0.2s",
    }),
    successIcon: {
      color: p.success.main,
    },
    errorIcon: {
      color: p.error.main,
    },
    rad: {
      position: "relative",
      top: 7,
      left: 5,
      backgroundColor: "#F2994A",
      boxShadow: "0px 0px 3px 1px #f2994a",
      borderRadius: 5,
      width: 5,
      height: 5,
    },
  })
);

export interface StyledAccordionProps extends AccordionProps {
  morePadding?: boolean;
  hasHorizontalPadding?: boolean;
  backgroundColor?: string;
  disabled?: boolean;
  summary:
    | NonNullable<ReactNode>
    | ((expanded: boolean) => NonNullable<ReactNode>);
  children: NonNullable<ReactNode>;
  id?: string;
  defaultExpanded?: boolean;
  isValid?: boolean;
  showValid?: boolean;
  callAttention?: boolean;
  bordered?: boolean;
}

export const StyledAccordion: React.FC<StyledAccordionProps> = ({
  disabled = false,
  summary,
  children,
  id,
  backgroundColor,
  morePadding,
  hasHorizontalPadding,
  isValid,
  showValid,
  callAttention,
  expanded = false,
  bordered,
  ...accordionProps
}) => {
  const summaryNode: NonNullable<ReactNode> = useMemo(() => {
    if (typeof summary === "function") return summary(expanded);
    return summary;
  }, [summary, expanded]);

  const classes = useStyles({
    backgroundColor,
    morePadding,
    expanded,
    hasHorizontalPadding,
    bordered,
  });

  return (
    <Accordion
      classes={{ root: classes.accordion }}
      disabled={disabled}
      expanded={expanded}
      {...accordionProps}
    >
      <AccordionSummary
        classes={{
          root: classes.summary,
          disabled: classes.summaryDisabled,
          expandIcon: classes.expandIcon,
        }}
        expandIcon={
          <KeyboardArrowLeft color={disabled ? "disabled" : "primary"} />
        }
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        {summaryNode}
        {!expanded && callAttention && <span className={classes.rad} />}
        {isValid !== undefined && (
          <div className={classes.statusIcon}>
            {isValid && showValid && (
              <SuccessIcon className={classes.successIcon} />
            )}
            {!isValid && <ErrorIcon className={classes.errorIcon} />}
          </div>
        )}
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
