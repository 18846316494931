import { combineReducers } from "@reduxjs/toolkit";
import call, { CallState } from "./call";
import zoho, { ZohoState } from "./zoho";
import hospital, { HospitalState } from "./hospital";
import dispatcher, { DispatcherState } from "./dispatcher";
import notifications, { NotificationState } from "./notifications";
import attentionCheck, { AttentionCheckState } from "./attentionCheck";

export const rootReducer = combineReducers({
  zoho,
  call,
  hospital,
  dispatcher,
  notifications,
  attentionCheck,
});

export interface RootState {
  zoho: ZohoState;
  call: CallState;
  hospital: HospitalState;
  dispatcher: DispatcherState;
  notifications: NotificationState;
  attentionCheck: AttentionCheckState;
}
