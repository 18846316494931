import React, { useCallback, useState } from "react";
import { Collapse, IconButton, makeStyles } from "@material-ui/core";
import { ExpandLess } from "@material-ui/icons";

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  card: {
    padding: s(1),
    border: `1px solid ${p.grey[300]}`,
    borderRadius: s(0.5),
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  expandBtn: {
    borderRadius: s(0.5),
    background: "#f6f6f6",
  },
  expandIcon: {
    fontSize: 15,
  },
}));

interface Props {
  title: JSX.Element;
}

export const CollapsedCard: React.FC<Props> = ({ title, children }) => {
  const [expand, setExpand] = useState(true);

  const handleExpandToggle = useCallback(() => {
    setExpand((e) => !e);
  }, []);

  const classes = useStyles();
  return (
    <div className={classes.card}>
      <div className={classes.header}>
        {title}
        <IconButton
          className={classes.expandBtn}
          size="small"
          onClick={handleExpandToggle}
        >
          <ExpandLess
            style={{
              transition: "0.2s",
              transform: `rotate(${expand ? "-180" : "-90"}deg)`,
            }}
            color="primary"
            className={classes.expandIcon}
          />
        </IconButton>
      </div>
      <Collapse in={expand}>{children}</Collapse>
    </div>
  );
};
