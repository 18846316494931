import { SiteContextProvider } from "context";
import React, { FC, useEffect, useMemo, useState } from "react";
import { io } from "socket.io-client";
import AudioProvider from "context/AudioContext";
import MediaDeviceProvider from "context/MediaDeviceContext";

const SiteProvider: FC = ({ children }) => {
  const [isSocketConnected, setIsSocketConnected] = useState(false);

  const socket = useMemo(() => {
    const baseUrl = process.env.REACT_APP_BASE_URL as string;
    const s = io(baseUrl, { transports: ["websocket"] });

    s.on("connect", () => {
      setIsSocketConnected(true);
    });

    return s;
  }, []);

  useEffect(() => {
    // socket.onAny((e, f) => {
    //   console.log(e, f);
    // });

    return () => {
      socket.disconnect();
    };
  }, [socket]);

  return (
    <SiteContextProvider value={{ socket, isSocketConnected }}>
      <MediaDeviceProvider>
        <AudioProvider>{children}</AudioProvider>
      </MediaDeviceProvider>
    </SiteContextProvider>
  );
};

export default SiteProvider;
