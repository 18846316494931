import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { useDialogStyles } from "component/Dialogs/styles";
import clsx from "clsx";
import Loader from "component/Loader/Loader";
import { Field, FormikProvider, useFormik } from "formik";
import {
  CheckCircleOutline,
  CloseOutlined,
  ErrorOutline,
  InfoOutlined,
} from "@material-ui/icons";
import { TextField } from "formik-material-ui";
import { SelectField } from "component/atoms";
import {
  ServiceCaseCallBackTypeEnum,
  ServiceCaseRequestTypeEnum,
  ServiceCaseResponse,
  ServiceCaseStatusEnum,
} from "@deep-consulting-solutions/be2-constants";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { CloseServiceCase } from "component/Dialogs/CloseServiceCase";
import { format } from "date-fns";
import {
  logFailedCallBackAttempts,
  updateServiceCase,
} from "component/Dialogs/request";
import * as yup from "yup";
import { notifications } from "services";
import { parsePhoneNumber } from "libphonenumber-js";
import { formatNameToDisplay, getENText } from "helpers";

const formatPhoneNumber = (phone: string) => {
  try {
    const phoneNumber = parsePhoneNumber(phone);
    return `+${phoneNumber.countryCallingCode} ${phoneNumber.formatNational()}`;
  } catch (err) {
    return "";
  }
};

const requestTypeOptions = Object.values(ServiceCaseRequestTypeEnum);

const useStyle = makeStyles((th) => ({
  fields: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 20,
  },
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 700,
    },
  },
  contacts: {
    boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.12)",
    borderRadius: 8,
    backgroundColor: "#ffffff",
    padding: "8px 12px",
  },
  notify: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    backgroundColor: "#263E7F",
    color: "#ffffff",
    padding: "10px 18px",
    borderRadius: 4,
  },
  divider: {
    margin: th.spacing(2, 0),
  },

  table: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
  },
  tableHeadRow: {
    backgroundColor: "#F6F6F6",
  },
}));

function createData(name: string, time: string | JSX.Element) {
  return { name, time };
}

const AttemptSnippet = ({
  attemptLogged,
  onClose,
}: {
  attemptLogged: "success" | "error" | false;
  onClose: () => void;
}) => {
  return (
    <Box
      display="flex"
      flex={1}
      alignItems="flex-start"
      style={{
        gap: 10,
        width: "100%",
        padding: "14px 17px",
        borderRadius: 4,
        backgroundColor:
          attemptLogged === "success" ? "#4cae4f1a" : "#f443361a",
      }}
    >
      {attemptLogged === "success" ? (
        <CheckCircleOutline style={{ color: "#4CAF50" }} />
      ) : (
        <ErrorOutline style={{ color: "#F44336" }} />
      )}
      <Typography
        variant="body2"
        style={{
          color: attemptLogged === "success" ? "#4CAF50" : "#F44336",
        }}
      >
        {attemptLogged === "success"
          ? "Attempt logged successfully"
          : "Error while trying to log attempt"}
      </Typography>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <IconButton size="small" type="button" onClick={onClose}>
          <CloseOutlined
            style={{
              color: attemptLogged === "success" ? "#4caf5066" : "#f4433666",
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export const ContactRequester = ({
  data,
  onClose,
  onUpdate,
}: {
  data: ServiceCaseResponse;
  onClose?: () => void;
  onUpdate?: (key: string, value: any) => void;
}) => {
  const baseClasses = useDialogStyles();
  const classes = useStyle();
  const [closeCase, setCloseCase] = useState(false);
  const [attemptLogged, setAttemptLogged] = useState<
    "success" | "error" | false
  >(false);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      caseStatus: ServiceCaseStatusEnum.GATHERING_REQUIREMENTS,
      situationDescription: "",
      requestType: "" as any,
    },
    validateOnMount: true,
    validationSchema: yup.object({
      situationDescription: yup
        .string()
        .required(getENText("validation.situationDescription.required")),
      requestType: yup
        .string()
        .required(getENText("validation.requestType.required")),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const res = await updateServiceCase(data.id, values);
      notifications.notifySuccess("Done");
      setSubmitting(false);
      resetForm();
      if (onUpdate) onUpdate("record", res);
      if (onClose) onClose();
    },
  });

  const rows = useMemo(() => {
    return data?.callBackLogs
      ?.filter(
        (el) =>
          el?.callBackType === ServiceCaseCallBackTypeEnum.UNANSWERED_REQUEST
      )
      .map((el) =>
        createData(
          el?.dispatcher?.name ||
            formatNameToDisplay(
              el?.medicalDirector?.firstName,
              el?.medicalDirector?.lastName
            ),
          <>
            {format(new Date(el.callBackTime), "dd MMM yyyy")}{" "}
            <span style={{ color: "#828282", marginLeft: 5 }}>
              {format(new Date(el.callBackTime), "pp")}
            </span>
          </>
        )
      );
  }, [data.callBackLogs]);

  const logFailedAttempt = () => {
    (async () => {
      try {
        setLoading(true);
        const res = await logFailedCallBackAttempts(data.id, {
          callBackTime: new Date().toISOString(),
          callBackPhoneNumber: data.callbackPhone,
          callBackType: ServiceCaseCallBackTypeEnum.UNANSWERED_REQUEST,
        });
        setAttemptLogged("success");
        setLoading(false);
        if (onUpdate) onUpdate("callBackLogs", res);
      } catch (e) {
        setAttemptLogged("error");
        setLoading(false);
      }
    })();
  };

  const closeAttemptSnippet = () => {
    setAttemptLogged(false);
  };

  const onCloseCase = (action?: string) => {
    setCloseCase(!closeCase);
    if (onUpdate) onUpdate("close-case", { id: data.id });
    if (onClose && action === "close-all") onClose();
  };

  const handleClose = () => {
    if (onClose) {
      closeAttemptSnippet();
      formik.resetForm();
      onClose();
    }
  };

  const btnTheme = React.useMemo(() => {
    return createTheme({
      palette: {
        secondary: {
          main: "#CA2027",
          contrastText: "#fff",
        },
      },
    });
  }, []);

  return (
    <>
      <CloseServiceCase open={closeCase} onClose={onCloseCase} id={data.id} />
      <Dialog
        open={Boolean(data) || loading}
        onClose={handleClose}
        onBackdropClick={handleClose}
        className={clsx(classes.dialog, baseClasses.dialog)}
      >
        <Loader open={formik.isSubmitting || loading} absolute />
        {Boolean(data) && (
          <FormikProvider value={formik}>
            <div className={baseClasses.dialogTitle}>
              <Typography
                color="primary"
                style={{ fontSize: 20, fontWeight: 500 }}
              >
                Contact Requester for Service Case {data.caseNumber}
              </Typography>
              <IconButton size="small" onClick={handleClose}>
                <CloseOutlined style={{ fontSize: 20, color: "#BDBDBD" }} />
              </IconButton>
            </div>
            <DialogContent>
              <form>
                <Box className={classes.notify}>
                  <InfoOutlined />
                  Try to contact Requester and continue with information
                  collection
                </Box>
                <Box mt={3}>
                  <Typography color="primary" style={{ fontWeight: 500 }}>
                    Unanswered Service Request Made On:
                  </Typography>
                  <Typography variant="body2">
                    {format(
                      new Date(data?.createdAt || ""),
                      "dd MMM yyyy - pp"
                    )}{" "}
                    - <b>{data.requestOrigin}</b>
                  </Typography>
                </Box>
                <Divider className={classes.divider} />
                <Box mt={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={8}>
                      <Typography
                        color="primary"
                        style={{ marginBottom: 10, fontWeight: 500 }}
                      >
                        Call Back Attempts ({rows.length})
                      </Typography>
                      <TableContainer style={{ maxHeight: 200 }}>
                        <Table
                          className={classes.table}
                          size="small"
                          stickyHeader
                        >
                          <TableHead>
                            <TableRow className={classes.tableHeadRow}>
                              <TableCell align="left">Dispatcher</TableCell>
                              <TableCell align="left">
                                Time of the Attempt
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row, i) => (
                              <TableRow key={`${i.toString()}`}>
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="left">{row.time}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        color="primary"
                        style={{ marginBottom: 10, fontWeight: 500 }}
                      >
                        Contacts Numbers:
                      </Typography>
                      <Box className={classes.contacts}>
                        <div>
                          <Typography
                            variant="caption"
                            style={{ color: "#828282" }}
                          >
                            Primary (Main Beneficiary):
                          </Typography>
                          <Typography variant="body2">
                            {data?.contactNumber &&
                              formatPhoneNumber(data?.contactNumber)}
                          </Typography>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ gap: 10, marginTop: 20 }}
                  >
                    <Button
                      color="primary"
                      type="button"
                      onClick={logFailedAttempt}
                    >
                      Log Failed Callback Attempt
                    </Button>
                    <Box>
                      {attemptLogged && (
                        <AttemptSnippet
                          attemptLogged={attemptLogged}
                          onClose={closeAttemptSnippet}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
                <Divider className={classes.divider} />
                <Box mb={2}>
                  <Typography color="primary" style={{ fontWeight: 500 }}>
                    Situation Description
                  </Typography>
                  <Typography variant="body2" style={{ color: "#4F4F4F" }}>
                    Obtain information from the Requester
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      size="small"
                      component={TextField}
                      name="situationDescription"
                      label="Situation Description"
                      multiline
                      required
                      minRows={3}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectField
                      size="small"
                      name="requestType"
                      label="Request Type"
                      required
                      options={requestTypeOptions}
                      helperText="Choose the type of service that is being requested after assessing the Requester's situation"
                    />
                  </Grid>
                </Grid>
              </form>
            </DialogContent>
            <DialogActions style={{ padding: 24 }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <ThemeProvider theme={btnTheme}>
                  <Button
                    color="secondary"
                    variant="contained"
                    type="submit"
                    onClick={() => setCloseCase(true)}
                  >
                    Close Service Case
                  </Button>
                </ThemeProvider>
                <Button
                  color="primary"
                  type="submit"
                  onClick={formik.handleSubmit as any}
                  disabled={!formik.isValid}
                >
                  Continue With Request
                </Button>
              </Box>
            </DialogActions>
          </FormikProvider>
        )}
      </Dialog>
    </>
  );
};
