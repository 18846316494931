import React from "react";
import { useStyles } from "./style";
import { Icon } from "./types";

export const ChatIcon = ({ onClick }: Icon) => {
  const classes = useStyles();
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={classes.icon}
    >
      <path
        d="M8 13L7.99854 13C7.29335 13.001 6.59108 12.9095 5.9097 12.7278L5.54024 12.6292L5.19929 12.8023C4.81331 12.9983 3.96192 13.3719 2.56687 13.6945C2.75524 13.0519 2.90199 12.3599 2.96547 11.695L3.01018 11.2268L2.67797 10.8938C1.61732 9.83052 1 8.46733 1 7C1 3.80756 4.00465 1 8 1C11.9953 1 15 3.80756 15 7C15 10.1924 11.9953 13 8 13Z"
        stroke="#4F4F4F"
        strokeWidth="2"
      />
    </svg>
  );
};

export const CloseChatIcon = ({ onClick }: Icon) => {
  const classes = useStyles();
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={classes.icon}
    >
      <path
        d="M8.50342 13.5L8.50269 13.5C7.75375 13.5011 7.00791 13.4039 6.28427 13.2109L6.09954 13.1616L5.92906 13.2482C5.42792 13.5026 4.26878 14.0006 2.32239 14.3601C2.62617 13.5482 2.8835 12.5657 2.97115 11.6475L2.99351 11.4134L2.8274 11.2469C1.68358 10.1003 1.00342 8.61366 1.00342 7C1.00342 3.47078 4.29675 0.5 8.50342 0.5C12.7101 0.5 16.0034 3.47078 16.0034 7C16.0034 10.5292 12.7101 13.5 8.50342 13.5Z"
        stroke="black"
      />
      <path
        d="M11.4423 8.07C11.2357 8.07 11.0623 8 10.9223 7.86C10.7823 7.72 10.7123 7.54667 10.7123 7.34C10.7123 7.13333 10.7823 6.96 10.9223 6.82C11.0623 6.68 11.2357 6.61 11.4423 6.61C11.6423 6.61 11.8123 6.68 11.9523 6.82C12.0923 6.96 12.1623 7.13333 12.1623 7.34C12.1623 7.54667 12.0923 7.72 11.9523 7.86C11.8123 8 11.6423 8.07 11.4423 8.07ZM8.50443 8.07C8.29777 8.07 8.12443 8 7.98443 7.86C7.84443 7.72 7.77443 7.54667 7.77443 7.34C7.77443 7.13333 7.84443 6.96 7.98443 6.82C8.12443 6.68 8.29777 6.61 8.50443 6.61C8.70443 6.61 8.87443 6.68 9.01443 6.82C9.15443 6.96 9.22443 7.13333 9.22443 7.34C9.22443 7.54667 9.15443 7.72 9.01443 7.86C8.87443 8 8.70443 8.07 8.50443 8.07ZM5.56654 8.07C5.35988 8.07 5.18654 8 5.04654 7.86C4.90654 7.72 4.83654 7.54667 4.83654 7.34C4.83654 7.13333 4.90654 6.96 5.04654 6.82C5.18654 6.68 5.35988 6.61 5.56654 6.61C5.76654 6.61 5.93654 6.68 6.07654 6.82C6.21654 6.96 6.28654 7.13333 6.28654 7.34C6.28654 7.54667 6.21654 7.72 6.07654 7.86C5.93654 8 5.76654 8.07 5.56654 8.07Z"
        fill="black"
      />
    </svg>
  );
};
