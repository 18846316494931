import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../reducers";
import { UnansweredServiceRequestI } from "../../layout/types";

const ENTITY = "notifications";

export interface NotificationState {
  notifications: Array<UnansweredServiceRequestI & { isNew?: boolean }>;
}

const initialState: NotificationState = {
  notifications: [],
};

const slice = createSlice({
  name: ENTITY,
  initialState,
  reducers: {
    setNotifications: (
      state,
      action: PayloadAction<UnansweredServiceRequestI & { isNew?: boolean }>
    ) => {
      const isExisting = state.notifications.find((el) => {
        return (
          el.serviceCaseDetails.caseNumber.toString() ===
          action.payload.serviceCaseDetails.caseNumber.toString()
        );
      });
      if (isExisting) {
        // update notification
        state.notifications = state.notifications.map((el) => {
          if (
            el.serviceCaseDetails.caseNumber ===
            action.payload.serviceCaseDetails.caseNumber
          ) {
            return { ...el, ...action.payload, isNew: el.isNew };
          }
          return el;
        });
      } else {
        // add to list
        state.notifications = [action.payload, ...state.notifications];
      }
    },
    updateNotifications: (
      state,
      action: PayloadAction<
        Array<UnansweredServiceRequestI & { isNew?: boolean }>
      >
    ) => {
      state.notifications = action.payload;
    },
    setNotificationToRead: (
      state,
      action: PayloadAction<{ serviceCaseDetailsId: string }>
    ) => {
      const copyNotifications = [...state.notifications];
      state.notifications = copyNotifications.map((el) => {
        if (el.serviceCaseDetails.id === action.payload.serviceCaseDetailsId) {
          return { ...el, isNew: false };
        }
        return el;
      });
    },
  },
});

export const notificationActions = {
  ...slice.actions,
};

export const notificationSelector = {
  notifications: (state: RootState) => state.notifications.notifications,
};

export default slice.reducer;
