import React, { FC } from "react";
import clsx from "clsx";
import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { useDialogStyles } from "component/Dialogs/styles";
import { CloseOutlined } from "@material-ui/icons";
import { createTheme } from "@material-ui/core/styles";
import theme from "theme";

const useStyle = makeStyles<any, { maxWidth?: number }>(() => ({
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      width: "100%",
      maxWidth: ({ maxWidth }) => maxWidth ?? 621,
      borderRadius: "4px 4px 16px 16px",
    },
  },
}));

const btnTheme = () => {
  return createTheme({
    palette: {
      ...theme.palette,
      secondary: {
        main: "#CA2027",
        contrastText: "#fff",
      },
    },
  });
};

export const ConfirmationDialog: FC<{
  maxWidth?: number;
  title?: string;
  open: boolean;
  onClose?: () => void;
  leftBtnProps?: ButtonProps;
  rightBtnProps?: ButtonProps;
  onConfirm?: () => void;
  onConfirmText?: string;
  onCancelText?: string;
  onCancel?: () => void;
  disableBackdropClick?: boolean;
  hideBox?: boolean;
}> = ({
  onCancel,
  rightBtnProps,
  leftBtnProps,
  onConfirm,
  open,
  title,
  onClose,
  children,
  onConfirmText,
  onCancelText,
  maxWidth,
  hideBox = false,
}) => {
  const baseClasses = useDialogStyles();
  const classes = useStyle({ maxWidth });

  return (
    <Dialog
      open={open}
      className={clsx(classes.dialog, baseClasses.dialog)}
      onClose={onClose}
    >
      <div className={baseClasses.dialogTitle}>
        <Typography color="primary" style={{ fontSize: 18 }}>
          {title || "Confirm Completion"}
        </Typography>
        <IconButton size="small" onClick={onClose}>
          <CloseOutlined style={{ fontSize: 20, color: "#BDBDBD" }} />
        </IconButton>
      </div>
      <DialogContent style={{ padding: 0 }}>
        {hideBox ? (
          children
        ) : (
          <Box
            px="16px"
            paddingTop="24px"
            paddingBottom="16px"
            position="relative"
          >
            {children}
          </Box>
        )}
      </DialogContent>
      <DialogActions style={{ padding: "0px 24px 24px" }}>
        <ThemeProvider theme={btnTheme}>
          <Button
            onClick={onCancel || onClose}
            variant="outlined"
            color="primary"
            type="submit"
            {...leftBtnProps}
          >
            {onCancelText || "Cancel"}
          </Button>
          <Button
            onClick={onConfirm}
            variant="contained"
            color="primary"
            type="submit"
            {...rightBtnProps}
          >
            {onConfirmText || "Confirm"}
          </Button>
        </ThemeProvider>
      </DialogActions>
    </Dialog>
  );
};
