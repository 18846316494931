import { Chat, ChatAttachment } from "@deep-consulting-solutions/be2-constants";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useCall } from "call/hooks";
import Loader from "component/Loader";
import { format } from "date-fns";
import React, {
  useMemo,
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";
import { getAttachmentSrc } from "./helpers";

const useStyles = makeStyles(({ spacing: s }) => ({
  wrapper: {
    display: "flex",
    flexFlow: "column nowrap",
    height: "100%",
    flex: 1,
    overflow: "hidden",
  },
  title: {
    fontWight: 500,
  },
  group: {
    boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.08)",
    padding: s(2, 0),
    background: "white",
  },
  caption: {
    fontSize: 8,
    color: "#828282",
    textAlign: "right",
  },
  recordingsWrapper: {
    width: "100%",
    height: "100%",
    overflow: "hidden auto",
  },
}));

const CallRecording = ({
  time,
  attachment,
}: Omit<Chat, "attachments"> & {
  attachment: ChatAttachment & {
    localUrl?: string;
    base64?: string | ArrayBuffer | null;
    url?: string;
  };
}) => {
  const classes = useStyles();
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const { viewedCall } = useCall();

  const [attachmentFile, setAttachmentFile] = useState<string | undefined>("");
  const [loading, setLoading] = useState(false);

  const getAttachmentFile = useCallback(async () => {
    try {
      setLoading(true);
      if (!attachment) {
        return undefined;
      }
      const url = await getAttachmentSrc({
        ...attachment,
        serviceCaseId: viewedCall?.updatedServiceCase?.id,
      });
      setAttachmentFile(url);
    } catch {
      //
    } finally {
      setLoading(false);
    }
  }, [attachment, viewedCall?.updatedServiceCase?.id]);

  useEffect(() => {
    getAttachmentFile();
  }, [getAttachmentFile]);

  const audioLength = useMemo(() => {
    const durationInSeconds = audioRef.current?.duration;

    if (!durationInSeconds) {
      return "";
    }

    const durationInMinutes = Math.floor(durationInSeconds / 60);

    return `${durationInMinutes}:${durationInMinutes - durationInSeconds}`;
  }, [audioRef]);

  return (
    <div className={classes.group}>
      <Loader open={loading} absolute />
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="body2" className={classes.title} color="primary">
            Voice Note
          </Typography>
          <Typography className={classes.caption} variant="caption">
            {format(new Date(time), "dd MMM yyyy")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.caption} variant="caption">
            {audioLength}
          </Typography>
        </Grid>
      </Grid>
      <audio ref={audioRef}>
        <track kind="captions" />
        <source type="audio/mp3" src={attachmentFile} />
      </audio>
    </div>
  );
};

export const CallRecordings = () => {
  const { chatRecordings } = useCall();
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.recordingsWrapper}>
        {chatRecordings.map((chatRecording) => (
          <React.Fragment key={chatRecording.id}>
            {chatRecording.attachments?.map((attachment) => (
              <CallRecording
                key={chatRecording.id}
                {...chatRecording}
                attachment={attachment}
              />
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
