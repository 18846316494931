import { Grid, makeStyles, Paper, Typography, Box } from "@material-ui/core";
import {
  ServiceTypesEnum,
  ServiceCaseResponse,
  PatientResponseWithContact,
} from "@deep-consulting-solutions/be2-constants";
import React, { useCallback } from "react";

import { CollapsedCard } from "component/CollapsedCard";
import { calculateAgeFromDOB, formatNameToDisplay } from "helpers";
import ViewIdentificationDocuments from "component/ViewIdentificationDocuments";
import { PatientCoverage } from "call/components/CallForms/ServiceForm/components";

import { displayTime, convertYesNoBoolean } from "./helpers";

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  wrapper: {
    padding: s(2),
    "&:not(:first-child)": {
      marginTop: s(2),
    },
  },
  header: {},
  title: {
    color: "#467ee5",
    fontSize: 20,
    fontWeight: 500,
  },
  expandBtn: {
    borderRadius: s(0.5),
    background: "#f6f6f6",
  },
  expandIcon: {
    fontSize: 25,
  },
  section: {
    marginTop: s(1),
    padding: s(1),
    border: `1px solid ${p.grey[300]}`,
    borderRadius: s(0.5),
  },
  line: {
    display: "flex",
  },
  lineKey: {
    color: p.grey[700],
    fontSize: 12,
    marginRight: s(1),
    maxWidth: "150px",
  },
  lineValue: {
    flex: 1,
    fontSize: 12,
    fontWeight: 600,
    color: p.grey[700],
  },
  link: {
    fontSize: 12,
    color: "#467ee5",
    textDecoration: "underline",
  },
  nok: {
    marginTop: s(1),
  },
  nokContent: {
    marginTop: s(1),
  },
  nokTitle: {
    color: "#263E7F",
    fontSize: 14,
  },
  coverage: {
    fontSize: 12,
    color: "#263E7F",
  },
  coverageStatus: {
    fontSize: 14,
    fontWeight: 600,
  },
}));

interface Props {
  patient: PatientResponseWithContact;
  handleOpenPaymentDialog: (values: {
    subscriptionForPayment: string;
    subscriptionContact: string;
  }) => void;
  patientValue?: { patientID: string; coverageID?: string };
  handlePatientCoverageChange: (patientID: string, coverageId: string) => void;
  service: ServiceTypesEnum;
  serviceCase: ServiceCaseResponse | null;
  disableForm: boolean;
}

export const Patient: React.FC<Props> = ({
  patient,
  patientValue,
  handleOpenPaymentDialog,
  handlePatientCoverageChange,
  service,
  serviceCase,
  disableForm,
}) => {
  const handleCoverageChange = useCallback(
    (coverageId: string) => {
      handlePatientCoverageChange(patient.patientID, coverageId);
    },
    [handlePatientCoverageChange, patient.patientID]
  );

  const classes = useStyles();

  const renderLine = (key: string, value: any, isSmall?: boolean) => {
    return (
      <Grid item xs={isSmall ? 4 : 6} className={classes.line}>
        <Typography className={classes.lineKey}>{key}:</Typography>
        <Typography className={classes.lineValue}>{value || "--"}</Typography>
      </Grid>
    );
  };

  return (
    <Paper className={classes.wrapper}>
      <div className={classes.header}>
        <Typography className={classes.title}>
          {patient.firstName} {patient.lastName}
        </Typography>
        <Typography className={classes.coverage}>
          Coverage:{" "}
          <span className={classes.coverageStatus}>
            {patient.coverageDetails?.coverageStatus || ""}
          </span>
        </Typography>
      </div>

      {patient.isMemberOrCustomer && serviceCase?.serviceTypeUpdatedByMD ? (
        <Box mt={2}>
          <PatientCoverage
            patient={patient}
            handleOpenPaymentDialog={handleOpenPaymentDialog}
            selectedCoverage={patientValue?.coverageID || ""}
            handleCoverageChange={handleCoverageChange}
            service={service}
            disableForm={disableForm}
          />
        </Box>
      ) : null}

      <div className={classes.section}>
        <Grid container>
          {renderLine(
            "Age",
            patient.dob ? calculateAgeFromDOB(patient.dob) : "-"
          )}
          {renderLine("Blood Type", patient.contact?.bloodType)}
          {renderLine("Primary Physician", patient.primaryPhysician)}
          {renderLine("Phone", patient.phone)}
          {renderLine("Preferred Hospital", patient.preferredHospital)}
          <Grid item xs={6} className={classes.line}>
            <ViewIdentificationDocuments
              patient={patient}
              textClassName={classes.link}
            />
          </Grid>
          {renderLine("Medical Status", patient.medicalStatus)}
        </Grid>
      </div>

      <div className={classes.section}>
        <Grid container>
          {renderLine("Level of Emergency", patient.levelOfEmergency)}
          {renderLine(
            "Preferred Date and Time of Arrival",
            patient.preferredDestinationArrivalTime
              ? displayTime(patient.preferredDestinationArrivalTime)
              : "-"
          )}
          {renderLine("Medical Needs", patient.medicalNeeds)}
          {renderLine(
            "Patient Can Fly Commercially",
            patient.canFlyCommercially
          )}
          {renderLine(
            "Requires Medical Escort on the Commercial Flight",
            convertYesNoBoolean(patient.comercialFlightMedicalEscort)
          )}
        </Grid>
      </div>

      <div className={classes.nok}>
        <CollapsedCard
          title={
            <Typography className={classes.nokTitle}>
              Next of Kin:{" "}
              <b>
                {formatNameToDisplay(
                  patient.nextOfKin?.firstName,
                  patient.nextOfKin?.lastName
                )}
              </b>
            </Typography>
          }
        >
          <Grid container className={classes.nokContent}>
            {renderLine(
              "Relation to Patient",
              patient.nextOfKin?.relationToPatient,
              true
            )}
            {renderLine("Email", patient.nextOfKin?.email, true)}
            {renderLine("Phone", patient.nextOfKin?.phone, true)}
          </Grid>
        </CollapsedCard>
      </div>
    </Paper>
  );
};
