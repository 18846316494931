import React from "react";
import { useStyles } from "./style";
import { Icon } from "./types";

export const VolumeOff = ({ onClick }: Icon) => {
  const classes = useStyles();
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={classes.icon}
    >
      <path
        d="M9.67426 0.469417C9.81761 0.538473 9.93858 0.646551 10.0233 0.781245C10.108 0.915938 10.153 1.07179 10.1531 1.2309V14.7684C10.1529 14.9276 10.1078 15.0836 10.0229 15.2183C9.93804 15.353 9.81688 15.461 9.67337 15.5299C9.52985 15.5988 9.3698 15.6259 9.21161 15.6079C9.05342 15.59 8.9035 15.5277 8.77909 15.4284L4.78044 12.2301H0.846096C0.621697 12.2301 0.406489 12.141 0.247816 11.9823C0.0891419 11.8237 0 11.6085 0 11.3841V4.61529C0 4.39089 0.0891419 4.17568 0.247816 4.01701C0.406489 3.85833 0.621697 3.76919 0.846096 3.76919H4.78044L8.77909 0.570949C8.90359 0.471427 9.05367 0.409096 9.21204 0.391133C9.37041 0.37317 9.53063 0.400307 9.67426 0.469417ZM21.7514 4.01625C21.8302 4.09485 21.8927 4.18821 21.9354 4.29101C21.978 4.3938 22 4.504 22 4.61529C22 4.72658 21.978 4.83678 21.9354 4.93957C21.8927 5.04236 21.8302 5.13573 21.7514 5.21432L18.9644 7.99967L21.7514 10.785C21.9103 10.9439 21.9996 11.1594 21.9996 11.3841C21.9996 11.6087 21.9103 11.8242 21.7514 11.9831C21.5926 12.142 21.3771 12.2312 21.1524 12.2312C20.9277 12.2312 20.7122 12.142 20.5534 11.9831L17.768 9.19605L14.9827 11.9831C14.8238 12.142 14.6083 12.2312 14.3836 12.2312C14.1589 12.2312 13.9435 12.142 13.7846 11.9831C13.6257 11.8242 13.5365 11.6087 13.5365 11.3841C13.5365 11.1594 13.6257 10.9439 13.7846 10.785L16.5716 7.99967L13.7846 5.21432C13.7059 5.13566 13.6435 5.04227 13.6009 4.93948C13.5584 4.8367 13.5365 4.72654 13.5365 4.61529C13.5365 4.50404 13.5584 4.39387 13.6009 4.29109C13.6435 4.18831 13.7059 4.09492 13.7846 4.01625C13.9435 3.85738 14.1589 3.76812 14.3836 3.76812C14.4949 3.76812 14.605 3.79003 14.7078 3.83261C14.8106 3.87518 14.904 3.93758 14.9827 4.01625L17.768 6.80329L20.5534 4.01625C20.632 3.93746 20.7253 3.87494 20.8281 3.83229C20.9309 3.78963 21.0411 3.76768 21.1524 3.76768C21.2637 3.76768 21.3739 3.78963 21.4767 3.83229C21.5795 3.87494 21.6728 3.93746 21.7514 4.01625Z"
        fill="#F71D3E"
      />
    </svg>
  );
};
