import { useMemo } from "react";

import { useAppSelector } from "redux/store";
import { zohoSelectors } from "redux/zoho";

import { isZohoBusinessOwner, isZohoBEAdminStaff } from "helpers";

export const useZohoCurrentUser = () => {
  const currentUser = useAppSelector(zohoSelectors.getCurrentUser);
  const isBusinessOwner = useMemo(
    () => isZohoBusinessOwner(currentUser),
    [currentUser]
  );
  const isBEAdminStaff = useMemo(
    () => isZohoBEAdminStaff(currentUser),
    [currentUser]
  );
  return {
    currentUser,
    isBusinessOwner,
    isBEAdminStaff,
  };
};
