import React, { useCallback, useState, useMemo, useEffect } from "react";
import {
  CreditCard,
  PaymentMethodEnum,
  SubscriptionPaymentBreakdown,
} from "@deep-consulting-solutions/be2-constants";
import {
  Box,
  Grid,
  Button,
  makeStyles,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { StyledTypography } from "component/StyledTypography";
import { useResponsive } from "hooks";
import { PaymentCardsStepEnum } from "./types";
import { CreditCardSnippet } from "./CreditCardSnippet";

const useStyle = makeStyles(({ spacing: s }) => ({
  selectedCard: {
    margin: s(2),
  },
}));

interface Props {
  cards: CreditCard[];
  gotoNewCard: () => void;
  subscriptionPaymentBreakdown: SubscriptionPaymentBreakdown | null;
  initialStep?: PaymentCardsStepEnum;
  payWithExistingCard?: (
    creditCardId: string,
    useCardForRecurringPayments: boolean
  ) => Promise<Record<string, unknown> | undefined>;
}

export const PaymentCards = ({
  cards,
  gotoNewCard,
  subscriptionPaymentBreakdown,
  initialStep,
  payWithExistingCard,
}: Props) => {
  const [step, setStep] = useState(PaymentCardsStepEnum.LIST_CARDS);
  const [selected, setSelected] = useState<CreditCard | undefined>(undefined);
  const [recurringValue, setRecurringValue] = React.useState(true);
  const classes = useStyle();
  const { isDesktop } = useResponsive();

  const isDirectPayment = useMemo(
    () =>
      subscriptionPaymentBreakdown?.paymentMethod ===
      PaymentMethodEnum.DIRECT_PAYMENT,
    [subscriptionPaymentBreakdown]
  );

  const handleRecurringValue = (value: boolean) => {
    setRecurringValue(value);
  };

  const handleUseSelectedCard = useCallback(() => {
    if (selected) {
      setStep(PaymentCardsStepEnum.SELECTED_CARD);
    }
  }, [selected]);

  const handleCancelUseSelectedCard = useCallback(() => {
    setStep(PaymentCardsStepEnum.LIST_CARDS);
  }, []);

  const makePaymentWithExistingCard = useCallback(async () => {
    if (!selected) {
      return;
    }
    if (payWithExistingCard) {
      const getIsRecurring = () => {
        if (!isDirectPayment) {
          return false;
        }

        return !selected.handlesRecurringPayments ? recurringValue : true;
      };
      await payWithExistingCard(selected.id, getIsRecurring());
    }
  }, [payWithExistingCard, selected, isDirectPayment, recurringValue]);

  const handleMakePaymentWithExistingCard = useCallback(() => {
    makePaymentWithExistingCard();
  }, [makePaymentWithExistingCard]);

  useEffect(() => {
    if (!cards.length) {
      gotoNewCard();
    } else {
      setSelected(cards[0]);
    }
  }, [cards, gotoNewCard]);

  useEffect(() => {
    if (initialStep) {
      setStep(initialStep);
    }
  }, [initialStep]);

  const amount = useMemo(
    () => subscriptionPaymentBreakdown?.grandTotal || 0,
    [subscriptionPaymentBreakdown]
  );

  if (step === PaymentCardsStepEnum.LIST_CARDS || !selected) {
    return (
      <>
        <StyledTypography align="left" variant="h4" color="primary.main">
          Your Credit Cards
        </StyledTypography>
        <Box mt={2} className={classes.selectedCard}>
          {cards.length > 0 && (
            <>
              {cards
                .map((c) => ({
                  ...c,
                  handlesRecurringPayments: isDirectPayment
                    ? c.handlesRecurringPayments
                    : false,
                }))
                .map((card) => (
                  <CreditCardSnippet
                    disabled={cards.length === 1}
                    key={card.id}
                    {...card}
                    brand={card.brand as any}
                    handleClick={(id: string) => {
                      setSelected(cards.find((s) => s.id === id));
                    }}
                    isSelected={selected?.id === card.id}
                  />
                ))}
              <Box mt={2}>
                <Grid
                  container
                  justify={isDesktop ? "flex-end" : "space-between"}
                  spacing={1}
                  wrap="wrap"
                >
                  <Grid item>
                    <Button
                      color="primary"
                      onClick={gotoNewCard}
                      variant="outlined"
                    >
                      USE A NEW CARD
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      disabled={!selected}
                      onClick={handleUseSelectedCard}
                    >
                      USE SELECTED CARD
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Box>
      </>
    );
  }
  return (
    <>
      <StyledTypography align="left" variant="h4" color="primary.main">
        Confirm Payment
      </StyledTypography>

      <Box mt={2} className={classes.selectedCard}>
        <StyledTypography align="left" variant="body2" color="primary.main">
          Are you sure you want to make this payment with this card?
        </StyledTypography>

        <Box mt={2}>
          <CreditCardSnippet
            disabled
            key={selected.id}
            {...selected}
            brand={selected.brand as any}
            isSelected
            handleClick={(id: string) => {
              setSelected(cards.find((s) => s.id === id));
            }}
          />
        </Box>

        {isDirectPayment && !selected.handlesRecurringPayments && (
          <Box mt={3}>
            <Grid
              container
              wrap="nowrap"
              justify="space-between"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item>
                <Box>
                  <StyledTypography
                    color="primary.main"
                    variant="body2"
                    align="left"
                  >
                    Would you like to use this card for future recurring
                    payment?
                  </StyledTypography>
                </Box>
                <Box mt={1}>
                  <StyledTypography
                    color="grey.900"
                    variant="caption"
                    align="left"
                  >
                    This will allow BahamasEvac to charge your card for this
                    payment and future payment in accordance with their terms.
                  </StyledTypography>
                </Box>
              </Grid>
              <Grid item>
                <Box>
                  <FormControlLabel
                    control={
                      <Switch
                        defaultChecked
                        color="primary"
                        onChange={(e) => handleRecurringValue(e.target.checked)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Yes"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        <Box mt={2}>
          <Grid
            container
            justify={isDesktop ? "flex-end" : "flex-start"}
            spacing={1}
          >
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                onClick={handleCancelUseSelectedCard}
              >
                CANCEL
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                disabled={!selected}
                onClick={handleMakePaymentWithExistingCard}
              >
                PAY BSD{amount}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
