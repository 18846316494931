import React, { useCallback, useState } from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import { ProvideInformationSupportDialog } from "call/components/ProvideInformationSupportDialog";
import { useCall } from "call/hooks";

const useStyles = makeStyles(({ spacing: s }) => ({
  wrapper: {
    display: "flex",
    alignItems: "stretch",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    marginBottom: s(4),
  },
  textIcon: {
    background: "rgba(254, 236, 235, 1)",
    borderRadius: 4,
    marginRight: s(2),
    flex: 1,
    display: "flex",
    alignItems: "center",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    padding: s(0, 2),
  },
  icon: {
    color: "#CA2027",
    marginRight: s(2),
  },
  text: { fontWeight: 700 },
}));

export const ServiceCaseRefused = () => {
  const { viewedCall } = useCall();

  const [
    showProvideInformationSupportDialog,
    setShowProvideInformationSupportDialog,
  ] = useState(false);

  const handleProvideInformationSupportRequest = useCallback(() => {
    setShowProvideInformationSupportDialog(true);
  }, []);

  const handleCloseApprovalRequestDialog = useCallback(() => {
    setShowProvideInformationSupportDialog(false);
  }, []);
  const classes = useStyles();

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.textIcon}>
          <div>
            <InfoOutlined className={classes.icon} fontSize="small" />
          </div>
          <div>
            <Typography
              align="left"
              className={classes.text}
              variant="subtitle2"
            >
              Service Case Refused
            </Typography>
          </div>
        </div>
        <Button
          color="primary"
          variant="outlined"
          onClick={handleProvideInformationSupportRequest}
          disabled={!!viewedCall?.disableForms}
        >
          PROVIDE INFORMATIONAL SUPPORT
        </Button>
      </div>

      <ProvideInformationSupportDialog
        open={showProvideInformationSupportDialog}
        handleClose={handleCloseApprovalRequestDialog}
      />
    </>
  );
};
