import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";

const useStyles = makeStyles(({ spacing: s }) => ({
  wrapper: {
    display: "flex",
    alignItems: "stretch",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    marginBottom: s(4),
    background: "rgba(237, 247, 237, 1)",
    borderRadius: 4,
    flex: 1,
    padding: s(2),
  },
  icon: {
    color: "#08A40F",
    marginRight: s(2),
  },
  text: { fontWeight: 700 },
}));

export const ServiceCaseAccepted = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div>
        <CheckCircleOutline className={classes.icon} fontSize="small" />
      </div>
      <div>
        <Typography align="left" className={classes.text} variant="subtitle2">
          Service Case Accepted
        </Typography>
      </div>
    </div>
  );
};
