import React, { useCallback, useEffect, useState } from "react";
import { Box, Card, makeStyles, Theme, Typography } from "@material-ui/core";
import {
  SocketEventsEnum,
  ServiceCaseResponse,
  ServiceCaseStatusEnum,
  ServiceCaseResponseExtended,
} from "@deep-consulting-solutions/be2-constants";
import clsx from "clsx";
import Loader from "component/Loader/Loader";
import { useElementWithin } from "hooks/useElementOnScreen";
import { DisplayModeLayout } from "layout/displayMode.layout";
import { DisplayLayout } from "redux/dispatcher";
import { useAppSelector } from "redux/store";
import { getInProgressStatus } from "pages/FulfilmentDashboard/Views/Default/request";
import { PatientStatusSnippet } from "pages/FulfilmentDashboard/components/PatientStatusSnippet";
import { useSocket } from "hooks";
import { getServiceCase } from "redux/serviceCase/requests";
import { shouldUpdateServiceCase } from "pages/FulfilmentDashboard/helpers";
import { GettingMoreLoader, RenderCaseStatus } from "../../components";
import { RenderMap } from "../MedicalDirector/RenderMap";
import { getUnanswered } from "./request";
import { updateStateForServiceCase } from "./helpers";

const FILTER_PER_PAGE = 20;

const useStyles = makeStyles<Theme, { layout?: DisplayLayout | null }>(
  ({ palette: p }) => ({
    container: {
      display: "grid",
      gridTemplateColumns: "auto auto 1fr",
      gap: 16,
      gridTemplateRows: ({ layout }) =>
        layout === DisplayLayout?.horizontal
          ? "auto auto minmax(auto, 500px)"
          : undefined,
    },
    card: {
      position: "relative",
      padding: 11,
      height: "min-content",
      maxWidth: ({ layout }) =>
        layout === DisplayLayout?.vertical ? 500 : "unset",
      minWidth: 432,
      width: "100%",
    },
    scrollable: {
      overflow: "auto",
      paddingLeft: 5,
      paddingRight: 5,
      maxHeight: "75vh",
    },
    isHorizontal: {
      display: "flex",
      gap: 16,
    },
    inputContainer: {
      position: "relative",
      display: "grid",
      width: "100%",
      gap: 10,
    },
    inputSplit: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: 10,
    },
    spaceBetween: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    tabBtn: {
      border: "none",
      background: "none",
      padding: "16px 8px",
      borderBottom: "1px solid #e0e0e0",
      textTransform: "uppercase",
      color: "#666666",
    },
    tabActive: {
      borderBottom: `2px solid ${p.primary.main}`,
      color: p.primary.main,
    },
  })
);

interface SocketEvent {
  serviceCaseId: string;
  modifiedFields: Array<keyof ServiceCaseResponse>;
}

export interface PriorityType {
  page: number;
  total: number;
  perPage: number;
  data: ServiceCaseResponseExtended[];
}

const Priority = () => {
  const { serviceCaseUpdated, displayLayout } = useAppSelector(
    (state) => state.dispatcher
  );

  const [ansLoading, setAnsLoading] = useState(false);
  const [prLoading, setPrLoading] = useState(false);

  const [ansState, setAnsState] = useState({
    total: 0,
    page: 1,
    perPage: FILTER_PER_PAGE,
    data: [] as ServiceCaseResponseExtended[],
  });

  const [prState, setPrState] = useState({
    total: 0,
    page: 1,
    perPage: FILTER_PER_PAGE,
    data: [] as ServiceCaseResponseExtended[],
  });

  const handleOnMoreSelection = (selected: string) => {
    if (selected === "edit") {
      // do edit action
    }
  };

  const handleUpdate = (id: string) => (data: ServiceCaseResponse) => {
    const cpyEmState = ansState.data.map((el) => {
      if (el.id === id) return { ...el, ...data };
      return el;
    });
    const cpyStState = prState.data.map((el) => {
      if (el.id === id) return { ...el, ...data };
      return el;
    });

    setAnsState({
      ...ansState,
      data: cpyEmState,
    });
    setPrState({
      ...prState,
      data: cpyStState,
    });
  };

  const [gettingMorePr, setGettingMorePr] = useState(false);
  const [gettingMore, setGettingMore] = useState(false);

  const emMoreReq = useCallback(() => {
    if (ansState.total > ansState.data.length) {
      const newPage = `${ansState.page + 1}`;
      setGettingMore(true);
      (async () => {
        try {
          const newRes = await getUnanswered({
            page: newPage,
          });
          setAnsState({
            ...ansState,
            page: +newPage,
            total: newRes.data.data.total,
            data: ansState.data.concat(newRes.data.data.serviceCases),
          });
          setGettingMore(false);
        } catch (e) {
          setGettingMore(false);
        }
      })();
    }
  }, [ansState]);

  const sTMoreReq = useCallback(() => {
    if (prState.total > prState.data.length) {
      const newPage = `${prState.page + 1}`;
      setGettingMorePr(true);
      (async () => {
        try {
          const newRes = await getInProgressStatus({
            page: newPage,
          });
          setPrState({
            ...prState,
            page: +newPage,
            total: newRes.data.data.total,
            data: prState.data.concat(newRes.data.data.serviceCases),
          });
          setGettingMorePr(false);
        } catch (e) {
          setGettingMorePr(false);
        }
      })();
    }
  }, [prState]);

  const { target: emergencyRef } = useElementWithin(emMoreReq, [ansState]);
  const { target: scheduledRef } = useElementWithin(sTMoreReq, [prState]);

  const classes = useStyles({ layout: displayLayout });

  useEffect(() => {
    (async () => {
      setAnsLoading(true);
      setPrLoading(true);
      try {
        const ansRes = await getUnanswered();
        setAnsLoading(false);
        setAnsState({
          data: ansRes.data.data.serviceCases,
          perPage: ansRes.data.data?.perPage || 10,
          total: ansRes.data.data.total,
          page: 1,
        });
        const prRes = await getInProgressStatus();
        setPrLoading(false);
        setPrState({
          data: prRes.data.data.serviceCases,
          perPage: prRes.data.data?.perPage || 10,
          total: prRes.data.data.total,
          page: 1,
        });
      } catch (e) {
        setAnsLoading(false);
        setPrLoading(false);
      }
    })();
  }, [serviceCaseUpdated]);

  const { addHandler } = useSocket("Priority");

  const handleNewServiceCase = useCallback(({ serviceCaseId }: SocketEvent) => {
    (async () => {
      const serviceCase = {
        ...(await getServiceCase(serviceCaseId)),
        quotes: [],
        isNew: true,
      };

      if (serviceCase.caseStatus === ServiceCaseStatusEnum.UNANSWERED) {
        setAnsState((state) => ({
          ...state,
          total: state.total + 1,
          data: [serviceCase as ServiceCaseResponseExtended].concat(state.data),
        }));
      } else if (
        [
          ServiceCaseStatusEnum.SERVICE_STARTED,
          ServiceCaseStatusEnum.PATIENT_RELEASED,
        ].includes(serviceCase.caseStatus)
      ) {
        setPrState((state) => ({
          ...state,
          total: state.total + 1,
          data: [serviceCase as ServiceCaseResponseExtended].concat(state.data),
        }));
      }
    })();
  }, []);

  const handleUpdatedServiceCase = useCallback(
    ({ serviceCaseId, modifiedFields }: SocketEvent) => {
      (async () => {
        const shouldUpdate = shouldUpdateServiceCase(modifiedFields);

        if (!shouldUpdate) return;

        const serviceCase = await getServiceCase(serviceCaseId);

        setAnsState((state) =>
          updateStateForServiceCase(
            state,
            serviceCase,
            serviceCase.caseStatus === ServiceCaseStatusEnum.UNANSWERED,
            modifiedFields
          )
        );

        setPrState((state) =>
          updateStateForServiceCase(
            state,
            serviceCase,
            [
              ServiceCaseStatusEnum.SERVICE_STARTED,
              ServiceCaseStatusEnum.PATIENT_RELEASED,
            ].includes(serviceCase.caseStatus),
            modifiedFields
          )
        );
      })();
    },
    []
  );

  useEffect(() => {
    addHandler(
      SocketEventsEnum.NEW_SERVICE_CASE_NOTIFICATION,
      handleNewServiceCase
    );

    addHandler(
      SocketEventsEnum.UPDATED_SERVICE_CASE_NOTIFICATION,
      handleUpdatedServiceCase
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DisplayModeLayout className={classes.container}>
        <Box minWidth={0}>
          <Card className={clsx(classes.card)}>
            <Loader absolute open={ansLoading} />
            <Typography
              color="primary"
              variant="body2"
              style={{ textTransform: "uppercase" }}
            >
              <b>unanswered ({ansState.total})</b>
            </Typography>
            {!!ansState.total && (
              <Box
                className={clsx(
                  classes.scrollable,
                  displayLayout === DisplayLayout.horizontal &&
                    classes.isHorizontal
                )}
                mt={4}
              >
                {ansState.data.map((el, idx) => (
                  <Box key={el.id}>
                    <PatientStatusSnippet
                      key={el.id}
                      onMore={handleOnMoreSelection}
                      onUpdate={handleUpdate(el.id)}
                      {...el}
                    >
                      <RenderCaseStatus
                        caseStatus={el.caseStatus}
                        overdue={el.overdue}
                      />
                    </PatientStatusSnippet>
                    <div
                      ref={
                        ansState.data.length - 2 === idx
                          ? emergencyRef
                          : undefined
                      }
                    />
                  </Box>
                ))}
                <GettingMoreLoader open={gettingMore} />
              </Box>
            )}
          </Card>
        </Box>
        <Box minWidth={0}>
          <Card className={clsx(classes.card)}>
            <Loader absolute open={prLoading} />
            <Typography
              color="primary"
              variant="body2"
              style={{ textTransform: "uppercase" }}
            >
              <b>in progress ({prState.total})</b>
            </Typography>
            {!!prState.total && (
              <Box
                className={clsx(
                  classes.scrollable,
                  displayLayout === DisplayLayout.horizontal &&
                    classes.isHorizontal
                )}
                mt={4}
              >
                {prState.data.map((el, idx) => (
                  <Box key={el.id}>
                    <PatientStatusSnippet
                      key={el.id}
                      onMore={handleOnMoreSelection}
                      onUpdate={handleUpdate(el.id)}
                      {...el}
                    >
                      <RenderCaseStatus
                        caseStatus={el.caseStatus}
                        overdue={el.overdue}
                      />
                    </PatientStatusSnippet>
                    <div
                      ref={
                        prState.data.length - 2 === idx
                          ? scheduledRef
                          : undefined
                      }
                    />
                  </Box>
                ))}
                <GettingMoreLoader open={gettingMorePr} />
              </Box>
            )}
          </Card>
        </Box>
        <Box
          height={displayLayout === DisplayLayout.vertical ? "80vh" : "100%"}
          minWidth={0}
        >
          <RenderMap />
        </Box>
      </DisplayModeLayout>
    </>
  );
};

export default Priority;
