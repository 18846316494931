import React, { ElementType } from "react";
import {
  makeStyles,
  Theme,
  Typography,
  TypographyProps,
} from "@material-ui/core";

const useStyles = makeStyles<Theme, { color: string }>(({ palette: p }) => ({
  root: {
    color: ({ color }) => {
      const keys = color.split(".");
      // @ts-ignore
      return p[keys[0]][keys[1]] || p.text.primary;
    },
  },
}));

export type StyledTypographyProps<C extends ElementType> = Omit<
  TypographyProps<C, { component?: C }>,
  "color"
> & {
  color: string;
};

/**
 *
 * @param color "primary.dark", "text.disabled"
 */
export const StyledTypography = <C extends ElementType>({
  color,
  ...typoProps
}: StyledTypographyProps<C>) => {
  const classes = useStyles({ color });
  return <Typography classes={{ root: classes.root }} {...typoProps} />;
};
