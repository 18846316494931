import { differenceInYears } from "date-fns";
import { getIn, Field, FormikProps } from "formik";
import React, { useState, useEffect, useCallback } from "react";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import { Box, Grid, makeStyles, MenuItem, Typography } from "@material-ui/core";
import {
  NextOfKinData,
  LocationTypeEnum,
  ServiceCaseResponse,
  MembersResponseSubObject,
  PatientLevelOfEmergencyEnum,
} from "@deep-consulting-solutions/be2-constants";

import Loader from "component/Loader";
import { formatNameToDisplay } from "helpers";
import { getContactSubMembers } from "redux/call/requests";
import { FormikDateTimePicker } from "call/components/FormikDateTimePicker";
import ViewIdentificationDocuments from "component/ViewIdentificationDocuments";

import ReceivingPhysician from "./ReceivingPhysician";
import { NextOfKinDisabledFields } from "../RequesterAndPatientForm/types";
import type { AssessmentFormFields, AssessmentPatient } from "./AssessmentForm";
import { ExistingNextOfKin } from "../RequesterAndPatientForm/ExistingNextOfKin";

interface MedicalInformationProps {
  index: number;
  disabled?: boolean;
  serviceCase: ServiceCaseResponse;
  formikProps: FormikProps<AssessmentFormFields>;
}

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  root: {
    padding: s(2),
    borderRadius: s(1),
    boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.12)",

    "&:not(:last-of-type)": {
      marginBottom: "24px",
    },

    "& > :not(:last-child)": {
      marginBottom: s(2),
    },
  },
  name: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "20px",
    marginBottom: s(2),
    color: p.primary.main,
  },
  details: {
    padding: s(1),
    borderRadius: s(0.5),
    border: "1px solid #e0e0e0",
  },
  detailItem: {
    display: "flex",

    "& :not(span)": {
      color: "#4f4f4f",
    },

    "&:not(:last-child)": {
      marginBottom: "12px",
    },

    "& :first-child": {
      marginRight: "8px",
    },

    "& :nth-child(2n)": {
      fontWeight: 700,
    },
  },
  checkboxes: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",

    "& > *": {
      maxHeight: s(2.25),
    },

    "& > :not(:last-child)": {
      marginBottom: s(1.25),
    },
  },
  checkboxLabel: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "14px",
    color: p.primary.main,
  },
}));

export const MedicalInformation = ({
  index,
  disabled,
  formikProps,
  serviceCase,
}: MedicalInformationProps) => {
  const classes = useStyles();
  const { values } = formikProps;

  const [loading, setLoading] = useState(false);
  const [memberNextOfKin, setMemberNextOfKin] = useState<
    Partial<NextOfKinData> | undefined
  >(undefined);
  const [memberSubscriptions, setMemberSubscriptions] = useState<
    MembersResponseSubObject[]
  >([]);
  const [disabledNextOfKinFields, setDisabledNextOfKinFields] = useState<
    Partial<NextOfKinDisabledFields>
  >({});

  const formKey = `patients[${index}]`;
  const formikPatient: AssessmentPatient = getIn(values, formKey);
  const serviceCasePatient = serviceCase.patients[index];

  const handleInitialValues = useCallback(async () => {
    if (formikPatient.contactID) {
      try {
        setLoading(true);

        const res = await getContactSubMembers(
          formikPatient.contactID,
          true,
          true
        );

        if (res.nextOfKin?.firstName) {
          setMemberNextOfKin(res.nextOfKin);
        }

        setDisabledNextOfKinFields({
          relationshipToPatient:
            !!formikPatient.nextOfKin?.relationshipToPatient,
          otherRelationToPatient:
            !!formikPatient.nextOfKin?.otherRelationToPatient,
          firstName: !!formikPatient.nextOfKin?.firstName,
          lastName: !!formikPatient.nextOfKin?.lastName,
          phone: !!formikPatient.nextOfKin?.phone,
          email: !!formikPatient.nextOfKin?.email,
        });

        setMemberSubscriptions(res.subscriptionMembers);
      } catch (error) {
        //
      } finally {
        setLoading(false);
      }
    }
  }, [formikPatient]);

  useEffect(() => {
    if (
      !formikPatient.isRequester ||
      (formikPatient.isRequester && formikPatient.nextOfKin?.firstName)
    ) {
      handleInitialValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.root}>
      <Loader open={loading} absolute />

      <Typography className={classes.name}>
        {formatNameToDisplay(
          serviceCasePatient.firstName,
          serviceCasePatient.lastName
        )}
      </Typography>

      <Box className={classes.details}>
        <Grid container>
          <Grid item xs={6}>
            <Box className={classes.detailItem}>
              <Typography>Age:</Typography>
              <Typography>
                {serviceCasePatient.age ||
                  differenceInYears(
                    Date.now(),
                    new Date(serviceCasePatient.dob || Date.now())
                  )}
              </Typography>
            </Box>

            <Box className={classes.detailItem}>
              <Typography>Primary Physician:</Typography>
              <Typography>{serviceCasePatient.primaryPhysician}</Typography>
            </Box>

            <Box className={classes.detailItem}>
              <Typography>Preferred Hospital:</Typography>
              <Typography>{serviceCasePatient.preferredHospital}</Typography>
            </Box>

            <Box className={classes.detailItem}>
              <Typography>Medical Status:</Typography>
              <Typography>{serviceCasePatient.medicalStatus}</Typography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box className={classes.detailItem}>
              <Typography>Blood Type:</Typography>
              <Typography>{serviceCasePatient.bloodType}</Typography>
            </Box>

            <Box className={classes.detailItem}>
              <Typography>Phone:</Typography>
              <Typography>{serviceCasePatient.phone}</Typography>
            </Box>

            <Box className={classes.detailItem}>
              <ViewIdentificationDocuments patient={serviceCasePatient} />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Grid container>
          <Grid item xs={6}>
            <Field
              select
              required
              size="small"
              disabled={disabled}
              component={TextField}
              label="Level of Emergency"
              name={`patients[${index}].levelOfEmergency`}
            >
              <MenuItem value={PatientLevelOfEmergencyEnum.LEVEL_1}>
                Level 1 - Most urgent
              </MenuItem>
              <MenuItem value={PatientLevelOfEmergencyEnum.LEVEL_2}>
                Level 2 - Less urgent (but still an emergency)
              </MenuItem>
              <MenuItem value={PatientLevelOfEmergencyEnum.LEVEL_3}>
                Level 3 - Least urgent (but still an emergency)
              </MenuItem>
              <MenuItem value={PatientLevelOfEmergencyEnum.BE_PRIORITY}>
                BahamasEvac Priority - not an emergency as such but Patient
                needs to get proper treatment within a reasonable time
              </MenuItem>
            </Field>
          </Grid>

          <Grid item xs={6}>
            <Field
              required
              size="small"
              ampm={false}
              disabled={disabled}
              label="DD/MM/YYYY 00:00"
              component={FormikDateTimePicker}
              typingFormat="dd/MM/yyyy HH:mm"
              displayFormat="dd/MM/yyyy HH:mm"
              name={`patients[${index}].preferredDestinationArrivalTime`}
              placeholder="Preferred Date and Time of Arrival at Destination *"
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={6}>
            <Field
              required
              multiline
              maxRows={4}
              disabled={disabled}
              component={TextField}
              label="Medical Needs of Patient"
              name={`patients[${index}].medicalNeeds`}
            />
          </Grid>

          <Grid item xs={6} className={classes.checkboxes}>
            <Field
              size="small"
              color="primary"
              type="checkbox"
              disabled={disabled}
              component={CheckboxWithLabel}
              name={`patients[${index}].canFlyCommercially`}
              Label={{
                label: (
                  <Typography className={classes.checkboxLabel}>
                    Patient can fly commercially
                  </Typography>
                ),
              }}
            />

            {values.patients[index].canFlyCommercially && (
              <Field
                size="small"
                color="primary"
                type="checkbox"
                disabled={disabled}
                name={`patients[${index}].requiresMedicalEscort`}
                component={CheckboxWithLabel}
                Label={{
                  label: (
                    <Typography className={classes.checkboxLabel}>
                      Requires Medical Escort on the Commercial Flight
                    </Typography>
                  ),
                }}
              />
            )}
          </Grid>
        </Grid>
      </Box>

      <ExistingNextOfKin
        formKey={formKey}
        forceDisableFields={disabled}
        memberships={memberSubscriptions}
        memberNextOfKin={memberNextOfKin}
        disabledFields={disabledNextOfKinFields}
        patient={values.patients[index] || undefined}
        setDisabledNextOfKinFields={setDisabledNextOfKinFields}
        nextOfKin={values.patients[index].nextOfKin || undefined}
      />

      {serviceCase.destinationLocationType ===
        LocationTypeEnum.MEDICAL_FACILITY && (
        <ReceivingPhysician
          index={index}
          disabled={disabled}
          formikProps={formikProps}
        />
      )}
    </Box>
  );
};
