import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { Radios } from "component/atoms";
import { StyledAccordion } from "component/atoms/StyledAccordion";
import {
  NextOfKinFormProps,
  NextOfKinTypeEnum,
  PatientData,
} from "call/components/CallForms/RequesterAndPatientForm/types";
import { NextOfKinForm } from "call/components/CallForms/RequesterAndPatientForm/NextOfKinForm";
import {
  NextOfKinData,
  MinimalContact,
  MembersResponseSubObject,
} from "@deep-consulting-solutions/be2-constants";
import { getIn, useFormikContext } from "formik";
import { contactIsOver18 } from "helpers";
import { useCall } from "call/hooks";
import { transformRelationToApplicantEnum } from "./helpers";
import { NextOfKinDisabledFields } from "./types";

interface Props extends NextOfKinFormProps {
  memberships: MembersResponseSubObject[];
  memberNextOfKin?: Partial<NextOfKinData>;
  patient: Pick<
    PatientData,
    "nextOfKinMembership" | "dob" | "age" | "nextOfKin"
  >;
  disabledFields?: Partial<NextOfKinDisabledFields>;
  setDisabledNextOfKinFields?: React.Dispatch<
    React.SetStateAction<Partial<NextOfKinDisabledFields>>
  >;
  forceDisableFields?: boolean;
}

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  nextOfKinContainer: {},
  sectionContainer: {
    background: "#F6F6F6",
    padding: s(2),
    borderRadius: s(1),
    marginBottom: s(2),
  },
  title: {
    fontWeight: 500,
    marginBottom: s(2),
  },
  defaultRadio: {
    padding: s(0, 0.75),
    "& .MuiSvgIcon-root": {
      fontSize: 16,
    },
    "& .MuiFormControlLabel-root": {
      marginBottom: s(1.5),
    },
  },
  contained: {
    "& .MuiFormControlLabel-label": {
      fontSize: s(1.25),
    },
  },
  errorMessage: {
    color: p.error.main,
  },
}));

export const ExistingNextOfKin = ({
  formKey,
  nextOfKin,
  memberships: m,
  memberNextOfKin,
  patient,
  disabledFields,
  setDisabledNextOfKinFields,
  forceDisableFields = false,
}: Props) => {
  const { viewedCall } = useCall();
  const classes = useStyles();
  const [nextOfKinExpanded, setNextOfKinExpanded] = useState(false);
  const { errors, touched, submitCount, setFieldValue, setFieldTouched } =
    useFormikContext();
  const [newNextOfKin, setNewNextOfKin] = useState<
    Partial<{
      relationToContact: string;
      otherRelationToContact: string;
      firstName: string;
      lastName: string;
      phone: string;
      email: string;
    }>
  >({});

  const fullName = useMemo(
    () =>
      nextOfKin?.firstName
        ? `${nextOfKin?.firstName} ${nextOfKin?.lastName}`
        : "",
    [nextOfKin?.firstName, nextOfKin?.lastName]
  );

  const getMembershipOptions = useCallback(
    (membership: MembersResponseSubObject) =>
      (membership.isMainBeneficiary
        ? []
        : [
            {
              label: `${membership.mainBeneficiary?.firstName} ${membership.mainBeneficiary?.lastName} (Main Beneficiary)`,
              value: membership.mainBeneficiary?.id,
            },
          ]
      )
        .concat(
          membership.mainBeneficiaryEmergencyContact?.firstName
            ? [
                {
                  label: `${membership.mainBeneficiaryEmergencyContact?.firstName} ${membership.mainBeneficiaryEmergencyContact?.lastName} (Emergency Contact)`,
                  value: membership.mainBeneficiaryEmergencyContact?.id,
                },
              ]
            : []
        )
        .concat(
          membership.otherMembers.map((otherMember) => ({
            label: `${otherMember.firstName} ${otherMember.lastName} (Dependent)`,
            value: otherMember.id,
          }))
        ),
    []
  );

  const memberships = useMemo(
    () => m.filter((membership) => !!getMembershipOptions(membership).length),
    [m, getMembershipOptions]
  );

  const handleMembershipRadioChange = useCallback(
    (value: string) => {
      for (let index = 0; index < memberships.length; index += 1) {
        const membership = memberships[index];
        if (membership.mainBeneficiary?.id === value) {
          const relationshipToPatient = transformRelationToApplicantEnum(
            "NextOfKinRelationToPatientEnum",
            membership.mainBeneficiary.relationToMainbeneficiary ||
              membership.mainBeneficiary.relationToContact
          );
          const otherRelationToPatient = membership.mainBeneficiary
            .relationToMainbeneficiary
            ? membership.mainBeneficiary.otherRelationToMainbeneficiary
            : membership.mainBeneficiary.otherRelationToContact;

          setFieldValue(`${formKey}.nextOfKin`, {
            relationshipToPatient: relationshipToPatient || "",
            otherRelationToPatient: otherRelationToPatient || "",
            firstName: membership.mainBeneficiary.firstName || "",
            lastName: membership.mainBeneficiary.lastName || "",
            phone: membership.mainBeneficiary.phone || "",
            email: membership.mainBeneficiary.email || "",
            isMemberOrCustomer: true,
            contactID: membership.mainBeneficiary.id || "",
          });
          if (setDisabledNextOfKinFields) {
            setDisabledNextOfKinFields({
              relationshipToPatient: !!relationshipToPatient,
              otherRelationToPatient: !!otherRelationToPatient,
              firstName: !!membership.mainBeneficiary?.firstName,
              lastName: !!membership.mainBeneficiary?.lastName,
              phone: !!membership.mainBeneficiary?.phone,
              email: !!membership.mainBeneficiary?.email,
            });
          }
          break;
        }

        if (membership.mainBeneficiaryEmergencyContact?.id === value) {
          let relationshipToPatient = transformRelationToApplicantEnum(
            "NextOfKinRelationToPatientEnum",
            membership.mainBeneficiaryEmergencyContact.relationToContact
          );
          let otherRelationToPatient =
            membership.mainBeneficiaryEmergencyContact.otherRelationToContact;

          if (
            membership.isMainBeneficiary &&
            membership.mainBeneficiaryEmergencyContact.relationToMainbeneficiary
          ) {
            relationshipToPatient = transformRelationToApplicantEnum(
              "NextOfKinRelationToPatientEnum",
              membership.mainBeneficiaryEmergencyContact
                .relationToMainbeneficiary
            );

            otherRelationToPatient =
              membership.mainBeneficiaryEmergencyContact
                .otherRelationToMainbeneficiary;
          }

          setFieldValue(`${formKey}.nextOfKin`, {
            relationshipToPatient: relationshipToPatient || "",
            otherRelationToPatient: otherRelationToPatient || "",
            firstName:
              membership.mainBeneficiaryEmergencyContact.firstName || "",
            lastName: membership.mainBeneficiaryEmergencyContact.lastName || "",
            phone: membership.mainBeneficiaryEmergencyContact.phone || "",
            email: membership.mainBeneficiaryEmergencyContact.email || "",
            isMemberOrCustomer: true,
            contactID: membership.mainBeneficiaryEmergencyContact.id || "",
          });
          if (setDisabledNextOfKinFields) {
            setDisabledNextOfKinFields({
              relationshipToPatient: !!relationshipToPatient,
              otherRelationToPatient: !!otherRelationToPatient,
              firstName:
                !!membership.mainBeneficiaryEmergencyContact?.firstName,
              lastName: !!membership.mainBeneficiaryEmergencyContact?.lastName,
              phone: !!membership.mainBeneficiaryEmergencyContact?.phone,
              email: !!membership.mainBeneficiaryEmergencyContact?.email,
            });
          }
          break;
        }

        const fromOtherMembers = membership.otherMembers.find(
          (o) => o.id === value
        );

        if (fromOtherMembers) {
          const isPatientDependent = !membership.isMainBeneficiary;
          const relationshipToPatient = transformRelationToApplicantEnum(
            "NextOfKinRelationToPatientEnum",
            isPatientDependent
              ? fromOtherMembers.relationToContact
              : fromOtherMembers.relationToMainbeneficiary ||
                  fromOtherMembers.relationToContact
          );
          const otherRelationToPatient = isPatientDependent
            ? fromOtherMembers.otherRelationToContact
            : fromOtherMembers.otherRelationToMainbeneficiary ||
              fromOtherMembers.otherRelationToContact;

          setFieldValue(`${formKey}.nextOfKin`, {
            relationshipToPatient: relationshipToPatient || "",
            otherRelationToPatient: otherRelationToPatient || "",
            firstName: fromOtherMembers.firstName || "",
            lastName: fromOtherMembers.lastName || "",
            phone: fromOtherMembers.phone || "",
            email: fromOtherMembers.email || "",
            isMemberOrCustomer: true,
            contactID: fromOtherMembers.id || "",
          });
          if (setDisabledNextOfKinFields) {
            setDisabledNextOfKinFields({
              relationshipToPatient: !!relationshipToPatient,
              otherRelationToPatient: !!otherRelationToPatient,
              firstName: !!fromOtherMembers?.firstName,
              lastName: !!fromOtherMembers?.lastName,
              phone: !!fromOtherMembers?.phone,
              email: !!fromOtherMembers?.email,
            });
          }
          break;
        }
      }
      setFieldValue(`${formKey}.nextOfKinMembership`, value);
    },
    [setFieldValue, memberships, formKey, setDisabledNextOfKinFields]
  );

  const handleNoneMembershipRadioChange = useCallback(
    (value: string) => {
      setFieldValue(`${formKey}.nextOfKinMembership`, value);
      setFieldTouched(`${formKey}.nextOfKin`, false);
      setFieldValue(`${formKey}.nextOfKin`, {
        phone: "",
        email: "",
        lastName: "",
        firstName: "",
        relationshipToPatient: "",
        isMemberOrCustomer: false,
        otherRelationToPatient: "",
      });
    },
    [formKey, setFieldValue, setFieldTouched]
  );

  const handleNewMembershipRadioChange = useCallback(
    (value: string) => {
      setFieldValue(`${formKey}.nextOfKin`, {
        relationshipToPatient: newNextOfKin?.relationToContact || "",
        otherRelationToPatient: newNextOfKin?.otherRelationToContact || "",
        firstName: newNextOfKin?.firstName || "",
        lastName: newNextOfKin?.lastName || "",
        phone: newNextOfKin?.phone || "",
        email: newNextOfKin?.email || "",
        isMemberOrCustomer: false,
      });
      if (setDisabledNextOfKinFields) {
        setDisabledNextOfKinFields({
          relationshipToPatient: false,
          otherRelationToPatient: false,
          firstName: false,
          lastName: false,
          phone: false,
          email: false,
        });
      }
      setFieldValue(`${formKey}.nextOfKinMembership`, value);
    },
    [setFieldValue, formKey, setDisabledNextOfKinFields, newNextOfKin]
  );

  const handleNextOfKinMembershipRadioChange = useCallback(
    (value: string) => {
      const relationshipToPatient =
        transformRelationToApplicantEnum(
          "NextOfKinRelationToPatientEnum",
          memberNextOfKin?.relationshipToPatient ||
            (memberNextOfKin as MinimalContact).relationToContact
        ) || "";

      const otherRelationToPatient =
        memberNextOfKin?.otherRelationToPatient ||
        (memberNextOfKin as MinimalContact).otherRelationToContact ||
        "";

      setFieldValue(`${formKey}.nextOfKin`, {
        relationshipToPatient,
        otherRelationToPatient,
        firstName: memberNextOfKin?.firstName || "",
        lastName: memberNextOfKin?.lastName || "",
        phone: memberNextOfKin?.phone || "",
        email: memberNextOfKin?.email || "",
        isMemberOrCustomer: true,
      });

      if (setDisabledNextOfKinFields) {
        setDisabledNextOfKinFields({
          relationshipToPatient: !!relationshipToPatient,
          otherRelationToPatient: !!otherRelationToPatient,
          firstName: !!memberNextOfKin?.firstName,
          lastName: !!memberNextOfKin?.lastName,
          phone: !!memberNextOfKin?.phone,
          email: !!memberNextOfKin?.email,
        });
      }
      setFieldValue(`${formKey}.nextOfKinMembership`, value);
    },
    [formKey, setFieldValue, setDisabledNextOfKinFields, memberNextOfKin]
  );

  const getIsMembershipOptionFromMembershipNumber = useCallback(
    (membership: MembersResponseSubObject) => {
      const options = getMembershipOptions(membership);

      return !!options.find(
        (option) => option.value === (patient as any)?.nextOfKinMembership
      );
    },
    [patient, getMembershipOptions]
  );

  const nextOfKinSubTitle = useMemo(() => {
    if (nextOfKinExpanded) {
      if (contactIsOver18({ dob: patient.dob, age: patient.age })) {
        return "(Strongly Recommended)";
      }

      return "";
    }

    if (fullName.trim()) {
      return <b>{fullName}</b>;
    }

    if (contactIsOver18({ dob: patient.dob, age: patient.age })) {
      return "(Strongly Recommended)";
    }

    return "";
  }, [nextOfKinExpanded, fullName, patient.dob, patient.age]);

  useEffect(() => {
    if ((patient as any)?.nextOfKinMembership === NextOfKinTypeEnum.NEW) {
      setNewNextOfKin({
        relationToContact: patient?.nextOfKin?.relationshipToPatient || "",
        otherRelationToContact:
          patient?.nextOfKin?.otherRelationToPatient || "",
        firstName: patient?.nextOfKin?.firstName || "",
        lastName: patient?.nextOfKin?.lastName || "",
        phone: patient?.nextOfKin?.phone || "",
        email: patient?.nextOfKin?.email || "",
      });
    }
  }, [patient]);

  useEffect(() => {
    if (disabledFields?.relationshipToPatient) {
      setFieldTouched(`${formKey}.nextOfKin.relationshipToPatient`, false);
    }
    if (disabledFields?.otherRelationToPatient) {
      setFieldTouched(`${formKey}.nextOfKin.otherRelationToPatient`, false);
    }
    if (disabledFields?.firstName) {
      setFieldTouched(`${formKey}.nextOfKin.firstName`, false);
    }
    if (disabledFields?.lastName) {
      setFieldTouched(`${formKey}.nextOfKin.lastName`, false);
    }
    if (disabledFields?.phone) {
      setFieldTouched(`${formKey}.nextOfKin.phone`, false);
    }
    if (disabledFields?.email) {
      setFieldTouched(`${formKey}.nextOfKin.email`, false);
    }
  }, [disabledFields, setFieldTouched, formKey]);

  const hasErrors = getIn(errors, `${formKey}.nextOfKin`);
  const isTouched = getIn(touched, `${formKey}.nextOfKin`);

  useEffect(() => {
    if (hasErrors) {
      setNextOfKinExpanded(true);
    }
  }, [hasErrors, submitCount]);

  const disableForm = useMemo(() => {
    if (viewedCall?.disableForms) {
      return true;
    }
    if (forceDisableFields) {
      return true;
    }

    return false;
  }, [viewedCall?.disableForms, forceDisableFields]);

  return (
    <StyledAccordion
      summary={() => <Typography>Next of kin {nextOfKinSubTitle}</Typography>}
      expanded={nextOfKinExpanded}
      bordered
      hasHorizontalPadding
      onChange={() => setNextOfKinExpanded(!nextOfKinExpanded)}
    >
      <div className={classes.nextOfKinContainer}>
        {!patient.nextOfKinMembership && hasErrors && isTouched && (
          <Box mb={2}>
            <Typography variant="body2" className={classes.errorMessage}>
              Please select a next of kin to continue.
            </Typography>
          </Box>
        )}

        {!!memberNextOfKin && (
          <div className={classes.sectionContainer}>
            <Typography
              variant="body1"
              color="primary"
              className={classes.title}
            >
              Patient&apos;s Next of Kin
            </Typography>
            <Radios
              name={`${formKey}.nextOfKinMembership`}
              onChange={handleNextOfKinMembershipRadioChange}
              options={[
                {
                  label: `${memberNextOfKin.firstName} ${memberNextOfKin.lastName}`,
                  value: NextOfKinTypeEnum.NEXT_OF_KIN,
                },
              ]}
              disabled={disableForm}
            />
            {(patient as any)?.nextOfKinMembership ===
              NextOfKinTypeEnum.NEXT_OF_KIN && (
              <NextOfKinForm
                disabledFields={disabledFields}
                formKey={formKey}
                nextOfKin={nextOfKin}
              />
            )}
          </div>
        )}
        {memberships.length ? (
          <div className={classes.contained}>
            <Typography variant="body2" color="primary">
              Select a member from Patient&apos;s membership(s)
            </Typography>
            {memberships.map((membership) => (
              <div
                key={membership.subscriptionID}
                className={classes.sectionContainer}
              >
                <Typography
                  variant="body1"
                  color="primary"
                  className={classes.title}
                >
                  Membership #{membership.mainBeneficiaryMembershipNo}
                </Typography>
                <Radios
                  name={`${formKey}.nextOfKinMembership`}
                  onChange={handleMembershipRadioChange}
                  options={getMembershipOptions(membership)}
                  disabled={disableForm}
                />
                {getIsMembershipOptionFromMembershipNumber(membership) && (
                  <NextOfKinForm
                    disabledFields={disabledFields}
                    formKey={formKey}
                    nextOfKin={nextOfKin}
                  />
                )}
              </div>
            ))}
          </div>
        ) : null}

        <div className={classes.sectionContainer}>
          <Radios
            name={`${formKey}.nextOfKinMembership`}
            onChange={handleNewMembershipRadioChange}
            options={[{ label: "New", value: NextOfKinTypeEnum.NEW }]}
            disabled={disableForm}
          />
          {(patient as any)?.nextOfKinMembership === NextOfKinTypeEnum.NEW && (
            <NextOfKinForm
              formKey={formKey}
              nextOfKin={nextOfKin}
              disabledFields={
                disableForm
                  ? {
                      firstName: true,
                      lastName: true,
                      phone: true,
                      email: true,
                      relationshipToPatient: true,
                      otherRelationToPatient: true,
                    }
                  : undefined
              }
            />
          )}
        </div>

        {contactIsOver18(patient) && (
          <div className={classes.sectionContainer}>
            <Radios
              name={`${formKey}.nextOfKinMembership`}
              onChange={handleNoneMembershipRadioChange}
              options={[{ label: "None", value: NextOfKinTypeEnum.NONE }]}
              disabled={disableForm}
            />
          </div>
        )}
      </div>
    </StyledAccordion>
  );
};
