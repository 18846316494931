import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(({ spacing: s }) => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: s(2),
    width: "100%",
    height: "100%",
    background: "white",
    borderRadius: 8,
  },
}));

interface Props {
  message?: string;
}

export const MapErrorComponent = ({ message }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Typography color="error" align="center" variant="body1">
        {message || "Unable to load map"}
      </Typography>
    </div>
  );
};
