import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useCallback, MouseEventHandler } from "react";

const useStyles = makeStyles(({ spacing: s }) => ({
  title: {
    padding: s(2),
  },
  content: {
    padding: s(0, 2),
  },
  actions: {
    padding: s(2),
  },
  bodyText: {
    color: "#4F4F4F",
    marginBottom: s(2),
    fontWeight: 400,
    fontSize: 16,
  },
  wrapper: {
    borderRadius: 16,
  },
  true: {
    background: "rgba(202, 32, 39, 1)",
    color: "white",
    "&:hover": {
      background: "rgba(202, 32, 39, 0.8)",
    },
  },
  titleText: {
    fontWeight: 700,
    fontSize: 24,
  },
  closeIcon: {
    color: "#4F4F4F",
  },
}));

interface Props {
  handleSave: () => void;
  handleDiscard: () => void;
  handleClose: () => void;
}

export const UnsavedChangesDialog = ({
  handleDiscard,
  handleSave,
  handleClose,
  ...props
}: DialogProps & Props) => {
  const classes = useStyles();

  const handleCancel: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      handleDiscard();
    },
    [handleDiscard]
  );

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      handleSave();
    },
    [handleSave]
  );

  return (
    <Dialog {...props} className={classes.wrapper} fullWidth maxWidth="sm">
      <DialogTitle className={classes.title}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography
              align="left"
              color="primary"
              variant="subtitle1"
              className={classes.titleText}
            >
              Unsaved changes
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <Close className={classes.closeIcon} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant="body2" align="left" className={classes.bodyText}>
          You have unsaved changes. Do you want to discard or save them?
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
        >
          <Grid item>
            <Button
              className={classes.true}
              color="primary"
              onClick={handleCancel}
            >
              Discard Changes
            </Button>
          </Grid>
          <Grid item>
            <Button color="primary" onClick={handleSubmit}>
              SAVE CHANGES
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
