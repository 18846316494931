import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { MedicalDirectorType } from "redux/call/types";

interface Props {
  medicalProfessional: MedicalDirectorType;
}

const useStyles = makeStyles(() => ({
  organization: {
    color: "#ED6C02",
  },
  wrapper: {
    width: "100%",
  },
}));

export const MedicalDirectorRow = ({ medicalProfessional }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Grid
        container
        alignItems="flex-start"
        justifyContent="space-between"
        spacing={2}
        wrap="nowrap"
      >
        <Grid item>
          <Grid container direction="column" alignItems="flex-start">
            <Grid item>
              <Typography variant="caption" color="primary">
                {medicalProfessional.fullName}
                <span className={classes.organization}>
                  {" "}
                  ({medicalProfessional.workingFacility})
                </span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                {medicalProfessional.phone}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="flex-end">
            <Grid item>
              <Typography variant="caption" align="right">
                <strong>Availability</strong>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" align="right">
                {medicalProfessional.availability}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
