import React, { useMemo } from "react";
import { Grid, makeStyles, Divider, Typography } from "@material-ui/core";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import { Field, useFormikContext } from "formik";
import { useCall } from "call/hooks";
import DCSPhoneInput from "component/atoms/DCSPhoneInput";
import {
  PatientCoverageEnum,
  ServiceCaseStatusEnum,
} from "@deep-consulting-solutions/be2-constants";
import { ServiceTitle } from "../components/ServiceTitle";
import {
  getIsServiceCaseApproved,
  getIsServiceCaseRefused,
  getIsServiceToBeRenderedEmergencyAirliftEvacuationOrHospitalTransferOrRepatriationOrCustom,
  checkIsRequesterAndIsContacted,
  getIsRequesterPhysician,
} from "../helpers";
import { FormValues } from "../types";
import { InfoCard } from "../components";
import { CallAttempts } from "./CallAttempts";
import { ServiceCaseStatusForPatientPhysician } from "./ServiceCaseStatusForPatientPhysician";

const collectionInfo = [
  "Collect the Patient's Physician information and initiate contact with the Physician.",
  "Get medical needs of Patients from Physician and Inform the Physician that a medical director for the case will contact them.",
];

const collectionInfo2 = [
  "When calling the Physician, make sure to confirm the initial and destination locations.",
  "Make sure the Physician is the same for all the Patients. If not, move the other Patients to a new service case.",
];

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  divider: {
    margin: s(2, 0),
  },
  wrapper: {
    padding: s(0, 0),
  },
  checkboxLabel: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "14px",
    color: p.primary.main,
  },
}));

export const PatientForm = () => {
  const { viewedCall } = useCall();

  const {
    values: {
      serviceToBeRendered,
      physician: { isContacted: physicianContacted },
    },
    errors,
    touched,
  } = useFormikContext<FormValues>();

  const { visible } = useMemo(() => {
    return {
      visible:
        !!serviceToBeRendered &&
        getIsServiceToBeRenderedEmergencyAirliftEvacuationOrHospitalTransferOrRepatriationOrCustom(
          serviceToBeRendered
        ),
    };
  }, [serviceToBeRendered]);

  const isRequesterPhysician = useMemo(() => {
    return getIsRequesterPhysician(viewedCall?.updatedServiceCase?.patients);
  }, [viewedCall?.updatedServiceCase?.patients]);

  const showFields = useMemo(() => {
    return checkIsRequesterAndIsContacted(
      isRequesterPhysician,
      physicianContacted
    );
  }, [isRequesterPhysician, physicianContacted]);

  const isServiceCaseAccepted = useMemo(() => {
    return getIsServiceCaseApproved(
      viewedCall?.updatedServiceCase?.approvalRequests
    );
  }, [viewedCall?.updatedServiceCase?.approvalRequests]);

  const isServiceCaseRefused = useMemo(
    () =>
      getIsServiceCaseRefused(viewedCall?.updatedServiceCase?.approvalRequests),
    [viewedCall?.updatedServiceCase?.approvalRequests]
  );

  const classes = useStyles();

  const disableForm = useMemo(
    () =>
      !!viewedCall?.disableForms ||
      viewedCall?.updatedServiceCase?.caseStatus !==
        ServiceCaseStatusEnum.GATHERING_REQUIREMENTS,
    [viewedCall?.disableForms, viewedCall?.updatedServiceCase?.caseStatus]
  );

  const onePatientIsCovered = useMemo(
    () =>
      viewedCall?.updatedServiceCase?.patients.some(
        (patient) =>
          !!patient.subCoverage.find(
            (subCoverage) =>
              subCoverage.coverage === PatientCoverageEnum.COVERED
          )
      ),
    [viewedCall?.updatedServiceCase?.patients]
  );

  if (!visible) {
    return null;
  }

  if (isServiceCaseAccepted || isServiceCaseRefused) {
    return (
      <div className={classes.wrapper}>
        <ServiceTitle title="Patient's Physician" />
        <ServiceCaseStatusForPatientPhysician />
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <InfoCard
        texts={[
          onePatientIsCovered
            ? "Inform the Requester that the transport service might not be rendered."
            : "Inform the Requester that the transport service might not be rendered and in any case it won't be covered by the Subscription Plan.",
        ]}
        visible={checkIsRequesterAndIsContacted(
          physicianContacted,
          isRequesterPhysician
        )}
      />
      <ServiceTitle title="Patient's Physician" />

      {!isRequesterPhysician && (
        <Field
          component={CheckboxWithLabel}
          color="primary"
          name="physician.isContacted"
          Label={{
            label: (
              <Typography color="primary" className={classes.checkboxLabel}>
                Patient&apos;s Physician was contacted
              </Typography>
            ),
          }}
          type="checkbox"
          disabled={disableForm}
        />
      )}

      <InfoCard
        texts={collectionInfo}
        visible={!isRequesterPhysician && physicianContacted}
      />
      {showFields && (
        <>
          <Grid container alignItems="stretch" spacing={4}>
            <Grid item xs={6}>
              <Field
                size="small"
                component={TextField}
                name="physician.firstName"
                label="First Name"
                required
                disabled={disableForm}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                size="small"
                component={TextField}
                name="physician.lastName"
                label="Last Name"
                required
                disabled={disableForm}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="stretch" spacing={4}>
            <Grid item xs={6}>
              <Field
                size="small"
                component={DCSPhoneInput}
                name="physician.phone"
                label="Phone"
                required
                errorMessage={
                  touched?.physician?.phone ? errors.physician?.phone : ""
                }
                disabled={disableForm}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                size="small"
                component={TextField}
                name="physician.email"
                type="email"
                label="Email"
                disabled={disableForm}
              />
            </Grid>
          </Grid>
        </>
      )}
      <InfoCard
        texts={collectionInfo2}
        visible={!isRequesterPhysician && physicianContacted}
      />

      <CallAttempts />
      <Divider className={classes.divider} />
    </div>
  );
};
