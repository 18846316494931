import { composeUserName } from "helpers";

export const getName = (data?: {
  firstName?: string | null;
  lastName?: string | null;
  name?: string | null;
}) => {
  if (!data) return "-";
  const { firstName, lastName, name } = data;
  if (name) return name;
  if (firstName || lastName) return composeUserName({ firstName, lastName });
  return "-";
};
