import React, { useCallback, useEffect, useState } from "react";
import { Close } from "@material-ui/icons";
import {
  Box,
  Button,
  Dialog,
  Checkbox,
  Typography,
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
} from "@material-ui/core";

import Loader from "component/Loader";
import {
  getLocationCollectorLink,
  sendLocationCollectorLink,
} from "redux/call/requests";
import { ServiceCaseResponse } from "@deep-consulting-solutions/be2-constants";

interface SendLocationCollectorDialogProps {
  phone: string;
  onClose: () => void;
  onSendClick: () => void;
  syncLiveLocation: boolean;
  serviceCase?: ServiceCaseResponse;
  setSyncLiveLocation: (value: boolean) => void;
}

const useStyles = makeStyles(({ spacing: s }) => ({
  dialogActions: {
    padding: s(2),
    display: "flex",
    justifyContent: "space-between",
  },
  dialogContent: {
    textAlign: "center",

    "& > :not(:last-child)": {
      marginBottom: s(2),
    },
  },
  brokenLinkBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& > :first-child": {
      marginRight: s(2),
    },
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F7F7F7",
    justifyContent: "space-between",
  },
  regenerateLinkButton: {
    padding: 0,
  },
  regenerateLinkText: {
    color: "#2F80ED",
    textTransform: "none",
    textDecoration: "underline",
  },
}));

const SendLocationCollectorDialog = ({
  phone,
  onClose,
  onSendClick,
  serviceCase,
  syncLiveLocation,
  setSyncLiveLocation,
}: SendLocationCollectorDialogProps) => {
  const classes = useStyles();
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(true);

  const getOTP = useCallback(async () => {
    setLoading(true);

    try {
      const { locationCollectorLink } = await getLocationCollectorLink(
        serviceCase?.id || "",
        syncLiveLocation
      );
      setLink(locationCollectorLink);
    } catch {
      //
    } finally {
      setLoading(false);
    }
  }, [serviceCase?.id, syncLiveLocation]);

  const handleSend = useCallback(() => {
    (async () => {
      setLoading(true);

      try {
        const id = serviceCase?.id;

        if (id) {
          await sendLocationCollectorLink(id, link, phone);
          onSendClick();
        }
      } catch {
        setLoading(false);
      }
    })();
  }, [link, phone, onSendClick, serviceCase?.id]);

  useEffect(() => {
    getOTP();
  }, [getOTP]);

  return (
    <Dialog open onClose={onClose}>
      <Loader open={loading} absolute />

      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography
          component="h2"
          color="primary"
          variant="subtitle1"
          style={{ fontWeight: 500 }}
        >
          Collect Location
        </Typography>

        <Close
          onClick={onClose}
          style={{ color: "#BDBDBD", cursor: "pointer" }}
        />
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Typography color="primary" variant="body2" style={{ fontSize: 24 }}>
          Send SMS with Location Collector link to
        </Typography>

        <Typography
          color="primary"
          variant="body2"
          style={{ fontWeight: 700, fontSize: 24 }}
        >
          {phone}
        </Typography>

        <Typography variant="body1" style={{ color: "#00000099" }}>
          The Requester will be able to click the link to share his Location
          from the BahamasEvac Mobile App (or via SMS if he is offline) or from
          the browser if he doesn&apos;t have the app.
        </Typography>

        <Typography
          variant="body2"
          style={{
            fontSize: 24,
            color: "#2F80ED",
            textDecoration: "underline",
          }}
        >
          {link}
        </Typography>

        <Box className={classes.brokenLinkBox}>
          <Typography variant="body1" style={{ color: "#4F4F4F" }}>
            The Link is not working?
          </Typography>

          <Button
            variant="text"
            className={classes.regenerateLinkButton}
            onClick={() => {
              getOTP();
            }}
          >
            <Typography variant="body1" className={classes.regenerateLinkText}>
              Regenerate
            </Typography>
          </Button>
        </Box>

        <Typography variant="body1" style={{ color: "#00000099" }}>
          You can also share the link with the Requester over the phone, or tell
          the Requester to use the <strong>Send GPS Location</strong> feature in
          the BahamasEvac Mobile App and enter the OTP there.
        </Typography>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={syncLiveLocation}
              name="syncLiveLocationForCollector"
              onChange={() => setSyncLiveLocation(!syncLiveLocation)}
            />
          }
          label={
            <Typography variant="subtitle2" color="primary">
              Sync Live Location
            </Typography>
          }
        />

        <Box>
          <Button
            color="primary"
            onClick={onClose}
            variant="outlined"
            style={{ marginRight: "16px" }}
          >
            Cancel
          </Button>

          <Button color="primary" onClick={handleSend}>
            Send
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default SendLocationCollectorDialog;
