import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
  useMemo,
} from "react";
import {
  CreditCard,
  PostVerifySubscriptionPayment,
  SubscriptionPaymentBreakdown,
} from "@deep-consulting-solutions/be2-constants";
import { notifications } from "services";
import { Box } from "@material-ui/core";
import Loader from "component/Loader";
import { formatExpiryNumber, pnpUtils } from "helpers";
import { PaymentCards } from "./PaymentCards";
import { PaymentStatus } from "./PaymentStatus";
import { PaymentForm } from "./PaymentForm";
import {
  GeneratePaymentOrderIdBody,
  GeneratePaymentOrderIdResponse,
  PaymentStatusReasonEnum,
  PaymentCardsFlowEnum,
  PaymentCardsStepEnum,
} from "./types";

interface Props {
  cards?: CreditCard[];
  subscriptionPaymentBreakdown: SubscriptionPaymentBreakdown | null;
  submitCardDetailsRequest?: (
    values: GeneratePaymentOrderIdBody
  ) => Promise<GeneratePaymentOrderIdResponse>;
  handlePaymentResponse?: () => any;
  verifyPayment: (
    values: typeof PostVerifySubscriptionPayment["Body"]
  ) => Promise<boolean>;
  paymentFormButtonLabel?: string;
  payWithExistingCard?: (
    creditCardId: string,
    useCardForRecurringPayments: boolean
  ) => Promise<Record<string, unknown>>;
  onSuccess?: () => void;
}

export const PaymentCard = ({
  cards,
  subscriptionPaymentBreakdown,
  submitCardDetailsRequest,
  handlePaymentResponse,
  verifyPayment,
  paymentFormButtonLabel,
  payWithExistingCard,
  onSuccess,
}: Props) => {
  const [flow, setFlow] = useState(PaymentCardsFlowEnum.CARDS);
  const orderIdInputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [errorReason, setErrorReason] = useState(
    PaymentStatusReasonEnum.BAD_CARD
  );
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const [pnpData, setPnpData] = useState("");
  const [showPaymentStatus, setShowPaymentStatus] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [pnpFormValues, setPnPFormValues] = useState<{
    cvv?: string;
    expiryDate?: string;
    cardNumber?: string;
  }>({});
  const [initialPaymentCardsStep, setInitialPaymentCardsStep] = useState<
    PaymentCardsStepEnum | undefined
  >(undefined);
  const [recurringValue, setRecurringValue] = useState<boolean | undefined>(
    undefined
  );
  const [holderName, setHolderName] = useState("");

  const closePaymentStatus = useCallback(() => {
    setShowPaymentStatus(false);
    if (isPaymentSuccessful && onSuccess) {
      onSuccess();
    }
  }, [isPaymentSuccessful, onSuccess]);

  const openPaymentStatus = useCallback((showSuccess: boolean) => {
    setIsPaymentSuccessful(showSuccess);
    setShowPaymentStatus(true);
  }, []);

  const newCardClick = useCallback(() => {
    if (flow === PaymentCardsFlowEnum.CARDS) {
      setInitialPaymentCardsStep(PaymentCardsStepEnum.LIST_CARDS);
    }
    closePaymentStatus();
  }, [closePaymentStatus, flow]);

  const handlePaymentStatus = useCallback(
    (status: boolean) => {
      if (handlePaymentResponse) {
        handlePaymentResponse();
      } else {
        openPaymentStatus(status);
      }
    },
    [handlePaymentResponse, openPaymentStatus]
  );

  const gotoNewCard = useCallback(() => {
    setFlow(PaymentCardsFlowEnum.NEW_CARD);
  }, []);

  const onSubmitCardDetails = useCallback(
    async (values: GeneratePaymentOrderIdBody) => {
      if (!submitCardDetailsRequest) {
        return;
      }
      setLoading(true);
      try {
        const res = await submitCardDetailsRequest(values);
        if (res) {
          if (orderIdInputRef.current) {
            setOrderId(res.orderId);
            setHolderName(values.creditCard.holderName);
            setRecurringValue(values.useCardForRecurringPayments);
            orderIdInputRef.current.value = res.orderId;
            window.payment_api.send();
          }
        }
      } catch {
        setErrorReason(PaymentStatusReasonEnum.BAD_CARD);
        handlePaymentStatus(false);
      } finally {
        setLoading(false);
      }
    },
    [submitCardDetailsRequest, handlePaymentStatus]
  );

  const onSubmitExistingCardDetails = useCallback(
    async (creditCardId: string, useCardForRecurringPayments: boolean) => {
      if (!payWithExistingCard) {
        return;
      }
      setLoading(true);
      try {
        const res = await payWithExistingCard(
          creditCardId,
          useCardForRecurringPayments
        );
        handlePaymentStatus(true);

        return res;
      } catch {
        setErrorReason(PaymentStatusReasonEnum.BAD_CARD);
        handlePaymentStatus(false);
      } finally {
        setLoading(false);
      }
    },
    [payWithExistingCard, handlePaymentStatus]
  );

  const handleCardVerify = useCallback((data: string) => {
    setPnpData(data);
  }, []);

  const verifyPnPPayment = useCallback(async () => {
    setLoading(true);
    if (orderId && pnpData && holderName) {
      const res = pnpUtils.decodePnpData(pnpData);
      if (res.FinalStatus === "problem") {
        setOrderId("");
        setPnpData("");
        setLoading(false);
        notifications.notifyError(res.MErrMsg);
      } else {
        const status = await verifyPayment({
          orderId,
          holderName,
          handlesRecurringPayments: Boolean(recurringValue),
        });
        if (!status) {
          setErrorReason(PaymentStatusReasonEnum.UNSUCCESSFUL);
        }
        handlePaymentStatus(status);
      }
    }
    setLoading(false);
  }, [
    orderId,
    pnpData,
    verifyPayment,
    setLoading,
    handlePaymentStatus,
    holderName,
    recurringValue,
  ]);

  useEffect(() => {
    // use return to run unmount statements
    return pnpUtils.initializeComponentForPayment(handleCardVerify, setLoading);
  }, [setLoading, handleCardVerify]);

  useEffect(() => {
    verifyPnPPayment();
  }, [verifyPnPPayment]);

  useEffect(() => {
    if (!cards?.length) {
      gotoNewCard();
    } else {
      setFlow(PaymentCardsFlowEnum.CARDS);
    }
  }, [cards, gotoNewCard]);

  const amount = useMemo(
    () => subscriptionPaymentBreakdown?.grandTotal || 0,
    [subscriptionPaymentBreakdown]
  );

  return (
    <Box>
      <PaymentStatus
        onClose={closePaymentStatus}
        isSuccessful={isPaymentSuccessful}
        open={showPaymentStatus}
        reason={errorReason}
        newCardClick={newCardClick}
      />
      <Loader open={loading} />
      {flow === PaymentCardsFlowEnum.CARDS && !!cards && (
        <PaymentCards
          cards={cards}
          gotoNewCard={gotoNewCard}
          subscriptionPaymentBreakdown={subscriptionPaymentBreakdown}
          initialStep={initialPaymentCardsStep}
          payWithExistingCard={onSubmitExistingCardDetails}
        />
      )}
      {flow === PaymentCardsFlowEnum.NEW_CARD && (
        <PaymentForm
          onSubmitCardDetails={onSubmitCardDetails}
          subscriptionPaymentBreakdown={subscriptionPaymentBreakdown}
          paymentFormButtonLabel={paymentFormButtonLabel}
          setPnPFormValues={setPnPFormValues}
        />
      )}

      {/* PNP Form */}
      <form>
        <input
          type="hidden"
          id="publisher_name"
          value={pnpUtils.MERCHANT_NAME}
        />
        <input type="hidden" id="mode" value="auth" />
        <input type="hidden" id="card_amount" value={amount} />
        <input type="hidden" id="convert" value="underscores" />
        <input type="hidden" id="orderID" ref={orderIdInputRef} />
        <input
          type="hidden"
          id="card_exp"
          value={formatExpiryNumber(pnpFormValues.expiryDate)}
        />
        <input type="hidden" id="card_cvv" value={pnpFormValues.cvv} />
        <input
          type="hidden"
          id="card_number"
          value={pnpFormValues.cardNumber}
        />
      </form>
    </Box>
  );
};
