import { Grid } from "@material-ui/core";
import { getIn, Field, useFormikContext } from "formik";
import { TextField } from "formik-material-ui";
import { SelectField } from "component/atoms";
import DCSPhoneInput from "component/atoms/DCSPhoneInput";
import { NextOfKinRelationToPatientEnum } from "@deep-consulting-solutions/be2-constants";
import { NextOfKinFormProps } from "call/components/CallForms/RequesterAndPatientForm/types";
import { useCall } from "call/hooks";

export const NextOfKinForm: React.FC<NextOfKinFormProps> = ({
  formKey,
  nextOfKin,
  disabledFields,
}) => {
  const { errors, touched } = useFormikContext();

  const phoneError = getIn(errors, `${formKey}.nextOfKin.phone`);
  const hasPhoneError =
    getIn(touched, `${formKey}.nextOfKin.phone`) && !!phoneError;

  const { viewedCall } = useCall();
  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <Field
          size="small"
          component={TextField}
          name={`${formKey}.nextOfKin.firstName`}
          label="First Name"
          required
          disabled={!!disabledFields?.firstName || !!viewedCall?.disableForms}
        />
      </Grid>
      <Grid item xs={4}>
        <Field
          size="small"
          component={TextField}
          name={`${formKey}.nextOfKin.lastName`}
          label="Last Name"
          required
          disabled={!!disabledFields?.lastName || !!viewedCall?.disableForms}
        />
      </Grid>
      <Grid item xs={4}>
        <SelectField
          size="small"
          name={`${formKey}.nextOfKin.relationshipToPatient`}
          label="Relation to Patient"
          required
          options={Object.values(NextOfKinRelationToPatientEnum).map(
            (relation) => ({
              label: relation,
              value: relation,
            })
          )}
          disabled={
            !!disabledFields?.relationshipToPatient ||
            !!viewedCall?.disableForms
          }
        />
      </Grid>
      {nextOfKin?.relationshipToPatient ===
        NextOfKinRelationToPatientEnum.OTHER && (
        <Grid item xs={4}>
          <Field
            size="small"
            component={TextField}
            name={`${formKey}.nextOfKin.otherRelationToPatient`}
            label="Relation to Patient"
            required
            disabled={
              !!disabledFields?.otherRelationToPatient ||
              !!viewedCall?.disableForms
            }
          />
        </Grid>
      )}
      <Grid item xs={4}>
        <Field
          required
          size="small"
          label="Phone"
          error={hasPhoneError}
          component={DCSPhoneInput}
          name={`${formKey}.nextOfKin.phone`}
          helperText={hasPhoneError ? phoneError : undefined}
          disabled={!!disabledFields?.phone || !!viewedCall?.disableForms}
        />
      </Grid>
      <Grid item xs={4}>
        <Field
          size="small"
          component={TextField}
          name={`${formKey}.nextOfKin.email`}
          label="Email"
          disabled={!!disabledFields?.email || !!viewedCall?.disableForms}
        />
      </Grid>
    </Grid>
  );
};
