import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import { PatientData } from "../types";

const useStyles = makeStyles(({ spacing: s }) => ({
  title: {
    padding: s(2),
  },
  content: {
    padding: s(2),
  },
  actions: {
    paddingTop: 0,
    padding: s(2),
  },
  wrapper: {
    padding: s(0),
  },
  true: {
    background: "rgba(202, 32, 39, 1)",
    color: "white",
    "&:hover": {
      background: "rgba(202, 32, 39, 0.8)",
    },
  },
  titleText: {
    fontWeight: 500,
  },
}));

interface Props {
  handleClose: () => void;
  handleDelete: () => void;
  patient?: PatientData;
}

export const DeletePatientDialog = ({
  handleClose,
  open,
  patient,
  handleDelete,
  ...props
}: DialogProps & Props) => {
  const classes = useStyles();

  const onConfirm = useCallback(() => {
    handleDelete();
    handleClose();
  }, [handleDelete, handleClose]);

  const fullName = useMemo(() => {
    return `${patient?.firstName || ""}${
      patient?.firstName && patient.lastName ? " " : ""
    }${patient?.lastName || ""}`;
  }, [patient?.firstName, patient?.lastName]);

  if (!fullName.trim()) {
    return null;
  }

  return (
    <Dialog
      open={open}
      className={classes.wrapper}
      onClose={handleClose}
      {...props}
    >
      <DialogContent className={classes.content}>
        <Typography
          align="left"
          color="primary"
          variant="body1"
          className={classes.titleText}
        >
          Are you sure you want to delete <strong>{fullName}</strong>?
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
        >
          <Grid item>
            <Button color="primary" variant="outlined" onClick={handleClose}>
              No
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.true}
              color="primary"
              onClick={onConfirm}
            >
              Yes
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
