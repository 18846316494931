import {
  PostSalesVerifySubscriptionPayment,
  GetSalesSubscriptionPaymentBreakdown,
  PostPaySubscriptionPaymentWithExistingCard,
  PostGenerateSalesSubscriptionPaymentOrderId,
  GetCustomerCreditCardsFromCRM,
} from "@deep-consulting-solutions/be2-constants";
import { crmSilentClient, getClient } from "apis";

export type PostGenerateSalesSubscriptionPaymentOrderIdBodyCreditCard =
  typeof PostGenerateSalesSubscriptionPaymentOrderId["Body"]["creditCard"];

export const getSubscriptionPaymentBreakdown = async (
  subscriptionID: string
) => {
  const res = await getClient(true).get<
    typeof GetSalesSubscriptionPaymentBreakdown["Res"]
  >(
    GetSalesSubscriptionPaymentBreakdown.ROUTE.replace(
      ":subscriptionId",
      subscriptionID
    )
  );

  return res.data.data;
};

export const submitCardDetails = async (
  subscriptionID: string,
  body: typeof PostGenerateSalesSubscriptionPaymentOrderId["Body"]
) => {
  const res = await getClient(true).post<
    typeof PostGenerateSalesSubscriptionPaymentOrderId["Res"]
  >(
    PostGenerateSalesSubscriptionPaymentOrderId.ROUTE.replace(
      ":subscriptionId",
      subscriptionID
    ),
    body
  );

  return res.data.data;
};

export const verifySubscriptionPayment = async (
  subscriptionID: string,
  body: typeof PostSalesVerifySubscriptionPayment["Body"]
) => {
  const res = await getClient(true).post<
    typeof PostSalesVerifySubscriptionPayment.Res
  >(
    PostSalesVerifySubscriptionPayment.ROUTE.replace(
      ":subscriptionId",
      subscriptionID
    ),
    body
  );

  return res.data.data;
};

export const payWithExistingCard = async (
  subscriptionID: string,
  body: typeof PostPaySubscriptionPaymentWithExistingCard.Body
) => {
  const res = await getClient(true).post<
    typeof PostPaySubscriptionPaymentWithExistingCard.Res
  >(
    PostPaySubscriptionPaymentWithExistingCard.ROUTE.replace(
      ":id",
      subscriptionID
    ),
    body
  );

  return res.data.data;
};

export const fetchCreditCardsReq = async (contactId: string) => {
  const res = await crmSilentClient.get<
    typeof GetCustomerCreditCardsFromCRM.Res
  >(GetCustomerCreditCardsFromCRM.ROUTE.replace(":id", contactId));

  return res.data.data;
};
