import { MembersResponseSubObject } from "@deep-consulting-solutions/be2-constants";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import { Radios } from "component/atoms";

interface StyleProps {
  clickable?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(
  ({ spacing: s, palette: p }) => ({
    heading: {
      fontSize: 20,
    },
    header: {
      padding: s(2),
      backgroundColor: "#FFF",
    },
    tableContainer: {
      margin: s(1, 0),
      border: "1px solid #E0E0E0",
      borderRadius: "4px",
      backgroundColor: "#ECECEC",
      "& .MuiTableCell-root": {
        color: p.primary.dark,
        fontSize: s(1.5),
        padding: s(1),
        minWidth: 100,
        letterSpacing: "0.15px",
      },
    },
    tableBody: {
      backgroundColor: "#FFF",
      "& .MuiTableRow-root:hover": {
        background: ({ clickable }) => (clickable ? "#BDBDBD" : "#FFF"),
        cursor: "pointer",
      },
    },
    memberships: {
      display: "flex",
      flexDirection: "column",
    },
    membershipItem: {
      "& span": {
        color: "#828282",
      },
    },
  })
);

export interface SearchedMemberProps {
  requesterMembers: MembersResponseSubObject[];
  formKey: string;
  getMembershipOptions: (membership: MembersResponseSubObject) => {
    label: string;
    value: string | undefined;
  }[];
  onClickPatient: (option: string) => void;
  disableForm: boolean;
}

export const SearchedPatients: React.FC<SearchedMemberProps> = ({
  requesterMembers,
  formKey,
  getMembershipOptions,
  onClickPatient,
  disableForm,
}) => {
  const classes = useStyles({ clickable: !!onClickPatient });

  return (
    <div className={classes.nextOfKinContainer}>
      {requesterMembers.map((membership) => (
        <div
          key={membership.subscriptionID}
          className={classes.sectionContainer}
        >
          <Typography variant="body1" color="primary" className={classes.title}>
            Membership #{membership.mainBeneficiaryMembershipNo}
          </Typography>
          <Radios
            name={`${formKey}.membership`}
            onChange={onClickPatient}
            options={getMembershipOptions(membership)}
            disabled={disableForm}
          />
        </div>
      ))}
    </div>
  );
};
