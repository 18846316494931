import { Grid, IconButton, makeStyles, Theme } from "@material-ui/core";
import { AddBox, Send } from "@material-ui/icons";
import { getFileType } from "call/helpers";
import { useCall } from "call/hooks";
import { bytesToMegaBytes } from "helpers/file";
import React, {
  ChangeEvent,
  ChangeEventHandler,
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { ChatMessageComponent } from "./ChatMessageComponent";

interface StyleProps {
  disableInput: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(({ spacing: s }) => ({
  wrapper: {
    display: "flex",
    flexFlow: "column nowrap",
    height: "100%",
    flex: 1,
  },
  messages: {
    flex: 1,
    overflow: "hidden",
  },
  actions: {
    padding: s(2),
    boxShadow: "inset 0px 1px 0px rgba(0, 0, 0, 0.12)",
  },
  add: {
    color: "#828282",
  },
  addWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  input: {
    border: 0,
    borderLeft: "1px solid rgba(86, 92, 99, 0.12)",
    outline: 0,
    display: "block",
    paddingLeft: s(2),
    fontSize: 14,
    fontWeight: 400,
    width: "100%",
    resize: "none",
    fontFamily: "'Raleway', sans-serif",
  },
  inputWrapper: {
    flex: 1,
  },
  sendWrapper: ({ disableInput }) => ({
    background: disableInput ? "#828282" : "#08A40F",
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transformOrigin: "bottom",
    padding: s(1),
    transform: "scale(0.7)",
  }),
  send: {
    color: "white",
    transformOrigin: "center",
    transform: "rotate(-45deg)",
  },
  sendDisabled: {
    color: "white",
    transformOrigin: "center",
    transform: "rotate(-45deg)",
  },
  iconButton: {
    padding: 0,
  },
  fileElement: {
    display: "none",
  },
  label: {
    cursor: "pointer",
  },
  files: {
    height: 80,
    width: 80,
    position: "relative",
    cursor: "pointer",
    border: 0,
    outline: 0,
    background: "none",
    borderRadius: 8,
  },
  filesImage: {
    width: "100%",
    height: "100%",
    borderRadius: 8,
  },
  removeIcon: {
    color: "white",
    position: "absolute",
    top: s(1),
    right: s(1),
    cursor: "pointer",
    borderRadius: "50%",
    background: "red",
  },
  messagesWrapper: {
    width: "100%",
    height: "100%",
    overflow: "hidden auto",
    padding: s(2),
    scrollBehavior: "smooth",
  },
}));

const getMimeType = (file: File) => {
  const type = getFileType(file);
  if (type) {
    if (type.toLowerCase().includes("image/")) {
      return "media";
    }

    if (type.toLowerCase().includes("audio/")) {
      return "recording";
    }
  }
  return "document";
};

export const CallMessages = () => {
  const { chatMessages, postMessageRequest } = useCall();
  const [message, setMessage] = useState("");

  const inputRef = useRef<HTMLInputElement | null>(null);

  const messagesDomId = useMemo(() => "messages-dom-id", []);

  const handleScrollToEndOfMessages = useCallback(() => {
    const messagesNode = document.querySelector(
      `#${messagesDomId}`
    ) as HTMLDivElement;
    if (messagesNode) {
      messagesNode.scrollTop = messagesNode.scrollHeight;
    }
  }, [messagesDomId]);

  const handleSend = useCallback(() => {
    if (!message) {
      return;
    }
    postMessageRequest({
      message,
      type: "message",
    });

    setMessage("");
  }, [message, postMessageRequest]);

  const handleImport: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const files = e.target.files;

      if (!files) {
        return;
      }

      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < files.length; index++) {
        const file = files[index];

        if (bytesToMegaBytes(file.size) <= 250) {
          postMessageRequest({
            message: "",
            file,
            type: getMimeType(file),
            localUrl: URL.createObjectURL(file),
            size: file.size,
          });
        }
      }

      inputRef.current!.value = "";
    },
    [postMessageRequest]
  );

  const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  }, []);

  const disableInput = useMemo(() => !message.trim(), [message]);

  const rows = useMemo(() => {
    const numberOfRowsFromMessage = message.split(/\r\n|\r|\n/).length;

    const maxRows = 10;

    return numberOfRowsFromMessage > maxRows
      ? maxRows
      : numberOfRowsFromMessage;
  }, [message]);

  const classes = useStyles({ disableInput });

  const domId = useMemo(() => "file-element", []);

  useEffect(() => {
    handleScrollToEndOfMessages();
  }, [handleScrollToEndOfMessages, chatMessages]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.messages}>
        <div id={messagesDomId} className={classes.messagesWrapper}>
          {chatMessages.map((chatMessage) => (
            <ChatMessageComponent key={chatMessage.id} {...chatMessage} />
          ))}
        </div>
      </div>
      <div className={classes.actions}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <div className={classes.addWrapper}>
              <IconButton className={classes.iconButton}>
                <label htmlFor={domId} className={classes.label}>
                  <AddBox className={classes.add} />
                  <input
                    type="file"
                    className={classes.fileElement}
                    id={domId}
                    onChange={handleImport}
                    multiple
                    accept="*"
                    ref={inputRef}
                  />
                </label>
              </IconButton>
            </div>
          </Grid>
          <Grid item className={classes.inputWrapper}>
            <textarea
              className={classes.input}
              placeholder="Write a message ..."
              color="secondary"
              onChange={handleChange}
              value={message}
              rows={rows}
            />
          </Grid>
          <Grid item>
            <div className={classes.sendWrapper}>
              <IconButton
                className={classes.iconButton}
                disabled={disableInput}
                onClick={handleSend}
              >
                <Send fontSize="small" className={classes.send} />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
