import { FC } from "react";
import {
  Dialog,
  List,
  ListItem,
  makeStyles,
  Typography,
  Divider,
} from "@material-ui/core";
import { PatientData } from "call/components/CallForms/RequesterAndPatientForm/types";

const useDialogStyles = makeStyles(({ spacing: s }) => ({
  dialog: {
    "& .MuiDialog-paper": {
      width: "100%",
      maxWidth: 650,
      borderRadius: 8,
      padding: s(3, 2),
      boxShadow:
        "0px 10px 14px rgba(84, 134, 199, 0.12), 0px 22px 35px rgba(84, 134, 199, 0.15), 0px 8px 42px rgba(84, 134, 199, 0.12)",
    },
    "& ul": {
      paddingLeft: s(2),
      marginTop: s(1),
      marginBottom: s(1),
    },
    "& li": {
      paddingBottom: s(1),
    },
  },
  title: {
    fontFamily: "'Roboto', 'Raleway', sans-serif",
  },
  divider: {
    margin: s(2, 0),
  },
}));

interface Props {
  patients: PatientData[];
  onClose: () => void;
  open: boolean;
}

export const ContactRequester: FC<Props> = ({ open, onClose, patients }) => {
  const classes = useDialogStyles();

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog}>
      <div className="">
        <Typography color="primary" variant="h5" className={classes.title}>
          Some patients are not members.
        </Typography>
        <Typography color="primary" variant="h5" className={classes.title}>
          Ask the requester if he is willing to pay for the service:
        </Typography>
        <ul>
          <li>
            If he is willing to pay then you can continue normally with the
            ticket
          </li>
          <li>
            If he is not willing to pay for some of the patients you can move
            them to another ticket and update its service to be rendered to
            informational support
          </li>
          <li>
            If all of patients will receive informational support then update
            service to be rendered to informational support and the ticket will
            be closed
          </li>
        </ul>
        <Divider className={classes.divider} />
        <Typography color="primary" variant="h5" className={classes.title}>
          Patients
        </Typography>
        <List>
          {patients.map((patient) => (
            <ListItem
              key={`${patient.email}-${patient.phone}`}
            >{`${patient.firstName} ${patient.lastName}`}</ListItem>
          ))}
        </List>
      </div>
    </Dialog>
  );
};
