import React, { useCallback } from "react";
import { FieldProps } from "formik";
import { FormHelperText } from "@material-ui/core";
import { CountrySelectInput } from "@deep-consulting-solutions/dcs-web-ui";

export type FormikCountrySelectInputProps = FieldProps & {
  onCountryChange: (selectedCountry: string) => any;
};

export const FormikCountrySelectInput: React.FC<
  FormikCountrySelectInputProps
> = ({ field, form, onCountryChange, ...props }) => {
  const { name } = field;
  const { errors, touched, setFieldValue } = form;
  const error = touched[name] && errors[name];

  const onCountryChanged = useCallback(
    (selectedCountry: string) => {
      setFieldValue(name, selectedCountry);
    },
    [setFieldValue, name]
  );

  return (
    <div>
      <CountrySelectInput
        error={!!error}
        {...field}
        {...props}
        onChange={onCountryChange || onCountryChanged}
      />
      <FormHelperText error variant="filled">
        {error || ""}
      </FormHelperText>
    </div>
  );
};
