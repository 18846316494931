import React, {
  useState,
  useEffect,
  useCallback,
  createContext,
  PropsWithChildren,
} from "react";
import { getPopup } from "./Popup";

type MapType = google.maps.Map | undefined;

export interface Popup extends google.maps.OverlayView {
  hide: () => void;
  show: () => void;
  toggle: () => void;
  position: google.maps.LatLng;
  update: (position: google.maps.LatLng, element: HTMLElement) => void;
}

interface MapContextInterface {
  map: MapType;
  listener: string;
  fitBounds: () => void;
  closePopup: () => void;
  setMapZoom: (zoom: number) => void;
  deleteMarker: (title: string) => void;
  removePolyline: (title: string) => void;
  removeListenerFromMap: (eventName: string) => void;
  getMarker: (title: string) => google.maps.Marker | undefined;
  createDestinationMarker: (
    options: google.maps.MarkerOptions
  ) => google.maps.Marker;
  createInitialLocationMarker: (
    options: google.maps.MarkerOptions
  ) => google.maps.Marker;
  addListenerToMap: (
    eventName: string,
    handler: (...args: any[]) => void
  ) => void;
  createMap: (
    mapDiv: HTMLDivElement,
    opts: google.maps.MapOptions
  ) => google.maps.Map;
  createMarker: (
    options: google.maps.MarkerOptions & { title: string }
  ) => google.maps.Marker;
  updateMarkerPosition: (
    markerTitle: string,
    position: google.maps.LatLngLiteral
  ) => void;
  createInfoWindow: (
    options: google.maps.InfoWindowOptions
  ) => google.maps.InfoWindow;
  createPolyline: (
    title: string,
    path: google.maps.LatLngLiteral[]
  ) => google.maps.Polyline;
  createPopup: (
    position: google.maps.LatLng,
    element: HTMLElement
  ) => Popup | null;
  updatePopup: (
    position: google.maps.LatLng,
    element: HTMLElement
  ) => Popup | null;
  fetchPopup: () => Popup | null;
}

export const destinationMarkerTitle = "destination";
export const initialLocationMarkerTitle = "initialLocation";

const MapContext = createContext<MapContextInterface | null>(null);

export const getIcon = (fillColor: string) => ({
  fillColor,
  scale: 1.5,
  rotation: 0,
  fillOpacity: 1,
  strokeWeight: 1,
  anchor: new google.maps.Point(15, 30),
  path: "M16.0013 2.66699C10.8413 2.66699 6.66797 6.84033 6.66797 12.0003C6.66797 19.0003 16.0013 29.3337 16.0013 29.3337C16.0013 29.3337 25.3346 19.0003 25.3346 12.0003C25.3346 6.84033 21.1613 2.66699 16.0013 2.66699ZM16.0013 15.3337C14.1613 15.3337 12.668 13.8403 12.668 12.0003C12.668 10.1603 14.1613 8.66699 16.0013 8.66699C17.8413 8.66699 19.3346 10.1603 19.3346 12.0003C19.3346 13.8403 17.8413 15.3337 16.0013 15.3337Z",
});

const MapProvider = ({ children }: PropsWithChildren<object>) => {
  const [listener, setListener] = useState("");
  const [map, setMap] = useState<MapType>(undefined);
  const [popup, setPopup] = useState<Popup | null>(null);
  const [markers, setMarkers] = useState<google.maps.Marker[]>([]);
  const [polylines, setPolylines] = useState<
    Record<string, google.maps.Polyline>
  >({});

  const createMap = useCallback(
    (element: HTMLDivElement, options: google.maps.MapOptions) => {
      if (map) {
        markers.forEach((marker) => {
          google.maps.event.clearInstanceListeners(marker);
          marker.setMap(null);
        });

        setMarkers([]);

        map.setOptions(null);
        google.maps.event.clearInstanceListeners(map);
      }

      const newMap = new google.maps.Map(element, options);
      (window as any).map = newMap;

      setMap(newMap);
      return newMap;
    },
    [map, markers]
  );

  const getMarker = useCallback(
    (title: string) => {
      return markers.find((marker) => marker.getTitle() === title);
    },
    [markers]
  );

  const createMarker = useCallback(
    ({ title, ...options }: google.maps.MarkerOptions & { title: string }) => {
      const existingMarker = getMarker(title);

      if (existingMarker) {
        if (map) existingMarker.setMap(map);
        existingMarker.setOptions(options);
        return existingMarker;
      }

      const marker = new google.maps.Marker({ map, title, ...options });
      setMarkers((prevMarkers) => [...prevMarkers, marker]);
      return marker;
    },
    [map, getMarker]
  );

  const createInitialLocationMarker = useCallback(
    (options: google.maps.MarkerOptions) => {
      return createMarker({
        ...options,
        icon: getIcon("#CA2027"),
        title: initialLocationMarkerTitle,
      });
    },
    [createMarker]
  );

  const createDestinationMarker = useCallback(
    (options: google.maps.MarkerOptions) => {
      return createMarker({
        ...options,
        icon: getIcon("#08A40F"),
        title: destinationMarkerTitle,
      });
    },
    [createMarker]
  );

  const fitBounds = useCallback(() => {
    // position map to show all available markers.
    if (map) {
      const bounds = new google.maps.LatLngBounds();

      markers.forEach((marker) => {
        const position = marker.getPosition();
        if (position) bounds.extend(position);
      });

      map.fitBounds(bounds);
    }
  }, [map, markers]);

  const updateMarkerPosition = useCallback(
    (markerTitle: string, position: google.maps.LatLngLiteral) => {
      const marker = getMarker(markerTitle);

      if (marker) {
        marker.setPosition(position);

        if (map) {
          marker.setMap(map);
          map.panTo(position);
        }
      }
    },
    [map, getMarker]
  );

  const deleteMarker = useCallback(
    (title: string) => {
      const marker = getMarker(title);

      if (marker) {
        marker.setMap(null);
      }
    },
    [getMarker]
  );

  const removeListenerFromMap = useCallback(
    (eventName: string) => {
      if (map) {
        google.maps.event.clearListeners(map, eventName);
        setListener("");
      }
    },
    [map]
  );

  const addListenerToMap = useCallback(
    (eventName: string, handler: (...args: any[]) => void) => {
      const [event, owner] = eventName.split("_");

      removeListenerFromMap(event);

      if (map) {
        setListener(owner);
        map.addListener(event, handler);
      }
    },
    [map, removeListenerFromMap]
  );

  const setMapZoom = useCallback(
    (zoom: number) => {
      map?.setZoom(zoom);
    },
    [map]
  );

  const createInfoWindow = useCallback(
    (options: google.maps.InfoWindowOptions) =>
      new window.google.maps.InfoWindow(options),
    []
  );

  const createPolyline = useCallback(
    (title: string, path: google.maps.LatLngLiteral[]) => {
      if (map) {
        if (polylines[title]) {
          polylines[title].setMap(map);
          polylines[title].setPath(path);
          return polylines[title];
        }

        const polyline = new google.maps.Polyline({
          map,
          path,
          strokeColor: "#192954",
          strokeOpacity: 1,
          strokeWeight: 3,
        });

        setPolylines((prevPolylines) => ({
          ...prevPolylines,
          [title]: polyline,
        }));
        return polyline;
      }

      throw new Error("Please create a map before creating a polyline.");
    },
    [map, polylines]
  );

  const removePolyline = useCallback(
    (title: string) => {
      const polyline = polylines[title];

      if (polyline) {
        polyline.setMap(null);
        polyline.setPath([]);
      }
    },
    [polylines]
  );

  const closePopup = useCallback(() => {
    if (popup) {
      popup.hide();
      popup.onRemove();
      popup.setMap(null);
      setPopup(null);
    }
  }, [popup]);

  const createPopup = useCallback(
    (position: google.maps.LatLng, element: HTMLElement) => {
      const PClass = getPopup();

      if (PClass) {
        closePopup();

        const newPopup = new PClass(position, element);
        newPopup.show();
        setPopup(newPopup);
        return newPopup;
      }

      return null;
    },
    [closePopup]
  );

  const updatePopup = useCallback(
    (position: google.maps.LatLng, element: HTMLElement) => {
      if (popup) {
        popup.update(position, element);
        return popup;
      }

      return null;
    },
    [popup]
  );

  const fetchPopup = useCallback(() => popup, [popup]);

  useEffect(() => {
    if (!popup) {
      const newPopup = createPopup(
        new google.maps.LatLng(0, 0),
        document.createElement("div")
      );

      newPopup?.hide();
      newPopup?.setMap(map || null);
      setPopup(newPopup);
    }
  }, [map, popup, createPopup]);

  return (
    <MapContext.Provider
      value={{
        map,
        listener,
        createMap,
        getMarker,
        fitBounds,
        setMapZoom,
        closePopup,
        fetchPopup,
        createPopup,
        updatePopup,
        createMarker,
        deleteMarker,
        createPolyline,
        removePolyline,
        addListenerToMap,
        createInfoWindow,
        updateMarkerPosition,
        removeListenerFromMap,
        createDestinationMarker,
        createInitialLocationMarker,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

const useMap = () => {
  const context = React.useContext(MapContext);

  if (context === null) {
    throw new Error("useMap must be used within a MapProvider");
  }

  return context;
};

export { MapProvider, useMap };
