import { createContext } from "react";
import { CallContextInterface } from "../types";

export const DashboardMockCallContext = createContext<CallContextInterface>({
  viewCall: () => {},
  updateCallRequesterGeoLocation: () => {},
  updateCallRequesterInitialAssessment: () => {},
  updateCallServiceCase: () => {},
  updateCall: () => {},
});
