import {
  PatientResponseWithContact,
  ServiceTypesEnum,
  SubscriptionStatusEnum,
} from "@deep-consulting-solutions/be2-constants";
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import { PatientCardRow } from "./PatientCardRow";

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  tableContainer: {
    boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",
    border: "1px solid #E0E0E0",
    borderRadius: 4,
  },
  tableRow: {
    padding: s(1),
  },
  tableHead: {
    background: "rgba(236, 236, 236, 1)",
  },
  wrapper: {
    background: "#FFFFFF",
    boxShadow: " 0px 3px 5px rgba(0, 0, 0, 0.12)",
    borderRadius: 8,
    padding: s(2),
    marginBottom: s(4),
  },
  title: {
    fontSize: 20,
  },
  collapse: {
    padding: s(2),
  },
  subtitle: {
    fontSize: 18,
    color: "#4F4F4F",
    marginBottom: s(2),
  },
  tableCellTitle: {
    fontWeight: 500,
    color: p.primary.dark,
    textAlign: "center",
    display: "block",
    fontSize: 12,
  },
}));

interface Props {
  patient: PatientResponseWithContact;
  handleOpenPaymentDialog: (values: {
    subscriptionForPayment: string;
    subscriptionContact: string;
  }) => void;
  selectedCoverage?: string;
  handleCoverageChange: (coverageId: string, coverageStatus: string) => void;
  service: ServiceTypesEnum;
  disableForm?: boolean;
}

export const PatientCoverage = ({
  patient,
  handleOpenPaymentDialog,
  selectedCoverage,
  handleCoverageChange,
  service,
  disableForm = false,
}: Props) => {
  const { coverages, contactID } = useMemo(() => {
    return {
      coverages: patient.subCoverage,
      id: patient.patientID,
      contactID: patient.contact.zohoID,
    };
  }, [patient.patientID, patient.subCoverage, patient.contact.zohoID]);

  const tableHeaders = useMemo(() => {
    const list = [
      " ",
      "Membership",
      "Type",
      "Subscription Status",
      "Plans",
      "Coverage",
    ];

    if (
      coverages.some(
        (c) => c.subscription?.status === SubscriptionStatusEnum.SUSPENDED
      )
    ) {
      list.push(" ");
    }

    return list;
  }, [coverages]);

  const handlePayment = useCallback(
    (subscriptionID: string) => {
      handleOpenPaymentDialog({
        subscriptionForPayment: subscriptionID,
        subscriptionContact: contactID,
      });
    },
    [handleOpenPaymentDialog, contactID]
  );

  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow className={classes.tableRow}>
            {tableHeaders.map((header, index) => (
              <TableCell
                size={header === " " ? "small" : undefined}
                key={header === " " ? String(index) : header}
              >
                <Typography
                  variant="caption"
                  className={classes.tableCellTitle}
                  align="center"
                >
                  {header}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {coverages.map((coverage) =>
            coverage ? (
              <PatientCardRow
                coverage={coverage}
                key={coverage.id}
                handlePayment={handlePayment}
                selectedCoverage={selectedCoverage}
                handleCoverageChange={handleCoverageChange}
                service={service}
                disableForm={disableForm}
              />
            ) : null
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
