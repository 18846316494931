import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { FC } from "react";
import { DialogProps } from "@material-ui/core/Dialog/Dialog";
import clsx from "clsx";
import Loader from "component/Loader";
import { Field, FormikProvider, useFormik } from "formik";
import { CloseOutlined } from "@material-ui/icons";
import { useDialogStyles } from "component/Dialogs/styles";
import DCSPhoneInput from "component/atoms/DCSPhoneInput";
import { FormikDatePicker } from "@deep-consulting-solutions/dcs-web-ui";
import { createUnansweredServiceRequest } from "component/Dialogs/request";
import {
  ServiceCaseRequestTypeEnum,
  ServiceCaseStatusEnum,
} from "@deep-consulting-solutions/be2-constants";
import * as yup from "yup";
import { getENText, VALIDATIONS } from "helpers";
import { notifications } from "services";
import { useDispatch } from "react-redux";
import { dispatcherActions } from "redux/dispatcher";

const dateTypingFormat = "dd/MM/yyyy HH:mm";
const dateDisplayFormat = "dd MMM yyyy p";

const useStyle = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      width: "100%",
      maxWidth: 700,
      borderRadius: "4px 4px 16px 16px",
    },
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

export const CreateUnansweredServiceRequest: FC<DialogProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyle();
  const baseClasses = useDialogStyles();

  const dispatch = useDispatch();

  const { setServiceCaseUpdated } = dispatcherActions;

  const formik = useFormik({
    initialValues: {
      caseStatus: ServiceCaseStatusEnum.UNANSWERED,
      requesterPhone: "",
      unansweredRequestTime: "",
      situationDescription: "",
      requestType: ServiceCaseRequestTypeEnum.EMERGENCY,
    },
    validationSchema: yup.object({
      requesterPhone: VALIDATIONS.phone,
      unansweredRequestTime: yup
        .date()
        .typeError(getENText("validation.unansweredRequestTime.required"))
        .max(new Date())
        .nullable()
        .required(getENText("validation.unansweredRequestTime.required")),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const res = await createUnansweredServiceRequest(values);
      dispatch(setServiceCaseUpdated());
      notifications.notifySuccess(
        `Unanswered Request Created for Service Case ${res.data.caseNumber}`
      );
      setSubmitting(false);
      resetForm();
      if (onClose) onClose({}, "escapeKeyDown");
    },
  });

  const handleClose = () => {
    formik.resetForm();
    if (onClose) onClose({}, "escapeKeyDown");
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={clsx(classes.dialog, baseClasses.dialog)}
    >
      <Loader open={formik.isSubmitting} absolute />
      <FormikProvider value={formik}>
        <div className={baseClasses.dialogTitle}>
          <div>
            <Typography
              color="primary"
              variant="body1"
              style={{ fontSize: 18 }}
            >
              Create Unanswered Request
            </Typography>
            <Typography color="primary" variant="body2">
              External Phone Unanswered Request
            </Typography>
          </div>
          <IconButton size="small" onClick={onClose as any}>
            <CloseOutlined style={{ fontSize: 20, color: "#BDBDBD" }} />
          </IconButton>
        </div>
        <DialogContent>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography color="primary" style={{ marginBottom: 10 }}>
                  Date and time of the Unanswered Call
                </Typography>
                <Field
                  component={FormikDatePicker}
                  size="small"
                  name="unansweredRequestTime"
                  label="Date and Time"
                  typingFormat={dateTypingFormat}
                  displayFormat={dateDisplayFormat}
                  placeholder="DD/MM/YYYY"
                  views={["date", "hours", "minutes"]}
                  disableFuture
                  disablePast={false}
                  maxDateMessage="Date cannot be in the future"
                />
              </Grid>
              <Grid item xs={6}>
                <Typography color="primary" style={{ marginBottom: 10 }}>
                  Contact Number
                </Typography>
                <Field
                  component={DCSPhoneInput}
                  name="requesterPhone"
                  label="Phone Number"
                  size="small"
                  required
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions style={{ padding: 24 }}>
          <Button
            color="primary"
            type="submit"
            onClick={formik.handleSubmit as any}
          >
            Create
          </Button>
        </DialogActions>
      </FormikProvider>
    </Dialog>
  );
};
