import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { Box, Button, Typography } from "@material-ui/core";
import {
  CancelButton,
  CardContent,
} from "pages/FulfilmentDashboard/components";
import {
  GetServiceCase,
  PatientCoverageEnum,
  PostReleaseServiceCAsePatients,
  PutUpdateServiceCaseStatus,
  ServiceCaseRequestTypeEnum,
  ServiceCaseResponse,
  ServiceCaseStatusEnum,
} from "@deep-consulting-solutions/be2-constants";
import * as yup from "yup";

import Loader from "component/Loader";
import { crmClient, getClient } from "apis";
import {
  cancelServiceCase,
  getServiceCaseZohoCRMUrl,
} from "redux/serviceCase/requests";
import { CaseClassificationsContext } from "context/CaseClassificationProvider";
import { reduceReceivingPhysicianErr } from "pages/FulfilmentDashboard/helpers";

import PatientReleasedCard from "../../../../components/PatientReleasedCard";
import { CancelService } from "../../../../components/CancelService";
import { ConfirmationDialog } from "../../../../Dialog/ConfirmationDialog";
import { RenderStatus } from "./RenderStatus";
import { getContentData, handleStepper } from "./helpers";
import { ContentCard } from "./ContentCard";

type CasesType = Array<{
  status:
    | ServiceCaseStatusEnum.PATIENT_RELEASED
    | ServiceCaseStatusEnum.COMPLETED
    | ServiceCaseStatusEnum.SERVICE_STARTED;
  checked: boolean;
  disabled: boolean;
  state: ServiceCaseResponse;
}>;

interface Option {
  label: string;
  value: string;
}

interface CancelChangesState {
  cancelForAllPatients: boolean;
  reasonForCancelling: string;
  patientIds: Option[];
}

interface RefundsData {
  patientId: string;
  refundCredit: boolean;
}

const initialCancelChangesState: CancelChangesState = {
  cancelForAllPatients: false,
  reasonForCancelling: "",
  patientIds: [],
};

const cancelChangesSchema = yup.object().shape({
  cancelForAllPatients: yup.boolean(),
  reasonForCancelling: yup.string().required(),
  patientIds: yup.array().of(yup.string().required()),
});

export const ProgressRendering = () => {
  const { state, updateState } = useContext(CaseClassificationsContext);
  const [cases, setCases] = useState<CasesType>([]);

  const [loading, setLoading] = useState(false);

  const startServiceCase = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getClient(true).put<
        typeof PutUpdateServiceCaseStatus.Res
      >(PutUpdateServiceCaseStatus.ROUTE.replace(":id", state?.id as string), {
        caseStatus: ServiceCaseStatusEnum.SERVICE_STARTED,
      });
      updateState(res.data.data);
    } finally {
      setLoading(false);
    }
  }, [state?.id, updateState]);

  const [completeDialog, setShowCompleteDialog] = useState(false);
  const [showCancelService, setShowCancelService] = useState(false);

  const [cancelLoading, setCancelLoading] = useState(false);
  const [patientReleaseIsLoading, setPatientReleaseIsLoading] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);

  const [showPatientReleased, setShowPatientReleased] =
    useState<Partial<ServiceCaseResponse> | null>(null);

  const [patientReleaseCard, setPatientReleaseCard] = useState<
    { id: string; firstName: string; lastName: string; title: string }[]
  >([]);
  const [pHasErr, setPHasErr] = useState<Record<number, any>>({});
  const [cHasErr, setCHasErr] = useState<Record<string, any>>({});

  const [cancelChangesState, setCancelChangesState] =
    useState<CancelChangesState>(initialCancelChangesState);

  const handleRevertCheck = useCallback(
    (status: ServiceCaseStatusEnum) => {
      const newCases = cases.map((e) => {
        if (e.status === status) {
          return { ...e, checked: !e.checked };
        }
        return e;
      });
      setCases(newCases);
      setShowPatientReleased(null);
    },
    [cases]
  );

  const checker = useCallback(
    (idx: number, checked: boolean) => {
      setCases(
        cases.map((e, i) => {
          if (i === idx) {
            return { ...e, checked };
          }
          return e;
        })
      );
    },
    [cases]
  );

  const handlePatientReleaseSend = useCallback(() => {
    const schema = yup.array().of(
      yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        title: yup.string().required(),
      })
    );

    schema
      .validate(patientReleaseCard)
      .then((value) => {
        if (value) {
          (async () => {
            if (!state) return;
            try {
              setPatientReleaseIsLoading(true);
              const patients = (
                value.concat(
                  state.patients
                    .filter((patient) => !!patient.serviceCancelled)
                    .map((p) => ({
                      id: p.patientID,
                      firstName: "",
                      lastName: "",
                      title: "",
                    }))
                ) as {
                  id: string;
                  firstName: string;
                  lastName: string;
                  title: string;
                }[]
              ).map(({ firstName, lastName, title, id }) => ({
                id,
                physicianFirstName: firstName,
                physicianLastName: lastName,
                physicianTitle: title,
              }));
              const res = await crmClient.post<
                typeof PostReleaseServiceCAsePatients.Res
              >(PostReleaseServiceCAsePatients.ROUTE.replace(":id", state.id), {
                patients,
              });
              setPatientReleaseIsLoading(false);
              updateState(res.data.data.serviceCase);
              setShowPatientReleased(null);
            } catch (e) {
              checker(1, false);
            } finally {
              setPatientReleaseIsLoading(false);
            }
          })();
        }
      })
      .catch((e) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const err = e.value.reduce(reduceReceivingPhysicianErr, {}) as Record<
          number,
          any
        >;
        setPHasErr(err);
      });
  }, [patientReleaseCard, state, updateState, checker]);

  const handleCaseComplete = () => {
    (async () => {
      try {
        setConfirmationLoading(true);
        const isPut =
          state?.requestType !== ServiceCaseRequestTypeEnum.INFORMATION_SUPPORT;
        const route = isPut
          ? PutUpdateServiceCaseStatus.ROUTE
          : "/service-cases/:id/complete-informational-case";

        const res = await getClient(true)[isPut ? "put" : "patch"]<
          typeof PutUpdateServiceCaseStatus.Res
        >(route.replace(":id", state?.id as string), {
          caseStatus: ServiceCaseStatusEnum.COMPLETED,
          requestType: state?.requestType,
          situationDescription: state?.situationDescription,
        } as typeof PutUpdateServiceCaseStatus.Body);
        checker(2, true);
        setShowCompleteDialog(false);
        updateState(res.data.data);
      } catch (e) {
        checker(2, false);
      } finally {
        setConfirmationLoading(false);
      }
    })();
  };

  const [refundStatus, setRefundStatus] = useState<Record<string, boolean>>({});

  const handleCancelServiceForPatient = async (id: string) => {
    if (state?.patients?.length) {
      setCHasErr({});

      try {
        cancelChangesSchema.validateSync(cancelChangesState);
        setCancelLoading(true);

        try {
          let patientsRefundData: RefundsData[] = [];
          const refundStatusEntries = Object.entries(refundStatus);

          if (state.patients.length === 1) {
            const [patient] = refundStatusEntries;

            patientsRefundData = [
              { patientId: patient[0], refundCredit: patient[1] },
            ];
          } else {
            patientsRefundData = refundStatusEntries.reduce(
              (acc, [key, value]) => {
                if (
                  cancelChangesState.patientIds.find(
                    (patient) => patient.value === key
                  )
                ) {
                  return [
                    ...acc,
                    {
                      patientId: key,
                      refundCredit: value,
                    },
                  ];
                }

                return acc;
              },
              [] as RefundsData[]
            );
          }

          const serviceCase = await cancelServiceCase(id, {
            patientsRefundData,
            reason: cancelChangesState.reasonForCancelling,
          });

          setCancelLoading(false);
          updateState(serviceCase);
          setShowCancelService(false);
          setCancelChangesState(initialCancelChangesState);
        } catch (err) {
          setCancelLoading(false);
        }
      } catch (err) {
        const obj: Record<string, any> = {};
        if (!cancelChangesState.reasonForCancelling) {
          obj.reasonForCancelling = "Reason for Cancelling is required";
        }
        if (!cancelChangesState.patientIds.length) {
          obj.patientIds = "Reason for Cancelling is required";
        }
        setCHasErr(obj);
      }
    }
  };

  const handleClick = (idx: number) => (checked: boolean) => {
    if (idx === 1) {
      checker(idx, checked);
      if (checked) setShowPatientReleased(state);
    }
    if (idx === 2) {
      setShowCompleteDialog(true);
    }
  };

  const handleCancelCompleteCase = () => {
    checker(2, false);
    setConfirmationLoading(false);
    setShowCompleteDialog(false);
  };

  const { coveredPatients, uncoveredPatients, cancellablePatients } =
    useMemo(() => {
      const initialValues: Record<string, ServiceCaseResponse["patients"]> = {
        coveredPatients: [],
        uncoveredPatients: [],
        cancellablePatients: [],
      };

      if (!state?.patients) return initialValues;

      const baseRefundStatus: Record<string, boolean> = {};

      const reducedPatients = state.patients.reduce((acc, cur) => {
        baseRefundStatus[cur.patientID] = false;

        if (!cur.serviceCancelled) {
          acc.cancellablePatients.push(cur);
        }

        if (
          !cancelChangesState.patientIds.find(
            (patient) => patient.value === cur.patientID
          )
        ) {
          return acc;
        }

        if (
          cur.selectedSubsCoverage?.coverage === PatientCoverageEnum.COVERED
        ) {
          return {
            ...acc,
            coveredPatients: [...acc.coveredPatients, cur],
          };
        }

        return {
          ...acc,
          uncoveredPatients: [...acc.uncoveredPatients, cur],
        };
      }, initialValues);

      setRefundStatus(baseRefundStatus);

      return reducedPatients;
    }, [state?.patients, cancelChangesState.patientIds]);

  const handleCheckboxChange = useCallback(
    (checked: boolean, patientID: string) => {
      setRefundStatus((prev) => ({
        ...prev,
        [patientID]: checked,
      }));
    },
    []
  );

  const cancelChangesIsValid = useMemo(() => {
    try {
      cancelChangesSchema.validateSync(cancelChangesState);
      return true;
    } catch {
      return false;
    }
  }, [cancelChangesState]);

  const [zohoCRMUrl, setZohoCRMUrl] = useState("");

  useEffect(() => {
    (async () => {
      if (!state?.id) return;

      try {
        const { url } = await getServiceCaseZohoCRMUrl(state.id);
        if (typeof url !== "string") return;

        setZohoCRMUrl(url);
      } catch {
        //
      }
    })();
  }, [state?.id]);

  useEffect(() => {
    if (state) {
      setCases(handleStepper(state) as CasesType);
    }
  }, [state]);

  useEffect(
    function getLatestServiceCaseData() {
      if (state?.id) {
        (async () => {
          try {
            setLoading(true);
            const res = await getClient(true).get<typeof GetServiceCase.Res>(
              GetServiceCase.ROUTE.replace(":id", state?.id)
            );
            handleStepper(res.data.data);
            updateState(res.data.data);
          } finally {
            setLoading(false);
          }
        })();
      }
    },
    [state?.id, updateState]
  );

  return (
    <>
      <Loader open={loading} />
      <ConfirmationDialog
        open={!!showPatientReleased}
        onClose={() => {
          setPatientReleaseIsLoading(false);
          checker(1, false);
          setShowPatientReleased(null);
        }}
        onConfirm={handlePatientReleaseSend}
        rightBtnProps={{ disabled: !showPatientReleased?.patients?.length }}
      >
        <PatientReleasedCard
          loading={patientReleaseIsLoading}
          errors={pHasErr}
          data={patientReleaseCard}
          onChange={(r) => {
            setPHasErr({});
            setPatientReleaseCard(r);
          }}
          patients={showPatientReleased?.patients}
          zohoCRMUrl={zohoCRMUrl}
        />
      </ConfirmationDialog>
      <ConfirmationDialog
        open={completeDialog}
        onClose={handleCancelCompleteCase}
        onConfirm={handleCaseComplete}
      >
        <Loader absolute open={confirmationLoading} />
        <Typography
          color="primary"
          style={{
            fontWeight: 700,
            fontSize: 24,
          }}
        >
          Confirm Completion of Service
        </Typography>
      </ConfirmationDialog>

      <ConfirmationDialog
        maxWidth={514}
        title="Cancel Case"
        open={showCancelService}
        onCancelText={
          cancellablePatients?.length > 1
            ? "Don’t Cancel"
            : "Don’t Cancel the Service"
        }
        onConfirmText={
          cancellablePatients?.length > 1
            ? "Cancel Service for these Patients"
            : "Cancel Service"
        }
        rightBtnProps={{
          color: "secondary",
          disabled: !(
            (state?.patients.length === 1 ||
              !!cancelChangesState.patientIds.length) &&
            cancelChangesIsValid
          ),
        }}
        onClose={() => {
          if (!loading && !cancelLoading) {
            handleRevertCheck(ServiceCaseStatusEnum.COMPLETED);
          }
          setShowCancelService(false);
        }}
        onConfirm={() => {
          handleCancelServiceForPatient(state?.id as string);
        }}
        hideBox
      >
        <Loader absolute open={cancelLoading} />
        <CancelService
          patients={state?.patients}
          status={state?.caseStatus as ServiceCaseStatusEnum}
          state={cancelChangesState}
          onChange={(data) => {
            setCHasErr({});
            setCancelChangesState(data as unknown as typeof cancelChangesState);
          }}
          errors={cHasErr}
          refundStatus={refundStatus}
          coveredPatients={coveredPatients}
          uncoveredPatients={uncoveredPatients}
          cancellablePatients={cancellablePatients}
          handleCheckboxChange={handleCheckboxChange}
        />
      </ConfirmationDialog>

      {state && Object.keys(state).length ? (
        <>
          <Box mt={4}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                {[
                  ServiceCaseStatusEnum.SERVICE_STARTED,
                  ServiceCaseStatusEnum.PATIENT_RELEASED,
                  ServiceCaseStatusEnum.SERVICE_SCHEDULED,
                ].includes(state?.caseStatus) && (
                  <>
                    <Typography color="primary">Case Status:</Typography>
                    <RenderStatus status={state?.caseStatus as string} />{" "}
                  </>
                )}
              </Box>

              <Box>
                {[
                  ServiceCaseStatusEnum.SERVICE_STARTED,
                  ServiceCaseStatusEnum.PATIENT_RELEASED,
                  ServiceCaseStatusEnum.SERVICE_SCHEDULED,
                ].includes(state.caseStatus) && (
                  <CancelButton
                    style={{ position: "relative" }}
                    onClick={() => {
                      setShowCancelService(true);
                    }}
                  >
                    Cancel Service
                  </CancelButton>
                )}

                {state.caseStatus ===
                  ServiceCaseStatusEnum.SERVICE_SCHEDULED && (
                  <Button
                    style={{ position: "relative", marginLeft: "16px" }}
                    color="primary"
                    onClick={() => {
                      startServiceCase();
                    }}
                  >
                    Start Service
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            mt={4}
            style={{
              pointerEvents: loading ? "none" : "auto",
            }}
          >
            <CardContent ml={6} position="relative">
              <Loader open={loading} absolute />
              {cases.map(({ status, state: iState, ...rest }, i, arr) => {
                return (
                  <ContentCard
                    key={`${i}`.toString()}
                    location={getContentData(status, iState).address}
                    label={status}
                    updatedOn={getContentData(status, iState).updateOn}
                    geoLocation={getContentData(status, iState).location}
                    {...rest}
                    lastElem={i === arr.length - 1}
                    onClick={handleClick(i)}
                  />
                );
              })}
            </CardContent>
          </Box>
        </>
      ) : null}
    </>
  );
};
