import { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
  Box,
  Card,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  SortOrderEnum,
  SocketEventsEnum,
  ServiceCaseResponse,
  GetFetchServiceCases,
  ServiceCaseRequestTypeEnum,
  ServiceCaseStatusGroupEnum,
  ServiceCaseRequestOriginEnum,
} from "@deep-consulting-solutions/be2-constants";
import clsx from "clsx";
import Loader from "component/Loader/Loader";
import { DisplayModeLayout } from "layout/displayMode.layout";
import { DisplayLayout } from "redux/dispatcher";
import { useAppSelector } from "redux/store";
import { PatientTableCellRecord } from "pages/FulfilmentDashboard/components";
import { FilterBar } from "pages/FulfilmentDashboard/Views/List/FilterBar";
import { useFormik } from "formik";
import {
  cleanParamObject,
  serviceCaseNeedsUpdate,
  shouldUpdateServiceCase,
} from "pages/FulfilmentDashboard/helpers";
import {
  AssignCase,
  CloseServiceCase,
  ContactRequester,
} from "component/Dialogs";
import { Action } from "pages/FulfilmentDashboard/types";
import { ROUTES } from "component/configs";
import { pick } from "lodash";
import { useHistory } from "react-router-dom";
import { useSocket } from "hooks";
import { getServiceCase } from "redux/serviceCase/requests";
import { getServiceCasesList } from "./request";
import { RenderMap } from "../MedicalDirector/RenderMap";

const useStyles = makeStyles<Theme, { layout?: DisplayLayout | null }>(() => ({
  container: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    gap: 16,
    gridTemplateRows: ({ layout }) =>
      layout === DisplayLayout?.horizontal
        ? "auto minmax(auto, 500px)"
        : undefined,
  },
  card: {
    position: "relative",
    padding: 11,
    height: "min-content",
    maxWidth: "unset",
    minWidth: 432,
    width: "100%",
  },
  scrollable: {
    overflow: "auto",
    paddingLeft: 5,
    paddingRight: 5,
    maxHeight: "75vh",
  },
  isHorizontal: {
    display: "flex",
    gap: 16,
  },
  inputContainer: {
    position: "relative",
    display: "grid",
    width: "100%",
    gap: 10,
  },
  inputSplit: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 10,
  },
  spaceBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tabBtn: {
    border: "none",
    background: "none",
    padding: "16px 8px",
    borderBottom: "1px solid #e0e0e0",
    textTransform: "uppercase",
    color: "#666666",
  },
  innerCard: {
    padding: "4px 8px",
    borderRadius: 4,
    border: "1px solid #d9e4ea",
    marginBottom: 4,
    textTransform: "capitalize",
  },
  paginationRoot: {
    width: "100vw",
    borderBottom: "unset",
  },
  rowsPerPage: {
    flexShrink: 1,
    width: "unset",
  },
  tableContainer: {
    borderRadius: 4,
    border: "1px solid rgba(0, 0, 0, 0.15)",
    "& .MuiTableCell-root": {
      padding: "14px 8px",
      borderBottom: "unset",
    },
  },
}));

const assignNewTableData = (
  res: { data: typeof GetFetchServiceCases["Res"] },
  page: number
) => {
  return {
    serviceCases: res.data.data.serviceCases,
    total: res.data.data.total,
    perPage: res.data.data.perPage || 10,
    page,
  };
};

interface SocketEvent {
  serviceCaseId: string;
  modifiedFields: Array<keyof ServiceCaseResponse>;
}

const List = () => {
  const { displayLayout, serviceCaseUpdated } = useAppSelector(
    (state) => state.dispatcher
  );
  const [showAction, setShowAction] = useState("");

  const [pageLoaded, setPageLoaded] = useState(false);

  const [initLoading, setInitLoading] = useState(false);
  const [perPage, setPerPage] = useState(20);

  const [tableData, setTableData] = useState({
    total: 0,
    page: 0,
    perPage: 20,
    serviceCases: [] as ServiceCaseResponse[],
  });

  const classes = useStyles({ layout: displayLayout });

  const formik = useFormik({
    initialValues: {
      searchText: "",
      statusGroup: [] as ServiceCaseStatusGroupEnum[],
      order: SortOrderEnum.DESC,
      status: [],
      service: [],
      dispatcherIDs: [],
      itenerarySectionState: [],
      requestType: "" as ServiceCaseRequestTypeEnum,
      requestOrigin: "",
      pendingQuoteApproval: false,
      overdue: false,
      sortBy: "createdAt",
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const res = await getServiceCasesList(
          cleanParamObject({
            ...values,
            perPage: perPage.toString(),
            page: 0,
          })
        );
        setTableData(assignNewTableData(res, 0));
        setSubmitting(false);
      } catch (e) {
        setSubmitting(false);
      } finally {
        setPageLoaded(true);
      }
    },
  });

  const handleRowChange = useCallback(
    (e) => {
      (async () => {
        setInitLoading(true);
        try {
          const res = await getServiceCasesList({
            ...cleanParamObject(formik.values),
            perPage: e.target.value,
          });
          setInitLoading(false);
          setPerPage(+e.target.value);
          setTableData({ ...res.data.data, page: 0, perPage: e.target.value });
        } catch (err) {
          setInitLoading(false);
        }
      })();
    },
    [formik.values]
  );

  const handlePageChange = useCallback(
    (e: any, page: number) => {
      (async () => {
        setInitLoading(true);
        try {
          const res = await getServiceCasesList({
            ...cleanParamObject(formik.values),
            perPage: perPage.toString(),
            page: (1 + page).toString(),
          });
          setInitLoading(false);
          setTableData(assignNewTableData(res, page));
          setPerPage(res.data.data.perPage || 10);
        } catch (err) {
          setInitLoading(false);
        }
      })();
    },
    [formik.values, perPage]
  );

  const handleSort = useCallback(() => {
    formik.setFieldValue(
      "order",
      formik.values.order === SortOrderEnum.DESC
        ? SortOrderEnum.ASC
        : SortOrderEnum.DESC
    );
  }, [formik]);
  const handleIsOverdue = useCallback(
    (e: ChangeEvent<any>) => {
      formik.setFieldValue("overdue", e.target.checked);
      formik.handleSubmit();
    },
    [formik]
  );
  const handleIsPendingApproval = useCallback(
    (e: ChangeEvent<any>) => {
      formik.setFieldValue("pendingQuoteApproval", e.target.checked);
      formik.handleSubmit();
    },
    [formik]
  );

  const updateState = useCallback(
    ({ name, value: v }: { name: string; value: any }) => {
      formik.setFieldValue(name, v);
    },
    [formik]
  );

  const [modalData, setModalData] = useState<ServiceCaseResponse | null>(null);

  const handleUpdate = useCallback((data: ServiceCaseResponse) => {
    setTableData((state) => ({
      ...state,
      serviceCases: state.serviceCases.map((el) => {
        if (el.id === data.id) return { ...el, ...data };
        return el;
      }),
    }));
  }, []);

  const history = useHistory();

  const handleAction = useCallback(
    (action: Action, serviceCase: ServiceCaseResponse) => {
      setModalData(serviceCase);
      setShowAction(action);
      if ([Action.Edit, Action.MedicalAssessment].includes(action)) {
        history.push(ROUTES.caseClassifications.path, {
          serviceCase: pick(serviceCase, [
            "id",
            "caseNumber",
            "caseStatus",
            "patients",
            "destinationLocationAddress",
            "destinationLocation",
            "updatedAt",
            "destinationLocationType",
            "destinationLocationLatLong",
            "destinationLocationAddress2",
            "itineraryDetails",
            "assignedStaff",
            "confirmPaymentReceived",
            "destinationLocationTypeName",
            "initialLocationLatLng",
            "paymentDetails",
          ]),
        });
      } else if (action === Action.Crm) {
        const { zohoID } = serviceCase;
        window.open(
          `https://crm.zoho.com/crm/org757043308/tab/CustomModule13/${zohoID}`,
          "_blank"
        );
      }
    },
    [history]
  );

  useEffect(() => {
    formik.handleSubmit();
    // eslint-disable-next-line
  }, [serviceCaseUpdated]);

  const { socket } = useSocket("List");

  const handleNewServiceCase = useCallback(
    ({ serviceCaseId }: { serviceCaseId: string }) => {
      (async () => {
        if (!formik.dirty) {
          const serviceCase = {
            ...(await getServiceCase(serviceCaseId)),
            isNew: true,
          };

          if (formik.values.order === SortOrderEnum.ASC) {
            setTableData((prev) => ({
              ...prev,
              total: prev.total + 1,
              serviceCases: [serviceCase, ...prev.serviceCases],
            }));
          } else {
            setTableData((prev) => ({
              ...prev,
              total: prev.total + 1,
              perPage: prev.perPage + 1,
              serviceCases: [...prev.serviceCases, serviceCase],
            }));
          }
        }
      })();
    },
    [formik.dirty, formik.values.order]
  );

  const handleUpdatedServiceCase = useCallback(
    ({ serviceCaseId, modifiedFields }: SocketEvent) => {
      (async () => {
        const shouldUpdate = shouldUpdateServiceCase(modifiedFields);

        if (!shouldUpdate) return;

        const savedServiceCase = tableData.serviceCases.find(
          ({ id }) => id === serviceCaseId
        );

        if (!savedServiceCase) return;

        const serviceCase = await getServiceCase(serviceCaseId);

        const needsUpdate = serviceCaseNeedsUpdate(
          savedServiceCase,
          serviceCase,
          modifiedFields
        );

        if (!needsUpdate) return;

        setTableData((data) => ({
          ...data,
          serviceCases: data.serviceCases.map((sc) =>
            sc.id === serviceCaseId ? serviceCase : sc
          ),
        }));
      })();
    },
    [tableData]
  );

  useEffect(
    () => {
      if (!formik.dirty) {
        socket.on(
          SocketEventsEnum.NEW_SERVICE_CASE_NOTIFICATION,
          handleNewServiceCase
        );
      }

      socket.on(
        SocketEventsEnum.UPDATED_SERVICE_CASE_NOTIFICATION,
        handleUpdatedServiceCase
      );

      return () => {
        socket.off(SocketEventsEnum.NEW_SERVICE_CASE_NOTIFICATION);
        socket.off(SocketEventsEnum.UPDATED_SERVICE_CASE_NOTIFICATION);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.dirty, handleNewServiceCase, handleUpdatedServiceCase]
  );

  const [unansweredCase, setUnansweredCase] =
    useState<ServiceCaseResponse | null>(null);

  const handleContactRequesterUpdate = useCallback(
    (key: string, value: any) => {
      if (unansweredCase) {
        if (key === "callBackLogs") {
          const updatedServiceCase = {
            ...unansweredCase,
            callBackLogs: [...unansweredCase.callBackLogs, value],
          };

          handleUpdate(updatedServiceCase);
          setUnansweredCase(updatedServiceCase);
        } else if (key === "record") {
          const updatedServiceCase = value as ServiceCaseResponse;
          handleUpdate(updatedServiceCase);
          setUnansweredCase(updatedServiceCase);
        }
      }
    },
    [handleUpdate, unansweredCase]
  );

  return (
    <>
      {modalData && (
        <AssignCase
          open={showAction === Action.Assign}
          {...modalData}
          onClose={() => setShowAction("")}
          onUpdate={handleUpdate}
        />
      )}

      {modalData && (
        <CloseServiceCase
          maxWidth={508}
          confirmDialog
          id={modalData.id}
          open={showAction === Action.Close}
          onClose={() => setShowAction("")}
          quotes={[]}
          onUpdate={handleUpdate}
        />
      )}

      {unansweredCase && (
        <ContactRequester
          data={unansweredCase}
          onClose={() => setUnansweredCase(null)}
          onUpdate={handleContactRequesterUpdate}
        />
      )}

      <DisplayModeLayout className={classes.container}>
        <Box minWidth={0}>
          <Card className={clsx(classes.card)}>
            <Loader absolute open={initLoading || formik.isSubmitting} />
            <FilterBar
              resetFiler={() => formik.resetForm()}
              updateState={updateState}
              onPendingApproval={handleIsPendingApproval}
              onOverdue={handleIsOverdue}
              setSortAsc={handleSort}
              sortAsc={formik.values.order === SortOrderEnum.ASC}
              isOverdue={formik.values.overdue}
              isPendingApproval={formik.values.pendingQuoteApproval}
              onSearchChange={formik.handleChange}
              selectedRequestType={formik.values.requestType}
              selectedStatusGroup={formik.values.statusGroup}
              selectedServices={formik.values.service}
              selectedStatuses={formik.values.status}
              searchText={formik.values.searchText}
              dispatcherIDs={formik.values.dispatcherIDs as any}
              requestOrigin={
                formik.values.requestOrigin as ServiceCaseRequestOriginEnum
              }
              onApplyFilter={() => formik.handleSubmit()}
              isTouched={formik.dirty}
              tableData={tableData}
              setTableData={setTableData}
            />
            <TableContainer
              style={{
                maxHeight: "70vh",
              }}
              className={classes.tableContainer}
            >
              <Table stickyHeader className={classes.table}>
                {tableData.total ? (
                  <TableBody>
                    {tableData.serviceCases.map((row) => (
                      <PatientTableCellRecord
                        onMore={handleAction}
                        key={row.id}
                        setUnansweredCase={setUnansweredCase}
                        {...row}
                      />
                    ))}
                  </TableBody>
                ) : (
                  <>
                    {(!initLoading || !formik.isSubmitting) && pageLoaded && (
                      <Typography
                        align="center"
                        style={{ padding: "30px" }}
                        variant="body2"
                      >
                        No Results Found!
                      </Typography>
                    )}
                  </>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100]}
              colSpan={6}
              count={tableData.total}
              rowsPerPage={perPage}
              page={+tableData.page}
              classes={{
                input: classes.rowsPerPage,
                root: classes.paginationRoot,
              }}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowChange}
            />
          </Card>
        </Box>
        <Box
          height={displayLayout === DisplayLayout.vertical ? "80vh" : "100%"}
          minWidth={0}
        >
          <RenderMap />
        </Box>
      </DisplayModeLayout>
    </>
  );
};

export default List;
