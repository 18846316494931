import { format } from "date-fns";
import * as Yup from "yup";

import { getENText, VALIDATIONS } from "helpers";
import {
  ServiceCaseAccessLinkResponse,
  PostGenerateServiceCaseAccessLink,
  AccessNotificationTypeEnum,
  AccessLinkRecipientTypeEnum,
} from "@deep-consulting-solutions/be2-constants";

export type AccessType = "URL" | "PIN";
export type ShareType = "organization" | "individual" | "anyone";
export type ShareViaType = "email" | "phone";

export interface FormValues {
  accessType: AccessType;
  shareType: ShareType;
  organizationName: string;
  personName: string;
  designationPerson: string;
  shareVia: ShareViaType;
  email: string;
  phone: string;
}

export const getValidationSchema = () => {
  return Yup.lazy((values: FormValues) => {
    return Yup.object({
      shareVia: Yup.string().required("This is required"),
      email: values.shareVia === "email" ? VALIDATIONS.email : Yup.string(),
      phone: values.shareVia === "phone" ? VALIDATIONS.phone : Yup.string(),
      personName:
        values.shareType === "anyone"
          ? Yup.string()
          : Yup.string()
              .trim()
              .required(getENText("accessLinks.personName.required")),
      organizationName:
        values.shareType === "organization"
          ? Yup.string()
              .trim()
              .required(getENText("accessLinks.organizationName.required"))
          : Yup.string(),
    }).required() as any;
  });
};

export const displayLastAccessTime = (date: Date) => {
  return format(date, "dd MMM yyyy hh:mm aa");
};

export const updateAccessLinks = (
  accessLinks: ServiceCaseAccessLinkResponse[],
  withLink: ServiceCaseAccessLinkResponse
) => {
  return accessLinks.map((link) => {
    return link.ID === withLink.ID ? withLink : link;
  });
};

export const mapRecipientType = (
  input: ShareType
): AccessLinkRecipientTypeEnum => {
  if (input === "organization") return AccessLinkRecipientTypeEnum.ORGANIZATION;
  if (input === "individual") return AccessLinkRecipientTypeEnum.INDIVIDUAL;
  return AccessLinkRecipientTypeEnum.ANYONE;
};

export const composeGenerateAccessLinkBody = (
  serviceCaseID: string,
  data: FormValues
): typeof PostGenerateServiceCaseAccessLink["Body"] => {
  const base = {
    serviceCaseId: serviceCaseID,
    recipientType: mapRecipientType(data.shareType),
    shareVia:
      data.shareVia === "email"
        ? AccessNotificationTypeEnum.EMAIL
        : AccessNotificationTypeEnum.SMS,
    recipientPhone: data.shareVia === "phone" ? data.phone : undefined,
    recipientEmail: data.shareVia === "email" ? data.email : undefined,
    isPinBased: data.accessType === "PIN",
  };

  if (data.shareType === "anyone") {
    return {
      ...base,
      recipientOrganizationName: "",
    };
  }

  if (data.shareType === "individual") {
    return {
      ...base,
      recipientOrganizationName: "",
      recipientName: data.personName,
      recipientDesignation: data.designationPerson,
    };
  }

  return {
    ...base,
    recipientOrganizationName: data.organizationName,
    recipientName: data.personName,
    recipientDesignation: data.designationPerson,
  };
};
