import {
  DispatcherActivityLogTypeEnum,
  GetServiceCase,
  PostDispatcherActivityLog,
} from "@deep-consulting-solutions/be2-constants";
import { getClient } from "../../apis";
import { formatDateToRequest } from "../../helpers";

export const openNotificationRequest = async (
  zohoID: string,
  serviceCaseId: string
) => {
  await getClient(true).post(
    PostDispatcherActivityLog.ROUTE.replace(":id", zohoID),
    {
      type: DispatcherActivityLogTypeEnum.NOTIFICATION_OPENED,
      entryTime: formatDateToRequest(new Date()),
      notificationSentDate: new Date().toISOString(),
    } as typeof PostDispatcherActivityLog.Body
  );
  return getClient(true).get<typeof GetServiceCase.Res>(
    GetServiceCase.ROUTE.replace(":id", serviceCaseId)
  );
};
