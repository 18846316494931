import * as Yup from "yup";
import { Form, Formik, FormikProps } from "formik";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import React, { useMemo, useState, useCallback, useEffect } from "react";
import {
  Hospital,
  LocationTypeEnum,
  ServiceCaseResponse,
  ServiceCaseStatusEnum,
  ServiceCaseRequestOriginEnum,
} from "@deep-consulting-solutions/be2-constants";

import { useCall } from "call/hooks";
import Loader from "component/Loader";
import { patchDestination, patchInitialLocation } from "redux/call/requests";
import { ProvideInformationSupportDialog } from "call/components/ProvideInformationSupportDialog";
import {
  DMStoDD,
  getLocationItems,
  getLocationFromGeoCodeResult,
} from "helpers";

import { InitialLocation } from "./InitialLocation";
import { LocationSummary } from "./LocationSummary";
import { DestinationLocation } from "./DestinationLocation";
import { fullValidationSchema, initialValuesSchema } from "./helpers";
import { TabsEnum } from "../types";

export interface LocationField {
  lat: number;
  lng: number;
  dms: string;
  address: string;
  plusCode?: string;
}

export interface LocationFormValues {
  syncLocation: boolean;
  destinationType: string;
  requesterAddress?: string;
  customDestination: string;
  requesterLocation?: string;
  destination: LocationField;
  initialLocationType: string;
  customInitialLocation: string;
  moreDestinationDetails: string;
  initialLocation: LocationField;
  showDestinationLocation: boolean;
  moreInitialAddressDetails: string;
  isInitialLocationAccurate: boolean;
  initialMedicalFacility: Hospital | null;
  destinationMedicalFacility: Hospital | null;
}

interface LocationFormProps {
  activeTab: TabsEnum;
  switchTab: (tab: TabsEnum) => void;
}

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  heading: {
    fontWeight: 500,
    marginBottom: s(2),
    color: p.primary.main,
    fontFamily: "'Roboto', sans-serif",

    "&:first-of-type": {
      marginTop: s(2),
    },
  },
  buttonContainer: {
    display: "flex",
    marginTop: s(4.25),
    justifyContent: "space-between",

    "& .MuiButton-label": {
      fontSize: 12,
    },
  },
  nextButton: {
    marginLeft: s(2),
  },
}));

export const LocationForm = ({ activeTab, switchTab }: LocationFormProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { viewedCall, updateCallServiceCase } = useCall();

  const initialLocationExits = useMemo(
    () =>
      !!viewedCall?.updatedServiceCase?.requesterAddress ||
      !!viewedCall?.updatedServiceCase?.requesterLocation,
    [
      viewedCall?.updatedServiceCase?.requesterAddress,
      viewedCall?.updatedServiceCase?.requesterLocation,
    ]
  );

  const initialValues: LocationFormValues = useMemo(() => {
    const serviceCase =
      viewedCall?.updatedServiceCase || ({} as ServiceCaseResponse);

    const values = {
      syncLiveLocation: !!viewedCall?.isEmergency,
      syncLocation: serviceCase.syncRequesterLiveLocation,
      destinationType: serviceCase.destinationLocationType || "",
      initialLocationType: serviceCase.initialLocationType || "",
      initialMedicalFacility: serviceCase.initalLocationHospital,
      customDestination: serviceCase.destinationLocationTypeName || "",
      customInitialLocation: serviceCase.initialLocationTypeName || "",
      showDestinationLocation: !!serviceCase.initialLocation,
      requesterAddress: viewedCall?.updatedServiceCase?.requesterAddress,
      destinationMedicalFacility: serviceCase.destinationLocationHospital,
      moreInitialAddressDetails: serviceCase.initialLocationAddress2 || "",
      requesterLocation: viewedCall?.updatedServiceCase?.requesterLocation,
      moreDestinationDetails: serviceCase.destinationLocationAddress2 || "",
      isInitialLocationAccurate:
        serviceCase.requestOrigin === ServiceCaseRequestOriginEnum.BE_APP_CALL
          ? serviceCase.requesterSameAsInitialLocation !== false
          : false,
      destination: {
        ...getLocationItems(serviceCase.destinationLocation),
        address: serviceCase.destinationLocationAddress || "",
      },
      initialLocation: {
        ...getLocationItems(serviceCase.initialLocation),
        address: serviceCase.initialLocationAddress || "",
      },
    };

    if (initialLocationExits && !values.initialLocation.dms) {
      const dms = values.requesterLocation as string;
      let address = values.requesterAddress as string;

      if (viewedCall?.requesterGeoLocation) {
        const { address: geoCodeAddress } = getLocationFromGeoCodeResult(
          viewedCall.requesterGeoLocation
        );

        if (geoCodeAddress) address = geoCodeAddress;
      }

      const [lat, lng] = DMStoDD(dms);
      values.initialLocation = {
        lat,
        lng,
        dms,
        address: address || "",
      };
    }

    return values;
  }, [
    initialLocationExits,
    viewedCall?.isEmergency,
    viewedCall?.updatedServiceCase,
    viewedCall?.requesterGeoLocation,
  ]);

  const [requesterLiveLocation, setRequesterLiveLocation] =
    useState<LocationField | null>(null);

  const updateRequesterLiveLocation = useCallback((state: LocationField) => {
    setRequesterLiveLocation(state);
  }, []);

  useEffect(() => {
    if (viewedCall?.requesterGeoLocation) {
      setRequesterLiveLocation(
        getLocationFromGeoCodeResult(viewedCall?.requesterGeoLocation)
      );
    }
  }, [viewedCall?.requesterGeoLocation]);

  const handleSubmit = useCallback(
    async (values: LocationFormValues) => {
      setLoading(true);

      try {
        const {
          destination,
          syncLocation,
          initialLocation,
          initialLocationType,
          customInitialLocation,
          initialMedicalFacility,
          moreInitialAddressDetails,
          isInitialLocationAccurate,
        } = values;

        await patchInitialLocation(viewedCall?.updatedServiceCase?.id || "", {
          syncRequesterLiveLocation: syncLocation,
          initialLocationTypeName: customInitialLocation,
          initialLocationAddress: initialLocation.address,
          initialLocationAddress2: moreInitialAddressDetails,
          requesterSameAsInitialLocation: isInitialLocationAccurate,
          initialLocationType: initialLocationType as LocationTypeEnum,
          initialLocation: `${initialLocation.lat},${initialLocation.lng}`,
          locationIdentificationPhone:
            viewedCall?.updatedServiceCase?.locationIdentificationPhone || "",
          medicalFacilityID:
            initialLocationType === LocationTypeEnum.MEDICAL_FACILITY
              ? initialMedicalFacility?.id
              : undefined,
        });

        const res = await patchDestination(
          viewedCall?.updatedServiceCase?.id || "",
          {
            destinationLocationAddress: destination.address,
            destinationLocationTypeName: values.customDestination,
            destinationLocationAddress2: values.moreDestinationDetails,
            destinationLocation: `${destination.lat},${destination.lng}`,
            medicalFacilityID: values.destinationMedicalFacility?.id || "",
            destinationLocationType: values.destinationType as LocationTypeEnum,
          }
        );

        updateCallServiceCase(viewedCall?.sessionId || "", res);
        switchTab(TabsEnum.SERVICE);
      } catch {
        //
      } finally {
        setLoading(false);
      }
    },
    [
      switchTab,
      updateCallServiceCase,
      viewedCall?.sessionId,
      viewedCall?.updatedServiceCase,
    ]
  );

  const handleNextClick = useCallback(
    ({
      values,
      setErrors,
      submitForm,
      setFieldValue,
      setFieldTouched,
    }: FormikProps<LocationFormValues>) => {
      (async () => {
        setLoading(true);

        const {
          syncLocation,
          initialLocation,
          initialLocationType,
          customInitialLocation,
          initialMedicalFacility,
          isInitialLocationAccurate,
          moreInitialAddressDetails,
        } = values;

        try {
          if (values.showDestinationLocation) {
            submitForm();
          } else {
            initialValuesSchema.validateSync(values, { abortEarly: false });
            await patchInitialLocation(
              viewedCall?.updatedServiceCase?.id || "",
              {
                syncRequesterLiveLocation: syncLocation,
                initialLocationTypeName: customInitialLocation,
                initialLocationAddress: initialLocation.address,
                initialLocationAddress2: moreInitialAddressDetails,
                requesterSameAsInitialLocation: isInitialLocationAccurate,
                initialLocationType: initialLocationType as LocationTypeEnum,
                initialLocation: `${initialLocation.lat},${initialLocation.lng}`,
                locationIdentificationPhone:
                  viewedCall?.updatedServiceCase?.locationIdentificationPhone ||
                  "",
                medicalFacilityID:
                  initialLocationType === LocationTypeEnum.MEDICAL_FACILITY
                    ? initialMedicalFacility?.id
                    : undefined,
              }
            );
            setFieldValue("showDestinationLocation", true);
          }
        } catch (err: any) {
          if ("inner" in err) {
            setErrors(
              (err as Yup.ValidationError).inner.reduce((acc, cur) => {
                acc[cur.path] = cur.message;
                setFieldTouched(cur.path, true);
                return acc;
              }, {} as Record<string, string>)
            );
          }
        } finally {
          setLoading(false);
        }
      })();
    },
    [viewedCall?.updatedServiceCase]
  );

  const [
    isInformationalSupportDialogOpen,
    setIsInformationalSupportDialogOpen,
  ] = useState(false);

  const showProvideInformationalSupportDialog = useCallback(() => {
    setIsInformationalSupportDialogOpen(true);
  }, []);

  const hideProvideInformationalSupportDialog = useCallback(() => {
    setIsInformationalSupportDialogOpen(false);
  }, []);

  return (
    <Formik
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={fullValidationSchema}
    >
      {function Callback(props) {
        const { values, isValid } = props;
        const { viewedCall: localViewedCall } = useCall();

        const disableForm = useMemo(
          () =>
            !!localViewedCall?.disableForms ||
            localViewedCall?.updatedServiceCase?.caseStatus !==
              ServiceCaseStatusEnum.GATHERING_REQUIREMENTS,
          [
            localViewedCall?.disableForms,
            localViewedCall?.updatedServiceCase?.caseStatus,
          ]
        );

        const isNextButtonDisabled = useMemo(() => {
          if (disableForm) return true;

          if (values.showDestinationLocation) return !isValid;

          return !values.initialLocation.dms;
        }, [
          isValid,
          disableForm,
          values.initialLocation.dms,
          values.showDestinationLocation,
        ]);

        return (
          <Form>
            <Loader open={loading} absolute />

            {values.initialLocation?.address &&
              values.isInitialLocationAccurate &&
              !values.syncLocation &&
              requesterLiveLocation && (
                <Box>
                  <Typography variant="h4" className={classes.heading}>
                    Requester GPS Location from BahamasEvac App
                  </Typography>

                  <Box mb={4}>
                    <LocationSummary
                      showAddress
                      location={requesterLiveLocation}
                    />
                  </Box>
                </Box>
              )}

            <Typography variant="h4" className={classes.heading}>
              Patient&apos;s Initial and Destination Location
            </Typography>

            <Box mb={2}>
              <InitialLocation
                {...props}
                activeTab={activeTab}
                requesterLiveLocation={requesterLiveLocation}
                updateRequesterLiveLocation={updateRequesterLiveLocation}
              />
            </Box>

            {values.showDestinationLocation && (
              <Box>
                <DestinationLocation {...props} activeTab={activeTab} />
              </Box>
            )}

            <Box className={classes.buttonContainer}>
              <Button
                variant="outlined"
                onClick={() => {
                  switchTab(TabsEnum.REQUESTER_AND_PATIENT);
                }}
              >
                Back
              </Button>

              <Box>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={disableForm}
                  onClick={showProvideInformationalSupportDialog}
                >
                  Provide Informational Support
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  className={classes.nextButton}
                  disabled={isNextButtonDisabled}
                  onClick={() => handleNextClick(props)}
                >
                  Next
                </Button>
              </Box>
            </Box>

            <ProvideInformationSupportDialog
              open={isInformationalSupportDialogOpen}
              handleClose={hideProvideInformationalSupportDialog}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
