import React, { useContext, useEffect, useState } from "react";

import Loader from "component/Loader";
import { notifications } from "services";
import { CallLayout, useCall } from "call";
import { zohoSelectors } from "redux/zoho";
import { useAppSelector } from "redux/store";
import { getServiceCase } from "redux/serviceCase/requests";
import { CaseClassificationsContext } from "context/CaseClassificationProvider";

const CaseClassificationsWidget = () => {
  const [loading, setLoading] = useState(false);
  const [id] = useAppSelector(zohoSelectors.getIds);
  const { viewedCall, setDashboardCall } = useCall();
  const { updateState } = useContext(CaseClassificationsContext);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const res = await getServiceCase(id, true);
        updateState(res);
        setDashboardCall?.(res);
      } catch {
        notifications.notifyError("Service case info is partial.");
      } finally {
        setLoading(false);
      }
    })();
  }, [id, updateState, setDashboardCall]);

  return (
    <>
      <Loader open={loading} />
      {!loading && !!viewedCall?.updatedServiceCase && <CallLayout />}
    </>
  );
};

export default CaseClassificationsWidget;
