import { makeStyles, Theme } from "@material-ui/core";

export const useMedicalDetailStyles = makeStyles<
  Theme,
  {
    btnSize?: "small" | "medium";
    btnIsOpen?: boolean;
    noBg?: boolean;
    reverseToggleAnimation?: boolean;
  }
>(({ palette }) => ({
  wrapper: {
    display: "grid",
    gridTemplateColumns: "minmax(auto, 645px) 1fr",
    gap: 16,
  },
  card: { padding: 16, backgroundColor: "white" },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textHeading: {
    fontSize: 24,
  },
  textSubHeading: {
    fontSize: 20,
  },
  textTitle: {
    fontSize: 14,
    color: palette.primary.main,
  },
  smallText: {
    fontSize: 12,
    color: "#4F4F4F",
    display: "block",
  },
  tinyText: {
    fontSize: 10,
    display: "block",
  },
  buttonWrapper: {
    borderRadius: 4,
    backgroundColor: ({ noBg }) => (noBg ? "unset" : "#F7F7F7"),
  },
  buttonToggle: {
    fontSize: ({ btnSize }) => (btnSize === "small" ? 12 : 18),
    color: "#263E7F",
    transition: "transform 0.5s",
    transform: ({ btnIsOpen, reverseToggleAnimation }) =>
      `rotate(${
        // eslint-disable-next-line no-nested-ternary
        btnIsOpen
          ? reverseToggleAnimation
            ? "270"
            : "-90"
          : reverseToggleAnimation
          ? "180"
          : "0"
      }deg)`,
  },
  divider: {
    marginTop: 32,
    marginBottom: 32,
  },
  checkbox: {
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export const BahamasDefaultLocation = `24° 41' 19" N, -78° 1' 27" W`;

export const BahamasDefaultGeocoderResult = {
  address_components: [
    {
      long_name: "The Bahamas",
      short_name: "BS",
      types: ["country", "political"],
    },
  ],
  formatted_address: "The Bahamas",
  geometry: {
    bounds: {
      south: 20.9082735,
      west: -80.4775603,
      north: 27.263412,
      east: -72.70975390000001,
    },
    location: {
      lat: 25.03428,
      lng: -77.39627999999999,
    },
    location_type: "APPROXIMATE",
    viewport: {
      south: 21.4248332,
      west: -81.257154,
      north: 27.444104,
      east: -70.6762782,
    },
  },
  place_id: "ChIJPQ9Isjua1ogR5XnHaoO0PhM",
  types: ["country", "political"],
};
