import {
  GenderEnum,
  GeneralAddress,
  NextOfKinRelationToPatientEnum,
  PatientResponseWithContact,
  RelationToApplicantEnum,
  ServiceCaseRequesterPatientRelationEnum,
  ServiceCaseRequestTypeEnum,
  ServiceCaseResponse,
  PatientTypeEnum,
  BloodTypeEnum,
  PatientLevelOfEmergencyEnum,
} from "@deep-consulting-solutions/be2-constants";
import { validatePhoneNumberWithCountryCode } from "@deep-consulting-solutions/dcs-web-ui";
import { formatDateToRequest, getENText } from "helpers";
import * as yup from "yup";
import { FormValues, NextOfKinTypeEnum, PatientData } from "./types";
import { booleanToYesOrNo, yesOrNoToBoolean } from "../LocationForm/helpers";

export const getAge = (dob: string) => {
  const dobObj = new Date(dob);
  const today = new Date();
  let dobInMilliseconds = dobObj.getTime(); // this age in milliseconds does not contain days in leap year
  const todayInMilliseconds = today.getTime();

  /**
   * Add extra leap year days
   */
  const daysFromLeapYear = Math.floor(
    (today.getFullYear() - dobObj.getFullYear()) / 4
  );
  const daysFromLeapYearInMilliseconds = daysFromLeapYear * 24 * 60 * 60 * 1000;
  dobInMilliseconds += daysFromLeapYearInMilliseconds;

  const ageInMilliseconds = todayInMilliseconds - dobInMilliseconds;

  return Math.floor(ageInMilliseconds / 31536000000);
};

export const patientNextOfKinValidation = {
  firstName: yup
    .string()
    .required(
      getENText(
        "requesterAndPatientForm.requester.nextOfKin.firstName.required"
      )
    ),
  lastName: yup
    .string()
    .required(
      getENText("requesterAndPatientForm.requester.nextOfKin.lastName.required")
    ),
  phone: yup
    .string()
    .test(
      "check validity",
      getENText("requesterAndPatientForm.requester.nextOfKin.phone.invalid"),
      validatePhoneNumberWithCountryCode
    )
    .required(
      getENText("requesterAndPatientForm.requester.nextOfKin.phone.required")
    ),
  email: yup.string().email(getENText("validation.email.invalid")),
  relationshipToPatient: yup
    .string()
    .oneOf(
      Object.values(NextOfKinRelationToPatientEnum),
      getENText(
        "requesterAndPatientForm.requester.nextOfKin.relationshipToPatient.required"
      )
    )
    .required(
      getENText(
        "requesterAndPatientForm.requester.nextOfKin.relationshipToPatient.required"
      )
    ),
  otherRelationToPatient: yup.string().when("relationshipToPatient", {
    is: NextOfKinRelationToPatientEnum.OTHER,
    then: yup
      .string()
      .required(
        getENText(
          "requesterAndPatientForm.requester.nextOfKin.otherRelationToPatient.required"
        )
      ),
  }),
};

export const patientNextOfKinValidationNotRequired = {
  firstName: yup.string(),
  lastName: yup.string(),
  phone: yup
    .string()
    .test(
      "check validity",
      getENText("requesterAndPatientForm.requester.nextOfKin.phone.invalid"),
      (value) => (value ? validatePhoneNumberWithCountryCode(value) : true)
    ),
  email: yup.string().email(getENText("validation.email.invalid")),
  relationshipToPatient: yup
    .string()
    .oneOf(
      Object.values(NextOfKinRelationToPatientEnum),
      getENText(
        "requesterAndPatientForm.requester.nextOfKin.relationshipToPatient.required"
      )
    ),
  otherRelationToPatient: yup.string().when("relationshipToPatient", {
    is: NextOfKinRelationToPatientEnum.OTHER,
    then: yup
      .string()
      .required(
        getENText(
          "requesterAndPatientForm.requester.nextOfKin.otherRelationToPatient.required"
        )
      ),
  }),
};

const checkIfPatientFullDetailsIsRequired = (
  isDeceased?: boolean,
  requestType?: ServiceCaseRequestTypeEnum
) => {
  if (requestType === ServiceCaseRequestTypeEnum.EMERGENCY || isDeceased) {
    return false;
  }
  return true;
};

const getCheckIfPatientFullDetailsIsRequired = (
  requestType?: ServiceCaseRequestTypeEnum
) => {
  return (isDeceased?: boolean) =>
    checkIfPatientFullDetailsIsRequired(isDeceased, requestType);
};

export const getRequesterAndPatientFormValidationSchema = (
  requestType?: ServiceCaseRequestTypeEnum
) =>
  yup.object({
    requester: yup.object({
      isMemberOrCustomer: yup
        .boolean()
        .required(
          getENText(
            "requesterAndPatientForm.requester.isMemberOrCustomer.required"
          )
        ),
      isPatient: yup
        .boolean()
        .required(
          getENText("requesterAndPatientForm.requester.isPatient.required")
        ),
      firstName: yup.string().when("isMemberOrCustomer", {
        is: false,
        then: yup
          .string()
          .required(
            getENText("requesterAndPatientForm.requester.firstName.required")
          ),
      }),
      lastName: yup.string().when("isMemberOrCustomer", {
        is: false,
        then: yup
          .string()
          .required(
            getENText("requesterAndPatientForm.requester.lastName.required")
          ),
      }),
      phone: yup.string().when("isMemberOrCustomer", {
        is: false,
        then: yup.string().when("isPatient", {
          is: false,
          then: yup
            .string()
            .test(
              "check validity",
              getENText("requesterAndPatientForm.requester.phone.invalid"),
              validatePhoneNumberWithCountryCode
            )
            .required(
              getENText("requesterAndPatientForm.requester.phone.required")
            ),
        }),
      }),
      email: yup.string().when("isMemberOrCustomer", {
        is: false,
        then: yup.string().email(getENText("validation.email.invalid")),
      }),
      gender: yup.string().when("isMemberOrCustomer", {
        is: false,
        then: yup
          .string()
          .oneOf(
            Object.values(GenderEnum),
            getENText("requesterAndPatientForm.requester.gender.required")
          )
          .when("isPatient", {
            is: true,
            then: yup
              .string()
              .required(
                getENText("requesterAndPatientForm.requester.gender.required")
              ),
          }),
      }),
      dob: yup
        .string()
        .nullable()
        .test({
          name: "check validity",
          message: getENText("requesterAndPatientForm.requester.dob.required"),
          test(value) {
            return !(
              this.parent?.isPatient === true &&
              this.parent?.isMemberOrCustomer === false &&
              !value &&
              !this.parent?.age
            );
          },
        })
        .when(["isPatient", "age"], {
          is: (isPatient: boolean, age: number) => isPatient === true && !age,
          then: yup
            .string()
            .nullable()
            .test({
              name: "check validity",
              message: getENText(
                "requesterAndPatientForm.requester.dob.invalid"
              ),
              test(value: unknown) {
                if (!value) {
                  return true;
                }
                return !!Date.parse(value as string);
              },
            }),
        }),
      age: yup
        .number()
        .min(0)
        .test({
          name: "check validity",
          message: getENText("requesterAndPatientForm.requester.age.required"),
          test(value) {
            return !(
              this.parent?.isPatient === true &&
              this.parent?.isMemberOrCustomer === false &&
              !value &&
              !this.parent?.dob
            );
          },
        }),
      nextOfKin: yup
        .object()
        .when(["nextOfKinMembership", "isPatient", "isMemberOrCustomer"], {
          is: (
            nextOfKinMembership: string,
            isPatient?: boolean,
            isMemberOrCustomer?: boolean
          ) =>
            isPatient &&
            !isMemberOrCustomer &&
            nextOfKinMembership === NextOfKinTypeEnum.NEW &&
            requestType !== ServiceCaseRequestTypeEnum.EMERGENCY,
          then: yup.object().shape(patientNextOfKinValidation),
          otherwise: yup.object().shape(patientNextOfKinValidationNotRequired),
        }),
      nextOfKinMembership:
        requestType === ServiceCaseRequestTypeEnum.EMERGENCY
          ? yup.string()
          : yup
              .string()
              .required(
                getENText("requesterAndPatientForm.patient.nextOfKin.required")
              ),
      medicalNeeds: yup.string().when(["isPatient", "isMemberOrCustomer"], {
        is: (isPatient?: boolean, isMemberOrCustomer?: boolean) =>
          isPatient && !isMemberOrCustomer,
        then: yup.string().required("Medical Needs are Required"),
        otherwise: yup.string().nullable(),
      }),
      patientWeight: yup
        .number()
        .nullable()
        .typeError("Patient weight is required")
        .when(["isPatient", "isMemberOrCustomer"], {
          is: (isPatient?: boolean, isMemberOrCustomer?: boolean) =>
            isPatient && !isMemberOrCustomer,
          then: yup
            .number()
            .positive("Patient weight must be greater than 0")
            .test({
              name: "check validity",
              message: "Patient weight must be greater than 0",
              test(value) {
                const patientWeight = Number(value);
                if (!value && patientWeight !== 0) {
                  return true;
                }
                return patientWeight > 0;
              },
            }),
        }),
    }),
    patients: yup.array().of(
      yup.object().shape({
        isDeceased: yup.boolean(),
        requesterRelationToPatient: yup
          .string()
          .oneOf(
            Object.values(ServiceCaseRequesterPatientRelationEnum),
            getENText(
              "requesterAndPatientForm.patient.relationshipToRequester.required"
            )
          )
          .when("isDeceased", {
            is: (isDeceased: boolean) => {
              return getCheckIfPatientFullDetailsIsRequired(requestType)(
                isDeceased
              );
            },
            then: yup
              .string()
              .required(
                getENText(
                  "requesterAndPatientForm.patient.relationshipToRequester.required"
                )
              ),
          }),
        requesterOtherRelationToPatient: yup
          .string()
          .when("requesterRelationToPatient", {
            is: ServiceCaseRequesterPatientRelationEnum.Other,
            then: yup
              .string()
              .required(
                getENText(
                  "requesterAndPatientForm.patient.relationshipToRequester.required"
                )
              ),
          }),
        firstName: yup
          .string()
          .required(
            getENText("requesterAndPatientForm.requester.firstName.required")
          ),
        lastName: yup
          .string()
          .required(
            getENText("requesterAndPatientForm.requester.lastName.required")
          ),
        phone: yup.string().when("patientType", {
          is: PatientTypeEnum.NEW,
          then: yup.string().test({
            name: "check validity",
            message: getENText(
              "requesterAndPatientForm.requester.phone.invalid"
            ),
            test(val: unknown) {
              if (
                !getCheckIfPatientFullDetailsIsRequired(requestType)(
                  !!this.parent?.isDeceased
                )
              ) {
                return true;
              }

              return validatePhoneNumberWithCountryCode(
                val as string | undefined
              );
            },
          }),
        }),
        email: yup.string().email(getENText("validation.email.invalid")),
        gender: yup.string().when("patientType", {
          is: PatientTypeEnum.NEW,
          then: yup.string().when("isDeceased", {
            is: getCheckIfPatientFullDetailsIsRequired(requestType),
            then: yup
              .string()
              .required(
                getENText("requesterAndPatientForm.requester.gender.required")
              ),
          }),
        }),
        dob: yup
          .string()
          .nullable()
          .test({
            name: "check validity",
            message: getENText(
              "requesterAndPatientForm.requester.dob.required"
            ),
            test(value) {
              if (
                this.parent?.patientType !== PatientTypeEnum.NEW ||
                !getCheckIfPatientFullDetailsIsRequired(requestType)(
                  !!this.parent?.isDeceased
                )
              ) {
                return true;
              }
              return !(!value && !this.parent?.age);
            },
          })
          .test({
            name: "check validity",
            message: getENText("requesterAndPatientForm.requester.dob.invalid"),
            test(value: unknown) {
              if (
                this.parent?.patientType !== PatientTypeEnum.NEW ||
                !getCheckIfPatientFullDetailsIsRequired(requestType)(
                  !!this.parent?.isDeceased
                )
              ) {
                return true;
              }
              if (!value) {
                return true;
              }

              return !!Date.parse(value as string);
            },
          }),
        age: yup
          .number()
          .min(0)
          .test({
            name: "check validity",
            message: getENText(
              "requesterAndPatientForm.requester.age.required"
            ),
            test(value) {
              if (
                this.parent?.patientType !== PatientTypeEnum.NEW ||
                !getCheckIfPatientFullDetailsIsRequired(requestType)(
                  !!this.parent?.isDeceased
                )
              ) {
                return true;
              }
              return !(!value && !this.parent?.dob);
            },
          }),
        nextOfKin: yup.object().when(["nextOfKinMembership", "isDeceased"], {
          is: (nextOfKinMembership: NextOfKinTypeEnum, isDeceased: boolean) => {
            if (
              !getCheckIfPatientFullDetailsIsRequired(requestType)(isDeceased)
            ) {
              return false;
            }
            return nextOfKinMembership !== NextOfKinTypeEnum.NONE;
          },
          then: yup.object().shape(patientNextOfKinValidation),
          otherwise: yup.object().nullable(),
        }),
        nextOfKinMembership: yup.string().when(["isDeceased", "patientType"], {
          is: (isDeceased: boolean) => {
            return getCheckIfPatientFullDetailsIsRequired(requestType)(
              isDeceased
            );
          },
          then: yup
            .string()
            .required(
              getENText("requesterAndPatientForm.patient.nextOfKin.required")
            ),
        }),
        patientWeight: yup
          .number()
          .nullable()
          .positive("Patient weight must be greater than 0")
          .typeError("Patient weight is required")
          .test({
            name: "check validity",
            message: "Patient weight must be greater than 0",
            test(value) {
              const patientWeight = Number(value);
              if (!value && patientWeight !== 0) {
                return true;
              }
              return patientWeight > 0;
            },
          }),
        medicalNeeds: yup.string().required("Medical Needs are Required"),
      })
    ),
  });

const getDefaultPatientType = (
  patient: PatientResponseWithContact,
  isRequesterMemberOfSubscription: boolean
) => {
  if (
    patient.patientType === PatientTypeEnum.MEMBER &&
    !isRequesterMemberOfSubscription
  ) {
    return PatientTypeEnum.EXISTING;
  }
  if (patient.patientType) {
    return patient.patientType;
  }
  if (
    patient.isRequester ||
    (patient.contactID && patient.isMemberOrCustomer)
  ) {
    return PatientTypeEnum.EXISTING;
  }
  return PatientTypeEnum.NEW;
};

export const getDefaultNextOfKinMembership = (
  patient: PatientResponseWithContact,
  isRequesterMemberOfSubscription: boolean
) => {
  if (!patient.nextOfKin?.firstName) {
    return NextOfKinTypeEnum.NONE;
  }
  if (
    getDefaultPatientType(patient, isRequesterMemberOfSubscription) ===
    PatientTypeEnum.NEW
  ) {
    return NextOfKinTypeEnum.NEW;
  }

  if (patient.nextOfKin?.nextOfKinID) {
    return NextOfKinTypeEnum.NEXT_OF_KIN;
  }
  return patient.nextOfKin?.contactID || "";
};

const getIsPatient = (
  isPatient?: boolean,
  patients?: ServiceCaseResponse["patients"]
) => {
  if ([true, false].includes(isPatient!)) {
    return isPatient!;
  }
  if (patients && patients.length) {
    return !!patients.find((patient) => patient.isRequester);
  }
  return "" as unknown as boolean;
};

export const getIsMemberOrCustomer = (isMemberOrCustomer?: boolean) => {
  if ([true, false].includes(isMemberOrCustomer!)) {
    return isMemberOrCustomer!;
  }
  return "" as unknown as boolean;
};

const getInitialAge = (age?: number) => {
  const ageNumber = Number(age);

  if (!Number.isNaN(ageNumber)) {
    return ageNumber;
  }

  return "" as unknown as number;
};

export const getRequesterAndPatientFormInitialValues = (
  serviceCase?: ServiceCaseResponse
): FormValues => {
  const requester = serviceCase?.requester as
    | ServiceCaseResponse["requester"]
    | undefined
    | any;
  const requesterPatientValue = serviceCase?.patients.find(
    (patient) => !!patient.isRequester
  );
  return {
    requester: {
      isPatient: getIsPatient(
        serviceCase?.requester?.isPatient,
        serviceCase?.patients
      ),
      isMemberOrCustomer: getIsMemberOrCustomer(
        serviceCase?.requester?.isMemberOrCustomer
      ),
      phone: serviceCase?.requester?.phone || "",
      email: serviceCase?.requester?.email || "",
      contactID:
        serviceCase?.requester?.contactID ||
        (serviceCase?.requester as any)?.id ||
        "",
      firstName: serviceCase?.requester?.firstName || "",
      lastName: serviceCase?.requester?.lastName || "",
      dob: serviceCase?.requester?.dob || "",
      gender: serviceCase?.requester?.gender || ("" as unknown as GenderEnum),
      age: getInitialAge(serviceCase?.requester?.age),
      nextOfKin: {
        relationshipToPatient:
          serviceCase?.requester?.nextOfKin?.relationshipToPatient ||
          ("" as unknown as NextOfKinRelationToPatientEnum),
        otherRelationToPatient:
          serviceCase?.requester?.nextOfKin?.otherRelationToPatient || "",
        firstName: serviceCase?.requester?.nextOfKin?.firstName || "",
        lastName: serviceCase?.requester?.nextOfKin?.lastName || "",
        phone: serviceCase?.requester?.nextOfKin?.phone || "",
        email: serviceCase?.requester?.nextOfKin?.email || "",
        isMemberOrCustomer: getIsMemberOrCustomer(
          serviceCase?.requester?.nextOfKin?.isMemberOrCustomer
        ),
      },
      nextOfKinMembership: NextOfKinTypeEnum.NEW,
      bloodType: (requester?.bloodType ||
        requesterPatientValue?.contact?.bloodType ||
        "") as BloodTypeEnum,
      patientWeight:
        requester?.contact?.patientWeight ||
        requesterPatientValue?.contact?.patientWeight ||
        ("" as unknown as number),
      primaryPhysicianName:
        requester?.contact?.primaryPhysicianName ||
        requesterPatientValue?.contact?.primaryPhysicianName ||
        "",
      primaryPhysicianPhone:
        requester?.contact?.primaryPhysicianPhone ||
        requesterPatientValue?.contact?.primaryPhysicianPhone ||
        "",
      levelOfEmergency:
        requester?.levelOfEmergency ||
        requesterPatientValue?.levelOfEmergency ||
        ("" as unknown as PatientLevelOfEmergencyEnum),
      medicalNeeds:
        requester?.medicalNeeds || requesterPatientValue?.medicalNeeds || "",
      canFlyCommercial: yesOrNoToBoolean(
        (requester?.canFlyCommercially as string) ||
          requesterPatientValue?.canFlyCommercially
      ),
      medicalEscort: yesOrNoToBoolean(
        (requester?.comercialFlightMedicalEscort as string) ||
          requesterPatientValue?.comercialFlightMedicalEscort
      ),
      preferredDestinationArrivalTime:
        requester?.preferredDestinationArrivalTime ||
        requesterPatientValue?.preferredDestinationArrivalTime ||
        "",
      preferredHospitalId:
        requester?.contact?.preferredHospital?.id ||
        requesterPatientValue?.contact?.preferredHospital?.id ||
        "",
    },
    patients:
      serviceCase?.patients
        ?.filter((patient) => {
          if (
            patient.isRequester &&
            !serviceCase?.requester?.isMemberOrCustomer &&
            serviceCase.requester?.isPatient
          ) {
            return false;
          }
          return true;
        })
        ?.map((patient) => ({
          isRequester: patient.isRequester || ("" as unknown as boolean),
          isMemberOrCustomer: getIsMemberOrCustomer(patient.isMemberOrCustomer),
          isDeceased: !!patient.isDeceased,
          requesterRelationToPatient:
            patient.requesterRelationToPatient ||
            ("" as unknown as ServiceCaseRequesterPatientRelationEnum),
          requesterOtherRelationToPatient:
            patient.requesterOtherRelationToPatient || "",
          contactID: patient.contactID || "",
          firstName: patient.firstName || "",
          lastName: patient.lastName || "",
          gender: patient.gender || ("" as GenderEnum),
          age: getInitialAge(patient.age),
          dob: patient.dob || "",
          phone: patient.phone || "",
          email: patient.email || "",
          skipNextOfKin:
            !!patient.nextOfKin?.firstName || ("" as unknown as boolean),
          nextOfKin: {
            relationshipToPatient:
              patient.nextOfKin?.relationToPatient ||
              ("" as unknown as NextOfKinRelationToPatientEnum),
            otherRelationToPatient:
              patient.nextOfKin?.otherRelationToPatient || "",
            firstName: patient.nextOfKin?.firstName || "",
            lastName: patient.nextOfKin?.lastName || "",
            phone: patient.nextOfKin?.phone || "",
            email: patient.nextOfKin?.email || "",
            isMemberOrCustomer: getIsMemberOrCustomer(
              patient?.nextOfKin?.isMemberOrCustomer
            ),
          },
          patientType: getDefaultPatientType(
            patient,
            getIsMemberOrCustomer(serviceCase?.requester?.isMemberOrCustomer)
          ),
          nextOfKinMembership: getDefaultNextOfKinMembership(
            patient,
            getIsMemberOrCustomer(serviceCase?.requester?.isMemberOrCustomer)
          ),
          bloodType: (patient.bloodType || "") as BloodTypeEnum,
          patientWeight:
            patient.contact.patientWeight || ("" as unknown as number),
          primaryPhysicianName: patient.contact.primaryPhysicianName || "",
          primaryPhysicianPhone: patient.contact.primaryPhysicianPhone || "",
          levelOfEmergency:
            patient.levelOfEmergency ||
            ("" as unknown as PatientLevelOfEmergencyEnum),
          medicalNeeds: patient.medicalNeeds || "",
          canFlyCommercial: yesOrNoToBoolean(patient.canFlyCommercially),
          medicalEscort: yesOrNoToBoolean(patient.comercialFlightMedicalEscort),
          preferredDestinationArrivalTime:
            patient.preferredDestinationArrivalTime || "",
          preferredHospitalId: patient.contact.preferredHospital?.id || "",
        })) || [],
  };
};

export const formatPayloadToSubmit = (
  serviceCase: ServiceCaseResponse,
  data: FormValues
): Record<string, unknown> => {
  const isEmergency =
    serviceCase.requestType === ServiceCaseRequestTypeEnum.EMERGENCY;

  const res: Record<string, any> = {
    requester: {
      isPatient: data.requester.isPatient,
      isMemberOrCustomer: data.requester.isMemberOrCustomer,
    },
    patients: data.patients.map((patient) => {
      const skipNextOfKin =
        (patient as any).nextOfKinMembership === NextOfKinTypeEnum.NONE ||
        isEmergency ||
        patient.isDeceased;
      const p: Record<string, any> = {
        isRequester: !!patient.isRequester,
        isMemberOrCustomer: patient.isMemberOrCustomer,
        isDeceased: !!patient.isDeceased,
        patientType: patient.patientType,
        skipNextOfKin,
        preferredHospitalId: patient.preferredHospitalId || undefined,
        bloodType: patient.bloodType || undefined,
        patientWeight: patient.patientWeight || undefined,
        primaryPhysicianName: patient.primaryPhysicianName || undefined,
        primaryPhysicianPhone: patient.primaryPhysicianPhone || undefined,
        levelOfEmergency: patient.levelOfEmergency || undefined,
        medicalNeeds: patient.medicalNeeds || undefined,
        canFlyCommercially: booleanToYesOrNo(patient.canFlyCommercial),
        requiresMedicalEscort: booleanToYesOrNo(patient.medicalEscort),
        preferredDestinationArrivalTime: patient.preferredDestinationArrivalTime
          ? formatDateToRequest(patient.preferredDestinationArrivalTime)
          : undefined,
        ...(skipNextOfKin
          ? {}
          : {
              nextOfKin: {
                relationshipToPatient:
                  patient?.nextOfKin?.relationshipToPatient,
                otherRelationToPatient:
                  patient.nextOfKin?.otherRelationToPatient,
                firstName: patient.nextOfKin?.firstName,
                lastName: patient.nextOfKin?.lastName,
                phone: patient.nextOfKin?.phone,
                email: patient.nextOfKin?.email || undefined,
                ...((patient as any).nextOfKinMembership ===
                NextOfKinTypeEnum.NEW
                  ? {}
                  : { contactID: patient.nextOfKin?.contactID }),
                isMemberOrCustomer:
                  patient.nextOfKin?.isMemberOrCustomer || false,
              },
            }),
      };

      if (patient.patientType !== PatientTypeEnum.NEW) {
        p.contactID = patient.contactID;
      } else {
        if (patient.email) {
          p.email = patient.email;
        }
        if (patient.dob) {
          p.dob = formatDateToRequest(patient.dob);
        }
        if (patient.age) {
          p.age = patient.age;
        }
        if (patient.gender) {
          p.gender = patient.gender;
        }
        if (patient.phone) {
          p.phone = patient.phone;
        }
        p.firstName = patient.firstName;
        p.lastName = patient.lastName;
      }

      if (patient.requesterRelationToPatient) {
        p.requesterRelationToPatient = patient.requesterRelationToPatient;
      }

      if (
        patient.requesterRelationToPatient ===
        ServiceCaseRequesterPatientRelationEnum.Other
      ) {
        p.requesterOtherRelationToPatient =
          patient.requesterOtherRelationToPatient;
      }
      return p;
    }),
    currentRequestType: serviceCase.requestType,
  };

  const getRequesterNextOfKin = (forPatient = false) => {
    const requesterNextOfKin = data.requester.nextOfKin;

    if (
      typeof requesterNextOfKin?.email === "string" &&
      !requesterNextOfKin?.email
    ) {
      requesterNextOfKin.email = undefined;
    }

    if (!requesterNextOfKin) {
      return undefined;
    }

    if ("isMemberOrCustomer" in requesterNextOfKin) {
      delete (requesterNextOfKin as any).isMemberOrCustomer;
    }

    if (forPatient) {
      requesterNextOfKin.isMemberOrCustomer = false;
    }

    if (
      requesterNextOfKin?.relationshipToPatient !==
      NextOfKinRelationToPatientEnum.OTHER
    ) {
      delete requesterNextOfKin.otherRelationToPatient;
    }

    return requesterNextOfKin;
  };

  if (!data.requester.isMemberOrCustomer) {
    res.requester.firstName = data.requester.firstName;
    res.requester.lastName = data.requester.lastName;
    res.requester.phone = data.requester.phone;
    if (data.requester.email) {
      res.requester.email = data.requester.email;
    }

    if (data.requester.isPatient) {
      res.requester.gender = data.requester.gender;
      if (data.requester.dob) {
        res.requester.dob = formatDateToRequest(data.requester.dob);
      } else if (data.requester.age) {
        res.requester.age = data.requester.age;
      }
      res.requester.nextOfKin = getRequesterNextOfKin();
      res.requester.preferredHospitalId =
        data.requester.preferredHospitalId || undefined;
      res.requester.bloodType = data.requester.bloodType || undefined;
      res.requester.patientWeight = data.requester.patientWeight || undefined;
      res.requester.primaryPhysicianName =
        data.requester.primaryPhysicianName || undefined;
      res.requester.primaryPhysicianPhone =
        data.requester.primaryPhysicianPhone || undefined;
      res.requester.levelOfEmergency =
        data.requester.levelOfEmergency || undefined;
      res.requester.medicalNeeds = data.requester.medicalNeeds || undefined;
      res.requester.canFlyCommercially =
        booleanToYesOrNo(data.requester.canFlyCommercial) || undefined;
      res.requester.requiresMedicalEscort =
        booleanToYesOrNo(data.requester.medicalEscort) || undefined;
      res.requester.preferredDestinationArrivalTime = data.requester
        .preferredDestinationArrivalTime
        ? formatDateToRequest(data.requester.preferredDestinationArrivalTime)
        : undefined;
    }
  } else {
    res.requester.contactID = data.requester.contactID;
  }

  let requesterAge = 0;
  if (data.requester.dob) requesterAge = getAge(data.requester.dob);
  else requesterAge = data.requester.age || 0;

  if (res.requester.isPatient && !res.requester.isMemberOrCustomer) {
    const requestingPatientIndex = (res.patients as any[]).findIndex(
      (p) => p.isRequester
    );

    const requestingPatient = {
      isRequester: true,
      isDeceased: false,
      isMemberOrCustomer: false,
      requesterRelationToPatient: ServiceCaseRequesterPatientRelationEnum.Other,
      requesterOtherRelationToPatient: "Requester",
      nextOfKin: getRequesterNextOfKin(true),
      skipNextOfKin: isEmergency || requesterAge >= 18,
      phone: res.requester.phone,
      firstName: res.requester.firstName,
      lastName: res.requester.lastName,
      gender: res.requester.gender,
      ...(() => {
        const p: Record<string, unknown> = {};
        if (res.requester.email) {
          p.email = res.requester.email;
        }
        if (data.requester.dob) {
          p.dob = formatDateToRequest(data.requester.dob);
        }
        if (res.requester.age) {
          p.age = res.requester.age;
        }
        return p;
      })(),
      preferredHospitalId: res.requester.preferredHospitalId || undefined,
      bloodType: res.requester.bloodType,
      patientWeight: res.requester.patientWeight,
      primaryPhysicianName: res.requester.primaryPhysicianName,
      primaryPhysicianPhone: res.requester.primaryPhysicianPhone,
      levelOfEmergency: res.requester.levelOfEmergency,
      medicalNeeds: res.requester.medicalNeeds,
      canFlyCommercially: res.requester.canFlyCommercial,
      requiresMedicalEscort: res.requester.medicalEscort,
      preferredDestinationArrivalTime:
        res.requester.preferredDestinationArrivalTime,
    };

    if (requestingPatientIndex !== -1) {
      res.patients[requestingPatientIndex] = requestingPatient;
    } else {
      res.patients = [...res.patients, requestingPatient];
    }
  }

  if (
    typeof res.requester.nextOfKin?.email === "string" &&
    !!res.requester.nextOfKin?.email
  ) {
    res.requester.nextOfKin.email = undefined;
  }

  return res;
};

export const getGeneralAddressString = (address?: GeneralAddress | null) => {
  const addressArray: string[] = [];
  if (!address) {
    return "";
  }
  const keys: (keyof GeneralAddress)[] = [
    "country",
    "city",
    "island",
    "street",
    "pobox",
  ];
  keys.forEach((key) => {
    const value = address[key];
    if (value) {
      addressArray.push(value);
    }
  });
  return addressArray.join(", ");
};

export const transformRelationToApplicantEnum = (
  expect:
    | "NextOfKinRelationToPatientEnum"
    | "ServiceCaseRequesterPatientRelationEnum",
  relationship?:
    | RelationToApplicantEnum
    | NextOfKinRelationToPatientEnum
    | ServiceCaseRequesterPatientRelationEnum
    | string
) => {
  if (!relationship) {
    return "";
  }
  const relation: Record<
    RelationToApplicantEnum,
    NextOfKinRelationToPatientEnum | ServiceCaseRequesterPatientRelationEnum
  > = {
    [RelationToApplicantEnum.parent]:
      expect === "NextOfKinRelationToPatientEnum"
        ? NextOfKinRelationToPatientEnum.PARENT
        : ServiceCaseRequesterPatientRelationEnum.PARENT,
    [RelationToApplicantEnum.Spouse]:
      expect === "NextOfKinRelationToPatientEnum"
        ? NextOfKinRelationToPatientEnum.SPOUSE
        : ServiceCaseRequesterPatientRelationEnum.SPOUSE,
    [RelationToApplicantEnum.other]:
      expect === "NextOfKinRelationToPatientEnum"
        ? NextOfKinRelationToPatientEnum.OTHER
        : ServiceCaseRequesterPatientRelationEnum.Other,
    [RelationToApplicantEnum.child]:
      expect === "NextOfKinRelationToPatientEnum"
        ? NextOfKinRelationToPatientEnum.CHILD
        : ServiceCaseRequesterPatientRelationEnum.CHILD,
  };

  if (
    Object.values(RelationToApplicantEnum)
      .map((c) => c?.toLowerCase())
      .includes(relationship.toLowerCase())
  ) {
    const res = relation[relationship as RelationToApplicantEnum];
    return expect === "NextOfKinRelationToPatientEnum"
      ? (res as NextOfKinRelationToPatientEnum)
      : (res as ServiceCaseRequesterPatientRelationEnum);
  }

  return expect === "NextOfKinRelationToPatientEnum"
    ? (relationship as NextOfKinRelationToPatientEnum)
    : (relationship as ServiceCaseRequesterPatientRelationEnum);
};

export const getDefaultPatient = (
  partialValues: Partial<PatientData>
): PatientData => ({
  isMemberOrCustomer: false,
  isRequester: false,
  isDeceased: false,
  id: Math.random().toString(),
  age: "" as unknown as number,
  gender: "" as unknown as GenderEnum,
  dob: "",
  email: "",
  phone: "",
  firstName: "",
  lastName: "",
  requesterRelationToPatient:
    "" as unknown as ServiceCaseRequesterPatientRelationEnum,
  requesterOtherRelationToPatient: "",
  patientType: "" as PatientTypeEnum,
  nextOfKinMembership: "",
  nextOfKin: {
    relationshipToPatient: "" as unknown as NextOfKinRelationToPatientEnum,
    otherRelationToPatient: "",
    email: "",
    phone: "",
    firstName: "",
    lastName: "",
    isMemberOrCustomer: false,
  },
  ...partialValues,
  bloodType: "" as BloodTypeEnum,
  patientWeight: "" as unknown as number,
  primaryPhysicianName: "",
  primaryPhysicianPhone: "",
  levelOfEmergency: "" as PatientLevelOfEmergencyEnum,
  medicalNeeds: "",
  canFlyCommercial: false,
  medicalEscort: false,
  preferredDestinationArrivalTime: "",
});

export const getShowExistingNextOfKinComponent = (
  isDeceased?: boolean,
  requestType?: ServiceCaseRequestTypeEnum
) => {
  if (isDeceased || requestType === ServiceCaseRequestTypeEnum.EMERGENCY) {
    return false;
  }
  return true;
};

export const parseDate = (date: string) => {
  try {
    return new Date(date);
  } catch (error) {
    return "";
  }
};
