import { Button } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { numberToTabsEnum, tabsEnumToNumber } from "../../helpers";
import { TabsEnum } from "../../types";
import { getIsBothObjectsEqual } from "./helpers";
import { UnsavedChangesDialog } from "./UnsavedChangesDialog";

export interface BackButtonProps<T> {
  disabled?: boolean;
  switchTab: (tab: TabsEnum) => void;
  activeTab: TabsEnum;
  oldValues: T;
  newValues: T;
  handleSaveValues: (values: T) => void;
}
type Values = unknown;

export const BackButton = <T extends Values>({
  disabled,
  switchTab,
  activeTab,
  oldValues,
  newValues,
  handleSaveValues,
}: BackButtonProps<T>): JSX.Element => {
  const [openUnsavedChangesDialog, setOpenUnsavedChangesDialog] =
    useState(false);

  const goBack = useCallback(() => {
    const previous = tabsEnumToNumber(activeTab) - 1;
    switchTab(numberToTabsEnum(previous < 0 ? 0 : previous));
  }, [switchTab, activeTab]);

  const handleClose = useCallback(() => {
    setOpenUnsavedChangesDialog(false);
  }, []);

  const onClickBack = useCallback(() => {
    if (getIsBothObjectsEqual(oldValues, newValues)) {
      goBack();
    } else {
      setOpenUnsavedChangesDialog(true);
    }
  }, [goBack, oldValues, newValues]);

  const handleSave = useCallback(() => {
    handleSaveValues(newValues);
    goBack();
    handleClose();
  }, [newValues, handleClose, goBack, handleSaveValues]);

  const handleDiscard = useCallback(() => {
    handleSaveValues(oldValues);
    handleClose();
    goBack();
  }, [oldValues, handleSaveValues, handleClose, goBack]);

  return (
    <>
      <UnsavedChangesDialog
        open={openUnsavedChangesDialog}
        handleDiscard={handleDiscard}
        handleClose={handleClose}
        handleSave={handleSave}
      />
      <Button
        color="primary"
        variant="outlined"
        onClick={onClickBack}
        disabled={disabled}
      >
        Back
      </Button>
    </>
  );
};
