import {
  Itinerary,
  ServiceCaseRequestTypeEnum,
  ServiceCaseResponse,
  ServiceCaseStatusEnum,
  ServiceCaseStatusGroupEnum,
  ServiceTypesEnum,
  UserRoleEnum,
} from "@deep-consulting-solutions/be2-constants";
import { MutableRefObject } from "react";
import { Action } from "pages/FulfilmentDashboard/types";
import { getENText } from "helpers";
import { ServiceCaseResponseExtended } from "@deep-consulting-solutions/be2-constants/build/types/types";
import { deepEqual } from "fast-equals";

/**
 * Clean filter object for ...service-cases/all? endpoint
 * @param obj
 */
export const cleanParamObject = (obj: Record<string, any>) => {
  return Object.entries(obj).reduce((res: Record<string, any>, [key, val]) => {
    const nVal = Array.isArray(val) ? val.join().trim() : val;
    if (nVal) {
      res[key] = nVal;
    }
    return res;
  }, {});
};

export const getStatusGroup = (status: ServiceCaseStatusEnum) => {
  switch (status) {
    case ServiceCaseStatusEnum.UNANSWERED:
    case ServiceCaseStatusEnum.GATHERING_REQUIREMENTS:
    case ServiceCaseStatusEnum.PENDING_MEDICAL_ASSESSMENT:
    case ServiceCaseStatusEnum.PENDING_BE_ACCEPTANCE:
    case ServiceCaseStatusEnum.PENDING_PLANNING:
      return ServiceCaseStatusGroupEnum.NEW;
    case ServiceCaseStatusEnum.SERVICE_SCHEDULED:
      return ServiceCaseStatusGroupEnum.SCHEDULED;
    case ServiceCaseStatusEnum.SERVICE_STARTED:
    case ServiceCaseStatusEnum.PATIENT_RELEASED:
      return ServiceCaseStatusGroupEnum.IN_PROGRESS;
    case ServiceCaseStatusEnum.REFUSED:
    case ServiceCaseStatusEnum.CANCELLED:
    case ServiceCaseStatusEnum.COMPLETED:
    case ServiceCaseStatusEnum.SURVEY_COMPLETED:
      return ServiceCaseStatusGroupEnum.COMPLETED;
    case ServiceCaseStatusEnum.RE_OPENED:
      return ServiceCaseStatusGroupEnum.RE_OPENED;
    case ServiceCaseStatusEnum.CLOSED:
      return ServiceCaseStatusGroupEnum.CLOSED;
    default:
      return status;
  }
};

export const resolveResponseForMedicalDirectorDisplayMode = ({
  total,
  serviceCases,
}: {
  total: number;
  serviceCases: ServiceCaseResponse[];
}) => {
  return serviceCases.reduce(
    (res, cur) => {
      if (cur.caseStatus === ServiceCaseStatusEnum.PENDING_MEDICAL_ASSESSMENT) {
        res.pendingMedicalAssessment = {
          count: +res.pendingMedicalAssessment.count + 1,
          data: [...res.pendingMedicalAssessment.data, cur],
        };
      }
      return res;
    },
    {
      total,
      pendingMedicalAssessment: {
        count: 0,
        data: [] as ServiceCaseResponse[],
      },
    }
  );
};

export const resolveResponseWithStatusGroup = ({
  statusGroup,
  total,
  perPage,
  serviceCases,
  ...rest
}: {
  statusGroup: ServiceCaseStatusGroupEnum;
  total: number;
  perPage: number;
  serviceCases: ServiceCaseResponse[];
}) => {
  return serviceCases.reduce(
    (res, cur) => {
      if (getStatusGroup(cur.caseStatus) === statusGroup) {
        res.count = +res.count + 1;
        res.data = [...res.data, cur];
      }
      return res;
    },
    {
      total,
      perPage,
      count: 0,
      data: [] as ServiceCaseResponse[],
      ...rest,
    }
  );
};

export const getSnippetColor = (status: ServiceCaseStatusEnum) => {
  switch (status) {
    case ServiceCaseStatusEnum.PENDING_MEDICAL_ASSESSMENT:
      return { tag: "#2F80ED", bg: "#ECF8FF", text: "#ffffff" };
    case ServiceCaseStatusEnum.PENDING_BE_ACCEPTANCE:
      return { tag: "#FCC221", bg: "#FFF3D3", text: "#333333" };
    case ServiceCaseStatusEnum.PENDING_PLANNING:
      return { tag: "#ED6C02", bg: "#FFF3E9", text: "#ffffff" };
    default:
      return { tag: "", bg: "", text: "#4F4F4F" };
  }
};

export const sortResponseForMedicalDirectorFilter = ({
  total,
  serviceCases,
  perPage,
  page = 1,
}: {
  total: number;
  perPage: number;
  page: number;
  serviceCases: ServiceCaseResponseExtended[];
}) => {
  return serviceCases.reduce(
    (res, cur) => {
      res.data.push(cur);
      return res;
    },
    {
      total,
      data: [] as Array<ServiceCaseResponseExtended>,
      perPage,
      page,
    }
  );
};

export const scrollToRef = (ref: MutableRefObject<any>) => {
  return window.scrollTo({
    top: ref.current.offsetTop,
  });
};

export const getStatusOptions = (value: ServiceCaseStatusGroupEnum[]) => {
  return value.reduce((acc: ServiceCaseStatusEnum[], cur) => {
    if (cur === ServiceCaseStatusGroupEnum.IN_PROGRESS) {
      acc.push(ServiceCaseStatusEnum.SERVICE_STARTED);
      acc.push(ServiceCaseStatusEnum.PATIENT_RELEASED);
    } else if (cur === ServiceCaseStatusGroupEnum.SCHEDULED) {
      acc.push(ServiceCaseStatusEnum.SERVICE_SCHEDULED);
    } else if (cur === ServiceCaseStatusGroupEnum.RE_OPENED) {
      acc.push(ServiceCaseStatusEnum.RE_OPENED);
    } else if (cur === ServiceCaseStatusGroupEnum.CLOSED) {
      acc.push(ServiceCaseStatusEnum.CLOSED);
    } else if (cur === ServiceCaseStatusGroupEnum.NEW) {
      acc.push(ServiceCaseStatusEnum.UNANSWERED);
      acc.push(ServiceCaseStatusEnum.GATHERING_REQUIREMENTS);
      acc.push(ServiceCaseStatusEnum.PENDING_MEDICAL_ASSESSMENT);
      acc.push(ServiceCaseStatusEnum.PENDING_PLANNING);
      acc.push(ServiceCaseStatusEnum.PENDING_BE_ACCEPTANCE);
    } else if (cur === ServiceCaseStatusGroupEnum.COMPLETED) {
      acc.push(ServiceCaseStatusEnum.REFUSED);
      acc.push(ServiceCaseStatusEnum.CANCELLED);
      acc.push(ServiceCaseStatusEnum.COMPLETED);
    }
    return acc;
  }, []);
};

export const getServiceOptions = (value: ServiceCaseRequestTypeEnum) => {
  const acc = [];
  if (value === ServiceCaseRequestTypeEnum.EMERGENCY) {
    acc.push(ServiceTypesEnum.EMERGENCY_AIRLIFT_EVACUATION);
    acc.push(ServiceTypesEnum.EMERGENCY_GROUND_TRANSPORT);
    acc.push(ServiceTypesEnum.EMERGENCY_MARITIME_TRANSPORT);
  } else if (value === ServiceCaseRequestTypeEnum.SCHEDULED_TRANSPORT) {
    acc.push(ServiceTypesEnum.HOSPITAL_TRANSFER);
    acc.push(ServiceTypesEnum.REPATRIATION);
    acc.push(ServiceTypesEnum.CUSTOM);
  } else if (value === ServiceCaseRequestTypeEnum.INFORMATION_SUPPORT) {
    acc.push(ServiceTypesEnum.INFORMATIONAL_SUPPORT);
  }
  return acc;
};

export const renderItinerarySections = (data: Itinerary[]) => {
  data.reduce((acc: any[], cur) => {
    acc.push(cur);
    return acc;
  }, []);
  return [1, 2, 3, 4];
};

export const renderServiceCaseActions = (
  role: UserRoleEnum,
  status: ServiceCaseStatusEnum,
  isMedicalDirector: boolean,
  isDispatcher: boolean
) => {
  const group = getStatusGroup(status);
  if (group !== ServiceCaseStatusGroupEnum.CLOSED) {
    if (isMedicalDirector)
      return status === ServiceCaseStatusEnum.PENDING_MEDICAL_ASSESSMENT
        ? [Action.MedicalAssessment, Action.Crm]
        : [Action.Crm];

    if (isDispatcher) {
      return [Action.Crm];
    }

    switch (role) {
      case UserRoleEnum.Manager:
      case UserRoleEnum.Administrator:
      case UserRoleEnum.BusinessOwner:
        return group === ServiceCaseStatusGroupEnum.COMPLETED
          ? [Action.Assign, Action.Close, Action.Crm]
          : [Action.Assign, Action.Crm];

      default:
        return [];
    }
  } else if (group === ServiceCaseStatusGroupEnum.CLOSED) {
    if (isMedicalDirector) return [Action.Crm];

    if (isDispatcher) return [Action.Crm];

    switch (role) {
      case UserRoleEnum.Manager:
      case UserRoleEnum.Administrator:
      case UserRoleEnum.BusinessOwner:
        return [Action.Crm];
      default:
        return [];
    }
  } else {
    return [];
  }
};

export const reduceReceivingPhysicianErr = (
  acc: Record<number, any>,
  cur: Record<string, string>,
  i: number
) => {
  if (!cur.firstName) {
    acc[i] = {
      ...(acc[i] || {}),
      firstName: getENText("validation.firstName.required"),
    };
  }
  if (!cur.lastName) {
    acc[i] = {
      ...(acc[i] || {}),
      lastName: getENText("validation.lastName.required"),
    };
  }
  if (!cur.title) {
    acc[i] = {
      ...(acc[i] || {}),
      title: getENText("validation.title.required"),
    };
  }
  return acc;
};

const fieldsForUpdate: Array<keyof ServiceCaseResponseExtended> = [
  "service",
  "overdue",
  "patients",
  "requester",
  "updatedAt",
  "createdAt",
  "dispatcher",
  "caseStatus",
  "requestType",
  "initialLocation",
];

export const shouldUpdateServiceCase = (
  modifiedFields: Array<keyof ServiceCaseResponseExtended>
) => {
  return modifiedFields.some((field) => fieldsForUpdate.includes(field));
};

export const serviceCaseNeedsUpdate = (
  prevCase: ServiceCaseResponse | ServiceCaseResponseExtended,
  serviceCase: ServiceCaseResponse | ServiceCaseResponseExtended,
  fields: Array<keyof ServiceCaseResponse>
) => {
  return fields.some(
    (field) => !deepEqual(prevCase[field], serviceCase[field])
  );
};
