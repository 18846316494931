import React from "react";
import { useStyles } from "./style";
import { Icon } from "./types";

export const FourSideB = ({ onClick }: Icon) => {
  const classes = useStyles();
  return (
    <svg
      width="34"
      height="29"
      viewBox="0 0 34 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={classes.icon}
    >
      <path
        d="M1 5C1 2.79086 2.79086 1 5 1H29C31.2091 1 33 2.79086 33 5V24C33 26.2091 31.2091 28 29 28H5C2.79086 28 1 26.2091 1 24V5Z"
        fill="#F7F7F7"
      />
      <rect x="7" y="7" width="8" height="6.5" rx="1" stroke="#828282" />
      <rect x="7" y="15.5" width="8" height="6.5" rx="1" stroke="#828282" />
      <rect x="18" y="7" width="3.5" height="15" rx="1" stroke="#828282" />
      <rect x="23.5" y="7" width="3.5" height="15" rx="1" stroke="#828282" />
      <path
        d="M5 1.5H29V0.5H5V1.5ZM32.5 5V24H33.5V5H32.5ZM29 27.5H5V28.5H29V27.5ZM1.5 24V5H0.5V24H1.5ZM5 27.5C3.067 27.5 1.5 25.933 1.5 24H0.5C0.5 26.4853 2.51472 28.5 5 28.5V27.5ZM32.5 24C32.5 25.933 30.933 27.5 29 27.5V28.5C31.4853 28.5 33.5 26.4853 33.5 24H32.5ZM29 1.5C30.933 1.5 32.5 3.067 32.5 5H33.5C33.5 2.51472 31.4853 0.5 29 0.5V1.5ZM5 0.5C2.51472 0.5 0.5 2.51472 0.5 5H1.5C1.5 3.067 3.067 1.5 5 1.5V0.5Z"
        fill="#E0E0E0"
      />
    </svg>
  );
};
