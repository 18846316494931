import React from "react";
import { Box, Theme, makeStyles, Typography } from "@material-ui/core";
import {
  ServiceCaseStatusEnum,
  ServiceCaseRequestTypeEnum,
  ServiceCaseStatusGroupEnum,
} from "@deep-consulting-solutions/be2-constants";

import { getStatusGroup } from "../helpers";
import "./SnippetInfoWindow.css";

interface SnippetInfoWindowProps {
  caseNumber: string;
  onTitleClick: () => void;
  caseStatus: ServiceCaseStatusEnum;
  requestType: ServiceCaseRequestTypeEnum;
}

interface StyleProps {
  isEmergency: boolean;
  backgroundColor: string;
}

const useStyles = makeStyles<Theme, StyleProps>(
  ({ spacing: s, palette: p }) => ({
    root: {
      padding: s(1),
      minWidth: s(9),
      background: ({ backgroundColor }) => backgroundColor,
      borderRadius: ({ isEmergency }) => (isEmergency ? `8px 8px 0 0` : s(1)),
      border: ({ isEmergency }) => (isEmergency ? "1px solid #CA2027" : "none"),
    },
    caseNumber: {
      fontSize: 10,
      cursor: "pointer",
      marginBottom: "2px",
      color: p.primary.dark,
    },
    statusGroup: {
      fontSize: 12,
      color: "#333",
      fontWeight: 700,
      fontFamily: `"Lato", "Roboto", sans-serif`,
    },
    emergency: {
      height: s(2),
      minWidth: s(9),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "0 0 8px 8px",
      backgroundColor: "#CA2027",
      border: "1px solid #CA2027",
    },
    emergencyText: {
      fontSize: 10,
      color: "white",
      fontWeight: 700,
      lineHeight: "11px",
    },
  })
);

const SnippetInfoWindow = ({
  caseNumber,
  caseStatus,
  requestType,
  onTitleClick,
}: SnippetInfoWindowProps) => {
  const isEmergency = requestType === ServiceCaseRequestTypeEnum.EMERGENCY;

  let backgroundColor = "white";

  switch (getStatusGroup(caseStatus)) {
    case ServiceCaseStatusGroupEnum.NEW:
      backgroundColor = "#E6F0FD";
      break;

    case ServiceCaseStatusGroupEnum.SCHEDULED:
      backgroundColor = "#FFF3E9";
      break;

    case ServiceCaseStatusGroupEnum.IN_PROGRESS:
      backgroundColor = "#E1F4E2";
      break;

    default:
      break;
  }

  const classes = useStyles({ isEmergency, backgroundColor });

  return (
    <>
      <Box className={classes.root}>
        <Typography className={classes.caseNumber} onClick={onTitleClick}>
          {caseNumber}
        </Typography>

        <Typography
          component="p"
          variant="subtitle2"
          className={classes.statusGroup}
        >
          {getStatusGroup(caseStatus)}
        </Typography>
      </Box>

      {isEmergency && (
        <Box className={classes.emergency}>
          <Typography
            component="span"
            variant="subtitle2"
            className={classes.emergencyText}
          >
            Emergency
          </Typography>
        </Box>
      )}
    </>
  );
};

export default SnippetInfoWindow;
