import { makeStyles, Typography } from "@material-ui/core";
import { useCall } from "call/hooks";
import { appendQueryParam, getQueryParam } from "helpers/queryParams";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CallChatTabPanel } from "./CallChatTabPanel";
import { callChatTabs, numberToTabsEnum, tabsEnumToNumber } from "./helpers";
import { TabsEnum } from "./types";

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  wrapper: {
    width: "100%",
    background: "#FFFFFF",
    borderRadius: 8,
    boxShadow: "0px -4px 20px rgba(38, 62, 127, 0.1)",
    height: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
  },
  title: {
    fontWeight: 800,
  },
  tabs: {
    background: "#EFEFEF",
    borderRadius: 4,
    margin: s(2, 0),
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "center",
    padding: 2,
  },
  tabsWrapper: {
    display: "flex",
    justifyContent: "center",
    borderRadius: 4,
    padding: s(2),
  },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: 10,
  },
  panelWrapper: {
    flex: 1,
    overflow: "hidden",
  },
  tabButton: {
    borderRadius: 4,
    display: "block",
    padding: s(2, 4),
    color: p.primary.main,
    border: 0,
    outline: 0,
    cursor: "pointer",
    "&:disabled": {
      cursor: "not-allowed",
    },
  },
}));

const queryName = "chatTab";

export const CallChat = () => {
  const { chatMedia, chatRecordings } = useCall();

  const [activeTab, setActiveTab] = useState(
    tabsEnumToNumber(TabsEnum.MESSAGES)
  );

  const location = useLocation();
  const history = useHistory();

  const handleSwitchTab = useCallback(
    (value: number) => {
      setActiveTab(value);
      history.push(
        appendQueryParam(location.pathname, [
          { key: queryName, value: numberToTabsEnum(value) },
        ])
      );
    },
    [history, location]
  );

  const checkDisabledTab = useCallback(
    (label: TabsEnum) => {
      if (label === TabsEnum.MEDIA && !chatMedia.length) {
        return true;
      }
      if (label === TabsEnum.RECORDINGS && !chatRecordings.length) {
        return true;
      }
      return false;
    },
    [chatRecordings.length, chatMedia.length]
  );

  useEffect(() => {
    const value = getQueryParam(queryName) as TabsEnum;
    if (value) {
      const indexOfQueryFromTab = tabsEnumToNumber(value);
      if (indexOfQueryFromTab !== activeTab) {
        if (
          (value === TabsEnum.MEDIA && !chatMedia.length) ||
          (value === TabsEnum.RECORDINGS && !chatRecordings.length)
        ) {
          setActiveTab(tabsEnumToNumber(TabsEnum.MESSAGES));
        } else {
          setActiveTab(indexOfQueryFromTab);
        }
      }
    }
  }, [activeTab, chatMedia.length, chatRecordings.length]);

  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.tabsWrapper}>
        <div className={classes.tabs} aria-label="call chat tabs">
          {callChatTabs.map((tab) => (
            <button
              onClick={() => handleSwitchTab(tab.index)}
              key={tab.label}
              color="primary"
              type="button"
              disabled={checkDisabledTab(tab.label)}
              className={classes.tabButton}
              style={{
                background: activeTab === tab.index ? "white" : undefined,
              }}
            >
              <Typography
                variant="body2"
                style={{
                  color: activeTab === tab.index ? undefined : "#828282",
                  fontWeight: activeTab === tab.index ? 500 : undefined,
                }}
                className={classes.tabLabel}
              >
                {tab.label}
              </Typography>
            </button>
          ))}
        </div>
      </div>
      <div className={classes.panelWrapper}>
        {callChatTabs.map((tab) => (
          <CallChatTabPanel
            key={tab.label}
            index={tab.index}
            value={activeTab}
            label={tab.label}
            {...tab.props}
          />
        ))}
      </div>
    </div>
  );
};
