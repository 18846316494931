import React, { FC, useEffect, useMemo, useRef } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { SIDE_BAR_MAX_HEIGHT } from "constants/style";
import { useCall } from "call/hooks";
import { CallNotificationWrapper } from "../CallNotificationWrapper";
import { CallNotification } from "../CallNotification";
import { OngoingCallsComponent } from "../OngoingCallsComponent";
import { CallLayout } from "../CallLayout";
import { CallCut } from "../CallCut";

const useStyles = makeStyles<Theme, { isOnCall: boolean }>(
  ({ spacing: s }) => ({
    wrapper: ({ isOnCall }) => ({
      position: "relative",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      justifyContent: "flex-start",
      ...(isOnCall
        ? {
            height: SIDE_BAR_MAX_HEIGHT,
            boxSizing: "border-box",
            padding: s(2, 0),
            overflow: "hidden",
          }
        : {
            minHeight: SIDE_BAR_MAX_HEIGHT,
          }),
    }),
    container: {
      position: "relative",
      paddingTop: ({ isOnCall }) => s(isOnCall ? 2 : 0),
      overflow: "hidden",
    },
  })
);

export const CallWrapper: FC = ({ children }) => {
  const { ringingCalls, ongoingCalls, callSource, viewedCall } = useCall();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const callsRef = useRef<HTMLDivElement | null>(null);
  const layoutRef = useRef<HTMLDivElement | null>(null);

  const isOnCall = useMemo(() => !!ongoingCalls.length, [ongoingCalls.length]);

  const classes = useStyles({ isOnCall });

  useEffect(() => {
    const wrapperElement = wrapperRef.current;
    const layoutElement = layoutRef.current;
    const callsElement = callsRef.current;
    if (wrapperElement && layoutElement && callsElement) {
      const wrapperHeight = wrapperElement.offsetHeight;
      if (isOnCall) {
        const callsHeight = callsElement.offsetHeight;
        layoutElement.setAttribute(
          "style",
          `height:${wrapperHeight - callsHeight}px;`
        );
      } else {
        layoutElement.setAttribute("style", `height:${wrapperHeight}px;`);
      }
    }
  }, [isOnCall]);

  return (
    <div ref={wrapperRef} className={classes.wrapper}>
      <div ref={callsRef} className={classes.activeCallId}>
        {isOnCall && <OngoingCallsComponent />}
      </div>
      <div ref={layoutRef} className={classes.container}>
        {callSource === "call" && !!viewedCall ? <CallLayout /> : children}
        <CallNotificationWrapper>
          {ringingCalls.map((call) => (
            <CallNotification {...call} key={call.sessionId} />
          ))}
        </CallNotificationWrapper>
        <CallCut />
      </div>
    </div>
  );
};
