import React, { useCallback } from "react";
import {
  TextField,
  TextFieldProps as MuiTextFieldProps,
} from "@material-ui/core";
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
} from "react-number-format";
import { FieldProps, getIn } from "formik";

export interface TextFieldProps
  extends FieldProps,
    Omit<
      MuiTextFieldProps,
      "name" | "value" | "error" | "ref" | "defaultValue" | "type"
    > {}

export interface FormikNumberFieldProps extends TextFieldProps {
  /**
   * - https://github.com/s-yadav/react-number-format#props
   * - exclude displayType
   */
  valueType?: "string" | "number" | "formatted";
  numberFormatProps?: Omit<NumberFormatProps, "displayType">;
}

/**
 * - Input format is customizable as it uses https://www.npmjs.com/package/react-number-format internally.
 * - Should be used with formik and material ui.
 * - The component can receive any of material ui TextField props https://material-ui.com/api/text-field/#props
 */
export const FormikNumberField = ({
  form: { errors, touched, setFieldValue, isSubmitting },
  field: { name, value, onBlur },
  numberFormatProps,
  disabled,
  helperText,
  size,
  valueType = "number",
  ...textFieldProps
}: FormikNumberFieldProps) => {
  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  const onValueChange = useCallback(
    (values: NumberFormatValues) => {
      let newValue: number | string | undefined;
      if (valueType === "number") {
        newValue =
          typeof values.floatValue === "number" ? values.floatValue : "";
      } else if (valueType === "string") {
        newValue = values.value;
      } else if (valueType === "formatted") {
        newValue = values.formattedValue;
      }
      setFieldValue(name, newValue);
    },
    [name, setFieldValue, valueType]
  );

  return (
    <NumberFormat
      autoComplete="off"
      size={size as any}
      {...textFieldProps}
      value={value}
      name={name}
      error={showError}
      helperText={showError ? fieldError : helperText}
      disabled={disabled ?? isSubmitting}
      onBlur={onBlur}
      customInput={TextField as any}
      allowNegative={false}
      {...numberFormatProps}
      onValueChange={onValueChange}
    />
  );
};
