import { Chat, ChatAttachment } from "@deep-consulting-solutions/be2-constants";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useCall } from "call/hooks";
import Loader from "component/Loader";
import { format } from "date-fns";
import React, { useMemo, useCallback, useState, useEffect } from "react";
import { getAttachmentSrc } from "./helpers";

type MappedMedia = Record<string, Chat[]>;

const useStyles = makeStyles(({ spacing: s }) => ({
  wrapper: {
    display: "flex",
    height: "100%",
    padding: s(2),
    flexFlow: "column nowrap",
    flex: 1,
    overflow: "hidden",
  },
  title: {
    fontSize: 10,
  },
  group: {
    marginBottom: s(4),
  },
  imageWrapper: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  images: {
    padding: s(2, 0),
  },
  mediaWrapper: {
    width: "100%",
    height: "100%",
    overflow: "hidden auto",
  },
  relative: {
    position: "relative",
  },
}));

const ImageAttachment = ({
  attachment,
}: {
  attachment: ChatAttachment & {
    localUrl?: string;
    base64?: string | ArrayBuffer | null;
    url?: string;
  };
}) => {
  const [attachmentFile, setAttachmentFile] = useState<string | undefined>("");
  const [loading, setLoading] = useState(false);
  const { viewedCall } = useCall();

  const getAttachmentFile = useCallback(async () => {
    try {
      setLoading(true);
      if (!attachment) {
        return undefined;
      }
      const url = await getAttachmentSrc({
        ...attachment,
        serviceCaseId: viewedCall?.updatedServiceCase?.id,
      });
      setAttachmentFile(url);
    } catch {
      //
    } finally {
      setLoading(false);
    }
  }, [attachment, viewedCall?.updatedServiceCase?.id]);

  useEffect(() => {
    getAttachmentFile();
  }, [getAttachmentFile]);
  const classes = useStyles();

  return (
    <Grid xs={3} item className={classes.relative}>
      <Loader open={loading} absolute />
      <img
        className={classes.imageWrapper}
        src={attachmentFile}
        alt={attachment.fileName}
      />
    </Grid>
  );
};

export const CallMedia = () => {
  const { chatMedia } = useCall();
  const classes = useStyles();

  const mappedMedia = useMemo(() => {
    return chatMedia.reduce((previousValue: MappedMedia, currentValue) => {
      const formattedDate = format(new Date(currentValue.time), "dd MMM yyyy");

      const oldValue = previousValue[formattedDate];
      previousValue[formattedDate] = oldValue
        ? oldValue.concat(currentValue)
        : [currentValue];

      return previousValue;
    }, {} as MappedMedia);
  }, [chatMedia]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.mediaWrapper}>
        {Object.entries(mappedMedia).map(([key, value]) => (
          <div className={classes.group} key={key}>
            <Typography
              color="primary"
              variant="caption"
              className={classes.title}
            >
              {key}
            </Typography>
            <Grid
              className={classes.images}
              container
              spacing={1}
              wrap="wrap"
              alignItems="stretch"
            >
              {value.map((medium) =>
                medium.attachments?.map((attachment) => (
                  <ImageAttachment
                    key={attachment.s3Key}
                    attachment={attachment}
                  />
                ))
              )}
            </Grid>
          </div>
        ))}
      </div>
    </div>
  );
};
