import { Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { useCall } from "call/hooks";
import { ServiceTitle } from "../components";
import { getIsServiceCaseApproved, getIsServiceCaseRefused } from "../helpers";

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  divider: {
    margin: s(2, 0),
  },
  medTitle: {
    color: p.primary.main,
    fontSize: 14,
    fontWeight: 600,
    marginBottom: s(1),
  },
  medText: {
    fontSize: 14,
    color: p.grey[600],
  },
}));

export const MedicalProfessionals = () => {
  const { viewedCall } = useCall();
  const isServiceCaseAccepted = useMemo(() => {
    return getIsServiceCaseApproved(
      viewedCall?.updatedServiceCase?.approvalRequests
    );
  }, [viewedCall?.updatedServiceCase?.approvalRequests]);

  const isServiceCaseRefused = useMemo(
    () =>
      getIsServiceCaseRefused(viewedCall?.updatedServiceCase?.approvalRequests),
    [viewedCall?.updatedServiceCase?.approvalRequests]
  );

  const visible = useMemo(
    () => isServiceCaseAccepted || isServiceCaseRefused,
    [isServiceCaseRefused, isServiceCaseAccepted]
  );
  const serviceCase = useMemo(
    () => viewedCall?.updatedServiceCase,
    [viewedCall?.updatedServiceCase]
  );

  const classes = useStyles();

  if (!visible) {
    return null;
  }

  return (
    <>
      <ServiceTitle title="Medical Professionals" />

      <Grid container>
        <Grid item xs={6}>
          <Typography className={classes.medTitle}>
            Number and Specialty of Medical Professionals Required
          </Typography>
          <Typography className={classes.medText}>
            {serviceCase?.noAndSpecialityOfMedicalProfessionals || "-"}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.medTitle}>
            Recommended Medical Professionals
          </Typography>
          <Typography className={classes.medText}>
            {serviceCase?.recommendedMedicalProfessionals || "-"}
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
    </>
  );
};

/**
 * ? OLD DESIGN COMMENTED OUT
 */

// import { Divider, Grid, makeStyles, Typography } from "@material-ui/core";
// import React, { useCallback, useEffect, useMemo, useState } from "react";
// import {
//   MedicalProfessional,
//   MedicalSpecialty,
// } from "@deep-consulting-solutions/be2-constants";
// import { useCall } from "call/hooks";
// import Loader from "component/Loader";
// import {
//   getMedicalProfessionals,
//   getMedicalSpecialities,
// } from "redux/call/requests";
// import { RecommendedSpecialty } from "./RecommendedSpecialty";
// import { ServiceTitle } from "../components";
// import { RecommendedMedicalProfessional } from "./RecommendedMedicalProfessional";
// import { getIsServiceCaseApproved, getIsServiceCaseRefused } from "../helpers";

// const useStyles = makeStyles(({ spacing: s }) => ({
//   divider: {
//     margin: s(2, 0),
//   },
//   recommended: {
//     fontWeight: 500,
//     marginBottom: s(2),
//   },
//   recommendedContainer: {
//     border: "1px solid #BDBDBD",
//     borderRadius: 4,
//     marginBottom: s(2),
//     padding: s(1),
//   },
// }));

// export const MedicalProfessionals = () => {
//   const { viewedCall } = useCall();
//   const [specialties, setSpecialties] = useState<MedicalSpecialty[]>([]);
//   const [loading, setLoading] = useState(false);
//   const [medicalProfessionals, setMedicalProfessionals] = useState<
//     MedicalProfessional[]
//   >([]);

//   const isServiceCaseAccepted = useMemo(() => {
//     return getIsServiceCaseApproved(
//       viewedCall?.updatedServiceCase?.approvalRequests
//     );
//   }, [viewedCall?.updatedServiceCase?.approvalRequests]);

//   const isServiceCaseRefused = useMemo(
//     () =>
//       getIsServiceCaseRefused(viewedCall?.updatedServiceCase?.approvalRequests),
//     [viewedCall?.updatedServiceCase?.approvalRequests]
//   );

//   const visible = useMemo(
//     () => isServiceCaseAccepted || isServiceCaseRefused,
//     [isServiceCaseRefused, isServiceCaseAccepted]
//   );

//   const recommendedSpecialties = useMemo(() => {
//     return specialties.map((specialty) => specialty.specialityName);
//   }, [specialties]);

//   const recommendedMedicalProfessionals = useMemo(
//     () =>
//       medicalProfessionals.map((medicalProfessional) => ({
//         id: medicalProfessional.id,
//         name: medicalProfessional.name,
//         specialities: medicalProfessional.specialities,
//         organization: medicalProfessional.organization,
//       })),
//     [medicalProfessionals]
//   );

//   const fetchRecommendedMedicalProfessionals = useCallback(async () => {
//     try {
//       setLoading(true);
//       const res = await getMedicalProfessionals();
//       setMedicalProfessionals(res);
//     } catch {
//       //
//     } finally {
//       setLoading(false);
//     }
//   }, []);

//   const fetchRecommendedSpecialties = useCallback(async () => {
//     try {
//       setLoading(true);
//       const res = await getMedicalSpecialities();
//       setSpecialties(res);
//     } catch {
//       //
//     } finally {
//       setLoading(false);
//     }
//   }, []);

//   useEffect(() => {
//     fetchRecommendedSpecialties();
//   }, [fetchRecommendedSpecialties]);

//   useEffect(() => {
//     fetchRecommendedMedicalProfessionals();
//   }, [fetchRecommendedMedicalProfessionals]);

//   const classes = useStyles();

//   if (!visible) {
//     return null;
//   }

//   return (
//     <>
//       <ServiceTitle title="Medical Professionals" />
//       <Loader open={loading} />
//       <Typography variant="subtitle1" className={classes.recommended}>
//         Recommended Specialties
//       </Typography>

//       <div className={classes.recommendedContainer}>
//         <Grid
//           container
//           spacing={1}
//           alignItems="stretch"
//           justifyContent="flex-start"
//         >
//           {recommendedSpecialties.map((recommendedSpecialty) => (
//             <RecommendedSpecialty
//               key={recommendedSpecialty}
//               recommendedSpecialty={recommendedSpecialty}
//               recommendedMedicalProfessionals={recommendedMedicalProfessionals}
//             />
//           ))}
//         </Grid>
//       </div>

//       <Typography variant="subtitle1" className={classes.recommended}>
//         Recommended Medical Professionals
//       </Typography>

//       <Grid
//         container
//         spacing={2}
//         alignItems="stretch"
//         justifyContent="flex-start"
//       >
//         {recommendedMedicalProfessionals.map(
//           (recommendedMedicalProfessional) => (
//             <RecommendedMedicalProfessional
//               key={recommendedMedicalProfessional.id}
//               recommendedMedicalProfessional={recommendedMedicalProfessional}
//               recommendedSpecialties={recommendedSpecialties}
//             />
//           )
//         )}
//       </Grid>
//       <Divider className={classes.divider} />
//     </>
//   );
// };

/**
 * ? END OLD DESIGN
 */
