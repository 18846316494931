import {
  BloodTypeEnum,
  EmploymentStatusEnum,
  PatientResponse,
} from "@deep-consulting-solutions/be2-constants";

export enum Action {
  Assign = "assign case",
  Edit = "edit",
  Close = "close case",
  MedicalAssessment = "medical assessment",
  Crm = "Open In CRM",
}

export interface PatientResponseWithContact extends PatientResponse {
  subCoverage: unknown[];
  contact: {
    firstName: string;
    lastName: string;
    bloodType: BloodTypeEnum;
    citizenship: [];
    contactType: string;
    createdAt: string;
    creditAmount: number;
    dateOfBirth: string;
    doNotContact: boolean;
    emailForUpdate: null | boolean;
    emailOptOut: boolean;
    emergencyContactOtherRelation: null | string;
    emergencyContactRelation: null | string;
    employmentStatus: null | EmploymentStatusEnum;
    fax: null | string;
    hasPendingInvite: boolean;
    insurancePolicyNumber: null | string;
    insuranceProvider: string;
    isClaimed: boolean;
    isEmailVerified: boolean;
    isEmployerPrimaryContact: boolean;
    isPersonEligibleForVisaFreeEntranceIntoUS: null | string;
    isPersonInadmissibleToEnterUS: null | string;
    lastUpdateByAppUserId: null | string;
    lastUpdateByZohoUserId: null | string;
    lastUpdateSource: null | string;
    preferredHospital: null | string;
    primaryPhysicianName: null | string;
    primaryPhysicianPhone: null | string;
    updatedAt: string;
    zohoID: string;
    zohoInvoiceCustomerID: null | string;
  };
}
