import React from "react";
import {
  Box,
  Button,
  IconButton,
  makeStyles,
  styled,
  Theme,
  Typography,
} from "@material-ui/core";
import PhoneMissedIcon from "@material-ui/icons/PhoneMissed";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles<Theme, { standAlone?: boolean; isNew?: boolean }>(
  () => ({
    root: {
      maxWidth: 400,
      backgroundColor: ({ standAlone, isNew }) =>
        standAlone || isNew ? "#ECF8FF" : "#FFFFFF",
    },
  })
);

const NotifyBody = styled("div")({
  display: "flex",
  alignItems: "start",
  justifyContent: "space-between",
  color: "#192954",
  padding: "16px",
});

const NotificationSnippet = React.memo(
  ({
    standAlone,
    isNew,
    onOpen,
    id,
    timeInText,
    onClose,
  }: {
    standAlone?: boolean;
    isNew?: boolean;
    onOpen?: () => void;
    id: string | number;
    timeInText: string;
    onClose?: () => void;
  }) => {
    const style = useStyles({ standAlone, isNew });

    return (
      <Box className={style.root}>
        {standAlone && (
          <div
            style={{
              textAlign: "end",
              padding: "2px 10px 0px",
              height: "16px",
            }}
          >
            <IconButton size="small" onClick={onClose}>
              <Close style={{ fontSize: 12 }} />
            </IconButton>
          </div>
        )}
        <NotifyBody
          style={{ borderTop: standAlone ? undefined : "1px solid #E0E0E0" }}
        >
          <PhoneMissedIcon style={{ fontSize: "1.1rem", marginRight: 5 }} />
          <div>
            <Typography color="primary" variant="subtitle2">
              Unanswered Service Request Reminder
            </Typography>
            <Typography style={{ fontSize: 10 }}>
              Call back reminder for Service Case #{id}
            </Typography>
            {!standAlone && (
              <Typography style={{ fontSize: 8, marginTop: 10 }}>
                {timeInText || "Few seconds ago"}
              </Typography>
            )}
          </div>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            type="button"
            onClick={onOpen}
            style={{ marginLeft: 20 }}
          >
            OPEN
          </Button>
        </NotifyBody>
      </Box>
    );
  }
);

export default NotificationSnippet;
