import React, { useMemo } from "react";
import { AccessLinksTab } from "pages/FulfilmentDashboard/AccessLinksTab";
import { makeStyles } from "@material-ui/core";
import { ServiceCaseResponse } from "@deep-consulting-solutions/be2-constants";
import { CallFormTabProps, MainTabsEnum } from "./types";
import { CallFormsLayout } from "./CallFormsLayout";
import { CallNotes } from "./CallNotes";

interface Props extends CallFormTabProps {
  index: number;
  value: number;
  label: MainTabsEnum;
  switchTab: (tab: number) => void;
  setCases: React.Dispatch<React.SetStateAction<ServiceCaseResponse[]>>;
}

interface StyleProps {
  hidden: boolean;
}

const useStyles = makeStyles(() => ({
  wrapper: ({ hidden }: StyleProps) => ({
    flex: 1,
    width: "100%",
    height: "100%",
    overflow: "hidden",
    ...(!hidden ? { display: "flex" } : {}),
    flexFlow: "column nowrap",
    alignItems: "stretch",
  }),
}));

export const CallFormsMainTabPanel = ({
  value,
  index,
  id,
  label,
  switchTab,
  setCases,
  ...props
}: Props) => {
  const hidden = useMemo(() => value !== index, [value, index]);
  const classes = useStyles({ hidden });

  return (
    <div
      role="tabpanel"
      hidden={hidden}
      id={props["aria-controls"]}
      aria-labelledby={id}
      className={classes.wrapper}
    >
      {label === MainTabsEnum.DETAILS && (
        <CallFormsLayout setCases={setCases} />
      )}
      {label === MainTabsEnum.ACCESS_LINKS && <AccessLinksTab />}
      {label === MainTabsEnum.NOTES && <CallNotes />}
    </div>
  );
};
