import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  MouseEventHandler,
} from "react";
import {
  Grid,
  makeStyles,
  Typography,
  Divider,
  Box,
  Button,
} from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { Field, useFormikContext } from "formik";
import {
  InformationSupportReasonEnum,
  ServiceCaseStatusEnum,
  ServiceTypesEnum,
  ServiceTypesWithoutMortalRemainsEnum,
} from "@deep-consulting-solutions/be2-constants";
import { SelectField } from "component/atoms";
import { useCall } from "call/hooks";
import { patchPatientSubCoverageService } from "redux/call/requests";
import Loader from "component/Loader";
import { getInformationalSupportDocumentLink } from "redux/serviceCase/requests";
import { openPageUrl } from "helpers";
import {
  getIsRequesterPhysician,
  getIsServiceCaseApproved,
  getIsServiceCaseRefused,
  getIsServiceCaseWaiting,
  getIsServiceToBeRenderedCustom,
  getIsServiceToBeRenderedEmergencyAirliftEvacuationOrHospitalTransferOrRepatriationOrCustom,
  getIsServiceToBeRenderedInformationalSupport,
} from "../helpers";
import { FormValues } from "../types";

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  divider: {
    margin: s(4, 0),
  },
  select: {
    width: "100%",
  },
  info: {
    color: p.grey[400],
    margin: s(1, 0),
    padding: s(0),
  },
  serviceTitle: {
    fontSize: 24,
    marginBottom: s(2),
  },
  instructions: {
    textDecoration: "underline",
  },
}));

const serviceToBeRenderedOptions = Object.values(
  ServiceTypesWithoutMortalRemainsEnum
);

export const ServiceToBeRendered = () => {
  const { viewedCall, updateCallServiceCase } = useCall();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [allowOtherCustomService, setAllowOtherCustomService] = useState(true);
  const {
    setFieldValue,
    values: {
      serviceToBeRendered,
      physician: { isContacted: physicianContacted },
      informationSupportReason,
    },
  } = useFormikContext<FormValues>();

  const {
    isCustomService,
    isInformationSupport,
    isServiceToBeRenderedEmergencyAirliftEvacuationOrHospitalTransferOrRepatriationOrCustom,
  } = useMemo(() => {
    return {
      isCustomService: getIsServiceToBeRenderedCustom(serviceToBeRendered),
      isInformationSupport:
        getIsServiceToBeRenderedInformationalSupport(serviceToBeRendered),
      isServiceToBeRenderedEmergencyAirliftEvacuationOrHospitalTransferOrRepatriationOrCustom:
        getIsServiceToBeRenderedEmergencyAirliftEvacuationOrHospitalTransferOrRepatriationOrCustom(
          serviceToBeRendered
        ),
    };
  }, [serviceToBeRendered]);

  const isAwaiting = useMemo(() => {
    return getIsServiceCaseWaiting(
      viewedCall?.updatedServiceCase?.approvalRequests
    );
  }, [viewedCall?.updatedServiceCase?.approvalRequests]);

  const isServiceCaseAccepted = useMemo(() => {
    return getIsServiceCaseApproved(
      viewedCall?.updatedServiceCase?.approvalRequests
    );
  }, [viewedCall?.updatedServiceCase?.approvalRequests]);

  const isServiceCaseRefused = useMemo(
    () =>
      getIsServiceCaseRefused(viewedCall?.updatedServiceCase?.approvalRequests),
    [viewedCall?.updatedServiceCase?.approvalRequests]
  );

  const isRequesterPhysician = useMemo(() => {
    return getIsRequesterPhysician(viewedCall?.updatedServiceCase?.patients);
  }, [viewedCall?.updatedServiceCase?.patients]);

  const serviceRef = React.useRef<ServiceTypesEnum | null>(serviceToBeRendered);

  const fetchUpdatedServiceCase = useCallback(async () => {
    if (serviceRef.current === serviceToBeRendered) return;

    try {
      setLoading(true);
      if (
        serviceToBeRendered &&
        viewedCall?.updatedServiceCase?.id &&
        !getIsServiceToBeRenderedInformationalSupport(serviceToBeRendered)
      ) {
        const res = await patchPatientSubCoverageService(
          viewedCall.updatedServiceCase.id,
          serviceToBeRendered
        );

        updateCallServiceCase(viewedCall.sessionId, res);
        serviceRef.current = serviceToBeRendered;
      }
    } catch {
      //
    } finally {
      setLoading(false);
    }
  }, [
    serviceToBeRendered,
    viewedCall?.sessionId,
    updateCallServiceCase,
    viewedCall?.updatedServiceCase?.id,
  ]);

  useEffect(() => {
    fetchUpdatedServiceCase();
  }, [fetchUpdatedServiceCase]);

  const openInstructions = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getInformationalSupportDocumentLink();
      openPageUrl(res.url);
    } catch {
      //
    } finally {
      setLoading(false);
    }
  }, []);

  const handleOpenInstructions: MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (e) => {
        e.preventDefault();
        openInstructions();
      },
      [openInstructions]
    );

  useEffect(() => {
    if (
      isServiceToBeRenderedEmergencyAirliftEvacuationOrHospitalTransferOrRepatriationOrCustom &&
      !isRequesterPhysician &&
      physicianContacted === false
    ) {
      setFieldValue("serviceToBeRendered", ServiceTypesEnum.CUSTOM);
      setDisabled(true);
      setAllowOtherCustomService(false);
    } else {
      setDisabled(false);
      setAllowOtherCustomService(true);
    }
  }, [
    isServiceToBeRenderedEmergencyAirliftEvacuationOrHospitalTransferOrRepatriationOrCustom,
    physicianContacted,
    setFieldValue,
    isRequesterPhysician,
  ]);

  const disableForm = useMemo(
    () =>
      !!viewedCall?.disableForms ||
      viewedCall?.updatedServiceCase?.caseStatus !==
        ServiceCaseStatusEnum.GATHERING_REQUIREMENTS,
    [viewedCall?.disableForms, viewedCall?.updatedServiceCase?.caseStatus]
  );

  const classes = useStyles();

  if (isAwaiting || isServiceCaseAccepted || isServiceCaseRefused) {
    return (
      <div>
        <Typography color="primary" variant="subtitle2">
          Services to be Rendered: <strong>{serviceToBeRendered}</strong>
        </Typography>

        <Divider className={classes.divider} />
      </div>
    );
  }

  return (
    <>
      <Loader open={loading} />
      <div>
        <Typography
          color="primary"
          variant="subtitle2"
          className={classes.serviceTitle}
        >
          Service to be Rendered
        </Typography>
        <Grid
          container
          alignItems="stretch"
          spacing={4}
          justifyContent="flex-start"
        >
          <Grid item xs={6}>
            <SelectField
              name="serviceToBeRendered"
              label="Request Type"
              placeholder="Request Type"
              required
              SelectProps={{
                displayEmpty: true,
              }}
              options={serviceToBeRenderedOptions.sort()}
              className={classes.select}
              size="small"
              disabled={disabled || disableForm}
            />
          </Grid>
          <Grid item xs={6}>
            {isCustomService && allowOtherCustomService ? (
              <Field
                size="small"
                component={TextField}
                name="customService"
                label="Custom Service"
                required
                disabled={disableForm}
              />
            ) : null}
            {isInformationSupport ? (
              <SelectField
                size="small"
                name="informationSupportReason"
                label="Information Support"
                required
                options={Object.values(InformationSupportReasonEnum)}
                disabled={disableForm}
              />
            ) : null}
          </Grid>
        </Grid>

        {isInformationSupport &&
        informationSupportReason === InformationSupportReasonEnum.OTHER ? (
          <Box mt={2}>
            <Field
              size="small"
              component={TextField}
              name="informationSupportExplanation"
              label="Explanation"
              required
              multiline
              minRows={3}
              disabled={disableForm}
            />
          </Box>
        ) : null}

        {isInformationSupport ? (
          <Box mt={2}>
            <Button
              color="primary"
              variant="text"
              onClick={handleOpenInstructions}
              className={classes.instructions}
            >
              Instructions
            </Button>
          </Box>
        ) : null}

        <Divider className={classes.divider} />
      </div>
    </>
  );
};
