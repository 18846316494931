import React, { useMemo } from "react";
import { Grid, makeStyles, Theme, GridSize } from "@material-ui/core";
import { CallSource, useCall } from "call/hooks";
import { CallLayoutEnum } from "call/types";

import { CallMap } from "../CallMap";
import { CallChat } from "../CallChat";
import { CallVideo } from "../CallVideo";
import { CallForms } from "../CallForms";

interface StyleProps {
  layout: CallLayoutEnum;
  callSource: CallSource;
}

const useStyles = makeStyles<Theme, StyleProps>(({ spacing: s }) => ({
  wrapper: {
    height: "100%",
    padding: ({ callSource }) => s(callSource === "call" ? 0 : 2, 2, 0, 2),
  },
  forms: {
    width: "50%",
    overflow: "hidden",
  },
  map: ({ layout }) => ({
    width: [
      CallLayoutEnum.FORM_MAP_CHAT,
      CallLayoutEnum.FORM_MAP_VIDEO,
      CallLayoutEnum.FORM_VIDEO_MAP_CHAT,
    ].includes(layout)
      ? "25%"
      : "50%",
    overflow: "hidden",
  }),
  video: ({ layout }) => ({
    display: [
      CallLayoutEnum.FORM_MAP,
      CallLayoutEnum.FORM_MAP_CHAT,
      CallLayoutEnum.MAP_FORM_CHAT,
    ].includes(layout)
      ? "none"
      : undefined,
    width: [CallLayoutEnum.MAP_FORM_VIDEO].includes(layout) ? "50%" : "25%",
    overflow: "hidden",
  }),
  chat: ({ layout }) => ({
    display: [
      CallLayoutEnum.FORM_MAP,
      CallLayoutEnum.FORM_MAP_VIDEO,
      CallLayoutEnum.MAP_FORM_VIDEO,
    ].includes(layout)
      ? "none"
      : undefined,
    width: [CallLayoutEnum.MAP_FORM_CHAT].includes(layout) ? "50%" : "25%",
    overflow: "hidden",
  }),
}));

export const CallLayout = () => {
  const { layout: layoutFromSettings, callSource } = useCall();

  const layout = useMemo(
    () =>
      callSource === "call" ? layoutFromSettings : CallLayoutEnum.FORM_MAP,
    [layoutFromSettings, callSource]
  );

  const classes = useStyles({ layout, callSource });

  const {
    formXs,
    mapXs,
    videoXs,
    chatXs,
  }: {
    formXs: GridSize;
    mapXs: GridSize;
    videoXs: GridSize;
    chatXs: GridSize;
  } = useMemo(() => {
    const formSize = [
      CallLayoutEnum.MAP_FORM_CHAT,
      CallLayoutEnum.MAP_FORM_VIDEO,
      CallLayoutEnum.MAP_FORM_VIDEO_CHAT,
    ].includes(layout)
      ? 6
      : 12;
    const mapSize = [
      CallLayoutEnum.FORM_MAP,
      CallLayoutEnum.FORM_MAP_CHAT,
      CallLayoutEnum.FORM_MAP_VIDEO,
    ].includes(layout)
      ? 12
      : 6;

    const videoSize = [CallLayoutEnum.FORM_VIDEO_MAP_CHAT].includes(layout)
      ? 6
      : 12;

    const chatSize = 12;

    return {
      formXs: formSize,
      mapXs: mapSize,
      videoXs: videoSize,
      chatXs: chatSize,
    };
  }, [layout]);

  return (
    <Grid
      container
      direction="column-reverse"
      wrap="wrap"
      spacing={2}
      className={classes.wrapper}
    >
      <Grid item xs={formXs} className={classes.forms}>
        <CallForms />
      </Grid>
      <Grid item xs={mapXs} className={classes.map}>
        <CallMap />
      </Grid>
      {callSource === "call" && (
        <>
          <Grid item xs={videoXs} className={classes.video}>
            <CallVideo />
          </Grid>
          <Grid item xs={chatXs} className={classes.chat}>
            <CallChat />
          </Grid>
        </>
      )}
    </Grid>
  );
};
