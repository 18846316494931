import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useDialogStyles } from "component/Dialogs/styles";
import clsx from "clsx";
import { AudioModule } from "component/Dialogs/audioVideoSettings/AudioModule";
import { VideoModule } from "component/Dialogs/audioVideoSettings/VideoModule";
import {
  ExtSpeakersModule,
  SpeakersModule,
} from "component/Dialogs/audioVideoSettings/SpeakersModule";

import { useRef } from "react";
import { useMediaDeviceContext } from "context/MediaDeviceContext";

const useStyle = makeStyles({
  fields: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 20,
  },
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 700,
    },
  },
  card: {
    border: "1px solid #BDBDBD",
    borderRadius: 4,
    backgroundColor: "#FDFDFD",
    width: "100%",
    padding: 16,
  },
});

export const AudioVideoSettings = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const baseClasses = useDialogStyles();
  const classes = useStyle();

  const { saveDevices } = useMediaDeviceContext();

  const handleSave = () => {
    saveDevices();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={clsx(classes.dialog, baseClasses.dialog)}
    >
      <div className={baseClasses.dialogTitle}>
        <Typography color="primary" style={{ fontSize: 20 }}>
          Audio and Video Settings
        </Typography>
      </div>

      <DialogContent style={{ padding: 0 }}>
        <Box padding={4} display="flex" flexDirection="column">
          <Box className={classes.card} mb={4}>
            <Typography color="primary" style={{ fontSize: 24 }}>
              Audio
            </Typography>
            <Box
              mt={2}
              style={{ display: "flex", flexDirection: "column", gap: 20 }}
            >
              <AudioModule />
              <SpeakersModule />
              <ExtSpeakersModule />
            </Box>
          </Box>
          <Box className={classes.card}>
            <Typography color="primary" style={{ fontSize: 24 }}>
              Video
            </Typography>
            <Box mt={2}>
              <VideoModule open={open} />
            </Box>
            <audio ref={audioRef}>
              <track kind="captions" />
            </audio>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions style={{ padding: "20px 32px" }}>
        <Button color="primary" type="submit" onClick={handleSave}>
          save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
