import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useCallback, MouseEventHandler, useMemo } from "react";
import * as yup from "yup";
import { FormikProvider, useFormik, Field } from "formik";
import { getENText } from "helpers";
import { notifications } from "services";
import { TextField } from "formik-material-ui";
import { useCall } from "call/hooks";
import { patchCancelApprovalRequest } from "redux/call/requests";
import Loader from "component/Loader";
import { TabsEnum } from "call/components/CallForms/types";
import { getAwaitServiceCaseApprovalRequest } from "../../helpers";

const useStyles = makeStyles(({ spacing: s }) => ({
  title: {
    background: "#F6F6F6",
    padding: s(2),
  },
  content: {
    padding: s(2),
  },
  actions: {
    padding: s(2),
  },
  bodyText: {
    color: "#4F4F4F",
    marginBottom: s(2),
  },
  wrapper: {
    borderRadius: 16,
  },
  titleText: {
    fontWeight: 500,
    fontSize: 24,
  },
  closeIcon: {
    color: "#4F4F4F",
  },
  cancelHighlight: {
    color: "rgba(202, 32, 39, 1)",
  },
  cancel: {
    background: "rgba(202, 32, 39, 1)",
    color: "white",
    "&:hover": {
      background: "rgba(202, 32, 39, 0.8)",
    },
  },
}));

interface Props {
  handleClose: () => void;
  handleDisableTab: (tab: TabsEnum) => void;
}

export const CancelApprovalRequestDialog = ({
  handleClose,
  handleDisableTab,
  ...props
}: DialogProps & Props) => {
  const { viewedCall, updateCallServiceCase } = useCall();

  const classes = useStyles();

  const approvalRequest = useMemo(
    () =>
      getAwaitServiceCaseApprovalRequest(
        viewedCall?.updatedServiceCase?.approvalRequests
      ),
    [viewedCall?.updatedServiceCase?.approvalRequests]
  );

  const formik = useFormik<{ cancellationReason: string }>({
    initialValues: {
      cancellationReason: "",
    },
    validationSchema: yup.object({
      cancellationReason: yup
        .string()
        .required(getENText("cancellationReason.patients.required")),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        if (!approvalRequest?.id) {
          return;
        }
        const res = await patchCancelApprovalRequest(
          approvalRequest.id,
          values.cancellationReason
        );
        updateCallServiceCase(viewedCall!.sessionId, res);
        handleDisableTab(TabsEnum.ASSESSMENT);
        notifications.notifySuccess(
          getENText("serviceForm.cancelApprovalRequest.successful")
        );
        handleClose();
      } catch (error) {
        //
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      formik.handleSubmit();
    },
    [formik]
  );

  const handleCancel: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      handleClose();
    },
    [handleClose]
  );

  return (
    <>
      <Loader open={formik.isSubmitting} />
      <Dialog {...props} className={classes.wrapper} fullWidth maxWidth="sm">
        <DialogTitle className={classes.title}>
          <Typography
            align="center"
            color="primary"
            variant="h4"
            className={classes.titleText}
          >
            Are you sure you want to{" "}
            <span className={classes.cancelHighlight}>Cancel</span> the Request
            for Approval?
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <FormikProvider value={formik}>
            <form>
              <Field
                component={TextField}
                name="cancellationReason"
                label="Cancellation Reason"
                required
                multiline
                minRows={3}
              />
            </form>
          </FormikProvider>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
          >
            <Grid item>
              <Button color="primary" variant="outlined" onClick={handleCancel}>
                DON&apos;T CANCEL THE REQUEST
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                onClick={handleSubmit}
                disabled={!formik.isValid}
                className={classes.cancel}
              >
                CANCEL THE REQUEST
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
