import * as Yup from "yup";
import {
  Hospital,
  LocationTypeEnum,
  YesNoEnum,
} from "@deep-consulting-solutions/be2-constants";

import type { LocationField, LocationFormValues } from "./LocationForm";

export const booleanToYesOrNo = (value?: boolean) => {
  if (typeof value !== "boolean") {
    return "";
  }
  return value ? "Yes" : "No";
};

export const yesOrNoToBoolean = (value?: string | YesNoEnum | null) => {
  if (typeof value !== "string") return false;
  return value.toLocaleLowerCase() === "yes";
};

export const LocationFieldSchema = Yup.object({
  plusCode: Yup.string(),
  lat: Yup.number().required(),
  lng: Yup.number().required(),
  dms: Yup.string().required(),
  address: Yup.string().required("Address is required"),
}).required();

const customLocationType = (when: string) =>
  Yup.string<string>().when(when, {
    is: LocationTypeEnum.CUSTOM,
    then: Yup.string().required("Custom location type is required"),
    otherwise: Yup.string(),
  });

export const initialValuesSchema = Yup.object<
  Partial<LocationFormValues>
>().shape({
  initialLocation: Yup.object<LocationField>().when(
    "isInitialLocationAccurate",
    {
      is: false,
      then: LocationFieldSchema,
      otherwise: Yup.object({
        dms: Yup.string().required(),
      }),
    }
  ),
  requesterAddress: Yup.string(),
  requesterLocation: Yup.string(),
  moreInitialAddressDetails: Yup.string(),
  isInitialLocationAccurate: Yup.boolean(),
  customInitialLocation: Yup.string().when(
    ["initialLocationType", "isInitialLocationAccurate"],
    {
      is: (initialLocationType, isInitialLocationAccurate) =>
        !isInitialLocationAccurate &&
        initialLocationType === LocationTypeEnum.CUSTOM,
      then: Yup.string().required("Custom location type is required"),
      otherwise: Yup.string(),
    }
  ),
  initialLocationType: Yup.mixed<LocationTypeEnum>().when(
    "isInitialLocationAccurate",
    {
      is: false,
      then: Yup.mixed<LocationTypeEnum>()
        .oneOf(Object.values(LocationTypeEnum))
        .required("Initial Location Type is required"),
    }
  ),
  initialMedicalFacility: Yup.object()
    .when("initialLocationType", {
      is: LocationTypeEnum.MEDICAL_FACILITY,
      then: Yup.object<Hospital>()
        .nullable()
        .required("Medical Facility is required"),
    })
    .nullable(),
});

export const fullValidationSchema = initialValuesSchema.shape({
  destination: LocationFieldSchema,
  moreDestinationDetails: Yup.string(),
  customDestination: customLocationType("destinationType"),
  destinationType: Yup.mixed<LocationTypeEnum>()
    .oneOf(Object.values(LocationTypeEnum))
    .required("Destination Type is required"),
  destinationMedicalFacility: Yup.object()
    .when("destinationType", {
      is: LocationTypeEnum.MEDICAL_FACILITY,
      then: Yup.object<Hospital>()
        .nullable()
        .required("Medical Facility is required"),
    })
    .nullable(),
});

export const mapStyle = [
  {
    featureType: "poi.attraction",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.business",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.government",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.medical",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi.park",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.place_of_worship",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.school",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.sports_complex",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];
