import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import { SelectField } from "component/atoms";
import { useStyles } from "call/components/CallForms/RequesterAndPatientForm/styles";
import {
  Member,
  NextOfKinDisabledFields,
  NextOfKinTypeEnum,
  PatientData,
  MembershipTypeEnum,
  FormValues,
} from "call/components/CallForms/RequesterAndPatientForm/types";
import { SearchForMember } from "call/components/CallForms/RequesterAndPatientForm/SearchForMember";
import { SearchedMembers } from "call/components/CallForms/RequesterAndPatientForm/SearchedMembers";
import {
  MembersResponseSubObject,
  NextOfKinData,
  ServiceCaseRequesterPatientRelationEnum,
} from "@deep-consulting-solutions/be2-constants";
import { ExistingNextOfKin } from "call/components/CallForms/RequesterAndPatientForm/ExistingNextOfKin";
import Loader from "component/Loader";
import {
  getContactSubMembers,
  searchExistingMembers,
} from "redux/call/requests";
import { useCall } from "call/hooks";
import {
  getAge,
  getGeneralAddressString,
  getShowExistingNextOfKinComponent,
  transformRelationToApplicantEnum,
} from "./helpers";
import { PatientMedicalInformationSection } from "./PatientMedicalInformationSection";

interface Props {
  formKey: string;
  patient: PatientData;
  handleOpenRemovePatientDialog: () => void;
  showRemovePatientButton: boolean;
  requesterSelectedMembers: Member[];
  disableForm: boolean;
}

const parseDate = (date: string) => {
  try {
    return new Date(date);
  } catch (error) {
    return "";
  }
};

export const ExistingPatientInformation: React.FC<Props> = ({
  patient,
  formKey,
  showRemovePatientButton,
  handleOpenRemovePatientDialog,
  requesterSelectedMembers,
  disableForm,
}) => {
  const { viewedCall } = useCall();
  const classes = useStyles({});
  const [selectedMembers, setSelectedMembers] = useState<Member[]>([]);
  const [loading, setLoading] = useState(false);
  const {
    setFieldValue,
    values: { requester },
  } = useFormikContext<FormValues>();
  const [subscriptionMembers, setSubscriptionMembers] = useState<
    MembersResponseSubObject[]
  >([]);
  const [memberNextOfKin, setMemberNextOfKin] = useState<
    undefined | Partial<NextOfKinData>
  >(undefined);
  const [disabledNextOfKinFields, setDisabledNextOfKinFields] = useState<
    Partial<NextOfKinDisabledFields>
  >({});

  const handleMemberSelect = useCallback(
    async (member: Member) => {
      if (member) {
        setSelectedMembers([member]);
        try {
          setLoading(true);

          let isRequester = patient.isRequester;

          if (
            requester.contactID &&
            requester.contactID === member.id &&
            !requester.isPatient
          ) {
            setFieldValue("requester.isPatient", true);
            isRequester = true;
            setFieldValue(`${formKey}.isRequester`, isRequester);
          }

          setFieldValue(`${formKey}.firstName`, member.firstName);
          setFieldValue(`${formKey}.lastName`, member.lastName);
          setFieldValue(`${formKey}.gender`, member.gender);
          setFieldValue(`${formKey}.dob`, member.dob);
          if (member.dob) {
            setFieldValue(`${formKey}.age`, getAge(member.dob as string));
          }
          setFieldValue(`${formKey}.contactID`, member.id);
          setFieldValue(`${formKey}.email`, member.email);
          setFieldValue(`${formKey}.phone`, member.phone);
          setFieldValue(`${formKey}.isMemberOrCustomer`, true);
          setFieldValue(
            `${formKey}.requesterRelationToPatient`,
            isRequester ? ServiceCaseRequesterPatientRelationEnum.Other : ""
          );
          setFieldValue(
            `${formKey}.requesterOtherRelationToPatient`,
            isRequester ? "Requester" : ""
          );

          const res = await getContactSubMembers(member.id, true, true);

          if (res.nextOfKin?.firstName) {
            setMemberNextOfKin(res.nextOfKin);
            setFieldValue(
              `${formKey}.nextOfKinMembership`,
              NextOfKinTypeEnum.NEXT_OF_KIN
            );
            const relationshipToPatient = transformRelationToApplicantEnum(
              "NextOfKinRelationToPatientEnum",
              res.nextOfKin.relationToMainbeneficiary ||
                res.nextOfKin.relationToContact
            );
            const otherRelationToPatient = res.nextOfKin
              .relationToMainbeneficiary
              ? res.nextOfKin.otherRelationToMainbeneficiary
              : res.nextOfKin.otherRelationToContact;
            setFieldValue(`${formKey}.nextOfKin`, {
              relationshipToPatient: relationshipToPatient || "",
              otherRelationToPatient: otherRelationToPatient || "",
              firstName: res.nextOfKin?.firstName || "",
              lastName: res.nextOfKin?.lastName || "",
              phone: res.nextOfKin?.phone || "",
              email: res.nextOfKin?.email || "",
              isMemberOrCustomer: true,
              contactID: res.nextOfKin?.id || "",
            });
            setDisabledNextOfKinFields({
              relationshipToPatient: !!relationshipToPatient,
              otherRelationToPatient: !!otherRelationToPatient,
              firstName: !!res.nextOfKin?.firstName,
              lastName: !!res.nextOfKin?.lastName,
              phone: !!res.nextOfKin?.phone,
              email: !!res.nextOfKin?.email,
            });
          } else {
            setMemberNextOfKin(undefined);
            const subscriptionAsMainBeneficiary = res.subscriptionMembers.find(
              (subscriptionMemberFiltered) =>
                !!subscriptionMemberFiltered.isMainBeneficiary
            );
            if (subscriptionAsMainBeneficiary) {
              const emergencyContact =
                subscriptionAsMainBeneficiary.mainBeneficiaryEmergencyContact;

              if (emergencyContact) {
                setFieldValue(
                  `${formKey}.nextOfKinMembership`,
                  emergencyContact?.id
                );
                const relationshipToPatient = transformRelationToApplicantEnum(
                  "NextOfKinRelationToPatientEnum",
                  emergencyContact.relationToMainbeneficiary ||
                    emergencyContact.relationToContact
                );

                const otherRelationToPatient =
                  emergencyContact.relationToMainbeneficiary
                    ? emergencyContact.otherRelationToMainbeneficiary
                    : emergencyContact.otherRelationToContact;

                setFieldValue(`${formKey}.nextOfKin`, {
                  relationshipToPatient: relationshipToPatient || "",
                  otherRelationToPatient: otherRelationToPatient || "",
                  firstName: emergencyContact.firstName || "",
                  lastName: emergencyContact.lastName || "",
                  phone: emergencyContact.phone || "",
                  email: emergencyContact.email || "",
                  isMemberOrCustomer: true,
                  contactID: emergencyContact?.id || "",
                });
                setDisabledNextOfKinFields({
                  relationshipToPatient: !!relationshipToPatient,
                  otherRelationToPatient: !!otherRelationToPatient,
                  firstName: !!emergencyContact?.firstName,
                  lastName: !!emergencyContact?.lastName,
                  phone: !!emergencyContact?.phone,
                  email: !!emergencyContact?.email,
                });
              }
            } else {
              const subscriptionAsDependent = res.subscriptionMembers.find(
                (subscriptionMemberFiltered) =>
                  !subscriptionMemberFiltered.isMainBeneficiary
              );

              if (subscriptionAsDependent) {
                const mainBeneficiary = subscriptionAsDependent.mainBeneficiary;

                if (mainBeneficiary) {
                  setFieldValue(
                    `${formKey}.nextOfKinMembership`,
                    mainBeneficiary?.id
                  );
                  const relationshipToPatient =
                    transformRelationToApplicantEnum(
                      "NextOfKinRelationToPatientEnum",
                      mainBeneficiary.relationToContact ||
                        mainBeneficiary.relationToMainbeneficiary
                    );
                  const otherRelationToPatient =
                    mainBeneficiary.relationToContact
                      ? mainBeneficiary.otherRelationToContact
                      : mainBeneficiary.otherRelationToMainbeneficiary;
                  setFieldValue(`${formKey}.nextOfKin`, {
                    relationshipToPatient: relationshipToPatient || "",
                    otherRelationToPatient: otherRelationToPatient || "",
                    firstName: mainBeneficiary.firstName || "",
                    lastName: mainBeneficiary.lastName || "",
                    phone: mainBeneficiary.phone || "",
                    email: mainBeneficiary.email || "",
                    isMemberOrCustomer: true,
                    contactID: mainBeneficiary.id,
                  });
                  setDisabledNextOfKinFields({
                    relationshipToPatient: !!relationshipToPatient,
                    otherRelationToPatient: !!otherRelationToPatient,
                    firstName: !!mainBeneficiary?.firstName,
                    lastName: !!mainBeneficiary?.lastName,
                    phone: !!mainBeneficiary?.phone,
                    email: !!mainBeneficiary?.email,
                  });
                } else {
                  setFieldValue(
                    `${formKey}.nextOfKinMembership`,
                    NextOfKinTypeEnum.NEW
                  );
                  setFieldValue(`${formKey}.nextOfKin`, {
                    relationshipToPatient: "",
                    otherRelationToPatient: "",
                    firstName: "",
                    lastName: "",
                    phone: "",
                    email: "",
                    isMemberOrCustomer: false,
                    contactID: "",
                  });
                  setDisabledNextOfKinFields({
                    relationshipToPatient: false,
                    otherRelationToPatient: false,
                    firstName: false,
                    lastName: false,
                    phone: false,
                    email: false,
                  });
                }
              }
            }
          }
          setSubscriptionMembers(res.subscriptionMembers);
        } catch (error) {
          //
        } finally {
          setLoading(false);
        }
      }
    },
    [
      setFieldValue,
      formKey,
      patient.isRequester,
      requester.contactID,
      requester.isPatient,
    ]
  );

  const handleInitialValues = useCallback(async () => {
    if (patient.contactID) {
      try {
        setLoading(true);
        const data: Record<string, any> = {};
        const fields = ["firstName", "lastName", "email", "phone"];
        fields.forEach((field: string) => {
          if ((patient as any)[field]) {
            data[field] = (patient as any)[field];
          }
        });
        if (Object.values(data).length > 0) {
          const contacts = await searchExistingMembers(data);

          const contact = contacts.find((s) => s.id === patient.contactID);
          if (contact) {
            const memberships = [
              {
                type: contact.isMainbeneficiaryOfPaidSub
                  ? MembershipTypeEnum.MainBeneficiary
                  : MembershipTypeEnum.Dependent,
                number: contact.membershipNumber,
              },
            ].concat(
              contact.dependentSubsMembershipNumbers.map((d) => ({
                type: MembershipTypeEnum.Dependent,
                number: d,
              }))
            );
            setSelectedMembers([
              {
                firstName: contact.firstName,
                lastName: contact.lastName,
                dob: parseDate(contact.dob),
                id: contact.id,
                memberships,
                citizenships: contact.citizenships,
                address: getGeneralAddressString(contact.address),
                email: contact.email,
                phone: contact.phone,
                gender: contact.gender,
              },
            ]);
          }
        }
        const res = await getContactSubMembers(patient.contactID, true, true);

        if (res.nextOfKin?.firstName) {
          setMemberNextOfKin(res.nextOfKin);
        }

        setDisabledNextOfKinFields({
          relationshipToPatient: !!patient.requesterRelationToPatient,
          otherRelationToPatient: !!patient.requesterOtherRelationToPatient,
          firstName: !!patient.nextOfKin?.firstName,
          lastName: !!patient.nextOfKin?.lastName,
          phone: !!patient.nextOfKin?.phone,
          email: !!patient.nextOfKin?.email,
        });

        setSubscriptionMembers(res.subscriptionMembers);
      } catch (error) {
        //
      } finally {
        setLoading(false);
      }
    }
  }, [patient]);

  const shouldRequestFullInfo = useMemo(() => {
    return getShowExistingNextOfKinComponent(
      patient.isDeceased,
      viewedCall?.updatedServiceCase?.requestType
    );
  }, [patient.isDeceased, viewedCall?.updatedServiceCase?.requestType]);

  useEffect(() => {
    if (
      patient.isRequester &&
      requesterSelectedMembers &&
      requesterSelectedMembers[0] &&
      !patient.nextOfKin?.firstName
    ) {
      handleMemberSelect(requesterSelectedMembers[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient.isRequester, requesterSelectedMembers, handleMemberSelect]);

  useEffect(() => {
    if (
      !patient.isRequester ||
      (patient.isRequester && patient.nextOfKin?.firstName)
    ) {
      handleInitialValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.fullSpaced}>
      <Loader open={loading} />
      <SearchForMember
        onMemberSelect={(member: Member) => {
          handleMemberSelect(member);
        }}
        reSearch={!!selectedMembers.length}
        handleOpenRemovePatientDialog={handleOpenRemovePatientDialog}
        showRemovePatientButton={showRemovePatientButton}
        filterMembersByPatients
        disabled={patient.isRequester}
        disableForm={disableForm}
      />
      {selectedMembers.length ? (
        <>
          <Box mt={2}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Field
                  name={`${formKey}.isDeceased`}
                  size="small"
                  type="checkbox"
                  color="primary"
                  Label={{ label: "Patient is deceased?" }}
                  component={CheckboxWithLabel}
                  disabled={disableForm || patient.isRequester}
                />
              </Grid>
            </Grid>
          </Box>
          <SearchedMembers
            members={selectedMembers}
            disableForm={disableForm}
          />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <SelectField
                size="small"
                name={`${formKey}.requesterRelationToPatient`}
                label="Requester relation to patient"
                required={shouldRequestFullInfo}
                options={Object.values(
                  ServiceCaseRequesterPatientRelationEnum
                ).map((relation) => ({
                  label: relation,
                  value: relation,
                }))}
                disabled={disableForm || patient.isRequester}
              />
            </Grid>
            {patient.requesterRelationToPatient ===
              ServiceCaseRequesterPatientRelationEnum.Other && (
              <Grid item xs={6}>
                <Field
                  size="small"
                  component={TextField}
                  name={`${formKey}.requesterOtherRelationToPatient`}
                  label="Requester relation to patient"
                  required={shouldRequestFullInfo}
                  disabled={disableForm || patient.isRequester}
                />
              </Grid>
            )}
          </Grid>
          <PatientMedicalInformationSection
            formKey={formKey}
            patient={patient}
            disableForm={disableForm}
          />
          {shouldRequestFullInfo && (
            <div className={classes.fullSpaced}>
              <ExistingNextOfKin
                formKey={formKey}
                nextOfKin={patient.nextOfKin}
                memberships={subscriptionMembers}
                memberNextOfKin={memberNextOfKin}
                patient={patient}
                disabledFields={disabledNextOfKinFields}
                setDisabledNextOfKinFields={setDisabledNextOfKinFields}
                forceDisableFields={disableForm}
              />
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};
