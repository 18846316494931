import cx from "clsx";
import * as Yup from "yup";
import { Alert } from "@material-ui/lab";
import { Field, FormikProps } from "formik";
import ReactDOMServer from "react-dom/server";
import { LocationOn } from "@material-ui/icons";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import {
  LocationTypeEnum,
  SocketEventsEnum,
  ServiceCaseStatusEnum,
  ServiceCaseRequestOriginEnum,
} from "@deep-consulting-solutions/be2-constants";
import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  Box,
  darken,
  Switch,
  Button,
  Divider,
  MenuItem,
  makeStyles,
  Typography,
  FormControlLabel,
} from "@material-ui/core";

import { useSocket } from "hooks";
import { useCall } from "call/hooks";
import DCSPhoneInput from "component/atoms/DCSPhoneInput";
import { COUNTRIES_BY_CODE, DEFAULT_COUNTRY } from "configs";
import { useMap, initialLocationMarkerTitle } from "call/context";
import { VALIDATIONS, getLocationFromGeoCodeResult } from "helpers";

import { TabsEnum } from "../types";
import { booleanToYesOrNo } from "./helpers";
import { tabsEnumToNumber } from "../helpers";
import { SharedLocationPayload } from "./types";
import { LocationSearch } from "./LocationSearch";
import { LocationSummary } from "./LocationSummary";
import { LocationInfoWindow } from "./LocationInfoWindow";
import { MedicalFacilityField } from "./MedicalFacilityField";
import type { LocationField, LocationFormValues } from "./LocationForm";
import SendLocationCollectorDialog from "./SendLocationCollectorDialog";
import { LocationAlert, LocationAlertStatusEnum } from "./LocationAlert";

type InitialLocationProps = FormikProps<LocationFormValues> & {
  activeTab: TabsEnum;
  requesterLiveLocation: LocationField | null;
  updateRequesterLiveLocation: (location: LocationField) => void;
};

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  title: {
    color: p.primary.main,
    fontFamily: "'Roboto', sans-serif",
  },
  isLocationAccurate: {
    color: "#4F4F4F",
    marginRight: s(3),
  },
  moreAddressDetails: {
    fontSize: 12,

    "& .MuiInputBase-input::placeholder": {
      fontSize: 12,
    },
  },
  alert: {
    marginBottom: s(2),
    justifyContent: "space-between",

    "& .MuiAlert-action": {
      marginLeft: "unset",
    },
  },
  findLocationAlert: {
    margin: s(2, 0),
    backgroundColor: p.primary.main,

    "& .MuiAlert-message": {
      padding: 0,
    },
  },
  findLocationList: {
    margin: 0,
  },
  sendCollectorButton: {
    height: s(5),
    color: "white",
    backgroundColor: "#08A40F",

    "&:hover": {
      backgroundColor: darken("#08A40F", 0.1),
    },
  },
  textField: {
    width: "217px",
    height: "54px",
    marginRight: s(2),

    "& .MuiFormLabel-root, & .MuiInputBase-root": {
      fontSize: 14,
    },
  },
  locationCollector: {
    gap: s(2),
    display: "flex",
    flexWrap: "wrap",
    marginBottom: s(3),
    alignItems: "stretch",
  },
  locationErrorAlert: {
    margin: s(2, 0),
  },
  locationIdentification: {
    marginRight: 0,
    "& .MuiInputBase-root": {
      height: "40px",
    },
    "& .MuiInputAdornment-root": {
      display: "none",
      visibility: "hidden",
    },
  },
  locationAddress: {
    color: p.primary.main,
  },
}));

const locationCollectorSchema = Yup.object({
  phone: VALIDATIONS.phone,
});

export const InitialLocation = (props: InitialLocationProps) => {
  const { viewedCall } = useCall();
  const [phoneError, setPhoneError] = useState("");

  const {
    dirty,
    values,
    activeTab,
    isSubmitting,
    setFieldValue,
    requesterLiveLocation,
    updateRequesterLiveLocation,
  } = props;

  const [syncLiveLocation, setSyncLiveLocation] = useState(false);
  const [phone, setPhone] = useState(COUNTRIES_BY_CODE[DEFAULT_COUNTRY].phone);

  const {
    map,
    getMarker,
    createMarker,
    deleteMarker,
    createInfoWindow,
    updateMarkerPosition,
    createInitialLocationMarker,
  } = useMap();

  useEffect(() => {
    if (!getMarker(initialLocationMarkerTitle)) {
      createInitialLocationMarker({});
    }
  }, [getMarker, createInitialLocationMarker]);

  const requesterGeoLocationField = useMemo(() => {
    return getLocationFromGeoCodeResult(viewedCall?.requesterGeoLocation);
  }, [viewedCall?.requesterGeoLocation]);

  useEffect(() => {
    let position: Record<string, number | undefined> = {
      lat: 0,
      lng: 0,
    };

    if (values.isInitialLocationAccurate) {
      if (syncLiveLocation) {
        position = {
          lat: requesterLiveLocation?.lat,
          lng: requesterLiveLocation?.lng,
        };
      } else {
        position = {
          lat: requesterGeoLocationField.lat,
          lng: requesterGeoLocationField.lng,
        };
      }
    } else {
      const { lat, lng } = values.initialLocation;

      position = {
        lat,
        lng,
      };
    }

    if (
      position.lat &&
      !Number.isNaN(Number(position.lat)) &&
      position.lng &&
      !Number.isNaN(Number(position.lng))
    ) {
      updateMarkerPosition("initialLocation", {
        lat: position.lat,
        lng: position.lng,
      });
    }
  }, [
    syncLiveLocation,
    updateMarkerPosition,
    requesterLiveLocation,
    values.initialLocation,
    requesterGeoLocationField,
    values.isInitialLocationAccurate,
  ]);

  const [isCollectorSent, setIsCollectorSent] = useState(false);
  const [showSendCollector, setShowSendCollector] = useState(false);
  const [locationAlertStatus, setLocationAlertStatus] = useState(
    LocationAlertStatusEnum.IDLE
  );

  const classes = useStyles();

  const sendLocationCollector = useCallback(() => {
    setIsCollectorSent(true);
    setShowSendCollector(false);
    setLocationAlertStatus(LocationAlertStatusEnum.IDLE);
  }, []);

  const handleSendCollector = useCallback(() => {
    try {
      locationCollectorSchema.validateSync({ phone });
      setShowSendCollector(true);
    } catch (err: any) {
      setPhoneError((err as Yup.ValidationError).message);
    }
  }, [phone]);

  const infoWindow = useMemo(() => {
    if (
      map &&
      tabsEnumToNumber(activeTab) < tabsEnumToNumber(TabsEnum.PLANNING)
    ) {
      return createInfoWindow({
        content: ReactDOMServer.renderToString(
          <LocationInfoWindow
            showAddress
            title="Initial Location"
            location={values.initialLocation}
          />
        ),
      });
    }
  }, [map, activeTab, createInfoWindow, values.initialLocation]);

  const { addHandler, removeHandler } = useSocket("Initial Location");

  const geocoder = useMemo(() => {
    if (map) return new google.maps.Geocoder();
  }, [map]);

  const syncTimer = useRef<NodeJS.Timeout | null>(null);
  const [locationSyncTime, setLocationSyncTime] = useState(0);
  const [locationAlertErrorMessage, setLocationAlertErrorMessage] =
    useState("");

  const updateLocation = useCallback(
    ({ location, serviceCaseDetails }: SharedLocationPayload) => {
      if (serviceCaseDetails.id !== viewedCall?.updatedServiceCase?.id) {
        return null;
      }

      try {
        setIsCollectorSent(false);
        setLocationAlertStatus(LocationAlertStatusEnum.LOADING);

        const [lat, lng] = location.split(" ");

        geocoder?.geocode(
          { location: { lat: parseFloat(lat), lng: parseFloat(lng) } },
          (results, status) => {
            if (status === google.maps.GeocoderStatus.OK && results?.[0]) {
              const initialLocation = getLocationFromGeoCodeResult(results[0]);
              updateRequesterLiveLocation(initialLocation);

              setLocationSyncTime(Date.now());
              setLocationAlertErrorMessage("");
              setLocationAlertStatus(LocationAlertStatusEnum.SUCCESS);

              // update Timer
              if (syncTimer.current) clearTimeout(syncTimer.current);

              syncTimer.current = setTimeout(() => {
                setLocationAlertStatus(LocationAlertStatusEnum.TIMEOUT);
              }, 120000);
            }
          }
        );
      } catch (err) {
        const suffix =
          ((err as any).message as string) || "An unexpected error ocurred.";

        setIsCollectorSent(false);
        setLocationAlertErrorMessage(suffix);
        setLocationAlertStatus(LocationAlertStatusEnum.ERROR);
      }
    },
    [geocoder, updateRequesterLiveLocation, viewedCall?.updatedServiceCase?.id]
  );

  const disableForm = useMemo(() => {
    return (
      !!viewedCall?.disableForms ||
      viewedCall?.updatedServiceCase?.caseStatus !==
        ServiceCaseStatusEnum.GATHERING_REQUIREMENTS
    );
  }, [viewedCall?.disableForms, viewedCall?.updatedServiceCase?.caseStatus]);

  useEffect(() => {
    if (geocoder && isCollectorSent && !values.isInitialLocationAccurate) {
      addHandler(
        SocketEventsEnum.SHARE_SERVICE_CASE_USER_LOCATION,
        updateLocation
      );

      return () => {
        removeHandler(
          SocketEventsEnum.SHARE_SERVICE_CASE_USER_LOCATION,
          updateLocation
        );
      };
    }
    removeHandler(
      SocketEventsEnum.SHARE_SERVICE_CASE_USER_LOCATION,
      updateLocation
    );
  }, [
    geocoder,
    addHandler,
    removeHandler,
    updateLocation,
    isCollectorSent,
    values.isInitialLocationAccurate,
  ]);

  useEffect(() => {
    const marker = getMarker("initialLocation");

    if (marker && infoWindow) {
      const event = marker.addListener("click", () => {
        if (values.initialLocation.address) {
          if (infoWindow.getPosition()) {
            infoWindow.close();
            infoWindow.setPosition(undefined);
          } else {
            infoWindow.open(map, marker);
          }
        }
      });

      return () => {
        event.remove();
        infoWindow.close();
        google.maps.event.clearListeners(marker, "click");
      };
    }
  }, [map, getMarker, infoWindow, values.initialLocation.address]);

  useEffect(() => {
    if (
      map &&
      !values.isInitialLocationAccurate &&
      viewedCall?.requesterGeoLocation &&
      !viewedCall.requesterGeoLocation?.isDefaultLocation
    ) {
      const title = "requesterGPS";
      const position = viewedCall?.requesterGeoLocation?.geometry.location;

      const marker = createMarker({
        title,
        position,
        icon: {
          scale: 1,
          rotation: 0,
          fillOpacity: 1,
          strokeWeight: 1,
          fillColor: "#263E7F",
          anchor: new google.maps.Point(15, 30),
          path: "M9 6.39134C9.86798 6.39134 10.7004 6.73614 11.3142 7.34989C11.9279 7.96365 12.2727 8.79608 12.2727 9.66406C12.2727 10.532 11.9279 11.3645 11.3142 11.9782C10.7004 12.592 9.86798 12.9368 9 12.9368C8.13202 12.9368 7.29959 12.592 6.68583 11.9782C6.07208 11.3645 5.72727 10.532 5.72727 9.66406C5.72727 8.79608 6.07208 7.96365 6.68583 7.34989C7.29959 6.73614 8.13202 6.39134 9 6.39134ZM1.67727 10.4822H0V8.84588H1.67727C2.04545 5.43406 4.77 2.70952 8.18182 2.34134V0.664062H9.81818V2.34134C13.23 2.70952 15.9545 5.43406 16.3227 8.84588H18V10.4822H16.3227C15.9545 13.8941 13.23 16.6186 9.81818 16.9868V18.6641H8.18182V16.9868C4.77 16.6186 2.04545 13.8941 1.67727 10.4822ZM9 3.93679C7.48103 3.93679 6.02428 4.5402 4.95021 5.61427C3.87613 6.68834 3.27273 8.1451 3.27273 9.66406C3.27273 11.183 3.87613 12.6398 4.95021 13.7139C6.02428 14.7879 7.48103 15.3913 9 15.3913C10.519 15.3913 11.9757 14.7879 13.0498 13.7139C14.1239 12.6398 14.7273 11.183 14.7273 9.66406C14.7273 8.1451 14.1239 6.68834 13.0498 5.61427C11.9757 4.5402 10.519 3.93679 9 3.93679Z",
        },
      });

      const gpsInfoWindow = createInfoWindow({
        content: ReactDOMServer.renderToString(
          <LocationInfoWindow
            showAddress
            title="Requester GPS"
            location={getLocationFromGeoCodeResult(
              viewedCall.requesterGeoLocation
            )}
          />
        ),
      });

      marker.addListener("click", () => {
        if (gpsInfoWindow.getPosition()) {
          gpsInfoWindow.close();
          gpsInfoWindow.setPosition(undefined);
        } else {
          gpsInfoWindow.open(map, marker);
        }
      });

      return () => {
        gpsInfoWindow.close();
        google.maps.event.clearListeners(marker, "click");
        deleteMarker(title);
      };
    }
  }, [
    map,
    createMarker,
    deleteMarker,
    createInfoWindow,
    values.isInitialLocationAccurate,
    viewedCall?.requesterGeoLocation,
    viewedCall?.updatedServiceCase?.requesterLocation,
  ]);

  return (
    <div>
      <Typography variant="h5" className={classes.title}>
        Initial Location
      </Typography>

      {viewedCall?.updatedServiceCase?.requestOrigin ===
        ServiceCaseRequestOriginEnum.BE_APP_CALL && (
        <Box>
          {!(
            viewedCall?.updatedServiceCase?.requesterLocation ||
            values.initialLocation.dms
          ) &&
            !dirty && (
              <Alert
                severity="error"
                variant="filled"
                className={classes.locationErrorAlert}
              >
                <strong>
                  Requester location is not available. Ask the requester if he
                  gave the BahamasEvac application location permission.
                </strong>
              </Alert>
            )}

          <Box display="flex" alignItems="center">
            <Typography variant="body2" className={classes.isLocationAccurate}>
              Is the requester&apos;s location accurate and should it be used as
              the initial location?
            </Typography>

            <FormControlLabel
              label={booleanToYesOrNo(values.isInitialLocationAccurate)}
              control={
                <Switch
                  color="primary"
                  disabled={disableForm}
                  name="isInitialLocationAccurate"
                  checked={values.isInitialLocationAccurate}
                  onChange={(e) =>
                    setFieldValue("isInitialLocationAccurate", e.target.checked)
                  }
                />
              }
            />
          </Box>

          <Divider />
        </Box>
      )}

      {values.isInitialLocationAccurate ? (
        <>
          <Box display="flex">
            <Field
              type="checkbox"
              color="primary"
              name="syncLocation"
              disabled={disableForm}
              component={CheckboxWithLabel}
              Label={{
                label: (
                  <Typography variant="body2">
                    Sync with Requester Live Location
                  </Typography>
                ),
              }}
            />
          </Box>

          <Box display="flex" alignItems="center">
            <Box
              width={32}
              height={32}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <LocationOn width={32} height={32} style={{ color: "#CA2027" }} />
            </Box>

            <Box mr="auto">
              <Box
                height="32px"
                display="flex"
                maxWidth={500}
                alignItems="center"
              >
                <Typography variant="body2" className={classes.locationAddress}>
                  {(values.syncLocation
                    ? requesterLiveLocation?.address
                    : requesterGeoLocationField.address) ||
                    "Requester's live address is invalid"}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ ml: "auto", mr: 10 }}>
              <Field
                size="small"
                variant="outlined"
                component={TextField}
                disabled={disableForm}
                name="moreInitialAddressDetails"
                placeholder="More Address Details"
                className={classes.moreAddressDetails}
              />
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Alert
            severity="info"
            variant="filled"
            className={classes.findLocationAlert}
          >
            <ul className={classes.findLocationList}>
              <li>
                <Typography variant="subtitle2">
                  Search for, or mark on map the patient&apos;s initial location
                  if the requester can provide it precisely, or send link to a
                  Location Identification Phone to collect the device&apos;s
                  location either using the BahamasEvac App or the browser.
                </Typography>
              </li>

              <Divider
                style={{
                  margin: "4px 0",
                  backgroundColor: "rgba(255, 255, 255, 0.54)",
                }}
              />

              <li>
                <Typography variant="subtitle2">
                  Make sure to assist the Requester in using this feature.
                </Typography>
              </li>
            </ul>
          </Alert>

          <Box className={classes.locationCollector}>
            <Field
              size="small"
              value={phone}
              error={phoneError}
              disabled={disableForm}
              helperText={phoneError}
              component={DCSPhoneInput}
              label="Location Identification Phone"
              placeholder="Location Identification Phone"
              className={cx(classes.textField, classes.locationIdentification)}
              InputProps={{
                onBlur: () => {
                  if (!phone) {
                    setPhone(COUNTRIES_BY_CODE[DEFAULT_COUNTRY].phone);
                  }
                },
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setPhone(e.target.value);
                  if (phoneError) setPhoneError("");
                },
              }}
            />

            <Button
              variant="contained"
              disabled={disableForm}
              onClick={handleSendCollector}
              className={classes.sendCollectorButton}
            >
              Send Location Collector Link
            </Button>

            <Field
              type="checkbox"
              color="primary"
              disabled={disableForm}
              value={syncLiveLocation}
              checked={syncLiveLocation}
              component={CheckboxWithLabel}
              onChange={() => setSyncLiveLocation(!syncLiveLocation)}
              Label={{
                label: (
                  <Typography variant="subtitle2" color="primary">
                    Sync Live Location
                  </Typography>
                ),
              }}
            />
          </Box>

          {isCollectorSent && (
            <Alert
              severity="info"
              className={classes.alert}
              onClose={() => {
                setIsCollectorSent(false);
              }}
            >
              Location collector link sent:
              <strong> waiting for response</strong>
            </Alert>
          )}

          <LocationAlert
            status={locationAlertStatus}
            locationSyncTime={locationSyncTime}
            errorMessage={locationAlertErrorMessage}
            location={`${values.initialLocation.lat} ${values.initialLocation.lng}`}
          />

          <Box display="flex" mb={1}>
            <Box
              width={32}
              height={32}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <LocationOn width={32} height={32} style={{ color: "#CA2027" }} />
            </Box>

            <Box width="450px">
              <Box display="flex" alignItems="center" mb={2}>
                <Field
                  select
                  required
                  size="small"
                  component={TextField}
                  name="initialLocationType"
                  className={classes.textField}
                  label="Initial Location Type"
                  disabled={isSubmitting || isCollectorSent || disableForm}
                  SelectProps={{
                    displayEmpty: true,
                  }}
                >
                  {Object.entries(LocationTypeEnum).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Field>

                {values.initialLocationType === LocationTypeEnum.CUSTOM && (
                  <Field
                    required
                    size="small"
                    label="Custom Type"
                    component={TextField}
                    disabled={disableForm}
                    placeholder="Custom Type"
                    name="customInitialLocation"
                    className={classes.textField}
                    style={{ marginRight: 0 }}
                  />
                )}

                {values.initialLocationType ===
                  LocationTypeEnum.MEDICAL_FACILITY && (
                  <MedicalFacilityField
                    formikProps={props}
                    disabled={disableForm}
                    fieldName="initialLocation"
                    name="initialMedicalFacility"
                  />
                )}
              </Box>

              <LocationSearch
                formikProps={props}
                disabled={disableForm}
                name="initialLocation"
                label="Initial Location"
              />
            </Box>
          </Box>
        </>
      )}

      {values.initialLocation?.dms && (
        <Box display="flex" width={482} justifyContent="space-between" mt={1}>
          <Box ml={4}>
            <LocationSummary
              location={
                values.isInitialLocationAccurate
                  ? getLocationFromGeoCodeResult(
                      viewedCall?.requesterGeoLocation
                    )
                  : values.initialLocation
              }
            />
          </Box>

          {!values.isInitialLocationAccurate && (
            <Box>
              <Field
                size="small"
                variant="outlined"
                component={TextField}
                disabled={disableForm}
                name="moreInitialAddressDetails"
                placeholder="More Address Details"
                className={classes.moreAddressDetails}
              />
            </Box>
          )}
        </Box>
      )}

      {showSendCollector && (
        <SendLocationCollectorDialog
          phone={phone}
          onSendClick={sendLocationCollector}
          syncLiveLocation={syncLiveLocation}
          setSyncLiveLocation={setSyncLiveLocation}
          onClose={() => setShowSendCollector(false)}
          serviceCase={viewedCall?.updatedServiceCase}
        />
      )}
    </div>
  );
};
