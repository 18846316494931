// @ts-nocheck
import {
  ServiceCaseResponse,
  ServiceCaseStatusEnum,
} from "@deep-consulting-solutions/be2-constants";

export const handleStepper = (data: ServiceCaseResponse) => {
  const loopData: ServiceCaseStatusEnum[] = [
    ServiceCaseStatusEnum.SERVICE_STARTED,
    ServiceCaseStatusEnum.PATIENT_RELEASED,
    ServiceCaseStatusEnum.COMPLETED,
  ];

  return loopData.reduce(
    (
      acc: {
        state: Partial<ServiceCaseResponse>;
        status: ServiceCaseStatusEnum;
        checked: boolean;
        disabled: boolean;
      }[],
      cur,
      i
    ) => {
      acc.push({
        state: data,
        status: cur,
        checked: loopData.slice(i).includes(data.caseStatus),
        // eslint-disable-next-line no-nested-ternary
        disabled: loopData.includes(data.caseStatus)
          ? i >= 1
            ? !acc[i - 1]?.checked
            : false
          : true,
      });
      return acc;
    },
    []
  );
};

export const getContentData = (
  label: ServiceCaseStatusEnum,
  serviceCase: Partial<ServiceCaseResponse>
) => {
  if (label === ServiceCaseStatusEnum.SERVICE_STARTED) {
    return {
      hospital: serviceCase?.initalLocationHospital?.name,
      location: serviceCase?.initialLocation,
      updateOn: serviceCase.caseStartedAt,
      address:
        serviceCase?.initialLocationAddress ||
        serviceCase?.initialLocationAddress2,
    };
  }
  if (label === ServiceCaseStatusEnum.PATIENT_RELEASED) {
    return {
      hospital: serviceCase?.destinationLocationHospital?.name,
      location: serviceCase?.destinationLocation,
      updateOn: serviceCase.patientReleasedAt,
      address:
        serviceCase?.destinationLocationAddress ||
        serviceCase?.destinationLocationAddress2,
    };
  }
  if (label === ServiceCaseStatusEnum.COMPLETED) {
    return {
      updateOn: serviceCase.caseCompletedAt,
      hospital: undefined,
      address: undefined,
    };
  }
  return { hospital: undefined, updateOn: undefined, address: undefined };
};
