import React, { ReactNode } from "react";
import { Provider } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { EnhancedStore } from "@reduxjs/toolkit";
import { pdfjs } from "react-pdf";

import theme from "theme";
import { NotificationProvider } from "services";
import NetworkErrorDialog from "component/NetworkErrorDialog";
import { BrowserRouter } from "react-router-dom";

export interface RootProps<T> {
  store: EnhancedStore<T>;
  children: ReactNode;
}

const Root = (() => {
  return class RootWrapper<T> extends React.PureComponent<RootProps<T>> {
    componentDidMount() {
      pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }

    render() {
      const { store, children } = this.props;

      const persistor = persistStore(store);
      persistor.flush();

      return (
        <BrowserRouter>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ThemeProvider theme={theme}>
                <NotificationProvider>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    {children}
                  </MuiPickersUtilsProvider>
                </NotificationProvider>
                <NetworkErrorDialog />
              </ThemeProvider>
            </PersistGate>
          </Provider>
        </BrowserRouter>
      );
    }
  };
})();

export default Root;
