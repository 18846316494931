import * as Yup from "yup";
import {
  Hospital,
  Itinerary,
  LocationTypeEnum,
  ServiceTypesEnum,
} from "@deep-consulting-solutions/be2-constants";

import { ddToDMS } from "helpers";

import type { FormFields, ItinerarySectionField } from "./PlanItinerary";

export const getLatAndLngFromAddress = (address: string) => {
  return address.split(",");
};

export const mapItinerarySectionsToLocations = (
  itinerarySections: Itinerary[]
) => {
  const [initialLocation, destinationLocation] = itinerarySections.reduce(
    (acc, cur, idx) => {
      if (idx === 0) {
        const [lat, lng] = cur.depatureLatLng
          .split(",")
          .map((item) => +item.trim());

        const first: ItinerarySectionField = {
          locationType: cur.departureLocationType,
          customLocation: cur.departureLocationCustomType || "",
          moreLocationDetails: cur.departureLocationAddress2 || "",
          locationMedicalFacility: cur.departureMedicalFacility
            ? cur.departureMedicalFacility
            : null,
          location: {
            lat,
            lng,
            dms: ddToDMS(lat, lng),
            address: cur.departureLocationAddress,
          },
        };

        acc.push(first);
      }

      const [lat, lng] = cur.arrivalLatLng
        .split(",")
        .map((item) => +item.trim());

      return [
        ...acc,
        {
          locationType: cur.arrivalLocationType,
          customLocation: cur.arrivalLocationCustomType || "",
          moreLocationDetails: cur.arrivalLocationAddress2 || "",
          locationMedicalFacility: cur.arrivalMedicalFacility
            ? cur.arrivalMedicalFacility
            : null,
          location: {
            lat,
            lng,
            dms: ddToDMS(lat, lng),
            address: cur.arrivalLocationAddress,
          },
        },
      ];
    },
    [] as ItinerarySectionField[]
  );

  return {
    initialLocation,
    destinationLocation,
  };
};

export const getLocationItems = (locationString?: string) => {
  let dms = "";

  const [latString = "0", lngString = "0"] = (locationString || "").split(",");
  const lat = Number(latString);
  const lng = Number(lngString);

  if (lat && lng) {
    dms = ddToDMS(lat, lng);
  }

  return { lat, lng, dms };
};

export const earlyValidationSchema = Yup.object<FormFields>().shape({
  assignedStaff: Yup.string(),
  itineraryDetails: Yup.string(),
  itinerarySections: Yup.object()
    .shape({
      initialLocation: Yup.object().required(),
      destinationLocation: Yup.object().shape({
        moreLocationDetails: Yup.string(),
        locationType: Yup.string<LocationTypeEnum>().required(
          "Location type is required"
        ),
        customLocation: Yup.string().when("locationType", {
          is: LocationTypeEnum.CUSTOM,
          then: Yup.string().required("Custom Location is required"),
        }),
        locationMedicalFacility: Yup.object()
          .nullable()
          .when("locationType", {
            is: LocationTypeEnum.MEDICAL_FACILITY,
            then: Yup.object<Hospital>().required(
              "Medical Facility is required"
            ),
          }),
        location: Yup.object().shape({
          lat: Yup.number().required(),
          lng: Yup.number().required(),
          dms: Yup.string().required(),
          address: Yup.string().required("Address field is required"),
        }),
      }),
    })
    .required(),
});

export const getSubmitAction = (
  service: ServiceTypesEnum | undefined
): "Schedule Service" | "Start Service" | null => {
  if (
    [
      ServiceTypesEnum.INFORMATIONAL_SUPPORT,
      ServiceTypesEnum.HOSPITAL_TRANSFER,
      ServiceTypesEnum.REPATRIATION,
      ServiceTypesEnum.CUSTOM,
    ].includes(service as ServiceTypesEnum)
  ) {
    return "Schedule Service";
  }
  if (
    [
      ServiceTypesEnum.EMERGENCY_GROUND_TRANSPORT,
      ServiceTypesEnum.EMERGENCY_MARITIME_TRANSPORT,
      ServiceTypesEnum.EMERGENCY_AIRLIFT_EVACUATION,
    ].includes(service as ServiceTypesEnum)
  ) {
    return "Start Service";
  }
  return null;
};
