export const takeScreenshot = (base64Data: string, fileName = "image.jpg") => {
  try {
    const linkSource = `data:$image/jpeg;base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    return true;
  } catch (err) {
    //
    return false;
  }
};
