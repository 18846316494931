import React from "react";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ spacing: s }) => ({
  serviceTitle: {
    fontSize: 24,
    marginBottom: s(2),
  },
}));

interface Props {
  title: string;
  visible?: boolean;
}
export const ServiceTitle = ({ title, visible = true }: Props) => {
  const classes = useStyles();
  if (!visible) {
    return null;
  }
  return (
    <Typography
      variant="subtitle2"
      color="primary"
      className={classes.serviceTitle}
    >
      {title}
    </Typography>
  );
};
