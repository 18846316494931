import React, { createContext, FC, useCallback, useMemo } from "react";
import { ServiceCaseResponse } from "@deep-consulting-solutions/be2-constants";
import { useCall } from "call";

interface CaseType {
  state: ServiceCaseResponse | null;
  updateState: (values: ServiceCaseResponse | null) => void;
}

export const CaseClassificationsContext = createContext<CaseType>(
  {} as CaseType
);

export const CaseClassificationContextProvider =
  CaseClassificationsContext.Provider;

export const CaseClassificationProvider: FC = ({ children }) => {
  const { viewedCall, updateCallServiceCase } = useCall();

  const state = useMemo(() => {
    return viewedCall?.updatedServiceCase || null;
  }, [viewedCall?.updatedServiceCase]);

  const updateState = useCallback(
    (values: ServiceCaseResponse | null) => {
      if (values && viewedCall?.sessionId) {
        updateCallServiceCase(viewedCall.sessionId, values);
      }
    },
    [updateCallServiceCase, viewedCall?.sessionId]
  );

  return (
    <CaseClassificationContextProvider value={{ state, updateState }}>
      {children}
    </CaseClassificationContextProvider>
  );
};
