import React from "react";
import { TextField } from "formik-material-ui";
import { Field } from "formik";
import {
  Chip,
  MenuItem,
  TextField as BaseTextField,
  TextFieldProps,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Close } from "@material-ui/icons";

type SelectProps = {
  options:
    | Array<{ label: string; value: any; isDisabled?: boolean }>
    | string[];
  name?: string;
  label?: string;
  disabled?: boolean;
};

export const SelectField = ({
  options,
  name,
  label,
  ...props
}: SelectProps & TextFieldProps) => {
  return (
    <Field component={TextField} select name={name} label={label} {...props}>
      {options.map((el) =>
        typeof el === "string" ? (
          <MenuItem key={el} value={el}>
            {el}
          </MenuItem>
        ) : (
          <MenuItem
            key={el.label}
            value={el.value}
            style={{
              pointerEvents: el.isDisabled ? "none" : "auto",
              opacity: el.isDisabled ? 0.5 : 1,
            }}
          >
            {el.label}
          </MenuItem>
        )
      )}
    </Field>
  );
};

export const SimpleSelect = ({
  name,
  options,
  label,
  ...props
}: TextFieldProps & SelectProps) => {
  return (
    <BaseTextField
      size="small"
      variant="outlined"
      name={name}
      label={label}
      {...props}
      select
    >
      {options.map((el) =>
        typeof el === "string" ? (
          <MenuItem key={el} value={el}>
            {el}
          </MenuItem>
        ) : (
          <MenuItem key={el.label} value={el.value}>
            {el.label}
          </MenuItem>
        )
      )}
    </BaseTextField>
  );
};

const MultiSelectWithTags = ({
  options,
  label,
  name,
  value,
  onChange,
  disabled,
  error,
}: SelectProps & {
  value: any[];
  onChange: (e: any) => void;
  disabled?: boolean;
  error?: any;
}) => {
  return (
    <Autocomplete
      disabled={disabled}
      multiple
      size="small"
      options={(options as any[]).filter(
        (option: any) =>
          !value.map((v) => v?.value || v).includes(option?.value || option)
      )}
      value={value}
      onChange={(e: React.ChangeEvent<any>, val) => {
        if (onChange) onChange({ target: { ...e.target, name, value: val } });
      }}
      renderTags={(v, getTagProps) => {
        return v.map((option, index) => {
          return (
            <Chip
              label={option?.label || option}
              color="primary"
              style={{ height: 24 }}
              deleteIcon={<Close style={{ height: 15 }} />}
              {...getTagProps({ index })}
            />
          );
        });
      }}
      getOptionLabel={(option) => option?.label || option}
      renderInput={(params) => {
        return (
          <BaseTextField
            {...params}
            variant="outlined"
            label={label}
            error={error}
            name={name}
          />
        );
      }}
    />
  );
};

export default MultiSelectWithTags;
