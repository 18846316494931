import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  title: {
    fontWeight: 500,
    fontSize: 24,
    padding: s(2, 0),
    lineHeight: "20px",
    display: "flex",
  },

  infoIcon: {
    color: "#ED6C02",
    fontSize: s(2.25),
    marginLeft: s(0.75),
  },
  patientInfoBox: ({ active }: { active?: boolean }) => ({
    border: active ? "2px solid rgba(30, 90, 182, 0.44)" : "1px solid #E0E0E0",
    boxSizing: "border-box",
    width: "100%",
    padding: s(2),
    borderRadius: active ? s(1) : 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: s(3),
    "& .MuiInputBase-root": {
      overflow: "hidden",
      fontSize: s(1.75),
      paddingRight: 0,
    },
    "& .flag-icon-2x": {
      fontSize: s(1.75),
    },
    "& .MuiInputLabel-root": {
      fontSize: s(1.5),
      width: "100%",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      paddingRight: s(3.5),
    },
    "& .MuiInputLabel-shrink": {
      width: "auto",
      textOverflow: "unset",
      paddingRight: 0,
    },
    "& .MuiFormControlLabel-label": {
      fontSize: s(1.5),
    },
    "& .MuiCheckbox-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },

    "& .MuiSvgIcon-root": {
      fontSize: 16,
    },

    "& .MuiInputAdornment-root": {
      width: s(6),
    },
    "& .Mui-checked ~ .MuiFormControlLabel-label": {
      color: p.primary.main,
    },
    "& .MuiGrid-item": {
      paddingTop: s(0.75),
      paddingBottom: s(0.75),
    },
  }),
  addPatientContainer: {
    padding: s(3, 4),
  },
  addPatientText: {
    paddingBottom: s(2),
    color: "#4F4F4F",
  },
  patientInfo: {
    marginTop: s(1),
    width: "100%",
  },
  searchMemberContainer: {
    display: "flex",
    position: "relative",
    width: "100%",
    "& .MuiButton-root": {
      fontSize: s(1.5),
      fontWeight: 500,
      letterSpacing: "0.3px",
    },
    "& .Mui-disabled": {
      background: "#828282",
      color: p.common.white,
    },
  },
  searchFieldsContainer: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    background: "#F7F7F7",
    border: "1px solid #E0E0E0",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
    padding: s(2),
    top: "100%",
    width: "100%",
    zIndex: 10,
    "& .MuiInputBase-root": {
      overflow: "hidden",
      fontSize: s(1.75),
      paddingRight: 0,
    },
    "& .flag-icon-2x": {
      fontSize: s(1.75),
    },
    "& .MuiInputLabel-root": {
      fontSize: s(1.5),
      width: "100%",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      paddingRight: s(3.5),
    },
    "& .MuiInputLabel-shrink": {
      width: "auto",
      textOverflow: "unset",
      paddingRight: 0,
    },
    "& .MuiFormControlLabel-label": {
      fontSize: s(1.5),
    },
    "& .MuiCheckbox-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },

    "& .MuiSvgIcon-root": {
      fontSize: 16,
    },

    "& .MuiInputAdornment-root": {
      width: s(6),
    },
    "& .Mui-checked ~ .MuiFormControlLabel-label": {
      color: p.primary.main,
    },
    "& .MuiGrid-item": {
      paddingTop: s(0.75),
      paddingBottom: s(0.75),
    },
  },
  searchForMembersButton: {
    boxShadow:
      "0px 2px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
  },
  patientForm: {
    "& .MuiInputBase-root": {
      overflow: "hidden",
      fontSize: s(1.75),
      paddingRight: 0,
    },
    "& .flag-icon-2x": {
      fontSize: s(1.75),
    },
    "& .MuiInputLabel-root": {
      fontSize: s(1.5),
      width: "100%",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      paddingRight: s(3.5),
    },
    "& .MuiInputLabel-shrink": {
      width: "auto",
      textOverflow: "unset",
      paddingRight: 0,
    },
    "& .MuiFormControlLabel-label": {
      fontSize: s(1.5),
    },
    "& .MuiCheckbox-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },

    "& .MuiSvgIcon-root": {
      fontSize: 16,
    },

    "& .MuiInputAdornment-root": {
      width: s(6),
    },
    "& .Mui-checked ~ .MuiFormControlLabel-label": {
      color: p.primary.main,
    },
    "& .MuiGrid-item": {
      paddingTop: s(0.75),
      paddingBottom: s(0.75),
    },
  },
  fullSpaced: {
    width: "100%",
    marginTop: s(1),
  },
  deleteButton: {
    background: "rgba(202, 32, 39, 1)",
    color: "white",
    "&:hover": {
      background: "rgba(202, 32, 39, 0.8)",
    },
  },
  fullWidth: {
    width: "100%",
  },
}));
