import {
  GetServiceCase,
  GetServiceCaseNotes,
  PostServiceCaseNote,
  ServiceCaseResponse,
  GetFetchServiceCases,
  PostRecommendServiceCase,
  GetServiceCaseZohoCRMUrl,
  GetServiceCaseAccessLinks,
  PatchAcceptApprovalRequest,
  PatchRefuseApprovalRequest,
  GetServiceCaseCallBackLogs,
  ServiceCaseResponseExtended,
  MovePatientsToNewServiceCase,
  PostGenerateServiceCaseAccessLink,
  PatchResendServiceCaseAccessLinks,
  GetInformationalSupportInstructions,
  PhysicianFailedCallbackUserTypeEnum,
  PatchRejectServiceCaseApprovalRequest,
  PostServiceCasePhysicianFailedCallback,
  PatchSetServiceCaseAccessLinkActiveStatus,
  PatchPermanentlyDeactivateServiceCaseAccessLink,
} from "@deep-consulting-solutions/be2-constants";
import { stringify } from "query-string";

import { crmClient } from "apis";

export const getFetchServiceCases = async (
  params: typeof GetFetchServiceCases.Params
) => {
  const res = await crmClient.get(
    `${GetFetchServiceCases.ROUTE}?${stringify(params)}`
  );
  return res.data.data.serviceCases as ServiceCaseResponseExtended[];
};

export const getServiceCaseNotes = async (
  id: string,
  params: typeof GetServiceCaseNotes.QueryParams
) => {
  const res = await crmClient.get<typeof GetServiceCaseNotes.Res>(
    `${GetServiceCaseNotes.ROUTE.replace(":id", id)}?${stringify(params)}`
  );

  return res.data.data;
};

export const saveServiceCaseNote = async (
  id: string,
  note: typeof PostServiceCaseNote.Body
) => {
  const res = await crmClient.post<typeof PostServiceCaseNote.Res>(
    PostServiceCaseNote.ROUTE.replace(":id", id),
    note
  );

  return res.data.data.note;
};

export const acceptApprovalRequestForMD = async (
  approvalRequestID: string,
  body: typeof PatchAcceptApprovalRequest["Body"]
) => {
  const res = await crmClient.patch<typeof PatchAcceptApprovalRequest["Res"]>(
    PatchAcceptApprovalRequest.ROUTE.replace(":id", approvalRequestID),
    body
  );
  return res.data.data;
};

export const refuseApprovalRequestForMD = async (
  approvalRequestID: string,
  reason: string
) => {
  const res = await crmClient.patch<typeof PatchRefuseApprovalRequest["Res"]>(
    PatchRefuseApprovalRequest.ROUTE.replace(":id", approvalRequestID),
    { reason }
  );
  return res.data.data;
};

export const movePatientsToNewServiceCase = async (
  serviceCaseID: string,
  patientIds: string[]
) => {
  const res = await crmClient.put<typeof MovePatientsToNewServiceCase["Res"]>(
    MovePatientsToNewServiceCase.ROUTE.replace(":id", serviceCaseID),
    { patientIds }
  );
  return res.data.data;
};

export const getInformationalSupportDocumentLink = async () => {
  const res = await crmClient.get<
    typeof GetInformationalSupportInstructions["Res"]
  >(GetInformationalSupportInstructions.ROUTE);
  return res.data.data;
};

export const recommendServiceCase = async (
  id: string,
  body: typeof PostRecommendServiceCase.Body
) => {
  const res = await crmClient.post<typeof PostRecommendServiceCase.Res>(
    PostRecommendServiceCase.ROUTE.replace(":id", id),
    body
  );

  return res.data.data;
};

export const rejectServiceCase = async (id: string, reason: string) => {
  const body: typeof PatchRejectServiceCaseApprovalRequest.Body = {
    reason,
  };

  const res = await crmClient.patch<
    typeof PatchRejectServiceCaseApprovalRequest.Res
  >(
    PatchRejectServiceCaseApprovalRequest.ROUTE.replace(
      ":approvalRequestId",
      id
    ),
    body
  );

  return res.data.data;
};

export const fetchAccessLinksForServiceCase = async (serviceCaseID: string) => {
  const res = await crmClient.get<typeof GetServiceCaseAccessLinks["Res"]>(
    GetServiceCaseAccessLinks.ROUTE.replace(":serviceCaseId", serviceCaseID)
  );
  return res.data.data.accessLinks;
};

export const addAccessLinkForServiceCase = async (
  body: typeof PostGenerateServiceCaseAccessLink["Body"]
) => {
  const res = await crmClient.post<
    typeof PostGenerateServiceCaseAccessLink["Res"]
  >(PostGenerateServiceCaseAccessLink.ROUTE, body);
  return res.data.data;
};

export const resendAccessLink = async (accessLinkID: string) => {
  const res = await crmClient.patch<
    typeof PatchResendServiceCaseAccessLinks["Res"]
  >(
    PatchResendServiceCaseAccessLinks.ROUTE.replace(
      ":accessLinkId",
      accessLinkID
    )
  );
  return res.data.data;
};

export const updateAccessLinkActiveStatus = async (
  caseID: string,
  accessLinkID: string,
  isActive: boolean
) => {
  const body: typeof PatchSetServiceCaseAccessLinkActiveStatus["Body"] = {
    isActive,
  };
  const res = await crmClient.patch<
    typeof PatchSetServiceCaseAccessLinkActiveStatus["Res"]
  >(
    PatchSetServiceCaseAccessLinkActiveStatus.ROUTE.replace(
      ":id",
      caseID
    ).replace(":accessLinkId", accessLinkID),
    body
  );

  return res.data.data.serviceCaseAccessLink;
};

export const permanentlyDeactivateAccessLink = async (
  caseID: string,
  accessLinkID: string
) => {
  const res = await crmClient.patch<
    typeof PatchPermanentlyDeactivateServiceCaseAccessLink["Res"]
  >(
    PatchPermanentlyDeactivateServiceCaseAccessLink.ROUTE.replace(
      ":id",
      caseID
    ).replace(":accessLinkId", accessLinkID)
  );
  return res.data.data.serviceCaseAccessLink;
};

export const logPhysicianCallbackAttempt = async (
  serviceCaseId: string,
  userType: PhysicianFailedCallbackUserTypeEnum
) => {
  const res = await crmClient.post<
    typeof PostServiceCasePhysicianFailedCallback.Res
  >(
    PostServiceCasePhysicianFailedCallback.ROUTE.replace(":id", serviceCaseId),
    {
      userType,
    }
  );

  return res.data.data;
};

export const getPhysicianCallbackLogs = async (
  serviceCaseId: string,
  params: typeof GetServiceCaseCallBackLogs.QueryParams
) => {
  const res = await crmClient.get<typeof GetServiceCaseCallBackLogs.Res>(
    `${GetServiceCaseCallBackLogs.ROUTE.replace(
      ":id",
      serviceCaseId
    )}?${stringify(params)}`
  );

  return res.data.data;
};

export const getServiceCase = async (id: string, isZohoId?: boolean) => {
  const searchParams = new URLSearchParams();

  if (isZohoId) {
    searchParams.set("isZohoId", "true");
  }

  const res = await crmClient.get<typeof GetServiceCase.Res>(
    `${GetServiceCase.ROUTE.replace(":id", id)}?${
      isZohoId ? searchParams.toString() : ""
    }`
  );

  return res.data.data;
};

export const cancelServiceCase = async (id: string, body: object) => {
  const res = await crmClient.patch<{ data: ServiceCaseResponse }>(
    `/service-cases/${id}/cancel-patients`,
    body
  );

  return res.data.data;
};

export const getServiceCaseZohoCRMUrl = async (id: string) => {
  const res = await crmClient.get<typeof GetServiceCaseZohoCRMUrl.Res>(
    GetServiceCaseZohoCRMUrl.ROUTE.replace(":id", id)
  );

  return res.data.data;
};
