import clsx from "clsx";
import { omit } from "lodash";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import React, {
  CSSProperties,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Card,
  Collapse,
  Divider,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  PatientResponseWithContact,
  ServiceCaseRequestTypeEnum,
  ServiceCaseResponse,
  ServiceCaseResponseExtended,
  ServiceCaseStatusEnum,
  ServiceCaseStatusGroupEnum,
  UserRoleEnum,
} from "@deep-consulting-solutions/be2-constants";
import { ROUTES } from "component/configs";
import { useZohoCurrentUser } from "hooks";
import { useAppSelector } from "redux/store";
import { formatNameToDisplay, getLocationItems } from "helpers";
import { DisplayLayout, dispatcherSelectors } from "redux/dispatcher";
import { Action } from "pages/FulfilmentDashboard/types";
import {
  AssignCase,
  CloseServiceCase,
  ContactRequester,
} from "component/Dialogs";
import { ReactComponent as LocationIcon } from "pages/FulfilmentDashboard/Views/MedicalDirector/locationIcon.svg";
import {
  getStatusGroup,
  renderServiceCaseActions,
} from "pages/FulfilmentDashboard/helpers";
import { Popover } from "pages/FulfilmentDashboard/components/Popover";
import { CSSTransition } from "react-transition-group";
import "./PatientStatusSnippet.css";
import { useMap } from "call";
import { RenderCaseStatus } from "./RenderCaseStatus";
import { StatusSnippetTag } from "./StatusSnippetTag";
import { getColors } from "./utils";
import { useInfoWindow } from "./useInfoWindow";

type BorderType = {
  caseStatus: ServiceCaseStatusEnum;
  requestType: ServiceCaseRequestTypeEnum;
};

const getColor = (type: string | BorderType) => {
  if (typeof type === "object") {
    if (type.requestType === ServiceCaseRequestTypeEnum.EMERGENCY) {
      return "#CA2027";
    }
    if (
      getStatusGroup(type.caseStatus) === ServiceCaseStatusGroupEnum.IN_PROGRESS
    ) {
      return "#08A40F";
    }
    return "white";
  }
  return "white";
};

const getBg = (bg: string) => {
  return bg || "white";
};

interface StyleProps {
  bg?: string;
  minHeightActive?: boolean;
  borderLine?: string | BorderType;
}

const useStyles = makeStyles<Theme, StyleProps>(({ palette }) => ({
  cardInner: {
    backgroundColor: ({ bg }) => getBg(bg as string),
    padding: 8,
    borderRadius: 4,
    marginBottom: 10,
    border: ({ borderLine }) =>
      borderLine && `1px solid ${getColor(borderLine)}`,
    minHeight: ({ minHeightActive }) => (minHeightActive ? 157 : undefined),
    "&:hover": {
      cursor: "pointer",
    },
  },
  alignHorizontal: {
    minWidth: 400,
    maxWidth: 450,
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 10,
  },
  text: {
    fontSize: 10,
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 8,
  },
  snippet: {
    padding: "4px 8px",
    borderRadius: 4,
    border: "1px solid #d9e4ea",
    marginBottom: 4,
    textTransform: "capitalize",
  },
  details: {
    display: "flex",
    color: "#4F4F4F",
  },
  collapseBtn: {
    textTransform: "capitalize",
    fontSize: 10,
    color: "#4f4f4f",
    "& :hover": {
      color: palette.primary.main,
    },
  },
  collapsed: {
    position: "relative",
    transition: "all 0.5s",
    fontSize: 10,
    marginLeft: 10,
  },
  unCollapsed: {
    top: -4,
    transform: "rotate(-90deg)",
  },
  moreBtn: {
    background: "none",
    border: "none",
    padding: 0,
    position: "relative",
    right: -10,
    height: "min-content",
    cursor: "pointer",
    paddingLeft: 5,
  },
  more: {
    color: "#828282",
  },
  bold: {
    fontWeight: 600,
  },
  dropdown: {
    backgroundColor: "white",
    borderRadius: 4,
  },
  dropdownBtn: {
    all: "unset",
    cursor: "pointer",
    padding: 8,
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    minWidth: 120,
  },
  removeBtnPadding: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 0,
  },
}));

const Snippet = ({
  firstName,
  lastName,
  nextOfKin,
  selectedSubsCoverage,
}: Array<PatientResponseWithContact>[0]) => {
  const classes = useStyles({});

  return (
    <div className={classes.snippet}>
      <Typography variant="caption" color="primary">
        {formatNameToDisplay(firstName, lastName)}
      </Typography>
      <div className={clsx(classes.details, classes.spaceBetween)}>
        <div>
          <Typography style={{ fontSize: 8 }}>Next of Kin:</Typography>
          <Typography style={{ fontSize: 10 }}>
            <b>
              {formatNameToDisplay(nextOfKin?.firstName, nextOfKin?.lastName) ||
                "--"}
            </b>
          </Typography>
        </div>

        <div>
          <Typography style={{ fontSize: 8 }}>Coverage:</Typography>

          {selectedSubsCoverage?.coverage ? (
            <Typography variant="subtitle2" style={{ fontSize: 10 }}>
              {selectedSubsCoverage.coverage}
            </Typography>
          ) : (
            <Typography>
              <b>--</b>
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

interface PatientStatusSnippetProps
  extends PropsWithChildren<ServiceCaseResponseExtended> {
  afterContents?: any;
  borderHighlight?: boolean;
  minHeightActive?: boolean;
  wrapperStyle?: CSSProperties;
  onMore?: (selected: string) => void;
  onUpdate?: (data: ServiceCaseResponse) => void;
  clickable?: boolean;
  isNew?: boolean;
}

export const PatientStatusSnippet = (props: PatientStatusSnippetProps) => {
  const { currentUser } = useZohoCurrentUser();
  const { isDispatcher, isMedicalDirector } = useAppSelector(
    dispatcherSelectors.roles
  );

  const {
    id,
    isNew,
    onMore,
    service,
    overdue,
    patients,
    onUpdate,
    updatedAt,
    createdAt,
    requester,
    caseNumber,
    caseStatus,
    dispatcher,
    requestType,
    wrapperStyle,
    afterContents,
    minHeightActive,
    borderHighlight = true,
    quotes,
    clickable = true,
    initialLocation,
  } = props;

  const { displayLayout } = useAppSelector((state) => state.dispatcher);

  const [showPatient, setShowPatient] = useState(false);
  const [showAction, setShowAction] = useState("");

  const history = useHistory();
  const [hasChange, setHasChange] = useState(false);

  const colors = useMemo(
    () =>
      getColors({
        hasChange,
        caseStatus,
        isMedicalDirector,
        role: currentUser?.profile.name,
      }),
    [hasChange, caseStatus, isMedicalDirector, currentUser?.profile.name]
  );

  const classes = useStyles({
    borderLine: borderHighlight
      ? {
          caseStatus,
          requestType,
        }
      : "",
    minHeightActive,
    bg: colors.bg,
  });

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const onActionSelect = useCallback(
    (action: Action) => {
      setShowAction(action);

      if ([Action.Edit, Action.MedicalAssessment].includes(action)) {
        history.push(ROUTES.caseClassifications.path, {
          serviceCase: omit(
            props,
            "afterContents",
            "borderHighlight",
            "minHeightActive",
            "wrapperStyle",
            "onMore",
            "onUpdate",
            "children",
            "clickable"
          ),
        });
      } else if (action === Action.Crm) {
        const { zohoID } = props;
        window.open(
          `https://crm.zoho.com/crm/org757043308/tab/CustomModule13/${zohoID}`,
          "_blank"
        );
      }
    },
    [history, props]
  );

  const renderServiceCaseActionsMem = useMemo(
    () =>
      renderServiceCaseActions(
        currentUser?.profile?.name as UserRoleEnum,
        caseStatus,
        isMedicalDirector,
        isDispatcher
      ),
    [caseStatus, isDispatcher, isMedicalDirector, currentUser?.profile?.name]
  );

  const triggerUpdate = () => {
    setHasChange(true);

    setTimeout(() => {
      setHasChange(false);
    }, 1000);
  };

  useEffect(() => {
    if (isNew) {
      triggerUpdate();
      onUpdate?.({
        ...omit(
          props,
          "afterContents",
          "borderHighlight",
          "minHeightActive",
          "wrapperStyle",
          "onMore",
          "onUpdate",
          "children",
          "clickable"
        ),
        isNew: false,
      } as ServiceCaseResponseExtended);
    }
  }, [isNew, props, onUpdate]);

  const ref = useRef<string | null>(null);

  useEffect(() => {
    if (ref.current && ref.current !== updatedAt) {
      triggerUpdate();
    }

    ref.current = updatedAt;
  }, [updatedAt]);

  useInfoWindow({
    serviceCase: omit(
      props,
      "afterContents",
      "borderHighlight",
      "minHeightActive",
      "wrapperStyle",
      "onMore",
      "onUpdate",
      "children",
      "clickable"
    ),
  });

  const { map } = useMap();

  const handleLocationsClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();

      if (map && initialLocation) {
        const { lat, lng } = getLocationItems(initialLocation);

        if (lat && lng) {
          map.setZoom(12);
          map.setCenter({ lat, lng });
        }
      }
    },
    [map, initialLocation]
  );

  const serviceCase = useMemo(
    () =>
      omit(
        props,
        "afterContents",
        "borderHighlight",
        "minHeightActive",
        "wrapperStyle",
        "onMore",
        "onUpdate",
        "children",
        "clickable"
      ),
    [props]
  );

  const [unansweredCase, setUnansweredCase] =
    useState<ServiceCaseResponse | null>(null);

  const handleCardClick = useCallback(() => {
    if (clickable) {
      if (caseStatus === ServiceCaseStatusEnum.UNANSWERED) {
        setUnansweredCase(serviceCase);
      } else {
        onActionSelect(Action.Edit);
      }
    }
  }, [caseStatus, serviceCase, clickable, onActionSelect]);

  const handleContactRequesterUpdate = useCallback(
    (key: string, value: any) => {
      if (onUpdate) {
        if (key === "callBackLogs") {
          const updatedServiceCase = {
            ...serviceCase,
            callBackLogs: [...serviceCase.callBackLogs, value],
          };

          onUpdate(updatedServiceCase);
          setUnansweredCase(updatedServiceCase);
        } else if (key === "record") {
          const updatedServiceCase = value as ServiceCaseResponse;
          onUpdate(updatedServiceCase);
          setUnansweredCase(updatedServiceCase);
        }
      }
    },
    [onUpdate, serviceCase]
  );

  return (
    <>
      <AssignCase
        open={showAction === Action.Assign}
        {...props}
        onClose={() => setShowAction("")}
        onUpdate={onUpdate}
      />
      <CloseServiceCase
        maxWidth={508}
        confirmDialog
        id={id}
        open={showAction === Action.Close}
        onClose={() => setShowAction("")}
        quotes={quotes}
        onUpdate={onUpdate}
      />

      {unansweredCase && (
        <ContactRequester
          data={unansweredCase}
          onClose={() => setUnansweredCase(null)}
          onUpdate={handleContactRequesterUpdate}
        />
      )}

      <CSSTransition
        timeout={1000}
        in={hasChange}
        classNames="snippet"
        addEndListener={(node, done) => {
          node.addEventListener("transitionend", done, false);
        }}
      >
        <Card
          className={clsx(
            classes.cardInner,
            displayLayout === DisplayLayout.horizontal &&
              classes.alignHorizontal
          )}
          style={{ overflow: "unset", minHeight: 159, ...wrapperStyle }}
          onClick={handleCardClick}
        >
          <div className={classes.heading}>
            <Typography
              color="primary"
              variant="caption"
              className={classes.bold}
            >
              {caseNumber} -{" "}
              <span style={{ textTransform: "capitalize" }}>{requestType}</span>
            </Typography>
            <Typography color="primary" style={{ fontSize: 8 }}>
              Dispatcher:{" "}
              <b style={{ textTransform: "capitalize" }}>
                {formatNameToDisplay(
                  dispatcher?.firstName,
                  dispatcher?.lastName
                ) || "--"}
              </b>
            </Typography>
            <div className={classes.heading}>
              <Typography style={{ fontSize: 8, color: "#4F4F4F" }}>
                {updatedAt
                  ? format(new Date(updatedAt ?? createdAt), "d LLL yyyy p")
                  : "--"}
              </Typography>
              {onMore && (
                <div style={{ position: "relative" }}>
                  {!!renderServiceCaseActionsMem.length && (
                    <Popover
                      onClick={handleClick}
                      buttonClassName={classes.moreBtn}
                      handleClick={handleClick}
                      button={<MoreVertIcon className={classes.more} />}
                      content={({ handleClose }) => (
                        <div className={classes.dropdown}>
                          {renderServiceCaseActionsMem.map((el, index, arr) => (
                            <React.Fragment key={`${index.toString()}`}>
                              <Tooltip
                                title={
                                  Action.Crm === el
                                    ? "To view case details and attach documents"
                                    : ""
                                }
                              >
                                <button
                                  type="button"
                                  className={classes.dropdownBtn}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    onActionSelect(el);
                                    handleClose(e);
                                  }}
                                >
                                  <Typography variant="caption" color="primary">
                                    {el}
                                  </Typography>
                                </button>
                              </Tooltip>
                              {arr.length - 1 !== index && <Divider />}
                            </React.Fragment>
                          ))}
                        </div>
                      )}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          <Divider style={{ margin: "8px 0px" }} />
          <Box mb={1}>
            {colors.bg ? (
              <StatusSnippetTag status={caseStatus} colors={colors} />
            ) : (
              <RenderCaseStatus overdue={overdue} caseStatus={caseStatus} />
            )}
          </Box>
          <div className={classes.spaceBetween}>
            <Typography className={clsx(classes.text)} style={{ fontSize: 12 }}>
              {service}
            </Typography>
            <button
              type="button"
              style={{ all: "unset" }}
              onClick={handleLocationsClick}
            >
              <Typography
                className={clsx(classes.text)}
                style={{ display: "flex", alignItems: "center", gap: 8 }}
              >
                Locations
                <Tooltip placement="top-start" arrow title="View Destinations">
                  <LocationIcon />
                </Tooltip>
              </Typography>
            </button>
          </div>
          <div className={classes.spaceBetween}>
            <Typography
              className={clsx(classes.text)}
              style={{ textTransform: "capitalize" }}
            >
              Requester:{" "}
              <b>
                {formatNameToDisplay(requester?.firstName, requester?.lastName)}
              </b>
            </Typography>
            <Button
              size="small"
              variant="text"
              className={clsx(
                classes.collapseBtn,
                (!patients || patients?.length === 0) &&
                  classes.removeBtnPadding
              )}
              onClick={(e) => {
                e.stopPropagation();
                setShowPatient(!showPatient);
              }}
            >
              {`Patient${patients && patients.length > 1 ? "s" : ""}`}
              {patients && patients?.length > 0 && (
                <ArrowBackIosIcon
                  className={clsx(
                    classes.collapsed,
                    showPatient && classes.unCollapsed
                  )}
                  style={{ fontSize: 10, color: "#263E7F" }}
                />
              )}
            </Button>
          </div>
          {patients && patients?.length ? (
            <Collapse in={showPatient}>
              <div>
                {patients.map((el) => (
                  <Snippet key={el.contactID} {...el} />
                ))}
              </div>
            </Collapse>
          ) : (
            <Typography
              variant="caption"
              style={{
                fontSize: 8,
                color: "#828282",
                display: "block",
                textAlign: "center",
              }}
            >
              Patient(s) information not gathered from the requester yet.
            </Typography>
          )}
          {afterContents && (
            <Box>
              <Divider style={{ margin: "8px 0px" }} />
              {afterContents}
            </Box>
          )}
        </Card>
      </CSSTransition>
    </>
  );
};
