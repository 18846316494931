import { PostVerifyLocationCollectorLink } from "@deep-consulting-solutions/be2-constants";
import { crmClient } from "apis";

export const verifyLocationCollectorLinkReq = async (
  id: string,
  otp: string
) => {
  const res = await crmClient.post<typeof PostVerifyLocationCollectorLink.Res>(
    `${PostVerifyLocationCollectorLink.ROUTE.replace(":id", id)}?otp=${otp}`
  );

  return res.data.data;
};
