import React from "react";

import { useCall } from "call/hooks";
import {
  ServiceCaseResponse,
  ServiceCaseStatusEnum,
} from "@deep-consulting-solutions/be2-constants";
import { AcceptanceByBEManager } from "pages/FulfilmentDashboard/Views/CaseClassifications/Tabs/Details/Tabs/Service/AcceptanceByBEManager";
import { ServiceFormComponent } from "./ServiceFormComponent";
import { TabsEnum } from "../types";

interface Props {
  activeTab: TabsEnum;
  skippedTabs: TabsEnum[];
  switchTab: (tab: TabsEnum) => void;
  handleSkipTab: (tab: TabsEnum) => void;
  handleEnableTab: (tab: TabsEnum) => void;
  handleIncludeTab: (tab: TabsEnum) => void;
  handleDisableTab: (tab: TabsEnum) => void;
  setCases: React.Dispatch<React.SetStateAction<ServiceCaseResponse[]>>;
}

export const ServiceForm = (props: Props) => {
  const { handleEnableTab, setCases } = props;
  const { viewedCall } = useCall();

  if (
    viewedCall?.updatedServiceCase?.caseStatus ===
    ServiceCaseStatusEnum.PENDING_BE_ACCEPTANCE
  ) {
    return (
      <AcceptanceByBEManager
        handleEnableTab={handleEnableTab}
        setCases={setCases}
      />
    );
  }

  return <ServiceFormComponent {...props} setCases={setCases} />;
};
