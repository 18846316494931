import {
  PatientCoverageEnum,
  PatientResponseWithContact,
} from "@deep-consulting-solutions/be2-constants";
import {
  Collapse,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import React, {
  useCallback,
  useMemo,
  useState,
  MouseEventHandler,
  useEffect,
} from "react";
import { TextField } from "formik-material-ui";
import { Field, useFormikContext } from "formik";
import {
  getIsServiceToBeRenderedInformationalSupport,
  getIsServiceToBeRenderedEmergencyAirliftEvacuationOrHospitalTransferOrRepatriationOrCustom,
} from "../helpers";
import { FormValues, PatientFormValues } from "../types";
import { PatientCoverage } from "../components";

interface StyleProps {
  showInfo?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(
  ({ spacing: s, palette: p }) => ({
    tableContainer: {
      boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",
      border: "1px solid #E0E0E0",
      borderRadius: 4,
    },
    tableRow: {
      padding: s(1),
    },
    tableHead: {
      background: "rgba(236, 236, 236, 1)",
    },
    wrapper: {
      background: "#FFFFFF",
      boxShadow: " 0px 3px 5px rgba(0, 0, 0, 0.12)",
      borderRadius: 8,
      padding: s(2),
      marginBottom: s(4),
    },
    title: {
      fontSize: 20,
    },
    collapse: {
      padding: s(2),
    },
    subtitle: {
      fontSize: 18,
      color: "#4F4F4F",
      marginBottom: s(2),
    },
    icon: ({ showInfo }) => ({
      transformOrigin: "center",
      transition: "all 0.3s",
      transform: showInfo ? undefined : "rotate(90deg)",
    }),
    tableCellTitle: {
      fontWeight: 500,
      color: p.primary.dark,
      textAlign: "center",
      display: "block",
      fontSize: 12,
    },
    medicalStatusTitle: {
      color: p.grey[400],
      fontWeight: 500,
    },
    medicalStatus: {
      marginTop: s(4),
    },
  })
);

interface Props {
  patient: PatientResponseWithContact;
  formKey: string;
  handleOpenPaymentDialog: (values: {
    subscriptionForPayment: string;
    subscriptionContact: string;
  }) => void;
  patientValue: PatientFormValues;
  disableForm: boolean;
}

export const PatientCard = ({
  patient,
  handleOpenPaymentDialog,
  formKey,
  patientValue,
  disableForm,
}: Props) => {
  const [showInfo, setShowInfo] = useState(true);

  const {
    values: { serviceToBeRendered },
    setFieldValue,
  } = useFormikContext<FormValues>();

  const { showMedicalStatus, isServiceToBeRenderedCustom } = useMemo(() => {
    return {
      showMedicalStatus:
        getIsServiceToBeRenderedEmergencyAirliftEvacuationOrHospitalTransferOrRepatriationOrCustom(
          serviceToBeRendered
        ),
      isServiceToBeRenderedCustom:
        getIsServiceToBeRenderedInformationalSupport(serviceToBeRendered),
    };
  }, [serviceToBeRendered]);

  const { fullName, isBEMember, coverages } = useMemo(() => {
    return {
      fullName: `${patient.firstName} ${patient.lastName}`,
      isBEMember: !!patient.isMemberOrCustomer,
      coverages: patient.subCoverage,
      id: patient.patientID,
      medicalStatus: patient.medicalStatus,
    };
  }, [
    patient.firstName,
    patient.lastName,
    patient.patientID,
    patient.subCoverage,
    patient.isMemberOrCustomer,
    patient.medicalStatus,
  ]);

  const handleToggleShowInfo: MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      setShowInfo((c) => !c);
    }, []);

  const handleCoverageChange = useCallback(
    (coverageId = "", coverageStatus = "") => {
      setFieldValue(formKey, {
        subCoverageID: coverageId,
        coverageStatus,
        isBEMember: patientValue.isBEMember,
        id: patientValue.id,
        medicalStatus: patientValue.medicalStatus,
      });
    },
    [
      setFieldValue,
      formKey,
      patientValue.isBEMember,
      patientValue.id,
      patientValue.medicalStatus,
    ]
  );

  useEffect(() => {
    if (!isBEMember) {
      setShowInfo(false);
    }
  }, [isBEMember]);

  useEffect(() => {
    if (coverages.length === 1 && !patientValue.subCoverageID) {
      handleCoverageChange(
        coverages[0].id,
        isServiceToBeRenderedCustom
          ? PatientCoverageEnum.NOT_COVERED
          : coverages[0].coverage || ""
      );
    }
  }, [
    coverages,
    handleCoverageChange,
    isServiceToBeRenderedCustom,
    patientValue.subCoverageID,
  ]);

  const classes = useStyles({ showInfo });

  return (
    <div className={classes.wrapper}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography
            color="primary"
            variant="subtitle2"
            className={classes.title}
          >
            {fullName}
          </Typography>
        </Grid>
        {isBEMember ? (
          <Grid item>
            <IconButton onClick={handleToggleShowInfo}>
              <KeyboardArrowDown color="primary" className={classes.icon} />
            </IconButton>
          </Grid>
        ) : null}
      </Grid>
      <Collapse in={showInfo}>
        <div className={classes.collapse}>
          <Typography variant="subtitle2" className={classes.subtitle}>
            Coverage
          </Typography>

          <PatientCoverage
            patient={patient}
            handleOpenPaymentDialog={handleOpenPaymentDialog}
            selectedCoverage={patientValue.subCoverageID}
            handleCoverageChange={handleCoverageChange}
            service={serviceToBeRendered}
            disableForm={disableForm}
          />

          {showMedicalStatus ? (
            <div className={classes.medicalStatus}>
              <Typography
                color="primary"
                className={classes.medicalStatusTitle}
                variant="subtitle1"
              >
                Medical Status
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field
                    size="small"
                    component={TextField}
                    name={`${formKey}.medicalStatus`}
                    label="Medical Status of Patient"
                    required={isBEMember}
                    multiline
                    minRows={3}
                    disabled={disableForm}
                  />
                </Grid>
              </Grid>
            </div>
          ) : null}
        </div>
      </Collapse>
    </div>
  );
};
