import { useCall } from "call/hooks";
import { useFormikContext } from "formik";
import React, { useMemo } from "react";
import { InfoCard } from "../components";
import {
  getIsServiceCaseApproved,
  getIsServiceCaseRefused,
  getShouldGoToPayment,
} from "../helpers";
import { FormValues } from "../types";

const notCoveredConditions = [
  "Ask the Requester if they are willing to pay for non-covered services, if provided with a quote, otherwise only informational support should be provided.",
  "Inform the Requester that payment for emergency services must be done by credit card.",
  "Wire transfer can be used in other cases but not recommended.",
];

export const NotCoveredConditions = () => {
  const {
    values: { serviceToBeRendered, patients },
  } = useFormikContext<FormValues>();
  const { viewedCall } = useCall();

  const visible = useMemo(
    () => getShouldGoToPayment(serviceToBeRendered, patients || []),
    [serviceToBeRendered, patients]
  );

  const isServiceCaseAccepted = useMemo(() => {
    return getIsServiceCaseApproved(
      viewedCall?.updatedServiceCase?.approvalRequests
    );
  }, [viewedCall?.updatedServiceCase?.approvalRequests]);

  const isServiceCaseRefused = useMemo(
    () =>
      getIsServiceCaseRefused(viewedCall?.updatedServiceCase?.approvalRequests),
    [viewedCall?.updatedServiceCase?.approvalRequests]
  );

  if (isServiceCaseAccepted || isServiceCaseRefused) {
    return null;
  }

  return (
    <InfoCard texts={notCoveredConditions} variant="error" visible={visible} />
  );
};
