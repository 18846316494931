import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useCallback, MouseEventHandler, useMemo } from "react";
import * as yup from "yup";
import { FormikProvider, useFormik, Field } from "formik";
import { getENText } from "helpers";
import { notifications } from "services";
import { CheckboxWithLabel } from "formik-material-ui";
import { useCall } from "call/hooks";
import { movePatients } from "redux/call/requests";
import Loader from "component/Loader";
import { Close } from "@material-ui/icons";
import {
  ServiceCaseResponse,
  PatientResponseWithContact,
} from "@deep-consulting-solutions/be2-constants";

const useStyles = makeStyles(({ spacing: s }) => ({
  title: {
    background: "#F6F6F6",
    padding: s(2),
  },
  content: {
    padding: s(2),
  },
  actions: {
    padding: s(2),
  },
  bodyText: {
    color: "#4F4F4F",
    marginBottom: s(2),
  },
  wrapper: {
    borderRadius: 16,
  },
  titleText: {
    fontWeight: 500,
  },
  closeIcon: {
    color: "#4F4F4F",
  },
}));

const getPatientNames = (
  patientIds: string[],
  patients: PatientResponseWithContact[]
) => {
  const patientsNames = patients
    .filter((patient) => patientIds.includes(patient.patientID))
    .map(
      (patient) =>
        `${patient.firstName || ""}${patient.firstName ? " " : ""}${
          patient.lastName || ""
        }`
    );
  return patientsNames.join(", ");
};

interface Props {
  handleClose: () => void;
  setCases: React.Dispatch<React.SetStateAction<ServiceCaseResponse[]>>;
}

export const MovePatientsToNewCaseDialog = ({
  handleClose,
  setCases,
  ...props
}: DialogProps & Props) => {
  const { viewedCall, updateCallServiceCase } = useCall();

  const classes = useStyles();

  const { patients, hasRequesterPatient } = useMemo(() => {
    const patientsNotRequester =
      viewedCall?.updatedServiceCase?.patients?.filter((p) => !p.isRequester) ||
      [];
    return {
      patients: patientsNotRequester,
      hasRequesterPatient:
        patientsNotRequester.length !==
        (viewedCall?.updatedServiceCase?.patients || []).length,
    };
  }, [viewedCall?.updatedServiceCase?.patients]);

  const formik = useFormik<{ patients: string[] }>({
    initialValues: {
      patients: [],
    },
    validationSchema: yup.object({
      patients: yup
        .array()
        .required(getENText("movePatientsDialog.patients.required")),
    }),
    onSubmit: async (values, { setSubmitting, setFieldValue }) => {
      try {
        setSubmitting(true);
        const res = await movePatients(
          viewedCall!.updatedServiceCase!.id,
          values.patients
        );

        updateCallServiceCase(viewedCall!.sessionId, res.existingServiceCase);
        setCases((existingCases) => [...existingCases, res.clonedServiceCase]);

        notifications.notifySuccess(
          getENText("serviceForm.movePatientsDialog.successful", {
            patient_names_list: getPatientNames(values.patients, patients),
            new_service_case_number: res.clonedServiceCase.caseNumber,
          })
        );
        handleClose();
        setFieldValue("patients", []);
      } catch (error) {
        //
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      formik.handleSubmit();
    },
    [formik]
  );

  const handleCancel: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      handleClose();
    },
    [handleClose]
  );

  const getIsDisabled = useCallback(
    (patientID: string) => {
      if (hasRequesterPatient) {
        return false;
      }
      if (
        patients.length > 1 &&
        formik.values.patients.length === patients.length - 1 &&
        !formik.values.patients.includes(patientID)
      ) {
        return true;
      }

      return false;
    },
    [formik.values.patients, patients, hasRequesterPatient]
  );

  return (
    <>
      <Loader open={formik.isSubmitting} />
      <Dialog {...props} className={classes.wrapper} fullWidth maxWidth="sm">
        <DialogTitle className={classes.title}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography
                align="left"
                color="primary"
                variant="subtitle1"
                className={classes.titleText}
              >
                Move Patients to New Case
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <Close className={classes.closeIcon} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <FormikProvider value={formik}>
            <form>
              <Grid container alignItems="center" wrap="wrap" spacing={2}>
                {patients.map((patient) => (
                  <Grid key={patient.email} item>
                    <Field
                      size="small"
                      component={CheckboxWithLabel}
                      name="patients"
                      value={patient.patientID}
                      color="primary"
                      Label={{
                        label: `${patient.firstName} ${patient.lastName}`,
                      }}
                      disabled={getIsDisabled(patient.patientID)}
                      type="checkbox"
                    />
                  </Grid>
                ))}
              </Grid>
            </form>
          </FormikProvider>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
          >
            <Grid item>
              <Button color="primary" variant="outlined" onClick={handleCancel}>
                CANCEL
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                onClick={handleSubmit}
                disabled={!formik.isValid}
              >
                MOVE PATIENTS TO NEW CASE
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
