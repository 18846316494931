import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import React, { useMemo, useEffect } from "react";
import {
  ServiceCaseResponse,
  ServiceCaseStatusGroupEnum,
} from "@deep-consulting-solutions/be2-constants";

import { getIcon, useMap } from "call";
import { ROUTES } from "component/configs";

import { getStatusGroup } from "../helpers";
import SnippetInfoWindow from "./SnippetInfoWindow";

interface InfoWindowArgs {
  serviceCase: ServiceCaseResponse;
}

export const useInfoWindow = ({ serviceCase }: InfoWindowArgs) => {
  const history = useHistory();

  const { caseNumber, caseStatus, requestType, initialLocation } = serviceCase;
  const {
    map,
    closePopup,
    fetchPopup,
    createPopup,
    updatePopup,
    createMarker,
    deleteMarker,
  } = useMap();

  const div = useMemo(() => {
    const element = document.createElement("div");

    ReactDOM.render(
      <SnippetInfoWindow
        caseNumber={serviceCase.caseNumber}
        caseStatus={serviceCase.caseStatus}
        requestType={serviceCase.requestType}
        onTitleClick={() => {
          history.push(ROUTES.caseClassifications.path, {
            serviceCase: {
              id: serviceCase.id,
            },
          });
        }}
      />,
      element
    );

    return element;
  }, [
    history,
    serviceCase.id,
    serviceCase.caseNumber,
    serviceCase.caseStatus,
    serviceCase.requestType,
  ]);

  const iconFillColor = useMemo(() => {
    let value = "";

    switch (getStatusGroup(caseStatus)) {
      case ServiceCaseStatusGroupEnum.NEW:
      case ServiceCaseStatusGroupEnum.RE_OPENED:
        value = "#2196F3";
        break;

      case ServiceCaseStatusGroupEnum.SCHEDULED:
        value = "#ED6C02";
        break;

      case ServiceCaseStatusGroupEnum.IN_PROGRESS:
        value = "#08A40F";
        break;

      default:
        break;
    }

    return value;
  }, [caseStatus]);

  useEffect(() => {
    if (initialLocation && iconFillColor) {
      const split = initialLocation.split(",");
      const splitMap = split.map(Number);
      const [lat, lng] = splitMap;

      if (lat && lng) {
        const marker = createMarker({
          title: caseNumber,
          position: { lat, lng },
          icon: getIcon(iconFillColor) || undefined,
        });

        if (map) {
          marker.addListener("click", () => {
            const position = new google.maps.LatLng(lat, lng);
            const existingPopup = fetchPopup();

            if (existingPopup) {
              // popup belongs to same marker.
              if (existingPopup.position.toString() === position.toString()) {
                existingPopup.toggle();
              } else {
                // popup belongs to different marker
                updatePopup(position, div);
              }
            }
          });

          return () => {
            closePopup();
            google.maps.event.clearListeners(marker, "click");

            deleteMarker(caseNumber);
          };
        }
      }
    }
  }, [
    div,
    map,
    closePopup,
    caseNumber,
    fetchPopup,
    createPopup,
    updatePopup,
    requestType,
    createMarker,
    deleteMarker,
    iconFillColor,
    initialLocation,
  ]);

  return null;
};
