import { crmClient, crmSilentClient } from "apis";
import { stringify } from "query-string";
import {
  GetDispatchers,
  GetAuthenticatedDispatcher,
  GetDispatcherActivityLogRecords,
  GetAuthenticatedMedicalDirector,
} from "@deep-consulting-solutions/be2-constants";

export const getRecordsRequest = async (
  id: string,
  params: typeof GetDispatcherActivityLogRecords.Params
) => {
  const res = await crmClient.get<typeof GetDispatcherActivityLogRecords.Res>(
    `${GetDispatcherActivityLogRecords.ROUTE.replace(":id", id)}?${stringify(
      params
    )}`
  );

  return res.data.data;
};

export const getAllDispatchersRequest = async () => {
  const res = await crmClient.get<typeof GetDispatchers.Res>(
    `${GetDispatchers.ROUTE}`
  );
  return res.data.data.dispatchers;
};

export const getAuthenticatedDispatcher = async () => {
  const res = await crmSilentClient.get<typeof GetAuthenticatedDispatcher.Res>(
    GetAuthenticatedDispatcher.ROUTE
  );
  return res.data.data;
};

export const getAuthenticatedMedicalDirector = async () => {
  const res = await crmSilentClient.get<
    typeof GetAuthenticatedMedicalDirector.Res
  >(GetAuthenticatedMedicalDirector.ROUTE);
  return res.data.data;
};

export const fetchRoles = async () => {
  const booleanTry = async (func: () => Promise<{ id: string }>) => {
    try {
      const { id } = await func();
      return id;
    } catch {
      return "";
    }
  };

  try {
    const roles = await Promise.all([
      booleanTry(getAuthenticatedMedicalDirector),
      booleanTry(getAuthenticatedDispatcher),
    ]);
    return roles;
  } catch {
    return ["", ""];
  }
};
