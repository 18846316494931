import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import React from "react";
import { TableSortLabel } from "@material-ui/core";

interface Column {
  id:
    | "caseNumber"
    | "requestType"
    | "createdAt"
    | "requestOrigin"
    | "callBackLogs"
    | "contact"
    | "contactNumber";
  label: string;
  minWidth?: number;
  align?: "right" | "left" | "center";
  format?: (value: any) => any;
}

export function EnhancedTableHead(props: {
  order?: "asc" | "desc" | false;
  columns: Column[];
  orderBy?: string;
  onRequestSort: (clickedTableHead: string) => void;
}) {
  const { order, orderBy, onRequestSort, columns } = props;
  const createSortHandler = (clickedTableHead: string) => () => {
    onRequestSort(clickedTableHead);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.id}
            align={column.align}
            style={{
              minWidth: column.minWidth,
              background: "#EFEFEF",
            }}
            sortDirection={orderBy === column.id ? order : false}
          >
            {["caseNumber", "callBackLogs"].includes(column.id) ? (
              <TableSortLabel
                active={orderBy === column.id}
                direction={
                  orderBy === column.id ? (order as "asc" | "desc") : "asc"
                }
                onClick={createSortHandler(column.id)}
              >
                <p style={{ fontWeight: 500 }}>{column.label}</p>
              </TableSortLabel>
            ) : (
              <p style={{ fontWeight: 500 }}>{column.label}</p>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
