import React, { useCallback, useState } from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import ArrowForwardIosOutlined from "@material-ui/icons/ArrowForwardIosOutlined";
import ArrowBackIosOutlined from "@material-ui/icons/ArrowBackIosOutlined";
import CallIcon from "@material-ui/icons/Call";
import { useCall } from "call/hooks";
import { Call } from "../../types";

type Size = "large" | "small";
interface StyleProps {
  isEmergency?: boolean;
  size: Size;
}
const useStyles = makeStyles<Theme, StyleProps>(
  ({ spacing: s, palette: p }) => ({
    container: ({ isEmergency }) => ({
      background: isEmergency ? "#CA2027" : "#FFFFFF",
      boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.15)",
      borderRadius: "8px 0px 0px 8px",
      padding: s(2),
      marginBottom: s(2),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    }),
    arrow: ({ isEmergency }) => ({
      color: isEmergency ? "white" : p.grey[500],
      cursor: "pointer",
    }),
    info: ({ isEmergency }) => ({
      color: isEmergency ? "white" : "#08A40F",
      fontWeight: 500,
      whiteSpace: "nowrap",
    }),
    source: ({ isEmergency }) => ({
      color: isEmergency ? "white" : p.grey[500],
      fontWeight: 300,
      fontSize: 10,
      whiteSpace: "nowrap",
    }),
    callIconContainer: ({ isEmergency }) => ({
      background: isEmergency ? "white" : "#08A40F",
      borderRadius: "50%",
      outline: `4px solid ${
        isEmergency ? "rgba(255, 255, 255, 0.32)" : "rgba(8, 164, 15, 0.32)"
      }`,
      width: 48,
      height: 48,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    }),
    callIcon: ({ isEmergency }) => ({
      color: isEmergency ? "#08A40F" : "white",
    }),
    collapse: ({ size }) => ({
      transition: "all 0.3s ease",
      height: size === "small" ? 0 : undefined,
      width: size === "small" ? 0 : undefined,
      marginLeft: size === "large" ? s(2) : undefined,
      overflow: "hidden",
      boxSizing: "border-box",
    }),

    group: ({ size }) => ({
      display: "flex",
      alignItems: "center",
      transition: "width 0.3s ease",
      width: size === "small" ? 40 : 200,
      boxSizing: "border-box",
    }),
  })
);

export const CallNotification = ({
  isEmergency,
  requestOrigin,
  fullName,
  sessionId,
}: Call) => {
  const { createPickCallRequest } = useCall();
  const [size, setSize] = useState<Size>("large");

  const expand = useCallback(() => {
    setSize("large");
  }, []);

  const collapse = useCallback(() => {
    setSize("small");
  }, []);

  const handlePickCall = useCallback(() => {
    createPickCallRequest(sessionId);
  }, [sessionId, createPickCallRequest]);

  const classes = useStyles({ isEmergency, size });

  return (
    <div className={classes.container}>
      <div className={classes.group}>
        <div>
          {size === "large" ? (
            <ArrowForwardIosOutlined
              fontSize="small"
              onClick={collapse}
              className={classes.arrow}
            />
          ) : (
            <ArrowBackIosOutlined
              fontSize="small"
              onClick={expand}
              className={classes.arrow}
            />
          )}
        </div>
        <div className={classes.collapse}>
          {isEmergency && (
            <Typography className={classes.source}>Emergency</Typography>
          )}
          <Typography variant="body1" className={classes.info}>
            Incoming Call
          </Typography>
          <Typography className={classes.source}>
            {requestOrigin} - {fullName}
          </Typography>
        </div>
      </div>
      <div>
        <div className={classes.callIconContainer}>
          <CallIcon className={classes.callIcon} onClick={handlePickCall} />
        </div>
      </div>
    </div>
  );
};
