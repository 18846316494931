import {
  GetServiceCases,
  ServiceCaseStatusEnum,
} from "@deep-consulting-solutions/be2-constants";
import { getClient } from "apis";

export const getAllUnansweredServiceCases = async (
  params: Partial<typeof GetServiceCases.QueryParams>
) => {
  const res = await getClient(true).get<typeof GetServiceCases.Res>(
    GetServiceCases.ROUTE,
    { params: { ...params, status: ServiceCaseStatusEnum.UNANSWERED } }
  );
  return res.data.data;
};
