import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  banner: {
    background: p.error.main,
    display: "flex",
    alignItems: "center",
    color: p.common.white,
    padding: s(1),
    borderRadius: s(1),
  },
  bannerMessage: {
    marginLeft: s(1),
    fontSize: 14,
  },
}));

export const Banner: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.banner}>
      <InfoOutlined />
      <Typography className={classes.bannerMessage}>{children}</Typography>
    </div>
  );
};
