import React, { useCallback, useMemo, useState, useEffect } from "react";
import { makeStyles, Tab, Tabs, Typography } from "@material-ui/core";
import { ServiceCaseResponse } from "@deep-consulting-solutions/be2-constants";

import { useCall } from "call/hooks";

import { TabsEnum } from "./types";
import { CallFormsTabPanel } from "./CallFormsTabPanel";
import {
  getCallFormTabs,
  getDefaultDisabledTabs,
  getInitialActiveTab,
  tabsEnumToNumber,
} from "./helpers";

const useStyles = makeStyles(({ spacing: s }) => ({
  wrapper: {
    flex: 1,
    width: "100%",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
  },
  tabs: {
    background: "#F7F7F7",
    boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.12)",
    borderRadius: 4,
    margin: s(0),
  },
  container: {
    flex: 1,
    overflow: "hidden auto",
  },
}));

interface CallFormsLayoutProps {
  setCases: React.Dispatch<React.SetStateAction<ServiceCaseResponse[]>>;
}

export const CallFormsLayout = ({ setCases }: CallFormsLayoutProps) => {
  const { viewedCall } = useCall();
  const [activeTab, setActiveTab] = useState(
    tabsEnumToNumber(getInitialActiveTab(viewedCall?.updatedServiceCase))
  );
  const [skippedTabs, setSkippedTabs] = useState<TabsEnum[]>([]);
  const [disabledTabs, setDisabledTabs] = useState<TabsEnum[]>(
    getDefaultDisabledTabs(viewedCall?.updatedServiceCase)
  );

  const tabs = useMemo(() => {
    return getCallFormTabs(skippedTabs);
  }, [skippedTabs]);

  const handleEnableTab = useCallback((tab: TabsEnum) => {
    setDisabledTabs((current) => {
      return current.filter((c) => c !== tab);
    });
  }, []);

  const handleDisableTab = useCallback((tab: TabsEnum) => {
    setDisabledTabs((current) => {
      if (current.includes(tab)) {
        return current;
      }
      return current.concat(tab);
    });
  }, []);

  const switchTab = useCallback(
    (tab: TabsEnum) => {
      if (!skippedTabs.includes(tab)) {
        const value = tabsEnumToNumber(tab);
        handleEnableTab(tab);
        setActiveTab(value);
      }
    },
    [skippedTabs, handleEnableTab]
  );

  const handleSwitchTab = useCallback(
    (event, value: number) => {
      let numberOfSkips = 0;
      skippedTabs
        .map((skippedTab) => tabsEnumToNumber(skippedTab))
        .forEach((skippedTab) => {
          if (skippedTab < value) {
            numberOfSkips += 1;
          }
        });
      const tab = tabs[value - numberOfSkips];
      switchTab(tab.label);
    },
    [switchTab, tabs, skippedTabs]
  );

  const handleSkipTab = useCallback((tab: TabsEnum) => {
    setSkippedTabs((current) => {
      if (!current.includes(tab)) {
        return current.concat(tab);
      }
      return current;
    });
  }, []);

  const handleIncludeTab = useCallback((tab: TabsEnum) => {
    setSkippedTabs((current) => {
      if (current.includes(tab)) {
        return current.filter((c) => c !== tab);
      }
      return current;
    });
  }, []);

  const getIsTabDisabled = useCallback(
    (tab: TabsEnum) => {
      return disabledTabs.includes(tab);
    },
    [disabledTabs]
  );

  const classes = useStyles();

  useEffect(() => {
    switchTab(getInitialActiveTab(viewedCall?.updatedServiceCase));
    setDisabledTabs(getDefaultDisabledTabs(viewedCall?.updatedServiceCase));
  }, [switchTab, viewedCall?.updatedServiceCase]);

  return (
    <>
      <div className={classes.tabs}>
        <Tabs
          value={activeTab}
          onChange={handleSwitchTab}
          aria-label="call form tabs"
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="off"
          disabled={!!viewedCall?.disableForms}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.label}
              label={<Typography variant="subtitle2">{tab.label}</Typography>}
              value={tabsEnumToNumber(tab.label)}
              textColor="primary"
              disabled={getIsTabDisabled(tab.label)}
              {...tab.props}
            />
          ))}
        </Tabs>
      </div>
      <div className={classes.container}>
        {tabs.map((tab) => {
          return (
            <CallFormsTabPanel
              key={tab.label}
              index={tab.index}
              value={activeTab}
              label={tab.label}
              setCases={setCases}
              switchTab={switchTab}
              skippedTabs={skippedTabs}
              handleSkipTab={handleSkipTab}
              handleEnableTab={handleEnableTab}
              handleIncludeTab={handleIncludeTab}
              handleDisableTab={handleDisableTab}
              {...tab.props}
            />
          );
        })}
      </div>
    </>
  );
};
