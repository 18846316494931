import React, { useRef, useState, useEffect } from "react";

import { useDeepCompareEffectForMaps } from "hooks";

interface ReactMapProps extends google.maps.MapOptions {
  id: string;
  mapClassName?: string;
  children?: React.ReactNode;
  style?: { [key: string]: string };
  onClick?: (event: google.maps.MapMouseEvent) => void;
  onIdle?: (
    map: google.maps.Map,
    places?: google.maps.places.PlacesService
  ) => void;
}

export const ReactMap = ({
  id,
  style,
  onIdle,
  onClick,
  children,
  mapClassName,
  ...options
}: ReactMapProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new google.maps.Map(ref.current, {}));
    }
  }, [map, ref]);

  useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  useDeepCompareEffectForMaps(() => {
    map?.setOptions(options);
  }, [map, options]);

  return (
    <>
      <div ref={ref} className={mapClassName} id={id} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};
