import { useCallback } from "react";

type StorageType = "session" | "local";

type UseStorageReturnValue = {
  getItem: <T = string>(key: string, type?: StorageType) => T | undefined;
  setItem: <T extends Record<string, unknown> | string = string>(
    key: string,
    value: T,
    type?: StorageType
  ) => boolean;
  removeItem: (key: string, type?: StorageType) => void;
  removeItems: (keys: string[], type?: StorageType) => void;
  clear: (type?: StorageType) => void;
};

export const useStorage = (): UseStorageReturnValue => {
  const storageType = (type?: StorageType): "localStorage" | "sessionStorage" =>
    `${type ?? "local"}Storage`;

  const isBrowser: boolean = ((): boolean => {
    try {
      return (
        typeof window !== "undefined" &&
        !!window[storageType()] &&
        !!window[storageType("session")]
      );
    } catch (error) {
      return false;
    }
  })();

  const getItem: UseStorageReturnValue["getItem"] = useCallback(
    (key, type) => {
      if (!isBrowser) return "";

      const data = window[storageType(type)][key];
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        return JSON.parse(data);
      } catch (error) {
        return data;
      }
    },
    [isBrowser]
  );

  const setItem: UseStorageReturnValue["setItem"] = useCallback(
    (key, value, type) => {
      let string = "";
      if (!isBrowser) return false;

      if (typeof value !== "string") {
        string = JSON.stringify(value);
      } else {
        string = value;
      }
      window[storageType(type)].setItem(key, string);
      return true;
    },
    [isBrowser]
  );

  const removeItem: UseStorageReturnValue["removeItem"] = useCallback(
    (key, type): void => {
      if (!isBrowser) return;
      window[storageType(type)].removeItem(key);
    },
    [isBrowser]
  );

  const removeItems: UseStorageReturnValue["removeItems"] = useCallback(
    (names, type) => {
      if (!isBrowser) return;
      names.forEach((name) => window[storageType(type)].removeItem(name));
    },
    [isBrowser]
  );
  const clear: UseStorageReturnValue["clear"] = useCallback(
    (type) => {
      if (!isBrowser) return;
      window[storageType(type)].clear();
    },
    [isBrowser]
  );
  return {
    getItem,
    setItem,
    removeItem,
    removeItems,
    clear,
  };
};
