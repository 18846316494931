import React, { useCallback, useMemo } from "react";
import {
  PaymentMethodEnum,
  SubscriptionPaymentBreakdown,
} from "@deep-consulting-solutions/be2-constants";
import {
  makeStyles,
  Button,
  Grid,
  InputAdornment,
  Box,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { StyledTypography } from "component/StyledTypography";
import { Formik, Field, Form, FormikConfig } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import {
  formatCVV,
  formatExpirationDate,
  formatCreditCardNumber,
  Values,
  getCardIssuer,
  getLastFourDigits,
  getMonthAndYear,
  creditCardValidationSchema,
  getCardBrandEnumFromCardBrand,
  getENText,
} from "helpers";
import { FormikNumberField } from "component/FormikNumberField";
import { notifications } from "services";
import { CreditCardLogo } from "component/CreditCardLogo/CreditCardLogo";
import { PaymentCardsIcons } from "./PaymentCardsIcons";
import { GeneratePaymentOrderIdBody } from "./types";

const useStyles = makeStyles(({ spacing: s }) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  content: {
    display: "flex",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    marginBottom: s(2),
  },
  button: {
    marginTop: s(2),
  },
  title: {
    marginBottom: s(2),
  },
  cardNumber: {
    fontSize: 16,
  },
}));

interface Props {
  subscriptionPaymentBreakdown: SubscriptionPaymentBreakdown | null;
  paymentFormButtonLabel?: string;
  onSubmitCardDetails: (values: GeneratePaymentOrderIdBody) => Promise<void>;
  setPnPFormValues: (values: {
    cvv?: string;
    expiryDate?: string;
    cardNumber?: string;
  }) => void;
}

const validationSchema = Yup.object(creditCardValidationSchema());

export const PaymentForm = ({
  subscriptionPaymentBreakdown,
  paymentFormButtonLabel,
  onSubmitCardDetails,
  setPnPFormValues,
}: Props) => {
  const classes = useStyles();
  const [recurringValue, setRecurringValue] = React.useState(true);

  const isDirectPayment = useMemo(
    () =>
      subscriptionPaymentBreakdown?.paymentMethod ===
      PaymentMethodEnum.DIRECT_PAYMENT,
    [subscriptionPaymentBreakdown]
  );

  const handleRecurringValue = (value: boolean) => {
    setRecurringValue(value);
  };

  const onSubmit: FormikConfig<Values>["onSubmit"] = useCallback(
    async (values) => {
      setPnPFormValues({
        cvv: values.cvv,
        cardNumber: values.cardNumber,
        expiryDate: values.expiryDate,
      });
      const brand = getCardBrandEnumFromCardBrand(
        getCardIssuer(values.cardNumber).toUpperCase()
      );
      if (!brand) {
        notifications.notifyError(getENText("payment.card.brand.invalid"));
        return;
      }
      const lastFourDigits = getLastFourDigits(
        values.cardNumber as unknown as number
      );
      await onSubmitCardDetails({
        useCardForRecurringPayments: isDirectPayment ? recurringValue : false,
        creditCard: {
          brand,
          holderName: values.holderName,
          lastFourDigits,
          ...getMonthAndYear(values.expiryDate),
        },
      });
    },
    [recurringValue, isDirectPayment, onSubmitCardDetails, setPnPFormValues]
  );

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialValues={{
        cvv: "",
        holderName: "",
        expiryDate: "",
        cardNumber: "",
      }}
    >
      {({ isValid, isSubmitting, values }) => {
        return (
          <Form>
            <StyledTypography
              color="primary.dark"
              variant="body1"
              className={classes.title}
            >
              Card Information
            </StyledTypography>
            <Field
              name="cardNumber"
              component={FormikNumberField}
              placeholder="Card Number"
              required
              className={`${classes.input} ${classes.cardNumber}`}
              format={formatCreditCardNumber}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {values.cardNumber ? (
                      <CreditCardLogo cardNumber={values.cardNumber} />
                    ) : (
                      <PaymentCardsIcons />
                    )}
                  </InputAdornment>
                ),
              }}
            />

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field
                  name="expiryDate"
                  component={FormikNumberField}
                  placeholder="MM/YY"
                  required
                  className={classes.input}
                  format={formatExpirationDate}
                  valueType="formatted"
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="cvv"
                  component={FormikNumberField}
                  placeholder="CVV"
                  required
                  className={classes.input}
                  format={formatCVV}
                />
              </Grid>
            </Grid>

            <Field
              name="holderName"
              component={TextField}
              placeholder="Name on Card"
              required
              className={classes.input}
            />

            {isDirectPayment && (
              <Box mt={3}>
                <Grid
                  container
                  wrap="nowrap"
                  justify="space-between"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Grid item>
                    <Box>
                      <StyledTypography
                        color="primary.main"
                        variant="body2"
                        align="left"
                      >
                        Would you like to use this card for future recurring
                        payment?
                      </StyledTypography>
                    </Box>
                    <Box mt={1}>
                      <StyledTypography
                        color="grey.900"
                        variant="caption"
                        align="left"
                      >
                        This will allow BahamasEvac to charge your card for this
                        payment and future payment in accordance with their
                        terms.
                      </StyledTypography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box>
                      <FormControlLabel
                        control={
                          <Switch
                            defaultChecked
                            color="primary"
                            onChange={(e) =>
                              handleRecurringValue(e.target.checked)
                            }
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Yes"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}

            <Box mt={3}>
              <Button
                disabled={!isValid || isSubmitting}
                color="primary"
                fullWidth
                type="submit"
                variant="contained"
              >
                {paymentFormButtonLabel || "SUBSCRIBE"}
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
