import React, { FC, useEffect, useState } from "react";
import { CardContent } from "pages/FulfilmentDashboard/components";
import {
  Box,
  Collapse,
  Divider,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ReactComponent as LocalHospital } from "images/hospital.svg";
import { InfoOutlined } from "@material-ui/icons";
import { format } from "date-fns";
import DoneSharpIcon from "@material-ui/icons/DoneSharp";
import { getLocationFromGeoCodeResult } from "helpers";
import { LocationSummaryToolTip } from "call/components/CallForms/LocationForm/LocationSummary";

type CheckBtnType = {
  checked?: boolean;
  disabled?: boolean;
  ready?: boolean;
  onClick?: (res: boolean) => void;
};

const useStyles = makeStyles<any, CheckBtnType>(() => ({
  checkBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    zIndex: 1,
    width: 32,
    height: 32,
    borderRadius: 16,
    border: ({ disabled }) => `2px solid ${disabled ? "#E0E0E0" : "#08A40F"}`,
    backgroundColor: ({ disabled, checked }) =>
      // eslint-disable-next-line no-nested-ternary
      disabled ? "#E0E0E0" : checked ? "#08A40F" : "white",
  },
}));

const CheckButton = ({ checked, disabled, ready, onClick }: CheckBtnType) => {
  const styles = useStyles({ disabled, ready, checked });
  return (
    <button
      className={styles.checkBtn}
      disabled={disabled || checked}
      type="button"
      onClick={() => {
        // setCheck(!check);
        if (onClick) onClick(!checked);
      }}
    >
      {!disabled
        ? checked && <DoneSharpIcon style={{ color: "white" }} />
        : null}
    </button>
  );
};

const Marker: FC<
  { left?: number; line?: boolean; expanded?: boolean } & CheckBtnType
> = ({ children, line = true, expanded, left, ...rest }) => {
  return (
    <Box position="relative">
      {children}
      <Box
        position="absolute"
        left={left || -84}
        top={0}
        bottom={0}
        display="flex"
        alignItems="center"
      >
        <CheckButton {...rest} />
        {line && (
          <Box
            bgcolor={!rest.disabled && rest.checked ? "#08A40F" : "#E0E0E0"}
            height={expanded ? "120px" : "75px"}
            width="2px"
            position="absolute"
            top={20}
            right={15}
          />
        )}
      </Box>
    </Box>
  );
};

export const ContentCard = ({
  label,
  updatedOn,
  location,
  geoLocation,
  lastElem,
  ...checkBoxProps
}: {
  label: string;
  updatedOn?: string;
  location?: string;
  geoLocation?: string;
  lastElem?: boolean;
} & CheckBtnType) => {
  const [show] = useState(checkBoxProps.checked);
  const [tooltip, setTooltip] = useState({
    lat: 0,
    lng: 0,
    dms: "",
    address: "",
  });

  useEffect(() => {
    if (geoLocation) {
      const geocoder = new google.maps.Geocoder();
      const [lng, lat] = geoLocation.split(",");
      geocoder.geocode(
        { location: { lat: +lat, lng: +lng } },
        (results, status) => {
          if (status === "OK" && results?.[0]) {
            const res = results?.[0];
            setTooltip(getLocationFromGeoCodeResult(res));
          }
        }
      );
    }
  }, [geoLocation]);

  return (
    <CardContent
      padding="12px"
      border="1px solid #828282"
      mb={lastElem ? 0 : 2}
    >
      <CardContent
        position="relative"
        padding="12px"
        border={
          !checkBoxProps.disabled && !checkBoxProps.checked
            ? "1px solid #C4E9C6"
            : "initial"
        }
        bgcolor={
          // eslint-disable-next-line no-nested-ternary
          show ? "unset" : checkBoxProps.disabled ? "#F7F7F7" : "#FAFFFA"
        }
      >
        <Marker line={!lastElem} expanded={show} {...checkBoxProps}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography style={{ fontWeight: 500, color: "#4F4F4F" }}>
              {label}
            </Typography>
            <Box
              display="grid"
              gridTemplateColumns="auto 1fr auto"
              alignItems="center"
              gridGap={6}
            >
              {location && (
                <>
                  <LocalHospital fontSize="small" />
                  <Typography variant="caption" color="primary">
                    {location}
                  </Typography>
                  <Tooltip
                    title={<LocationSummaryToolTip location={tooltip} />}
                    placement="top-end"
                    arrow
                  >
                    <InfoOutlined color="primary" fontSize="small" />
                  </Tooltip>
                </>
              )}
            </Box>
          </Box>
        </Marker>
        <Collapse in={!lastElem && show}>
          <Divider style={{ margin: "12px 0px" }} />
          <Box display="flex" justifyContent="flex-end">
            <Typography variant="caption" color="primary">
              <span style={{ color: "#828282" }}>Updated On:</span>{" "}
              {!!updatedOn && format(new Date(updatedOn), "d LLL yyyy p")}
            </Typography>
          </Box>
        </Collapse>
      </CardContent>
    </CardContent>
  );
};
