import { FormikProps, getIn } from "formik";
import { LocationSearching } from "@material-ui/icons";
import { AutocompleteRenderInputParams } from "formik-material-ui-lab";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import { LocationTypeEnum } from "@deep-consulting-solutions/be2-constants";
import { InputAdornment, SvgIcon, TextField, Tooltip } from "@material-ui/core";
import Autocomplete, {
  AutocompleteChangeReason,
} from "@material-ui/lab/Autocomplete";

import { useCall } from "call/hooks";
import { useMap } from "call/context";
import {
  ddToDMS,
  getLocationFromPlacesResult,
  getLocationFromGeoCodeResult,
} from "helpers";
import { ReactComponent as Location4Icon } from "images/location4.svg";

interface LocationSearchProps<FormValues extends Record<string, any>> {
  name: string;
  label: string;
  className?: string;
  disabled?: boolean;
  markerTitle?: string;
  formikProps: FormikProps<FormValues>;
}

export const LocationSearch = <FormValues extends Record<string, any>>({
  name,
  label,
  className,
  markerTitle,
  disabled = false,
  formikProps: {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
  },
}: LocationSearchProps<FormValues>) => {
  const { viewedCall } = useCall();
  const [loadingAddress, setLoadingAddress] = useState(false);
  const nameValue = useMemo(() => `${name}.address`, [name]);

  const fieldValue = getIn(values, nameValue);
  const fieldError: string | undefined = getIn(errors, nameValue);
  const showFieldError: boolean = getIn(touched, nameValue) && !!fieldError;

  const title = markerTitle || name;

  const {
    map,
    listener,
    createMarker,
    addListenerToMap,
    removeListenerFromMap,
    createDestinationMarker,
    createInitialLocationMarker,
  } = useMap();

  const autoCompleteTypes = useMemo(() => {
    switch (values[`${name}Type`]) {
      case LocationTypeEnum.AIRPORT:
        return ["airport"];

      case LocationTypeEnum.MEDICAL_FACILITY:
        return ["hospital"];

      default:
        return undefined;
    }
  }, [name, values]);

  const {
    init,
    setValue,
    suggestions: { data, loading: placesLoading },
  } = usePlacesAutocomplete({
    cache: false,
    callbackName: "dummyPlacesLoaded2", // different on purpose, so that it wont be called automatically
    requestOptions: {
      types: autoCompleteTypes,
    },
  });

  const handleSelect = useCallback(
    async (address: string) => {
      setLoadingAddress(true);
      setValue(address, false);
      try {
        const results = await getGeocode({ address });
        setLoadingAddress(false);
        return results[0];
      } catch (err) {
        setLoadingAddress(false);
      }
      return undefined;
    },
    [setValue]
  );

  const onAutocompleteChange = useCallback(
    (
      event: React.ChangeEvent<any>,
      value: string | google.maps.places.AutocompletePrediction | null,
      reason: AutocompleteChangeReason
    ) => {
      (async () => {
        if (reason === "select-option") {
          const result = await handleSelect(
            (value as google.maps.places.AutocompletePrediction).description
          );

          if (result) {
            const address = result.formatted_address;
            const lat = result.geometry.location.lat();
            const lng = result.geometry.location.lng();
            const plusCode = result.plus_code?.compound_code;

            setValue(address, false);
            setFieldValue(name, {
              lat,
              lng,
              address,
              plusCode,
              dms: ddToDMS(lat, lng),
            });
          }
        } else if (reason === "clear") {
          setFieldValue(name, "");
        } else if (reason === "create-option") {
          //
        } else if (reason === "remove-option") {
          //
        } else if (reason === "blur") {
          //
        }
      })();
    },
    [name, setValue, handleSelect, setFieldValue]
  );

  const getOptionSelected = useCallback(
    (
      option: google.maps.places.AutocompletePrediction,
      value: google.maps.places.AutocompletePrediction
    ) => {
      const [stNumber, stName] = (value as unknown as string).split(" ");
      const hasNumber = option.description.includes(stNumber);
      const hasName = option.description.includes(stName);

      return hasNumber && hasName;
    },
    []
  );

  const onInputChange = useCallback(
    (_event: React.ChangeEvent<any>, value: string) => {
      setValue(value);
      setFieldValue(name, {
        lat: 0,
        lng: 0,
        dms: "",
        plusCode: "",
        address: value,
      });
    },
    [name, setFieldValue, setValue]
  );

  const onInputBlur = useCallback(() => {
    setFieldTouched(nameValue, true);
  }, [nameValue, setFieldTouched]);

  useEffect(() => {
    init();
  }, [init]);

  const isInputDisabled = useMemo(() => {
    return (
      disabled || loadingAddress || isSubmitting || viewedCall?.disableForms
    );
  }, [disabled, loadingAddress, isSubmitting, viewedCall?.disableForms]);

  const resetMedicalFacility = useCallback(() => {
    if (
      name === "initialLocation" &&
      values.initialLocationType !== LocationTypeEnum.MEDICAL_FACILITY
    ) {
      setFieldValue("initialMedicalFacility", null);
    } else if (
      name === "destination" &&
      values.destinationType !== LocationTypeEnum.MEDICAL_FACILITY
    ) {
      setFieldValue("destinationMedicalFacility", null);
    }
  }, [name, setFieldValue, values.destinationType, values.initialLocationType]);

  const updateLocation = useCallback(
    async ({ latLng }: { latLng: google.maps.LatLng }) => {
      const [object] = await getGeocode({ location: latLng });
      const location = getLocationFromGeoCodeResult(object);
      setFieldValue(name, location);
      resetMedicalFacility();
    },
    [name, setFieldValue, resetMedicalFacility]
  );

  const placesService = useMemo(() => {
    if (map) return new google.maps.places.PlacesService(map);
  }, [map]);

  const updateLocationWithPlace = useCallback(
    (e: google.maps.IconMouseEvent) => {
      placesService?.getDetails(
        { placeId: e.placeId! },
        (
          place: google.maps.places.PlaceResult | null,
          status: google.maps.places.PlacesServiceStatus
        ) => {
          if (status === "OK" && place?.geometry?.location) {
            const location = getLocationFromPlacesResult(place);
            setFieldValue(name, location);
            resetMedicalFacility();
          } else {
            updateLocation({ latLng: e.latLng! });
          }
        }
      );
    },
    [name, setFieldValue, placesService, updateLocation, resetMedicalFacility]
  );

  const enableLocateOnMap = useCallback(() => {
    setTimeout(() => {
      // delay to allow formik propagate its events

      removeListenerFromMap("click");

      addListenerToMap(
        `click_${title}`,
        (e: google.maps.MapMouseEvent | google.maps.IconMouseEvent) => {
          let marker: google.maps.Marker;

          if (title === "initialLocation") {
            marker = createInitialLocationMarker({
              draggable: true,
              position: e.latLng,
            });
          } else if (title === "destination") {
            marker = createDestinationMarker({
              draggable: true,
              position: e.latLng,
            });
          } else {
            marker = createMarker({
              title,
              draggable: true,
              position: e.latLng,
            });
          }

          if ("placeId" in e) {
            e.stop();
            updateLocationWithPlace(e);
          } else {
            updateLocation({ latLng: e.latLng! });
          }

          marker.addListener(
            "dragend",
            ({ latLng }: google.maps.MapMouseEvent) =>
              updateLocation({ latLng: latLng! })
          );
        }
      );
    }, 500);
  }, [
    title,
    createMarker,
    updateLocation,
    addListenerToMap,
    removeListenerFromMap,
    updateLocationWithPlace,
    createDestinationMarker,
    createInitialLocationMarker,
  ]);

  return (
    <Autocomplete<google.maps.places.AutocompletePrediction, false, true, true>
      freeSolo
      fullWidth
      options={data}
      disableClearable
      value={fieldValue}
      className={className}
      inputValue={fieldValue}
      loading={placesLoading}
      disabled={isInputDisabled}
      onInputChange={onInputChange}
      onChange={onAutocompleteChange}
      noOptionsText="No Results Found"
      getOptionSelected={getOptionSelected}
      getOptionLabel={(
        option: google.maps.places.AutocompletePrediction | string
      ) => (typeof option === "string" ? option : option.description)}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          label={label}
          name={nameValue}
          autoComplete="off"
          onBlur={onInputBlur}
          error={showFieldError}
          helperText={showFieldError ? fieldError : ""}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={enableLocateOnMap}
                disablePointerEvents={viewedCall?.disableForms}
              >
                <div
                  style={{
                    width: "1px",
                    height: "40px",
                    marginRight: "16px",
                    backgroundColor: "rgba(86, 92, 99, 0.12)",
                  }}
                />

                <Tooltip
                  arrow
                  placement="top-end"
                  title="Locate on Map"
                  style={{ cursor: "pointer" }}
                >
                  {listener === title ? (
                    <LocationSearching width={24} height={24} color="primary" />
                  ) : (
                    <SvgIcon color="secondary">
                      <Location4Icon />
                    </SvgIcon>
                  )}
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};
