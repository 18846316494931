import React from "react";
import { ServiceCaseStatusEnum } from "@deep-consulting-solutions/be2-constants";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import clsx from "clsx";

interface StyleProps {
  tag: string;
  text: string;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  tag: {
    padding: "4px 14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: ({ tag }) => tag,
    color: ({ text }) => text,
    borderRadius: 20,
    width: "fit-content",
    fontSize: 10,
  },
}));

interface StatusSnippetTagProps {
  status: ServiceCaseStatusEnum;
  colors: {
    bg: string;
    tag: string;
    text: string;
  };
}

export const StatusSnippetTag = ({
  colors: { tag, text },
  status,
}: StatusSnippetTagProps) => {
  const styles = useStyles({ tag, text });

  return status === ServiceCaseStatusEnum.PENDING_PLANNING ? (
    <>
      <Typography variant="caption" style={{ fontWeight: 700 }}>
        {status}
      </Typography>{" "}
      <div className={styles.tag}>
        <Typography style={{ fontSize: "inherit", color: "inherit" }}>
          Quote Pending Approval
        </Typography>
      </div>
    </>
  ) : (
    <div
      className={clsx(
        styles.tag,
        !status.toLowerCase().includes("pending") && { paddingLeft: 0 }
      )}
    >
      <Typography
        style={{
          fontSize: "inherit",
          color: "inherit",
          fontWeight: !status.toLowerCase().includes("pending") ? 400 : 700,
        }}
      >
        {status}
      </Typography>
    </div>
  );
};
