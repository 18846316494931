import React from "react";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ spacing: s }) => ({
  root: {
    gap: s(2),
    display: "flex",
  },
}));

interface MinimalMedicalProfessionalsProps {
  disableForms?: boolean;
}

const MinimalMedicalProfessionals = ({
  disableForms,
}: MinimalMedicalProfessionalsProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Field
        required
        multiline
        minRows={2}
        maxRows={2}
        component={TextField}
        disabled={disableForms}
        name="noAndSpecialityOfMedicalProfessionals"
        label="Number and Specialty of Medical Professionals Required"
      />

      <Field
        required
        multiline
        minRows={2}
        maxRows={2}
        component={TextField}
        disabled={disableForms}
        name="recommendedMedicalProfessionals"
        label="Recommended Medical Professionals"
      />
    </Box>
  );
};

export default MinimalMedicalProfessionals;
