import {
  UserRoleEnum,
  ServiceCaseStatusEnum,
} from "@deep-consulting-solutions/be2-constants";

export const getColors = ({
  role,
  hasChange,
  caseStatus,
  isMedicalDirector,
}: {
  role?: string;
  hasChange: boolean;
  isMedicalDirector: boolean;
  caseStatus: ServiceCaseStatusEnum;
}) => {
  if (!hasChange) {
    if (
      isMedicalDirector &&
      caseStatus === ServiceCaseStatusEnum.PENDING_MEDICAL_ASSESSMENT
    ) {
      return { tag: "#2F80ED", bg: "#ECF8FF", text: "#ffffff" };
    }

    if (
      role === UserRoleEnum.Manager &&
      caseStatus === ServiceCaseStatusEnum.PENDING_BE_ACCEPTANCE
    ) {
      return { tag: "#FCC221", bg: "#FFF3D3", text: "#333333" };
    }
  }

  return { tag: "", bg: "", text: "#4F4F4F" };
};
