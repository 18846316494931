import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { MapErrorComponent, MapLoadingComponent, useMap } from "call";

interface StyleProps {
  listener: string;
}

const useStyles = makeStyles<Theme, StyleProps>(({ palette: p }) => ({
  wrapper: {
    height: "100%",
    width: "100%",
    background: "white",
    borderRadius: 8,
    position: "relative",
    border: ({ listener }) =>
      listener ? `3px solid ${p.primary.main}` : "none",
  },
}));

export const RenderMap = () => {
  const [loading] = useState(false);
  const [error] = useState<string | undefined>();

  const ref = useRef<HTMLDivElement | null>(null);

  const { createMap, listener } = useMap();

  const classes = useStyles({ listener });

  useEffect(() => {
    if (ref.current) {
      createMap(ref.current, {
        zoom: 9,
        mapTypeControl: false,
        streetViewControl: false,
        // center is set to the Bahamas.
        center: { lat: 24.6886111, lng: -77.9758333 },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.wrapper} ref={ref} id="call-form-map">
      {error ? <MapErrorComponent message={error} /> : null}
      {loading ? <MapLoadingComponent /> : null}
    </div>
  );
};
