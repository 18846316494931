import {
  SortOrderEnum,
  GetFetchServiceCases,
  ServiceCaseStatusGroupEnum,
} from "@deep-consulting-solutions/be2-constants";
import { getClient } from "apis";

export const getInProgressStatus = (
  params: Partial<typeof GetFetchServiceCases.Params> = {}
) => {
  return getClient(true).get<typeof GetFetchServiceCases["Res"]>(
    GetFetchServiceCases.ROUTE,
    {
      params: {
        perPage: 20,
        statusGroup: ServiceCaseStatusGroupEnum.IN_PROGRESS,
        searchText: "",
        sortBy: "createdAt",
        order: SortOrderEnum.DESC,
        ...params,
      },
    }
  );
};

export const getNewStatus = async (
  params: Partial<typeof GetFetchServiceCases.Params> = {}
) => {
  return getClient(true).get<typeof GetFetchServiceCases["Res"]>(
    GetFetchServiceCases.ROUTE,
    {
      params: {
        statusGroup: ServiceCaseStatusGroupEnum.NEW,
        perPage: 20,
        searchText: "",
        sortBy: "createdAt",
        order: SortOrderEnum.DESC,
        ...params,
      },
    }
  );
};

export const getScheduledStatus = async (
  params: Partial<typeof GetFetchServiceCases.Params> = {}
) => {
  return getClient(true).get<typeof GetFetchServiceCases["Res"]>(
    GetFetchServiceCases.ROUTE,
    {
      params: {
        statusGroup: ServiceCaseStatusGroupEnum.SCHEDULED,
        perPage: 20,
        searchText: "",
        sortBy: "createdAt",
        order: SortOrderEnum.DESC,
        ...params,
      },
    }
  );
};

export type FilterStateKeys =
  | "allStatuses"
  | "newStatuses"
  | "scheduledStatuses";
export type FilterStateType = {
  count: number;
  data: typeof GetFetchServiceCases["Res"]["data"];
};

export type GetNewAndScheduledStatusResponseType = Record<
  FilterStateKeys,
  FilterStateType
>;

export const getNewAndScheduledStatus = async (
  params: Partial<typeof GetFetchServiceCases.Params> = {}
): Promise<GetNewAndScheduledStatusResponseType> => {
  const defParams = {
    perPage: 20,
    searchText: "",
    sortBy: "createdAt",
    order: SortOrderEnum.DESC,
    ...params,
  } as typeof GetFetchServiceCases.Params;

  const nRes = await getNewStatus(defParams);
  const sRes = await getScheduledStatus(defParams);

  return {
    allStatuses: {
      count: nRes.data.data.total + sRes.data.data.total,
      data: {
        ...(defParams as any),
        perPage: defParams.perPage as unknown as number,
        serviceCases: nRes.data.data.serviceCases.concat(
          sRes.data.data.serviceCases
        ),
        total: nRes.data.data.total + sRes.data.data.total,
        page: 1,
      },
    },
    newStatuses: {
      count: nRes.data.data.total,
      data: { ...nRes.data.data, page: 1 },
    },
    scheduledStatuses: {
      count: sRes.data.data.total,
      data: { ...sRes.data.data, page: 1 },
    },
  };
};
