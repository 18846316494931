import React, { useCallback } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

interface TableActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

const useStyles = makeStyles(({ spacing: s }) => ({
  actionsComponent: {
    flexShrink: 0,
    marginLeft: s(2.5),
  },
}));

const TableActions = ({
  page,
  count,
  rowsPerPage,
  onPageChange,
}: TableActionsProps) => {
  const classes = useStyles();

  const handleBackButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page - 1);
    },
    [page, onPageChange]
  );

  const handleNextButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page + 1);
    },
    [page, onPageChange]
  );

  return (
    <div className={classes.actionsComponent}>
      <IconButton
        disabled={page === 0}
        aria-label="previous page"
        onClick={handleBackButtonClick}
      >
        <KeyboardArrowLeft />
      </IconButton>

      <IconButton
        aria-label="next page"
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <KeyboardArrowRight />
      </IconButton>
    </div>
  );
};

export default TableActions;
