import React, { FC, useCallback, useEffect, useState } from "react";
import {
  MinimalContact,
  ServiceCaseCallBackTypeEnum,
} from "@deep-consulting-solutions/be2-constants";
import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  Close,
  InfoOutlined,
  KeyboardArrowDown,
  KeyboardArrowLeft,
} from "@material-ui/icons";

import { Call } from "call/types";
import { useCall } from "call/hooks";
import Loader from "component/Loader";
import { notifications } from "services";
import { formatNameToDisplay, getENText } from "helpers";
import { getContactSubMembers } from "redux/call/requests";
import { logFailedCallBackAttempts } from "component/Dialogs/request";

import { getRole, getOppositeRole } from "./helpers";

const useStyles = makeStyles(({ spacing: s, palette: p }) => ({
  container: {
    position: "absolute",
    top: 0,
    right: 0,
    padding: s(2),
  },
  wrapper: {
    maxWidth: 400,
    background: " #FFFFFF",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.12)",
    borderRadius: 8,
    position: "relative",
    padding: s(2),
  },
  close: {
    position: "absolute",
    top: 0,
    right: 0,
    width: s(3),
    height: s(3),
    borderRadius: "50%",
    backgroundColor: "white",
  },
  contacts: {
    background: "#F7F7F7",
    borderRadius: 8,
    padding: s(2),
  },
  viewContacts: {
    color: p.grey[400],
    fontSize: 10,
    fontWeight: 400,
    background: "none",
    outline: 0,
    border: 0,
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    cursor: "pointer",
  },
  primary: {
    borderTop: "1px solid rgba(86, 92, 99, 0.1)",
    marginTop: s(1),
    padding: s(1),
  },
  primaryTitle: {
    color: p.grey[400],
  },
  primaryNumber: {
    color: "#4F4F4F",
  },
  warning: {
    background: "#D32F2F",
    borderRadius: 4,
    padding: s(2),
    margin: s(4, 0),
    width: "100%",
  },
  warningText: {
    color: "white",
  },
  logButton: {
    width: "100%",
    height: s(5.5),
    position: "relative",
  },
}));

interface MemberContactProps {
  contact: MinimalContact & {
    role?: string;
  };
}

const MemberContact = ({ contact }: MemberContactProps) => {
  const classes = useStyles();

  if (!(contact.phone && contact.role)) return null;

  return (
    <div className={classes.primary}>
      <Typography className={classes.primaryTitle} variant="caption">
        {formatNameToDisplay(contact.firstName, contact.lastName)} (
        {contact.role})
      </Typography>

      <Typography className={classes.primaryNumber} variant="body2">
        {contact.phone}
      </Typography>
    </div>
  );
};

export const Item = ({ sessionId, updatedServiceCase }: Call) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [showAllContacts, setShowAllContacts] = useState(false);

  const { removeCutCall } = useCall();

  const handleRemove = useCallback(() => {
    removeCutCall(sessionId);
  }, [removeCutCall, sessionId]);

  const handleViewContacts = useCallback(() => {
    setShowAllContacts((c) => !c);
  }, []);

  const [memberContacts, setMemberContacts] = useState<MinimalContact[]>([]);

  useEffect(() => {
    (async () => {
      if (updatedServiceCase?.requester?.isMemberOrCustomer) {
        try {
          const { contactID } = updatedServiceCase.requester;
          const { nextOfKin, subscriptionMembers } = await getContactSubMembers(
            contactID
          );

          const group: (MinimalContact & { role?: string })[] = [];

          if (nextOfKin) {
            group.push({
              ...nextOfKin,
              role: getRole(nextOfKin),
            });
          }

          subscriptionMembers.forEach((sub) => {
            if (sub.mainBeneficiary) {
              group.push({ ...sub.mainBeneficiary, role: "Main Beneficiary" });
            }

            if (sub.mainBeneficiaryEmergencyContact) {
              group.push({
                ...sub.mainBeneficiaryEmergencyContact,
                role: "Emergency Contact",
              });
            }

            sub.otherMembers.forEach((member) => {
              return group.push({
                ...member,
                role: sub.isMainBeneficiary
                  ? getOppositeRole(member)
                  : getRole(member),
              });
            });
          });

          setMemberContacts(group);
        } catch {
          //
        }
      }
    })();
  }, [updatedServiceCase?.requester]);

  const handleLogCallbackAttempt = useCallback(() => {
    (async () => {
      try {
        setLoading(true);

        await logFailedCallBackAttempts(updatedServiceCase?.id || "", {
          callBackTime: new Date().toISOString(),
          callBackPhoneNumber: updatedServiceCase?.callbackNumber || "",
          callBackType: ServiceCaseCallBackTypeEnum.UNANSWERED_REQUEST,
        });

        notifications.notifySuccess(getENText("failedCallbackLog.successful"));
      } catch {
        //
      } finally {
        setLoading(false);
      }
    })();
  }, [updatedServiceCase?.id, updatedServiceCase?.callbackNumber]);

  return (
    <div className={classes.wrapper}>
      <IconButton onClick={handleRemove} className={classes.close}>
        <Close />
      </IconButton>

      <div className={classes.contacts}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography color="primary" variant="subtitle2">
              Contacts Numbers:
            </Typography>
          </Grid>

          <Grid item>
            <button
              type="button"
              className={classes.viewContacts}
              onClick={handleViewContacts}
            >
              <span>View all Contacts </span>
              {!showAllContacts ? <KeyboardArrowLeft /> : <KeyboardArrowDown />}
            </button>
          </Grid>
        </Grid>

        <div className={classes.primary}>
          <Typography className={classes.primaryTitle} variant="caption">
            Primary:
          </Typography>
          <Typography className={classes.primaryNumber} variant="body2">
            {updatedServiceCase?.contactNumber || "--"}
          </Typography>
        </div>

        {showAllContacts ? (
          <>
            {updatedServiceCase?.callbackNumber && (
              <div className={classes.primary}>
                <Typography className={classes.primaryTitle} variant="caption">
                  Callback Number
                </Typography>

                <Typography className={classes.primaryNumber} variant="body2">
                  {updatedServiceCase.callbackNumber}
                </Typography>
              </div>
            )}

            {memberContacts.map((memberContact) => (
              <MemberContact key={memberContact.id} contact={memberContact} />
            ))}
          </>
        ) : null}
      </div>

      <Grid container className={classes.warning} spacing={1} wrap="nowrap">
        <Grid item>
          <InfoOutlined className={classes.warningText} />
        </Grid>
        <Grid item>
          <Typography className={classes.warningText} variant="subtitle2">
            The connection with the Requester has been cut. Call back the
            Requester using the available contact numbers.
          </Typography>
        </Grid>
      </Grid>

      <Button
        color="primary"
        disabled={loading}
        className={classes.logButton}
        onClick={handleLogCallbackAttempt}
      >
        {loading ? (
          <Loader open absolute />
        ) : (
          <Typography variant="body2" style={{ fontWeight: 700 }}>
            Log Failed Callback Attempt
          </Typography>
        )}
      </Button>
    </div>
  );
};

export const CallCut: FC = () => {
  const classes = useStyles();
  const { cutCalls } = useCall();

  if (!cutCalls.length) {
    return null;
  }

  return (
    <div className={classes.container}>
      {cutCalls.map((cutCall) => (
        <Item key={cutCall.sessionId} {...cutCall} />
      ))}
    </div>
  );
};
