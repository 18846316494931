import React from "react";
import {
  DialogTitle,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: p.grey[100],
    paddingLeft: s(1),
    paddingRight: s(1),
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    color: p.primary.main,
  },
  closeBtn: {
    transition: "0.2s",
    opacity: 0.5,
    "&:hover": {
      opacity: 1,
    },
  },
}));

interface Props {
  title: string;
  onClose: () => any;
}

export const DialogTitleWithCloseButton: React.FC<Props> = ({
  title,
  onClose,
}) => {
  const classes = useStyles();
  return (
    <DialogTitle disableTypography className={classes.titleWrapper}>
      <Typography className={classes.title}>{title}</Typography>
      <IconButton size="small" onClick={onClose} className={classes.closeBtn}>
        <Close />
      </IconButton>
    </DialogTitle>
  );
};
