import { Box, Typography } from "@material-ui/core";
import React from "react";
import { FulfilmentDashboardLayout } from "layout";
import { useAppSelector } from "redux/store";
import { DisplayMode } from "redux/dispatcher";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { environmentVariables } from "configs/environmentVariables";
import { MapErrorComponent, MapLoadingComponent, MapProvider } from "call";
import MedicalDirector from "./Views/MedicalDirector";
import DefaultView from "./Views/Default";
import RequestType from "./Views/RequestType";
import List from "./Views/List";
import Priority from "./Views/Priority";

const RenderScreen = ({ mode }: { mode: DisplayMode }) => {
  if (mode === DisplayMode.List) {
    return <List />;
  }
  if (mode === DisplayMode.Priority) {
    return <Priority />;
  }
  if (mode === DisplayMode.RequestType) {
    return <RequestType />;
  }
  if (mode === DisplayMode.MedicalDirector) {
    return <MedicalDirector />;
  }
  if (mode === DisplayMode.StatusGroup) {
    return <DefaultView />;
  }
  return (
    <Box
      display="flex"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Typography style={{ fontSize: 64 }}>Dashboard</Typography>
    </Box>
  );
};

const render = (status: Status) => {
  if (status === Status.FAILURE) {
    return <MapErrorComponent />;
  }
  return <MapLoadingComponent />;
};

export const FulfilmentDashboard = () => {
  const { displayMode } = useAppSelector((state) => state.dispatcher);

  return (
    <FulfilmentDashboardLayout.Container>
      <Box height="calc(100vh - 200px)">
        <Wrapper
          render={render}
          libraries={["places"]}
          apiKey={environmentVariables.REACT_APP_GOOGLE_MAP_API_KEY}
        >
          <MapProvider>
            <RenderScreen mode={displayMode as DisplayMode} />
          </MapProvider>
        </Wrapper>
      </Box>
    </FulfilmentDashboardLayout.Container>
  );
};
