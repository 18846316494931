import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import clsx from "clsx";
import Loader from "component/Loader";
import { CloseOutlined } from "@material-ui/icons";
import { useDialogStyles } from "component/Dialogs/styles";
import {
  GetDispatchers,
  PostAssignServiceCase,
  ServiceCaseResponse,
} from "@deep-consulting-solutions/be2-constants";
import { PatientStatusSnippet } from "pages/FulfilmentDashboard/components/PatientStatusSnippet";
import { getClient } from "apis";
import { formatNameToDisplay } from "helpers";
import { notifications } from "services";
import { SimpleSelect } from "../atoms/Select";
import { HideCurrentlyAssignedDispatcher } from "./utils";

const useStyle = makeStyles(() => ({
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      width: "100%",
      maxWidth: 432,
      borderRadius: "4px 4px 16px 16px",
    },
  },
}));

export const AssignCase: FC<
  {
    open: boolean;
    onClose(): void;
    onUpdate?: (data: ServiceCaseResponse) => void;
  } & ServiceCaseResponse
> = ({ open, onClose, onUpdate, ...data }) => {
  const classes = useStyle();
  const baseClasses = useDialogStyles();

  const [dispatchers, setDispatchers] = useState<
    typeof GetDispatchers.Res["data"]
  >({ dispatchers: [], total: 0 });
  const [gettingDispatchers, setGettingDispatchers] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedDispatcher, setSelectedDispatcher] = useState("");

  const handleClose = () => {
    if (onClose) onClose();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setSelectedDispatcher(e.target.value);
  };

  const handleAssignCase = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsSubmitting(true);
    (async () => {
      try {
        const res = await getClient(true).post<
          typeof PostAssignServiceCase.Res
        >(PostAssignServiceCase.ROUTE.replace(":id", data.id), {
          dispatcherId: selectedDispatcher,
        });
        setIsSubmitting(false);
        notifications.notifySuccess("Case assigned to Dispatcher");
        if (onUpdate) onUpdate(res.data.data.serviceCase);
        handleClose();
      } catch (e) {
        setIsSubmitting(false);
      }
    })();
  };

  useEffect(() => {
    if (open) {
      (async () => {
        try {
          setGettingDispatchers(true);
          const res = await getClient(true).get<typeof GetDispatchers.Res>(
            GetDispatchers.ROUTE
          );
          setGettingDispatchers(false);
          setDispatchers(res.data.data);
        } catch (e) {
          setGettingDispatchers(false);
        }
      })();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={clsx(classes.dialog, baseClasses.dialog)}
    >
      <Loader open={gettingDispatchers || isSubmitting} absolute />
      <>
        <div className={baseClasses.dialogTitle}>
          <Typography color="primary" style={{ fontSize: 20 }}>
            Assign Case
          </Typography>
          <IconButton size="small" onClick={handleClose as any}>
            <CloseOutlined style={{ fontSize: 20, color: "#BDBDBD" }} />
          </IconButton>
        </div>
        <DialogContent style={{ padding: 0 }}>
          <Box
            px="16px"
            paddingTop="24px"
            paddingBottom="16px"
            position="relative"
          >
            <Loader open={gettingDispatchers} absolute />
            <PatientStatusSnippet
              quotes={[]}
              {...data}
              clickable={false}
              onMore={undefined}
              afterContents={
                <SimpleSelect
                  name="dispatcher"
                  onChange={handleChange}
                  options={dispatchers.dispatchers
                    .filter(HideCurrentlyAssignedDispatcher(data.dispatcher))
                    .map((el) => ({
                      label: formatNameToDisplay(el.firstName, el.lastName),
                      value: el.id,
                      isDisabled: data.dispatcher.id === el.id,
                    }))}
                />
              }
            />
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: "0px 24px 24px" }}>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="primary"
            type="submit"
          >
            Cancel
          </Button>
          <Button
            onClick={handleAssignCase}
            color="primary"
            type="submit"
            disabled={!selectedDispatcher || isSubmitting}
          >
            Assign Case
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};
