import { useCallback, useEffect, useRef, useState } from "react";

// eslint-disable-next-line no-undef
export const useElementOnScreen = (
  options?: IntersectionObserverInit & { dependents?: any[] }
) => {
  const containerRef = useRef(null);
  const [visible, setVisible] = useState(false);

  const callbackFunction = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    setVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (containerRef.current) observer.observe(containerRef.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, options, ...(options?.dependents || [])]);

  return { ref: containerRef, visible };
};

export const useElementWithin = (callback: () => void, deps: any[] = []) => {
  const observer = useRef<IntersectionObserver>(null);

  const target = useCallback(
    (node: any) => {
      if (observer.current) observer.current.disconnect();
      // @ts-ignore
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          callback();
        }
      });
      if (node) observer.current.observe(node as Element);
      // eslint-disable-next-line
  }, [callback, ...deps])

  return { target };
};
