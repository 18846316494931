import { makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles(({ spacing: s }) => ({
  time: {
    fontWeight: 600,
    marginRight: s(2),
  },
}));

interface Props {
  time?: string;
}

export const CallTime = ({ time }: Props) => {
  const [lengthOfCall, setLengthOfCall] = useState("");

  const classes = useStyles();

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (time) {
        const timeDifference = new Date(
          new Date().getTime() - new Date(time).getTime()
        ).getTime();

        let reminder = timeDifference;

        const minutes = Math.floor(reminder / 60000);
        reminder -= minutes * 60000;

        const seconds = Math.floor(reminder / 1000);

        setLengthOfCall(
          `${minutes < 10 ? `0${minutes}` : minutes}:${
            seconds < 10 ? `0${seconds}` : seconds
          }`
        );
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [time]);

  return (
    <Typography variant="subtitle1" color="primary" className={classes.time}>
      {lengthOfCall}
    </Typography>
  );
};
