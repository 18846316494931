import {
  ServiceCaseResponse,
  ServiceCaseStatusEnum,
} from "@deep-consulting-solutions/be2-constants";
import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  spaceBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export const RenderCaseStatus = ({
  caseStatus,
  overdue,
}: Partial<ServiceCaseResponse & { overdue?: boolean }>) => {
  const classes = useStyles();

  return (
    <div className={classes.spaceBetween}>
      <Typography
        variant="caption"
        style={{
          fontWeight: 700,
          color:
            caseStatus === ServiceCaseStatusEnum.UNANSWERED
              ? "#CA2027"
              : "#333333",
        }}
      >
        {caseStatus}
      </Typography>
      {overdue && (
        <Typography color="primary" variant="caption">
          <span style={{ color: "#CA2027", fontWeight: 500 }}>Overdue:</span> 30
          Minutes ago
        </Typography>
      )}
    </div>
  );
};
