import {
  InformationSupportReasonEnum,
  ServiceCaseApprovalRequestStatusEnum,
  ServiceCaseApprovalRequestTypeEnum,
  ServiceCaseStatusEnum,
} from "@deep-consulting-solutions/be2-constants";
import { Button, Grid } from "@material-ui/core";
import { ProvideInformationSupportDialog } from "call/components/ProvideInformationSupportDialog";
import { useCall } from "call/hooks";
import Loader from "component/Loader";
import { useFormikContext } from "formik";
import { getENText } from "helpers";
import React, {
  MouseEventHandler,
  useCallback,
  useMemo,
  useState,
} from "react";
import { provideInformationalSupport } from "redux/call/requests";
import { notifications } from "services";
import { BackButton } from "../../Components";
import { TabsEnum } from "../../types";
import {
  geIsAllPatientsCovered,
  getButtonText,
  getInitialValues,
  getIsServiceCaseApproved,
  getIsServiceCaseRefused,
  getIsServiceCaseWaiting,
  getIsServiceToBeRenderedEmergencyGroundTransportOrEmergencyMaritimeTransport,
  getIsServiceToBeRenderedInformationalSupport,
  showValidationErrorAsNotification,
} from "../helpers";
import { FormValues } from "../types";

interface Props {
  switchTab: (tab: TabsEnum) => void;
  activeTab: TabsEnum;
}

export const FormActions = ({ switchTab, activeTab }: Props) => {
  const [loading, setLoading] = useState(false);
  const {
    values: {
      serviceToBeRendered,
      informationSupportReason,
      informationSupportExplanation,
      patients,
    },
    isValid,
    isSubmitting,
    values,
    setValues,
    errors,
    submitForm,
  } = useFormikContext<FormValues>();
  const { viewedCall, updateCallServiceCase } = useCall();
  const [oldValues, setOldValues] = useState<FormValues>(
    getInitialValues(viewedCall?.updatedServiceCase)
  );

  const [
    showProvideInformationSupportDialog,
    setShowProvideInformationSupportDialog,
  ] = useState(false);

  const handleProvideInformationSupportRequest = useCallback(() => {
    setShowProvideInformationSupportDialog(true);
  }, []);

  const handleCloseApprovalRequestDialog = useCallback(() => {
    setShowProvideInformationSupportDialog(false);
  }, []);

  const isServiceCaseAcceptedByManager = useMemo(() => {
    if (!viewedCall?.updatedServiceCase?.approvalRequests?.length) {
      return true;
    }
    if (
      viewedCall?.updatedServiceCase?.approvalRequests?.every(
        ({ type, status }) =>
          type === ServiceCaseApprovalRequestTypeEnum.SERVICE_CASE &&
          status === ServiceCaseApprovalRequestStatusEnum.CANCELLED
      )
    ) {
      return true;
    }
    return !!viewedCall?.updatedServiceCase?.approvalRequests?.some(
      ({ type, status }) =>
        type === ServiceCaseApprovalRequestTypeEnum.SERVICE_CASE &&
        status === ServiceCaseApprovalRequestStatusEnum.APPROVED
    );
  }, [viewedCall?.updatedServiceCase?.approvalRequests]);

  const isServiceCaseAccepted = useMemo(() => {
    return getIsServiceCaseApproved(
      viewedCall?.updatedServiceCase?.approvalRequests
    );
  }, [viewedCall?.updatedServiceCase?.approvalRequests]);

  const isServiceCaseRefused = useMemo(
    () =>
      getIsServiceCaseRefused(viewedCall?.updatedServiceCase?.approvalRequests),
    [viewedCall?.updatedServiceCase?.approvalRequests]
  );

  const isServiceCaseApproved = useMemo(() => {
    return getIsServiceCaseApproved(
      viewedCall?.updatedServiceCase?.approvalRequests
    );
  }, [viewedCall?.updatedServiceCase?.approvalRequests]);

  const canGoToNextStep = useMemo(() => {
    if (!serviceToBeRendered) {
      return false;
    }
    if (isServiceCaseAccepted || isServiceCaseApproved) {
      return true;
    }

    return (
      getIsServiceToBeRenderedEmergencyGroundTransportOrEmergencyMaritimeTransport(
        serviceToBeRendered
      ) ||
      (!getIsServiceToBeRenderedEmergencyGroundTransportOrEmergencyMaritimeTransport(
        serviceToBeRendered
      ) &&
        isServiceCaseAcceptedByManager)
    );
  }, [
    isServiceCaseAcceptedByManager,
    serviceToBeRendered,
    isServiceCaseAccepted,
    isServiceCaseApproved,
  ]);

  const buttonText = useMemo(
    () =>
      getButtonText(
        serviceToBeRendered,
        isServiceCaseApproved,
        isServiceCaseAccepted,
        geIsAllPatientsCovered(patients)
      ),
    [
      serviceToBeRendered,
      isServiceCaseApproved,
      isServiceCaseAccepted,
      patients,
    ]
  );

  const handleSubmitOfferInformationalSupport = useCallback(async () => {
    if (viewedCall?.updatedServiceCase?.id) {
      const payload: { explaination?: string; reason: string } = {
        reason: informationSupportReason,
      };
      if (informationSupportReason === InformationSupportReasonEnum.OTHER) {
        payload.explaination = informationSupportExplanation;
      } else {
        payload.explaination = "";
      }
      try {
        setLoading(true);
        const res = await provideInformationalSupport(
          viewedCall.updatedServiceCase.id,
          payload
        );
        updateCallServiceCase(viewedCall.sessionId, res);
        notifications.notifySuccess(
          getENText("informationalSupport.successful")
        );
      } catch (error) {
        //
      } finally {
        setLoading(false);
      }
    }
  }, [
    viewedCall?.updatedServiceCase?.id,
    informationSupportReason,
    informationSupportExplanation,
    viewedCall?.sessionId,
    updateCallServiceCase,
  ]);

  const handleOfferInformationalSupport: MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (e) => {
        e.preventDefault();
        handleSubmitOfferInformationalSupport();
      },
      [handleSubmitOfferInformationalSupport]
    );

  const restoreValues = useCallback(
    (v: FormValues) => {
      setValues(v);
      setOldValues(v);
    },
    [setValues]
  );

  const triggerSubmit = useCallback(() => {
    if (
      viewedCall?.updatedServiceCase?.caseStatus !==
      ServiceCaseStatusEnum.GATHERING_REQUIREMENTS
    ) {
      switchTab(TabsEnum.PLANNING);
      return;
    }

    if (!isValid) {
      showValidationErrorAsNotification(errors);
      return;
    }

    submitForm();
  }, [
    submitForm,
    isValid,
    errors,
    switchTab,
    viewedCall?.updatedServiceCase?.caseStatus,
  ]);

  const isAwaiting = useMemo(() => {
    return getIsServiceCaseWaiting(
      viewedCall?.updatedServiceCase?.approvalRequests
    );
  }, [viewedCall?.updatedServiceCase?.approvalRequests]);

  const disableForm = useMemo(
    () =>
      viewedCall?.disableForms ||
      viewedCall?.updatedServiceCase?.caseStatus !==
        ServiceCaseStatusEnum.GATHERING_REQUIREMENTS,
    [viewedCall?.disableForms, viewedCall?.updatedServiceCase?.caseStatus]
  );

  if (isAwaiting) {
    return null;
  }

  return (
    <>
      <Loader open={loading} />
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <BackButton<FormValues>
            activeTab={activeTab}
            switchTab={switchTab}
            oldValues={oldValues}
            newValues={values}
            handleSaveValues={restoreValues}
            disabled={disableForm}
          />
        </Grid>

        {isServiceCaseRefused ? null : (
          <>
            {getIsServiceToBeRenderedInformationalSupport(
              serviceToBeRendered
            ) ? (
              <Grid item>
                <Button
                  color="primary"
                  disabled={disableForm || !isValid}
                  onClick={handleOfferInformationalSupport}
                >
                  Complete Service
                </Button>
              </Grid>
            ) : (
              <Grid item>
                <Grid container justifyContent="flex-end" alignItems="center">
                  {isServiceCaseAccepted && (
                    <Grid item>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleProvideInformationSupportRequest}
                        disabled={disableForm}
                      >
                        PROVIDE INFORMATIONAL SUPPORT
                      </Button>
                      <ProvideInformationSupportDialog
                        open={showProvideInformationSupportDialog}
                        handleClose={handleCloseApprovalRequestDialog}
                      />
                    </Grid>
                  )}
                  {!isServiceCaseRefused && (
                    <Grid item>
                      <Button
                        color="primary"
                        type="submit"
                        onClick={triggerSubmit}
                        disabled={
                          !canGoToNextStep ||
                          isSubmitting ||
                          viewedCall?.disableForms
                        }
                      >
                        {buttonText}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  );
};
