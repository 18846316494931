import React, { useCallback, useEffect, useState } from "react";

import { format, isValid } from "date-fns";
import { useMediaQuery, useTheme } from "@material-ui/core";
import {
  KeyboardDateTimePickerProps,
  KeyboardDateTimePicker,
} from "formik-material-ui-pickers";

export const DATE_PICKER_FORMATS = {
  typing: "dd/MM/yyyy",
  display: "dd MMMM yyyy",
};

export type FormikDateTimePickerProps = KeyboardDateTimePickerProps & {
  typingFormat?: string;
  displayFormat?: string;
};

/**
 * The component accepts Material UI KeyboardDatePicker props: https://material-ui-pickers.dev/api/KeyboardDatePicker
 */
export const FormikDateTimePicker: React.FC<FormikDateTimePickerProps> = ({
  typingFormat = DATE_PICKER_FORMATS.typing,
  displayFormat = DATE_PICKER_FORMATS.display,
  field,
  form,
  ...props
}) => {
  const [focus, setFocus] = useState(false);
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const { setFieldTouched, touched } = form;
  const { value, name } = field;

  const onFocus = useCallback(() => {
    setFocus(true);
  }, [setFocus]);

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFocus(false);
      field.onBlur(e);
    },
    [field]
  );

  const displayDate = useCallback(
    (d: Date | null) => {
      if (!d || !isValid(d)) return "";

      const dateFormat = focus ? typingFormat : displayFormat;

      return format(d, dateFormat);
    },
    [displayFormat, focus, typingFormat]
  );

  useEffect(() => {
    if (value && !touched[name]) {
      setFieldTouched(name);
    }
  }, [name, setFieldTouched, touched, value]);

  return (
    <KeyboardDateTimePicker
      autoComplete="off"
      disablePast
      disableToolbar
      autoOk={!isSmallDevice}
      variant={isSmallDevice ? "dialog" : "inline"}
      format={typingFormat}
      labelFunc={displayDate}
      onFocus={onFocus}
      KeyboardButtonProps={{
        "aria-label": "change date",
      }}
      field={field}
      form={form}
      onBlur={onBlur}
      {...props}
    />
  );
};
