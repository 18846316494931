import type { ZohoCurrentUser } from "services/zoho/types";

export const isZohoBusinessOwner = (
  currentUser: ZohoCurrentUser | null | undefined
) => {
  return currentUser?.profile.name === "Business Owner";
};

export const isZohoBEAdminStaff = (
  currentUser: ZohoCurrentUser | null | undefined
) => {
  return currentUser?.profile.name === "Business Owner";
};
