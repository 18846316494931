import React from "react";
import { useStyles } from "./style";
import { Icon } from "./types";

export const TwoSideLayout = ({ onClick }: Icon) => {
  const classes = useStyles();
  return (
    <svg
      width="32"
      height="28"
      viewBox="0 0 32 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={classes.icon}
    >
      <path
        d="M0.5 4.5C0.5 2.29086 2.29086 0.5 4.5 0.5H27.5C29.7091 0.5 31.5 2.29086 31.5 4.5V23.5C31.5 25.7091 29.7091 27.5 27.5 27.5H4.5C2.29086 27.5 0.5 25.7091 0.5 23.5V4.5Z"
        fill="#F7F7F7"
      />
      <rect x="6.5" y="6.5" width="8" height="15" rx="1" stroke="#828282" />
      <rect x="17.5" y="6.5" width="8" height="15" rx="1" stroke="#828282" />
      <path
        d="M4.5 1H27.5V0H4.5V1ZM31 4.5V23.5H32V4.5H31ZM27.5 27H4.5V28H27.5V27ZM1 23.5V4.5H0V23.5H1ZM4.5 27C2.567 27 1 25.433 1 23.5H0C0 25.9853 2.01472 28 4.5 28V27ZM31 23.5C31 25.433 29.433 27 27.5 27V28C29.9853 28 32 25.9853 32 23.5H31ZM27.5 1C29.433 1 31 2.567 31 4.5H32C32 2.01472 29.9853 0 27.5 0V1ZM4.5 0C2.01472 0 0 2.01472 0 4.5H1C1 2.567 2.567 1 4.5 1V0Z"
        fill="#E0E0E0"
      />
    </svg>
  );
};
