import { ServiceCaseStatusEnum } from "@deep-consulting-solutions/be2-constants";
import { Typography } from "@material-ui/core";
import React from "react";

const colors = (status: ServiceCaseStatusEnum) => {
  switch (status) {
    case ServiceCaseStatusEnum.COMPLETED:
      return { bg: "#E1F4E2", co: "#333333" };

    case ServiceCaseStatusEnum.RE_OPENED:
    case ServiceCaseStatusEnum.SERVICE_SCHEDULED:
      return { bg: "#ED6C02", co: "white" };

    case ServiceCaseStatusEnum.SERVICE_STARTED:
    case ServiceCaseStatusEnum.CLOSED:
    default:
      return { bg: "#08A40F", co: "white" };
  }
};

export const RenderStatus = ({ status }: { status: string }) => {
  const { bg, co } = colors(status as ServiceCaseStatusEnum);

  return (
    <Typography
      style={{
        backgroundColor: bg,
        borderRadius: 4,
        padding: "4px 14px",
        width: "min-width",
        fontSize: 12,
        color: co,
        marginLeft: 16,
      }}
    >
      {status}
    </Typography>
  );
};
