import React, { useCallback, useState } from "react";
import {
  makeStyles,
  Typography,
  Switch,
  IconButton,
  Collapse,
  Divider,
  Link,
  Button,
} from "@material-ui/core";
import clsx from "clsx";
import { ExpandLess, Visibility, VisibilityOff } from "@material-ui/icons";

import {
  AccessLinkRecipientTypeEnum,
  AccessNotificationTypeEnum,
  ServiceCaseAccessLinkResponse,
} from "@deep-consulting-solutions/be2-constants";
import { displayLastAccessTime } from "../helpers";

export const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  wrapper: {
    border: `1px solid ${p.grey[300]}`,
    padding: s(1),
    borderRadius: s(1),
    marginBottom: s(3),
  },
  head: {
    display: "flex",
    alignItems: "center",
  },
  lastAccess: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
  },
  lastAccessTitle: {
    fontSize: 12,
    color: p.grey[700],
    marginRight: s(1),
  },
  lastAccessTime: {
    fontSize: 12,
    color: p.grey[700],
    fontWeight: 600,
  },
  headStatus: {
    marginLeft: s(0.5),
    marginRight: s(1.5),
    fontSize: 14,
    color: p.primary.main,
  },
  headActions: {
    display: "flex",
    alignItems: "center",
  },
  collapseBtn: {
    borderRadius: s(0.5),
    background: "#f6f6f6",
  },
  infoData: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  keyValue: {
    display: "flex",
    alignItems: "center",
    marginTop: s(1),
  },
  full: {
    width: "100%",
  },
  half: {
    width: "50%",
  },
  key: {
    color: p.grey[700],
    fontSize: 12,
    marginRight: s(1.5),
  },
  value: {
    display: "flex",
    alignItems: "center",
  },
  link: {
    fontSize: 12,
    textDecoration: "underline",
  },
  icon: {
    color: p.grey[500],
    fontSize: 15,
  },
  name: {
    fontSize: 12,
    fontWeight: 600,
    color: p.grey[800],
  },
  role: {
    fontSize: 10,
    color: p.grey[800],
    marginLeft: s(0.5),
  },
  actions: {
    display: "flex",
    alignItems: "center",
    marginTop: s(1),
  },
  dumpActionDiv: {
    flex: 1,
  },
  deactivateBtn: {
    color: p.primary.dark,
    fontSize: 10,
  },
  resendBtn: {
    fontSize: 10,
  },
  caseNoWrapper: {
    marginRight: "30px",
  },
  pin: {
    marginRight: s(1),
  },
}));

interface Props {
  accessLink: ServiceCaseAccessLinkResponse;
  onActiveToggle: (accessLinkID: string, isActive: boolean) => any;
  onDeactivateClick: (accessLink: ServiceCaseAccessLinkResponse) => any;
  onResendClick: (accessLinkID: string) => any;
  disabled?: boolean;
}

export const AccessLink: React.FC<Props> = ({
  accessLink,
  onActiveToggle,
  onDeactivateClick,
  onResendClick,
  disabled,
}) => {
  const { isPinBased } = accessLink;
  const [expand, setExpand] = useState(true);

  const handleExpandToggle = useCallback(() => {
    setExpand((current) => !current);
  }, []);

  const [pinShow, setPinShow] = useState(false);

  const handlePinToggle = useCallback(() => {
    setPinShow((show) => !show);
  }, []);

  const handleActiveToggle = useCallback(() => {
    onActiveToggle(accessLink.ID, !accessLink.isActive);
  }, [accessLink.ID, accessLink.isActive, onActiveToggle]);

  const handleLinkDeactivate = useCallback(() => {
    onDeactivateClick(accessLink);
  }, [onDeactivateClick, accessLink]);

  const handleResendClick = useCallback(() => {
    onResendClick(accessLink.ID);
  }, [onResendClick, accessLink.ID]);

  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.head}>
        <Typography>{isPinBased ? "PIN" : "URL"} Based</Typography>
        <div className={classes.lastAccess}>
          <Typography className={classes.lastAccessTitle}>
            Last Accessed On:{" "}
          </Typography>
          <Typography className={classes.lastAccessTime}>
            {accessLink.lastAccessedOn
              ? displayLastAccessTime(new Date(accessLink.lastAccessedOn))
              : "-"}
          </Typography>
        </div>
        <div className={classes.headActions}>
          <Switch
            color="primary"
            checked={accessLink.isActive}
            onClick={handleActiveToggle}
            disabled={disabled || accessLink.permanentlyDeactivated}
          />
          <Typography className={classes.headStatus}>
            {accessLink.isActive ? "Active" : "Inactive"}
          </Typography>
          <IconButton
            className={classes.collapseBtn}
            size="small"
            onClick={handleExpandToggle}
          >
            <ExpandLess
              style={{
                transition: "0.2s",
                transform: `rotate(${expand ? "-180" : "-90"}deg)`,
              }}
              color="primary"
            />
          </IconButton>
        </div>
      </div>

      <Collapse in={expand}>
        <div>
          <Divider />
          <div className={classes.infoData}>
            {isPinBased && (
              <>
                <div className={clsx(classes.keyValue, classes.caseNoWrapper)}>
                  <Typography className={classes.key}>Case No:</Typography>
                  <Typography className={classes.name}>
                    {accessLink.caseNumber}
                  </Typography>
                </div>
                <div className={classes.keyValue}>
                  <Typography className={classes.key}>PIN:</Typography>
                  <div className={classes.value}>
                    <Typography className={clsx(classes.name, classes.pin)}>
                      {pinShow ? accessLink.pin : "****"}
                    </Typography>
                    <IconButton size="small" onClick={handlePinToggle}>
                      {pinShow ? (
                        <Visibility className={classes.icon} />
                      ) : (
                        <VisibilityOff className={classes.icon} />
                      )}
                    </IconButton>
                  </div>
                </div>
              </>
            )}

            <div className={clsx(classes.keyValue, classes.full)}>
              <Typography className={classes.key}>Accessed Via:</Typography>
              <div className={classes.value}>
                <Link href={accessLink.url} target="_blank">
                  <Typography className={classes.link}>
                    {isPinBased
                      ? accessLink.url
                      : `Service Case ${accessLink.caseNumber}`}
                  </Typography>
                </Link>
              </div>
            </div>

            <div className={clsx(classes.keyValue, classes.full)}>
              <Typography className={classes.key}>Generated by:</Typography>
              <div className={classes.value}>
                <Typography className={classes.name}>
                  {accessLink.generatedBy.name}
                </Typography>
                {accessLink.generatedBy.designation && (
                  <Typography className={classes.role}>
                    ({accessLink.generatedBy.designation})
                  </Typography>
                )}
              </div>
            </div>

            <div className={clsx(classes.keyValue, classes.half)}>
              <Typography className={classes.key}>Name:</Typography>
              <div className={classes.value}>
                <Typography className={classes.name}>
                  {accessLink.recipientName || "--"}
                </Typography>
                {accessLink.recipientDesignation && (
                  <Typography className={classes.role}>
                    ({accessLink.recipientDesignation})
                  </Typography>
                )}
              </div>
            </div>

            <div className={clsx(classes.keyValue, classes.half)}>
              <Typography className={classes.key}>Organization:</Typography>
              <Typography className={classes.name}>
                {accessLink.recipientOrganization || "--"}
              </Typography>
            </div>

            <div className={clsx(classes.keyValue, classes.half)}>
              <Typography className={classes.key}>
                Recipient{" "}
                {accessLink.notificationType ===
                AccessNotificationTypeEnum.EMAIL
                  ? "Email:"
                  : "Phone:"}
              </Typography>
              <Typography className={classes.name}>
                {accessLink.notificationType ===
                AccessNotificationTypeEnum.EMAIL
                  ? accessLink.recipientEmail || "--"
                  : accessLink.recipientPhone || "--"}
              </Typography>
            </div>
          </div>

          <div className={classes.actions}>
            {!accessLink.permanentlyDeactivated && (
              <Button
                className={classes.deactivateBtn}
                variant="outlined"
                color="primary"
                onClick={handleLinkDeactivate}
                disabled={disabled}
              >
                Deactivate Access Permanently
              </Button>
            )}
            <div className={classes.dumpActionDiv} />
            {accessLink.recipientType !==
              AccessLinkRecipientTypeEnum.ANYONE && (
              <Button
                color="primary"
                className={classes.resendBtn}
                onClick={handleResendClick}
                disabled={disabled}
              >
                Resend
              </Button>
            )}
          </div>
        </div>
      </Collapse>
    </div>
  );
};
