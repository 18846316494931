import { Chat, ChatAttachment } from "@deep-consulting-solutions/be2-constants";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import { useCall } from "call/hooks";
import Loader from "component/Loader";
import { format } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getAttachmentSrc } from "./helpers";

interface StyleProps {
  variant: "left" | "right";
}

const useStyles = makeStyles<Theme, StyleProps>(({ spacing: s }) => ({
  wrapper: ({ variant }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginBottom: s(2),
    justifyContent: variant === "left" ? "flex-start" : "flex-end",
    position: "relative",
  }),
  image: {
    width: 113,
    height: 113,
    borderRadius: 8,
    objectFit: "cover",
  },
  text: ({ variant }) =>
    variant === "left"
      ? {
          background: "#F6F6F6",
          borderRadius: "0px 8px 8px 8px",
          maxWidth: "70%",
          padding: s(2),
        }
      : {
          maxWidth: "70%",
          padding: s(2),
          background: "#EFF3FF",
          borderRadius: "8px 0px 8px 8px",
        },
  time: {
    fontSize: 10,
    display: "block",
    color: "#828282",
  },
  messageText: {
    width: "100%",
    overflowWrap: "break-word",
  },
}));

const SingleAttachment = ({
  sender,
  time,
  type,
  attachment,
}: Pick<Chat, "sender" | "time" | "type"> & {
  attachment: ChatAttachment & {
    localUrl?: string;
    base64?: string | ArrayBuffer | null;
    url?: string;
    serviceCaseId?: string;
    sessionId?: string;
    chatId?: string;
    attachmentId?: string;
  };
}) => {
  const classes = useStyles({
    variant: sender === "user" ? "left" : "right",
  });
  const { viewedCall } = useCall();
  const [attachmentFile, setAttachmentFile] = useState<string | undefined>("");
  const [loading, setLoading] = useState(false);

  const getAttachmentFile = useCallback(async () => {
    try {
      setLoading(true);
      if (!attachment) {
        return undefined;
      }
      const url = await getAttachmentSrc({
        ...attachment,
        serviceCaseId: viewedCall?.updatedServiceCase?.id,
      });
      setAttachmentFile(url);
    } catch {
      //
    } finally {
      setLoading(false);
    }
  }, [attachment, viewedCall?.updatedServiceCase?.id]);

  const documentDetails = useMemo(() => {
    if (type === "document") {
      const fileName =
        attachment.fileName || ((attachment as any).name as string);
      return (
        <a href={attachmentFile} target="_blank" download rel="noreferrer">
          <b>{fileName}</b>
        </a>
      );
    }

    return "";
  }, [type, attachmentFile, attachment]);

  useEffect(() => {
    getAttachmentFile();
  }, [getAttachmentFile]);

  return (
    <div
      className={classes.wrapper}
      key={attachment.fileName || attachment.s3Key}
    >
      <Loader open={loading} absolute />
      {(type === "media" || (type as string) === "image") && (
        <img className={classes.image} src={attachmentFile} alt="chat" />
      )}

      {type === "recording" && (
        <audio>
          <track kind="captions" />
          <source type={attachment.mimeType} src={attachmentFile} />
        </audio>
      )}

      {type === "document" && (
        <div className={classes.text}>
          <Typography
            color="primary"
            variant="caption"
            className={classes.messageText}
          >
            {documentDetails}
          </Typography>
          <Typography variant="caption" align="right" className={classes.time}>
            {format(new Date(time), "hh:mm a")}
          </Typography>
        </div>
      )}
    </div>
  );
};

export const ChatMessageComponent = ({
  sender,
  time,
  type,
  attachments,
  contact,
  ...rest
}: Chat) => {
  const classes = useStyles({
    variant: sender === "user" ? "left" : "right",
  });

  const contactDetails = useMemo(() => {
    if (type === "contact" && contact) {
      return (
        <span>
          <b>Name:</b> {contact.name}
          <br />
          <b>Phone:</b> {contact.phone}
        </span>
      );
    }

    return "";
  }, [type, contact]);

  if (type === "contact" || type === "message") {
    return (
      <div className={classes.wrapper}>
        {type === "message" && (
          <div className={classes.text}>
            <Typography
              color="primary"
              variant="caption"
              className={classes.messageText}
            >
              {rest.message}
            </Typography>
            <Typography
              variant="caption"
              align="right"
              className={classes.time}
            >
              {format(new Date(time), "hh:mm a")}
            </Typography>
          </div>
        )}

        {type === "contact" && (
          <div className={classes.text}>
            <Typography
              color="primary"
              variant="caption"
              className={classes.messageText}
            >
              {contactDetails}
            </Typography>
            <Typography
              variant="caption"
              align="right"
              className={classes.time}
            >
              {format(new Date(time), "hh:mm a")}
            </Typography>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      {attachments?.map((attachment) => (
        <SingleAttachment
          key={attachment.s3Key}
          sender={sender}
          time={time}
          attachment={attachment}
          type={type}
        />
      ))}
    </>
  );
};
