import { Dialog } from "@material-ui/core";
import React from "react";
import { Checkout } from "./Checkout";

interface Props {
  goBack: () => void;
  subscriptionId: string;
  contactID: string;
  handleSuccess: () => Promise<void>;
}

export const PaymentDialog = ({
  goBack,
  subscriptionId,
  contactID,
  handleSuccess,
}: Props) => {
  return (
    <Dialog open={!!subscriptionId} fullScreen>
      <Checkout
        goBack={goBack}
        subscriptionId={subscriptionId}
        contactID={contactID}
        handleSuccess={handleSuccess}
      />
    </Dialog>
  );
};
