import { ChatAttachment } from "@deep-consulting-solutions/be2-constants";
import { fetchS3SignedUrlForDownload } from "redux/call/requests";
import { CallChatTab, CallChatTabProps, TabsEnum } from "./types";

export const tabsEnumToNumber = (value: TabsEnum) => {
  return Object.values(TabsEnum).indexOf(value);
};

export const numberToTabsEnum = (value: number) => {
  return Object.values(TabsEnum)[value];
};

export const getCallChatTabId = (index: number) => `call-chat-tab-${index}`;

export const getCallChatAreaLabel = (index: number) =>
  `call-chat-tab-panel-${index}`;

const getProps = (index: number): CallChatTabProps => {
  return {
    id: getCallChatTabId(index),
    "aria-controls": getCallChatAreaLabel(index),
  };
};

export const callChatTabs: CallChatTab[] = Object.values(TabsEnum).map(
  (tab) => ({
    label: tab,
    props: getProps(tabsEnumToNumber(tab)),
    index: tabsEnumToNumber(tab),
  })
);

export const getAttachmentSrc = async (
  attachment: ChatAttachment & {
    localUrl?: string;
    base64?: string | ArrayBuffer | null;
    url?: string;
    serviceCaseId?: string;
    sessionId?: string;
    chatId?: string;
    attachmentId?: string;
  }
) => {
  try {
    if (attachment.url) {
      return attachment.url;
    }

    if (attachment.base64 && typeof attachment.base64 === "string") {
      return attachment.base64;
    }

    if (attachment.localUrl) {
      return attachment.localUrl;
    }

    if (
      attachment.sessionId &&
      attachment.chatId &&
      attachment.serviceCaseId &&
      attachment.attachmentId
    ) {
      const res = await fetchS3SignedUrlForDownload(
        attachment.serviceCaseId,
        attachment.sessionId,
        attachment.chatId,
        attachment.attachmentId
      );

      return res.url;
    }

    return undefined;
  } catch (err) {
    return undefined;
  }
};
