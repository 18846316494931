import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import { SelectField } from "component/atoms";
import { useStyles } from "call/components/CallForms/RequesterAndPatientForm/styles";
import {
  Member,
  NextOfKinDisabledFields,
  PatientData,
  NextOfKinTypeEnum,
  MembershipTypeEnum,
  Membership,
} from "call/components/CallForms/RequesterAndPatientForm/types";
import { SearchedMembers } from "call/components/CallForms/RequesterAndPatientForm/SearchedMembers";
import {
  GenderEnum,
  MembersResponseSubObject,
  MinimalContact,
  NextOfKinData,
  NextOfKinRelationToPatientEnum,
  ServiceCaseRequesterPatientRelationEnum,
} from "@deep-consulting-solutions/be2-constants";
import { ExistingNextOfKin } from "call/components/CallForms/RequesterAndPatientForm/ExistingNextOfKin";
import Loader from "component/Loader";
import { getContactSubMembers } from "redux/call/requests";
import { useCall } from "call/hooks";
import { SearchForPatient } from "./SearchForPatient";
import {
  getGeneralAddressString,
  getShowExistingNextOfKinComponent,
  transformRelationToApplicantEnum,
} from "./helpers";
import { FormValues } from "./types";
import { PatientMedicalInformationSection } from "./PatientMedicalInformationSection";

interface Props {
  formKey: string;
  patient: PatientData;
  requesterMembers?: MembersResponseSubObject[];
  handleOpenRemovePatientDialog: () => void;
  handleRemovePatientWithoutDialog: () => void;
  showRemovePatientButton: boolean;
  disableForm: boolean;
}

export const MemberPatientInformation: React.FC<Props> = ({
  patient,
  formKey,
  requesterMembers: rM,
  showRemovePatientButton,
  handleOpenRemovePatientDialog,
  handleRemovePatientWithoutDialog,
  disableForm,
}) => {
  const { viewedCall } = useCall();

  const classes = useStyles({});
  const [selectedMembers, setSelectedMembers] = useState<Member[]>([]);
  const [loading, setLoading] = useState(false);
  const {
    setFieldValue,
    values,
    values: { patients },
  } = useFormikContext<FormValues>();
  const [subscriptionMembers, setSubscriptionMembers] = useState<
    MembersResponseSubObject[]
  >([]);
  const [memberNextOfKin, setMemberNextOfKin] = useState<
    undefined | Partial<NextOfKinData>
  >(undefined);
  const [disabledRelationField, setDisabledRelationField] = useState(false);
  const [disabledOtherRelationField, setDisabledOtherRelationField] =
    useState(false);
  const [disabledNextOfKinFields, setDisabledNextOfKinFields] = useState<
    Partial<NextOfKinDisabledFields>
  >({});

  const handleMemberSelect = useCallback(
    async (
      member: Member & {
        requesterRelationToPatient: NextOfKinRelationToPatientEnum;
        requesterOtherRelationToPatient?: string;
      }
    ) => {
      if (member) {
        setSelectedMembers([member]);
        try {
          setLoading(true);
          const res = await getContactSubMembers(member.id, true, true);
          setFieldValue(`${formKey}.firstName`, member.firstName);
          setFieldValue(`${formKey}.lastName`, member.lastName);
          setFieldValue(`${formKey}.gender`, member.gender);
          setFieldValue(`${formKey}.dob`, member.dob);
          setFieldValue(`${formKey}.contactID`, member.id);
          setFieldValue(`${formKey}.email`, member.email);
          setFieldValue(`${formKey}.phone`, member.phone);
          setFieldValue(
            `${formKey}.requesterRelationToPatient`,
            transformRelationToApplicantEnum(
              "NextOfKinRelationToPatientEnum",
              member.requesterRelationToPatient
            )
          );
          setFieldValue(
            `${formKey}.requesterOtherRelationToPatient`,
            member.requesterOtherRelationToPatient
          );
          setDisabledRelationField(!!member.requesterRelationToPatient);

          setDisabledOtherRelationField(
            !!member.requesterOtherRelationToPatient
          );
          setFieldValue(`${formKey}.isMemberOrCustomer`, true);
          if (res.nextOfKin?.firstName) {
            setFieldValue(
              `${formKey}.nextOfKinMembership`,
              NextOfKinTypeEnum.NEXT_OF_KIN
            );
            setMemberNextOfKin(res.nextOfKin);
            const relationshipToPatient = transformRelationToApplicantEnum(
              "NextOfKinRelationToPatientEnum",
              res.nextOfKin.relationToMainbeneficiary ||
                res.nextOfKin.relationToContact
            );
            const otherRelationToPatient = res.nextOfKin
              .relationToMainbeneficiary
              ? res.nextOfKin.otherRelationToMainbeneficiary
              : res.nextOfKin.otherRelationToContact;
            setFieldValue(`${formKey}.nextOfKin`, {
              relationshipToPatient: relationshipToPatient || "",
              otherRelationToPatient: otherRelationToPatient || "",
              firstName: res.nextOfKin?.firstName || "",
              lastName: res.nextOfKin?.lastName || "",
              phone: res.nextOfKin?.phone || "",
              email: res.nextOfKin?.email || "",
              isMemberOrCustomer: true,
              contactID: res.nextOfKin?.id || "",
            });
            setDisabledNextOfKinFields({
              relationshipToPatient: !!relationshipToPatient,
              otherRelationToPatient: !!otherRelationToPatient,
              firstName: !!res.nextOfKin?.firstName,
              lastName: !!res.nextOfKin?.lastName,
              phone: !!res.nextOfKin?.phone,
              email: !!res.nextOfKin?.email,
            });
          } else {
            setMemberNextOfKin(undefined);
            const subscriptionAsMainBeneficiary = res.subscriptionMembers.find(
              (subscriptionMemberFiltered) =>
                !!subscriptionMemberFiltered.isMainBeneficiary
            );
            if (subscriptionAsMainBeneficiary) {
              const emergencyContact =
                subscriptionAsMainBeneficiary.mainBeneficiaryEmergencyContact;

              if (emergencyContact) {
                setFieldValue(
                  `${formKey}.nextOfKinMembership`,
                  emergencyContact?.id
                );
                const relationshipToPatient = transformRelationToApplicantEnum(
                  "NextOfKinRelationToPatientEnum",
                  emergencyContact.relationToMainbeneficiary ||
                    emergencyContact.relationToContact
                );

                const otherRelationToPatient =
                  emergencyContact.relationToMainbeneficiary
                    ? emergencyContact.otherRelationToMainbeneficiary
                    : emergencyContact.otherRelationToContact;
                setFieldValue(`${formKey}.nextOfKin`, {
                  relationshipToPatient: relationshipToPatient || "",
                  otherRelationToPatient: otherRelationToPatient || "",
                  firstName: emergencyContact.firstName || "",
                  lastName: emergencyContact.lastName || "",
                  phone: emergencyContact.phone || "",
                  email: emergencyContact.email || "",
                  isMemberOrCustomer: true,
                  contactID: emergencyContact?.id || "",
                });
                setDisabledNextOfKinFields({
                  relationshipToPatient: !!relationshipToPatient,
                  otherRelationToPatient: !!otherRelationToPatient,
                  firstName: !!emergencyContact?.firstName,
                  lastName: !!emergencyContact?.lastName,
                  phone: !!emergencyContact?.phone,
                  email: !!emergencyContact?.email,
                });
              }
            } else {
              const subscriptionAsDependent = res.subscriptionMembers.find(
                (subscriptionMemberFiltered) =>
                  !subscriptionMemberFiltered.isMainBeneficiary
              );

              if (subscriptionAsDependent) {
                const mainBeneficiary = subscriptionAsDependent.mainBeneficiary;

                if (mainBeneficiary) {
                  setFieldValue(
                    `${formKey}.nextOfKinMembership`,
                    mainBeneficiary?.id
                  );

                  const relationshipToPatient =
                    transformRelationToApplicantEnum(
                      "NextOfKinRelationToPatientEnum",
                      mainBeneficiary.relationToContact ||
                        mainBeneficiary.relationToMainbeneficiary
                    );
                  const otherRelationToPatient =
                    mainBeneficiary.relationToContact
                      ? mainBeneficiary.otherRelationToContact
                      : mainBeneficiary.otherRelationToMainbeneficiary;
                  setFieldValue(`${formKey}.nextOfKin`, {
                    relationshipToPatient: relationshipToPatient || "",
                    otherRelationToPatient: otherRelationToPatient || "",
                    firstName: mainBeneficiary.firstName || "",
                    lastName: mainBeneficiary.lastName || "",
                    phone: mainBeneficiary.phone || "",
                    email: mainBeneficiary.email || "",
                    isMemberOrCustomer: true,
                    contactID: mainBeneficiary.id,
                  });
                  setDisabledNextOfKinFields({
                    relationshipToPatient: !!relationshipToPatient,
                    otherRelationToPatient: !!otherRelationToPatient,
                    firstName: !!mainBeneficiary?.firstName,
                    lastName: !!mainBeneficiary?.lastName,
                    phone: !!mainBeneficiary?.phone,
                    email: !!mainBeneficiary?.email,
                  });
                }
              } else {
                setFieldValue(
                  `${formKey}.nextOfKinMembership`,
                  NextOfKinTypeEnum.NEW
                );
                setFieldValue(`${formKey}.nextOfKin`, {
                  relationshipToPatient: "",
                  otherRelationToPatient: "",
                  firstName: "",
                  lastName: "",
                  phone: "",
                  email: "",
                  isMemberOrCustomer: false,
                  contactID: "",
                });
                setDisabledNextOfKinFields({
                  relationshipToPatient: false,
                  otherRelationToPatient: false,
                  firstName: false,
                  lastName: false,
                  phone: false,
                  email: false,
                });
              }
            }
          }
          setSubscriptionMembers(res.subscriptionMembers);
        } catch (error) {
          //
        } finally {
          setLoading(false);
        }
      }
    },
    [setFieldValue, formKey]
  );

  const getMembershipOptions = useCallback(
    (membership: MembersResponseSubObject) => {
      const res = [
        {
          label: `${membership.mainBeneficiary?.firstName} ${membership.mainBeneficiary?.lastName} (Main Beneficiary)`,
          value: membership.mainBeneficiary?.id,
        },
      ].concat(
        membership.otherMembers.map((otherMember) => ({
          label: `${otherMember.firstName} ${otherMember.lastName} (Dependent)`,
          value: otherMember.id,
        }))
      );

      return res.filter(
        (r) => !patients.map((p) => p.contactID).includes(r.value)
      );
    },
    [patients]
  );

  const requesterMembers = useMemo(() => {
    return rM?.filter(
      (membership) => !!getMembershipOptions(membership).length
    );
  }, [rM, getMembershipOptions]);

  const getMembershipWithValue = useCallback(
    (
      value: string
    ): {
      membership: string | null;
      details:
        | (Member & {
            requesterRelationToPatient: NextOfKinRelationToPatientEnum;
            requesterOtherRelationToPatient?: string;
          })
        | null;
    } => {
      let details: MinimalContact | undefined;

      if (rM) {
        let memberships: Membership[] = [];
        let requesterRelationToPatient: unknown;
        let requesterOtherRelationToPatient: string | undefined;
        for (let index = 0; index < rM.length; index += 1) {
          const membership = rM[index];

          if (membership.mainBeneficiary?.id === value) {
            details = membership.mainBeneficiary;
            requesterRelationToPatient = transformRelationToApplicantEnum(
              "ServiceCaseRequesterPatientRelationEnum",
              membership.mainBeneficiary.relationToMainbeneficiary ||
                membership.mainBeneficiary.otherRelationToContact
            );
            requesterOtherRelationToPatient = membership.mainBeneficiary
              .relationToMainbeneficiary
              ? membership.mainBeneficiary.otherRelationToMainbeneficiary
              : membership.mainBeneficiary.otherRelationToContact;
            memberships = [
              {
                type: MembershipTypeEnum.MainBeneficiary,
                number: membership.mainBeneficiaryMembershipNo,
              },
            ];
            break;
          }
          const fromOtherMembers = membership.otherMembers.find(
            (o) => o.id === value
          );

          if (fromOtherMembers) {
            details = fromOtherMembers;
            requesterRelationToPatient = transformRelationToApplicantEnum(
              "ServiceCaseRequesterPatientRelationEnum",
              fromOtherMembers.relationToMainbeneficiary ||
                fromOtherMembers.relationToContact
            );
            requesterOtherRelationToPatient =
              fromOtherMembers.relationToMainbeneficiary
                ? fromOtherMembers.otherRelationToMainbeneficiary
                : fromOtherMembers.otherRelationToContact;
            memberships = [
              {
                type: MembershipTypeEnum.Dependent,
                number: membership.mainBeneficiaryMembershipNo,
              },
            ];

            break;
          }
        }

        if (details) {
          return {
            membership: value,
            details: {
              id: value,
              firstName: details.firstName,
              lastName: details.lastName,
              memberships,
              dob: details.dob,
              citizenships: details.citizenship,
              address: getGeneralAddressString(details.address),
              email: details.email,
              phone: details.phone,
              gender: details.gender || ("" as GenderEnum),
              requesterRelationToPatient: (requesterRelationToPatient ||
                "") as NextOfKinRelationToPatientEnum,
              requesterOtherRelationToPatient:
                requesterOtherRelationToPatient || "",
            },
          };
        }
      }
      return {
        details: null,
        membership: null,
      };
    },
    [rM]
  );

  const onPatientSelect = useCallback(
    (option: string) => {
      const { membership, details } = getMembershipWithValue(option);
      if (membership && details) {
        handleMemberSelect(details);
        setFieldValue(`${formKey}.membership`, membership);
      }
    },
    [getMembershipWithValue, formKey, setFieldValue, handleMemberSelect]
  );

  const handleInitialValues = useCallback(async () => {
    try {
      setLoading(true);

      if (patient.contactID) {
        const { details } = getMembershipWithValue(patient.contactID);
        if (details) {
          setSelectedMembers([details]);
          setFieldValue(`${formKey}.membership`, patient.contactID);

          const res = await getContactSubMembers(patient.contactID, true, true);

          if (res.nextOfKin?.firstName) {
            setMemberNextOfKin(res.nextOfKin);
          }

          setDisabledNextOfKinFields({
            relationshipToPatient: !!patient.requesterRelationToPatient,
            otherRelationToPatient: !!patient.requesterOtherRelationToPatient,
            firstName: !!patient.nextOfKin?.firstName,
            lastName: !!patient.nextOfKin?.lastName,
            phone: !!patient.nextOfKin?.phone,
            email: !!patient.nextOfKin?.email,
          });

          setSubscriptionMembers(res.subscriptionMembers);
        }
      } else {
        setSelectedMembers([]);
      }
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  }, [patient, getMembershipWithValue, setFieldValue, formKey]);

  const shouldRequestFullInfo = useMemo(() => {
    return getShowExistingNextOfKinComponent(
      patient.isDeceased,
      viewedCall?.updatedServiceCase?.requestType
    );
  }, [patient.isDeceased, viewedCall?.updatedServiceCase?.requestType]);

  useEffect(() => {
    handleInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!values.requester.isMemberOrCustomer) {
      handleRemovePatientWithoutDialog();
    }
  }, [handleRemovePatientWithoutDialog, values.requester.isMemberOrCustomer]);

  return (
    <div className={classes.fullSpaced}>
      <Loader open={loading} />
      <SearchForPatient
        onPatientSelect={onPatientSelect}
        reSearch={!!selectedMembers.length}
        requesterMembers={requesterMembers}
        formKey={formKey}
        handleOpenRemovePatientDialog={handleOpenRemovePatientDialog}
        showRemovePatientButton={showRemovePatientButton}
        getMembershipOptions={getMembershipOptions}
        disableForm={disableForm}
      />

      {selectedMembers.length ? (
        <>
          <Box mt={2}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Field
                  name={`${formKey}.isDeceased`}
                  size="small"
                  type="checkbox"
                  color="primary"
                  Label={{ label: "Patient is deceased?" }}
                  component={CheckboxWithLabel}
                  disabled={disableForm}
                />
              </Grid>
            </Grid>
          </Box>
          <SearchedMembers
            members={selectedMembers}
            disableForm={disableForm}
          />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <SelectField
                disabled={disabledRelationField || disableForm}
                size="small"
                name={`${formKey}.requesterRelationToPatient`}
                label="Requester relation to patient"
                required={shouldRequestFullInfo}
                options={Object.values(
                  ServiceCaseRequesterPatientRelationEnum
                ).map((relation) => ({
                  label: relation,
                  value: relation,
                }))}
              />
            </Grid>
            {patient.requesterRelationToPatient ===
              ServiceCaseRequesterPatientRelationEnum.Other && (
              <Grid item xs={6}>
                <Field
                  disabled={disabledOtherRelationField || disableForm}
                  size="small"
                  component={TextField}
                  name={`${formKey}.requesterOtherRelationToPatient`}
                  label="Requester relation to patient"
                  required={shouldRequestFullInfo}
                />
              </Grid>
            )}
          </Grid>
          <PatientMedicalInformationSection
            formKey={formKey}
            patient={patient}
            disableForm={disableForm}
          />
          {shouldRequestFullInfo && (
            <div className={classes.fullSpaced}>
              <ExistingNextOfKin
                formKey={formKey}
                nextOfKin={patient.nextOfKin}
                memberships={subscriptionMembers}
                memberNextOfKin={memberNextOfKin}
                patient={patient}
                disabledFields={disabledNextOfKinFields}
                setDisabledNextOfKinFields={setDisabledNextOfKinFields}
                forceDisableFields={disableForm}
              />
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};
