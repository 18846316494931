import cx from "clsx";
import { Page, Document } from "react-pdf";
import { Close } from "@material-ui/icons";
import { SelectProps } from "formik-material-ui";
import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Dialog,
  Select,
  MenuItem,
  Typography,
  makeStyles,
  InputLabel,
  DialogTitle,
  FormControl,
  DialogContent,
} from "@material-ui/core";
import {
  SortOrderEnum,
  DocumentTypeEnum,
  GetSalesDocuments,
  PatientResponseWithContact,
} from "@deep-consulting-solutions/be2-constants";

import Loader from "component/Loader";
import { formatNameToDisplay } from "helpers";
import { documentRequests } from "redux/documents";

type DocumentsType = typeof GetSalesDocuments.Result & {
  src: string | File;
};

interface Props {
  textClassName?: string;
  patient: PatientResponseWithContact;
}

const useStyles = makeStyles(({ palette: p, spacing: s }) => ({
  dialogTitle: {
    display: "flex",
    backgroundColor: "#F6F6F6",
    justifyContent: "space-between",
  },
  titleText: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "24px",
    color: p.primary.main,
  },
  text: {
    cursor: "pointer",
    color: "#2F80ED",
    textTransform: "none",
    textDecoration: "underline",
  },
  dialogContent: {
    padding: s(2),
  },
  document: {
    width: "100%",
    height: "100%",
    maxHeight: "527px",
    objectFit: "contain",

    "& .react-pdf__Page__canvas": {
      width: "100% !important",
      height: "100% !important",
      maxHeight: "527px",
      objectFit: "contain",
    },
  },
}));

const ViewIdentificationDocuments = ({ patient, textClassName }: Props) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fullName = formatNameToDisplay(patient.firstName, patient.lastName);
  const [documents, setDocuments] = useState<DocumentsType[]>([]);
  const [selected, setSelected] = useState<DocumentsType | null>(null);

  const handleSelectChange: SelectProps["onChange"] = useCallback(
    (e) => {
      const document = documents.find(
        (doc) => doc.type === (e.target.value as string)
      );

      if (document) setSelected(document);
    },
    [documents]
  );

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const docs = await documentRequests.fetchDocuments({
          page: 1,
          perPage: 10,
          sortBy: "createdAt",
          owner: patient.contactID,
          order: SortOrderEnum.DESC,
          type: [
            DocumentTypeEnum.PASSPORT,
            DocumentTypeEnum.BAHAMIAN_ID,
            DocumentTypeEnum.US_ENTRY_DOCUMENT,
            DocumentTypeEnum.RENEWAL_CONFIRMATION_DOCUMENT,
          ].join(","),
        });

        const completeDocs = await Promise.all(
          docs.documents.map(async (document) => {
            const url = (await documentRequests.fetchS3DownloadURL(document.id))
              .url;

            const isDocumentAnImage = document.mimeType.includes("image");

            if (isDocumentAnImage) {
              return {
                ...document,
                src: url,
              };
            }

            const response = await fetch(url);
            const blob = await response.blob();
            const file = new File([blob], document.fileName);

            return {
              ...document,
              src: file,
            };
          })
        );

        setDocuments(completeDocs);

        if (completeDocs[0]) {
          setSelected(completeDocs[0]);
        }
      } catch {
        //
      } finally {
        setLoading(false);
      }
    })();
  }, [patient.contactID]);

  return (
    <>
      <Typography
        role="button"
        variant="button"
        onClick={() => setIsOpen(true)}
        className={cx(classes.text, textClassName)}
      >
        View Patient Identification
      </Typography>

      <Dialog open={isOpen} fullWidth>
        <Loader open={loading} absolute />

        <DialogTitle className={classes.dialogTitle} disableTypography>
          <Typography className={classes.titleText}>
            Identification Documents - {fullName}
          </Typography>

          <Close
            style={{ cursor: "pointer" }}
            onClick={() => setIsOpen(false)}
          />
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          {documents.length > 1 ? (
            <FormControl
              fullWidth
              style={{ marginTop: "8px", marginBottom: "16px" }}
            >
              <InputLabel id="document-type-select-label" variant="outlined">
                Document Type
              </InputLabel>

              <Select
                variant="outlined"
                label="Document Type"
                id="document-type-select"
                value={selected?.type || ""}
                onChange={handleSelectChange}
                labelId="document-type-select-label"
              >
                {documents.map((document) => (
                  <MenuItem key={document.id} value={document.type}>
                    {document.type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}

          <Box sx={{ p: 1, boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.12)" }}>
            {selected ? (
              <Box>
                <Box style={{ padding: "8px 16px" }}>
                  <Typography>{selected?.type}</Typography>
                </Box>

                <Box width="100%" maxHeight={527}>
                  {typeof selected.src === "string" ? (
                    <img
                      src={selected.src}
                      className={classes.document}
                      alt={`${selected.type} for ${fullName}`}
                    />
                  ) : (
                    <Document
                      file={selected.src}
                      className={classes.document}
                      loading={<Loader open absolute />}
                    >
                      <Page pageNumber={1} renderAnnotationLayer={false} />
                    </Document>
                  )}
                </Box>
              </Box>
            ) : (
              !loading && (
                <Box style={{ padding: "8px 16px" }}>
                  <Typography variant="body2">
                    No documents to display.
                  </Typography>
                </Box>
              )
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewIdentificationDocuments;
