import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Card, makeStyles, Typography } from "@material-ui/core";
import {
  SocketEventsEnum,
  ServiceCaseResponse,
  GetFetchServiceCases,
  ServiceCaseStatusEnum,
  ServiceCaseStatusGroupEnum,
  ServiceCaseResponseExtended,
  MinimalMedicalDirectorResponse,
  ServiceTypesWithoutMortalRemainsEnum,
} from "@deep-consulting-solutions/be2-constants";
import { Field, FormikProvider, useFormik } from "formik";
import { PatientStatusSnippet } from "pages/FulfilmentDashboard/components/PatientStatusSnippet";
import {
  getPendingMedicalAssessment,
  getSignedInMedicalDirector,
} from "pages/FulfilmentDashboard/Views/MedicalDirector/requests";
import { getClient } from "apis";
import { useElementWithin } from "hooks/useElementOnScreen";
import { useAppSelector } from "redux/store";
import { useSocket } from "hooks";
import { getServiceCase } from "redux/serviceCase/requests";
import { CheckboxWithLabel } from "formik-material-ui";
import MultiSelectWithTags from "../../../../component/atoms/Select";
import Loader from "../../../../component/Loader";
import {
  cleanParamObject,
  getStatusOptions,
  serviceCaseNeedsUpdate,
  shouldUpdateServiceCase,
  sortResponseForMedicalDirectorFilter,
} from "../../helpers";
import { RenderMap } from "./RenderMap";
import {
  GettingMoreLoader,
  RenderCaseStatus,
  StatusTag,
} from "../../components";

const FILTER_PER_PAGE = 50;

const useStyles = makeStyles(() => ({
  container: {
    display: "grid",
    gridTemplateColumns: "auto auto 1fr",
    gap: 16,
  },
  card: {
    padding: 11,
    height: "min-content",
    maxWidth: 500,
    minWidth: 432,
    width: "100%",
  },
  inputContainer: {
    position: "relative",
    display: "grid",
    width: "100%",
    gap: 10,
  },
  inputSplit: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 10,
  },
  spaceBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const statusGroupOptions = [
  ServiceCaseStatusGroupEnum.IN_PROGRESS,
  ServiceCaseStatusGroupEnum.SCHEDULED,
  ServiceCaseStatusGroupEnum.NEW,
];

const assignServiceCaseResponse = ({
  total,
  serviceCases,
  perPage,
  page,
}: {
  serviceCases: ServiceCaseResponseExtended[];
  total: number;
  perPage?: number | undefined;
  page?: number | undefined;
}) => {
  return {
    total,
    serviceCases,
    perPage: perPage || 10,
    page: page || 1,
  };
};

interface SocketEvent {
  serviceCaseId: string;
  modifiedFields: Array<keyof ServiceCaseResponse>;
}

const MedicalDirector = () => {
  const { id: dispatcherId, serviceCaseUpdated } = useAppSelector(
    (state) => state.dispatcher
  );
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [hasTouched, setHasTouched] = useState(false);

  const [dashboardState, setDashboardState] = useState({
    total: 0,
    perPage: 0,
    serviceCases: [] as ServiceCaseResponseExtended[],
    page: 1,
  });

  const [filterState, setFilterState] = useState({
    total: 0,
    data: [] as ServiceCaseResponseExtended[],
    perPage: 10,
    page: 1,
  });

  const handleOnMoreSelection = (selected: string) => {
    if (selected === "edit") {
      // do edit action
    }
  };

  const [gettingMorePM, setGettingMorePM] = useState(false);
  const makePMRequest = useCallback(() => {
    if (dashboardState.serviceCases.length < dashboardState.total) {
      (async () => {
        const newPage = `${dashboardState.page + 1}`;
        setGettingMorePM(true);
        try {
          const res = await getPendingMedicalAssessment({
            page: newPage,
          });
          const data = assignServiceCaseResponse(res.data.data);
          setDashboardState({
            ...data,
            serviceCases: dashboardState.serviceCases.concat(data.serviceCases),
          });
          setGettingMorePM(false);
        } catch (e) {
          setGettingMorePM(false);
        }
      })();
    }
  }, [dashboardState.page, dashboardState.serviceCases, dashboardState.total]);
  const { target } = useElementWithin(makePMRequest, [dashboardState]);

  const [medicalDirector, setMedicalDirector] =
    useState<MinimalMedicalDirectorResponse | null>(null);

  const [gettingMore, setGettingMore] = useState(false);
  const formik = useFormik({
    initialValues: {
      statusGroup: [],
      status: [],
      service: [],
      page: 1,
      assignedToMe: false,
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const params = cleanParamObject({
          sortBy: "createdAt",
          order: "asc",
          status: values.status.join().trim(),
          statusGroup: values.statusGroup.join().trim(),
          service: values.service.join().trim(),
          ...(values.assignedToMe && {
            medicalDirectorIDs: medicalDirector?.id,
          }),
        });
        const res = await getClient(true).get<{
          data: {
            total: number;
            perPage: number;
            serviceCases: ServiceCaseResponseExtended[];
            page: number;
          };
        }>(GetFetchServiceCases.ROUTE, {
          params: { ...params, searchText: "" },
        });
        setSubmitting(false);
        setFilterState(
          sortResponseForMedicalDirectorFilter(
            assignServiceCaseResponse(res.data.data)
          )
        );
      } catch (e) {
        setSubmitting(false);
      } finally {
        setHasTouched(true);
      }
    },
  });

  const makeFilterRequest = useCallback(() => {
    if (filterState.data.length < filterState.total) {
      (async () => {
        setGettingMore(true);
        const params = cleanParamObject({
          sortBy: "createdAt",
          order: "asc",
          status: formik.values.status.join().trim(),
          statusGroup: formik.values.statusGroup.join().trim(),
          service: formik.values.service.join().trim(),
          page: filterState.page + 1,
          perPage: FILTER_PER_PAGE,
        });
        try {
          const res = await getClient(true).get<{
            data: {
              total: number;
              perPage: number;
              serviceCases: ServiceCaseResponseExtended[];
              page: number;
            };
          }>(GetFetchServiceCases.ROUTE, {
            params: { ...params, searchText: "" },
          });
          const values = sortResponseForMedicalDirectorFilter(
            assignServiceCaseResponse(res.data.data)
          );
          setFilterState({
            total: values.total,
            perPage: values.perPage,
            data: filterState.data.concat(values.data),
            page: filterState.page + 1,
          });
          setGettingMore(false);
        } catch (e) {
          setGettingMore(false);
        }
      })();
    }
  }, [
    filterState.data,
    filterState.page,
    filterState.total,
    formik.values.service,
    formik.values.status,
    formik.values.statusGroup,
  ]);
  const { target: filterRef } = useElementWithin(makeFilterRequest, [
    filterState,
  ]);

  const handleUpdate = (id: string) => (data: ServiceCaseResponse) => {
    const cpyDstate = dashboardState.serviceCases.map((el) => {
      if (el.id === id) return { ...el, ...data };
      return el;
    });
    const cpyFState = filterState.data.map((el) => {
      if (el.id === id) return { ...el, ...data };
      return el;
    });

    setDashboardState({
      ...dashboardState,
      serviceCases: cpyDstate,
    });
    setFilterState({
      ...filterState,
      data: cpyFState,
    });
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await getPendingMedicalAssessment();
        const md = await getSignedInMedicalDirector();
        setMedicalDirector(md);

        setLoading(false);
        setDashboardState(assignServiceCaseResponse(res.data.data));
      } catch (e) {
        setLoading(false);
      }
    })();
  }, [dispatcherId, serviceCaseUpdated]);

  const { addHandler } = useSocket("Medical Director");

  const handleNewServiceCase = useCallback(({ serviceCaseId }: SocketEvent) => {
    (async () => {
      const serviceCase = {
        ...(await getServiceCase(serviceCaseId)),
        quotes: [],
        isNew: true,
      };

      if (
        serviceCase.caseStatus ===
        ServiceCaseStatusEnum.PENDING_MEDICAL_ASSESSMENT
      ) {
        setDashboardState((state) => ({
          ...state,
          total: state.total + 1,
          data: [serviceCase as ServiceCaseResponseExtended].concat(
            state.serviceCases
          ),
        }));
      }
    })();
  }, []);

  const handleUpdatedServiceCase = useCallback(
    ({ serviceCaseId, modifiedFields }: SocketEvent) => {
      (async () => {
        const shouldUpdate = shouldUpdateServiceCase(modifiedFields);

        if (!shouldUpdate) return;

        const serviceCase = await getServiceCase(serviceCaseId);

        const mapFunc = (item: ServiceCaseResponseExtended) => {
          if (
            item.id === serviceCaseId &&
            serviceCaseNeedsUpdate(item, serviceCase, modifiedFields)
          ) {
            return { ...item, ...serviceCase };
          }
          return item;
        };

        setDashboardState((state) => ({
          ...state,
          serviceCases: state.serviceCases.map((sc) => mapFunc(sc)),
        }));

        setFilterState((state) => ({
          ...state,
          data: state.data.map((sc) => mapFunc(sc)),
        }));
      })();
    },
    []
  );

  useEffect(() => {
    addHandler(
      SocketEventsEnum.NEW_SERVICE_CASE_NOTIFICATION,
      handleNewServiceCase
    );

    addHandler(
      SocketEventsEnum.UPDATED_SERVICE_CASE_NOTIFICATION,
      handleUpdatedServiceCase
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Loader open={loading} />
      <div className={classes.container}>
        <Box minWidth={0}>
          <Card className={classes.card}>
            <Typography
              color="primary"
              variant="body2"
              style={{ textTransform: "uppercase" }}
            >
              <b>Pending Medical Assessment ({dashboardState.total})</b>
            </Typography>
            {!!dashboardState.total && (
              <Box overflow="auto" px="5px" maxHeight="80vh" mt={4}>
                {dashboardState.serviceCases.map((el, idx) => (
                  <React.Fragment key={el.id}>
                    <PatientStatusSnippet
                      key={el.id}
                      borderHighlight={false}
                      {...el}
                    >
                      <StatusTag status={el.caseStatus} />
                    </PatientStatusSnippet>
                    <div
                      ref={
                        dashboardState.serviceCases.length - 2 === idx
                          ? target
                          : undefined
                      }
                    />
                  </React.Fragment>
                ))}
                <GettingMoreLoader open={gettingMorePM} />
              </Box>
            )}
          </Card>
        </Box>
        <Box minWidth={0}>
          <Card className={classes.card}>
            <FormikProvider value={formik}>
              <div className={classes.inputContainer}>
                <Loader absolute open={formik.isSubmitting} />
                <div className={classes.inputSplit}>
                  <MultiSelectWithTags
                    label="Status Group"
                    name="statusGroup"
                    onChange={formik.handleChange}
                    value={formik.values.statusGroup}
                    options={statusGroupOptions}
                  />
                  <MultiSelectWithTags
                    label="Status"
                    name="status"
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    options={getStatusOptions(formik.values.statusGroup)}
                  />
                </div>
                <MultiSelectWithTags
                  value={formik.values.service}
                  label="Service"
                  name="service"
                  onChange={formik.handleChange}
                  options={Object.values(ServiceTypesWithoutMortalRemainsEnum)}
                />
                <Field
                  type="checkbox"
                  color="primary"
                  name="assignedToMe"
                  component={CheckboxWithLabel}
                  Label={{ label: "Assigned To Me" }}
                />
                <Button
                  type="submit"
                  color="primary"
                  disabled={!formik.dirty}
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  Apply Filters
                </Button>
              </div>
            </FormikProvider>
            {filterState.total ? (
              <Box overflow="auto" px="5px" maxHeight="75vh" mt={2}>
                {filterState.data.map((el, idx) => (
                  <React.Fragment key={el.id}>
                    <PatientStatusSnippet
                      {...el}
                      onMore={handleOnMoreSelection}
                      onUpdate={handleUpdate(el.id)}
                    >
                      <RenderCaseStatus
                        caseStatus={el.caseStatus}
                        overdue={el.overdue}
                      />
                    </PatientStatusSnippet>
                    <div
                      ref={
                        filterState.data.length - 2 === idx
                          ? filterRef
                          : undefined
                      }
                    />
                  </React.Fragment>
                ))}
                <GettingMoreLoader open={gettingMore} />
              </Box>
            ) : (
              !formik.isSubmitting &&
              hasTouched && (
                <Typography
                  align="center"
                  style={{ padding: "30px" }}
                  variant="body2"
                >
                  No Results Found!
                </Typography>
              )
            )}
          </Card>
        </Box>
        <Box height="80vh" minWidth={0}>
          <RenderMap />
        </Box>
      </div>
    </>
  );
};

export default MedicalDirector;
