import { PostHospital } from "@deep-consulting-solutions/be2-constants";

import { crmClient } from "apis";

export const createHospitalReq = async (body: typeof PostHospital.Body) => {
  const res = await crmClient.post<typeof PostHospital.Res>(
    PostHospital.ROUTE,
    body
  );

  return res.data.data;
};
