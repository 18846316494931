import { useSelector } from "react-redux";
import { TextField } from "@material-ui/core";
import { getIn, Field, FormikProps } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { AutocompleteRenderInputParams } from "formik-material-ui-lab";
import { Hospital as IHospital } from "@deep-consulting-solutions/be2-constants";
import {
  Autocomplete,
  FilterOptionsState,
  createFilterOptions,
} from "@material-ui/lab";

import { ddToDMS } from "helpers";
import { useAppDispatch } from "redux/store";
import { hospitalActions, hospitalSelectors } from "redux/hospital";

import { NewMedicalFacilityDialog } from "./NewMedicalFacilityDialog";

interface Hospital extends IHospital {
  inputValue?: string;
}

interface MedicalFacilityFieldProps<T> {
  name: string; // "destinationMedicalFacility"
  fieldName: string; // destination
  disabled?: boolean;
  formikProps: FormikProps<T>;
}

const filter = createFilterOptions<Hospital>();

export function MedicalFacilityField<T extends Record<string, any>>({
  name,
  fieldName,
  disabled = false,
  formikProps: {
    errors,
    values,
    touched,
    handleBlur,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
  },
}: MedicalFacilityFieldProps<T>) {
  const dispatch = useAppDispatch();
  const hospitals = useSelector(hospitalSelectors.getHospitals);

  const [dialogValue, setDialogValue] = useState("");
  const [showNewMedicalFacilityDialog, setShowNewMedicalFacilityDialog] =
    useState(false);

  const fieldError: string | undefined = getIn(errors, name);
  const showFieldError: boolean = getIn(touched, name) && !!fieldError;
  const [value, setValue] = useState<Hospital | null>(
    () => getIn(values, name) || null
  );

  useEffect(() => {
    if (!hospitals.length) {
      dispatch(hospitalActions.fetchHospitals());
    }
  }, [dispatch, hospitals.length]);

  const handleClose = useCallback(() => {
    setValue(null);
    setDialogValue("");
    setShowNewMedicalFacilityDialog(false);
  }, []);

  const selectHospital = useCallback(
    (hospital: Hospital) => {
      const [lat, lng] = hospital.location
        .split(",")
        .map((item) => parseFloat(parseFloat(item).toFixed(7)));

      setFieldValue(name, hospital);
      setFieldValue(fieldName, {
        lat,
        lng,
        dms: ddToDMS(lat, lng),
        address: hospital.address.street || "",
      });
    },
    [name, fieldName, setFieldValue]
  );

  const handleCreateMedicalFacility = useCallback(
    (hospital: Hospital) => {
      setDialogValue("");
      setValue(hospital);
      selectHospital(hospital);
      hospitalActions.addHospital(hospital);
      setShowNewMedicalFacilityDialog(false);
    },
    [selectHospital]
  );

  return (
    <>
      <Field
        freeSolo
        clearOnBlur
        name={name}
        size="small"
        blurOnSelect
        selectOnFocus
        value={value}
        options={hospitals}
        component={Autocomplete}
        disabled={disabled || isSubmitting}
        style={{ width: "217px", height: "54px" }}
        renderOption={(option: Hospital) => option.name}
        onBlur={(e: React.FocusEvent) => {
          setTimeout(() => {
            // delay to ensure the data is saved.

            handleBlur(e);
            setFieldTouched(name, true);
          }, 0);
        }}
        getOptionLabel={(option: Hospital) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        onChange={(event: React.ChangeEvent, newValue: Hospital | string) => {
          if (typeof newValue === "string") {
            setDialogValue(newValue);
            setShowNewMedicalFacilityDialog(true);
          } else if (newValue?.inputValue) {
            setDialogValue(newValue.inputValue);
            setShowNewMedicalFacilityDialog(true);
          } else {
            setValue(newValue);
            selectHospital(newValue);
          }
        }}
        filterOptions={(
          options: Hospital[],
          params: FilterOptionsState<Hospital>
        ) => {
          const filtered = filter(options, params);

          // Suggest the creation of a new value
          if (params.inputValue !== "") {
            filtered.push({
              ...({} as Hospital),
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            variant="outlined"
            error={showFieldError}
            label="Medical Facility"
            helperText={showFieldError ? fieldError : undefined}
          />
        )}
      />

      {showNewMedicalFacilityDialog && (
        <NewMedicalFacilityDialog
          onClose={handleClose}
          inputValue={dialogValue}
          onCreate={handleCreateMedicalFacility}
        />
      )}
    </>
  );
}
