import { deepEqual } from "fast-equals";

export const getIsBothObjectsEqual = (
  oldObject: unknown,
  newObject: unknown
) => {
  try {
    return deepEqual(oldObject, newObject);
  } catch {
    return false;
  }
};
