import React, { FC } from "react";
import { Box } from "@material-ui/core";
import { BoxProps } from "@material-ui/core/Box/Box";
import { DisplayLayout } from "redux/dispatcher";
import { useAppSelector } from "redux/store";

export const DisplayModeLayout: FC<BoxProps> = ({ children, ...rest }) => {
  const { displayLayout } = useAppSelector((state) => state.dispatcher);
  return (
    <Box
      display="grid"
      gridGap="16px"
      {...rest}
      style={{
        gridTemplateColumns:
          displayLayout !== DisplayLayout.vertical ? "1fr" : "",
      }}
    >
      {children}
    </Box>
  );
};
