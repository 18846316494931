import React, { useState, useEffect } from "react";
import { Box, makeStyles, Theme } from "@material-ui/core";
import { useResponsive } from "hooks";
import { displayPrice } from "helpers";
import { StyledTypography } from "component/StyledTypography";
import { StyledTitleAndAmount } from "../StyledTitleAndAmount";

interface StyleProps {
  isDesktop: boolean;
  isMobile: boolean;
  hideDetails: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(
  ({ spacing: s, palette: p }) => ({
    content: {
      display: "flex",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    input: {
      marginTop: s(2),
    },
    mainTitle: ({ isDesktop }) => ({
      fontFamily: "Roboto",
      fontWeight: "normal",
      marginBottom: s(2),
      ...(isDesktop
        ? {}
        : {
            fontSize: 14,
          }),
    }),
    title: {
      fontWeight: "normal",
    },
    mainList: ({ hideDetails }) => ({
      padding: s(0),
      listStyleType: "none",
      display: hideDetails ? "none" : "block",
    }),
    subList: {
      listStyleType: "disc",
      color: p.primary.dark,
    },
    largeAmount: ({ isDesktop }) => ({
      fontSize: isDesktop ? 20 : 14,
    }),
    rule: ({ isDesktop }) => ({
      borderBottom: `1px solid ${p.primary.main}`,
      paddingBottom: s(0),
      margin: s(2, 0, 1, 0),
      ...(isDesktop
        ? {}
        : {
            fontSize: 14,
          }),
    }),
    alert: {
      fontWeight: "normal",
    },
    plans: ({ isDesktop }) =>
      isDesktop
        ? {}
        : {
            fontSize: 16,
          },
    toggleHideButton: {
      color: p.primary.dark,
      background: "none",
      outline: "none",
      border: "none",
      fontSize: 10,
      margin: 0,
      padding: 0,
      display: "flex",
      cursor: "pointer",
      fontWeight: 500,
      alignItems: "center",
    },
    waive: {
      color: p.grey[600],
      textDecoration: "line-through",
    },
  })
);

interface Props {
  breakdown: {
    monthlyFee: number;
    isDirectPayment: boolean;
    recurringPeriod: string;
    recurringPeriodFee: number;
    recurringPeriodFeeVat: number;
    totalRecurringPeriodFee: number;
    subtotal: number;
    subtotalVat: number;
    alreadyCollectedAmount: number;
  };
}

export const Overdue = ({
  breakdown: {
    monthlyFee,
    isDirectPayment,
    recurringPeriod,
    recurringPeriodFee,
    recurringPeriodFeeVat,
    totalRecurringPeriodFee,
    subtotal,
    subtotalVat,
    alreadyCollectedAmount,
  },
}: Props) => {
  const { isDesktop, isMobile } = useResponsive();
  const [hideDetails, setHideDetails] = useState(true);
  const classes = useStyles({ isDesktop, isMobile, hideDetails });

  useEffect(() => {
    if (isDesktop) {
      setHideDetails(false);
    }
  }, [isDesktop]);

  return (
    <>
      <StyledTitleAndAmount
        title="MONTHLY FEE:"
        amount={displayPrice(monthlyFee)}
        amountClassName={classes.largeAmount}
      />

      {isDirectPayment && (
        <StyledTitleAndAmount
          title="RECURRING PAYMENT PERIOD:"
          amount={recurringPeriod}
        />
      )}

      {isDirectPayment && (
        <StyledTitleAndAmount
          title={`${recurringPeriod} FEE:`}
          amount={displayPrice(recurringPeriodFee)}
          amountClassName={classes.largeAmount}
        />
      )}

      <StyledTitleAndAmount
        title="VAT (10%):"
        amount={`+ ${displayPrice(recurringPeriodFeeVat)}`}
      />

      <StyledTitleAndAmount
        title={`TOTAL RECURRING FEE ${
          isDirectPayment ? `every 3 Months` : "(due by salary deduction)"
        }`}
        amount={displayPrice(totalRecurringPeriodFee)}
        amountClassName={classes.largeAmount}
      />

      {!isDirectPayment && (
        <Box mt={2}>
          <StyledTitleAndAmount
            title="ALREADY COLLECTED PAYMENT:"
            amount={`- ${displayPrice(alreadyCollectedAmount)}`}
          />
        </Box>
      )}

      <StyledTypography
        variant="h5"
        color="primary.dark"
        className={classes.rule}
      >
        Your amount due now:
      </StyledTypography>

      {isDirectPayment && (
        <StyledTitleAndAmount
          title="SUB-TOTAL:"
          amount={displayPrice(subtotal)}
          amountClassName={classes.largeAmount}
        />
      )}

      <StyledTitleAndAmount
        title="VAT (10%):"
        amount={`+ ${displayPrice(subtotalVat)}`}
      />
    </>
  );
};
