import { makeStyles } from "@material-ui/core";
import { Formik, Form, FormikConfig } from "formik";
import React, { useCallback, useMemo } from "react";

import { useCall } from "call/hooks";
import Loader from "component/Loader";
import { useAppSelector } from "redux/store";
import { dispatcherSelectors } from "redux/dispatcher";
import {
  patchEmergencyService,
  patchSendForApproval,
  patchSendMedicalAssessment,
  putUpdateServiceCaseStatus,
} from "redux/call/requests";
import {
  ServiceCaseResponse,
  ServiceCaseStatusEnum,
  UserRoleEnum,
} from "@deep-consulting-solutions/be2-constants";

import { useZohoCurrentUser } from "hooks";
import { TabsEnum } from "../types";
import { Patients } from "./Patients";
import { FormActions } from "./components";
import { PatientForm } from "./PatientForm";
import { ServiceStatus } from "./ServiceStatus";
import { MedicalDirector } from "./MedicalDirector";
import { FormValues } from "./types";
import { ServiceToBeRendered } from "./ServiceToBeRendered";
import { NotCoveredConditions } from "./NotCoveredConditions";
import { MedicalProfessionals } from "./MedicalProfessionals";
import { MovePatientsToNewCase } from "./MovePatientsToNewCase";
import {
  geIsAllPatientsCovered,
  getButtonText,
  getInitialValues,
  getIsServiceCaseApproved,
  getIsServiceToBeRenderedEmergencyGroundTransportOrEmergencyMaritimeTransport,
  validationSchema,
} from "./helpers";
import { PatientsMedicalInformation } from "./PatientsMedicalInformation";
import { HandleUpdateFormValuesWithServiceCaseDetailsAfterTabChange } from "../Components";

const useStyles = makeStyles(({ spacing: s }) => ({
  divider: {
    margin: s(2, 0),
  },
  wrapper: {
    padding: s(2, 1),
  },
}));

interface Props {
  activeTab: TabsEnum;
  switchTab: (tab: TabsEnum) => void;
  handleSkipTab: (tab: TabsEnum) => void;
  handleIncludeTab: (tab: TabsEnum) => void;
  handleDisableTab: (tab: TabsEnum) => void;
  setCases: React.Dispatch<React.SetStateAction<ServiceCaseResponse[]>>;
}

export const ServiceFormComponent = ({
  setCases,
  switchTab,
  activeTab,
  handleSkipTab,
  handleIncludeTab,
  handleDisableTab,
}: Props) => {
  const { viewedCall, updateCallServiceCase } = useCall();
  const { currentUser } = useZohoCurrentUser();
  const { isMedicalDirector } = useAppSelector(dispatcherSelectors.roles);

  const goToPlanning = useCallback(
    (skipAssessment = false) => {
      if (skipAssessment) {
        handleSkipTab(TabsEnum.ASSESSMENT);
      } else {
        handleIncludeTab(TabsEnum.ASSESSMENT);
      }
      switchTab(TabsEnum.PLANNING);
    },
    [switchTab, handleSkipTab, handleIncludeTab]
  );

  const goToAssessment = useCallback(() => {
    switchTab(TabsEnum.ASSESSMENT);
  }, [switchTab]);

  const updateServiceCaseStatus = useCallback(
    async (status: ServiceCaseStatusEnum) => {
      if (viewedCall?.updatedServiceCase?.id) {
        await putUpdateServiceCaseStatus(
          viewedCall?.updatedServiceCase.id,
          status
        );
      }
    },
    [viewedCall?.updatedServiceCase?.id]
  );

  const isServiceCaseApproved = useMemo(() => {
    return getIsServiceCaseApproved(
      viewedCall?.updatedServiceCase?.approvalRequests
    );
  }, [viewedCall?.updatedServiceCase?.approvalRequests]);

  const isServiceCaseAccepted = useMemo(() => {
    return getIsServiceCaseApproved(
      viewedCall?.updatedServiceCase?.approvalRequests
    );
  }, [viewedCall?.updatedServiceCase?.approvalRequests]);

  const updateServiceCaseService = useCallback(
    async (values: FormValues) => {
      const payload = {
        service: values.serviceToBeRendered,
        patients: values.patients.map((patient) => ({
          patientID: patient.id,
          ...(patient.subCoverageID
            ? { coverageID: patient.subCoverageID }
            : {}),
        })),
      };
      if (viewedCall?.updatedServiceCase?.id) {
        const res = await patchEmergencyService(
          viewedCall.updatedServiceCase.id,
          payload
        );
        updateCallServiceCase(viewedCall.sessionId, res);
      }
    },
    [
      viewedCall?.updatedServiceCase?.id,
      viewedCall?.sessionId,
      updateCallServiceCase,
    ]
  );

  const handleSendForMedicalAssessment = useCallback(
    async (values: FormValues) => {
      const payload = {
        service: values.serviceToBeRendered,
        medicalDirectorID: values.medicalDirectorId,
        physician: values.physician,
        patients: values.patients.map((patient) => ({
          id: patient.id,
          medicalStatus: patient.medicalStatus,
          ...(patient.subCoverageID
            ? { subCoverageID: patient.subCoverageID }
            : {}),
        })),
      };
      if (viewedCall?.updatedServiceCase?.id) {
        const res = await patchSendMedicalAssessment(
          viewedCall.updatedServiceCase.id,
          payload
        );

        updateCallServiceCase(viewedCall.sessionId, res);

        if (
          currentUser?.profile.name === UserRoleEnum.Administrator ||
          isMedicalDirector
        ) {
          goToAssessment();
        }
      }
    },
    [
      viewedCall?.updatedServiceCase?.id,
      viewedCall?.sessionId,
      updateCallServiceCase,
      goToAssessment,
      isMedicalDirector,
      currentUser?.profile.name,
    ]
  );

  const handleSendForApproval = useCallback(async () => {
    return patchSendForApproval();
  }, []);

  const onFormSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        if (
          viewedCall?.updatedServiceCase?.caseStatus !==
          ServiceCaseStatusEnum.GATHERING_REQUIREMENTS
        ) {
          return goToPlanning();
        }
        const buttonText = getButtonText(
          values.serviceToBeRendered,
          isServiceCaseApproved,
          isServiceCaseAccepted,
          geIsAllPatientsCovered(values.patients)
        );
        if (buttonText === "START PLANNING") {
          if (
            !getIsServiceToBeRenderedEmergencyGroundTransportOrEmergencyMaritimeTransport(
              values.serviceToBeRendered
            )
          ) {
            await updateServiceCaseStatus(
              ServiceCaseStatusEnum.PENDING_PLANNING
            );
            goToPlanning();
          } else {
            await updateServiceCaseService(values);
            goToPlanning(true);
          }
        } else if (buttonText === "SEND FOR MEDICAL ASSESSMENT") {
          await handleSendForMedicalAssessment(values);
        } else if (buttonText === "SEND FOR APPROVAL") {
          await handleSendForApproval();
        } else if (buttonText === "NEXT") {
          await updateServiceCaseStatus(ServiceCaseStatusEnum.QUOTE_REQUESTED);
          goToPlanning();
        }
      } catch (error) {
        //
      } finally {
        setSubmitting(false);
      }
    },
    [
      goToPlanning,
      handleSendForApproval,
      isServiceCaseApproved,
      isServiceCaseAccepted,
      handleSendForMedicalAssessment,
      updateServiceCaseStatus,
      updateServiceCaseService,
      viewedCall?.updatedServiceCase?.caseStatus,
    ]
  );

  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <ServiceStatus
        handleDisableTab={handleDisableTab}
        serviceCase={viewedCall?.updatedServiceCase}
      />

      <MovePatientsToNewCase setCases={setCases} />

      <Formik
        enableReinitialize
        onSubmit={onFormSubmit}
        validationSchema={validationSchema}
        initialValues={getInitialValues(viewedCall?.updatedServiceCase)}
      >
        {({ isSubmitting }) => (
          <Form>
            <HandleUpdateFormValuesWithServiceCaseDetailsAfterTabChange
              valuesCreator={getInitialValues}
              activeTab={activeTab}
              presentTab={TabsEnum.SERVICE}
            />
            <Loader open={isSubmitting} />

            <ServiceToBeRendered />

            <PatientForm />

            <MedicalDirector />

            <Patients />

            <NotCoveredConditions />

            <PatientsMedicalInformation />

            <MedicalProfessionals />

            <FormActions switchTab={switchTab} activeTab={activeTab} />
          </Form>
        )}
      </Formik>
    </div>
  );
};
