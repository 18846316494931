import {
  ServiceCaseResponse,
  ServiceCaseResponseExtended,
} from "@deep-consulting-solutions/be2-constants";
import { serviceCaseNeedsUpdate } from "pages/FulfilmentDashboard/helpers";
import type { PriorityType } from "./index";

export const updateStateForServiceCase = (
  state: PriorityType,
  serviceCase: ServiceCaseResponse | ServiceCaseResponseExtended,
  test: boolean,
  modifiedFields: Array<keyof ServiceCaseResponse>
) => {
  const serviceCaseInState = state.data.find(({ id }) => id === serviceCase.id);

  if (serviceCaseInState && !test) {
    return {
      ...state,
      total: state.total - 1,
      data: state.data.filter(({ id }) => id !== serviceCase.id),
    };
  }

  if (!serviceCaseInState && test) {
    return {
      ...state,
      total: state.total + 1,
      data: [
        {
          ...serviceCase,
          quotes: [],
          isNew: true,
        } as unknown as ServiceCaseResponseExtended,
      ].concat(state.data),
    };
  }

  if (serviceCaseInState && test) {
    return {
      ...state,
      data: state.data.map((item) =>
        item.id === serviceCase.id &&
        serviceCaseNeedsUpdate(serviceCaseInState, serviceCase, modifiedFields)
          ? { ...serviceCase, quotes: [] }
          : item
      ),
    };
  }

  return state;
};
