import {
  CreditCard,
  PostGenerateSalesSubscriptionPaymentOrderId,
  PostVerifySubscriptionPayment,
  SubscriptionPaymentBreakdown,
  SubscriptionPaymentStatusEnum,
} from "@deep-consulting-solutions/be2-constants";
import Loader from "component/Loader";
import { PNPLayout } from "component/PNPLayout";
import React, { useCallback, useEffect, useState } from "react";
import { paymentRequests } from "redux/payment";

interface Props {
  goBack: () => void;
  subscriptionId: string;
  contactID: string;
  handleSuccess: () => Promise<void>;
}

export const Checkout = ({
  goBack,
  subscriptionId,
  contactID,
  handleSuccess,
}: Props) => {
  const [creditCards, setCreditCards] = useState<CreditCard[]>([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [subscriptionPaymentBreakdown, setSubscriptionPaymentBreakdown] =
    useState<SubscriptionPaymentBreakdown | null>(null);

  const submitCreditCard = useCallback(
    async (
      values: typeof PostGenerateSalesSubscriptionPaymentOrderId["Body"]
    ) => {
      const res = await paymentRequests.submitCardDetails(
        subscriptionId,
        values
      );
      return { orderId: res.orderId };
    },
    [subscriptionId]
  );

  const verifyPayment = useCallback(
    async (values: typeof PostVerifySubscriptionPayment["Body"]) => {
      try {
        if (!subscriptionId) {
          return true;
        }
        await paymentRequests.verifySubscriptionPayment(subscriptionId, values);
        return true;
      } catch (error) {
        return false;
      }
    },
    [subscriptionId]
  );

  const onSuccess = useCallback(() => {
    handleSuccess();
    goBack();
  }, [goBack, handleSuccess]);

  const onBackClick = useCallback(() => {
    goBack();
  }, [goBack]);

  const getPaymentBreakdown = useCallback(async () => {
    try {
      if (!subscriptionId) {
        onBackClick();
        return;
      }
      const res = await paymentRequests.getSubscriptionPaymentBreakdown(
        subscriptionId
      );
      setSubscriptionPaymentBreakdown(res);
    } catch (err) {
      //
    }
  }, [onBackClick, subscriptionId]);

  const getCreditCards = useCallback(async () => {
    try {
      if (!contactID) {
        return;
      }
      const res = await paymentRequests.fetchCreditCardsReq(contactID);
      setCreditCards(res);
    } catch (err) {
      //
    }
  }, [contactID]);

  const onPageLoad = useCallback(async () => {
    try {
      setPageLoading(true);
      await Promise.all([getPaymentBreakdown(), getCreditCards()]);
    } catch (error) {
      //
    } finally {
      setPageLoading(false);
    }
  }, [getPaymentBreakdown, getCreditCards]);

  const payWithExistingCard = useCallback(
    async (creditCardId: string, useCardForRecurringPayments: boolean) => {
      await paymentRequests.payWithExistingCard(subscriptionId, {
        creditCardId,
        useCardForRecurringPayments,
      });
      return {};
    },
    [subscriptionId]
  );

  useEffect(() => {
    if (
      subscriptionPaymentBreakdown?.subscripionPaymentStatus ===
      SubscriptionPaymentStatusEnum.PAID
    ) {
      onSuccess();
    }
  }, [subscriptionPaymentBreakdown, onSuccess]);

  useEffect(() => {
    onPageLoad();
  }, [onPageLoad]);

  if (pageLoading) {
    return <Loader open />;
  }
  return (
    <PNPLayout
      subscriptionPaymentBreakdown={subscriptionPaymentBreakdown}
      verifyPayment={verifyPayment}
      paymentFormButtonLabel={`Pay BSD${subscriptionPaymentBreakdown?.grandTotal}`}
      cards={creditCards}
      onSuccess={onSuccess}
      submitCardDetailsRequest={submitCreditCard}
      onBackClick={onBackClick}
      payWithExistingCard={payWithExistingCard}
    />
  );
};
