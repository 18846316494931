import React, { useCallback, useContext, useRef, useState } from "react";
import { DeviceKind, useMediaDeviceContext } from "context/MediaDeviceContext";

interface AudioContextProps {
  startRecord: () => void;
  stopRecord: () => void;
  audioUrl: string;
  state: RecordingState | "playing";
  visualData: number[];
  stopPlay: (el?: HTMLAudioElement) => void;
  play: (kind: DeviceKind) => Promise<void>;
  device: DeviceKind;
  initAudioRecorder: (deviceId?: string | undefined) => Promise<void>;
}

interface EnhancedHTMLMediaElement extends HTMLMediaElement {
  sinkId?: string;
  setSinkId?: (sinkId: string) => Promise<undefined>;
}

const initial = Array.from(new Array(32).fill(0));

export const AudioMediaContext = React.createContext<AudioContextProps | null>(
  null
);
export const useAudioContext = () =>
  useContext(AudioMediaContext) as AudioContextProps;

const AudioProvider: React.FC = ({ children }) => {
  const { selectedDevices } = useMediaDeviceContext();
  const handle = useRef(0);
  // const audioRef = useRef<HTMLAudioElement>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();
  const [recordSource, setRecordSource] =
    useState<MediaStreamAudioSourceNode>();
  const [recordContext, setRecordContext] = useState<AudioContext>();
  const [recordAnalyzer, setRecordAnalyzer] = useState<AnalyserNode>();
  const [audioElement, setAudioElement] = useState<HTMLAudioElement>();
  const [audioUrl, setAudioUrl] = useState("");
  const [visualData, setVisualData] = useState<number[]>(initial);
  const [playing, setPlaying] = useState(false);
  const [device, setDevice] = useState<DeviceKind>("audioinput");
  const [, setAudioChunks] = useState<Blob[]>([]);

  const stopRecord = useCallback(() => {
    if (!mediaRecorder || mediaRecorder.state === "inactive") return;
    mediaRecorder.stop();
    cancelAnimationFrame(handle.current);
    setVisualData(initial);
  }, [mediaRecorder]);

  const initRecordVisualizer = useCallback(
    (stream: MediaStream) => {
      const ctx = recordContext || new AudioContext();
      if (!recordContext) {
        setRecordContext(ctx);
      }
      const source = recordSource || ctx.createMediaStreamSource(stream);
      if (!recordSource) {
        setRecordSource(source);
      }
      const analyser = recordAnalyzer || ctx.createAnalyser();
      if (!recordAnalyzer) {
        setRecordAnalyzer(analyser);
      }
      analyser.fftSize = 64;
      source.connect(analyser);

      const frequencyData = new Uint8Array(analyser.frequencyBinCount);
      return {
        frequencyData,
        ctx,
        source,
        analyser,
      };
    },
    [recordAnalyzer, recordContext, recordSource]
  );

  const startRecord = useCallback(() => {
    if (!mediaRecorder) return;
    setVisualData(initial);
    const init = initRecordVisualizer(mediaRecorder.stream);

    function renderFrame() {
      handle.current = requestAnimationFrame(renderFrame);
      if (init) {
        const { analyser, frequencyData } = init;
        analyser.getByteFrequencyData(frequencyData);
        const data = Array.from(frequencyData);
        setVisualData(data);
      }
    }
    mediaRecorder.start();
    renderFrame();
    setTimeout(() => {
      stopRecord();
    }, 8000);
  }, [initRecordVisualizer, mediaRecorder, stopRecord]);

  const initAudioRecorder = useCallback(async (deviceId?: string) => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: deviceId ? { deviceId } : true,
    });
    setAudioUrl("");
    const recorder = new MediaRecorder(stream);

    recorder.addEventListener("dataavailable", (event) => {
      setAudioChunks((curr) => {
        const chunks = [...curr, event.data];
        const blob = new Blob(chunks, { type: "audio/mpeg" });
        const url = URL.createObjectURL(blob);
        setAudioUrl(url);
        return chunks;
      });
    });
    setMediaRecorder(recorder);
  }, []);

  const stopPlay: AudioContextProps["stopPlay"] = useCallback(
    (element) => {
      const el = element || audioElement;
      if (el) {
        el.pause();
        el.currentTime = 0;
        el.src = "";
        setPlaying(false);
        cancelAnimationFrame(handle.current);
      }
    },
    [audioElement]
  );

  const play: AudioContextProps["play"] = useCallback(
    async (kind) => {
      setDevice(kind);
      const audio = document.createElement("audio") as EnhancedHTMLMediaElement;
      audio.src = audioUrl;
      if (kind.includes("audiooutput") && typeof audio.sinkId !== "undefined") {
        await audio.setSinkId?.(selectedDevices[kind]);
      }
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: selectedDevices[kind] },
      });
      const audioCtx = new AudioContext();
      const analyzer = audioCtx.createAnalyser();
      analyzer.fftSize = 64;
      const source = audioCtx.createMediaStreamSource(stream);
      source.connect(analyzer);
      const frequencyData = new Uint8Array(analyzer.frequencyBinCount);

      function renderFrame() {
        handle.current = requestAnimationFrame(renderFrame);

        analyzer.getByteFrequencyData(frequencyData);
        const data = Array.from(frequencyData);
        setVisualData(data);
      }

      setPlaying(true);
      setAudioElement(audio);
      audio.play();
      renderFrame();
      audio.onended = () => {
        stopPlay(audio);
      };
    },
    [audioUrl, selectedDevices, stopPlay]
  );

  return (
    <AudioMediaContext.Provider
      value={{
        play,
        device,
        audioUrl,
        stopPlay,
        visualData,
        stopRecord,
        startRecord,
        state: playing ? "playing" : mediaRecorder?.state || "inactive",
        initAudioRecorder,
        // attachSelectedDevice,
      }}
    >
      {children}
    </AudioMediaContext.Provider>
  );
};

export default AudioProvider;
