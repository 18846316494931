import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  Theme,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import clsx from "clsx";
import Loader from "component/Loader";
import { CloseOutlined } from "@material-ui/icons";
import { useDialogStyles } from "component/Dialogs/styles";
import {
  GetDispatchers,
  PostAssignServiceCase,
} from "@deep-consulting-solutions/be2-constants";
import { PatientStatusSnippet } from "pages/FulfilmentDashboard/components/PatientStatusSnippet";
import { getClient } from "apis";
import { formatNameToDisplay } from "helpers";
import { notifications } from "services";
import { createTheme } from "@material-ui/core/styles";
import { useElementWithin } from "hooks/useElementOnScreen";
import { getPendingCases } from "component/Dialogs/request";
import { omit } from "lodash";
import { useAppSelector } from "redux/store";
import { ServiceCaseResponseExtended } from "@deep-consulting-solutions/be2-constants/build/types/types";
import { SimpleSelect } from "../atoms/Select";

const useStyle = makeStyles<Theme, { width: number }>(() => ({
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      width: "100%",
      maxWidth: ({ width }) => width,
      borderRadius: "4px 4px 16px 16px",
    },
  },
}));

const btnTheme = createTheme({
  palette: {
    secondary: {
      main: "#CA2027",
      contrastText: "#fff",
    },
  },
});

export const StartEndShift: FC<{
  type?: "end" | "start";
  reAssign?: boolean;
  open: boolean;
  loading?: boolean;
  onClose(): void;
  onActionClick?: () => void;
}> = ({ open, reAssign, onClose, onActionClick, loading, type = "start" }) => {
  const { id: userId } = useAppSelector((state) => state.dispatcher);

  const [data, setData] = useState<ServiceCaseResponseExtended[]>([]);
  const [dataParam, setDataParam] = useState({ total: 0, page: 1 });
  const baseClasses = useDialogStyles();
  const classes = useStyle({ width: reAssign ? 848 : 500 });

  const [dispatchers, setDispatchers] = useState<
    typeof GetDispatchers.Res["data"]
  >({ dispatchers: [], total: 0 });
  const [gettingDispatchers, setGettingDispatchers] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<Record<number, boolean>>({});
  const [values, setValues] = useState<Record<string, string>>({});
  // const [selectedDispatcher, setHasSelected] = useState("");

  const [moreFilterCount, setLoadMoreFilterCount] = useState(6);
  const [loadMore, setLoadMore] = useState(false);

  const fetchMoreTabbedRequest = useCallback(() => {
    if (moreFilterCount < dataParam.total) {
      const newPage = `${(dataParam.page || 1) + 1}`;
      setLoadMore(true);
      (async () => {
        try {
          const cases = await getPendingCases({
            dispatcherIDs: userId,
            page: newPage,
            perPage: "10",
          });
          setLoadMore(false);
          setData((prev) => [...prev, ...cases.data.data.serviceCases]);
          setDataParam(
            omit({ ...cases.data.data, page: +newPage }, ["serviceCases"])
          );
          setLoadMoreFilterCount(moreFilterCount + 10);
        } catch (e) {
          setLoadMore(false);
        }
      })();
    }
  }, [dataParam.page, dataParam.total, moreFilterCount, userId]);

  const { target: filterRef } = useElementWithin(fetchMoreTabbedRequest, [
    data,
    moreFilterCount,
  ]);

  const handleClose = () => {
    if (onClose) onClose();
  };

  const handleAssignCase = useCallback(
    (idx: number, dispatcherId: string) => {
      (async () => {
        try {
          setIsSubmitting((prev) => ({
            ...prev,
            [idx]: true,
          }));

          const id = data[idx].id;

          const res = await getClient(true).post<
            typeof PostAssignServiceCase.Res
          >(PostAssignServiceCase.ROUTE.replace(":id", id || ""), {
            dispatcherId,
          });

          const { serviceCase } = res.data.data;

          notifications.notifySuccess("Done");

          setIsSubmitting((prev) => ({
            ...prev,
            [idx]: false,
          }));

          setData((existingData) =>
            existingData.map((item) =>
              item.id === id ? Object.assign(serviceCase, { quotes: [] }) : item
            )
          );

          setValues((prev) => ({
            ...prev,
            [serviceCase.id]: dispatcherId,
          }));
        } catch (e) {
          setIsSubmitting((prev) => ({
            ...prev,
            [idx]: false,
          }));
        }
      })();
    },
    [data]
  );

  const handleChange = (pos: number) => (e: ChangeEvent<HTMLInputElement>) => {
    handleAssignCase(pos, e.target.value);
  };

  const allCasesHaveBeenReassigned = useMemo(() => {
    const dispatcherIDs = Object.values(values);
    if (!dispatcherIDs.length) return false;
    return dispatcherIDs.reduce((acc, cur) => acc && cur);
  }, [values]);

  const dispatcherOptions = useMemo(
    () =>
      dispatchers.dispatchers.reduce((acc, cur) => {
        if (cur.id !== userId) {
          acc.push({
            label: formatNameToDisplay(cur.firstName, cur.lastName),
            value: cur.id,
          });
        }
        return acc;
      }, [] as { label: string; value: string }[]),
    [userId, dispatchers.dispatchers]
  );

  useEffect(() => {
    if (reAssign) {
      (async () => {
        try {
          setGettingDispatchers(true);
          const res = await getClient(true).get<typeof GetDispatchers.Res>(
            GetDispatchers.ROUTE
          );
          const cases = await getPendingCases({ dispatcherIDs: userId });
          setData(cases.data.data.serviceCases);

          setValues(
            cases.data.data.serviceCases.reduce(
              (acc, cur) => ({ ...acc, [cur.id]: "" }),
              {} as Record<string, string>
            )
          );

          setDataParam({
            total: cases.data.data.total,
            page: cases.data.data.page || 1,
          });
          setGettingDispatchers(false);
          setDispatchers(res.data.data);
        } catch (e) {
          setGettingDispatchers(false);
        }
      })();
    }
  }, [reAssign, userId]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      className={clsx(classes.dialog, baseClasses.dialog)}
    >
      <Loader open={gettingDispatchers || (loading as boolean)} absolute />
      <>
        <div className={baseClasses.dialogTitle}>
          <Typography
            color="primary"
            style={{ fontSize: 20, textTransform: "capitalize" }}
          >
            {`${type} shift`}
          </Typography>
          <IconButton size="small" onClick={handleClose as any}>
            <CloseOutlined style={{ fontSize: 20, color: "#BDBDBD" }} />
          </IconButton>
        </div>
        <DialogContent style={{ padding: 0 }}>
          <Box
            px="16px"
            paddingTop="24px"
            paddingBottom="16px"
            position="relative"
          >
            {reAssign ? (
              <>
                <Loader open={gettingDispatchers} absolute />
                <Typography color="primary" variant="body2">
                  <b style={{ fontSize: 16 }}>You have pending service cases</b>{" "}
                  <br />
                  Please assign them to someone else on shift to be able to end
                  your shift.
                </Typography>
                <Box
                  mt={2}
                  display="grid"
                  gridTemplateColumns="1fr 1fr"
                  gridGap={16}
                  maxHeight="230px"
                  overflow="auto"
                >
                  {data.map((sCase, i) => (
                    <Box>
                      <PatientStatusSnippet
                        {...sCase}
                        onMore={undefined}
                        afterContents={
                          <Box position="relative">
                            <Loader open={isSubmitting[i]} absolute />
                            <SimpleSelect
                              disabled={isSubmitting[i]}
                              name="dispatcher"
                              value={values[sCase.id]}
                              onChange={handleChange(i)}
                              options={dispatcherOptions}
                            />
                          </Box>
                        }
                      />
                      <div
                        ref={moreFilterCount - 2 === i ? filterRef : undefined}
                      />
                    </Box>
                  ))}
                </Box>
                <Box
                  padding={2}
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  position="relative"
                >
                  <Loader open={loadMore} absolute />
                </Box>
              </>
            ) : (
              <Typography color="primary" variant="body2">
                Are you sure you want to {type} your shift?
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: "0px 24px 24px" }}>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="primary"
            type="submit"
          >
            Cancel
          </Button>
          <ThemeProvider theme={btnTheme}>
            <Button
              color={type === "end" ? "secondary" : "primary"}
              type="submit"
              variant="contained"
              onClick={onActionClick}
              disabled={reAssign && !allCasesHaveBeenReassigned}
            >
              {type === "end" ? "end" : "yes"}
            </Button>
          </ThemeProvider>
        </DialogActions>
      </>
    </Dialog>
  );
};
