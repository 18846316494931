import {
  GetDispatcherActiveCalls,
  ServiceCaseCallSessionStatusEnum,
  ServiceCaseRequestTypeEnum,
} from "@deep-consulting-solutions/be2-constants";
import OpenTok from "@opentok/client";
import { getType } from "mime";
import { Call } from "./types";

export const mapActiveCallsResToCallType = (
  res: typeof GetDispatcherActiveCalls.Res.data,
  storedCalls: Call[]
): {
  callsToHold: Call[];
  callsToEnd: Call[];
  activeCall?: Call;
  callsRinging: Call[];
  storedCallsDiscardedFromServer: Call[];
} => {
  const mappedCalls = res.activeCalls.map((r) => {
    const storedCall = storedCalls.find((s) => s.sessionId === r.sessionId);
    return {
      ...storedCall,
      isEmergency:
        r.serviceCase.requestType === ServiceCaseRequestTypeEnum.EMERGENCY,
      fullName: `${r.serviceCase.requester?.firstName} ${r.serviceCase.requester?.lastName}`,
      firstName: r.serviceCase.requester?.firstName || "",
      lastName: r.serviceCase.requester?.lastName || "",
      requestOrigin: r.serviceCase.requestOrigin,
      id: r.serviceCase.id,
      isOnHold: r.status === ServiceCaseCallSessionStatusEnum.ON_HOLD,
      isEnded: r.status === ServiceCaseCallSessionStatusEnum.ENDED,
      isUnanswered: r.status === ServiceCaseCallSessionStatusEnum.UNANSWERED,
      isCut: r.status === ServiceCaseCallSessionStatusEnum.CUT,
      isInProgress: r.status === ServiceCaseCallSessionStatusEnum.IN_PROGRESS,
      requestType: r.serviceCase.requestType,
      timeOfPickUp: r.createdAt,
      isRinging: r.status === ServiceCaseCallSessionStatusEnum.CREATED,
      updatedServiceCase: r.serviceCase,
      sessionId: r.sessionId,
      dispatcherIds: [],
    };
  });

  const callsRinging = mappedCalls.filter((r) => {
    return r.isRinging;
  }); // add to store and make ring

  const callsNotRinging = mappedCalls.filter((r) => {
    return !r.isRinging;
  });

  const [activeCall, ...callsNotRingingWithTokSession] = callsNotRinging.filter(
    (r) => {
      return !!r.token; // take one active and hold others
    }
  );

  const callsNotRingingWithoutOpenTokSession = mappedCalls.filter((r) => {
    return !r.token; // end all
  });

  const storedCallsDiscardedFromServer = storedCalls.filter(
    (call) => !res.activeCalls.find((c) => c.sessionId === call.sessionId)
  );

  return {
    callsToHold: callsNotRingingWithTokSession,
    callsToEnd: callsNotRingingWithoutOpenTokSession,
    activeCall,
    callsRinging,
    storedCallsDiscardedFromServer,
  };
};

export const createOpenTokSession = async ({
  sessionId,
  token,
  apiKey,
}: {
  sessionId: string;
  token: string;
  apiKey?: string;
}) => {
  try {
    const openTokSession = OpenTok.initSession(
      apiKey || process.env.REACT_APP_OPEN_TOK_API_KEY || "",
      sessionId
    );

    const openTokPublisher = OpenTok.initPublisher(
      `publisher-${sessionId}`,
      {
        insertMode: "append",
        width: "100%",
        height: "100%",
        publishAudio: false,
        publishVideo: false,
      },
      () => {
        //
      }
    );
    await new Promise((resolve, reject) =>
      openTokSession.connect(token, (err) => {
        if (err) {
          reject(err);
        }
        resolve(true);
      })
    );

    return { session: openTokSession, publisher: openTokPublisher };
  } catch (err) {
    return undefined;
  }
};

export const fileToBase64 = async (file: File) => {
  try {
    return await new Promise<string | ArrayBuffer | null>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  } catch {
    return null;
  }
};

export const getFileType = (file: File | undefined | null) => {
  if (!file) {
    return "";
  }

  return file.type || getType(file.name) || "application/octet-stream"; // use general purpose mime type
};
