import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { EndCallDialog, PickCallDialog } from "call/components";
import { useSocket } from "hooks/useSocket";
import {
  ChatAttachment,
  SocketEventsEnum,
  ServiceCaseRequestTypeEnum,
  PostSendDispactherSessionChat,
  CallSocketNotificationResponses,
  Chat,
  PostMessageRequestBody,
  ServiceCaseResponse,
  PatchInitialAssessment,
  ServiceCaseSocketNotificationResponses,
  ChatSenderType,
  SessionTokenAppEnum,
} from "@deep-consulting-solutions/be2-constants";
import EmergencyIncomingCallSound from "media/emergency-call.mp3";
import IncomingCallSound from "media/call.mp3";
import UnansweredCallSound from "media/unanswered-call.mp3";
import CheckInSound from "media/check-in.mp3";
import {
  postAnswerCallSession,
  postHoldCall,
  postResumeCall,
  postEndCallByDispatcher,
  postSendDispactherSessionChat,
  fetchS3SignedUrlForUpload,
  uploadFileToS3,
  getActiveCalls,
  getServiceCase,
} from "redux/call/requests";
import Loader from "component/Loader";
import { dispatcherSelectors } from "redux/dispatcher";
import { useAppDispatch, useAppSelector } from "redux/store";
import {
  createOpenTokSession,
  fileToBase64,
  getFileType,
  mapActiveCallsResToCallType,
} from "call/helpers";
import { callActions, callSelectors } from "redux/call";
import { ddToDMS, getENText } from "helpers";
import { notifications } from "services";
import { HOLD_AND_RESUME_CALL_DELAY } from "call/constants";
import { CallContext } from "./context";
import {
  Call,
  CallLayoutEnum,
  CustomGeoCodeResult,
  CallContextInterface,
} from "../types";

export type SoundVariant = "emergency" | "incoming" | "check-in" | "unanswered";

export const CallProvider: FC = ({ children }) => {
  const callSelector = useAppSelector(callSelectors.callState);
  const [calls, setCalls] = useState<Call[]>([]);
  const [cutCalls, setCutCalls] = useState<Call[]>([]);
  const [loading, setLoading] = useState(0);
  const [viewedCall, setViewedCall] = useState<string | undefined>();
  const [activeCallSession, setActiveCallSession] = useState("");
  const [endCallSessionRequest, setEndCallSessionRequest] = useState("");
  const [pickCallRequestId, setPickCallRequestId] = useState("");
  const [chatOpen, setChatOpen] = useState(false);
  const [stopVideo, setStopVideo] = useState(false);
  const messagesFromStore = useAppSelector(callSelectors.chats);
  const [chatMessages, setChatMessages] = useState<Record<string, Chat[]>>(
    messagesFromStore || {}
  );
  const [layout, setLayout] = useState<CallLayoutEnum>(CallLayoutEnum.FORM_MAP);
  const [mutedMicCalls, setMutedMicCalls] = useState<Record<string, boolean>>(
    {}
  );
  const [soundInterval, setSoundInterval] = useState<
    NodeJS.Timer | undefined
  >();
  const [holdAndResumeCallDelay, setHoldAndResumeCallDelay] = useState<
    Record<string, number>
  >({});
  const dispatcherId = useAppSelector(dispatcherSelectors.id);
  const dispatcherRoles = useAppSelector(dispatcherSelectors.roles);

  const incomingCallSound = useRef<HTMLAudioElement | null>(null);
  const emergencyIncomingCallSound = useRef<HTMLAudioElement | null>(null);
  const unansweredCallSound = useRef<HTMLAudioElement | null>(null);
  const checkInSound = useRef<HTMLAudioElement | null>(null);
  const dispatch = useAppDispatch();
  const { addHandler } = useSocket("Call Provider");
  const [openTokSessions, setOpenTokSessions] = useState<
    CallContextInterface["openTokSessions"]
  >({});

  /**
   * MANAGE NOTIFICATION SOUNDS
   */

  const getCurrentSoundPlaying = useCallback(() => {
    if (!incomingCallSound.current?.paused) {
      return "incoming";
    }

    if (!emergencyIncomingCallSound.current?.paused) {
      return "emergency";
    }

    if (!unansweredCallSound.current?.paused) {
      return "unanswered";
    }

    if (!checkInSound.current?.paused) {
      return "check-in";
    }
  }, []);

  const getAudioElement = useCallback((sound: SoundVariant) => {
    if (sound === "incoming") {
      return incomingCallSound.current;
    }

    if (sound === "emergency") {
      return emergencyIncomingCallSound.current;
    }

    if (sound === "unanswered") {
      return unansweredCallSound.current;
    }

    if (sound === "check-in") {
      return checkInSound.current;
    }
  }, []);

  const getAudioElements = useCallback(() => {
    return [
      incomingCallSound.current,
      emergencyIncomingCallSound.current,
      unansweredCallSound.current,
      checkInSound.current,
    ];
  }, []);

  const stopSound = useCallback(() => {
    const audioElements = getAudioElements();
    audioElements.forEach((audioElement) => {
      if (!audioElement) {
        return;
      }
      audioElement.pause();
      audioElement.currentTime = 0;
      setSoundInterval((current) => {
        clearInterval(current);
        return undefined;
      });
    });
  }, [getAudioElements]);

  const playSound = useCallback(
    async (sound: SoundVariant) => {
      const currentSoundPlaying = getCurrentSoundPlaying();
      if (currentSoundPlaying === sound) {
        return;
      }
      stopSound();

      const audioElement = getAudioElement(sound);
      if (!audioElement) {
        return;
      }
      await audioElement.play();
      setSoundInterval(
        setInterval(() => {
          audioElement.play();
        }, audioElement.duration * 1000 + 1000)
      );
    },
    [stopSound, getAudioElement, getCurrentSoundPlaying]
  );

  /**
   * MESSAGES
   */

  const handleReceivedPrivateMessage = useCallback((messageString: string) => {
    try {
      const message = JSON.parse(messageString);

      if (
        message.signalType === "private-message" &&
        message.sender !== "admin"
      ) {
        const messageBody: Chat = {
          id: Math.random().toString(),
          time: new Date().toISOString(),
          sender: "user" as ChatSenderType,
          message: message.message,
          type: message.type,
          sessionId: message.sessionId,
          attachments: (
            message.attachments as Chat["attachments"] & { id: string }
          )?.map((attachment) => ({
            ...attachment,
            attachmentId: (attachment as unknown as { id: string }).id,
            chatId: message.chatId,
            sessionId: message.sessionId,
          })),
          contact: message.contact,
        };
        setChatMessages((current) => ({
          ...current,
          [message.sessionId]: current[message.sessionId]?.concat([
            messageBody,
          ]) || [messageBody],
        }));
      }
    } catch (err) {
      // ? Should notify mobile of failure to send proper message body
    }
  }, []);

  const sendPrivateMessage = useCallback(
    async (call: Call, msg: Chat & { caseId?: string; chatId?: string }) => {
      try {
        const message = {
          ...msg,
          type: msg.type === "media" ? "image" : msg.type,
          attachments: msg.attachments?.map(
            ({ fileName, s3Key, mimeType, size, attachmentId, ...rest }) => ({
              fileName,
              s3Key,
              mimeType,
              size,
              attachmentId,
              id: (rest as any).id,
            })
          ),
        };

        await new Promise((resolve, reject) =>
          openTokSessions[call.sessionId]?.session.signal(
            {
              data: JSON.stringify({
                ...message,
                signalType: "private-message",
              }),
            },
            (err) => {
              if (err) {
                reject(err);
              }
              resolve(true);
            }
          )
        );
      } catch (err) {
        // ? Handle failure in sending message
      }
    },
    [openTokSessions]
  );

  const postMessageRequest = useCallback(
    async (message: PostMessageRequestBody) => {
      const payload: typeof PostSendDispactherSessionChat.Body = {
        message: message.message,
      };

      try {
        if (viewedCall) {
          const call = calls.find((c) => c.sessionId === viewedCall)!;

          let attachments:
            | (ChatAttachment & {
                localUrl?: string;
                base64?: string | ArrayBuffer | null;
                size?: number;
              })[]
            | undefined;

          const mimeType = getFileType(message.file);

          if (message.file) {
            const base64 = await fileToBase64(message.file);
            attachments = [
              {
                fileName: message.file.name,
                mimeType,
                localUrl: URL.createObjectURL(message.file),
                s3Key: "",
                base64,
                size: message.file.size,
              },
            ];
          }

          let chat: Chat = {
            time: new Date().toISOString(),
            id: `${Math.random().toString()} ${viewedCall}`,
            sender: "admin",
            message: message.message,
            sessionId: viewedCall,
            type: message.type,
            attachments: attachments as unknown as Chat["attachments"],
            isSending: true,
          };
          setChatMessages((current) => ({
            ...current,
            [chat.sessionId]: current[chat.sessionId]?.concat(chat) || [chat],
          }));

          if (message.file) {
            try {
              if (!mimeType) {
                throw new Error("Unable to send this file type");
              }
              const s3Data = await fetchS3SignedUrlForUpload(
                mimeType,
                message.file.name
              );

              await uploadFileToS3(message.file, s3Data.url);

              payload.attachments = attachments?.map((attachment) => ({
                fileName: attachment.fileName,
                s3Key: s3Data.key,
                mimeType: attachment.mimeType,
              }));
              chat = {
                ...chat,
                attachments: chat.attachments?.map((attachment) => ({
                  ...attachment,
                  fileName: attachment.fileName,
                  s3Key: s3Data.key,
                  mimeType: attachment.mimeType,
                })),
              };
            } catch (err) {
              notifications.notifyInfo(
                `Unable to send ${message.file.name}. File type is not supported.`
              );
              setChatMessages((current) => {
                const sessionChats = current[chat.sessionId];
                return {
                  ...current,
                  [chat.sessionId]: sessionChats.filter(
                    (c) => c.id !== chat.id
                  ),
                };
              });
              throw new Error((err as Error).message);
            }
          }

          const res = await postSendDispactherSessionChat(
            call.id!,
            viewedCall,
            payload
          );

          const updatedCallChat = res.updatedCallChat as Chat;

          const mappedCallChat = {
            ...chat,
            attachments: updatedCallChat.attachments?.map((attachment) => ({
              ...attachment,
              attachmentId: (attachment as any).id,
              id: (attachment as any).id,
            })),
            chatId: updatedCallChat.id,
            caseId: call.updatedServiceCase!.id,
          } as Chat & {
            caseId?: string;
            chatId?: string;
          };

          await sendPrivateMessage(call, mappedCallChat);
          setChatMessages((current) => {
            const sessionChats = current[chat.sessionId];
            return {
              ...current,
              [chat.sessionId]: sessionChats.map((c) =>
                c.id === chat.id ? { ...chat, isSending: false } : c
              ),
            };
          });
        }
      } catch (error) {
        //
      }
    },
    [calls, viewedCall, sendPrivateMessage]
  );

  const handleCreateOpenTokSession = useCallback(
    async ({
      sessionId,
      token,
      apiKey,
    }: {
      sessionId: string;
      token: string;
      apiKey?: string;
    }) => {
      if (openTokSessions[sessionId]) {
        return true;
      }
      const oT = await createOpenTokSession({
        token,
        sessionId,
        apiKey,
      });
      if (oT) {
        oT.session.on("signal", (event) => {
          if (event.data) {
            handleReceivedPrivateMessage(event.data);
          }
        });
        setOpenTokSessions((c) => ({
          ...c,
          [sessionId]: oT,
        }));
      }

      return oT;
    },
    [handleReceivedPrivateMessage, openTokSessions]
  );

  /**
   * MANAGE CALLS
   */
  const createEndCallRequest = useCallback(() => {
    setEndCallSessionRequest(activeCallSession);
  }, [activeCallSession]);

  const handleEndedCall = useCallback(
    (endedCall: typeof CallSocketNotificationResponses.Ended) => {
      new Promise((resolve: (calls: Call[]) => void) => {
        setEndCallSessionRequest((selectedSession) => {
          if (selectedSession === endedCall.sessionId) return "";
          return selectedSession;
        });

        setCalls((c) => {
          const otherCalls = c.filter(
            (cl) => cl.sessionId !== endedCall.sessionId
          );

          resolve(otherCalls);
          return otherCalls;
        });
      }).then((otherCalls) => {
        const otherCallsNotRinging = otherCalls.filter((c) => !c.isRinging);

        setViewedCall((current) => {
          if (current === endedCall.sessionId) {
            if (otherCallsNotRinging.length > 0) {
              return otherCallsNotRinging[0].sessionId;
            }
            return "";
          }
          return current;
        });
        setActiveCallSession((current) => {
          if (current === endedCall.sessionId) {
            if (otherCallsNotRinging.length > 0) {
              return otherCallsNotRinging[0].sessionId;
            }
            return "";
          }
          return current;
        });
      });
    },
    []
  );

  const completeEndCallRequest = useCallback(
    async (sessionId: string = endCallSessionRequest) => {
      setLoading((c) => c + 1);
      try {
        const call = calls.find((cl) => cl.sessionId === sessionId)!;
        if (!call?.updatedServiceCase?.id) {
          return;
        }
        await postEndCallByDispatcher(
          call.assignedServiceCaseId || call.updatedServiceCase.id,
          sessionId
        );
        setEndCallSessionRequest("");
        setViewedCall((current) => {
          if (current === sessionId) {
            return "";
          }
          return current;
        });
        setActiveCallSession((current) => {
          if (current === sessionId) {
            return "";
          }
          return current;
        });
        handleEndedCall({
          sessionId,
          by: SessionTokenAppEnum.DISPATCHER,
          id: call.id || "",
        });
      } catch {
        //
      } finally {
        setLoading((c) => c - 1);
      }
    },
    [endCallSessionRequest, calls, handleEndedCall]
  );

  const cancelEndCallRequest = useCallback(() => {
    setEndCallSessionRequest("");
  }, []);

  const handleHoldAndResumeCallDelay = useCallback(
    (sessionId: string) => {
      if (holdAndResumeCallDelay[sessionId]) {
        const now = Date.now();
        if (holdAndResumeCallDelay[sessionId] >= now) {
          notifications.notifyInfo(getENText("holdAndResumeCallDelay.info"));
          return false;
        }
      }
      return true;
    },
    [holdAndResumeCallDelay]
  );

  const putCallOnHold = useCallback(
    async (sessionId: string = activeCallSession) => {
      setLoading((c) => c + 1);
      try {
        if (!sessionId) {
          return;
        }
        if (!handleHoldAndResumeCallDelay(sessionId)) {
          return;
        }
        const call = calls.find((cl) => cl.sessionId === sessionId)!;
        await postHoldCall(call.id!, sessionId);
      } catch {
        //
      } finally {
        setLoading((c) => c - 1);
      }
    },
    [activeCallSession, calls, handleHoldAndResumeCallDelay]
  );
  const viewCall = useCallback((sessionId: string) => {
    setViewedCall(sessionId);
  }, []);

  const resumeCallFromHold = useCallback(
    async (sessionId: string) => {
      try {
        setLoading((c) => c + 1);

        if (!handleHoldAndResumeCallDelay(sessionId)) {
          return;
        }

        if (activeCallSession !== sessionId) {
          await putCallOnHold();
        }
        const call = calls.find((cl) => cl.sessionId === sessionId)!;
        viewCall(sessionId);
        setActiveCallSession(sessionId);
        await postResumeCall(call.id!, sessionId);
      } catch {
        //
      } finally {
        setLoading((c) => c - 1);
      }
    },
    [
      calls,
      putCallOnHold,
      activeCallSession,
      handleHoldAndResumeCallDelay,
      viewCall,
    ]
  );

  const cancelPickCallRequest = useCallback(() => {
    setPickCallRequestId("");
  }, []);

  const completePickCallRequest = useCallback(
    async (sessionId: string = pickCallRequestId) => {
      try {
        setLoading((c) => c + 1);
        if (activeCallSession) {
          await putCallOnHold();
        }
        const res = await postAnswerCallSession(sessionId);

        setCalls((current) => {
          return current.map((c) =>
            c.sessionId === sessionId
              ? {
                  ...c,
                  ...res,
                }
              : c
          );
        });
        setPickCallRequestId("");
        setActiveCallSession(sessionId);
        viewCall(sessionId);
      } catch {
        //
      } finally {
        setLoading((c) => c - 1);
      }
    },
    [pickCallRequestId, activeCallSession, putCallOnHold, viewCall]
  );

  const updateCallRequesterGeoLocation = useCallback(
    (
      sessionId: string,
      requesterGeoLocation: google.maps.GeocoderResult,
      isDefaultLocation = false
    ) => {
      let locationObject: google.maps.LatLngLiteral = {
        lat: 0,
        lng: 0,
      };

      if (
        requesterGeoLocation.geometry.location instanceof google.maps.LatLng
      ) {
        locationObject = requesterGeoLocation.geometry.location.toJSON();
      } else {
        locationObject = requesterGeoLocation.geometry.location;
      }

      const { lat, lng } = locationObject;
      const dms = ddToDMS(lat, lng);

      setCalls((current) =>
        current.map((c) =>
          c.sessionId === sessionId
            ? {
                ...c,
                updatedServiceCase: {
                  ...((c.updatedServiceCase || {}) as ServiceCaseResponse),
                  requesterLocation: dms,
                },
                requesterGeoLocation: {
                  ...((c.requesterGeoLocation || {}) as CustomGeoCodeResult),
                  address_components: requesterGeoLocation.address_components,
                  plus_code: requesterGeoLocation.plus_code,
                  geometry: {
                    location: locationObject,
                  },
                  isDefaultLocation,
                },
              }
            : c
        )
      );
    },
    []
  );

  const updateCallServiceCase = useCallback(
    (sessionId: string, serviceCase: ServiceCaseResponse) => {
      setCalls((current) =>
        current.map((c) =>
          c.sessionId === sessionId
            ? {
                ...c,
                ...(c.updatedServiceCase
                  ? {
                      updatedServiceCase: {
                        ...c.updatedServiceCase,
                        ...serviceCase,
                      },
                    }
                  : {}),
              }
            : c
        )
      );
    },
    []
  );

  const updateCallRequesterInitialAssessment = useCallback(
    (
      sessionId: string,
      initialAssessmentResponse: typeof PatchInitialAssessment.Res.data
    ) => {
      setCalls((current) =>
        current.map((c) =>
          c.sessionId === sessionId
            ? {
                ...c,
                ...(c.updatedServiceCase
                  ? {
                      updatedServiceCase: {
                        ...c.updatedServiceCase,
                        ...initialAssessmentResponse,
                      },
                    }
                  : {}),
              }
            : c
        )
      );
    },
    []
  );

  const createPickCallRequest = useCallback(
    (sessionId: string) => {
      const call = calls.find((c) => c.sessionId === sessionId);
      if (!call) {
        return;
      }
      if (!activeCallSession) {
        completePickCallRequest(sessionId);
        return;
      }
      setPickCallRequestId(sessionId);
    },
    [calls, completePickCallRequest, activeCallSession]
  );

  const removeCutCall = useCallback((sessionId: string) => {
    setCutCalls((current) =>
      current.filter((cl) => cl.sessionId !== sessionId)
    );
  }, []);

  /**
   * MANAGE LAYOUT
   */
  const handleSwitchLayout = useCallback((v?: CallLayoutEnum) => {
    if (v !== undefined) {
      setLayout(v);
    } else {
      setLayout((c) => {
        const ans =
          CallLayoutEnum[CallLayoutEnum[c + 1] as unknown as number] ||
          CallLayoutEnum.FORM_MAP;

        return ans as unknown as CallLayoutEnum;
      });
    }
  }, []);

  const handleOpenChat = useCallback((v?: boolean) => {
    if (v !== undefined) {
      setChatOpen(v);
    } else {
      setChatOpen((c) => !c);
    }
  }, []);

  const handleOpenVideo = useCallback((v?: boolean) => {
    if (v !== undefined) {
      setStopVideo(v);
    } else {
      setStopVideo((c) => !c);
    }
  }, []);

  useEffect(() => {
    /**
     * Sync layout with present state of chat and video
     */

    /**
     * ============ CHAT =================
     */
    if (chatOpen) {
      if (layout === CallLayoutEnum.FORM_MAP) {
        handleSwitchLayout(CallLayoutEnum.FORM_MAP_CHAT);
      }

      if (layout === CallLayoutEnum.FORM_MAP_VIDEO) {
        handleSwitchLayout(CallLayoutEnum.FORM_VIDEO_MAP_CHAT);
      }
      if (layout === CallLayoutEnum.MAP_FORM_VIDEO) {
        handleSwitchLayout(CallLayoutEnum.MAP_FORM_VIDEO_CHAT);
      }
    }

    if (!chatOpen) {
      if (layout === CallLayoutEnum.FORM_MAP_CHAT) {
        handleSwitchLayout(CallLayoutEnum.FORM_MAP);
      }
      if (layout === CallLayoutEnum.FORM_VIDEO_MAP_CHAT) {
        handleSwitchLayout(CallLayoutEnum.FORM_MAP_VIDEO);
      }
      if (layout === CallLayoutEnum.MAP_FORM_CHAT) {
        handleSwitchLayout(CallLayoutEnum.FORM_MAP);
      }
      if (layout === CallLayoutEnum.MAP_FORM_VIDEO_CHAT) {
        handleSwitchLayout(CallLayoutEnum.MAP_FORM_VIDEO);
      }
    }

    /**
     * ============ VIDEO =================
     */

    if (stopVideo) {
      if (layout === CallLayoutEnum.FORM_MAP) {
        handleSwitchLayout(CallLayoutEnum.FORM_MAP_VIDEO);
      }

      if (layout === CallLayoutEnum.FORM_MAP_CHAT) {
        handleSwitchLayout(CallLayoutEnum.FORM_VIDEO_MAP_CHAT);
      }
      if (layout === CallLayoutEnum.MAP_FORM_CHAT) {
        handleSwitchLayout(CallLayoutEnum.MAP_FORM_VIDEO_CHAT);
      }
    }

    if (!stopVideo) {
      if (layout === CallLayoutEnum.FORM_MAP_VIDEO) {
        handleSwitchLayout(CallLayoutEnum.FORM_MAP);
      }
      if (layout === CallLayoutEnum.FORM_VIDEO_MAP_CHAT) {
        handleSwitchLayout(CallLayoutEnum.FORM_MAP_CHAT);
      }
      if (layout === CallLayoutEnum.MAP_FORM_VIDEO) {
        handleSwitchLayout(CallLayoutEnum.FORM_MAP);
      }
      if (layout === CallLayoutEnum.MAP_FORM_VIDEO_CHAT) {
        handleSwitchLayout(CallLayoutEnum.MAP_FORM_CHAT);
      }
    }
  }, [layout, chatOpen, handleSwitchLayout, stopVideo]);

  /**
   * Add handlers to socket
   */
  const handleIncomingCall = useCallback(
    (newCall: typeof CallSocketNotificationResponses.NewServiceCall) => {
      try {
        if (dispatcherId && newCall.dispatcherIds.includes(dispatcherId)) {
          const call = {
            ...newCall,
            fullName: `${newCall.firstName} ${newCall.lastName}`,
            isEmergency:
              newCall.requestType === ServiceCaseRequestTypeEnum.EMERGENCY,
          };

          setCalls((current) => {
            const existing = current.find(
              (c) => c.sessionId === call.sessionId
            );

            if (existing) {
              return current.map((c) =>
                c.sessionId === call.sessionId ? existing : c
              );
            }
            return current.concat({ ...call, isRinging: true });
          });
        }
      } catch {
        //
      }
    },
    [dispatcherId]
  );

  const handleAnsweredCall = useCallback(
    (answeredCall: typeof CallSocketNotificationResponses.Answered) => {
      if (!(dispatcherId && answeredCall.dispatcherId === dispatcherId)) {
        setCalls((current) => {
          return current.filter(
            (cc) => cc.sessionId !== answeredCall.sessionId
          );
        });
      } else {
        setTimeout(() => {
          setCalls((current) => {
            const call = current.find(
              (cc) => cc.sessionId === answeredCall.sessionId && cc.isRinging
            );

            if (call?.updatedServiceCase) {
              /**
               * * Call was picked on this device
               */
              return current.map((cc) =>
                cc.sessionId === answeredCall.sessionId
                  ? {
                      ...cc,
                      isRinging: false,
                      timeOfPickUp: new Date().toISOString(),
                      id: answeredCall.serviceCaseID,
                    }
                  : cc
              );
            }
            /**
             * * Call was not picked on this device
             */
            return current.filter((cc) => cc.sessionId !== call?.sessionId);
          });
        }, 2000);
      }
    },
    [dispatcherId]
  );

  const handleCutCall = useCallback(
    (cutCall: typeof CallSocketNotificationResponses.Cut) => {
      new Promise((resolve: (calls: Call[]) => void) => {
        setCalls((c) => {
          const otherCalls = c.filter((cl) => {
            if (cl.sessionId !== cutCall.sessionId) {
              return true;
            }
            setCutCalls((current) => Array.from(new Set(current).add(cl)));
            return false;
          });
          resolve(otherCalls);
          return otherCalls;
        });
      }).then((otherCalls) => {
        setViewedCall((current) => {
          if (current === cutCall.sessionId) {
            if (otherCalls.length > 0) {
              return otherCalls[0].sessionId;
            }
            return "";
          }
          return current;
        });
        setActiveCallSession((current) => {
          if (current === cutCall.sessionId) {
            if (otherCalls.length > 0) {
              return otherCalls[0].sessionId;
            }
            return "";
          }
          return current;
        });
      });
    },
    []
  );

  const handleSetHoldAndResumeCallDelay = useCallback((sessionID: string) => {
    const now = Date.now();
    setHoldAndResumeCallDelay((current) => ({
      ...current,
      [sessionID]: now + HOLD_AND_RESUME_CALL_DELAY,
    }));
  }, []);

  const handleHoldCall = useCallback(
    (heldCall: typeof CallSocketNotificationResponses.OnHold) => {
      setCalls((current) => {
        const call = current.find((cl) => cl.sessionId === heldCall.sessionId);
        if (call) {
          return current.map((cc) =>
            cc?.sessionId === heldCall.sessionId
              ? { ...call, isOnHold: true }
              : cc
          );
        }
        return current;
      });
      handleSetHoldAndResumeCallDelay(heldCall.sessionId);
    },
    [handleSetHoldAndResumeCallDelay]
  );

  const handleResumedCall = useCallback(
    (resumedCall: typeof CallSocketNotificationResponses.OnHold) => {
      setCalls((current) => {
        const call = current.find(
          (cl) => cl.sessionId === resumedCall.sessionId && cl.isOnHold
        );
        if (call) {
          return current.map((cc) =>
            cc?.sessionId === resumedCall.sessionId
              ? { ...call, isOnHold: false }
              : cc
          );
        }

        return current;
      });
      handleSetHoldAndResumeCallDelay(resumedCall.sessionId);
    },
    [handleSetHoldAndResumeCallDelay]
  );

  const handleUnansweredCall = useCallback(
    (unansweredCall: typeof CallSocketNotificationResponses.Unanswered) => {
      setCalls((c) =>
        c.filter((cl) => cl.sessionId !== unansweredCall.sessionId)
      );
      setViewedCall((current) => {
        if (current === unansweredCall.sessionId) {
          return "";
        }
        return current;
      });
      setActiveCallSession((current) => {
        if (current === unansweredCall.sessionId) {
          return "";
        }
        return current;
      });
    },
    []
  );

  const handleUpdateServiceCase = useCallback(
    async (call: Call) => {
      if (call.updatedServiceCase?.id) {
        const res = await getServiceCase(call.updatedServiceCase.id);
        updateCallServiceCase(call.sessionId, res);
      }
    },
    [updateCallServiceCase]
  );

  const handleUpdateServiceCaseIfExist = useCallback(
    (serviceCaseID: string) => {
      setCalls((current) => {
        const call = current.find((cl) => cl.sessionId === serviceCaseID);
        if (call) {
          handleUpdateServiceCase(call);
        }
        return current;
      });
    },
    [handleUpdateServiceCase]
  );

  const handleUpdateServiceCaseFromNotification = useCallback(
    (
      payload: typeof ServiceCaseSocketNotificationResponses.BeManagerApprovalRequestRefused
    ) => {
      handleUpdateServiceCaseIfExist(payload.serviceCaseDetails.id);
    },
    [handleUpdateServiceCaseIfExist]
  );

  useEffect(() => {
    if (dispatcherId) {
      addHandler<typeof CallSocketNotificationResponses.NewServiceCall>(
        SocketEventsEnum.NEW_SERVICE_CASE_CALL,
        handleIncomingCall
      );
      addHandler<typeof CallSocketNotificationResponses.Answered>(
        SocketEventsEnum.CALL_ANSWERED,
        handleAnsweredCall
      );

      addHandler<typeof CallSocketNotificationResponses.Unanswered>(
        SocketEventsEnum.CALL_UNANSWERED,
        handleUnansweredCall
      );

      addHandler<typeof CallSocketNotificationResponses.Cut>(
        SocketEventsEnum.CALL_CUT,
        handleCutCall
      );

      addHandler<typeof CallSocketNotificationResponses.OnHold>(
        SocketEventsEnum.CALL_ON_HOLD,
        handleHoldCall
      );

      addHandler<typeof CallSocketNotificationResponses.Resumed>(
        SocketEventsEnum.CALL_RESUMED,
        handleResumedCall
      );

      addHandler<typeof CallSocketNotificationResponses.Ended>(
        SocketEventsEnum.CALL_ENDED,
        handleEndedCall
      );

      addHandler<
        typeof ServiceCaseSocketNotificationResponses.BeManagerApprovalRequestAccepted
      >(
        SocketEventsEnum.BE_MANAGER_APPROVAL_REQUEST_ACCEPTED,
        handleUpdateServiceCaseFromNotification
      );

      addHandler<
        typeof ServiceCaseSocketNotificationResponses.BeManagerApprovalRequestRefused
      >(
        SocketEventsEnum.BE_MANAGER_APPROVAL_REQUEST_REFUSED,
        handleUpdateServiceCaseFromNotification
      );
    }
  }, [
    handleIncomingCall,
    addHandler,
    handleAnsweredCall,
    handleUnansweredCall,
    handleCutCall,
    handleHoldCall,
    handleResumedCall,
    handleEndedCall,
    dispatcherId,
    handleUpdateServiceCaseFromNotification,
  ]);

  /**
   * Unsubscribed from interval
   */
  useEffect(() => {
    return () => {
      if (soundInterval) {
        clearInterval(soundInterval);
      }
    };
  }, [soundInterval]);

  /**
   * Sync sound
   */
  useEffect(() => {
    const ringingCalls = calls.filter((call) => call.isRinging);
    if (!ringingCalls.length) {
      stopSound();
    } else {
      const hasEmergencyCallRinging = ringingCalls.find(
        (ringingCall) => ringingCall.isEmergency
      );
      if (hasEmergencyCallRinging) {
        playSound("emergency");
      } else {
        playSound("incoming");
      }
    }
  }, [calls, stopSound, playSound]);

  const fetchActiveCalls = useCallback(async () => {
    setLoading((c) => c + 1);
    try {
      if (!dispatcherRoles.isDispatcher) {
        return;
      }
      const res = await getActiveCalls();

      const {
        callsToHold,
        callsToEnd,
        activeCall,
        callsRinging,
        storedCallsDiscardedFromServer,
      } = mapActiveCallsResToCallType(res, callSelector.calls);

      const activeCalls = [...callsRinging, ...callsToEnd, ...callsToHold];

      if (activeCall) {
        setActiveCallSession(activeCall.sessionId);
        viewCall(activeCall.sessionId);
        activeCalls.push(activeCall);
      } else {
        setActiveCallSession("");
        setViewedCall("");
      }

      const storedCallsDiscardedFromServerSessionIDs =
        storedCallsDiscardedFromServer.map((c) => c.sessionId);

      setCalls((current) => {
        const filtered = current
          .concat(activeCalls)
          .filter(
            (c) =>
              !storedCallsDiscardedFromServerSessionIDs.includes(c.sessionId)
          );
        return filtered;
      });

      const holdCalls = async (lastHeldCallIndex = 0): Promise<boolean> => {
        if (!callsToHold.length) {
          return true;
        }
        const callToHold = callsToHold[lastHeldCallIndex];
        await postHoldCall(callToHold.id!, callToHold.sessionId);
        if (lastHeldCallIndex === callsToHold.length - 1) {
          return true;
        }
        const holdCallRes = await holdCalls(lastHeldCallIndex + 1);

        return holdCallRes;
      };

      await holdCalls();
    } catch (error) {
      //
    } finally {
      setLoading((c) => c - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatcherRoles.isDispatcher, viewCall]);

  const updateCall = useCallback((sessionId: string, call: Call) => {
    setCalls((savedCalls) =>
      savedCalls.map((selectedCall) => {
        if (selectedCall.sessionId === sessionId) {
          return {
            ...selectedCall,
            ...call,
          };
        }

        return call;
      })
    );
  }, []);

  useEffect(() => {
    fetchActiveCalls();
  }, [fetchActiveCalls]);

  /**
   * Update store
   */

  useEffect(() => {
    dispatch(callActions.setCalls(calls));
  }, [dispatch, calls]);

  useEffect(() => {
    dispatch(callActions.setChats(chatMessages));
  }, [dispatch, chatMessages]);

  useEffect(() => {
    if (viewedCall) {
      const activeCall = calls.find((call) => call.sessionId === viewedCall);
      if (activeCall && activeCall.token) {
        const timeOutId = setTimeout(() => {
          handleCreateOpenTokSession({
            token: activeCall.token!,
            sessionId: activeCall.sessionId,
            apiKey: activeCall.apiKey,
          });
        }, 1000);

        return () => clearTimeout(timeOutId);
      }
    }
  }, [calls, viewedCall, handleCreateOpenTokSession]);

  /**
   * Handle CALL MICS
   */

  const handleMuteCall = useCallback((sessionID: string, value?: boolean) => {
    setMutedMicCalls((current) => ({
      ...current,
      [sessionID]: typeof value === "boolean" ? value : !current[sessionID],
    }));
  }, []);

  return (
    <CallContext.Provider
      value={{
        calls,
        viewedCall,
        createPickCallRequest,
        createEndCallRequest,
        viewCall,
        activeCallSession,
        chatOpen,
        stopVideo,
        handleSwitchLayout,
        handleOpenChat,
        layout,
        completeEndCallRequest,
        endCallSessionRequest,
        cancelEndCallRequest,
        completePickCallRequest,
        cancelPickCallRequest,
        pickCallRequestId,
        putCallOnHold,
        resumeCallFromHold,
        handleOpenVideo,
        chatMessages,
        postMessageRequest,
        removeCutCall,
        cutCalls,
        updateCallRequesterGeoLocation,
        updateCallRequesterInitialAssessment,
        updateCallServiceCase,
        openTokSessions,
        updateCall,
        mutedMicCalls,
        handleMuteCall,
      }}
    >
      <EndCallDialog />
      <PickCallDialog />
      <Loader open={loading > 0} />
      <audio ref={incomingCallSound}>
        <track kind="captions" />
        <source type="audio/mp3" src={IncomingCallSound} />
      </audio>
      <audio ref={emergencyIncomingCallSound}>
        <track kind="captions" />
        <source type="audio/mp3" src={EmergencyIncomingCallSound} />
      </audio>
      <audio ref={checkInSound}>
        <track kind="captions" />
        <source type="audio/mp3" src={CheckInSound} />
      </audio>
      <audio ref={unansweredCallSound}>
        <track kind="captions" />
        <source type="audio/mp3" src={UnansweredCallSound} />
      </audio>
      {children}
    </CallContext.Provider>
  );
};
